/* eslint-disable react-hooks/exhaustive-deps */
import { LinearProgress, MenuItem, Select } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Row } from 'reactstrap';
// import { useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { useQuery, useMutation } from '@apollo/client';
import { GET_MEETING_ROOM_STATUS, GET_MEETING_ROOMS, ADD_MEETING_ROOM } from '../../../graphql/meeting-rooms.graphql';
import { GET_LOCATIONS } from '../../../graphql/locations.graphql';
import useToast from '../../../hooks/useToast';
import { RoomStatus } from '../../../models/meeting.room.model';
import { Location } from '../../../models/location.model';
import Spinner from '../../../components/Spinner/Spinner';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FeaturedImage from '../../../components/Image component/ImageContainer';
import { uploadImage } from '../../../hooks/upload-image.hook';
import { useNavigate } from 'react-router-dom';

type FormData = {
    name: string;
    description: string;
    capacity: string;
    status: string;
    location: string;
};

const MeetingAddForm = () => {
    // const history = useHistory();
    const navigate = useNavigate();
    const { loading, error, data } = useQuery(GET_MEETING_ROOM_STATUS);
    const { showToast, Notification } = useToast();
    const [imageFile, setImageFile] = useState<any>();
    const [image, setImage] = useState<string>();
    const { loading: loadingLocations, error: errorLoading, data: loadedLocations } = useQuery(GET_LOCATIONS);
    const fileRef: any = useRef(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [addMeetingRoomMutation, { loading: isAddingRoom, error: errorAddingRoom, data: addedRoom }] = useMutation(
        ADD_MEETING_ROOM,
        {
            refetchQueries: [{ query: GET_MEETING_ROOMS }, 'GetMeetingRooms'],
        },
    );

    const redirectToMeetingRooms = () => {
        navigate(-1);
    };

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        trigger,
        formState: { errors },
    } = useForm<FormData>();

    useEffect(() => {
        register('description', { required: true });
    });

    useEffect(() => {
        if (error || errorLoading) {
            showToast('An error has occurred, please refresh the page', 'danger');
        }

        if (errorAddingRoom) {
            showToast('An error has occurred while trying to add this meeting team', 'danger');
        }
    }, [error, errorLoading, errorAddingRoom]);

    useEffect(() => {
        if (addedRoom && !error && !errorLoading && !errorAddingRoom) {
            showToast('Successfully added a room', 'success');
            setIsLoading(false);
            reset();
        }

        if (addedRoom) {
            console.log('show data :', addedRoom);
        }
    }, [addedRoom]);

    const onImageChange = (e: any) => {
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setImageFile(e.target.files[0]);
        setImage(imageUrl);
    };

    const onSubmit = async (data: FormData) => {
        setIsLoading(true);
        if (data.status === 'empty') {
            setValue('status', '');
        }

        if (data.location === 'empty') {
            setValue('location', '');
        }

        const isAnySelectEmpty = data.status === 'empty' ? true : false;

        if (isAnySelectEmpty) {
            setIsLoading(false);
            return;
        }

        if (!imageFile) {
            showToast('Please make sure that an image file is selected', 'danger');
            setIsLoading(false);
            return;
        }

        const imageKey = await uploadImage(imageFile, 'meeting_rooms_images');

        // console.log("Image key :", imageKey)

        if (imageKey === 'error') {
            showToast('An error has occurred while trying to upload this image, please try again', 'danger');
            setIsLoading(false);
            return;
        }

        const roomMeeting = {
            name: data.name,
            description: data.description,
            capacity: data.capacity,
            status_enum: data.status,
            location_id: data.location,
            featured_image: imageKey,
        };

        console.log('meeting room data :', roomMeeting);
        addMeetingRoomMutation({
            variables: {
                room: roomMeeting,
            },
        });
    };

    if (loading || loadingLocations) {
        return <LinearProgress />;
    }

    const locations: Location[] = loadedLocations?.location;
    const roomStatuses: RoomStatus[] = data?.roomStatus;

    return (
        <Container className='mt-4' fluid>
            {Notification}
            <Row>
                <Col md='12' lg='6'>
                    <Card>
                        <CardHeader>
                            <Row noGutters={true}>
                                <button
                                    className='btn btn-outline-primary btn-sm mr-4'
                                    onClick={redirectToMeetingRooms}>
                                    <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                                    <span className='btn-inner-text'>Back</span>
                                </button>
                                <h3 className='m-0'>Add room</h3>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='name'>
                                        Featured Image
                                    </label>
                                    <FeaturedImage imageSource={image} fileInputRef={fileRef} />
                                    <input
                                        onChange={(e) => onImageChange(e)}
                                        type='file'
                                        accept='image/*'
                                        ref={fileRef}
                                        style={{ display: 'none' }}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='name'>
                                        Name
                                    </label>
                                    <input
                                        className='form-control'
                                        {...register('name', { required: true })}
                                        id='company-name'
                                        placeholder="Enter meeting room's name..."
                                        type='text'
                                    />
                                    {errors.name && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='name'>
                                        Description
                                    </label>
                                    <CKEditor
                                        id='description'
                                        data={''}
                                        editor={ClassicEditor}
                                        config={{
                                            removePlugins: ['Heading'],
                                            toolbar: [
                                                'bold',
                                                'italic',
                                                'bulletedList',
                                                'numberedList',
                                                'blockQuote',
                                                'link',
                                            ],
                                        }}
                                        placeholder='Enter room description'
                                        onChange={(event: any, editor: any) => {
                                            setValue('description', editor.getData());
                                            trigger('description');
                                        }}
                                        onReady={(editor: any) => {}}
                                    />
                                    {errors.description && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='email'>
                                        Capacity
                                    </label>
                                    <input
                                        className='form-control'
                                        {...register('capacity', { required: true })}
                                        id='email'
                                        placeholder="Enter meeting room's capacity..."
                                        type='text'
                                    />
                                    {errors.capacity && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='contact'>
                                        Status
                                    </label>
                                    <Select
                                        id='package'
                                        className='form-control'
                                        sx={{ borderRadius: '12px', padding: '0' }}
                                        placeholder='Select a role'
                                        defaultValue={'active'}
                                        inputProps={{
                                            ...register('status', {
                                                required: true,
                                                value: 'active',
                                            }),
                                        }}>
                                        <MenuItem disabled className='placeholder-text' value={'empty'}>
                                            Select meeting room's status...
                                        </MenuItem>
                                        {roomStatuses.map((status: RoomStatus) => {
                                            return (
                                                <MenuItem key={status.value} value={status.value}>
                                                    {status.title}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    {errors.status && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='package'>
                                        Locations
                                    </label>
                                    <Select
                                        id='package'
                                        className='form-control'
                                        sx={{ borderRadius: '12px', padding: '0' }}
                                        defaultValue={'empty'}
                                        inputProps={{
                                            placeholder: 'Select a location',
                                            ...register('location', {
                                                required: true,
                                                value: 'empty',
                                            }),
                                        }}>
                                        <MenuItem disabled className='placeholder-text' value={'empty'}>
                                            Select meeting room's location...
                                        </MenuItem>
                                        {locations.map((location: Location) => {
                                            return (
                                                <MenuItem key={location.id} value={location.id}>
                                                    {location.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    {errors.location && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                                <div className='d-flex h-100'>
                                    <div className='align-self-end ml-auto'>
                                        <Button
                                            type='submit'
                                            className='btn btn-dark mt-4 btn-block'
                                            disabled={isAddingRoom || isLoading}>
                                            {isAddingRoom || isLoading ? (
                                                <span>
                                                    <Spinner />
                                                </span>
                                            ) : (
                                                <span>Add room</span>
                                            )}
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default MeetingAddForm;
