import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table,
    UncontrolledTooltip,
} from 'reactstrap';
import BasicMenu from '../../../../components/Menu';
import useToast from '../../../../hooks/useToast';
import { useMutation, useQuery } from '@apollo/client';
import { GET_RESOURCE_ITEMS, REMOVE_RESOURCE_ITEM } from '../../../../graphql/resource.graphql';
import { useParams } from 'react-router-dom';
import { LinearProgress } from '@mui/material';
import { ResourceItem } from '../../../../models/resource.model';
import _ from 'lodash';
import SweetAlert from 'react-bootstrap-sweetalert';
import ResourceForm from './ResourceForm';
import ResourceItemDetailsForm from './ResourceItemDetailsForm';
export default function ResourceItemList() {
    const [page, setPage] = useState<number>(0);
    const params: any = useParams();
    const [alert, setAlert] = useState<any>(null);
    const [showAddForm, setShowSddForm] = useState<boolean>(false);
    const [showDetailsForm, setShowDetailsForm] = useState<boolean>(false);

    const [rowPerPage, setRowPerPage] = useState<number>(10);
    const { showToast, Notification } = useToast();
    const [itemId, setItemId] = useState<string>('');
    const [searchKey, setSearchKey] = useState<string>('');
    const [sortField, setSortField] = useState<string>('name');
    const [sortDirection, setSortDirection] = useState<string>('desc');

    const { data, loading, error } = useQuery(GET_RESOURCE_ITEMS, {
        variables: {
            resource_id: params?.id,
        },
    });

    const [removedResourceItem, { error: removeError }] = useMutation(REMOVE_RESOURCE_ITEM);

    useEffect(() => {
        if (removeError) {
            showToast('An error has occurred while trying to remove the resource item', 'danger');
        }
    }, [removeError]);

    const sortBy: any = (items: ResourceItem[], field: string, direction: string) => {
        const resourceMap = new Map([
            ['name', _.orderBy(items, (item) => item.name, [direction === 'asc' ? 'asc' : 'desc'])],
            ['status', _.orderBy(items, (item) => item.status.title, [direction === 'asc' ? 'asc' : 'desc'])],
            ['code', _.orderBy(items, (item) => item.code, [direction === 'asc' ? 'asc' : 'desc'])],
            ['location', _.orderBy(items, (item) => item?.location?.name, [direction === 'asc' ? 'asc' : 'desc'])],
            ['type', _.orderBy(items, (item) => item?.resource?.name, [direction === 'asc' ? 'asc' : 'desc'])],
        ]);
        return resourceMap.get(field);
    };

    const displayAlert = (props: any) => {
        setAlert(
            <SweetAlert
                {...props}
                showCancel
                confirmBtnText='Continue'
                confirmBtnBsStyle='danger'
                title='Are you sure?'
                onConfirm={() => {
                    removedResourceItem({
                        variables: {
                            id: props.res.id,
                        },
                    });
                    setAlert(null);
                }}
                onCancel={() => {
                    setAlert(null);
                }}
                focusCancelBtn>
                This resource will be removed from the system.
            </SweetAlert>,
        );
    };

    const nextPage = () => {
        setPage(page + 1);
    };

    const previousPage = () => {
        setPage(page - 1);
    };

    const getRowValue = (value: string) => {
        setRowPerPage(parseInt(value));
    };

    useEffect(() => {
        if (error) {
            showToast('An error has occurred while trying to load this page', 'danger');
        }
    }, [error]);

    if (loading) {
        return <LinearProgress />;
    }

    if (showDetailsForm) {
        return (
            <ResourceItemDetailsForm resourceId={itemId} redirectBackToResourceList={() => setShowDetailsForm(false)} />
        );
    }

    if (showAddForm) {
        return <ResourceForm redirectBackToResourceList={() => setShowSddForm(false)} />;
    }

    const resourceItems: ResourceItem[] = data?.items;

    let filteredResourceItems = resourceItems?.filter((res) => {
        if (searchKey === '') {
            return res;
        }
        return (
            res.name.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            res.status.title.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            res.location.name.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            res.code.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            res.resource.name.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase())
        );
    });

    filteredResourceItems = sortBy(filteredResourceItems, sortField, sortDirection);

    return (
        <>
            {alert}
            {Notification}
            <Row className='mt-4'>
                <div className='col'>
                    <Card>
                        <CardHeader className='border-0'>
                            <Row>
                                <Col xs='6'>
                                    <h3 className='mb-0'>Resources Items</h3>
                                </Col>
                                <Col className='text-right' xs='6'>
                                    <button
                                        onClick={() => setShowSddForm(true)}
                                        className='btn btn-round btn-sm btn-dark'
                                        color='default'
                                        id='tooltip969372949'>
                                        <span className='btn-inner--text'>Add item</span>
                                    </button>
                                    <UncontrolledTooltip delay={0} target='tooltip969372949'>
                                        Add item
                                    </UncontrolledTooltip>
                                </Col>
                            </Row>
                            <Row className='mt-4'>
                                <Col>
                                    <Input
                                        className='w-25'
                                        value={searchKey}
                                        placeholder='Search keyword...'
                                        onChange={(e) => {
                                            setSearchKey(e.target.value);
                                            setPage(0);
                                        }}></Input>
                                </Col>
                            </Row>
                        </CardHeader>
                        <Table className='align-items-center table-flush'>
                            <thead className='thead-light'>
                                <tr>
                                    <th
                                        onClick={() => {
                                            setSortField('name');
                                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                        }}
                                        className='sort'
                                        data-sort='subscriber'
                                        scope='col'>
                                        Name
                                    </th>
                                    <th
                                        onClick={() => {
                                            setSortField('location');
                                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                        }}
                                        className='sort'
                                        data-sort='status'
                                        scope='col'>
                                        Location
                                    </th>
                                    <th
                                        onClick={() => {
                                            setSortField('status');
                                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                        }}
                                        className='sort'
                                        data-sort='status'
                                        scope='col'>
                                        Status
                                    </th>
                                    <th
                                        onClick={() => {
                                            setSortField('code');
                                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                        }}
                                        className='sort'
                                        data-sort='status'
                                        scope='col'>
                                        Code
                                    </th>
                                    <th
                                        onClick={() => {
                                            setSortField('type');
                                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                        }}
                                        className='sort'
                                        data-sort='type'
                                        scope='col'>
                                        Resource Type
                                    </th>
                                    <th scope='col'></th>
                                </tr>
                            </thead>
                            <tbody className='list'>
                                {filteredResourceItems.map((res) => {
                                    return (
                                        <tr
                                            key={res.id}
                                            onClick={() => {
                                                setItemId(res.id);
                                                setShowDetailsForm(true);
                                                // navigate(`/admin/resources/manage/${res?.id}`);
                                            }}
                                            style={{
                                                cursor: 'pointer',
                                            }}>
                                            <td>{res.name}</td>
                                            <td>{res.location.name}</td>
                                            <td>{res.status.title}</td>
                                            <td>{res.code}</td>
                                            <td>{res.resource.name}</td>
                                            <td className='text-right'>
                                                <Button
                                                    onClick={() => {
                                                        setItemId(res.id);
                                                        setShowDetailsForm(true);
                                                    }}
                                                    className='btn btn-info btn-icon-only rounded-circle btn-sm'>
                                                    <i className='fa-solid fa-pencil'></i>
                                                </Button>

                                                <Button
                                                    className='btn btn-danger btn-icon-only rounded-circle btn-sm'
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        displayAlert({ warning: true, res });
                                                    }}>
                                                    <i className='fa-solid fa-trash-can'></i>
                                                </Button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                        <CardFooter className='py-4'>
                            <Row>
                                <div className='col-4 d-flex'>
                                    <span style={{ fontSize: '12px' }}>Rows per page:</span>
                                    <BasicMenu
                                        getValue={getRowValue}
                                        options={['10', '20', '100']}
                                        value={rowPerPage.toString()}
                                    />
                                </div>
                                <div className='col-4 d-flex'>
                                    <span className='min-text'>Page:</span>
                                    <span className='min-text pl-2'>
                                        {page + 1} of {Math.ceil(filteredResourceItems.length / rowPerPage)}
                                    </span>
                                </div>
                                <div className='col-4 d-flex'>
                                    <Pagination>
                                        <PaginationItem className={page <= 0 ? 'disabled' : ''}>
                                            <PaginationLink onClick={(e) => previousPage()}>
                                                <i className='fas fa-angle-left' />
                                                <span className='sr-only'>Previous</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                        <PaginationItem
                                            className={
                                                page >= Math.ceil(filteredResourceItems.length / rowPerPage) - 1
                                                    ? 'disabled'
                                                    : ''
                                            }>
                                            <PaginationLink onClick={(e) => nextPage()}>
                                                <i className='fas fa-angle-right' />
                                                <span className='sr-only'>Previous</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                    </Pagination>
                                </div>
                            </Row>
                        </CardFooter>
                    </Card>
                </div>
            </Row>
        </>
    );
}
