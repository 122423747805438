/* eslint-disable no-useless-escape */
import { Chip } from '@mui/material';
import React, { useState } from 'react';
import FormGroup from '@mui/material/FormGroup';
import Input from '@mui/material/Input';
import { BookingGuest } from '../../../../models/bookings.model';

interface EmailChipListInputProps {
    label?: string;
    placeholder: string;
    items: BookingGuest[];
    onAddItem: (item: BookingGuest) => void;
    onRemoveitem: (item: BookingGuest) => void;
}

const EmailChipListInput = ({ items, placeholder, onRemoveitem, onAddItem }: EmailChipListInputProps) => {
    const [error, setError] = useState<string | null>();
    const [value, setValue] = useState<string>();

    const handleKeyDown = (event: any) => {
        if (['Enter', 'Tab', ','].includes(event.key)) {
            event.preventDefault();
            const value = event.target.value;
            if (isValid(value)) {
                console.log('Add guest');
                onAddItem({ email: value });
                setValue('');
            }
        }
    };

    const handleChange = (event: any) => {
        setValue(event.target.value);
    };

    const isValid = (email: string) => {
        let error = null;

        if (isInList(email)) {
            error = `${email} has already been added.`;
        }

        if (!isEmail(email)) {
            error = `${email} is not a valid email address.`;
        }

        if (error) {
            setError(error);
            return false;
        }
        setError(null);
        return true;
    };

    const isInList = (email: string) => {
        return items.findIndex((item) => item.email === email) > 0;
    };

    const isEmail = (email: string) => {
        return /[\w\d.-]+@[\w\d.-]+.[\w\d.-]+/.test(email);
    };

    return (
        <>
            <FormGroup>
                <Input
                    value={value}
                    sx={{ width: '50%' }}
                    fullWidth={false}
                    style={{ fontSize: '14px' }}
                    className='mb-2'
                    placeholder={placeholder}
                    type='text'
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                />
                {error && <span className='invalid'>{error}</span>}
                <div style={{ display: 'inline-block' }}>
                    {items.map((item) => {
                        return (
                            <Chip
                                size='small'
                                key={item.email}
                                variant='outlined'
                                label={item.email}
                                onDelete={() => {
                                    console.log('Delete booking guest...');
                                    onRemoveitem(item);
                                }}
                            />
                        );
                    })}
                </div>
            </FormGroup>
        </>
    );
};

export default EmailChipListInput;
