/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { LinearProgress, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Label, Row, Spinner } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useQuery, useMutation } from '@apollo/client';
import { ADD_CLIENT, GET_CLIENTS } from '../../../graphql/clients.graphql';
import useToast from '../../../hooks/useToast';
import { GET_ADD_CLIENT_DATA } from '../../../graphql/clients.graphql';
import { ClientStatus, ClientType } from '../../../models/client.model';
import { PhoneInput } from 'react-international-phone';
import { Role } from '../../../models/user.model';
import { useNavigate } from 'react-router-dom';
import { PhoneNumberUtil } from 'google-libphonenumber';

type FormData = {
    companyName: string;
    vatNumber: string;
    email: string;
    contactNumber: string;
    principalMemberName: string;
    principalMemberSurname: string;
    status: string;
    type: string;
    companyRegNumber: string;
    identityNumber: string;
    licencePlate: string;
    isColabMember: string;
    individualName: string;
    accountCode: string;
    hasAccount: string;
};

const ClientAddForm = () => {
    const navigate = useNavigate();
    const [isAccountCode, setIsAccountCode] = useState<boolean>(false);
    const [memberRole, setMemberRole] = useState<Role>();
    const { loading, error, data } = useQuery(GET_ADD_CLIENT_DATA);
    const [accountType, setAccountType] = useState<string>('');
    const [addClientMutation, { loading: addingClient, error: addingError, data: clientAdded }] = useMutation(
        ADD_CLIENT,
        {
            refetchQueries: [{ query: GET_CLIENTS }, 'GetClients'],
        },
    );
    const { showToast, Notification } = useToast();

    const phoneUtil = PhoneNumberUtil.getInstance();

    const isPhoneValid = (phone: string) => {
        try {
            return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
        } catch (error) {
            return false;
        }
    };

    const {
        register,
        handleSubmit,
        setValue,
        reset,
        watch,
        setError,
        formState: { errors },
    } = useForm<FormData>();

    const redirectToClients = (e: any) => {
        e.preventDefault();
        navigate(-1);
    };

    useEffect(() => {
        console.log('Account type', accountType);
    }, [accountType]);

    useEffect(() => {
        if (data) {
            const member: Role = data?.role.find((role) => role.name === 'Principal Member');

            setMemberRole(member ? member : undefined);
        }
    }, [data]);

    useEffect(() => {
        if (error) {
            showToast('An error has occurred, please try again.', 'danger');
        }

        if (addingError) {
            showToast('An error has occurred while trying to add a client, please try again', 'danger');
        }
    }, [error, addingError]);

    useEffect(() => {
        if (!error && clientAdded) {
            showToast('Client is added Successfully', 'success');
            reset();
        }
    }, [clientAdded]);

    const addClient = (data: FormData) => {
        if (data.type === 'empty') {
            setValue('type', '');
            return;
        }

        if (!isPhoneValid(data.contactNumber)) {
            setError('contactNumber', { type: 'custom', message: '*Please enter a valid phone number' });
            return;
        }

        const userRoleId = memberRole.id;
        const userAccount = {
            company_name: data.type !== 'individual' ? data.companyName : null,
            company_registration_number: data.type !== 'individual' ? data.companyRegNumber : null,
            vat_number: data.vatNumber !== '' ? data.vatNumber : null,
            status_enum: data.status,
            has_colab_account: data.hasAccount,
            account_code: data.accountCode,
            type_enum: data.type,
            is_colab_member: data.isColabMember,
            individual_name: data.type === 'individual' ? data.individualName : null,
            user: {
                on_conflict: {
                    constraint: 'user_email_key',
                    update_columns: ['name', 'surname', 'contact_number', 'id_number', 'licence_plate'],
                },
                data: {
                    name: data.principalMemberName,
                    surname: data.principalMemberSurname,
                    email: data.email,
                    contact_number: data.contactNumber,
                    role_id: userRoleId,
                    id_number: data.identityNumber,
                    licence_plate: data.licencePlate,
                },
            },
        };

        addClientMutation({
            variables: {
                user_account: userAccount,
            },
        });
    };

    const onSubmit = (data: FormData) => {
        addClient(data);
    };

    if (loading) {
        return <LinearProgress />;
    }

    const clientStatusList: ClientStatus[] = data?.status;
    const clientTypes: ClientType[] = data?.types;
    const handleChange = (e: any) => {
        if (e.target.checked) {
            setIsAccountCode(true);
        } else setIsAccountCode(false);
    };

    return (
        <>
            {Notification}
            <Container className='mt-4' fluid>
                <Row>
                    <Col md='12' lg='6'>
                        <Card>
                            <CardHeader>
                                <Row noGutters={true}>
                                    <button
                                        className='btn btn-outline-primary btn-sm mr-4'
                                        onClick={(e) => redirectToClients(e)}>
                                        <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                                        <span className='btn-inner-text'>Back</span>
                                    </button>
                                    <h3 className='m-0'>Add client</h3>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <h3>Client Information</h3>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-12'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='p-member-surname'>
                                                    Type
                                                </label>
                                                <Select
                                                    id='type'
                                                    sx={{ borderRadius: '12px', padding: '0' }}
                                                    className='form-control'
                                                    defaultValue={'empty'}
                                                    onChange={(event: SelectChangeEvent) => {
                                                        setAccountType(event.target.value);
                                                    }}
                                                    inputProps={{
                                                        ...register('type', {
                                                            required: true,
                                                            value: '',
                                                        }),
                                                    }}>
                                                    <MenuItem disabled value={'empty'}>
                                                        <span className='placeholder-text'>
                                                            Select a client type...
                                                        </span>
                                                    </MenuItem>
                                                    {clientTypes.map((type) => {
                                                        return (
                                                            <MenuItem key={type.value} value={type.value}>
                                                                {type.title}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormGroup>
                                        </div>
                                        {accountType === 'individual' && (
                                            <div className='col-lg-6 col-md-12'>
                                                <FormGroup>
                                                    <label className='form-control-label' htmlFor='name'>
                                                        Individual name
                                                    </label>
                                                    <input
                                                        className='form-control'
                                                        {...register('individualName', {
                                                            required: accountType === 'individual',
                                                        })}
                                                        id='company-name'
                                                        placeholder="Enter company's name..."
                                                        type='text'
                                                    />
                                                    {errors.companyName && (
                                                        <span className='invalid'>*This field is required</span>
                                                    )}
                                                </FormGroup>
                                            </div>
                                        )}
                                        {accountType !== 'individual' && accountType !== '' && (
                                            <div className='col-lg-6 col-md-12'>
                                                <FormGroup>
                                                    <label className='form-control-label' htmlFor='name'>
                                                        Company name
                                                    </label>
                                                    <input
                                                        className='form-control'
                                                        {...register('companyName', {
                                                            required: accountType !== 'individual',
                                                        })}
                                                        id='company-name'
                                                        placeholder="Enter company's name..."
                                                        type='text'
                                                    />
                                                    {errors.companyName && (
                                                        <span className='invalid'>*This field is required</span>
                                                    )}
                                                </FormGroup>
                                            </div>
                                        )}
                                        {accountType !== 'individual' && accountType !== '' && (
                                            <div className='col-lg-6 col-md-12'>
                                                <FormGroup>
                                                    <label className='form-control-label' htmlFor='companyRegNumber'>
                                                        Company registration number
                                                    </label>
                                                    <input
                                                        className='form-control'
                                                        id='companyRegNumber'
                                                        placeholder="Enter company's registration number..."
                                                        {...register('companyRegNumber', {
                                                            required: false,
                                                        })}
                                                        type='text'
                                                    />
                                                    {errors.companyRegNumber && (
                                                        <span className='invalid'>*This field is required</span>
                                                    )}
                                                </FormGroup>
                                            </div>
                                        )}
                                        <div className='col-lg-6 col-md-12'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='name'>
                                                    VAT number (optional)
                                                </label>
                                                <input
                                                    className='form-control'
                                                    {...register('vatNumber', { required: false })}
                                                    id='vatNumber'
                                                    placeholder="Enter client's VAT number..."
                                                    type='text'
                                                />
                                                {errors.vatNumber && (
                                                    <span className='invalid'>*This field is required</span>
                                                )}
                                            </FormGroup>
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='email'>
                                                    Email
                                                </label>
                                                <input
                                                    className='form-control'
                                                    {...register('email', { required: true })}
                                                    id='email'
                                                    placeholder="Enter the client's email..."
                                                    type='email'
                                                />
                                                {errors.email && (
                                                    <span className='invalid'>*This field is required</span>
                                                )}
                                            </FormGroup>
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='contact'>
                                                    Contact number
                                                </label>
                                                <PhoneInput
                                                    defaultCountry='za'
                                                    placeholder='Enter phone number...'
                                                    inputClassName='form-control'
                                                    value={watch('contactNumber')}
                                                    style={
                                                        {
                                                            '--react-international-phone-width': '100%',
                                                            '--react-international-phone-height': '44px',
                                                            '--react-international-phone-border-radius': '12px',
                                                        } as React.CSSProperties
                                                    }
                                                    {...register('contactNumber', {
                                                        required: true,
                                                    })}
                                                    onChange={(phone) => setValue('contactNumber', phone)}
                                                />
                                                {errors.contactNumber && (
                                                    <span className='invalid'>
                                                        {errors.contactNumber.message ?? '*This field is required'}
                                                    </span>
                                                )}
                                            </FormGroup>
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='p-member-surname'>
                                                    Status
                                                </label>
                                                <Select
                                                    id='status'
                                                    sx={{ borderRadius: '12px', padding: '0' }}
                                                    className='form-control'
                                                    defaultValue={'active'}
                                                    inputProps={{
                                                        ...register('status', {
                                                            required: true,
                                                            value: '',
                                                        }),
                                                    }}>
                                                    {clientStatusList.map((status) => {
                                                        return (
                                                            <MenuItem key={status.value} value={status.value}>
                                                                {status.title}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormGroup>
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <FormGroup check inline>
                                                <input
                                                    type='checkbox'
                                                    className='mr-2'
                                                    {...register('hasAccount', {
                                                        required: false,
                                                    })}
                                                    onChange={handleChange}
                                                />
                                                <Label check>Has Colab Account?</Label>
                                            </FormGroup>
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <FormGroup check inline>
                                                <input
                                                    type='checkbox'
                                                    className='mr-2'
                                                    {...register('isColabMember', {
                                                        required: false,
                                                    })}
                                                />
                                                <Label check>Is Colab Member?</Label>
                                            </FormGroup>
                                        </div>
                                        {isAccountCode && (
                                            <div className='col-lg-6 col-md-12 mt-3'>
                                                <FormGroup>
                                                    <label className='form-control-label' htmlFor='account-code'>
                                                        Account code
                                                    </label>
                                                    <input
                                                        className='form-control'
                                                        id='account-code'
                                                        placeholder='Enter Account code...'
                                                        {...register('accountCode', {
                                                            required: false,
                                                        })}
                                                        type='text'
                                                    />
                                                </FormGroup>
                                            </div>
                                        )}
                                        <div className='col-md-12 mt-4'>
                                            <h3>Principle Member</h3>
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='p-member-name'>
                                                    Member name
                                                </label>
                                                <input
                                                    className='form-control'
                                                    {...register('principalMemberName', {
                                                        required: true,
                                                    })}
                                                    id='p-member-name'
                                                    placeholder="Enter member's name..."
                                                    type='text'
                                                />
                                                {errors.principalMemberName && (
                                                    <span className='invalid'>*This field is required</span>
                                                )}
                                            </FormGroup>
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='p-member-surname'>
                                                    Member surname
                                                </label>
                                                <input
                                                    className='form-control'
                                                    {...register('principalMemberSurname', {
                                                        required: true,
                                                    })}
                                                    id='p-member-surname'
                                                    placeholder="Enter member's surname..."
                                                    type='text'
                                                />
                                                {errors.principalMemberSurname && (
                                                    <span className='invalid'>*This field is required</span>
                                                )}
                                            </FormGroup>
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='identityNumber'>
                                                    Identity number
                                                </label>
                                                <input
                                                    className='form-control'
                                                    id='identityNumber'
                                                    placeholder="Enter member's identity number..."
                                                    {...register('identityNumber', {
                                                        required: true,
                                                    })}
                                                    type='text'
                                                />
                                                {errors.identityNumber && (
                                                    <span className='invalid'>*This field is required</span>
                                                )}
                                            </FormGroup>
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='licencePlate'>
                                                    License plate(optional)
                                                </label>
                                                <input
                                                    className='form-control'
                                                    id='licencePlate'
                                                    placeholder="Enter member's licence plate..."
                                                    {...register('licencePlate', {
                                                        required: false,
                                                    })}
                                                    type='text'
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className='col-lg-6 col-md-12'></div>
                                    </div>

                                    <div className='d-flex h-100'>
                                        <div className='align-self-end ml-auto'>
                                            <Button
                                                type='submit'
                                                className='btn btn-dark mt-4 btn-block'
                                                disabled={addingClient}>
                                                {!addingClient ? (
                                                    <span>Add client</span>
                                                ) : (
                                                    <span>
                                                        <Spinner />
                                                    </span>
                                                )}
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {/**Account Document Upload */}
            {/* <Container className="mt-4" fluid>
        <Row>
          <Col>Hello</Col>
        </Row>
      </Container> */}
        </>
    );
};

export default ClientAddForm;

// email,mail_list_id
