import { gql } from "@apollo/client";

export const GET_BOOKING_RATE = gql`
  mutation getBookingRate($args: get_booking_price_args!) {
    rate: get_booking_price(args: $args) {
      quotedPrice: quoted_rate
      price: selected_rate
      totalPrice: total_rate
      unit: rate_unit
      discount
      quantity
    }
  }
`;
