/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery, useMutation } from '@apollo/client';
import {
    Autocomplete,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';

import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormGroup } from 'reactstrap';
import { SEARCH_EXISTING_BOOKINGS } from '../../../../graphql/bookings.graphql';
import { ADD_QUOTE_ITEM } from '../../../../graphql/quotes.graphql';
import useToast from '../../../../hooks/useToast';
import { QuoteItem } from '../../../../models/quotes.models';

interface Props {
    isOpen: boolean;
    quoteId: string;
    quoteItems: QuoteItem[];
    clientId: string;
    handleClose: () => void;
}

interface BookingInfo {
    id?: string;
    price?: string;
    subject?: string;
    discount?: number;
    bookingNumber?: string;
    totalPrice?: number;
    quotedPrice?: number;
    quantity?: number;
    unit?: string;
}

type FormData = {
    bookingInfo: BookingInfo;
};

const AddExistingBookingDialog = ({ isOpen, handleClose, quoteId, quoteItems, clientId }: Props) => {
    const [searchBooking, { data, called, error }] = useLazyQuery(SEARCH_EXISTING_BOOKINGS);
    const [query, setQuery] = useState<string>();
    const { showToast, Notification } = useToast();
    const [bookings, setBookings] = useState<BookingInfo[]>([]);
    const { register, setValue, handleSubmit } = useForm<FormData>();
    const [addQuoteItem, { loading: addingItem, data: addedItem, error: addingError }] = useMutation(ADD_QUOTE_ITEM, {
        // refetchQueries: [{ query: GET_QUOTE_DETAILS }, "getQuote"],
        refetchQueries: ['getQuote'],
    });

    useEffect(() => {
        if (query && query.length >= 3) {
            searchBooking({
                variables: {
                    query: `%${query}%`,
                    accountId: clientId,
                },
            });
        }
    }, [query]);

    useEffect(() => {
        if (data && called) {
            setBookings(data?.bookings);
        }

        if (error && !data) {
            console.log('Error', error);
        }

        if (addedItem) {
            showToast('Successfully added an existing booking.', 'success');
            handleClose();
        }

        if (addingError) {
            showToast('An error has occurred while trying to add an existing booking', 'danger');
        }
    }, [data, called, error, addedItem, addingError]);

    const isBookingInQuoteList = (bookingId: string): boolean => {
        for (let item of quoteItems) {
            if (item.booking?.id === bookingId) {
                return true;
            }
        }
        return false;
    };

    const onSubmit = (data: FormData) => {
        if (isBookingInQuoteList(data.bookingInfo.id)) {
            showToast('This booking is already in a quote, please select another booking', 'danger');
            console.log("Can't add this!");
            return;
        }

        const item = {
            booking_id: data.bookingInfo.id,
            quote_id: quoteId,
            status_enum: 'pending',
            quantity: data.bookingInfo.quantity,
            price: data?.bookingInfo.price,
            discount: data?.bookingInfo.discount,
            total_price: data.bookingInfo?.totalPrice,
            quoted_price: data.bookingInfo?.quotedPrice || data.bookingInfo?.totalPrice,
            currency: 'zar',
            unit: data.bookingInfo?.unit,
        };

        addQuoteItem({
            variables: {
                item: item,
            },
        });
    };

    return (
        <div>
            {Notification}
            <Dialog open={isOpen} onClose={handleClose}>
                <DialogTitle> Link existing booking</DialogTitle>
                <form
                    style={{
                        width: 450,
                    }}
                    onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>
                        <FormGroup>
                            <label className='form-control-label' htmlFor='name'>
                                Booking
                            </label>
                            <Autocomplete
                                id='client'
                                {...register('bookingInfo', { required: true })}
                                onChange={(event, value) => {
                                    setValue('bookingInfo', value);
                                }}
                                onInputChange={(event, value) => {
                                    setQuery(value);
                                }}
                                filterOptions={(options) => options}
                                options={bookings}
                                getOptionLabel={(option) => `${option?.bookingNumber} - ${option?.subject}`}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder='Select booking...'
                                        fullWidth={true}
                                        variant='outlined'
                                        style={{ fontSize: '14px' }}
                                    />
                                )}
                            />
                        </FormGroup>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button disabled={addingItem} type='submit'>
                            {!addingItem && <span>Add</span>}
                            {addingItem && (
                                <span>
                                    <CircularProgress size={16} />
                                </span>
                            )}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
};

export default AddExistingBookingDialog;
