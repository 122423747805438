import React from "react";
import { Card, CardBody } from "reactstrap";
import { MonthlyBookingTotal } from "../../../../models/dashboard.model";
import ReactECharts from "echarts-for-react";
import { EChartsOption } from "echarts";
// import { orderBy, groupBy } from "lodash";
import moment from "moment";
// import { getDateList } from "../../../../utils/date.utils";

interface Props {
  bookingData: MonthlyBookingTotal[] | null;
}

const TotalPackagesSoldChart = ({ bookingData }: Props) => {
  const months: string[] = bookingData.map((data) =>
    moment()
      .utc()
      .month(data.month - 1)
      .year(data.year)
      .format("MMM")
  );

  const bookingMonthlyCount: number[] = bookingData.map((data) => data.count);

  const chartOptions: EChartsOption = {
    title: {
      text: "Total monthly room bookings",
    },
    xAxis: {
      type: "category",
      data: months,
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: bookingMonthlyCount,
        type: "bar",
      },
    ],
  };

  return (
    <Card>
      <CardBody>
        <ReactECharts option={chartOptions} style={{ height: "500px" }} />
      </CardBody>
    </Card>
  );
};

export default TotalPackagesSoldChart;
