import { User } from "../models/user.model";

export const getPermission = (user: User | null, permission: string, level: string): boolean => {
  const userRolePermission = user?.role?.rolePermissions?.find((rolePermission) => rolePermission.permission.tag === permission);
  let hasPermission = false;
  switch (level) {
    case "read": {
      hasPermission = userRolePermission?.read || false;
      break;
    }
    case "create": {
      hasPermission = userRolePermission?.create || false;
      break;
    }
    case "update": {
      hasPermission = userRolePermission?.update || false;
      break;
    }
    case "delete": {
      hasPermission = userRolePermission?.delete || false;
      break;
    }
    default: {
      hasPermission = false;
      break;
    }
  }
  return hasPermission;
};
