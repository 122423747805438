/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  ADD_COMMENT,
  GET_COMMENT_TYPES,
  GetLeadDetails,
} from "../../../../graphql/leads.graphql";
import { useMutation, useQuery } from "@apollo/client";
import { CommentType } from "../../../../models/lead.model";
import useToast from "../../../../hooks/useToast";

interface AddCommentDialogProps {
  isOpen: boolean;
  toggleOpen: (isOpen: boolean) => void;
  userId: string;
  selectedLeadId: string;
}

type FormData = {
  type: string;
  comment: string;
};

export default function CommentDialog({
  isOpen,
  toggleOpen,
  userId,
  selectedLeadId,
}: AddCommentDialogProps) {
  const { showToast, Notification } = useToast();

  const { data } = useQuery(GET_COMMENT_TYPES);
  const [addComment, { called, error, loading, reset }] = useMutation(
    ADD_COMMENT,
    {
      refetchQueries: [{ query: GetLeadDetails }, "GetLeadDetails"],
    }
  );

  useEffect(() => {
    if (called && !loading && !error) {
      showToast("Successfully added a comment", "success");
      reset();
      resetForm();
      toggleOpen(false);
    }
    if (called && error) {
      showToast("An error has occurred, please try again", "danger");
    }
  }, [called, loading, error]);

  const typeSelect: CommentType[] = data?.commentTypes;
  const {
    register,
    handleSubmit,
    reset: resetForm,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmit = (data: FormData) => {
    const comment = {
      comment: data.comment,
      type_enum: data.type,
      user_id: userId,
      product_lead_id: selectedLeadId,
    };

    addComment({ variables: { comment } });
  };

  const handleClose = () => {
    toggleOpen(false);
  };

  return (
    <div>
      {Notification}
      <Dialog open={isOpen} onClose={handleClose} fullWidth={true}>
        <DialogTitle>Add a comment</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <FormGroup>
              <label className="form-control-label" htmlFor="currency">
                Type
              </label>
              <Select
                id="type"
                className="form-control"
                sx={{ borderRadius: "12px", padding: "0" }}
                defaultValue={""}
                inputProps={{
                  ...register("type", {
                    required: true,
                  }),
                }}
              >
                {typeSelect?.map((type: CommentType) => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.title}
                  </MenuItem>
                ))}
              </Select>
              {errors.type && (
                <span className="invalid">*This field is required</span>
              )}
            </FormGroup>
            <FormGroup className="mt-2">
              <label className="form-control-label" htmlFor="comment">
                Comment
              </label>
              <textarea
                className="form-control"
                {...register("comment", { required: true })}
                id="comment"
                placeholder="Enter a comment..."
                rows={3}
              ></textarea>
              {errors.comment && (
                <span className="invalid">*This field is required</span>
              )}
            </FormGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit" disabled={loading}>
              {loading && <CircularProgress size={16} />}
              {!loading && <span>Add</span>}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
