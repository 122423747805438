import moment from "moment";
import { BookingTimelineItemModel } from "../models/bookings.model";

// const defaultTimes: BookingTimelineItemModel[] = [
//   { start: "08:00", end: "09:00" },
//   { start: "08:00", end: "09:00" },
//   { start: "09:00", end: "10:00" },
//   { start: "10:00", end: "11:00" },
//   { start: "11:00", end: "12:00" },
//   { start: "12:00", end: "13:00" },
//   { start: "13:00", end: "14:00" },
//   { start: "14:00", end: "15:00" },
//   { start: "15:00", end: "16:00" },
//   { start: "16:00", end: "17:00" },
//   { start: "17:00", end: "18:00" },
//   { start: "18:00", end: "19:00" },
//   { start: "19:00", end: "20:00" },
//   { start: "20:00", end: "21:00" },
// ];

const defaultTimes: BookingTimelineItemModel[] = [
  { start: "08:00", end: "08:30" },
  { start: "08:30", end: "09:00" },
  { start: "09:00", end: "09:30" },
  { start: "09:30", end: "10:00" },
  { start: "10:00", end: "10:30" },
  { start: "10:30", end: "11:00" },
  { start: "11:00", end: "11:30" },
  { start: "11:30", end: "12:00" },
  { start: "12:00", end: "12:30" },
  { start: "12:30", end: "13:00" },
  { start: "13:00", end: "13:30" },
  { start: "13:30", end: "14:00" },
  { start: "14:00", end: "14:30" },
  { start: "14:30", end: "15:00" },
  { start: "15:00", end: "15:30" },
  { start: "15:30", end: "16:00" },
  { start: "16:00", end: "16:30" },
  { start: "16:30", end: "17:00" },
  { start: "17:00", end: "17:30" },
  { start: "17:30", end: "18:00" },
  { start: "18:00", end: "18:30" },
  { start: "18:30", end: "19:00" },
  { start: "19:00", end: "19:30" },
  { start: "19:30", end: "20:00" },
  { start: "20:00", end: "20:30" },
  { start: "20:30", end: "21:00" },
];

export const getTime = (time: string): number[] => {
  const timeParts = time.split(":");

  return timeParts.map((part) => {
    return parseInt(part);
  });
};

export const getDayMissingTimes = (
  date: string,
  roomBookings: any[]
): BookingTimelineItemModel[] => {
  let missingTimes: BookingTimelineItemModel[] = JSON.parse(
    JSON.stringify(defaultTimes)
  );
  roomBookings.forEach((booking: any) => {
    const startTime: string = moment(booking.from).format("HH:mm");
    const endTime: string = moment(booking.to).format("HH:mm");
    missingTimes = missingTimes.filter(
      (time) => time.end <= startTime || time.start >= endTime
    );
  });

  let fullSlots: BookingTimelineItemModel[] = [];

  if (missingTimes.length > 0) {
    fullSlots.push(missingTimes[0]);
  }

  missingTimes.forEach((time, index) => {
    const nextTime = missingTimes[index + 1];
    const currentSlot = JSON.parse(
      JSON.stringify(fullSlots[fullSlots.length - 1])
    );

    if (currentSlot) {
      if (!nextTime) {
        currentSlot.end = time.end;
      } else if (nextTime?.start === time.end) {
        currentSlot.end = nextTime.end;
        fullSlots[fullSlots.length - 1] = currentSlot;
      } else if (nextTime?.start !== time.end) {
        currentSlot.end = time.end;

        fullSlots.push(nextTime);
      }
    }
  });

  // console.log("FULL SLOTS", fullSlots);

  return fullSlots.map((time) => {
    // console.log("Minutes", getTime(time.start)[1]);
    return {
      start: moment(date)
        .hours(getTime(time.start)[0])
        .minutes(getTime(time.start)[1])
        .seconds(0)
        .milliseconds(0)
        .format("YYYY-MM-DDTHH:mm:ss"),
      end: moment(date)
        .hours(getTime(time.end)[0])
        .minutes(getTime(time.end)[1])
        .seconds(0)
        .milliseconds(0)
        .utc()
        .format("YYYY-MM-DDTHH:mm:ss"),
    };
  });
};
