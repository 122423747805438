import React from "react";
import { Card, CardHeader, Col, Row } from "reactstrap";
import EditPackageForm from "./EditPackageForm";
import RateListView from "./RateListView";
import { MenuPackage} from "../../../models/vendor.model";

interface PackageListViewProps{
  backToMenuPackages: () => void;
   selectedPackage: MenuPackage | null;
}

function PackageManageView({ backToMenuPackages, selectedPackage }: PackageListViewProps) {

  return (
    <>
      <Row className="mt-4">
        <Col md="12" lg="6">
          <Card>
            <CardHeader>
              <Row noGutters={true}>
                <button
                  className="btn btn-outline-primary btn-sm mr-4"
                  onClick={() => backToMenuPackages()}
                >
                  <i
                    className="fas fa-angle-left"
                    style={{ fontSize: "14px" }}
                  />
                  <span className="btn-inner-text">Back</span>
                </button>
                <h3 className="m-0">Update Package</h3>
              </Row>
            </CardHeader>
            <EditPackageForm selectedPackage={selectedPackage} />
          </Card>
        </Col>
        <Col md="12" lg="6">
          <RateListView selectedPackage={selectedPackage} />
        </Col>
      </Row>
    </>
  );
}

export default PackageManageView;
