/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import { Divider } from "@mui/material";
import TimeAgo from "react-timeago";
import CommentDialog from "./dialog/CommentDialog";
import { Comment } from "../../../models/lead.model";
import SweetAlert from "react-bootstrap-sweetalert";
import { GetLeadDetails, REMOVE_COMMENT } from "../../../graphql/leads.graphql";
import { useMutation } from "@apollo/client";
import useToast from "../../../hooks/useToast";

interface LeadDetailsProps {
  comments: Comment[];
  leadUserId: string;
  leadId: string;
}

export default function CommentDetails({
  comments,
  leadUserId,
  leadId,
}: LeadDetailsProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [alert, setAlert] = useState<any>(null);
  const { showToast, Notification } = useToast();

  const [removeComment, { loading: isRemoving, error: removeError, called }] =
    useMutation(REMOVE_COMMENT, {
      refetchQueries: [{ query: GetLeadDetails }, "GetLeadDetails", "GetLeads"],
    });

  useEffect(() => {
    if (removeError) {
      showToast("An error has occurred, please try again", "danger");
    }
  }, [removeError]);

  useEffect(() => {
    if (!isRemoving && called && !removeError) {
      showToast("Successfully removed  comment", "success");
    }
  }, [isRemoving, called]);

  const openDialog = () => {
    setIsOpen(true);
  };

  const displayAlert = (props: any) => {
    setAlert(
      <SweetAlert
        {...props}
        showCancel
        confirmBtnText="Continue"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => {
          removeComment({
            variables: { commentId: props.comment.id },
          });
          setAlert(null);
        }}
        onCancel={() => {
          setAlert(null);
        }}
        focusCancelBtn
      >
        This comment will be removed from the system.
      </SweetAlert>
    );
  };

  return (
    <>
      {alert}
      {Notification}
      {
        <CommentDialog
          isOpen={isOpen}
          toggleOpen={(isOpen) => {
            setIsOpen(isOpen);
          }}
          userId={leadUserId}
          selectedLeadId={leadId}
        />
      }
      <Container fluid={true}>
        <Row>
          <Col md="12" lg="8">
            <Card>
              <CardHeader>
                <Row noGutters={true}>
                  <h2>Comments</h2>
                  <button
                    className="btn btn-outline-primary btn-sm ml-4"
                    onClick={() => openDialog()}
                  >
                    Add comment
                  </button>
                </Row>
              </CardHeader>
              <CardBody>
                {comments?.map((comment) => {
                  return (
                    <CardBody key={comment.id}>
                      <Row noGutters={true}>
                        <Col>
                          <Row className="justify-content-between">
                            <h3 className="mb-0">
                              {`${comment.commentUser.name} ${comment.commentUser.surname}`}
                            </h3>
                            <p className="mb-0">
                              {<TimeAgo date={comment.createdAt} />}
                            </p>
                          </Row>
                          <Row className="p-0">
                            <p className="font-weight-normal">
                              {comment.commentType.title}
                            </p>
                          </Row>
                          <Row className="p-0">
                            <p>{comment.comment}</p>
                          </Row>
                          <div className="d-flex h-10 pb-3">
                            <div className="align-self-end ml-auto">
                              <Button
                                className="btn btn-danger btn-icon-only rounded-circle btn-sm"
                                onClick={() => {
                                  displayAlert({ warning: true, comment });
                                }}
                              >
                                <i className="fa-solid fa-trash-can"></i>
                              </Button>
                            </div>
                          </div>
                          <Divider />
                        </Col>
                      </Row>
                    </CardBody>
                  );
                })}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
