/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from '@apollo/client';
import { FormControl, LinearProgress, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import moment from 'moment';
import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardText, CardTitle, Col, Container, Row } from 'reactstrap';
import useToast from '../../../hooks/useToast';
import { Comment, Lead, LeadStatus } from '../../../models/lead.model';
import { GetLeadDetails, UPDATE_LEAD_STATUS } from '../../../graphql/leads.graphql';
import { User } from '../../../models/user.model';
import { useSelector } from 'react-redux';
import CommentDetails from './CommentDetails';

export const LeadDetails = () => {
    // let history = useHistory();
    const navigate = useNavigate();
    const params: any = useParams();
    const { showToast, Notification } = useToast();
    const user: User | null = useSelector((state: any) => state.user.userInfo);

    const { loading, data } = useQuery(GetLeadDetails, {
        variables: { leadId: params.id },
    });

    const [updateLeadStatus, { called: updatedStatus, loading: isUpdatingStatus, reset, error }] = useMutation(
        UPDATE_LEAD_STATUS,
        {
            // refetchQueries: [{ query: GetLeadDetails }, "GetLeadDetails", "GetLeads"],
            refetchQueries: ['GetLeadDetails', 'GetLeads'],
        },
    );

    useEffect(() => {
        if (updatedStatus && !isUpdatingStatus && !error) {
            reset();
            showToast('Successfully updated lead status', 'success');
        } else if (error) {
            showToast('An error has occured, please try again', 'danger');
        }
    }, [updatedStatus, isUpdatingStatus, error]);

    const lead: Lead = data?.lead;
    const comments: Comment[] = data?.lead.productLeadComments;

    const handleStatusChange = useCallback(
        (value: SelectChangeEvent) => {
            console.log('STATUS CHANGE', value);
            console.log('LEAD ID', lead.id);
            updateLeadStatus({
                variables: { leadId: lead.id, status: value.target.value },
            });
        },
        [lead],
    );

    if (loading) {
        return <LinearProgress />;
    }

    const statusses: LeadStatus[] = data.leadStatuses;

    const userId: any = user?.id;

    return (
        <>
            {Notification}
            <Container fluid={true}>
                <Row className='mt-4'>
                    <Col md='12' lg='8'>
                        <Card>
                            <CardHeader>
                                <Row noGutters={true}>
                                    <button
                                        className='btn btn-outline-primary btn-sm mr-4'
                                        onClick={(e) => navigate(-1)}>
                                        <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                                        <span className='btn-inner-text'>Back</span>
                                    </button>

                                    <h1 className='m-0'>Lead Details</h1>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    {lead.category !== 'contact_us' && lead.category !== 'internal' && (
                                        <Col md='12' lg='6'>
                                            <CardTitle>Company Information</CardTitle>
                                            <CardText>Date: {moment(lead.createdAt).format('yyyy-MM-DD')}</CardText>
                                            <CardText> Company: {lead.companyName}</CardText>
                                            <CardText> Industry: {lead.industry}</CardText>
                                            <CardText>
                                                {' '}
                                                Special Needs:{' '}
                                                {lead?.specialNeeds?.toString() === 'true' ? 'Yes' : 'No'}
                                            </CardText>
                                            <CardText> Office Environment: {lead.officeEnvironment}</CardText>
                                            <CardText> Total People: {lead.totalCapacity}</CardText>
                                        </Col>
                                    )}
                                    <Col md='12' lg='6'>
                                        <CardTitle>Contact Information</CardTitle>
                                        <CardText> Name: {lead.companyName}</CardText>
                                        <CardText> Email: {lead.contactEmail}</CardText>
                                        <CardText> Contact Number: {lead.contactNumber}</CardText>
                                        {lead.contactTime && <CardText> Time: {lead.contactTime}</CardText>}
                                        <CardText>
                                            Status:{' '}
                                            <FormControl variant='standard' className='ml-1'>
                                                <Select
                                                    className='bare-dropdown'
                                                    labelId='demo-simple-select-label'
                                                    id='demo-simple-select'
                                                    value={lead.status.value}
                                                    label='Status'
                                                    onChange={handleStatusChange}>
                                                    {statusses.map((status) => (
                                                        <MenuItem key={status.value} value={status.value}>
                                                            {status.title}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </CardText>
                                    </Col>
                                    {(lead.message && lead.category === 'contact_us') ||
                                        (lead.message && lead.category === 'internal' && (
                                            <Col md='12' lg='6'>
                                                <CardText>Message: {lead.message}</CardText>
                                            </Col>
                                        ))}
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {<CommentDetails comments={comments} leadUserId={userId} leadId={lead.id} />}
        </>
    );
};
