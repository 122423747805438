import React, { useEffect, useState } from 'react';
import { Container, Row } from 'reactstrap';
import { CONFERENCE_DAILY_BOOKING_DASHBOARD_DATA } from '../../../graphql/bookings.graphql';
import { useQuery } from '@apollo/client';
import { LinearProgress } from '@mui/material';
import useToast from '../../../hooks/useToast';
import { ConferenceBookingInfo } from '../../../models/bookings.model';
import moment from 'moment';
import ConferenceDailyIncomeGraph from './components/ConferenceDailyIncomeGraph';

export interface DateRange {
    start: string;
    end: string;
}

export default function DailyConferenceIncomeView() {
    const { data, loading, error, refetch } = useQuery(CONFERENCE_DAILY_BOOKING_DASHBOARD_DATA, {
        variables: {
            from: moment().subtract(2, 'weeks').format('YYYY-MM-DD'),
            to: moment().add(30, 'days').format('YYYY-MM-DD'),
        },
        fetchPolicy: 'network-only',
    });
    const { showToast, Notification } = useToast();

    const [dateRange, setDateRange] = useState<DateRange>({
        start: moment().subtract(2, 'weeks').format('YYYY-MM-DD'),
        end: moment().add(30, 'days').format('YYYY-MM-DD'),
    });

    useEffect(() => {
        if (dateRange) {
            refetch({
                from: moment(dateRange.start).format('YYYY-MM-DD'),
                to: moment(dateRange.end).format('YYYY-MM-DD'),
            });
            // setForceRerender(true);
        }
    }, [dateRange]);

    useEffect(() => {
        if (error) {
            showToast('An error has occurred while trying to load this page', 'danger');
        }
    }, [error]);

    if (loading) {
        return <LinearProgress />;
    }
    const chartInfo: ConferenceBookingInfo[] = data?.incomeData || [];
    return (
        <>
            {Notification}
            <Container fluid>
                <h1 className='mt-4 mb-4'>Daily Income Dashboard</h1>

                <ConferenceDailyIncomeGraph
                    loading={loading}
                    onDateRangeChange={setDateRange}
                    dateRange={dateRange}
                    chartInfo={chartInfo}
                />
            </Container>
        </>
    );
}
