import { gql } from '@apollo/client';

export const GET_BOOKINGS = gql`
    query GetBookings {
        booking(
            where: {
                _and: [
                    { deleted_at: { _is_null: true } }
                    { status_enum: { _nin: [expired, transferred, pending_review] } }
                ]
            }
            order_by: { time_from: desc }
        ) {
            id
            from: time_from
            to: time_to
            subject
            reference
            bookingReference: booking_number
            meetingRoomItem: meeting_room_item {
                id
                name
            }
            guests: booking_guests {
                email
            }
            status {
                value
                title
            }
            client: user_account {
                id
                individualName: individual_name
                companyName: company_name
                user {
                    id
                    name
                    surname
                }
            }
        }
    }
`;

export const GET_BOOKING = gql`
    query GetBooking($id: uuid!) {
        booking: booking_by_pk(id: $id) {
            id
            from: time_from
            to: time_to
            subject
            reference
            notes: additional_notes
            meetingRoomItem: meeting_room_item {
                id
                name
            }
            status {
                value
                title
            }
            client: user_account {
                id
                companyName: company_name
                user {
                    id
                    name
                    surname
                }
            }
            guests: booking_guests(order_by: { email: asc }) {
                id
                email
            }
        }
        status: booking_status {
            title
            value
        }
        meetingRoomItems: meeting_room_item(order_by: { name: asc }) {
            id
            name
        }
    }
`;

export const UPDATE_BOOKING = gql`
    mutation UpdateBooking($id: uuid!, $booking: booking_set_input!, $guests: [booking_guest_insert_input!]!) {
        update_booking_by_pk(pk_columns: { id: $id }, _set: $booking) {
            id
        }
        insert_booking_guest(
            objects: $guests
            on_conflict: { constraint: booking_guest_booking_id_email_key, update_columns: [] }
        ) {
            affected_rows
        }
    }
`;

export const REMOVE_BOOKING_GUEST = gql`
    mutation RemoveBookingGuest($guestId: uuid!) {
        delete_booking_guest_by_pk(id: $guestId) {
            id
        }
    }
`;

export const REMOVE_BOOKING_CHECKLIST_ADD_ON = gql`
    mutation DeletedBookingChecklistAddOn($id: uuid!) {
        delete_booking_add_on_by_pk(id: $id) {
            id
        }
    }
`;

export const REMOVE_BOOKING_MENU_PACKAGE = gql`
    mutation RemoveBookingMenuItem($id: uuid!) {
        delete_booking_menu_package_by_pk(id: $id) {
            id
        }
    }
`;

export const SEARCH_EXISTING_BOOKINGS = gql`
    query SearchExistingBookingLink($query: String!, $accountId: uuid!) {
        bookings: booking(
            where: {
                _and: [
                    { deleted_at: { _is_null: true } }
                    { account_id: { _eq: $accountId } }
                    { status_enum: { _in: [completed, confirmed, pending, active] } }
                    {
                        _or: [
                            { subject: { _ilike: $query } }
                            { booking_number: { _ilike: $query } }
                            { reference: { _ilike: $query } }
                        ]
                    }
                ]
            }
        ) {
            id
            price
            subject
            bookingNumber: booking_number
            reference
            quantity
            quotedPrice: quoted_price
            totalPrice: total_price
            discount
            unit
        }
    }
`;

export const GET_BOOKING_DETAILS = gql`
    query GetBooking($id: uuid!) {
        booking: booking_by_pk(id: $id) {
            id
            from: time_from
            to: time_to
            subject
            price
            includeCalendarInvites: include_calendar_invites
            voucherId: voucher_id
            voucherCode: voucher_code
            hasCustomPricing: has_custom_pricing
            requiresVerification: requires_verification
            client: user_account {
                id
                companyName: company_name
                individualName: individual_name
                includeCalendarInvites: include_calendar_invites
                members: team_members(where: { send_communication: { _eq: true } }) {
                    id
                    user {
                        name
                        surname
                    }
                }
            }
            accessCode: access_code
            sendCommunication: send_communications
            configurationId: configuration_id
            totalPrice: total_price
            quotedPrice: quoted_price
            bookingResources: booking_after_hour_resources {
                id
                bookingId: booking_id
                name
                quantity
                rate
                resourceId: resource_id
                totalPrice: total_price
            }
            nominatedMember: nominated_members {
                member {
                    id
                    user {
                        name
                        surname
                    }
                }
            }
            roomExtras: room_extras {
                id
                quantity
                totalPrice: total_price
                rate
                roomExtra: room_extra {
                    id
                    title
                }
            }
            menuPackages: menu_packages {
                id
                unitPrice: price
                vendorName: vendor_name
                totalPrice: total_price
                menuPackageName: menu_package_name
                menuPackageId: menu_package_id
                featuredImage: featured_image
                vendorId: vendor_id
                quantity
            }
            quantity
            checklistAddons: checklist_add_ons {
                id
                bookingId: booking_id
                checklistItemId: checklist_item_id
            }
            discount
            unit
            isLongTerm: is_long_term
            client: user_account {
                id
            }
            billingPeriod: billing_period
            additionalNotes: additional_notes
            meetingRoomItem: meeting_room_item {
                id
            }
            guests: booking_guests {
                id
                email
            }
            status {
                value
                title
            }
            recurringSchedule: recurring_schedule {
                id
                period
                isRecurring: is_recurring
                endDate: ends_on
                type {
                    value
                    title
                }
            }
        }
        rooms: meeting_room_item(
            where: {
                deleted_at: { _is_null: true }
                status_enum: { _neq: "archived" }
                meeting_room: { deleted_at: { _is_null: true }, status_enum: { _eq: "active" } }
            }
            order_by: { name: asc }
        ) {
            id
            name
            checklistAddOns: checklist_add_ons(where: { deleted_at: { _is_null: true } }) {
                id
                checkListItem: meeting_room_checklist_item {
                    id
                    name
                }
            }
            itemConfigurations: meeting_room_item_configurations(where: { deleted_at: { _is_null: true } }) {
                id
                configurations: meeting_room_configurations {
                    id
                    image: image_path
                    name
                }
            }
            status {
                value
                title
            }
            rules {
                id
                allowRepeating: allow_repeating
                allowLongTerm: allow_long_term
                allowShortBooking: allow_short_booking
                overflowBooking: overflow_booking
            }
            meetingRoom: meeting_room {
                id
                name
                rates {
                    price
                    type: rate_type {
                        value
                        title
                    }
                }
            }
        }
        status: booking_status {
            title
            value
        }
    }
`;

export const REMOVE_BOOKING_ROOM_EXTRAS = gql`
    mutation RemoveBookingRoomExtra($id: uuid!) {
        roomExtra: delete_booking_room_extra_by_pk(id: $id) {
            id
        }
    }
`;

export const REMOVE_BOOKING_RESOURCE = gql`
    mutation RemoveBookingResource($id: uuid!) {
        delete_booking_after_hour_resource_by_pk(id: $id) {
            id
        }
    }
`;

export const REMOVE_NOMINATED_MEMBER = gql`
    mutation RemoveNominatedMember($memberId: uuid!) {
        delete_nominated_member(where: { member_id: { _eq: $memberId } }) {
            returning {
                id
            }
        }
    }
`;

export const SEND_EMAIL_TO_MEMBERS = gql`
    mutation SendEmailsToMembers($bookingId: uuid!) {
        sendEmail: send_email_to_nominated_members(bookingId: $bookingId) {
            message
        }
    }
`;

export const GET_FULL_CALENDAR_BOOKINGS = gql`
    query GetFullCalendarBookings($start: timestamp, $end: timestamp) {
        bookings: booking(
            where: {
                _and: {
                    time_from: { _gte: $start }
                    time_to: { _lte: $end }
                    status_enum: { _nin: [expired, cancelled] }
                    deleted_at: { _is_null: true }
                }
            }
        ) {
            id
            from: time_from
            to: time_to
            subject
            status {
                value
                title
            }
            client: user_account {
                id
                individualName: individual_name
                companyName: company_name
                user {
                    id
                    name
                    surname
                }
            }
            meetingRoomItem: meeting_room_item {
                id
                name
            }
        }
        rooms: meeting_room_item(
            where: {
                deleted_at: { _is_null: true }
                status_enum: { _neq: "archived" }
                meeting_room: { deleted_at: { _is_null: true }, status_enum: { _eq: "active" } }
            }
            order_by: { name: asc }
        ) {
            id
            name
            checklistAddOns: checklist_add_ons(where: { deleted_at: { _is_null: true } }) {
                id
                checkListItem: meeting_room_checklist_item {
                    id
                    name
                }
            }
            itemConfigurations: meeting_room_item_configurations(where: { deleted_at: { _is_null: true } }) {
                id
                configurations: meeting_room_configurations {
                    id
                    image: image_path
                    name
                }
            }
            status {
                value
                title
            }
            rules {
                id
                allowRepeating: allow_repeating
                allowLongTerm: allow_long_term
                allowShortBooking: allow_short_booking
                overflowBooking: overflow_booking
            }
            meetingRoom: meeting_room {
                id
                name
                roomExtras: room_extras(where: { deleted_at: { _is_null: true } }) {
                    id
                    price
                    title
                    description
                    unit: room_extra_unit {
                        title
                        value
                    }
                }
                rates {
                    price
                    type: rate_type {
                        value
                        title
                    }
                }
            }
        }
        statusList: booking_status {
            title
            value
        }
    }
`;

export const CONFERENCE_BOOKING_DASHBOARD_DATA = gql`
    query GetBookingDashBoardData($from: date, $to: date, $inSixtyDays: date!) {
        incomeData: actual_vs_budget_conference_income(
            where: { _and: [{ g_date: { _gte: $from } }, { g_date: { _lte: $to } }] }
        ) {
            date: g_date
            expectedIncome: expected_amount
            actualIncome: actual_amount
            potentialIncome: potential_income
        }
        sixtyDaysIncome: actual_vs_budget_conference_income(where: { g_date: { _eq: $inSixtyDays } }, limit: 1) {
            date: g_date
            expectedIncome: expected_amount
            actualIncome: actual_amount
        }
    }
`;

export const CONFERENCE_DAILY_BOOKING_DASHBOARD_DATA = gql`
    query GetDailyActualVsExpectedIncome($from: date!, $to: date!) {
        incomeData: daily_actual_vs_expected_income(
            where: { _and: [{ d_date: { _gte: $from } }, { d_date: { _lte: $to } }] }
        ) {
            date: d_date
            expectedIncome: expected_income
            actualIncome: actual_income
            roomCapacity: room_capacity
            potentialIncome: potential_income
        }
    }
`;
