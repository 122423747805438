import React from "react";
import UserAvatar from "../../../../components/UserAvatar";
import { TaskEvent } from "../../../../models/tasks.model";
import TaskCommentCard from "./TaskCommentCard";
import TaskEventItem from "./TaskEventItem";

interface ActivityTimeLieItemProps {
  taskEvent?: TaskEvent;
  userId: string;
  isLastChild?: boolean;
}

const ActivityTimeLieItem = ({
  taskEvent,
  userId,
  isLastChild,
}: ActivityTimeLieItemProps) => {
  if (taskEvent?.comment) {
    return (
      <>
        <div className="row mt-4">
          <div className="col-1">
            <UserAvatar
              name={`${taskEvent?.user?.name} ${taskEvent?.user?.surname}`}
            />
            {!isLastChild && <div className="vertical-line"></div>}
          </div>
          <div className="col-11">
            <TaskCommentCard userId={userId} comment={taskEvent?.comment} />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="mt-4">
        <TaskEventItem
          userId={userId}
          taskEvent={taskEvent}
          isLastChild={isLastChild}
        />
      </div>
    </>
  );
};

export default ActivityTimeLieItem;
