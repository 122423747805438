import React, { useEffect, useState } from 'react';

import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table,
    UncontrolledTooltip,
} from 'reactstrap';
import { getPermission } from '../../../../utils/user.utils';
import BasicMenu from '../../../../components/Menu';
import { useSelector } from 'react-redux';
import { User } from '../../../../models/user.model';
import { LocationSpace } from '../../../../models/location.model';
import _ from 'lodash';
import LocationRoomAddForm from './LocationRoomAddForm';
import LocationRoomDetails from './LocationRoomDetails';
import { GET_PORTAL_LOCATION, REMOVE_LOCATION_SPACE } from '../../../../graphql/locations.graphql';
import { useMutation } from '@apollo/client';
import SweetAlert from 'react-bootstrap-sweetalert';
import useToast from '../../../../hooks/useToast';

interface RoomLocationViewProps {
    rooms: LocationSpace[];
    floors: LocationSpace[];
}

export default function RoomLocationView({ rooms, floors }: RoomLocationViewProps) {
    const user: User | null = useSelector((state: any) => state.user.userInfo);
    const [page, setPage] = useState<number>(0);
    const [alert, setAlert]: any = React.useState(null);
    const { showToast, Notification } = useToast();
    const [searchKey, setSearchKey] = useState<string>('');
    const [rowPerPage, setRowPerPage] = useState<number>(10);
    const [sortFiled, setSortField] = useState<string>('name');
    const [sortDirection, setSortDirection] = useState<string>('asc');
    const [showAddForm, setShowAddForm] = useState<boolean>(false);
    const [roomId, setRoomId] = useState<string>();
    const [showDetailsForm, setShowDetailsForm] = useState<boolean>(false);
    const sortBy: any = (locationSpaces: LocationSpace[], field: string, direction: string) => {
        const orderedBookings = new Map([
            ['name', _.orderBy(locationSpaces, (space) => space.name, [direction === 'asc' ? 'asc' : 'desc'])],
            [
                'status',
                _.orderBy(locationSpaces, (space) => space.status.title, [direction === 'asc' ? 'asc' : 'desc']),
            ],
            ['floor', _.orderBy(locationSpaces, (space) => space.parent.name, [direction === 'asc' ? 'asc' : 'desc'])],
        ]);
        return orderedBookings.get(field);
    };

    const [removeLocationRoom, { data, error }] = useMutation(REMOVE_LOCATION_SPACE, {
        refetchQueries: [GET_PORTAL_LOCATION],
    });

    const nextPage = () => {
        setPage(page + 1);
    };

    useEffect(() => {
        if (data) {
            showToast('Successfully removed room', 'success');
        }
    }, [data]);

    useEffect(() => {
        if (error) {
            showToast('An error has occurred while trying to remove this room', 'danger');
        }
    }, [error]);

    const displayAlert = (props: any) => {
        setAlert(
            <SweetAlert
                {...props}
                showCancel
                confirmBtnText='Continue'
                confirmBtnBsStyle='danger'
                title='Are you sure?'
                onConfirm={() => {
                    removeLocationRoom({
                        variables: {
                            id: props.space.id,
                        },
                    });
                    setAlert(null);
                }}
                onCancel={() => {
                    setAlert(null);
                }}
                focusCancelBtn>
                This room will be removed.
            </SweetAlert>,
        );
    };

    const backToListView = () => {
        if (showAddForm) setShowAddForm(false);
        if (showDetailsForm) setShowDetailsForm(false);
    };

    const openDetailsForm = (roomId: string) => {
        setRoomId(roomId);
        setShowDetailsForm(true);
    };

    const previousPage = () => {
        setPage(page - 1);
    };

    const getRowValue = (value: string) => {
        setRowPerPage(parseInt(value));
    };

    const locations: LocationSpace[] = rooms || [];
    let filteredLocations = locations.filter((location) => {
        if (searchKey === '') {
            return location;
        }

        return (
            location.name.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            location.status.title.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            location.parent.name.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase())
        );
    });

    if (floors.length === 0) {
        return (
            <Card className='mt-4'>
                <CardBody>
                    <h3>Add a floor before adding rooms</h3>
                </CardBody>
            </Card>
        );
    }

    if (showAddForm) {
        return <LocationRoomAddForm floors={floors} goBackToListView={backToListView} />;
    }

    if (showDetailsForm) {
        return <LocationRoomDetails roomId={roomId} floors={floors} goBackToListView={backToListView} />;
    }

    filteredLocations = sortBy(filteredLocations, sortFiled, sortDirection);
    return (
        <>
            {alert}
            {Notification}
            <div className='mt-4'>
                <Card>
                    <CardHeader className='border-0'>
                        <Row>
                            <Col xs='6'>
                                <h3 className='mb-0'>Rooms</h3>
                            </Col>
                            {getPermission(user, 'locations', 'create') && (
                                <Col className='text-right' xs='6'>
                                    <Button
                                        onClick={() => {
                                            setShowAddForm(true);
                                        }}
                                        className='btn btn-round btn-dark btn-sm'
                                        color='default'
                                        id='tooltip969372949'>
                                        <span className='btn-inner--text'>Add Room</span>
                                    </Button>
                                    <UncontrolledTooltip delay={0} target='tooltip969372949'>
                                        Add Room
                                    </UncontrolledTooltip>
                                </Col>
                            )}
                        </Row>
                        <Row className='mt-4'>
                            <Col>
                                <Input
                                    className='w-25'
                                    value={searchKey}
                                    placeholder='Search keyword...'
                                    onChange={(e) => {
                                        setSearchKey(e.target.value);
                                    }}
                                />
                            </Col>
                        </Row>
                    </CardHeader>
                    <Table className='align-items-center table-flush' responsive>
                        <thead className='thead-light'>
                            <tr>
                                <th
                                    onClick={() => {
                                        setSortField('name');
                                        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                    }}
                                    className='sort'
                                    data-sort='name'
                                    scope='col'>
                                    Name
                                </th>
                                <th
                                    onClick={() => {
                                        setSortField('status');
                                        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                    }}
                                    className='sort'
                                    data-sort='streetAddress'
                                    scope='col'>
                                    Status
                                </th>
                                <th
                                    onClick={() => {
                                        setSortField('floor');
                                        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                    }}
                                    className='sort'
                                    data-sort='streetAddress'
                                    scope='col'>
                                    Floor
                                </th>

                                <th scope='col'></th>
                            </tr>
                        </thead>
                        <tbody className='list'>
                            {filteredLocations.slice(page * rowPerPage, page * rowPerPage + rowPerPage).map((space) => {
                                return (
                                    <tr
                                        key={space.id}
                                        onClick={() => {
                                            openDetailsForm(space.id);
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                        }}>
                                        <td>{`${space.name}`}</td>
                                        <td>{space.status.title}</td>
                                        <td>{space.parent.name || ''}</td>
                                        <td className='text-right'>
                                            {getPermission(user, 'locations', 'update') && (
                                                <Button
                                                    onClick={() => {
                                                        openDetailsForm(space.id);
                                                    }}
                                                    className='btn btn-info btn-icon-only rounded-circle btn-sm'>
                                                    <i className='fa-solid fa-pencil'></i>
                                                </Button>
                                            )}
                                            {getPermission(user, 'locations', 'delete') && (
                                                <Button
                                                    className='btn btn-danger btn-icon-only rounded-circle btn-sm'
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        displayAlert({ warning: true, space });
                                                    }}>
                                                    <i className='fa-solid fa-trash-can'></i>
                                                </Button>
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                    <CardFooter className='py-4'>
                        <Row>
                            <div className='col-4 d-flex'>
                                <span style={{ fontSize: '12px' }}>Rows per page:</span>
                                <BasicMenu
                                    getValue={getRowValue}
                                    options={['10', '20', '100']}
                                    value={rowPerPage.toString()}
                                />
                            </div>

                            <div className='col-4 d-flex'>
                                <span className='min-text'>Page:</span>
                                <span className='min-text pl-2'>
                                    {page + 1} of {Math.ceil(filteredLocations.length / rowPerPage)}
                                </span>
                            </div>
                            <div className='col-4 d-flex'>
                                <Pagination>
                                    <PaginationItem className={page <= 0 ? 'disabled' : ''}>
                                        <PaginationLink onClick={(e) => previousPage()}>
                                            <i className='fas fa-angle-left' />
                                            <span className='sr-only'>Previous</span>
                                        </PaginationLink>
                                    </PaginationItem>
                                    <PaginationItem
                                        className={
                                            page >= Math.ceil(filteredLocations.length / rowPerPage) - 1
                                                ? 'disabled'
                                                : ''
                                        }>
                                        <PaginationLink onClick={(e) => nextPage()}>
                                            <i className='fas fa-angle-right' />
                                            <span className='sr-only'>Previous</span>
                                        </PaginationLink>
                                    </PaginationItem>
                                </Pagination>
                            </div>
                        </Row>
                    </CardFooter>
                </Card>
            </div>
        </>
    );
}
