import React, { useEffect, useRef } from 'react';
import CalendarView from './components/CalendarView';
import { Card, CardBody, CardTitle, Container, Row, Col } from 'reactstrap';
import AlternativeHeader from '../../../components/Footers/Headers/AlternativeHeader';
import { useSelector } from 'react-redux';
import { User } from '../../../models/user.model';
import { getPermission } from '../../../utils/user.utils';
import { GET_DASHBOARD_DATA } from '../../../graphql/dashboard.graphql';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { LinearProgress } from '@mui/material';
import { DashboardData } from '../../../models/dashboard.model';
import { BookingEvent, BookingStatus } from '../../../models/bookings.model';
import TotalPackagesSoldChart from './components/TotalPackagesSoldChart';
import TotalPackageAvailability from './components/TotalPackageAvailability';
import { MeetingRoomItem } from '../../../models/meeting.room.model';

declare const window: any;

const Alternative = () => {
    const now = useRef(new Date());

    const { loading, data, error, called } = useQuery<DashboardData>(GET_DASHBOARD_DATA, {
        variables: {
            startDate: moment(now.current)
                .subtract(7, 'days')

                .utc(true)
                .toISOString(),
            endDate: moment(now.current).endOf('day').utc(true).toISOString(),
            todayStart: moment(now.current).startOf('day').utc(true).toISOString(),
            todayEnd: moment(now.current).endOf('day').utc(true).toISOString(),
        },
    });
    const user: User | null = useSelector((state: any) => state.user.userInfo);

    useEffect(() => {
        if (called && data && window.Chart) {
            // parseOptions(Chart, chartOptions());
            console.log('Query called', called);
            console.log('Data pulled', data);
        }
    }, [called, data]);

    useEffect(() => {
        if (error) {
            console.log('ERROR', error);
        }
    }, [error]);

    if (loading) {
        return <LinearProgress />;
    }

    const clientsPermission = getPermission(user, 'clients', 'read');
    const bookingsPermission = getPermission(user, 'bookings', 'read');

    const getIsAllDayBooking = (start: string, end: string): boolean => {
        const hours = moment(end).diff(moment(start), 'hours');
        return hours >= 8;
    };

    const getBookingClassName = (status: BookingStatus): string => {
        switch (status.value) {
            case 'active':
                return 'bg-green';
            case 'complete':
                return 'bg-green';
            case 'pending':
                return 'bg-orange';
            case 'confirmed':
                return 'bg-info';
            default:
                return 'bg-info';
        }
    };

    const getBookingEvents = (): BookingEvent[] => {
        if (data?.bookings) {
            const { bookings } = data;
            return bookings.map((booking) => {
                const event: BookingEvent = {
                    id: booking.id,
                    title: booking?.client?.companyName ?? booking?.client?.individualName,
                    description: booking.subject,
                    allDay: getIsAllDayBooking(booking.from, booking.to),
                    className: getBookingClassName(booking.status),
                    start: new Date(booking.from),
                    roomName: booking.meetingRoomItem?.name,
                };
                return event;
            });
        }
        return [];
    };
    // const clients: Client[] = data?.clients;
    const statusList: BookingStatus[] = data?.statusList;
    const rooms: MeetingRoomItem[] = data?.rooms;

    return (
        <div>
            <AlternativeHeader />
            <Container className='mt-6' fluid>
                <Row>
                    {clientsPermission && (
                        <Col md='6' xl='3'>
                            <Card className='card-stats'>
                                <CardBody>
                                    <Row>
                                        <div className='col'>
                                            <CardTitle tag='h5' className='text-uppercase text-muted mb-0'>
                                                Total clients
                                            </CardTitle>
                                            <span className='h2 font-weight-bold mb-0'>
                                                {data?.totalClients.aggregate.count || 0}
                                            </span>
                                        </div>
                                        <Col className='col-auto'>
                                            <div className='icon icon-shape bg-gradient-red text-white rounded-circle shadow'>
                                                <i className='fa-solid fa-user'></i>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    )}
                    {bookingsPermission && (
                        <>
                            <Col md='6' xl='3'>
                                <Card className='card-stats'>
                                    <CardBody>
                                        <Row>
                                            <div className='col'>
                                                <CardTitle tag='h5' className='text-uppercase text-muted mb-0'>
                                                    Bookings today
                                                </CardTitle>
                                                <span className='h2 font-weight-bold mb-0'>
                                                    {data?.totalBookingsToday.aggregate.count || 0}
                                                </span>
                                            </div>
                                            <Col className='col-auto'>
                                                <div className='icon icon-shape bg-gradient-orange text-white rounded-circle shadow'>
                                                    <i className='fa-solid fa-book'></i>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md='6' xl='3'>
                                <Card className='card-stats'>
                                    <CardBody>
                                        <Row>
                                            <div className='col'>
                                                <CardTitle tag='h5' className='text-uppercase text-muted mb-0'>
                                                    Upcoming bookings
                                                </CardTitle>
                                                <span className='h2 font-weight-bold mb-0'>
                                                    {data?.upcomingBookings.aggregate.count || 0}
                                                </span>
                                            </div>
                                            <Col className='col-auto'>
                                                <div className='icon icon-shape bg-gradient-green text-white rounded-circle shadow'>
                                                    <i className='fa fa-arrow-up'></i>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md='6' xl='3'>
                                <Card className='card-stats'>
                                    <CardBody>
                                        <Row>
                                            <div className='col'>
                                                <CardTitle tag='h5' className='text-uppercase text-muted mb-0'>
                                                    All time bookings
                                                </CardTitle>
                                                <span className='h2 font-weight-bold mb-0'>
                                                    {data?.totalBookings.aggregate.count || 0}
                                                </span>
                                            </div>
                                            <Col className='col-auto'>
                                                <div className='icon icon-shape bg-gradient-primary text-white rounded-circle shadow'>
                                                    <i className='ni ni-chart-bar-32' />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </>
                    )}
                </Row>
                {bookingsPermission && (
                    <Row>
                        <Col md='12' lg='8'>
                            <CalendarView
                                bookings={getBookingEvents()}
                                bookingData={data?.bookings}
                                // clients={clients}
                                statusList={statusList}
                                rooms={rooms}
                            />
                        </Col>
                    </Row>
                )}
                {user?.role.tag === 'admin' && (
                    <Row>
                        <Col>
                            <TotalPackagesSoldChart bookingData={data?.totalBookedRoomsPerMonth || []} />
                        </Col>
                        <Col>
                            <TotalPackageAvailability totalPackages={data?.totalBookedPackagesPerMonth || []} />
                        </Col>
                    </Row>
                )}
            </Container>
        </div>
    );
};

export default Alternative;
