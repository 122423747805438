/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
import RoomExtraDetailForm from "./RoomExtraDetailsForm";
import BasicMenu from "../../../../../components/Menu";
import useToast from "../../../../../hooks/useToast";
import { RoomExtra } from "../../../../../models/room.extra.model";
import _ from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";
import { useMutation } from "@apollo/client";
import {
  GET_MEETING_ROOM,
  REMOVE_ROOM_EXTRA,
} from "../../../../../graphql/meeting-rooms.graphql";

interface RoomExtraListViewProps {
  roomExtras: RoomExtra[];
  displayAddForm: () => void;
}

const RoomExtraListView = ({
  roomExtras,
  displayAddForm,
}: RoomExtraListViewProps) => {
  const [alert, setAlert] = useState<any>(null);
  const { showToast, Notification } = useToast();
  const [page, setPage] = useState<number>(0);
  const [rowPerPage, setRowsPerPage] = useState<number>(10);
  const [filterKey, setFilterKey] = useState<string>("");
  const [sortDirection, setSortDirection] = useState<string>("desc");
  const [sortFiled, setSortField] = useState<string>("title");
  const [displayDetailForm, setDisplayDetailForm] = useState<boolean>(false);
  const [roomExtra, setRoomExtra] = useState<any>();
  const [removeRoomExtra, { data: deletedRoomExtra, error }] = useMutation(
    REMOVE_ROOM_EXTRA,
    {
      refetchQueries: [{ query: GET_MEETING_ROOM }, "GetMeetingRoom"],
    }
  );

  const sortBy: any = (
    items: RoomExtra[],
    field: string,
    direction: string
  ) => {
    const orderedBookings = new Map([
      [
        "title",
        _.orderBy(items, (item) => item?.title, [
          direction === "asc" ? "asc" : "desc",
        ]),
      ],
      [
        "status",
        _.orderBy(items, (item) => item?.status?.title, [
          direction === "asc" ? "asc" : "desc",
        ]),
      ],
      [
        "unit",
        _.orderBy(items, (item) => item.unit.title, [
          direction === "asc" ? "asc" : "desc",
        ]),
      ],
      [
        "price",
        _.orderBy(items, (item) => item.price, [
          direction === "asc" ? "asc" : "desc",
        ]),
      ],
    ]);
    return orderedBookings.get(field);
  };

  useEffect(() => {
    if (error)
      showToast(
        "An error has occurred while trying to remove this room extra",
        "danger"
      );

    if (deletedRoomExtra) {
      showToast("Successfully removed the room extra", "success");
    }
  }, [error, deletedRoomExtra]);

  const getRowValue = (value: string) => {
    setRowsPerPage(parseInt(value));
  };
  const nextPage = () => {
    setPage(page + 1);
  };

  const displayAlert = (props: any) => {
    setAlert(
      <SweetAlert
        {...props}
        showCancel
        confirmBtnText="Continue"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => {
          removeRoomExtra({
            variables: {
              id: props.extra.id,
            },
          });
          setAlert(null);
        }}
        onCancel={() => {
          setAlert(null);
        }}
        focusCancelBtn
      >
        This meeting room will be removed.
      </SweetAlert>
    );
  };

  const previousPage = () => {
    setPage(page - 1);
  };

  let filteredItems: RoomExtra[] = roomExtras.filter((item) => {
    if (filterKey === "") {
      return item;
    }

    return (
      item.status.title
        .toLocaleLowerCase()
        .includes(filterKey.toLocaleLowerCase()) ||
      item.title.toLocaleLowerCase().includes(filterKey.toLocaleLowerCase()) ||
      item.unit.title
        .toLocaleLowerCase()
        .includes(filterKey.toLocaleLowerCase())
    );
  });

  filteredItems = sortBy(filteredItems, sortFiled, sortDirection);

  if (displayDetailForm) {
    return (
      <RoomExtraDetailForm
        roomExtra={roomExtra}
        backToListView={() => setDisplayDetailForm(false)}
      />
    );
  }

  return (
    <>
      {alert}
      {Notification}
      <Card className="mt-4">
        <Row>
          <div className="col">
            <CardHeader className="border-0">
              <Row>
                <Col xs="6">
                  <h3 className="mb-0">Room extras</h3>
                </Col>
                <Col className="text-right" xs="6">
                  <button
                    onClick={() => displayAddForm()}
                    className="btn btn-dark btn-sm"
                  >
                    Add room extra
                  </button>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col xs="6">
                  <Input
                    className="form-control w-50"
                    type="text"
                    placeholder="Search keyword..."
                    onChange={(e) => {
                      setFilterKey(e.target.value);
                      setPage(0);
                    }}
                  />
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th
                      onClick={() => {
                        setSortField("title");
                        setSortDirection(
                          sortDirection === "asc" ? "desc" : "asc"
                        );
                      }}
                      className="sort"
                      data-sort="title"
                      scope="col"
                    >
                      Title
                    </th>
                    <th
                      onClick={() => {
                        setSortField("status");
                        setSortDirection(
                          sortDirection === "asc" ? "desc" : "asc"
                        );
                      }}
                      className="sort"
                      data-sort="status"
                      scope="col"
                    >
                      Status
                    </th>
                    <th
                      onClick={() => {
                        setSortField("unit");
                        setSortDirection(
                          sortDirection === "asc" ? "desc" : "asc"
                        );
                      }}
                      className="sort"
                      data-sort="unit"
                      scope="col"
                    >
                      Unit
                    </th>
                    <th
                      onClick={() => {
                        setSortField("price");
                        setSortDirection(
                          sortDirection === "asc" ? "desc" : "asc"
                        );
                      }}
                      className="sort"
                      data-sort="price"
                      scope="col"
                    >
                      Price
                    </th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody className="list">
                  {filteredItems
                    .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                    .map((extra) => {
                      return (
                        <tr key={extra.id}>
                          <td>{extra?.title}</td>
                          <td>{extra?.status?.title}</td>
                          <td>{extra?.unit?.title}</td>
                          <td>{`R${extra?.price}`}</td>
                          <td className="text-right">
                            <button
                              className="btn btn-info btn-icon-only rounded-circle btn-sm"
                              onClick={() => {
                                setDisplayDetailForm(true);
                                setRoomExtra(extra);
                                // setRoomItem(item);
                              }}
                            >
                              <i className="fa-solid fa-pencil"></i>
                            </button>
                            <button
                              className="btn btn-danger btn-icon-only rounded-circle btn-sm"
                              onClick={() =>
                                displayAlert({ warning: true, extra })
                              }
                            >
                              <i className="fa-solid fa-trash-can"></i>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </CardBody>
            <CardFooter className="py-4">
              <Row>
                <div className="col-4 d-flex">
                  <span className="min-text">Rows per page:</span>
                  <BasicMenu
                    getValue={getRowValue}
                    options={["10", "20", "100"]}
                    value={rowPerPage.toString()}
                  />
                </div>
                <div className="col-4 d-flex">
                  <span className="min-text">Page:</span>
                  <span className="min-text pl-2">
                    {page + 1} of {Math.ceil(filteredItems.length / rowPerPage)}
                  </span>
                </div>
                <div className="col-4 d-flex">
                  <Pagination>
                    <PaginationItem className={page <= 0 ? "disabled" : ""}>
                      <PaginationLink onClick={(e) => previousPage()}>
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem
                      className={
                        page >= Math.ceil(filteredItems.length / rowPerPage) - 1
                          ? "disabled"
                          : ""
                      }
                    >
                      <PaginationLink onClick={(e) => nextPage()}>
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </div>
              </Row>
            </CardFooter>
          </div>
        </Row>
      </Card>
    </>
  );
};

export default RoomExtraListView;
