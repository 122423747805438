/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import EditLocationForm from './EditLocationForm';
// import { useHistory } from "react-router-dom";
import { Col, Row } from 'reactstrap';
import { useQuery } from '@apollo/client';
import { GET_PORTAL_LOCATION } from '../../../graphql/locations.graphql';
import { useNavigate, useParams } from 'react-router-dom';
import { LinearProgress } from '@mui/material';
import useToast from '../../../hooks/useToast';
import { Location, LocationSpace } from '../../../models/location.model';
import LocationImageView from './LocationImageView';
import { Image } from '../../../models/product.model';
import LocationFloorView from '../locations/components/LocationFloorView';
import RoomLocationView from '../locations/components/RoomLocationView';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box sx={{ p: 0 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs() {
    const [value, setValue] = React.useState(0);
    const params: any = useParams();
    const { showToast, Notification } = useToast();
    const { loading, error, data } = useQuery(GET_PORTAL_LOCATION, {
        variables: {
            id: params.id,
        },
    });

    React.useEffect(() => {
        if (error) {
            showToast('An error has occurred, please refresh the page', 'danger');
        }
    }, [error]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    // let history = useHistory();
    const navigate = useNavigate();
    const redirectToLocations = (e: any) => {
        e.preventDefault();
        navigate(-1);
    };

    if (loading) {
        return <LinearProgress />;
    }

    const location: Location = data?.location;
    const images: Image[] = location?.images;
    const floors: LocationSpace[] = location?.floors;
    const rooms: LocationSpace[] = location?.rooms;
    return (
        <div className='container-fluid mt-4'>
            <Col>
                {Notification}
                <Row className='my-4'>
                    <button className='btn btn-outline-primary btn-sm mr-4' onClick={(e) => redirectToLocations(e)}>
                        <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                        <span className='btn-inner-text'>Back</span>
                    </button>
                    <h3 className='m-0'>Manage Location</h3>
                </Row>
            </Col>
            <Col>
                <Row>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
                                <Tab sx={{ textTransform: 'capitalize' }} label='Details' {...a11yProps(0)} />
                                <Tab sx={{ textTransform: 'capitalize' }} label='Images' {...a11yProps(1)} />
                                <Tab sx={{ textTransform: 'capitalize' }} label='Floors' {...a11yProps(2)} />
                                <Tab sx={{ textTransform: 'capitalize' }} label='Rooms' {...a11yProps(3)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <EditLocationForm location={location} />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <LocationImageView images={images} />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <LocationFloorView floors={floors} />
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <RoomLocationView floors={floors} rooms={rooms} />
                        </TabPanel>
                    </Box>
                </Row>
            </Col>
        </div>
    );
}
