/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from "@apollo/client";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
} from "@mui/material";
import { FormGroup } from "reactstrap";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  GET_MEETING_ROOM,
  INSERT_ROOM_DISCOUNT,
} from "../../../../graphql/meeting-rooms.graphql";
import useToast from "../../../../hooks/useToast";
import {
  DiscountType,
  RoomDiscount,
} from "../../../../models/meeting.room.model";

interface AddRoomDiscountDialogProps {
  discountTypes: DiscountType[];
  isOpen: boolean;
  roomId: string;
  discount: RoomDiscount | null;
  toggleOpen: (isOpen: boolean) => void;
}

type FormData = {
  name: string;
  description: string;
  percentage: number;
  type: string;
};

export const AddRoomDiscountDialog = ({
  isOpen,
  discountTypes,
  toggleOpen,
  roomId,
  discount,
}: AddRoomDiscountDialogProps) => {
  const [addRoomRate, { error, called, loading, reset }] = useMutation(
    INSERT_ROOM_DISCOUNT,
    {
      refetchQueries: [{ query: GET_MEETING_ROOM }, "GetMeetingRoom"],
    }
  );
  const { showToast, Notification } = useToast();

  useEffect(() => {
    if (called && !loading && !error) {
      showToast(
        `Successfully ${discount ? "updated" : "added"} room discount`,
        "success"
      );
      reset();
      resetForm();
      toggleOpen(false);
    }
    if (called && error) {
      showToast("An error has occurred, please try again", "danger");
    }
  }, [called, loading, error]);

  useEffect(() => {
    if (discount) {
      setValue("name", discount.name);
      setValue("description", discount.description);
      setValue("percentage", discount.percentage * 100);
      setValue("type", discount.type.value);
    }
  }, [discount]);

  const handleClose = () => {
    toggleOpen(false);
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset: resetForm,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmit = (data: FormData) => {
    const discount = {
      meeting_room_id: roomId,
      name: data.name,
      description: data.description,
      percentage: data.percentage / 100,
      tag: data.type,
      deleted_at: null,
    };
    addRoomRate({
      variables: {
        discount,
      },
    });
  };

  return (
    <div>
      {Notification}
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>{discount ? "Update" : "Add"} Room Discount</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <DialogContentText className="mb-4">
              Enter the details to add the discount to this room
            </DialogContentText>
            <FormGroup>
              <label className="form-control-label" htmlFor="name">
                Name
              </label>
              <input
                className="form-control"
                {...register("name", { required: true, value: discount?.name })}
                id="discount-name"
                placeholder="Enter name..."
                type="text"
              />
              {errors.name && (
                <span className="invalid">*This field is required</span>
              )}
            </FormGroup>
            <FormGroup>
              <label className="form-control-label" htmlFor="description">
                Description
              </label>
              <textarea
                className="form-control"
                {...register("description", {
                  required: true,
                  value: discount?.description,
                })}
                id="description"
                placeholder="Enter description..."
                rows={3}
              ></textarea>
              {errors.description && (
                <span className="invalid">*This field is required</span>
              )}
            </FormGroup>
            <FormGroup>
              <label className="form-control-label" htmlFor="percentage">
                Percentage Discount
              </label>
              <input
                className="form-control"
                {...register("percentage", {
                  required: true,
                  value: discount?.percentage ? discount.percentage * 100 : 0,
                })}
                id="discount"
                step={0.01}
                placeholder="Enter percentage discount..."
                type="number"
              />
              {errors.name && (
                <span className="invalid">*This field is required</span>
              )}
            </FormGroup>
            <FormGroup>
              <label className="form-control-label" htmlFor="currency">
                Discount Type
              </label>
              <Select
                id="type"
                className="form-control"
                sx={{ borderRadius: "12px", padding: "0" }}
                placeholder="Select a type"
                defaultValue={discount?.type.value || ""}
                inputProps={{
                  ...register("type", {
                    required: true,
                    value: discount?.type.value,
                  }),
                }}
              >
                {discountTypes.map((type) => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.title}
                  </MenuItem>
                ))}
              </Select>
              {errors.type && (
                <span className="invalid">*This field is required</span>
              )}
            </FormGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit" disabled={loading}>
              {loading && <CircularProgress size={16} />}
              {!loading && <span>{discount ? "Update" : "Add"}</span>}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
