export const statusOptions: string[] = ["All","Archived", "Active"];
export const productPackages :string[]  = [
  "All",
  "Drop-in Office",
  "Dedicated Desk",
  "Flexible Desk",
  "Private Office",
  "Custom Office",
  "Drop-in Desk",
];


export const bookingTimeList: string[] = [
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
]

export const timeSlots = [
  {start: '08:00', end: '09:00'},
  {start: '10:00', end: '11:00'},
  {start: '12:00', end: '13:00'},
  {start: '14:00', end: '15:00'},
  {start: '16:00', end: '17:00'},
  { start: "17:00", end: "18:00" },
  { start: "18:00", end: "19:00" },
  { start: "19:00", end: "20:00" },
  { start: "20:00", end: "21:00" },
];
