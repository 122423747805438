import React, { useState } from "react";
import { Col, Row, UncontrolledTooltip } from "reactstrap";
import { Booking } from "../../models/bookings.model";
import {
  MenuType,
  Vendor,
  VendorMenuPackageItem,
} from "../../models/vendor.model";
import AddCateringMenuOptionDialog from "../Dialog/AddCateringMenuOptionDialog";
import PackageMenuItem from "./PackageMenuItem";

interface Props {
  selectedMenuItems?: VendorMenuPackageItem[];
  selectedBooking?: Booking;
  vendorMenuTypes?: MenuType[];
  vendors?: Vendor[];
  getSelectedMenuItems?: (items: VendorMenuPackageItem[]) => void;
}

const CateringMenuView = ({
  selectedMenuItems,
  selectedBooking,
  vendorMenuTypes,
  vendors,
  getSelectedMenuItems,
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const getItemsTotalPrice = (): number => {
    let total = 0;
    selectedMenuItems.forEach((menu) => {
      total += menu.totalPrice;
    });

    return total;
  };

  return (
    <>
      {isOpen && (
        <AddCateringMenuOptionDialog
          isOpen={isOpen}
          vendors={vendors}
          vendorMenuTypes={vendorMenuTypes}
          menuItems={selectedMenuItems}
          getSelectedItems={getSelectedMenuItems}
          handleClose={() => {
            setIsOpen(false);
          }}
        />
      )}
      <Row>
        <Col>
          <Row>
            <Col xs="6">
              <h3 className="mb-0">Selected Menu Items</h3>
            </Col>
            <Col className="text-right" xs="6">
              <div
                className="btn btn-dark btn-sm"
                color="default"
                id="tooltip969372949"
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                Add option
              </div>
              <UncontrolledTooltip delay={0} target="tooltip969372949">
                Add option
              </UncontrolledTooltip>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="menu-items">
              {selectedMenuItems.map((item) => {
                return (
                  <PackageMenuItem
                    key={item?.id}
                    item={item}
                    selectedMenuItems={selectedMenuItems}
                    selectedBooking={selectedBooking}
                    getSelectedMenuItems={getSelectedMenuItems}
                  />
                );
              })}
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <div className="d-flex h-100">
                <div className="align-self-end ml-auto">
                  {selectedMenuItems.length > 0 && (
                    <span>
                      Total R{getItemsTotalPrice().toFixed(2)} (excl VAT)
                    </span>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default CateringMenuView;
