/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormGroup, Container, Card, CardHeader, CardBody, Button, Row, Col } from 'reactstrap';
import { GET_PORTAL_USER, UPDATE_USER } from '../../../graphql/user.graphql';
import useToast from '../../../hooks/useToast';
import { useForm } from 'react-hook-form';
import { Role, User } from '../../../models/user.model';
import { LinearProgress, MenuItem, Select } from '@mui/material';
import Spinner from '../../../components/Spinner/Spinner';
import { PhoneNumberUtil } from 'google-libphonenumber';

import { PhoneInput } from 'react-international-phone';

interface UserFormProp {
    isAddForm: boolean;
}

type FormData = {
    name: string;
    surname: string;
    email: string;
    contactNumber: string;
    role: string;
};

const EditUserForm = ({ isAddForm }: UserFormProp) => {
    let navigate = useNavigate();
    const params: any = useParams();

    const phoneUtil = PhoneNumberUtil.getInstance();

    const isPhoneValid = (phone: string) => {
        try {
            return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
        } catch (error) {
            return false;
        }
    };

    const { showToast, Notification } = useToast();
    const { loading, error, data } = useQuery(GET_PORTAL_USER, {
        variables: { id: params.id },
    });
    const [updateUserMutation, { loading: isUpdating, error: updateError, called }] = useMutation(UPDATE_USER, {
        refetchQueries: ['GetUsers'],
    });

    const {
        register,
        handleSubmit,
        setValue,
        setError,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues: {
            role: '',
        },
    });

    useEffect(() => {
        if (error || updateError) {
            showToast('An error has occurred, please try again', 'danger');
        }
    }, [error, updateError]);

    useEffect(() => {
        if (!isUpdating && called && !updateError) {
            showToast('Successfully updated user', 'success');
        }
    }, [isUpdating, called]);

    const user: User = data?.user;
    const roles: Role[] = data?.role || [];

    const onSubmit = async (data: FormData) => {
        if (!isPhoneValid(data.contactNumber)) {
            setError('contactNumber', { type: 'custom', message: '*Please enter a valid phone number' });
            return;
        }

        const updatedUser = {
            name: data.name,
            surname: data.surname,
            email: data.email,
            contact_number: data.contactNumber,
            role_id: data.role,
        };

        console.log('user to update', updatedUser);
        updateUserMutation({
            variables: { id: user.id, user: updatedUser },
        });
    };

    const redirectToUsers = (e: any) => {
        e.preventDefault();
        navigate(-1);
    };

    if (loading) {
        return <LinearProgress />;
    }

    return (
        <>
            {Notification}
            <Container className='mt-4' fluid>
                <Row>
                    <Col md='12' lg='6'>
                        <Card>
                            <CardHeader>
                                <Row noGutters={true}>
                                    <button
                                        className='btn btn-outline-primary btn-sm mr-4'
                                        onClick={(e) => redirectToUsers(e)}>
                                        <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                                        <span className='btn-inner-text'>Back</span>
                                    </button>

                                    <h1 className='m-0'>Update user</h1>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='name'>
                                            Name
                                        </label>
                                        <input
                                            className='form-control'
                                            {...register('name', {
                                                required: true,
                                                value: user?.name,
                                            })}
                                            id='name'
                                            placeholder="Enter user's name..."
                                            type='text'
                                        />
                                        {errors.name && <span className='invalid'>*This field is required</span>}
                                    </FormGroup>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='name'>
                                            Surname
                                        </label>
                                        <input
                                            className='form-control'
                                            {...register('surname', {
                                                required: true,
                                                value: user?.surname,
                                            })}
                                            id='surname'
                                            placeholder="Enter user's surname..."
                                            type='text'
                                        />
                                        {errors.surname && <span className='invalid'>*This field is required</span>}
                                    </FormGroup>

                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='email'>
                                            Email
                                        </label>
                                        <input
                                            className='form-control'
                                            {...register('email', {
                                                required: true,
                                                value: user?.email,
                                            })}
                                            id='email'
                                            placeholder="Enter the user's email..."
                                            type='email'
                                        />
                                        {errors.email && <span className='invalid'>*This field is required</span>}
                                    </FormGroup>

                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='contact'>
                                            Contact number
                                        </label>

                                        <PhoneInput
                                            defaultCountry='za'
                                            placeholder='Enter phone number...'
                                            inputClassName='form-control'
                                            value={user.contactNumber}
                                            style={
                                                {
                                                    '--react-international-phone-width': '100%',
                                                    '--react-international-phone-height': '44px',
                                                    '--react-international-phone-border-radius': '12px',
                                                } as React.CSSProperties
                                            }
                                            {...register('contactNumber', {
                                                required: true,
                                            })}
                                            onChange={(phone) => setValue('contactNumber', phone)}
                                        />
                                        {errors.contactNumber && (
                                            <span className='invalid'>
                                                {errors.contactNumber.message ?? '*This field is required'}
                                            </span>
                                        )}
                                    </FormGroup>

                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='role'>
                                            Role
                                        </label>
                                        <Select
                                            id='role'
                                            className='form-control'
                                            sx={{ borderRadius: '12px', padding: '0' }}
                                            placeholder='Select a role'
                                            defaultValue={user?.role.id}
                                            inputProps={{
                                                ...register('role', {
                                                    required: true,
                                                    value: user?.role.id,
                                                }),
                                            }}>
                                            {roles.map((roleItem) => (
                                                <MenuItem
                                                    key={roleItem.id}
                                                    value={roleItem.id}
                                                    selected={user?.role.id === roleItem.id}>
                                                    {roleItem.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {errors.role && <span className='invalid'>*This field is required</span>}
                                    </FormGroup>
                                    <div className='d-flex h-100'>
                                        <div className='align-self-end ml-auto'>
                                            <Button
                                                type='submit'
                                                className='btn btn-dark mt-4 btn-block'
                                                disabled={isUpdating}>
                                                {!isUpdating ? (
                                                    <span>Update user</span>
                                                ) : (
                                                    <span>
                                                        <Spinner />
                                                    </span>
                                                )}
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default EditUserForm;
