import { MenuItem, Select, SelectChangeEvent, Switch, FormControlLabel, Button, Checkbox } from '@mui/material';
import React from 'react';
import { Card, CardBody, CardFooter, CardHeader, Input as StrapInput } from 'reactstrap';
import { ResourceAttribute, ResourceFormAttributeOption } from '../../../../models/resource.model';
import DeleteIcon from '@mui/icons-material/Delete';
import { v4 } from 'uuid';
import { useMutation } from '@apollo/client';
import { GET_RESOURCE, REMOVE_ATTRIBUTE, REMOVE_ATTRIBUTE_OPTION } from '../../../../graphql/resource.graphql';
import DragHandleIcon from '@mui/icons-material/DragHandle';

interface ResourceFormAttributeProps {
    attribute: ResourceAttribute;
    availableAttributes: ResourceAttribute[];
    onAttributeChange: (attributes: ResourceAttribute[]) => void;
    onDragStart: (event: any, index: number) => void;
    onRemove: (attribute: ResourceAttribute) => void;
    onDragOver: (event: any) => void;
    onDrop: (event: any, index: number) => void;
}

const attributeType = [
    { title: 'Text', value: 'text' },
    { title: 'Textarea', value: 'textarea' },
    { title: 'Dropdown', value: 'dropdown' },
    { title: 'Checkbox', value: 'checkbox' },
    { title: 'Number', value: 'number' },
    { title: 'Date', value: 'date' },
    { title: 'Time', value: 'time' },
];

export default function ResourceFormAttribute({
    attribute,
    availableAttributes,
    onAttributeChange,
    onRemove,
    onDragOver,
    onDragStart,
    onDrop,
}: ResourceFormAttributeProps) {
    const [removeAttribute] = useMutation(REMOVE_ATTRIBUTE, {
        refetchQueries: [GET_RESOURCE],
    });

    const [removeOption] = useMutation(REMOVE_ATTRIBUTE_OPTION, {
        refetchQueries: [GET_RESOURCE],
    });

    const handleTypeChange = (event: SelectChangeEvent) => {
        const attributes = [...availableAttributes];
        const index = availableAttributes.findIndex(
            (a) => availableAttributes.indexOf(a) === availableAttributes.indexOf(attribute),
        );
        if (index === -1) return;
        attributes[index].type = event.target.value;
        onAttributeChange(attributes);
    };

    const handleRequiredChange = () => {
        const attributes = [...availableAttributes];
        const index = availableAttributes.findIndex(
            (a) => availableAttributes.indexOf(a) === availableAttributes.indexOf(attribute),
        );
        if (index === -1) return;
        attributes[index].required = !attributes[index].required;
        onAttributeChange([...attributes]);
    };

    const handleTitleChange = (e: any) => {
        const attributes = [...availableAttributes];
        const index = availableAttributes.findIndex(
            (a) => availableAttributes.indexOf(a) === availableAttributes.indexOf(attribute),
        );
        if (index === -1) return;
        attributes[index].title = e.target.value;
        onAttributeChange([...attributes]);
    };

    const handelAttributeDelete = () => {
        if (attribute?.id) {
            removeAttribute({
                variables: {
                    id: attribute?.id,
                },
            });
        }
    };

    const addAttributeOption = () => {
        const attributes = [...availableAttributes];
        const index = availableAttributes.findIndex(
            (a) => availableAttributes.indexOf(a) === availableAttributes.indexOf(attribute),
        );
        const option: ResourceFormAttributeOption = {
            value: '',
            key: v4(),
            order: attributes[index]?.options?.length + 1 ?? 1,
        };
        attributes[index].options = [...attributes[index].options, option];
        onAttributeChange([...attributes]);
    };

    const removeAttributeOption = (optionIndex: number) => {
        const attributes = [...availableAttributes];

        const index = availableAttributes.findIndex(
            (a) => availableAttributes.indexOf(a) === availableAttributes.indexOf(attribute),
        );
        if (index === -1) return;

        if (attributes[index].options[optionIndex]?.id) {
            removeOption({
                variables: {
                    id: attributes[index].options[optionIndex]?.id,
                },
            });
        }

        attributes[index].options.splice(optionIndex, 1);

        //Re-order all the options
        for (var i = 0; i < attributes[index].options.length; i++) {
            attributes[index].options[i].order = i + 1;
        }
        onAttributeChange([...attributes]);
    };

    const handleAttributeOptionChange = (value: string, optionIndex: number) => {
        const attributes = [...availableAttributes];
        const index = availableAttributes.findIndex(
            (a) => availableAttributes.indexOf(a) === availableAttributes.indexOf(attribute),
        );
        if (index === -1) return;
        attributes[index].options[optionIndex].value = value;
        console.log('Option', attributes[index].options[optionIndex].value);
        onAttributeChange([...attributes]);
    };

    const handleCardToggle = () => {
        const attributes = [...availableAttributes];
        const index = availableAttributes.findIndex(
            (a) => availableAttributes.indexOf(a) === availableAttributes.indexOf(attribute),
        );
        for (var i = 0; i < attributes.length; i++) {
            attributes[i].isOpen = false;
        }
        attributes[index].isOpen = true;
        onAttributeChange([...attributes]);
    };

    const handleAttributeValueChange = (e: any) => {
        const attributes = [...availableAttributes];
        const index = availableAttributes.findIndex(
            (a) => availableAttributes.indexOf(a) === availableAttributes.indexOf(attribute),
        );
        if (index === -1) return;
        if (attribute.type === 'checkbox') {
            attributes[index].value.value = e.target.checked;
        } else {
            attributes[index].value.value = e.target.value;
        }

        onAttributeChange([...attributes]);
    };

    const i = availableAttributes.findIndex(
        (a) => availableAttributes.indexOf(a) === availableAttributes.indexOf(attribute),
    );

    return (
        <Card
            draggable
            onClick={handleCardToggle}
            onDragStart={(e) => onDragStart(e, i)}
            onDragOver={onDragOver}
            onDrop={(e) => {
                onDrop(e, i);
            }}>
            <CardHeader
                style={{
                    cursor: 'move',
                }}>
                <div className='d-flex justify-content-center'>
                    <DragHandleIcon />
                </div>
            </CardHeader>
            <CardBody className={attribute.isOpen ? '' : 'attribute-card'}>
                <div className='row d-flex'>
                    {!attribute?.isOpen && <span className='ml-2 mb-4'>{attribute?.title ?? ''}</span>}
                    {attribute.isOpen && (
                        <div className='col-lg-7'>
                            <input
                                id='title'
                                placeholder='Add attribute title'
                                onChange={handleTitleChange}
                                value={attribute?.title ?? ''}
                                // fullWidth={true}
                                className='form-control'
                                style={{ fontSize: '14px' }}
                            />
                        </div>
                    )}
                    <div className='col-lg-5'>
                        {attribute.isOpen && (
                            <Select
                                id='title'
                                variant='outlined'
                                className='form-control'
                                defaultValue={attribute?.type ?? 'empty'}
                                placeholder='Add attribute title'
                                onChange={handleTypeChange}
                                fullWidth={true}
                                style={{ fontSize: '14px', borderRadius: '12px' }}>
                                <MenuItem disabled value={'empty'}>
                                    <span className='placeholder-text'>Select attribute type</span>
                                </MenuItem>
                                {attributeType.map((type) => {
                                    return (
                                        <MenuItem key={type.value} value={type.value}>
                                            {type.title}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        )}
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className='col-lg-12'>
                        {attribute.type === 'text' && (
                            <input
                                className='form-control'
                                type='text'
                                onChange={handleAttributeValueChange}
                                disabled
                                placeholder='Text input field'
                            />
                        )}
                        {attribute.type === 'number' && (
                            <input
                                className='form-control'
                                type='number'
                                disabled
                                onChange={handleAttributeValueChange}
                                placeholder='Number input field'
                            />
                        )}
                        {attribute.type === 'date' && (
                            <input
                                className='form-control'
                                type='date'
                                disabled
                                onChange={handleAttributeValueChange}
                                placeholder='Number input field'
                            />
                        )}
                        {attribute.type === 'checkbox' && <Checkbox disabled onChange={handleAttributeValueChange} />}
                        {attribute.type === 'time' && (
                            <input
                                className='form-control'
                                type='time'
                                disabled
                                onChange={handleAttributeValueChange}
                                placeholder='Number input field'
                            />
                        )}
                        {attribute.type === 'textarea' && (
                            <textarea
                                style={{
                                    backgroundColor: 'transparent',
                                }}
                                className='w-100 p-4 form-control'
                                disabled
                                onChange={handleAttributeValueChange}
                                rows={5}
                                placeholder='Textarea input field'
                            />
                        )}

                        {attribute.type === 'dropdown' && (
                            <>
                                {attribute?.options?.map((option, index) => {
                                    return (
                                        <div className='row mb-2' key={option.key}>
                                            <div className='col-lg-6'>
                                                <StrapInput
                                                    fullWidth={true}
                                                    value={option.value ?? ''}
                                                    type='text'
                                                    onChange={(e) => handleAttributeOptionChange(e.target.value, index)}
                                                    placeholder={`Option ${option.order}`}
                                                />
                                            </div>
                                            <div className='col-lg-6'>
                                                <DeleteIcon
                                                    onClick={() => removeAttributeOption(index)}
                                                    sx={{
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}

                                <div className='row mt-4'>
                                    <div className='col-lg-6'>
                                        <Button onClick={addAttributeOption}>Add option</Button>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </CardBody>
            <CardFooter>
                {attribute.isOpen && (
                    <>
                        <DeleteIcon
                            sx={{
                                cursor: 'pointer',
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onRemove(attribute);
                                handelAttributeDelete();
                            }}
                        />

                        <FormControlLabel
                            sx={{
                                marginLeft: 2,
                                marginTop: 1,
                            }}
                            control={
                                <Switch
                                    color='primary'
                                    defaultChecked={attribute.required ?? false}
                                    onChange={handleRequiredChange}
                                />
                            }
                            label='Required'
                        />
                    </>
                )}
            </CardFooter>
        </Card>
    );
}
