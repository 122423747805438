import { gql } from "@apollo/client";

export const GET_DASHBOARD_DATA = gql`
  query GetDashboardData(
    $startDate: timestamp
    $endDate: timestamp
    $todayStart: timestamp
    $todayEnd: timestamp
  ) {
    totalClients: user_account_aggregate(
      where: { status_enum: { _eq: active } }
    ) {
      aggregate {
        count
      }
    }
    statusList: booking_status {
      value
      title
    }
    totalBookedPackagesPerMonth: total_booked_packages_per_month {
      packageCount: package_count
      packageName: package_name
      month: pb_month
    }
    totalBookedRoomsPerMonth: total_booked_rooms_per_month {
      month: b_month
      year: b_year
      count
    }
    clients: user_account(
      where: { deleted_at: { _is_null: true } }
      order_by: { company_name: asc }
    ) {
      id
      companyName: company_name
      individualName: individual_name
      isColabMember: is_colab_member
      contractExpiry: contract_expiry_date
      user {
        id
        name
        surname
        contactNumber: contact_number
        email
      }
    }
    rooms: meeting_room_item(
      where: {
        deleted_at: { _is_null: true }
        status_enum: { _neq: "archived" }
        meeting_room: {
          deleted_at: { _is_null: true }
          status_enum: { _eq: "active" }
        }
      }
      order_by: { name: asc }
    ) {
      id
      name
      checklistAddOns: checklist_add_ons(
        where: { deleted_at: { _is_null: true } }
      ) {
        id
        checkListItem: meeting_room_checklist_item {
          id
          name
        }
      }
      itemConfigurations: meeting_room_item_configurations(
        where: { deleted_at: { _is_null: true } }
      ) {
        id
        configurations: meeting_room_configurations {
          id
          image: image_path
          name
        }
      }
      status {
        value
        title
      }
      rules {
        id
        allowRepeating: allow_repeating
        allowLongTerm: allow_long_term
        allowShortBooking: allow_short_booking
        overflowBooking: overflow_booking
      }
      meetingRoom: meeting_room {
        id
        name
        roomExtras: room_extras {
          id
          price
          title
          description
          unit: room_extra_unit {
            title
            value
          }
        }
        rates {
          price
          type: rate_type {
            value
            title
          }
        }
      }
    }

    totalBookingsToday: booking_aggregate(
      where: {
        _and: [
          { status_enum: { _in: [active, completed, paid, confirmed] } }
          { time_from: { _gte: $todayStart }, time_to: { _lte: $todayEnd } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    upcomingBookings: booking_aggregate(
      where: {
        _and: [
          { status_enum: { _in: [active, paid, confirmed] } }
          { time_from: { _gte: now } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    totalBookings: booking_aggregate(
      where: { status_enum: { _in: [active, paid, confirmed, completed] } }
    ) {
      aggregate {
        count
      }
    }
    monthlyOrders: monthly_package_orders(
      limit: 12
      order_by: { year: desc, month: desc }
    ) {
      bookingId: booking_id
      packageName: package_name
      month
      year
      count
    }
    totalAvailability: get_total_package_availability(
      args: { start_date: $startDate, end_date: $endDate }
      order_by: { date: asc }
    ) {
      packageId: package_id
      name
      date
      totalAvailable: total_available
    }
    bookings: booking(where: { _and: [{ time_from: { _gte: $startDate } }] }) {
      id
      from: time_from
      to: time_to
      subject
      status {
        value
        title
      }
      client: user_account {
        id
        individualName: individual_name
        companyName: company_name
        user {
          id
          name
          surname
        }
      }
    }
  }
`;
