import { useMutation } from '@apollo/client';
import { MenuItem, Select } from '@mui/material';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Row, Spinner } from 'reactstrap';
import {
    ADD_MAIL_LIST_EMAIL,
    GET_MAIL_LIST_DETAILS,
    UPDATE_MAIL_LIST_EMAIL,
} from '../../../../../graphql/mail-list.graphql';
import useToast from '../../../../../hooks/useToast';
import { useParams } from 'react-router-dom';
import { MailListEmail } from '../../../../../models/newsletter-emil.model';
import { Status } from '../../../../../models/newsletter-post.model';

type FormData = {
    status: string;
    email: string;
};

interface MailListEmailAddFormProps {
    email?: MailListEmail;
    emailStatusList: Status[];
    onReturnToEmailListView: () => void;
}

export default function MailListEmailAddForm({
    email,
    emailStatusList,
    onReturnToEmailListView,
}: MailListEmailAddFormProps) {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues: {
            email: email?.email ?? '',
            status: email?.status?.value ?? '',
        },
    });
    const { showToast, Notification } = useToast();
    const params: any = useParams();
    const [addEmail, { loading, error }] = useMutation(ADD_MAIL_LIST_EMAIL, {
        refetchQueries: [GET_MAIL_LIST_DETAILS],
    });

    const [editEmail, { loading: updating, error: updateError }] = useMutation(UPDATE_MAIL_LIST_EMAIL, {
        refetchQueries: [GET_MAIL_LIST_DETAILS],
    });

    useEffect(() => {
        if (updateError) {
            showToast('An error has occurred while trying to update this email', 'danger');
        }
    }, [updateError]);

    const onSubmit = async (data: FormData) => {
        console.log('Data', data);

        const emailPayLoad = {
            id: email?.id ?? undefined,
            email: data.email,
            status_enum: data.status,
            mail_list_id: params?.id,
        };

        if (!email?.id) {
            await addEmail({
                variables: {
                    email: emailPayLoad,
                },
            });
        } else {
            const emailPayload = {
                email: data.email,
                status_enum: data.status,
            };
            await editEmail({
                variables: {
                    id: email?.id,
                    email: emailPayload,
                },
            });
        }

        if (email?.id) {
            showToast('Successfully updated email', 'success');
            return;
        }

        showToast('Successfully added email', 'success');
    };

    useEffect(() => {
        if (error) {
            if (email) {
                showToast('An error has occurred while trying to update email', 'danger');
                return;
            }

            showToast('An error has occurred while trying to add email', 'danger');
        }
    }, [error]);

    return (
        <>
            {Notification}
            <Row className='mt-4'>
                <Col md='12' lg='6'>
                    <Card>
                        <CardHeader>
                            <Row noGutters={true}>
                                <button
                                    className='btn btn-outline-primary btn-sm mr-4'
                                    onClick={onReturnToEmailListView}>
                                    <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                                    <span className='btn-inner-text'>Back</span>
                                </button>
                                {email && <h3 className='m-0'> Email Information</h3>}
                                {!email && <h3 className='m-0'> Add Email</h3>}
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className='row'>
                                    <div className='col-lg-12 col-md-12'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='title'>
                                                Email Address
                                            </label>
                                            <input
                                                className='form-control'
                                                {...register('email', { required: true })}
                                                id='email'
                                                placeholder='Enter email...'
                                                type='email'
                                            />
                                            {errors.email && <span className='invalid'>*This field is required</span>}
                                        </FormGroup>
                                    </div>
                                    <div className='col-lg-12 col-md-12'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='status'>
                                                Status
                                            </label>
                                            <Select
                                                id='status'
                                                sx={{ borderRadius: '12px', padding: '0' }}
                                                className='form-control'
                                                defaultValue={email?.status?.value ?? ' '}
                                                inputProps={{
                                                    ...register('status', {
                                                        required: true,
                                                    }),
                                                }}>
                                                <MenuItem disabled value=' '>
                                                    Select status...
                                                </MenuItem>
                                                {emailStatusList?.map((status: any) => {
                                                    return (
                                                        <MenuItem key={status.value} value={status.value}>
                                                            {status.title}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className='d-flex h-100'>
                                    <div className='align-self-end ml-auto'>
                                        <Button
                                            type='submit'
                                            className='btn btn-dark mt-4 btn-block'
                                            disabled={loading}>
                                            {!loading && !updating && <span>{!email ? 'Add' : 'Update'}</span>}
                                            {(loading || updating) && <Spinner />}
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
}
