import { useMutation } from '@apollo/client';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormGroup } from 'reactstrap';
import { EXPORT_CLIENTS } from '../../../../graphql/clients.graphql';
import useToast from '../../../../hooks/useToast';
import { downloadPdf } from '../../../../utils/file.utils';
import { v4 } from 'uuid';

const options = [
    { value: 'all', title: 'All' },
    { value: 'conference', title: 'Conference' },
    { value: 'colab', title: 'Colab' },
    { value: 'advocate', title: 'Advocate' },
];

interface ClientExportDialogProps {
    isOpen: boolean;
    handleClose: () => void;
}

type FormData = {
    type: string;
};

export default function ClientExportDialog({ isOpen, handleClose }: ClientExportDialogProps) {
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm<FormData>();

    const { showToast, Notification } = useToast();

    const downloadDocument = (path: string) => {
        const url = `${process.env.REACT_APP_IMAGE_URL}/${path}`;
        downloadPdf(url, `${v4()}`);
    };

    const [exportClients, { loading, data, error }] = useMutation(EXPORT_CLIENTS);

    const onSubmit = async (formData: FormData) => {
        if (formData?.type === ' ' || formData?.type === '') {
            setError('type', { type: 'custom' });
            return;
        }

        await exportClients({
            variables: {
                type: formData?.type,
            },
        });
    };

    useEffect(() => {
        if (data) {
            downloadDocument(data?.clients?.path);
            handleClose();
        }
    }, [data]);

    useEffect(() => {
        if (error) {
            showToast('An error has occurred while trying to export the client ', 'danger');
            console.log('Export error', error);
        }
    }, [error]);

    return (
        <>
            {Notification}
            <Dialog open={isOpen} onClose={handleClose}>
                <DialogTitle>Create Client Export</DialogTitle>
                <form onSubmit={handleSubmit(onSubmit)} style={{ width: '500px' }}>
                    <DialogContent>
                        <FormGroup>
                            <label className='form-control-label' htmlFor='currency'>
                                Export Type
                            </label>
                            <Select
                                id='type'
                                className='form-control'
                                sx={{ borderRadius: '12px', padding: '0' }}
                                defaultValue={' '}
                                inputProps={{
                                    ...register('type', {
                                        required: true,
                                    }),
                                }}>
                                <MenuItem disabled value=' '>
                                    Select a client export type...
                                </MenuItem>
                                {options.map((option) => {
                                    return (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.title}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            {errors.type && <span className='invalid'>*This field is required</span>}
                        </FormGroup>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} disabled={loading}>
                            Cancel
                        </Button>
                        <Button type='submit' disabled={loading}>
                            {loading && <CircularProgress size={16} />}
                            {!loading && <span>Export</span>}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}
