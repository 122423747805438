/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Button, Card, CardBody, Table } from "reactstrap";
import AddConfigurationDialog from "../dialogs/AddConfigurationDialog";
import { useMutation } from "@apollo/client";
import {
  REMOVE_ROOM_CONFIGURATION,
  GET_MEETING_ROOM,
} from "../../../../graphql/meeting-rooms.graphql";
import useToast from "../../../../hooks/useToast";
import SweetAlert from "react-bootstrap-sweetalert";
import { MeetingRoomConfiguration } from "../../../../models/meeting.room.model";

interface MeetingRoomConfigurationProp {
  configurations: MeetingRoomConfiguration[];
}

const MeetingRoomConfigurationView = ({
  configurations,
}: MeetingRoomConfigurationProp) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [configuration, setConfiguration] = useState<any>(null);
  const { Notification, showToast } = useToast();

  const [removeConfiguration, { data: removedConfig, error: errorRemoving }] =
    useMutation(REMOVE_ROOM_CONFIGURATION, {
      refetchQueries: [GET_MEETING_ROOM],
    });

  const [alert, setAlert] = useState<any>(null);

  useEffect(() => {
    if (errorRemoving) {
      showToast(
        "An error has occurred while trying to remove this configuration",
        "danger"
      );
    }

    if (removedConfig) {
      showToast("Successfully removed configuration for this room", "success");
    }
  }, [removedConfig, errorRemoving]);

  const displayAlert = (props: any) => {
    setAlert(
      <SweetAlert
        {...props}
        showCancel
        confirmBtnText="Continue"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => {
          removeConfiguration({
            variables: {
              id: props.config.id,
            },
          });
          setAlert(null);
        }}
        onCancel={() => {
          setAlert(null);
        }}
        focusCancelBtn
      >
        This configuration will be removed.
      </SweetAlert>
    );
  };

  return (
    <>
      {Notification}
      {alert}
      {
        <AddConfigurationDialog
          configuration={configuration}
          isOpen={isDialogOpen}
          handleClose={() => setIsDialogOpen(false)}
        />
      }
      <Card>
        <CardBody>
          <div className="row no-gutters justify-content-between align-items-center mb-3">
            <h3 className="mb-0">Room Configurations</h3>
            <button
              className="btn btn-dark btn-sm"
              onClick={() => {
                setIsDialogOpen(true);
                setConfiguration(null);
              }}
            >
              Add Configuration
            </button>
          </div>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Status</th>
                <th scope="col" />
              </tr>
            </thead>
            <tbody className="list">
              {configurations.map((config) => {
                return (
                  <tr key={config?.id}>
                    <td>{config?.name}</td>
                    <td>{config?.status?.title}</td>
                    <td className="text-right">
                      <Button
                        onClick={() => {
                          setConfiguration(config);
                          setIsDialogOpen(true);
                        }}
                        className="btn btn-info btn-icon-only rounded-circle btn-sm"
                      >
                        <i className="fa-solid fa-pencil"></i>
                      </Button>
                      <Button
                        onClick={() => displayAlert({ warning: true, config })}
                        className="btn btn-danger btn-icon-only rounded-circle btn-sm"
                      >
                        <i className="fa-regular fa-trash-can"></i>
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </>
  );
};

export default MeetingRoomConfigurationView;
