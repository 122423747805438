import React from 'react';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { ConferenceBookingInfo } from '../../../../models/bookings.model';
import moment from 'moment';
import { currencyFormatter } from '../../../../utils/format.utils';

interface ConferenceDashboardCardsProps {
    chartInfo: ConferenceBookingInfo[];
    sixtyDaysInfo: ConferenceBookingInfo[];
}

export default function ConferenceDashboardCards({ chartInfo, sixtyDaysInfo }: ConferenceDashboardCardsProps) {
    const differenceForToday = (): number => {
        const today = moment().format('YYYY-MM-DD');
        const chartData = chartInfo.find((info) => info.date === today);
        if (!chartData) return 0;
        return Math.abs(chartData.expectedIncome - chartData.actualIncome);
    };

    const textColor = (styleName: string): string => {
        const styleMap = new Map<string, string>();

        styleMap.set('bg-success', 'text-success');
        styleMap.set('bg-danger', 'text-danger');

        if (!styleMap.get(styleName)) return 'text-primary';

        return styleMap.get(styleName);
    };

    const todayDifferenceBackgroundColor = (): string => {
        const today = moment().format('YYYY-MM-DD');
        const chartData = chartInfo.find((info) => info.date === today);
        if (!chartData) return 'bg-primary';
        return chartData.actualIncome >= chartData.expectedIncome ? 'bg-success' : 'bg-danger';
    };

    const differenceInThirtyDays = (): number => {
        const today = moment().add(1, 'month').format('YYYY-MM-DD');
        const chartData = chartInfo.find((info) => info.date === today);
        if (!chartData) return 0;
        return Math.abs(chartData.expectedIncome - chartData.actualIncome);
    };

    const thirtyDayDifferenceBackgroundColor = (): string => {
        const today = moment().add(1, 'month').format('YYYY-MM-DD');
        const chartData = chartInfo.find((info) => info.date === today);
        if (!chartData) return 'bg-primary';
        return chartData.actualIncome >= chartData.expectedIncome ? 'bg-success' : 'bg-danger';
    };

    const differenceInSixtyDays = (): number => {
        const chartData = sixtyDaysInfo[0];
        if (!chartData) return 0;
        return Math.abs(chartData.expectedIncome - chartData.actualIncome);
    };

    const sixtyDaysDayDifferenceBackgroundColor = (): string => {
        const chartData = sixtyDaysInfo[0];
        if (!chartData) return 'bg-primary';
        return chartData.actualIncome >= chartData.expectedIncome ? 'bg-success' : 'bg-danger';
    };

    return (
        <Row>
            <Col md='6' xl='4'>
                <Card className='card-stats'>
                    <CardBody>
                        <Row>
                            <div className='col'>
                                <CardTitle tag='h5' className='text-uppercase text-muted mb-0'>
                                    Difference as of today
                                </CardTitle>
                                <span
                                    className={`h2 font-weight-bold mb-0 ${textColor(
                                        todayDifferenceBackgroundColor(),
                                    )}`}>
                                    {currencyFormatter(differenceForToday())}
                                </span>
                            </div>
                            <Col className='col-auto'>
                                <div
                                    className={`icon icon-shape ${todayDifferenceBackgroundColor()} text-white rounded-circle shadow`}>
                                    {todayDifferenceBackgroundColor() === 'bg-success' && (
                                        <i className='fa-solid fa-arrow-up'></i>
                                    )}
                                    {todayDifferenceBackgroundColor() === 'bg-danger' && (
                                        <i className='fa-solid fa-arrow-down'></i>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>

            <Col md='6' xl='4'>
                <Card className='card-stats'>
                    <CardBody>
                        <Row>
                            <div className='col'>
                                <CardTitle tag='h5' className={'text-uppercase text-muted mb-0'}>
                                    Difference in 30 Days
                                </CardTitle>
                                <span
                                    className={`h2 font-weight-bold mb-0 ${textColor(
                                        thirtyDayDifferenceBackgroundColor(),
                                    )}`}>
                                    {currencyFormatter(differenceInThirtyDays())}
                                </span>
                            </div>
                            <Col className='col-auto'>
                                <div
                                    className={`icon icon-shape ${thirtyDayDifferenceBackgroundColor()} text-white rounded-circle shadow`}>
                                    {thirtyDayDifferenceBackgroundColor() === 'bg-success' && (
                                        <i className='fa-solid fa-arrow-up'></i>
                                    )}
                                    {thirtyDayDifferenceBackgroundColor() === 'bg-danger' && (
                                        <i className='fa-solid fa-arrow-down'></i>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
            <Col md='6' xl='4'>
                <Card className='card-stats'>
                    <CardBody>
                        <Row>
                            <div className='col'>
                                <CardTitle tag='h5' className='text-uppercase text-muted mb-0'>
                                    Difference in 60 Days
                                </CardTitle>
                                <span
                                    className={`h2 font-weight-bold mb-0 ${textColor(
                                        sixtyDaysDayDifferenceBackgroundColor(),
                                    )}`}>
                                    {currencyFormatter(differenceInSixtyDays())}
                                </span>
                            </div>
                            <Col className='col-auto'>
                                <div
                                    className={`icon icon-shape ${sixtyDaysDayDifferenceBackgroundColor()} text-white rounded-circle shadow`}>
                                    {sixtyDaysDayDifferenceBackgroundColor() === 'bg-success' && (
                                        <i className='fa-solid fa-arrow-up'></i>
                                    )}
                                    {sixtyDaysDayDifferenceBackgroundColor() === 'bg-danger' && (
                                        <i className='fa-solid fa-arrow-down'></i>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}
