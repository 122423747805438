/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "./image.css";

const FeaturedImage = ({ imageSource, fileInputRef }: any) => {
  const openFileSelector = (e: any) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  return (
    <div className="image-container">
      {!imageSource ? <h5 className="text-center">Select an image</h5> : <img src={imageSource} alt="img" className="image" />}

      <div className="overlay">
        <h1>
          <button
            className="btn btn-info btn-icon-only rounded-circle btn-sm"
            onClick={(e) => {
              openFileSelector(e);
            }}
          >
            <i className="fa-solid fa-pencil"></i>
          </button>
          <input type="file" style={{ display: "none" }} ref={fileInputRef} />
        </h1>
      </div>
    </div>
  );
};

export default FeaturedImage;
