import React, { useCallback, useEffect, useState } from 'react';
import { BookingRoomExtra } from '../../../../../models/bookings.model';
import ClearIcon from '@mui/icons-material/Clear';
import { useMutation } from '@apollo/client';
import { REMOVE_BOOKING_ROOM_EXTRAS } from '../../../../../graphql/bookings.graphql';
import SweetAlert from 'react-bootstrap-sweetalert';

interface BookingAdOnItemProps {
    bookingAdOn?: BookingRoomExtra;
    selectedAdOns?: BookingRoomExtra[];
    getBookingAdOns: (adOns: BookingRoomExtra[]) => void;
}

const BookingAdOnItem = ({ bookingAdOn, selectedAdOns, getBookingAdOns }: BookingAdOnItemProps) => {
    const [quantity, setQuantity] = useState<number>(bookingAdOn?.quantity || 0);
    const [alert, setAlert] = useState<any>(null);

    const addQuantity = () => setQuantity(quantity + 1);
    const minuteQuantity = () => {
        if (quantity >= 2) setQuantity(quantity - 1);
    };

    const [deleteBookingAddOn] = useMutation(REMOVE_BOOKING_ROOM_EXTRAS);

    const handleQuantityChange = useCallback(() => {
        if (quantity) {
            const itemIndex = selectedAdOns?.indexOf(bookingAdOn);

            if (itemIndex === -1) {
                return;
            }

            const newAdOn: BookingRoomExtra = {
                id: bookingAdOn?.id,
                title: bookingAdOn?.title,
                roomExtraId: bookingAdOn?.roomExtraId,
                rate: bookingAdOn?.rate,
                quantity: quantity,
                totalPrice: quantity * bookingAdOn?.rate,
            };

            console.log('New  booking add-on', newAdOn);

            const items = [...selectedAdOns];
            items.splice(itemIndex, 1, { ...newAdOn });
            getBookingAdOns([...items]);
        }
    }, [bookingAdOn, getBookingAdOns, quantity, selectedAdOns]);

    useEffect(() => {
        handleQuantityChange();
    }, [handleQuantityChange]);

    const onItemRemove = () => {
        if (bookingAdOn?.id) {
            deleteBookingAddOn({
                variables: {
                    id: bookingAdOn?.id,
                },
            });
        }

        const itemIndex = selectedAdOns?.indexOf(bookingAdOn);
        const items = [...selectedAdOns?.filter((resource) => selectedAdOns.indexOf(resource) !== itemIndex)];
        getBookingAdOns(items);
    };

    const displayAlert = (props: any) => {
        setAlert(
            <SweetAlert
                {...props}
                showCancel
                confirmBtnText='Continue'
                confirmBtnBsStyle='danger'
                title='Are you sure?'
                onConfirm={() => {
                    onItemRemove();
                    setAlert(null);
                }}
                onCancel={() => {
                    setAlert(null);
                }}
                focusCancelBtn>
                This add-on will be removed from the system.
            </SweetAlert>,
        );
    };

    return (
        <div className='row mt-4'>
            {alert}
            <div className='col-3'>
                <p className='small-text overflow-text'>{bookingAdOn?.title}</p>
            </div>
            <div className='col-3 d-flex justify-content-center'>
                <span
                    style={{
                        width: 30,
                        height: 30,
                    }}
                    className='btn rounded-circle btn-dark btn-sm'
                    onClick={minuteQuantity}>
                    -
                </span>
                <p className='pr-2 small-text'>{bookingAdOn?.quantity}</p>
                <span
                    style={{
                        width: 30,
                        height: 30,
                    }}
                    className='btn rounded-circle btn-dark btn-sm'
                    onClick={addQuantity}>
                    +
                </span>
            </div>
            <div className='col-3 d-flex justify-content-center'>
                <div>
                    <p className='small-text overflow-text'>R{bookingAdOn?.totalPrice.toFixed(2)}</p>
                </div>
            </div>
            <div className='col-3 d-flex justify-content-end'>
                <div className='text-right'>
                    <ClearIcon
                        sx={{
                            cursor: 'pointer',
                        }}
                        color='error'
                        onClick={() => displayAlert({ warning: true })}
                    />
                </div>
            </div>
        </div>
    );
};

export default BookingAdOnItem;
