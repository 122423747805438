import { gql } from '@apollo/client';

export const GET_SERVICE_PROVIDERS = gql`
    query GetServiceProviders {
        providers: service_provider(where: { deleted_at: { _is_null: true } }) {
            id
            name
            registrationNumber: registration_number
            email: email_address
            contactNumber: contact_number
            status {
                value
                title
            }
        }
    }
`;

export const REMOVE_SERVICE_PROVIDER = gql`
    mutation RemoveServiceProvider($id: uuid!) {
        provider: update_service_provider_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const ADD_SERVICE_PROVIDER = gql`
    mutation AddServiceProvider($provider: service_provider_insert_input!) {
        provider: insert_service_provider_one(object: $provider) {
            id
        }
    }
`;

export const UPDATE_SERVICE_PROVIDER = gql`
    mutation UpdateServiceProvider($id: uuid!, $provider: service_provider_set_input!) {
        provider: update_service_provider_by_pk(pk_columns: { id: $id }, _set: $provider) {
            id
        }
    }
`;

export const GET_SERVICE_PROVIDER_LIST = gql`
    query GetServiceProviderStatusList {
        statusList: service_provider_status {
            value
            title
        }
    }
`;

export const GET_SERVICE_PROVIDER = gql`
    query GetServiceProvider($id: uuid!) {
        provider: service_provider_by_pk(id: $id) {
            id
            name
            logoPath: logo_path
            registrationNumber: registration_number
            email: email_address
            contactNumber: contact_number
            vatNumber: vat_number
            representedBy: represented_by
            representationCapacity: representation_capacity
            addresses {
                id
                building
                city
                suburb
                postalCode: postal_code
                street
                country
                province
            }
            status {
                value
                title
            }
        }
        statusList: service_provider_status {
            value
            title
        }
    }
`;

export const GET_SERVICE_AGREEMENTS = gql`
    query GetServiceAgreements($provider_id: uuid!) {
        agreement: service_agreement(
            where: { service_provider_id: { _eq: $provider_id }, deleted_at: { _is_null: true } }
        ) {
            id
            serviceProvider: service_provider {
                id
                name
            }
            startDate: start_date
            endDate: end_date
            status {
                value
                title
            }
            documentPath: document_path
            dateSigned: date_signed
            priceExclVat: price_excl_vat
            priceInclVat: price_incl_vat
            billingPeriod: agreement_billing_period {
                value
                title
            }
        }
    }
`;

export const GET_SERVICE_AGREEMENT = gql`
    query GetServiceAgreement($id: uuid!) {
        agreement: service_agreement_by_pk(id: $id) {
            id
            serviceProvider: service_provider {
                id
                name
            }
            startDate: start_date
            endDate: end_date
            status {
                value
                title
            }
            documentPath: document_path
            dateSigned: date_signed
            priceExclVat: price_excl_vat
            priceInclVat: price_incl_vat
            billingPeriod: agreement_billing_period {
                value
                title
            }
        }
        billingPeriodList: service_agreement_billing_period {
            value
            title
        }
        statusList: service_agreement_status {
            value
            title
        }
    }
`;

export const REMOVE_SERVICE_AGREEMENT = gql`
    mutation RemoveServiceAgreement($id: uuid!) {
        agreement: update_service_agreement_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const ADD_SERVICE_AGREEMENT = gql`
    mutation AddServiceAgreement($agreement: service_agreement_insert_input!) {
        agreement: insert_service_agreement_one(object: $agreement) {
            id
        }
    }
`;

export const GET_AGREEMENT_BILLING_PERIOD_LIST = gql`
    query GetAgreementBillingPeriodList {
        billingPeriodList: service_agreement_billing_period {
            value
            title
        }
        statusList: service_agreement_status {
            value
            title
        }
    }
`;

export const UPDATE_SERVICE_AGREEMENT = gql`
    mutation UpdateServiceAgreement($id: uuid!, $agreement: service_agreement_set_input!) {
        agreement: update_service_agreement_by_pk(pk_columns: { id: $id }, _set: $agreement) {
            id
        }
    }
`;

export const UPDATE_OR_ADD_ADDRESS = gql`
    mutation AddOrUpdateServiceProvderAddress($address: service_provider_address_insert_input!) {
        address: insert_service_provider_address_one(
            object: $address
            on_conflict: {
                constraint: service_provider_address_pkey
                update_columns: [building, city, country, postal_code, province, street, suburb]
            }
        ) {
            id
        }
    }
`;
