import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Row, Spinner } from 'reactstrap';

import { useParams } from 'react-router-dom';
import { PhoneInput } from 'react-international-phone';
import { useForm } from 'react-hook-form';

import { useMutation, useQuery } from '@apollo/client';
import { LinearProgress, MenuItem, Select } from '@mui/material';

import { PhoneNumberUtil } from 'google-libphonenumber';
import useToast from '../../../../hooks/useToast';
import { GET_SERVICE_PROVIDER, UPDATE_SERVICE_PROVIDER } from '../../../../graphql/service-providers.graphql';
import { uploadImage } from '../../../../hooks/upload-image.hook';
import FeaturedImage from '../../../../components/Image component/ImageContainer';
import { ServiceProvider, ServiceProviderStatus } from '../../../../models/service-providers.model';
import ServiceProviderAddressDetails from './ServiceProviderAddressDetails';

type FormData = {
    name: string;
    registrationNumber: string;
    vatNumber: string;
    representedBy: string;
    representationCapacity: string;
    contactNumber: string;
    emailAddress: string;
    status: string;
};

export default function ServiceProviderDetailsForm() {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const params: any = useParams();

    const isPhoneValid = (phone: string) => {
        try {
            return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
        } catch (error) {
            return false;
        }
    };

    const fileInputRef: any = useRef(null);
    const [imageFile, setImageFile] = useState<any>();
    const [image, setImage] = useState<string>();
    const { showToast, Notification } = useToast();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { data, loading, error } = useQuery(GET_SERVICE_PROVIDER, {
        variables: {
            id: params?.id,
        },
    });

    const [updateServiceProvider, { data: addedProvider, error: addingError }] = useMutation(UPDATE_SERVICE_PROVIDER, {
        refetchQueries: [GET_SERVICE_PROVIDER],
    });

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm<FormData>();

    const onImageChange = (e: any) => {
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setImageFile(e.target.files[0]);
        setImage(imageUrl);
    };

    useEffect(() => {
        if (data) {
            const serviceProvider: ServiceProvider = data?.provider;
            setImage(`${process.env.REACT_APP_IMAGE_URL}/${serviceProvider.logoPath}`);
        }
    }, [data]);

    useEffect(() => {
        if (error) {
            showToast('An error has occurred while trying to load the page', 'danger');
            console.log('Error', error);
        }
    }, [error]);

    useEffect(() => {
        if (addingError) {
            showToast('An error has occurred while trying to add service provider', 'danger');
            console.log('Adding error', addingError);
            setIsLoading(false);
        }
    }, [addingError]);

    useEffect(() => {
        if (addedProvider) {
            showToast('Successfully added service provider', 'success');

            setIsLoading(false);
        }
    }, [addedProvider]);

    const onSubmit = async (data: FormData) => {
        let imageKey;
        if (!isPhoneValid(data.contactNumber)) {
            setError('contactNumber', { type: 'custom', message: '*Please enter a valid phone number' });
            return;
        }

        setIsLoading(true);

        if (imageFile) imageKey = await uploadImage(imageFile, 'locations_image');
        if (!imageFile && serviceProvider.logoPath) imageKey = serviceProvider.logoPath;
        if (imageKey === 'error') {
            showToast('An error has occurred while tying to upload this image', 'danger');
            setIsLoading(true);
            return;
        }

        const provider = {
            name: data.name,
            registration_number: data.registrationNumber,
            email_address: data.emailAddress,
            vat_number: data.vatNumber,
            contact_number: data.contactNumber,
            represented_by: data.representedBy,
            representation_capacity: data.representationCapacity,
            logo_path: imageKey,
            status_enum: data.status,
        };

        await updateServiceProvider({
            variables: {
                id: params?.id,
                provider: provider,
            },
        });
    };

    if (loading) {
        return <LinearProgress />;
    }

    const serviceProvider: ServiceProvider = data?.provider;
    const serviceProviderStatusList: ServiceProviderStatus[] = data?.statusList;

    return (
        <>
            {Notification}
            <Row className='mt-4'>
                <Col md='12' lg='6'>
                    <Card>
                        <CardHeader>
                            <Row noGutters={true}>
                                <h3 className='m-0'>Service Provider Details</h3>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <FormGroup>
                                    <label className='form-control-label'>Logo</label>
                                    <FeaturedImage fileInputRef={fileInputRef} imageSource={image} />
                                    <input
                                        ref={fileInputRef}
                                        type='file'
                                        accept='image/*'
                                        onChange={(e) => {
                                            onImageChange(e);
                                        }}
                                        style={{ display: 'none' }}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='name'>
                                        Name
                                    </label>
                                    <input
                                        className='form-control'
                                        placeholder="Enter service provider's name..."
                                        {...register('name', { required: true, value: serviceProvider.name ?? '' })}
                                        type='text'
                                    />
                                    {errors.name && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='street_address'>
                                        Registration Number
                                    </label>
                                    <input
                                        className='form-control'
                                        {...register('registrationNumber', {
                                            required: true,
                                            value: serviceProvider.registrationNumber ?? '',
                                        })}
                                        placeholder='Enter registration number...'
                                        type='text'
                                    />
                                    {errors.registrationNumber && (
                                        <span className='invalid'>*This field is required</span>
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='street_address'>
                                        VAT Number
                                    </label>
                                    <input
                                        className='form-control'
                                        {...register('vatNumber', {
                                            required: true,
                                            value: serviceProvider?.vatNumber ?? '',
                                        })}
                                        placeholder='Enter VAT number...'
                                        type='text'
                                    />
                                    {errors.vatNumber && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='city'>
                                        Represented By(Optional)
                                    </label>
                                    <input
                                        className='form-control'
                                        {...register('representedBy', {
                                            required: false,
                                            value: serviceProvider.representedBy ?? '',
                                        })}
                                        placeholder='Enter represented by...'
                                        type='text'
                                    />{' '}
                                    {errors.representedBy && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label'>Representation Capacity(Optional)</label>
                                    <input
                                        className='form-control'
                                        placeholder='Enter representation capacity...'
                                        {...register('representationCapacity', {
                                            required: false,
                                            value: serviceProvider.representationCapacity ?? '',
                                        })}
                                        type='text'
                                    />
                                    {errors.representationCapacity && (
                                        <span className='invalid'>*This field is required</span>
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label'>Status</label>
                                    <Select
                                        className='form-control'
                                        defaultValue={serviceProvider?.status.value ?? ' '}
                                        {...register('status', {
                                            required: true,
                                            value: serviceProvider.status?.value ?? '',
                                        })}
                                        sx={{ borderRadius: '12px', padding: 0 }}>
                                        <MenuItem disabled value={' '}>
                                            {'Select status for service provider...'}
                                        </MenuItem>
                                        {serviceProviderStatusList.map((status: ServiceProviderStatus) => {
                                            return (
                                                <MenuItem key={status.value} value={status.value}>
                                                    {status.title}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    {errors.status && <span className='invalid'>*This field is required</span>}
                                </FormGroup>

                                <FormGroup>
                                    <label className='form-control-label' htmlFor='email'>
                                        Contact Number
                                    </label>
                                    <PhoneInput
                                        defaultCountry='za'
                                        placeholder='Enter contact number...'
                                        inputClassName='form-control'
                                        value={serviceProvider?.contactNumber ?? ''}
                                        style={
                                            {
                                                '--react-international-phone-width': '100%',
                                                '--react-international-phone-height': '44px',
                                                '--react-international-phone-border-radius': '12px',
                                            } as React.CSSProperties
                                        }
                                        {...register('contactNumber', {
                                            required: true,
                                            value: serviceProvider.contactNumber ?? '',
                                        })}
                                        onChange={(phone) => setValue('contactNumber', phone)}
                                    />
                                    {errors.contactNumber && (
                                        <span className='invalid'>{errors.contactNumber.message}</span>
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='email'>
                                        Email Address
                                    </label>
                                    <input
                                        className='form-control'
                                        {...register('emailAddress', {
                                            required: true,
                                            value: serviceProvider.email ?? '',
                                        })}
                                        placeholder='Enter email address...'
                                        type='email'
                                    />
                                    {errors.emailAddress && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                                <div className='d-flex h-100'>
                                    <div className='align-self-end ml-auto'>
                                        <Button
                                            type='submit'
                                            className='btn btn-dark mt-4 btn-block'
                                            disabled={isLoading}>
                                            {isLoading && (
                                                <span>
                                                    <Spinner />
                                                </span>
                                            )}
                                            {!isLoading && <span>Update</span>}
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
                <Col md='12' lg='6'>
                    <ServiceProviderAddressDetails address={serviceProvider.addresses[0]} />
                </Col>
            </Row>
        </>
    );
}
