import React, { useEffect } from 'react';
import { Card, CardBody } from 'reactstrap';
import {
    SurveyQuestion,
    SurveyQuestionItem,
    SurveyQuestionItemDropdownOption,
    SurveyQuestionItemListOption,
    SurveyQuestionItemType,
} from '../../../../models/survey.modesl';
import QuestionSectionItem from './QuestionSectionItem';
import { v4 } from 'uuid';
import {
    GET_SURVEY,
    REMOVE_DROPDOWN_OPTION,
    REMOVE_LIST_OPTION,
    REMOVE_QUESTION,
    REMOVE_QUESTION_ITEM,
} from '../../../../graphql/survey.graphql';
import { useMutation } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/Delete';

interface QuestionSectionProps {
    questions: SurveyQuestion[];
    question: SurveyQuestion;
    typeList: SurveyQuestionItemType[];
    onQuestionChange: (questions: SurveyQuestion[]) => void;
}

export default function QuestionSection({ questions, question, typeList, onQuestionChange }: QuestionSectionProps) {
    const [removeItem, { error }] = useMutation(REMOVE_QUESTION_ITEM, {
        refetchQueries: [GET_SURVEY],
    });
    const [removeDropdown, { error: removeError }] = useMutation(REMOVE_DROPDOWN_OPTION, {
        refetchQueries: [GET_SURVEY],
    });

    const [removedListOption, { error: listRemoveError }] = useMutation(REMOVE_LIST_OPTION, {
        refetchQueries: [GET_SURVEY],
    });

    const [removeQuestion, { error: questionRemove }] = useMutation(REMOVE_QUESTION, {
        refetchQueries: [GET_SURVEY],
    });

    const onItemAdd = () => {
        const questionIndex = questions.indexOf(question);
        const newQuestions = [...questions];

        const newItem: SurveyQuestionItem = {
            title: '',
            required: false,
            dropDownOptions: [],
            listOptions: [],
            key: v4(),
            type: {
                title: '',
                value: 'text',
            },
        };

        newQuestions[questionIndex].items = [...newQuestions[questionIndex].items, newItem];
        onQuestionChange(newQuestions);
    };

    const onRemoveQuestion = () => {
        const newQuestions = [...questions];
        const questionIndex = questions.indexOf(question);
        const questionId = questions[questionIndex]?.id ?? undefined;

        if (questionId) {
            removeQuestion({
                variables: {
                    id: questionId,
                },
            });
        }
        onQuestionChange([...newQuestions.filter((q) => newQuestions.indexOf(q) !== questionIndex)]);
    };

    useEffect(() => {
        if (error) {
            console.log('Item delete error', error);
        }
    }, [error]);

    useEffect(() => {
        if (removeError) {
            console.log('Remove error', removeError);
        }
    }, [removeError]);

    useEffect(() => {
        if (listRemoveError) {
            console.log('Remove error', listRemoveError);
        }
    }, [listRemoveError]);

    useEffect(() => {
        if (questionRemove) {
            console.log('Remove question', questionRemove);
        }
    }, [questionRemove]);

    const onQuestionTitleChange = (value: string) => {
        const questionIndex = questions.indexOf(question);
        const newQuestions = [...questions];
        newQuestions[questionIndex].title = value;
        onQuestionChange(newQuestions);
    };

    const onQuestionSubtitleChange = (value: string) => {
        const questionIndex = questions.indexOf(question);
        const newQuestions = [...questions];
        newQuestions[questionIndex].subtitle = value;
        onQuestionChange(newQuestions);
    };

    const onItemTypeChange = (type: string, item: SurveyQuestionItem) => {
        const questionIndex = questions.indexOf(question);
        const newQuestions = [...questions];
        const itemIndex = newQuestions[questionIndex].items.indexOf(item);
        newQuestions[questionIndex].items[itemIndex].type = { value: type, title: '' };
        onQuestionChange(newQuestions);
    };

    const onDropdownOptionAdd = (item: SurveyQuestionItem) => {
        const questionIndex = questions.indexOf(question);
        const newQuestions = [...questions];
        const itemIndex = newQuestions[questionIndex].items.indexOf(item);
        const newOption: SurveyQuestionItemDropdownOption = {
            value: '',
            key: v4(),
        };
        newQuestions[questionIndex].items[itemIndex].dropDownOptions = [
            ...newQuestions[questionIndex].items[itemIndex].dropDownOptions,
            newOption,
        ];
        onQuestionChange(newQuestions);
    };

    const onDropdownOptionTitleChange = (item: SurveyQuestionItem, index: number, value: string) => {
        const questionIndex = questions.indexOf(question);
        const newQuestions = [...questions];
        const itemIndex = newQuestions[questionIndex].items.indexOf(item);
        newQuestions[questionIndex].items[itemIndex].dropDownOptions[index].value = value;
        onQuestionChange(newQuestions);
    };

    const onListOptionTitleChange = (item: SurveyQuestionItem, index: number, value: string) => {
        const questionIndex = questions.indexOf(question);
        const newQuestions = [...questions];
        const itemIndex = newQuestions[questionIndex].items.indexOf(item);
        newQuestions[questionIndex].items[itemIndex].listOptions[index].value = value;
        onQuestionChange(newQuestions);
    };

    const onListOptionAdd = (item: SurveyQuestionItem) => {
        const questionIndex = questions.indexOf(question);
        const newQuestions = [...questions];
        const itemIndex = newQuestions[questionIndex].items.indexOf(item);
        const newOption: SurveyQuestionItemListOption = {
            value: '',
            key: v4(),
        };

        newQuestions[questionIndex].items[itemIndex].listOptions = [
            ...newQuestions[questionIndex].items[itemIndex].listOptions,
            newOption,
        ];
        onQuestionChange(newQuestions);
    };

    const onDropdownOptionRemove = (item: SurveyQuestionItem, index: number) => {
        const questionIndex = questions.indexOf(question);
        const newQuestions = [...questions];
        const itemIndex = newQuestions[questionIndex].items.indexOf(item);
        const optionId = newQuestions[questionIndex].items[itemIndex].dropDownOptions[index]?.id ?? undefined;
        if (optionId) {
            removeDropdown({
                variables: {
                    id: optionId,
                },
            });
        }
        newQuestions[questionIndex].items[itemIndex].dropDownOptions = [
            ...newQuestions[questionIndex].items[itemIndex].dropDownOptions.filter(
                (option) => newQuestions[questionIndex].items[itemIndex].dropDownOptions.indexOf(option) !== index,
            ),
        ];
        onQuestionChange(newQuestions);
    };

    const onListOptionRemove = (item: SurveyQuestionItem, index: number) => {
        const questionIndex = questions.indexOf(question);
        const newQuestions = [...questions];
        const itemIndex = newQuestions[questionIndex].items.indexOf(item);
        const optionId = newQuestions[questionIndex].items[itemIndex].listOptions[index]?.id ?? undefined;
        if (optionId) {
            removedListOption({
                variables: {
                    id: optionId,
                },
            });
        }

        newQuestions[questionIndex].items[itemIndex].listOptions = [
            ...newQuestions[questionIndex].items[itemIndex].listOptions.filter(
                (option) => newQuestions[questionIndex].items[itemIndex].listOptions.indexOf(option) !== index,
            ),
        ];
        onQuestionChange(newQuestions);
    };

    const onRequiredChanges = (item: SurveyQuestionItem) => {
        const questionIndex = questions.indexOf(question);
        const newQuestions = [...questions];
        const itemIndex = newQuestions[questionIndex].items.indexOf(item);
        newQuestions[questionIndex].items[itemIndex].required = !newQuestions[questionIndex].items[itemIndex].required;
        onQuestionChange(newQuestions);
    };

    const onTitleChanges = (item: SurveyQuestionItem, value: string) => {
        const questionIndex = questions.indexOf(question);
        const newQuestions = [...questions];
        const itemIndex = newQuestions[questionIndex].items.indexOf(item);
        newQuestions[questionIndex].items[itemIndex].title = value;
        onQuestionChange(newQuestions);
    };

    const onItemRemove = (item: SurveyQuestionItem) => {
        const questionIndex = questions.indexOf(question);
        const newQuestions = [...questions];
        const itemIndex = newQuestions[questionIndex].items.indexOf(item);
        if (item?.id) {
            removeItem({
                variables: {
                    id: item?.id,
                },
            });
        }
        newQuestions[questionIndex].items = [
            ...newQuestions[questionIndex].items.filter(
                (item) => newQuestions[questionIndex].items.indexOf(item) !== itemIndex,
            ),
        ];
        onQuestionChange(newQuestions);
    };

    return (
        <Card>
            <CardBody>
                <div className='row mb-2'>
                    <div className='col-6'>
                        <h3>{`Question ${question.order} of ${questions.length}`}</h3>
                    </div>
                    <div className='col-6 text-right'>
                        <button className='btn btn-round btn-sm btn-dark' onClick={onItemAdd}>
                            <span className='btn-inner-text'>Add item</span>
                        </button>
                        <DeleteIcon sx={{ cursor: 'pointer' }} onClick={onRemoveQuestion} />
                    </div>
                </div>
                <div className='row mb-4'>
                    <div className='col-lg-12'>
                        <label className='form-control-label'>Title</label>
                        <input
                            placeholder='Question title'
                            value={question.title ?? ''}
                            className='form-control'
                            onChange={(e) => onQuestionTitleChange(e.target.value)}
                        />
                    </div>
                </div>
                <div className='row mb-6'>
                    <div className='col-lg-12'>
                        <label className='form-control-label'>Subtitle</label>
                        <textarea
                            placeholder='Subtitle(optional)'
                            className='form-control'
                            value={question.subtitle ?? ''}
                            onChange={(e) => onQuestionSubtitleChange(e.target.value)}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-12'>
                        {question.items.map((item) => {
                            return (
                                <QuestionSectionItem
                                    onItemTypeChange={onItemTypeChange}
                                    onListOptionTitleChange={onListOptionTitleChange}
                                    onListOptionAdd={onListOptionAdd}
                                    onDropdownOptionRemove={onDropdownOptionRemove}
                                    onListOptionRemove={onListOptionRemove}
                                    onDropdownOptionTitleChange={onDropdownOptionTitleChange}
                                    onDropdownOptionAdd={onDropdownOptionAdd}
                                    onRequiredChanges={onRequiredChanges}
                                    onItemRemove={onItemRemove}
                                    onItemTitleChange={onTitleChanges}
                                    key={item.key}
                                    typeList={typeList}
                                    item={item}
                                />
                            );
                        })}
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}
