/* eslint-disable react-hooks/exhaustive-deps */
import { LinearProgress, MenuItem, Select } from '@mui/material';
import React, { useEffect } from 'react';

import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Row, Spinner } from 'reactstrap';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ADD_MAIL_LIST, GET_MAIL_LISTS, GET_STATUS_LIST } from '../../../../graphql/mail-list.graphql';
import { Status } from '../../../../models/newsletter-post.model';
import useToast from '../../../../hooks/useToast';

interface FormData {
    name: string;
    status: string;
}

export default function MailListAddFormView() {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<FormData>();
    const { data, loading, error } = useQuery(GET_STATUS_LIST);
    const [addMailList, { loading: adding, error: addingError }] = useMutation(ADD_MAIL_LIST, {
        refetchQueries: [GET_MAIL_LISTS],
    });
    const { showToast, Notification } = useToast();

    const navigate = useNavigate();

    const redirectToEmails = () => {
        navigate(-1);
    };

    const onSubmit = async (data: FormData) => {
        console.log('Form data', data);

        const mailList = {
            name: data.name,
            status_enum: data.status,
        };

        await addMailList({
            variables: {
                mailList,
            },
        });

        showToast('Successfully added a mail list', 'success');
        reset();
    };

    useEffect(() => {
        if (error) {
            console.log('Error', error);
        }
    }, [error]);

    useEffect(() => {
        if (addingError) {
            showToast('An error has occurred while trying to add a mail list', 'danger');
        }
    }, [addingError]);

    if (loading) {
        return <LinearProgress />;
    }

    const statusList: Status[] = data?.statusList;

    return (
        <>
            {Notification}
            <Container className='mt-4' fluid>
                <Row>
                    <Col md='12' lg='6'>
                        <Card>
                            <CardHeader>
                                <Row noGutters={true}>
                                    <button
                                        className='btn btn-outline-primary btn-sm mr-4'
                                        onClick={(e) => redirectToEmails()}>
                                        <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                                        <span className='btn-inner-text'>Back</span>
                                    </button>
                                    <h3 className='m-0'>Add Mail List</h3>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='title'>
                                                    Name
                                                </label>
                                                <input
                                                    className='form-control'
                                                    {...register('name', { required: true })}
                                                    id='title'
                                                    placeholder='Enter post title...'
                                                    type='text'
                                                />
                                                {errors.name && (
                                                    <span className='invalid'>*This field is required</span>
                                                )}
                                            </FormGroup>
                                        </div>

                                        <div className='col-lg-12'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='status'>
                                                    Status
                                                </label>
                                                <Select
                                                    id='status'
                                                    sx={{ borderRadius: '12px', padding: '0' }}
                                                    className='form-control'
                                                    defaultValue={' '}
                                                    inputProps={{
                                                        ...register('status', {
                                                            required: true,
                                                        }),
                                                    }}>
                                                    <MenuItem disabled value=' '>
                                                        Select status...
                                                    </MenuItem>
                                                    {statusList?.map((status: any) => {
                                                        return (
                                                            <MenuItem key={status.value} value={status.value}>
                                                                {status.title}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormGroup>
                                        </div>
                                        {/**Type section */}
                                    </div>
                                    <div className='d-flex h-100'>
                                        <div className='align-self-end ml-auto'>
                                            <Button
                                                type='submit'
                                                className='btn btn-dark mt-4 btn-block'
                                                disabled={adding}>
                                                {!adding ? (
                                                    <span>Add</span>
                                                ) : (
                                                    <span>
                                                        <Spinner />
                                                    </span>
                                                )}
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
