/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import { useLocation, NavLink as NavLinkRRD, Link } from 'react-router-dom';
import classnames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Collapse,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import { useSelector } from 'react-redux';
import { User } from '../../models/user.model';
import { Auth } from 'aws-amplify';

interface PropType {
    toggleSidenav: any;
    sidenavOpen: boolean;
    routes: any;
    logo: any;
    rtlActive: boolean;
}

function Sidebar({ toggleSidenav, sidenavOpen, routes, logo, rtlActive }: PropType) {
    const [state, setState] = React.useState({});
    const location = useLocation();
    const user: User | null = useSelector((state: any) => state.user.userInfo);
    const rolePermissions = user?.role.rolePermissions;

    const getCollapseInitialState = useCallback(
        (routes: any) => {
            for (let i = 0; i < routes.length; i++) {
                const permission = routes[i].permission;
                const rolePermission = rolePermissions?.find(
                    (rolePermission) => rolePermission.permission.tag === permission,
                );
                if (rolePermission?.read) {
                    if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
                        return true;
                    } else if (location.pathname.indexOf(routes[i].path) !== -1) {
                        return true;
                    }
                }
            }
            return false;
        },
        [location.pathname, rolePermissions],
    );

    const getCollapseStates = useCallback(
        (routes: any) => {
            let initialState = {};
            routes.map((prop: any, key: any) => {
                const permission = prop.permission;
                const rolePermission = rolePermissions?.find(
                    (rolePermission) => rolePermission.permission.tag === permission,
                );
                // console.log("ROLE PERMISSION", rolePermission);
                if (prop.collapse && !rolePermission?.read) {
                    initialState = {
                        [prop.state]: getCollapseInitialState(prop.views),
                        ...getCollapseStates(prop.views),
                        ...initialState,
                    };
                }
                return null;
            });
            return initialState;
        },
        [getCollapseInitialState, rolePermissions],
    );

    // useEffect(() => {
    //     setState(getCollapseStates(routes));
    // }, [routes, getCollapseStates]);

    const activeRoute = (routeName: string) => {
        return location.pathname.indexOf(routeName) > -1 ? 'active' : '';
    };

    const onMouseEnterSidenav = () => {
        if (!document.body.classList.contains('g-sidenav-pinned')) {
            document.body.classList.add('g-sidenav-show');
        }
    };

    const onMouseLeaveSidenav = () => {
        if (!document.body.classList.contains('g-sidenav-pinned')) {
            document.body.classList.remove('g-sidenav-show');
        }
    };

    const closeSidenav = () => {
        if (window.innerWidth < 1200) {
            toggleSidenav();
        }
    };

    const createLinks = (routes: object[]) => {
        return routes
            .filter((route: any) => !route.hidden)
            .filter((route: any) => {
                const permission = route.permission;
                const rolePermission = rolePermissions?.find(
                    (rolePermission) => rolePermission.permission.tag === permission,
                );
                return rolePermission?.read;
            })
            .map((prop: any, key: any) => {
                if (prop.redirect) {
                    return null;
                }
                if (prop.collapse) {
                    var st: any = {};
                    st[prop.state] = !state[prop.state as keyof object];
                    return (
                        <NavItem key={key}>
                            <NavLink
                                href='#pablo'
                                data-toggle='collapse'
                                aria-expanded={state[prop.state as keyof object]}
                                className={classnames({
                                    active: getCollapseInitialState(prop.views),
                                })}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setState(st);
                                }}>
                                {prop.icon ? (
                                    <>
                                        <i className={prop.icon} />
                                        <span className='nav-link-text'>{prop.name}</span>
                                    </>
                                ) : prop.miniName ? (
                                    <>
                                        <span className='sidenav-mini-icon'> {prop.miniName} </span>
                                        <span className='sidenav-normal'> {prop.name} </span>
                                    </>
                                ) : null}
                            </NavLink>
                            <Collapse isOpen={state[prop.state as keyof object]}>
                                <Nav className='nav-sm flex-column'>{createLinks(prop.views)}</Nav>
                            </Collapse>
                        </NavItem>
                    );
                }

                return (
                    <NavItem className={activeRoute(prop.layout + prop.path)} key={key}>
                        <NavLink
                            to={prop.layout + prop.path}
                            activeClassName=''
                            onClick={closeSidenav}
                            tag={NavLinkRRD}>
                            {prop.icon !== undefined ? (
                                <>
                                    <i className={prop.icon} />
                                    <span className='nav-link-text'>{prop.name}</span>
                                </>
                            ) : prop.miniName !== undefined ? (
                                <>
                                    <span className='sidenav-mini-icon'> {prop.miniName} </span>
                                    <span className='sidenav-normal'> {prop.name} </span>
                                </>
                            ) : (
                                prop.name
                            )}
                        </NavLink>
                    </NavItem>
                );
            });
    };

    let navbarBrandProps;
    if (logo && logo.innerLink) {
        navbarBrandProps = {
            to: logo.innerLink,
            tag: Link,
        };
    } else if (logo && logo.outterLink) {
        navbarBrandProps = {
            href: logo.outterLink,
            target: '_blank',
        };
    }

    const scrollBarInner = (
        <div className='scrollbar-inner'>
            <div className='sidenav-header d-flex align-items-center'>
                {logo ? (
                    <NavbarBrand {...navbarBrandProps}>
                        <h1>Colab</h1>
                    </NavbarBrand>
                ) : null}
                <div className='ml-auto'>
                    <div
                        className={classnames('sidenav-toggler d-none d-xl-block', {
                            active: sidenavOpen,
                        })}
                        onClick={toggleSidenav}>
                        <div className='sidenav-toggler-inner'>
                            <i className='sidenav-toggler-line' />
                            <i className='sidenav-toggler-line' />
                            <i className='sidenav-toggler-line' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='navbar-inner'>
                <Collapse navbar isOpen={true}>
                    <Nav navbar>{createLinks(routes)}</Nav>
                    <Nav className='mb-md-3' navbar></Nav>
                </Collapse>
            </div>
            <UncontrolledDropdown nav className='mt-8'>
                <DropdownToggle className='nav-link pr-0' color='' tag='a'>
                    <Media className='align-items-center'>
                        <span className='avatar avatar-sm rounded-circle'>
                            <img alt='...' src={require('../../assets/img/theme/team-4.jpg')} />
                            {/* <UserAvatar name={`${user.name} ${user.surname}`} /> */}
                        </span>
                        <Media className='ml-2 d-none d-lg-block'>
                            <span className='mb-0 text-sm font-weight-bold'>
                                {user.name} {user.surname}
                            </span>
                        </Media>
                    </Media>
                </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem
                        href='#pablo'
                        onClick={async (e) => {
                            e.preventDefault();

                            try {
                                await Auth.signOut({ global: true });
                            } catch (error) {
                                console.log('error signing out: ', error);
                            }
                        }}>
                        <i className='ni ni-user-run' />
                        <span>Logout</span>
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    );

    return (
        <Navbar
            className={
                'sidenav navbar-vertical navbar-expand-xs navbar-light bg-white ' + (rtlActive ? '' : 'fixed-left')
            }
            onMouseEnter={onMouseEnterSidenav}
            onMouseLeave={onMouseLeaveSidenav}>
            {navigator.platform.indexOf('Win') > -1 ? (
                <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
            ) : (
                scrollBarInner
            )}
        </Navbar>
    );
}

Sidebar.defaultProps = {
    routes: [{}],
    toggleSidenav: () => {},
    sidenavOpen: false,
    rtlActive: false,
};

export default Sidebar;
