import React, { useEffect } from 'react';
import { Button, Card, CardBody, FormGroup } from 'reactstrap';
import { BillingAddress } from '../../../../models/client.model';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { GET_SERVICE_PROVIDER, UPDATE_OR_ADD_ADDRESS } from '../../../../graphql/service-providers.graphql';
import useToast from '../../../../hooks/useToast';
import Spinner from '../../../../components/Spinner/Spinner';

interface ServiceProviderAddressDetailsProps {
    address: BillingAddress;
}

type FormData = {
    building: string;
    street: string;
    suburb: string;
    city: string;
    province: string;
    country: string;
    postalCode: string;
};

export default function ServiceProviderAddressDetails({ address }: ServiceProviderAddressDetailsProps) {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues: {
            building: address?.building ?? '',
            street: address?.street ?? '',
            suburb: address?.suburb ?? '',
            city: address?.city ?? '',
            province: address?.province ?? '',
            country: address?.country ?? '',
            postalCode: address?.postalCode ?? '',
        },
    });

    const params: any = useParams();
    const { showToast, Notification } = useToast();

    const [updateAddress, { data, loading, error }] = useMutation(UPDATE_OR_ADD_ADDRESS, {
        refetchQueries: [GET_SERVICE_PROVIDER],
    });

    const onSubmit = (formData: FormData) => {
        const addressData = {
            id: address?.id ?? undefined,
            street: formData.street ?? '',
            suburb: formData.suburb ?? '',
            city: formData.city ?? '',
            building: formData.building ?? '',
            postal_code: formData.postalCode ?? '',
            service_provider_id: params?.id,
            country: formData.country ?? '',
            province: formData.province ?? '',
        };

        updateAddress({
            variables: {
                address: addressData,
            },
        });
    };

    useEffect(() => {
        if (data) {
            showToast('Successfully updated address', 'success');
        }
    }, [data]);

    useEffect(() => {
        if (error) {
            showToast('An error has occurred while trying to update the address', 'danger');
        }
    }, [error]);

    return (
        <>
            {Notification}
            <Card>
                <CardBody>
                    <h3>Service Provider Address</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='row'>
                            <div className='col-lg-6 col-md-12'>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='name'>
                                        Building name (optional)
                                    </label>
                                    <input
                                        className='form-control'
                                        id='company-name'
                                        {...register('building', { required: false })}
                                        placeholder='Enter building name...'
                                        type='text'
                                    />
                                    {errors.building && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                            </div>
                            <div className='col-lg-6 col-md-12'>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='street'>
                                        Street address
                                    </label>
                                    <input
                                        className='form-control'
                                        id='street'
                                        {...register('street', { required: true })}
                                        placeholder='Enter street address...'
                                        type='text'
                                    />
                                    {errors.street && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                            </div>
                            <div className='col-lg-6 col-md-12'>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='suburb'>
                                        Suburb
                                    </label>
                                    <input
                                        className='form-control'
                                        id='suburb'
                                        {...register('suburb', { required: true })}
                                        placeholder='Enter suburb...'
                                        type='text'
                                    />
                                    {errors.suburb && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                            </div>
                            <div className='col-lg-6 col-md-12'>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='city'>
                                        City
                                    </label>
                                    <input
                                        className='form-control'
                                        id='city'
                                        placeholder='Enter city...'
                                        type='text'
                                        {...register('city', { required: true })}
                                    />
                                    {errors.city && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                            </div>
                            <div className='col-lg-6 col-md-12'>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='province'>
                                        Province
                                    </label>
                                    <input
                                        className='form-control'
                                        id='province'
                                        placeholder='Enter province...'
                                        type='text'
                                        {...register('province', { required: true })}
                                    />
                                    {errors.province && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                            </div>
                            <div className='col-lg-6 col-md-12'>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='country'>
                                        Country
                                    </label>
                                    <input
                                        className='form-control'
                                        id='country'
                                        placeholder='Enter country...'
                                        type='text'
                                        {...register('country', { required: true })}
                                    />
                                    {errors.country && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                            </div>
                            <div className='col-lg-6 col-md-12'>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='postal-code'>
                                        Postal Code
                                    </label>
                                    <input
                                        className='form-control'
                                        id='postal-code'
                                        placeholder='Enter postal code...'
                                        type='text'
                                        {...register('postalCode', { required: true })}
                                    />
                                    {errors.postalCode && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                            </div>
                        </div>

                        <div className='d-flex h-100'>
                            <div className='align-self-end ml-auto'>
                                <Button type='submit' className='btn btn-dark  mt-4'>
                                    {loading && (
                                        <span>
                                            <Spinner />
                                        </span>
                                    )}

                                    {!loading && <span>Update</span>}
                                </Button>
                            </div>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </>
    );
}
