/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table,
    UncontrolledTooltip,
} from 'reactstrap';
import { getPermission } from '../../../utils/user.utils';
import { User } from '../../../models/user.model';
import { useSelector } from 'react-redux';
// import { Link } from "react-router-dom";
import VendorMenuForm from './CreatePackageForm';
import { useMutation, useQuery } from '@apollo/client';
import { DELETE_MENU_PACKAGE, GET_MENU_DETAILS } from '../../../graphql/menu-packages.graphql';
import { MenuPackage } from '../../../models/vendor.model';
import _ from 'lodash';
import BasicMenu from '../../../components/Menu';
import SweetAlert from 'react-bootstrap-sweetalert';
import useToast from '../../../hooks/useToast';
import { useParams } from 'react-router-dom';
import { LinearProgress } from '@mui/material';
import PackageManageView from './PackageManageView';

function PackageListView() {
    const user: User | null = useSelector((state: any) => state.user.userInfo);
    const param: any = useParams();
    const [alert, setAlert] = useState<any>(null);
    const { showToast, Notification } = useToast();
    const [displayForm, setDisplayForm] = useState<boolean>(false);
    const [searchKey, setSearchKey] = useState<string>('');
    const [sortFiled, setSortField] = useState<string>('name');
    const [sortDirection, setSortDirection] = useState<string>('desc');
    const [page, setPage] = useState<number>(0);
    const [rowPerPage, setRowPerPage] = useState<number>(10);
    const [displayManageView, setDisplayManageView] = useState<boolean>();
    const [selectedData, setSelectedData] = useState<MenuPackage | null>(null);

    const {
        data: menuData,
        loading: isLoading,
        error: menuError,
    } = useQuery(GET_MENU_DETAILS, {
        variables: {
            id: param.id,
        },
    });

    const [removeMenuPackage, { data: removedPackage, error: removeError }] = useMutation(DELETE_MENU_PACKAGE, {
        // refetchQueries: [{ query: GET_MENU_DETAILS }, "getMenuDetails"],
        refetchQueries: ['getMenuDetails'],
    });

    const nextPage = () => {
        setPage(page + 1);
    };

    const previousPage = () => {
        setPage(page - 1);
    };

    const getRowValue = (value: string) => {
        setRowPerPage(parseInt(value));
    };

    const removePackage = (id: string) => {
        removeMenuPackage({
            variables: { id },
        });
    };

    useEffect(() => {
        if (menuError) {
            showToast('An error has occurred, please refresh your page', 'danger');
        }
    }, [menuError]);

    useEffect(() => {
        if (removeError) {
            showToast('An error has occurred while trying to remove a package', 'danger');
        }
    }, [removeError]);

    useEffect(() => {
        if (removedPackage) {
            showToast('Successfully removed package', 'success');
        }
    }, [removedPackage]);

    if (displayForm) {
        return <VendorMenuForm backToMenuPackages={() => setDisplayForm(false)} />;
    }

    if (displayManageView) {
        return (
            <PackageManageView backToMenuPackages={() => setDisplayManageView(false)} selectedPackage={selectedData} />
        );
    }

    if (isLoading) {
        return <LinearProgress />;
    }

    const packages: MenuPackage[] = menuData?.menu;

    const editPackage = (packageData: any) => {
        setDisplayManageView(true);
        setSelectedData(packageData);
    };

    const sortBy: any = (packages: MenuPackage[], field: string, direction: string) => {
        const orderedBookings = new Map([
            ['name', _.orderBy(packages, (p) => p.name, [direction === 'asc' ? 'asc' : 'desc'])],
            ['price', _.orderBy(packages, (p) => p.price, [direction === 'asc' ? 'asc' : 'desc'])],
            ['menuCategory', _.orderBy(packages, (p) => p.menuCategory.title, [direction === 'asc' ? 'asc' : 'desc'])],
            ['menuMealType', _.orderBy(packages, (p) => p.menuMealType?.title, [direction === 'asc' ? 'asc' : 'desc'])],
            ['menuType', _.orderBy(packages, (p) => p.menuType.title, [direction === 'asc' ? 'asc' : 'desc'])],
            ['menuStatus', _.orderBy(packages, (p) => p.menuStatus.title, [direction === 'asc' ? 'asc' : 'desc'])],
        ]);
        return orderedBookings.get(field);
    };

    let filteredMenuPackages = packages.filter((p: any) => {
        if (searchKey === '') {
            return p;
        }

        return (
            p.name.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            p.price.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            p.menuCategory.title.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            p.menuMealType?.title.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            p.menuType.title.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            p.menuStatus.title.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase())
        );
    });

    filteredMenuPackages = sortBy(filteredMenuPackages, sortFiled, sortDirection);

    const displayAlert = (props: any) => {
        setAlert(
            <SweetAlert
                {...props}
                showCancel
                confirmBtnText='Continue'
                confirmBtnBsStyle='danger'
                title='Are you sure?'
                onConfirm={() => {
                    removePackage(props.p.id);
                    setAlert(null);
                }}
                onCancel={() => {
                    setAlert(null);
                }}
                focusCancelBtn>
                This paackage will be removed from the system.
            </SweetAlert>,
        );
    };

    return (
        <>
            {Notification}
            {alert}
            <div>
                <Row className='mt-4'>
                    <div className='col'>
                        <Card>
                            <CardHeader className='border-0'>
                                <Row>
                                    <Col xs='6'>
                                        <h3 className='mb-0'>Menu packages</h3>
                                    </Col>
                                    {getPermission(user, 'locations', 'create') && (
                                        <Col className='text-right' xs='6'>
                                            <button
                                                className='btn btn-dark btn-sm'
                                                color='default'
                                                id='tooltip969372949'
                                                onClick={() => {
                                                    setDisplayForm(true);
                                                }}>
                                                Add package
                                            </button>
                                            <UncontrolledTooltip delay={0} target='tooltip969372949'>
                                                Add package
                                            </UncontrolledTooltip>
                                        </Col>
                                    )}
                                </Row>
                                <Row className='mt-4'>
                                    <Col>
                                        <Input
                                            className='w-25'
                                            placeholder='Search keyword...'
                                            onChange={(e) => {
                                                setSearchKey(e.target.value);
                                                setPage(0);
                                            }}></Input>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <Table className='align-items-center table-flush'>
                                <thead className='thead-light'>
                                    <tr>
                                        <th
                                            onClick={() => {
                                                setSortField('name');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='name'
                                            scope='col'>
                                            Name
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('price');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='price'
                                            scope='col'>
                                            Price
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('menuCategory');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='menuCategory'
                                            scope='col'>
                                            Category
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('MenuMealType');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='MenuMealType'
                                            scope='col'>
                                            Meal Type
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('menuType');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='menuType'
                                            scope='col'>
                                            Type
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('menuStatus');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='menuStatus'
                                            scope='col'>
                                            Status
                                        </th>
                                        <th scope='col'></th>
                                    </tr>
                                </thead>
                                <tbody className='list'>
                                    {filteredMenuPackages
                                        ?.slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                                        .map((p: any) => {
                                            return (
                                                <tr
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => editPackage(p)}
                                                    key={p.id}>
                                                    <td>{`${p.name}`}</td>
                                                    <td>{p.price === null ? 'R0.00' : `R${p.price.toFixed(2)}`}</td>
                                                    <td>{p.menuCategory.title}</td>
                                                    <td>{p.menuMealType?.title}</td>
                                                    <td>{p.menuType.title}</td>
                                                    <td>{p.menuStatus.title}</td>
                                                    <td className='text-right'>
                                                        {getPermission(user, 'locations', 'update') && (
                                                            <Button
                                                                className='btn btn-info btn-icon-only rounded-circle btn-sm'
                                                                onClick={() => editPackage(p)}>
                                                                {' '}
                                                                <i className='fa-solid fa-pencil'></i>
                                                            </Button>
                                                        )}
                                                        {getPermission(user, 'locations', 'delete') && (
                                                            <Button
                                                                className='btn btn-danger btn-icon-only rounded-circle btn-sm'
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    displayAlert({ warning: true, p });
                                                                }}>
                                                                <i className='fa-solid fa-trash-can'></i>
                                                            </Button>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </Table>
                            <CardFooter className='py-4'>
                                <Row>
                                    <div className='col-4 d-flex'>
                                        <span style={{ fontSize: '12px' }}>Rows per page:</span>
                                        <BasicMenu
                                            getValue={getRowValue}
                                            options={['10', '20', '100']}
                                            value={rowPerPage.toString()}
                                        />
                                    </div>
                                    <div className='col-4 d-flex'>
                                        <span className='min-text'>Page:</span>
                                        <span className='min-text pl-2'>
                                            {page + 1} of {Math.ceil(filteredMenuPackages?.length / rowPerPage)}
                                        </span>
                                    </div>
                                    <div className='col-4 d-flex'>
                                        <Pagination>
                                            <PaginationItem className={page <= 0 ? 'disabled' : ''}>
                                                <PaginationLink onClick={(e) => previousPage()}>
                                                    <i className='fas fa-angle-left' />
                                                    <span className='sr-only'>Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem
                                                className={
                                                    page >= Math.ceil(filteredMenuPackages?.length / rowPerPage) - 1
                                                        ? 'disabled'
                                                        : ''
                                                }>
                                                <PaginationLink onClick={(e) => nextPage()}>
                                                    <i className='fas fa-angle-right' />
                                                    <span className='sr-only'>Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                    </div>
                                </Row>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </div>
        </>
    );
}

export default PackageListView;
