import { MenuItem, Select } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import ReactDatetimeClass from 'react-datetime';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Row } from 'reactstrap';
import MembershipListItemHeader from './MembershipListItemHeader';
import {
    MembershipAgreementAddendum,
    MembershipAgreementAddendumStatus,
    MembershipAgreementItemFormData,
    MembershipAgreementItemStatus,
} from '../../../../models/membership-agreement.model';
import MembershipListItem from './MembershipListItem';
import { useForm } from 'react-hook-form';
import moment, { isMoment } from 'moment';
import { TeamMember } from '../../../../models/team.member.model';
import { ProductPackage } from '../../../../models/packages.models';
import AddMembershipAgreementItemDialog from '../dialogs/AddMembershipAgreementItemDialog';
import { useMutation } from '@apollo/client';
import {
    ADD_MEMBERSHIP_AGREEMENT_ADDENDUM,
    GET_MEMBERSHIP_AGREEMENT,
    GET_MEMBERSHIP_AGREEMENTS,
} from '../../../../graphql/membership-agreemnet.graphql';
import useToast from '../../../../hooks/useToast';
import Spinner from '../../../../components/Spinner/Spinner';
import { uploadDocument } from '../../../../hooks/upload-documents.hook';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';

interface AddendumFormProps {
    onRedirectBack: () => void;
    agreementId: string;
    members: TeamMember[];
    packages: ProductPackage[];
    addendum: MembershipAgreementAddendum;
    itemStatusList: MembershipAgreementItemStatus[];
    statusList: MembershipAgreementAddendumStatus[];
}

type FormData = {
    startDate: string;
    endDate: string;
    status: string;
};

export default function AddendumForm({
    onRedirectBack,
    agreementId,
    members,
    packages,
    itemStatusList,
    statusList,
    addendum,
}: AddendumFormProps) {
    const [items, setItems] = useState<MembershipAgreementItemFormData[]>([]);
    const [selectedItem, setSelectedItem] = useState<MembershipAgreementItemFormData>();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<File>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const fileInputRef = useRef<HTMLInputElement>();
    const [addAddendum, { data, error }] = useMutation(ADD_MEMBERSHIP_AGREEMENT_ADDENDUM, {
        refetchQueries: [GET_MEMBERSHIP_AGREEMENT, GET_MEMBERSHIP_AGREEMENTS],
    });
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<FormData>();

    useEffect(() => {
        if (addendum) {
            setItems(addendum.items || []);
        }
    }, []);

    const { showToast, Notification } = useToast();

    const openFileExplorer = () => {
        fileInputRef.current.click();
    };

    const onUpdateItem = (items: MembershipAgreementItemFormData[]) => {
        setItems(items);
    };

    const onItemSelect = (item: MembershipAgreementItemFormData) => {
        if (!item) return;

        setSelectedItem(item);
        setIsOpen(true);
    };

    const onItemAdd = (item: MembershipAgreementItemFormData) => {
        setItems([...items, item]);
    };

    const onItemRemove = (item: MembershipAgreementItemFormData) => {
        const index = items.indexOf(item);
        const newItems = [...items];
        setItems(newItems.filter((i) => newItems.indexOf(i) !== index));
    };

    useEffect(() => {
        if (error) {
            showToast('An error has occurred while trying ', 'danger');
            setIsLoading(false);
        }
    }, [error]);

    useEffect(() => {
        if (data) {
            showToast('Successfully added an addendum', 'success');
            onRedirectBack();
            setIsLoading(false);
        }
    }, [data]);

    const handleFileAttach = (e: any) => {
        setSelectedFile(e.target.files[0]);
    };

    const onSubmit = async (formData: FormData) => {
        let documentKey: string = '';

        if (!addendum && !selectedFile) {
            showToast('Please select a document file for the addendum', 'danger');
            return;
        }

        setIsLoading(true);
        if (selectedFile) documentKey = await uploadDocument(selectedFile, 'membership_agreement');
        if (addendum && !selectedFile) documentKey = addendum.documentPath;
        if (documentKey === 'error') {
            showToast('An error has occurred while trying to upload this file', 'danger');
            setIsLoading(false);
            return;
        }

        const addendumData = {
            start_date: formData.startDate,
            end_date: formData.endDate,
            status_enum: formData.status,
            agreement_id: agreementId,
            document_path: documentKey,
            updated_addendum_id: addendum?.id ?? undefined,
            agreement_items: {
                data: items.map((item) => {
                    return {
                        package_id: item.packageId,
                        assign_member_id: item.assignMemberId ?? undefined,
                        parking_fee: item.parkingFee ?? 0,
                        agreement_id: agreementId,
                        balcony_fee: item.balconyFee ?? 0,
                        type_enum: 'addendum_list',
                        total_fee: item.totalFee,
                        status_enum: 'active',
                    };
                }),
            },
        };

        addAddendum({
            variables: {
                addendum: addendumData,
            },
        });
    };

    return (
        <>
            {Notification}
            {isOpen && (
                <AddMembershipAgreementItemDialog
                    onAddOrUpdateItem={onItemAdd}
                    isOpen={isOpen}
                    members={members}
                    item={selectedItem}
                    onUpdateItem={onUpdateItem}
                    items={items}
                    packages={packages}
                    statusList={itemStatusList}
                    handleClose={() => setIsOpen(false)}
                />
            )}
            <Card>
                <CardHeader>
                    <Row noGutters={true}>
                        <button className='btn btn-outline-primary btn-sm mr-4' onClick={() => onRedirectBack()}>
                            <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                            <span className='btn-inner-text'>Back</span>
                        </button>

                        <h4 className='m-0'>Membership Agreement Addendum</h4>
                    </Row>
                </CardHeader>
                <CardBody>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <FormGroup>
                            <label className='form-control-label' htmlFor='name'>
                                Start Date
                            </label>
                            <ReactDatetimeClass
                                inputProps={{
                                    placeholder: 'Select start date...',
                                    disabled: addendum !== undefined,
                                }}
                                {...register('startDate', {
                                    value: addendum?.startDate ? moment(addendum?.startDate).format('yyyy-MM-DD') : '',
                                    required: true,
                                })}
                                dateFormat={'YYYY-MM-DD'}
                                initialValue={
                                    addendum?.startDate ? moment(addendum?.startDate).format('yyyy-MM-DD') : ''
                                }
                                timeFormat={false}
                                onChange={(value) => {
                                    if (value && isMoment(value)) {
                                        setValue('startDate', value.format('yyyy-MM-DD'));
                                    }
                                }}
                            />
                            {errors.startDate && <span className='invalid'>*This field is required</span>}
                        </FormGroup>
                        <FormGroup>
                            <label className='form-control-label' htmlFor='street_address'>
                                End Date
                            </label>
                            <ReactDatetimeClass
                                inputProps={{
                                    placeholder: 'Select end date...',
                                    disabled: addendum !== undefined,
                                }}
                                dateFormat={'YYYY-MM-DD'}
                                initialValue={addendum?.endDate ? moment(addendum?.endDate).format('yyyy-MM-DD') : ''}
                                timeFormat={false}
                                {...register('endDate', {
                                    value: addendum?.endDate ? moment(addendum?.endDate).format('yyyy-MM-DD') : '',
                                    required: true,
                                })}
                                onChange={(value) => {
                                    if (value && isMoment(value)) {
                                        setValue('endDate', value.format('yyyy-MM-DD'));
                                    }
                                }}
                            />
                            {errors.endDate && <span className='invalid'>*This field is required</span>}
                        </FormGroup>
                        <FormGroup>
                            <label className='form-control-label'>Status</label>
                            <Select
                                {...register('status', { required: true, value: addendum?.status?.value ?? ' ' })}
                                defaultValue={addendum?.status?.value ?? ' '}
                                className='form-control'
                                disabled={addendum !== undefined}
                                sx={{ borderRadius: '12px', padding: 0 }}>
                                <MenuItem disabled value={' '}>
                                    {'Select a status ...'}
                                </MenuItem>
                                {statusList.map((status) => {
                                    return (
                                        <MenuItem key={status.value} value={status.value}>
                                            {status.title}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            {errors.status && <span className='invalid'>*This field is required</span>}
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col md='12' lg='6'>
                                    <label className='form-control-label'>Items</label>
                                </Col>
                                <Col md='12' lg='6' className='text-right'>
                                    <span
                                        className=' btn btn-round btn-dark btn-sm'
                                        color='default'
                                        onClick={() => {
                                            setIsOpen(true);
                                            setSelectedItem(undefined);
                                        }}
                                        id='tooltip969372949'>
                                        <span className='btn-inner--text'> Add item</span>
                                    </span>
                                </Col>
                            </Row>
                            <Row className='mt-4'></Row>
                            <Row>
                                <Col>
                                    {items.length !== 0 && <MembershipListItemHeader />}
                                    {items.length === 0 && <p className='text-center'>No items added</p>}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {items?.map((item, index) => {
                                        return (
                                            <MembershipListItem
                                                packages={packages}
                                                members={members}
                                                onItemSelect={onItemSelect}
                                                onItemRemove={onItemRemove}
                                                item={item}
                                                key={index}
                                            />
                                        );
                                    })}
                                </Col>
                            </Row>
                        </FormGroup>
                        <FormGroup>
                            <Row>
                                <Col>
                                    <span
                                        className=' mt-4 btn btn-round btn-dark btn-sm'
                                        color='default'
                                        onClick={openFileExplorer}
                                        id='tooltip969372949'>
                                        <span className='btn-inner--text'> Attach file</span>
                                    </span>
                                    <input
                                        style={{ display: 'none' }}
                                        multiple
                                        onChange={handleFileAttach}
                                        accept='.pdf, .docx, .xlsx'
                                        type='file'
                                        ref={fileInputRef}
                                    />
                                </Col>
                            </Row>
                            <Row className='mt-4'>
                                <Col>
                                    {(selectedFile || addendum?.documentPath) && (
                                        <InsertDriveFileOutlinedIcon fontSize={'large'} />
                                    )}
                                    <p>
                                        {selectedFile?.name?.substring(0, 6) ?? addendum?.documentPath?.substring(0, 6)}
                                    </p>
                                </Col>
                            </Row>
                        </FormGroup>

                        <div className='d-flex h-100'>
                            <div className='align-self-end ml-auto'>
                                <Button
                                    disabled={
                                        isLoading ||
                                        addendum?.status?.value === 'updated' ||
                                        addendum?.status?.value === 'cancelled'
                                    }
                                    type='submit'
                                    className='btn btn-dark mt-4 btn-block'>
                                    {!isLoading && <span>{addendum ? 'Save' : 'Add'}</span>}
                                    {isLoading && <Spinner />}
                                </Button>
                            </div>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </>
    );
}
