import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select } from '@mui/material';
import { Row } from 'reactstrap';
import { RoomExtra } from '../../../../../models/room.extra.model';
import { BookingRoomExtra } from '../../../../../models/bookings.model';

interface BookingAddOnsDialogProps {
    isOpen: boolean;
    handleClose: () => void;
    roomExtras?: RoomExtra[];
    bookingHours?: number;
    bookingRoomAdOns: BookingRoomExtra[];
    getBookingRoomAdOns: (addOns: BookingRoomExtra[]) => void;
}

const BookingAddOnsDialog = ({
    isOpen,
    handleClose,
    roomExtras,
    bookingHours,
    bookingRoomAdOns,
    getBookingRoomAdOns,
}: BookingAddOnsDialogProps) => {
    const [selectedRoomAdOn, setSelectedRoomAdOn] = useState<BookingRoomExtra>();

    const onAddItems = () => {
        const items = [...bookingRoomAdOns, selectedRoomAdOn];
        getBookingRoomAdOns(items);
        handleClose();
    };

    const onSelectedAdOn = (adOnId: string) => {
        const addOnOption = roomExtras?.find((roomExtra) => {
            return roomExtra.id === adOnId;
        });

        let quantity = 1;
        if (addOnOption.unit.value === 'hour') {
            quantity = bookingHours >= 1 ? bookingHours : 1;
        }

        const newAdOn: BookingRoomExtra = {
            title: addOnOption.title,
            roomExtraId: addOnOption?.id,
            rate: addOnOption.price,
            quantity: quantity,
            totalPrice: quantity * addOnOption.price,
        };

        setSelectedRoomAdOn(newAdOn);
    };

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>Add room add-ons</DialogTitle>
            <DialogContent
                sx={{
                    width: 600,
                }}>
                <Row>
                    <div className='col'>
                        <Select
                            sx={{
                                width: '100%',
                            }}
                            variant='standard'
                            defaultValue={'empty'}
                            onChange={(e) => {
                                onSelectedAdOn(e.target.value);
                            }}>
                            <MenuItem disabled value={'empty'}>
                                <span className='placeholder-text'>Select a room add-on...</span>
                            </MenuItem>
                            {roomExtras?.map((roomExtra) => {
                                return (
                                    <MenuItem value={roomExtra?.id} key={roomExtra?.id}>
                                        {roomExtra?.title}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </div>
                </Row>
            </DialogContent>
            <DialogActions>
                <div className='mt-4 d-flex h-100 p-2'>
                    <div className='align-self-end ml-auto'>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={onAddItems} disabled={!selectedRoomAdOn} type='submit'>
                            Add
                        </Button>
                    </div>
                </div>
            </DialogActions>
        </Dialog>
    );
};

export default BookingAddOnsDialog;
