/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Row } from 'reactstrap';
import PackageImage from '../../../components/Image component/PackageImage';
import { Image } from '../../../models/product.model';
import { uploadImage } from '../../../hooks/upload-image.hook';
import useToast from '../../../hooks/useToast';
import { useMutation } from '@apollo/client';
import { ADD_PACKAGE_IMAGE } from '../../../graphql/packages.graphql';
import { useParams } from 'react-router-dom';
// import LinearProgress from "@mui/material/LinearProgress";
import { CircularProgress } from '@mui/material';

interface ImageViewProps {
    images: Image[] | undefined;
}

const PackageImageView = ({ images }: ImageViewProps) => {
    const inputFileRef: any = useRef(null);
    // const [imageFile, setImageFile] = useState<any>();
    const { showToast, Notification } = useToast();
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [addImageMutation, { error, data }] = useMutation(ADD_PACKAGE_IMAGE, {
        // refetchQueries: [{ query: GET_PRODUCT_PACKAGE }, "GetProductPackage"],
        refetchQueries: ['GetProductPackage'],
    });
    const params: any = useParams();

    const changeImage = (e: any) => {
        // setImageFile(e.target.files[0]);
        addImage(e.target.files[0]);
    };

    const addImage = async (imageFile: any) => {
        if (!imageFile) {
            showToast('Please select an image first', 'danger');
            return;
        }

        setIsUpdating(true);
        const imageKey = await uploadImage(imageFile, 'package_images');

        if (imageKey === 'error') {
            showToast('An error has occurred while trying ', 'danger');
            setIsUpdating(false);
            return;
        }

        const image = {
            package_id: params.id,
            path: imageKey,
        };

        addImageMutation({
            variables: {
                image: image,
            },
        });
    };

    useEffect(() => {
        if (error) {
            showToast('An error has occurred while trying to an image', 'danger');
            setIsUpdating(false);
        }
    }, [error]);

    useEffect(() => {
        if (data && !error) {
            showToast('Successfully added an image in packages', 'success');
            setIsUpdating(false);
        }
    }, [data]);

    if (images?.length === 0) {
        return (
            <Card className='mt-4'>
                {Notification}
                <CardHeader>
                    <button
                        onClick={() => {
                            inputFileRef.current.click();
                        }}
                        disabled={isUpdating}
                        className='btn btn-dark btn-sm'>
                        {isUpdating ? <CircularProgress color='primary' size={16} /> : <span>Add</span>}
                    </button>
                    <input
                        onChange={(e) => changeImage(e)}
                        ref={inputFileRef}
                        type='file'
                        style={{ display: 'none' }}
                    />
                </CardHeader>
                <CardBody>
                    <h1>This Package has no images</h1>
                </CardBody>
            </Card>
        );
    }

    return (
        <>
            {Notification}
            <Card className='mt-4'>
                <CardHeader>
                    <button
                        onClick={() => {
                            inputFileRef.current.click();
                        }}
                        disabled={isUpdating}
                        className='btn btn-dark btn-sm'>
                        {isUpdating ? <CircularProgress color='primary' size={16} /> : <span>Add</span>}
                    </button>
                    <input
                        onChange={(e) => changeImage(e)}
                        ref={inputFileRef}
                        type='file'
                        accept='image/*'
                        style={{ display: 'none' }}
                    />
                </CardHeader>
                <CardBody>
                    <div className='container'>
                        <Row>
                            {images?.map((image) => {
                                return (
                                    <div className='pr-4 mt-4'>
                                        <PackageImage
                                            id={image.id}
                                            sourceImage={`${process.env.REACT_APP_IMAGE_URL}/${image?.path}`}
                                        />
                                    </div>
                                );
                            })}
                        </Row>
                    </div>
                </CardBody>
            </Card>
        </>
    );
};

export default PackageImageView;
