import React, { useState } from "react";
import { RoomExtra } from "../../../../../models/room.extra.model";
import RoomExtraAddForm from "./RoomExtraAddForm";
import RoomExtraListView from "./RoomExtraListView";

interface BaseRootProps {
  roomExtras: RoomExtra[];
}

const BaseRoot = ({ roomExtras }: BaseRootProps) => {
  const [displayAddForm, setDisplayAddForm] = useState<boolean>(false);

  if (displayAddForm) {
    return <RoomExtraAddForm backToListView={() => setDisplayAddForm(false)} />;
  }

  return (
    <>
      <RoomExtraListView
        roomExtras={roomExtras}
        displayAddForm={() => setDisplayAddForm(true)}
      />
    </>
  );
};

export default BaseRoot;
