import { LinearProgress, MenuItem, Select } from '@mui/material';
import moment, { isMoment } from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import ReactDatetimeClass from 'react-datetime';
import { useForm } from 'react-hook-form';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Row } from 'reactstrap';
import Spinner from '../../../../components/Spinner/Spinner';

import {
    GET_SERVICE_AGREEMENT,
    GET_SERVICE_AGREEMENTS,
    UPDATE_SERVICE_AGREEMENT,
} from '../../../../graphql/service-providers.graphql';
import { useMutation, useQuery } from '@apollo/client';
import useToast from '../../../../hooks/useToast';
import {
    ServiceAgreement,
    ServiceAgreementBillingPeriod,
    ServiceAgreementStatus,
} from '../../../../models/service-providers.model';
import { useDropzone } from 'react-dropzone';
import { uploadDocument } from '../../../../hooks/upload-documents.hook';

interface ServiceAgreementDetailsFormProps {
    onRedirectBack: () => void;
    agreementId: string;
}

type FormData = {
    startDate: string;
    endDate: string;
    dateSigned: string;
    documentPath: string;
    priceExclVAT: number;
    priceIncVAT: number;
    billingPeriod: string;
    status: string;
};

export default function ServiceAgreementDetailsForm({ onRedirectBack, agreementId }: ServiceAgreementDetailsFormProps) {
    const {
        register,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>();

    const [file, setFile] = useState<File>(null);

    const { showToast, Notification } = useToast();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onDrop = useCallback((acceptedFile: File[]) => {
        setFile(acceptedFile[0]);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'application/pdf': ['.pdf'],
            'application/msword': ['.doc'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
        },
    });

    const { data, loading, error } = useQuery(GET_SERVICE_AGREEMENT, {
        variables: {
            id: agreementId,
        },
    });

    const [updateAgreement, { data: updated, error: updateError }] = useMutation(UPDATE_SERVICE_AGREEMENT, {
        refetchQueries: [GET_SERVICE_AGREEMENTS],
    });

    const onSubmit = async (formData: FormData) => {
        const agreement: ServiceAgreement = data?.agreement;
        let documentKey = '';

        setIsLoading(true);
        if (file) documentKey = await uploadDocument(file, 'service_agreements');

        if (!file && agreement.documentPath) documentKey = agreement.documentPath;
        if (documentKey === 'error') {
            showToast('An error has occurred while trying to upload this file', 'danger');
            setIsLoading(false);
            return;
        }

        const agreementData = {
            start_date: formData.startDate,
            end_date: formData.endDate,
            date_signed: formData.dateSigned,
            document_path: documentKey,
            price_incl_vat: formData.priceIncVAT,
            price_excl_vat: formData.priceExclVAT,
            billing_period: formData.billingPeriod,
            status_enum: formData.status,
        };

        await updateAgreement({
            variables: {
                id: agreementId,
                agreement: agreementData,
            },
        });
    };

    useEffect(() => {
        if (error) {
            showToast('An error has occurred while trying to load the page', 'danger');
            console.log('Error', error);
        }
    }, [error]);

    useEffect(() => {
        if (updated) {
            showToast('Successfully updated service agreement', 'success');
            setIsLoading(false);
        }
    }, [updated]);

    useEffect(() => {
        if (updateError) {
            showToast('An error has occurred while trying update this service agreement', 'danger');
            console.log('Update error', updateError);
            setIsLoading(false);
        }
    }, [updateError]);

    if (loading) {
        return <LinearProgress />;
    }

    const agreement: ServiceAgreement = data?.agreement;
    const billingPeriodList: ServiceAgreementBillingPeriod[] = data?.billingPeriodList;
    const statusList: ServiceAgreementStatus[] = data?.statusList;
    return (
        <>
            {Notification}
            <Row className='mt-4'>
                <Col md='12' lg='6'>
                    <Card>
                        <CardHeader>
                            <Row noGutters={true}>
                                <button
                                    className='btn btn-outline-primary btn-sm mr-4'
                                    onClick={(e) => onRedirectBack()}>
                                    <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                                    <span className='btn-inner-text'>Back</span>
                                </button>

                                <h4 className='m-0'>Update Service Level Agreement</h4>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='name'>
                                        Start Date
                                    </label>
                                    <ReactDatetimeClass
                                        inputProps={{
                                            placeholder: 'Select agreement start date...',
                                            ...register('startDate', {
                                                required: true,
                                                value: moment(agreement.startDate).format('yyyy-MM-DD') ?? '',
                                            }),
                                        }}
                                        dateFormat={'YYYY-MM-DD'}
                                        initialValue={moment(agreement.startDate)}
                                        timeFormat={false}
                                        onChange={(value) => {
                                            if (value && isMoment(value)) {
                                                setValue('startDate', value.format('yyyy-MM-DD'));
                                            }
                                        }}
                                    />
                                    {errors.startDate && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='street_address'>
                                        End Date
                                    </label>
                                    <ReactDatetimeClass
                                        inputProps={{
                                            placeholder: 'Select agreement end date...',
                                            ...register('endDate', {
                                                required: true,
                                                value: moment(agreement.endDate).format('yyyy-MM-DD') ?? '',
                                            }),
                                        }}
                                        dateFormat={'YYYY-MM-DD'}
                                        initialValue={moment(agreement.endDate)}
                                        timeFormat={false}
                                        onChange={(value) => {
                                            if (value && isMoment(value)) {
                                                setValue('endDate', value.format('yyyy-MM-DD'));
                                            }
                                        }}
                                    />
                                    {errors.endDate && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='city'>
                                        Date Signed
                                    </label>
                                    <ReactDatetimeClass
                                        inputProps={{
                                            placeholder: 'Select signed date for agreement...',
                                            ...register('dateSigned', {
                                                required: true,
                                                value: moment(agreement.dateSigned).format('yyyy-MM-DD') ?? '',
                                            }),
                                        }}
                                        dateFormat={'YYYY-MM-DD'}
                                        timeFormat={false}
                                        initialValue={moment(agreement.dateSigned)}
                                        onChange={(value) => {
                                            if (value && isMoment(value)) {
                                                setValue('dateSigned', value.format('yyyy-MM-DD'));
                                            }
                                        }}
                                    />
                                    {errors.dateSigned && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label'>Price Excl VAT</label>
                                    <input
                                        className='form-control'
                                        {...register('priceExclVAT', { required: true, value: agreement.priceExclVat })}
                                        placeholder='Enter agreement price(VAT excl)...'
                                        type='number'
                                        step={0.01}
                                    />
                                    {errors.priceExclVAT && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label'>Price Incl VAT</label>
                                    <input
                                        className='form-control'
                                        {...register('priceIncVAT', { required: true, value: agreement.priceInclVat })}
                                        placeholder='Enter agreement price(VAT incl)...'
                                        type='number'
                                        step={0.01}
                                    />
                                    {errors.priceIncVAT && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label'>Billing Period</label>
                                    <Select
                                        className='form-control'
                                        defaultValue={agreement.billingPeriod.value ?? ' '}
                                        {...register('billingPeriod', {
                                            required: true,
                                            value: agreement.billingPeriod.value,
                                        })}
                                        sx={{ borderRadius: '12px', padding: 0 }}>
                                        <MenuItem disabled value={' '}>
                                            {'Select a billing period...'}
                                        </MenuItem>
                                        {billingPeriodList.map((bp) => {
                                            return (
                                                <MenuItem key={bp.value} value={bp.value}>
                                                    {bp.title}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    {errors.billingPeriod && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label'>Status</label>
                                    <Select
                                        className='form-control'
                                        defaultValue={agreement?.status.value ?? ' '}
                                        {...register('status', {
                                            required: true,
                                            value: agreement?.status.value ?? '',
                                        })}
                                        sx={{ borderRadius: '12px', padding: 0 }}>
                                        <MenuItem disabled value={' '}>
                                            {'Select a status for service agreement...'}
                                        </MenuItem>
                                        {statusList.map((status) => {
                                            return (
                                                <MenuItem key={status.value} value={status.value}>
                                                    {status.title}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    {errors.status && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                                <FormGroup>
                                    <div {...getRootProps()} className='document-container'>
                                        {!file ? <h5>{agreement.documentPath ?? ''}</h5> : file.name}
                                        <div className='overlay'>
                                            <input {...getInputProps()} />
                                        </div>
                                    </div>
                                </FormGroup>

                                <div className='d-flex h-100'>
                                    <div className='align-self-end ml-auto'>
                                        <Button
                                            type='submit'
                                            className='btn btn-dark mt-4 btn-block'
                                            disabled={isLoading}>
                                            {!isLoading && <span>Update</span>}
                                            {isLoading && (
                                                <span>
                                                    <Spinner />
                                                </span>
                                            )}
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
}
