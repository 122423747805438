import React from 'react';
import classnames from 'classnames';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interaction from '@fullcalendar/interaction';
import { Button, ButtonGroup, Card, CardBody, FormGroup, Form, Input, Modal, Row, Col } from 'reactstrap';
import { PackageBookingEvent } from '../../../../models/package-bookings.model';
import { useNavigate } from 'react-router-dom';
// import { useHistory } from "react-router-dom";

let calendar: any;

interface PackageCalendarProps {
    packageBookings?: PackageBookingEvent[];
}

const PackageCalendar = ({ packageBookings }: PackageCalendarProps) => {
    const [events, setEvents]: any = React.useState(packageBookings || []);
    const [modalAdd, setModalAdd]: any = React.useState(false);
    const [modalChange, setModalChange]: any = React.useState(false);
    const [startDate, setStartDate]: any = React.useState(null);
    const [endDate, setEndDate]: any = React.useState(null);
    const [radios, setRadios]: any = React.useState(null);
    const [eventId, setEventId]: any = React.useState(null);
    const [eventTitle, setEventTitle]: any = React.useState(null);
    const navigate = useNavigate();
    const [eventDescription, setEventDescription]: any = React.useState(null);
    // eslint-disable-next-line
    const [event, setEvent]: any = React.useState(null);
    const [currentDate, setCurrentDate]: any = React.useState(null);
    const calendarRef: any = React.useRef(null);
    React.useEffect(() => {
        createCalendar();
        // eslint-disable-next-line
    }, []);
    const createCalendar = () => {
        calendar = new Calendar(calendarRef.current, {
            plugins: [dayGridPlugin, interaction],
            initialView: 'dayGridWeek',
            selectable: true,
            editable: false,
            events: events,
            headerToolbar: false,
            // Add new event
            select: (info) => {
                // history.push(`admin/package-bookings/details/${event?.id}`);
            },
            // Edit calendar event action
            eventClick: ({ event }) => {
                navigate(`/admin/package-bookings/details/${event?.id}`);
            },
        });
        calendar.render();
        setCurrentDate(calendar.view.title);
    };
    const changeView = (newView: any) => {
        calendar.changeView(newView);
        setCurrentDate(calendar.view.title);
    };
    const addNewEvent = () => {
        var newEvents = events;
        newEvents.push({
            title: eventTitle,
            start: startDate,
            end: endDate,
            className: radios,
            id: events[events.length - 1] + 1,
        });
        calendar.addEvent({
            title: eventTitle,
            start: startDate,
            end: endDate,
            className: radios,
            id: events[events.length - 1] + 1,
        });
        setModalAdd(false);
        setEvents(newEvents);
        setStartDate(undefined);
        setEndDate(undefined);
        setRadios('bg-info');
        setEventTitle(undefined);
    };
    const changeEvent = () => {
        var newEvents = events.map((prop: any, ke: any) => {
            if (prop.id + '' === eventId + '') {
                setEvent(undefined);
                calendar.getEventById(eventId).remove();
                let saveNewEvent = {
                    ...prop,
                    title: eventTitle,
                    className: radios,
                    description: eventDescription,
                };
                calendar.addEvent(saveNewEvent);
                return {
                    ...prop,
                    title: eventTitle,
                    className: radios,
                    description: eventDescription,
                };
            } else {
                return prop;
            }
        });
        setModalChange(false);
        setEvents(newEvents);
        setRadios('bg-info');
        setEventTitle(undefined);
        setEventDescription(undefined);
        setEventId(undefined);
        setEvent(undefined);
    };

    return (
        <div className='header header-dark content__title content__title--calendar'>
            <div className='header-body'>
                <Row className='align-items-center py-4'>
                    <Col lg='6'>
                        <h6 className='fullcalendar-title h2 text-black d-inline-block mb-0 mr-1'>{currentDate}</h6>
                    </Col>
                    <Col className='mt-3 mt-md-0 text-md-right' lg='6'>
                        <Button
                            className='fullcalendar-btn-prev btn-neutral'
                            color='default'
                            onClick={() => {
                                calendar.prev();
                                setCurrentDate(calendar.view.title);
                            }}
                            size='sm'>
                            <i className='fas fa-angle-left' />
                        </Button>
                        <Button
                            className='fullcalendar-btn-next btn-neutral'
                            color='default'
                            onClick={() => {
                                calendar.next();
                                setCurrentDate(calendar.view.title);
                            }}
                            size='sm'>
                            <i className='fas fa-angle-right' />
                        </Button>
                        <Button
                            className='btn-neutral'
                            color='default'
                            data-calendar-view='month'
                            onClick={() => changeView('dayGridMonth')}
                            size='sm'>
                            Month
                        </Button>
                        <Button
                            className='btn-neutral'
                            color='default'
                            data-calendar-view='basicWeek'
                            onClick={() => changeView('dayGridWeek')}
                            size='sm'>
                            Week
                        </Button>
                        <Button
                            className='btn-neutral'
                            color='default'
                            data-calendar-view='basicDay'
                            onClick={() => changeView('dayGridDay')}
                            size='sm'>
                            Day
                        </Button>
                    </Col>
                </Row>
            </div>
            <div className=''>
                <Card className='card-calendar'>
                    <CardBody className='p-4'>
                        <div
                            className='calendar'
                            data-toggle='calendar'
                            id='calendar'
                            ref={calendarRef}
                            style={{ height: '300px' }}
                        />
                    </CardBody>
                </Card>
                <Modal
                    isOpen={modalAdd}
                    toggle={() => setModalAdd(false)}
                    className='modal-dialog-centered modal-secondary'>
                    <div className='modal-body'>
                        <form className='new-event--form'>
                            <FormGroup>
                                <label className='form-control-label'>Event title</label>
                                <Input
                                    className='form-control-alternative new-event--title'
                                    placeholder='Event Title'
                                    type='text'
                                    onChange={(e) => setEventTitle(e.target.value)}
                                />
                            </FormGroup>
                            <FormGroup className='mb-0'>
                                <label className='form-control-label d-block mb-3'>Status color</label>
                                <ButtonGroup
                                    className='btn-group-toggle btn-group-colors event-tag'
                                    data-toggle='buttons'>
                                    <Button
                                        className={classnames('bg-info', {
                                            active: radios === 'bg-info',
                                        })}
                                        color=''
                                        type='button'
                                        onClick={() => setRadios('bg-info')}
                                    />
                                    <Button
                                        className={classnames('bg-warning', {
                                            active: radios === 'bg-warning',
                                        })}
                                        color=''
                                        type='button'
                                        onClick={() => setRadios('bg-warning')}
                                    />
                                    <Button
                                        className={classnames('bg-danger', {
                                            active: radios === 'bg-danger',
                                        })}
                                        color=''
                                        type='button'
                                        onClick={() => setRadios('bg-danger')}
                                    />
                                    <Button
                                        className={classnames('bg-success', {
                                            active: radios === 'bg-success',
                                        })}
                                        color=''
                                        type='button'
                                        onClick={() => setRadios('bg-success')}
                                    />
                                    <Button
                                        className={classnames('bg-default', {
                                            active: radios === 'bg-default',
                                        })}
                                        color=''
                                        type='button'
                                        onClick={() => setRadios('bg-default')}
                                    />
                                    <Button
                                        className={classnames('bg-primary', {
                                            active: radios === 'bg-primary',
                                        })}
                                        color=''
                                        type='button'
                                        onClick={() => setRadios('bg-primary')}
                                    />
                                </ButtonGroup>
                            </FormGroup>
                        </form>
                    </div>
                    <div className='modal-footer'>
                        <Button className='new-event--add' color='primary' type='button' onClick={addNewEvent}>
                            Add event
                        </Button>
                        <Button className='ml-auto' color='link' type='button' onClick={() => setModalAdd(false)}>
                            Close
                        </Button>
                    </div>
                </Modal>
                <Modal
                    isOpen={modalChange}
                    toggle={() => setModalChange(false)}
                    className='modal-dialog-centered modal-secondary'>
                    <div className='modal-body'>
                        <Form className='edit-event--form'>
                            <FormGroup>
                                <label className='form-control-label'>Event title</label>
                                <Input
                                    className='form-control-alternative edit-event--title'
                                    placeholder='Event Title'
                                    type='text'
                                    defaultValue={eventTitle}
                                    onChange={(e) => setEventTitle(e.target.value)}
                                />
                            </FormGroup>
                            <FormGroup>
                                <label className='form-control-label d-block mb-3'>Status color</label>
                                <ButtonGroup
                                    className='btn-group-toggle btn-group-colors event-tag mb-0'
                                    data-toggle='buttons'>
                                    <Button
                                        className={classnames('bg-info', {
                                            active: radios === 'bg-info',
                                        })}
                                        color=''
                                        type='button'
                                        onClick={() => setRadios('bg-info')}
                                    />
                                    <Button
                                        className={classnames('bg-warning', {
                                            active: radios === 'bg-warning',
                                        })}
                                        color=''
                                        type='button'
                                        onClick={() => setRadios('bg-warning')}
                                    />
                                    <Button
                                        className={classnames('bg-danger', {
                                            active: radios === 'bg-danger',
                                        })}
                                        color=''
                                        type='button'
                                        onClick={() => setRadios('bg-danger')}
                                    />
                                    <Button
                                        className={classnames('bg-success', {
                                            active: radios === 'bg-success',
                                        })}
                                        color=''
                                        type='button'
                                        onClick={() => setRadios('bg-success')}
                                    />
                                    <Button
                                        className={classnames('bg-default', {
                                            active: radios === 'bg-default',
                                        })}
                                        color=''
                                        type='button'
                                        onClick={() => setRadios('bg-default')}
                                    />
                                    <Button
                                        className={classnames('bg-primary', {
                                            active: radios === 'bg-primary',
                                        })}
                                        color=''
                                        type='button'
                                        onClick={() => setRadios('bg-primary')}
                                    />
                                </ButtonGroup>
                            </FormGroup>
                            <FormGroup>
                                <label className='form-control-label'>Description</label>
                                <Input
                                    className='form-control-alternative edit-event--description textarea-autosize'
                                    placeholder='Event Desctiption'
                                    type='textarea'
                                    defaultValue={eventDescription}
                                    onChange={(e) => setEventDescription(e.target.value)}
                                />
                                <i className='form-group--bar' />
                            </FormGroup>
                            <input className='edit-event--id' type='hidden' />
                        </Form>
                    </div>
                    <div className='modal-footer'>
                        <Button color='primary' onClick={changeEvent}>
                            Update
                        </Button>
                        <Button
                            color='danger'
                            onClick={() => {
                                setModalChange(false);
                            }}>
                            Delete
                        </Button>
                        <Button className='ml-auto' color='link' onClick={() => setModalChange(false)}>
                            Close
                        </Button>
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default PackageCalendar;
