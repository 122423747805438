import React from "react";
import Spinner from "../../../../components/Spinner/Spinner";

interface CommentTextFieldProps {
  isLoading?: boolean;
  commentText?: string;
  getCommentText?: (text: string) => void;
  onAddComment?: () => void;
}

const CommentTextField = ({
  isLoading,
  commentText,
  getCommentText,
  onAddComment,
}: CommentTextFieldProps) => {
  return (
    <React.Fragment>
      <div className="comment-text-field">
        <textarea
          value={commentText}
          onChange={(e) => {
            getCommentText(e.target.value);
          }}
          rows={2}
          className="text-field"
          placeholder="Add your comment..."
        ></textarea>
        <div className="comment-footer">
          <div className="row">
            <div className="col text-right">
              <button
                onClick={() => {
                  onAddComment();
                  getCommentText("");
                }}
                disabled={isLoading || commentText === "" || !commentText}
                className="btn btn-primary"
              >
                {!isLoading && <span>Comment</span>}
                {isLoading && <Spinner />}
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CommentTextField;
