/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table,
    UncontrolledTooltip,
} from 'reactstrap';
import { TeamMember } from '../../../models/team.member.model';
import { useMutation } from '@apollo/client';
import { REMOVE_MEMBER } from '../../../graphql/clients.graphql';
import SweetAlert from 'react-bootstrap-sweetalert';
import useToast from '../../../hooks/useToast';
import MemberAddForm from './MemberAddForm';
import BasicMenu from '../../../components/Menu';
import ClientManageMemberForm from './ClientManageMemberForm';
import { User } from '../../../models/user.model';
import { useSelector } from 'react-redux';
import { getPermission } from '../../../utils/user.utils';
import _ from 'lodash';

interface ClientMemberProp {
    members: TeamMember[];
}

const ClientMembersView: any = ({ members }: ClientMemberProp) => {
    const [page, setPage] = useState<number>(0);
    const [rowPerPage, setRowPerPage] = useState<number>(10);
    const [displayForm, setDisplayForm] = useState<boolean>(false);
    const [displayManageForm, setDisplayManageForm] = useState<boolean>(false);
    const [alert, setAlert] = useState<any>(null);
    const [memberId, setMemberId] = useState<string>('');
    const [sortDirection, setSortDirection] = useState<string>('asc');
    const [sortFiled, setSortField] = useState<string>('name');
    const { showToast, Notification } = useToast();
    const [searchValue, setSearchValue] = useState<string>('');
    const [memberDeleteMutation, { error: errorRemoving, data: memberRemoved }] = useMutation(REMOVE_MEMBER, {
        // refetchQueries: [{ query: GET_CLIENT }, "GetClient"],
        refetchQueries: ['GetClient'],
    });
    const user: User | null = useSelector((state: any) => state.user.userInfo);

    const displayAlert = (props: any) => {
        setAlert(
            <SweetAlert
                {...props}
                showCancel
                confirmBtnText='Continue'
                confirmBtnBsStyle='danger'
                title='Are you sure?'
                onConfirm={() => {
                    deleteMember(props.member.id);
                    setAlert(null);
                }}
                onCancel={() => {
                    setAlert(null);
                }}
                focusCancelBtn>
                This member will be removed from the system.
            </SweetAlert>,
        );
    };

    const sortBy: any = (members: TeamMember[], field: string, direction: string) => {
        const orderedBookings = new Map([
            ['name', _.orderBy(members, (member) => member.user.name, [direction === 'asc' ? 'asc' : 'desc'])],
            ['email', _.orderBy(members, (member) => member.user.email, [direction === 'asc' ? 'asc' : 'desc'])],
            [
                'contactNumber',
                _.orderBy(members, (member) => member.user.contactNumber, [direction === 'asc' ? 'asc' : 'desc']),
            ],
            ['role', _.orderBy(members, (member) => member.user.role, [direction === 'asc' ? 'asc' : 'desc'])],
            [
                'identityNumber',
                _.orderBy(members, (member) => member.user.identityNumber, [direction === 'asc' ? 'asc' : 'desc']),
            ],
            ['status', _.orderBy(members, (member) => member.status?.title, [direction === 'asc' ? 'asc' : 'desc'])],
        ]);
        return orderedBookings.get(field);
    };

    const deleteMember = async (_id: string) => {
        memberDeleteMutation({
            variables: {
                id: _id,
            },
        });
    };

    useEffect(() => {
        if (errorRemoving) {
            showToast('An error has occurred while trying to remove member', 'danger');
        }
    }, [errorRemoving]);

    useEffect(() => {
        if (memberRemoved) {
            showToast('Member successfully removed', 'success');
        }
    }, [memberRemoved]);

    const getRowValue = (value: string) => {
        setRowPerPage(parseInt(value));
    };
    const nextPage = () => {
        setPage(page + 1);
    };

    const previousPage = () => {
        setPage(page - 1);
    };

    if (displayManageForm) {
        return <ClientManageMemberForm goBackToMembers={() => setDisplayManageForm(false)} id={memberId} />;
    }

    if (displayForm) {
        return (
            <MemberAddForm
                goBackToMembers={() => {
                    setDisplayForm(false);
                }}
            />
        );
    }

    if (members.length === 0 && !displayForm) {
        return (
            <Card className='mt-4'>
                <CardHeader className='border-0'>
                    <Row>
                        <Col xs='6'>
                            <h3 className='mb-0'>Members</h3>
                        </Col>
                        {getPermission(user, 'client_members', 'create') && (
                            <Col className='text-right' xs='6'>
                                <button
                                    onClick={() => {
                                        setDisplayForm(true);
                                    }}
                                    className='btn btn-round btn-sm btn-dark'
                                    color='default'
                                    id='tooltip969372949'>
                                    <span className='btn-inner--text'>Add member</span>
                                </button>
                                <UncontrolledTooltip delay={0} target='tooltip969372949'>
                                    Add member
                                </UncontrolledTooltip>
                            </Col>
                        )}
                    </Row>
                </CardHeader>
                <CardBody>
                    <h2>This client has no members</h2>
                </CardBody>
            </Card>
        );
    }

    let filteredMembers = members.filter((member: TeamMember) => {
        const checkForSearch =
            member.user.name.toLowerCase().includes(searchValue.toLowerCase()) ||
            member.user.email.toLowerCase().includes(searchValue.toLowerCase()) ||
            member.user.contactNumber.toLowerCase().includes(searchValue.toLowerCase());
        return checkForSearch;
    });

    filteredMembers = sortBy(filteredMembers, sortFiled, sortDirection);

    return (
        members.length > 0 && (
            <>
                {Notification}
                {alert}
                <Card className='mt-4'>
                    <Row>
                        <div className='col'>
                            <CardHeader className='border-0'>
                                <Row>
                                    <Col xs='6'>
                                        <h3 className='mb-0'>Members</h3>
                                    </Col>
                                    <Col className='text-right' xs='6'>
                                        <button
                                            onClick={() => {
                                                setDisplayForm(true);
                                            }}
                                            className='btn btn-dark btn-sm'>
                                            Add member
                                        </button>
                                    </Col>
                                </Row>
                                <Row className='mt-4'>
                                    <Col xs='6'>
                                        <Input
                                            className='form-control w-50'
                                            type='text'
                                            placeholder='Search keyword...'
                                            onChange={(e) => {
                                                setSearchValue(e.target.value);
                                                setPage(0);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Table className='align-items-center table-flush' responsive>
                                    <thead className='thead-light'>
                                        <tr>
                                            <th
                                                onClick={() => {
                                                    setSortField('name');
                                                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                                }}
                                                className='sort'
                                                data-sort='name'
                                                scope='col'>
                                                Name and Surname
                                            </th>
                                            <th
                                                onClick={() => {
                                                    setSortField('email');
                                                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                                }}
                                                className='sort'
                                                data-sort='email'
                                                scope='col'>
                                                Email
                                            </th>
                                            <th
                                                onClick={() => {
                                                    setSortField('contactNumber');
                                                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                                }}
                                                className='sort'
                                                data-sort='contactNumber'
                                                scope='col'>
                                                Contact Number
                                            </th>
                                            <th
                                                onClick={() => {
                                                    setSortField('role');
                                                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                                }}
                                                className='sort'
                                                data-sort='role'
                                                scope='col'>
                                                Role
                                            </th>
                                            <th
                                                onClick={() => {
                                                    setSortField('identityNumber');
                                                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                                }}
                                                className='sort'
                                                data-sort='identityNumber'
                                                scope='col'>
                                                IDENTITY NUMBER
                                            </th>
                                            <th
                                                onClick={() => {
                                                    setSortField('status');
                                                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                                }}
                                                className='sort'
                                                data-sort='status'
                                                scope='col'>
                                                STATUS
                                            </th>
                                            <th scope='col'></th>
                                            <th scope='col'></th>
                                        </tr>
                                    </thead>
                                    <tbody className='list'>
                                        {filteredMembers
                                            .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                                            .map((member: any) => {
                                                return (
                                                    <tr
                                                        key={member.id}
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            setDisplayManageForm(true);
                                                            setMemberId(member.id);
                                                        }}>
                                                        <td>{`${member.user.name} ${member.user.surname}`}</td>
                                                        <td>{member.user.email}</td>
                                                        <td>{member.user.contactNumber}</td>
                                                        <td>{member.role?.name}</td>
                                                        <td>{member.user.identityNumber}</td>
                                                        <td>{member.status?.title}</td>
                                                        <td className='text-end'>
                                                            {getPermission(user, 'client_members', 'update') && (
                                                                <button
                                                                    onClick={() => {
                                                                        setDisplayManageForm(true);
                                                                        setMemberId(member.id);
                                                                    }}
                                                                    className='btn btn-info btn-icon-only rounded-circle btn-sm'>
                                                                    <i className='fa-solid fa-pencil'></i>
                                                                </button>
                                                            )}
                                                            {getPermission(user, 'client_members', 'delete') && (
                                                                <button
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        displayAlert({ warning: true, member });
                                                                    }}
                                                                    className='btn btn-danger btn-icon-only rounded-circle btn-sm'>
                                                                    <i className='fa-solid fa-trash-can'></i>
                                                                </button>
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </Table>
                            </CardBody>
                            <CardFooter className='py-4'>
                                <Row>
                                    <div className='col-4 d-flex'>
                                        <span className='min-text'>Rows per page:</span>
                                        <BasicMenu
                                            getValue={getRowValue}
                                            options={['10', '20', '100']}
                                            value={rowPerPage.toString()}
                                        />
                                    </div>
                                    <div className='col-4 d-flex'>
                                        <span className='min-text'>Page:</span>
                                        <span className='min-text pl-2'>
                                            {page + 1} of {Math.ceil(filteredMembers.length / rowPerPage)}
                                        </span>
                                    </div>
                                    <div className='col-4 d-flex'>
                                        <Pagination>
                                            <PaginationItem className={page <= 0 ? 'disabled' : ''}>
                                                <PaginationLink onClick={(e) => previousPage()}>
                                                    <i className='fas fa-angle-left' />
                                                    <span className='sr-only'>Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem
                                                className={
                                                    page >= Math.ceil(filteredMembers.length / rowPerPage) - 1
                                                        ? 'disabled'
                                                        : ''
                                                }>
                                                <PaginationLink onClick={(e) => nextPage()}>
                                                    <i className='fas fa-angle-right' />
                                                    <span className='sr-only'>Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                    </div>
                                </Row>
                            </CardFooter>
                        </div>
                    </Row>
                </Card>
            </>
        )
    );
};

export default ClientMembersView;
