import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table,
    UncontrolledTooltip,
} from 'reactstrap';
import BasicMenu from '../../../components/Menu';
import useToast from '../../../hooks/useToast';
import { useMutation, useQuery } from '@apollo/client';
import { GET_RESOURCES, REMOVE_RESOURCE } from '../../../graphql/resource.graphql';
import { LinearProgress } from '@mui/material';
import { Resource } from '../../../models/resource.model';
import _ from 'lodash';
import { Link, useNavigate } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

export default function ResourceListView() {
    const [page, setPage] = useState<number>(0);
    const [rowPerPage, setRowPerPage] = useState<number>(10);
    const { showToast, Notification } = useToast();
    const [searchKey, setSearchKey] = useState<string>('');
    const [sortFiled, setSortField] = useState<string>('name');
    const [sortDirection, setSortDirection] = useState<string>('desc');
    const [alert, setAlert] = useState<any>(null);

    const navigate = useNavigate();

    const { data, loading, error } = useQuery(GET_RESOURCES);

    const [removeResource, { data: removedResource }] = useMutation(REMOVE_RESOURCE, {
        refetchQueries: [{ query: GET_RESOURCES }],
    });

    const nextPage = () => {
        setPage(page + 1);
    };

    const getRowValue = (value: string) => {
        setRowPerPage(parseInt(value));
    };

    useEffect(() => {
        if (removedResource) {
            showToast('Successfully removed resource', 'success');
        }
    }, [removedResource]);

    const displayAlert = (props: any) => {
        setAlert(
            <SweetAlert
                {...props}
                showCancel
                confirmBtnText='Continue'
                confirmBtnBsStyle='danger'
                title='Are you sure?'
                onConfirm={() => {
                    removeResource({
                        variables: {
                            id: props.res.id,
                        },
                    });
                    setAlert(null);
                }}
                onCancel={() => {
                    setAlert(null);
                }}
                focusCancelBtn>
                This resource will be removed from the system.
            </SweetAlert>,
        );
    };

    const sortBy: any = (resources: Resource[], field: string, direction: string) => {
        const resourceMap = new Map([
            ['name', _.orderBy(resources, (res) => res.name, [direction === 'asc' ? 'asc' : 'desc'])],
            ['status', _.orderBy(resources, (res) => res.status.title, [direction === 'asc' ? 'asc' : 'desc'])],
            ['type', _.orderBy(resources, (res) => res.type.title, [direction === 'asc' ? 'asc' : 'desc'])],
        ]);
        return resourceMap.get(field);
    };

    const previousPage = () => {
        setPage(page - 1);
    };

    useEffect(() => {
        if (error) {
            showToast('An error has occurred while trying to load resources', 'danger');
            console.log('Error', error);
        }
    }, [error]);

    if (loading) {
        return <LinearProgress />;
    }

    const resources: Resource[] = data?.resource || [];

    let filteredResources = resources?.filter((res) => {
        if (searchKey === '') {
            return res;
        }
        return (
            res.name.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            res.status.title.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            res.type.title.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase())
        );
    });

    filteredResources = sortBy(filteredResources, sortFiled, sortDirection);

    return (
        <>
            {alert}
            {Notification}
            <Container className='mt-4' fluid>
                <Row>
                    <Col>
                        <h1>Resources</h1>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <div className='col'>
                        <Card>
                            <CardHeader className='border-0'>
                                <Row>
                                    <Col xs='6'>
                                        <h3 className='mb-0'>Resources</h3>
                                    </Col>
                                    <Col className='text-right' xs='6'>
                                        <button
                                            onClick={() => navigate('/admin/resources/new')}
                                            className='btn btn-round btn-sm btn-dark'
                                            color='default'
                                            id='tooltip969372949'>
                                            <span className='btn-inner--text'>Add resource</span>
                                        </button>
                                        <UncontrolledTooltip delay={0} target='tooltip969372949'>
                                            Add Resource
                                        </UncontrolledTooltip>
                                    </Col>
                                </Row>
                                <Row className='mt-4'>
                                    <Col>
                                        <Input
                                            className='w-25'
                                            value={searchKey}
                                            placeholder='Search keyword...'
                                            onChange={(e) => {
                                                setSearchKey(e.target.value);
                                                setPage(0);
                                            }}></Input>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <Table className='align-items-center table-flush'>
                                <thead className='thead-light'>
                                    <tr>
                                        <th
                                            onClick={() => {
                                                setSortField('name');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='subscriber'
                                            scope='col'>
                                            Name
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('status');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='status'
                                            scope='col'>
                                            Status
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('type');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='type'
                                            scope='col'>
                                            Type
                                        </th>
                                        <th scope='col'></th>
                                    </tr>
                                </thead>
                                <tbody className='list'>
                                    {filteredResources.map((res) => {
                                        return (
                                            <tr
                                                key={res.id}
                                                onClick={() => {
                                                    navigate(`/admin/resources/manage/${res?.id}`);
                                                }}
                                                style={{
                                                    cursor: 'pointer',
                                                }}>
                                                <td>{res.name}</td>
                                                <td>{res.status.title}</td>
                                                <td>{res.type.title}</td>
                                                <td className='text-right'>
                                                    <Link
                                                        className='btn btn-info btn-icon-only rounded-circle btn-sm'
                                                        to={`/admin/resources/manage/${res?.id}`}>
                                                        <i className='fa-solid fa-pencil'></i>
                                                    </Link>

                                                    <Button
                                                        className='btn btn-danger btn-icon-only rounded-circle btn-sm'
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            displayAlert({ warning: true, res });
                                                        }}>
                                                        <i className='fa-solid fa-trash-can'></i>
                                                    </Button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                            <CardFooter className='py-4'>
                                <Row>
                                    <div className='col-4 d-flex'>
                                        <span style={{ fontSize: '12px' }}>Rows per page:</span>
                                        <BasicMenu
                                            getValue={getRowValue}
                                            options={['10', '20', '100']}
                                            value={rowPerPage.toString()}
                                        />
                                    </div>
                                    <div className='col-4 d-flex'>
                                        <span className='min-text'>Page:</span>
                                        <span className='min-text pl-2'>
                                            {page + 1} of {Math.ceil(filteredResources.length / rowPerPage)}
                                        </span>
                                    </div>
                                    <div className='col-4 d-flex'>
                                        <Pagination>
                                            <PaginationItem className={page <= 0 ? 'disabled' : ''}>
                                                <PaginationLink onClick={(e) => previousPage()}>
                                                    <i className='fas fa-angle-left' />
                                                    <span className='sr-only'>Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem
                                                className={
                                                    page >= Math.ceil(filteredResources.length / rowPerPage) - 1
                                                        ? 'disabled'
                                                        : ''
                                                }>
                                                <PaginationLink onClick={(e) => nextPage()}>
                                                    <i className='fas fa-angle-right' />
                                                    <span className='sr-only'>Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                    </div>
                                </Row>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
}
