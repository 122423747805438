import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import Sidebar from '../components/Sidebar/Sidebar';
import routes from '../routes';
import AdminNavbar from '../components/Navbars/AdminNavbar';

import { PreLoader } from '../components/Pre-Loader';
import { useGetUser } from '../hooks/auth.hook';

function Admin(): any {
    const [sidenavOpen, setSidenavOpen] = useState(true);
    const location = useLocation();
    const mainContentRef: any = useRef(null);
    const { userLoading, user } = useGetUser();

    // console.log("USER", user);

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        if (user) {
            if (document.scrollingElement) document.scrollingElement.scrollTop = 0;
            mainContentRef.current.scrollTop = 0;
        }
    }, [location, user]);

    // const getRoutes = (routes: any) => {
    //     return routes.map((prop: any, key: any) => {
    //         if (prop.collapse) {
    //             return getRoutes(prop.views);
    //         }
    //         if (prop.layout === '/admin') {
    //             return (
    //                 <Route path={prop.layout + prop.path} element={prop.component} key={key}>
    //                     {prop.children &&
    //                         prop.children.map((child: any, childKey: any) => (
    //                             <Route
    //                                 // exact={true}

    //                                 path={prop.layout + prop.path + child.path}
    //                                 element={child.component}
    //                                 key={key + '_' + childKey}
    //                             />
    //                         ))}
    //                 </Route>
    //             );
    //         } else {
    //             return null;
    //         }
    //     });
    // };

    // toggles collapse between mini sidenav and normal
    const toggleSidenav = useCallback(
        (e: any) => {
            if (document.body.classList.contains('g-sidenav-pinned')) {
                document.body.classList.remove('g-sidenav-pinned');
                document.body.classList.add('g-sidenav-hidden');
            } else {
                document.body.classList.add('g-sidenav-pinned');
                document.body.classList.remove('g-sidenav-hidden');
            }
            setSidenavOpen(!sidenavOpen);
        },
        [sidenavOpen],
    );

    if (!user || userLoading) {
        return <PreLoader />;
    }

    return (
        user && (
            <>
                <Sidebar
                    routes={routes}
                    toggleSidenav={toggleSidenav}
                    sidenavOpen={sidenavOpen}
                    logo={{
                        innerLink: '/',
                        imgSrc: require('../assets/img/brand/argon-react.png'),
                        imgAlt: '...',
                    }}
                />
                <div className='main-content' ref={mainContentRef}>
                    {/* <AdminNavbar theme={'light'} toggleSidenav={toggleSidenav} sidenavOpen={sidenavOpen} /> */}
                    <Outlet />
                </div>
                {sidenavOpen ? <div className='backdrop d-xl-none' onClick={toggleSidenav} /> : null}
            </>
        )
    );
}

export default Admin;
