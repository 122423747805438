import React from "react";
import { Card, CardBody } from "reactstrap";
import ReactECharts from "echarts-for-react";
import { TotalMonthlyPackages } from "../../../../models/dashboard.model";

import moment from "moment";
import { EChartsOption } from "echarts";

interface Props {
  totalPackages: TotalMonthlyPackages[];
}

const TotalPackageAvailability = ({ totalPackages }: Props) => {
  let legend: string[] = [];
  let data: any[] = [];

  //Package names
  let packageNames: string[] = totalPackages.map(
    (packageName) => packageName.packageName
  );

  const availablePackagesName: string[] = packageNames.filter(
    (n, index) => packageNames.indexOf(n) === index
  );

  legend = availablePackagesName;

  //Month data
  let months: number[] = totalPackages.map((element) => element.month);
  const totalMonths: number[] = months.filter(
    (m, index) => months.indexOf(m) === index
  );
  const totalFormattedMonths: string[] = totalMonths.map((element) =>
    moment()
      .month(element - 1)
      .format("MMM")
  );

  availablePackagesName.forEach((packageName) => {
    data.push({
      name: packageName,
      type: "line",
      stack: "Total",
      data: totalPackages
        .filter((element) => element.packageName === packageName)
        .map((element) => element.packageCount),
    });
  });

  const chartOptions: EChartsOption = {
    title: {
      text: "Total monthly packages",
    },
    tooltip: {
      trigger: "axis",
    },
    legend: {
      data: legend,

      top: "12%",
    },
    grid: {
      left: "3%",
      right: "4%",
      top: "30%",
      bottom: "3%",
      containLabel: true,
    },

    xAxis: {
      type: "category",
      boundaryGap: true,
      data: totalFormattedMonths,
    },
    yAxis: {
      type: "value",
    },
    series: data,
  };
  return (
    <Card>
      <CardBody>
        <ReactECharts option={chartOptions} style={{ height: "500px" }} />
      </CardBody>
    </Card>
  );
};

export default TotalPackageAvailability;
