import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table,
    UncontrolledTooltip,
} from 'reactstrap';
import BasicMenu from '../../../../components/Menu';
import { useParams } from 'react-router-dom';
import useToast from '../../../../hooks/useToast';
import _ from 'lodash';
import { MembershipAgreement } from '../../../../models/membership-agreement.model';
import { useMutation, useQuery } from '@apollo/client';
import { GET_MEMBERSHIP_AGREEMENTS, REMOVE_MEMBER_AGREEMENT } from '../../../../graphql/membership-agreemnet.graphql';
import { LinearProgress } from '@mui/material';
import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';
import MembershipAgreementAddForm from './MembershipAgreementAddForm';
import MembershipAgreementDetailsForm from './MembershipAgreementDetailsForm';
import { downloadPdf } from '../../../../utils/file.utils';

export default function MembershipAgreementList() {
    const [page, setPage] = useState<number>(0);
    const [searchKey, setSearchKey] = useState<string>('');
    const [rowPerPage, setRowPerPage] = useState<number>(10);
    const [alert, setAlert] = useState<any>(null);
    const { showToast, Notification } = useToast();
    const params: any = useParams();
    const [sortField, setSortField] = useState<string>('startDate');
    const [sortDirection, setSortDirection] = useState<string>('asc');

    const [agreementId, setAgreementId] = useState<string>('');

    const [showAddForm, setShowAddForm] = useState<boolean>(false);
    const [showDetailsForm, setShowDetailsForm] = useState<boolean>(false);

    const { data, loading, error } = useQuery(GET_MEMBERSHIP_AGREEMENTS, {
        variables: {
            accountId: params?.id,
        },
    });

    const [removeAgreement, { data: removed }] = useMutation(REMOVE_MEMBER_AGREEMENT, {
        refetchQueries: [GET_MEMBERSHIP_AGREEMENTS],
    });

    const getMonthlyTotal = (aggregate: any): number => {
        let totalAmount = 0;

        // console.log('Aggregate', aggregate?.aggregate);

        const balconyFee = aggregate?.aggregate?.sum.balconyFee ?? 0;
        const parkingFee = aggregate?.aggregate?.sum.parkingFee ?? 0;
        const totalFee = aggregate?.aggregate?.sum.totalFee ?? 0;

        totalAmount = balconyFee + parkingFee + totalFee;

        return totalAmount;
    };

    const getAgreementDuration = (agreement: MembershipAgreement): string => {
        const startDate = moment(agreement.startDate);
        const endDate = moment(agreement.endDate);
        const durationInMonths = endDate.diff(startDate, 'months');

        if (durationInMonths <= 1) {
            return `${durationInMonths} month`;
        }

        return `${durationInMonths} months`;
    };

    useEffect(() => {
        if (removed) {
            showToast('Successfully removed membership agreement.', 'success');
        }
    }, [removed]);

    const sortBy: any = (agreements: MembershipAgreement[], field: string, direction: string) => {
        const orderedProviders = new Map([
            [
                'startDate',
                _.orderBy(agreements, (agreement) => agreement.startDate, [direction === 'asc' ? 'asc' : 'desc']),
            ],
            [
                'duration',
                _.orderBy(agreements, (agreement) => getAgreementDuration(agreement), [
                    direction === 'asc' ? 'asc' : 'desc',
                ]),
            ],
            [
                'status',
                _.orderBy(agreements, (agreement) => agreement.status.title, [direction === 'asc' ? 'asc' : 'desc']),
            ],

            [
                'total',
                _.orderBy(agreements, (agreement) => getMonthlyTotal(agreement?.itemsAggregate), [
                    direction === 'asc' ? 'asc' : 'desc',
                ]),
            ],
        ]);
        return orderedProviders.get(field);
    };

    const downloadDocument = (path: string, documentId: string) => {
        const url = `${process.env.REACT_APP_IMAGE_URL}/${path}`;
        downloadPdf(url, `${documentId}`);
    };

    const displayAlert = (props: any) => {
        setAlert(
            <SweetAlert
                {...props}
                showCancel
                confirmBtnText='Continue'
                confirmBtnBsStyle='danger'
                title='Are you sure?'
                onConfirm={() => {
                    removeAgreement({
                        variables: {
                            id: props?.agreement?.id,
                        },
                    });
                    setAlert(null);
                }}
                onCancel={() => {
                    setAlert(null);
                }}
                focusCancelBtn>
                This membership agreement will be removed from the system.
            </SweetAlert>,
        );
    };

    const previousPage = () => {
        setPage(page - 1);
    };

    const nextPage = () => {
        setPage(page + 1);
    };

    const getRowValue = (value: string) => {
        setRowPerPage(parseInt(value));
    };

    useEffect(() => {
        if (error) {
            showToast('An error has occurred while trying to load the page', 'danger');
        }
    }, [error]);

    const redirectToDetailsForm = (agreementId: string) => {
        setShowAddForm(false);
        setShowDetailsForm(true);
        setAgreementId(agreementId);
    };

    if (showAddForm) {
        return (
            <MembershipAgreementAddForm
                onRedirectToDetails={redirectToDetailsForm}
                onRedirectBack={() => setShowAddForm(false)}
            />
        );
    }

    if (showDetailsForm) {
        return (
            <MembershipAgreementDetailsForm
                agreementId={agreementId}
                onRedirectBack={() => setShowDetailsForm(false)}
            />
        );
    }

    if (loading) {
        return <LinearProgress />;
    }

    const agreements: MembershipAgreement[] = data?.agreements;
    let filteredAgreements = agreements?.filter((agreement) => {
        if (searchKey === '') {
            return agreement;
        }
        const filtered =
            agreement.startDate.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            agreement.endDate.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            agreement.status.title.toString().toLocaleLowerCase().includes(searchKey.toLocaleLowerCase());

        return filtered;
    });

    filteredAgreements = sortBy(filteredAgreements, sortField, sortDirection);

    // console.log('Agreements', filteredAgreements);

    return (
        <>
            {alert}
            {Notification}
            <Row className='mt-4'>
                <div className='col'>
                    <Card>
                        <CardHeader className='border-0'>
                            <Row>
                                <Col xs='6'>
                                    <h3 className='mb-0'>Membership Agreements</h3>
                                </Col>
                                <Col className='text-right' xs='6'>
                                    <Button
                                        onClick={() => setShowAddForm(true)}
                                        className='btn btn-round btn-sm btn-dark'
                                        color='default'
                                        id='tooltip969372949'>
                                        <span className='btn-inner--text'>Add membership agreement </span>
                                    </Button>

                                    <UncontrolledTooltip delay={0} target='tooltip969372949'>
                                        Add membership agreement
                                    </UncontrolledTooltip>
                                </Col>
                            </Row>
                            <Row className='mt-4 border-0'>
                                <Col xs='8'>
                                    <Input
                                        className='form-control w-25'
                                        type='text'
                                        value={searchKey}
                                        placeholder='Search keyword...'
                                        onChange={(e) => {
                                            setSearchKey(e.target.value);
                                            setPage(0);
                                        }}
                                    />
                                </Col>
                            </Row>
                        </CardHeader>
                        <Table className='align-items-center table-flush' responsive>
                            <thead className='thead-light'>
                                <tr>
                                    <th
                                        onClick={() => {
                                            setSortField('startDate');
                                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                        }}
                                        className='sort'
                                        data-sort='date'
                                        scope='col'>
                                        Start date
                                    </th>
                                    <th
                                        onClick={() => {
                                            setSortField('duration');
                                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                        }}
                                        className='sort'
                                        data-sort='reference'
                                        scope='col'>
                                        Duration
                                    </th>
                                    <th
                                        onClick={() => {
                                            setSortField('status');
                                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                        }}
                                        className='sort'
                                        data-sort='reference'
                                        scope='col'>
                                        Status
                                    </th>

                                    <th
                                        onClick={() => {
                                            setSortField('total');
                                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                        }}
                                        className='sort'
                                        data-sort='paymentStatus'
                                        scope='col'>
                                        Total per month
                                    </th>

                                    <th scope='col' />
                                </tr>
                            </thead>
                            <tbody className='list'>
                                {filteredAgreements
                                    .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                                    .map((agreement) => {
                                        return (
                                            <tr
                                                key={agreement.id}
                                                onClick={() => {
                                                    setAgreementId(agreement.id);
                                                    setShowDetailsForm(true);
                                                }}
                                                style={{
                                                    cursor: 'pointer',
                                                }}>
                                                <td>{moment(agreement?.startDate).format('yyyy-MM-DD')}</td>
                                                <td>{getAgreementDuration(agreement)}</td>
                                                <td>{agreement?.status?.title ?? ''}</td>
                                                <td>{`R ${getMonthlyTotal(agreement?.itemsAggregate).toFixed(2)}`}</td>

                                                <td>
                                                    <Button
                                                        onClick={() => {
                                                            setAgreementId(agreement.id);
                                                            setShowDetailsForm(true);
                                                        }}
                                                        className='btn btn-info btn-icon-only rounded-circle btn-sm'>
                                                        <i className='fa-solid fa-pencil'></i>
                                                    </Button>

                                                    <Button
                                                        className='btn btn-outlined-info btn-sm'
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            if (agreement.documentPath) {
                                                                downloadDocument(agreement.documentPath, agreement.id);
                                                            }
                                                        }}
                                                        disabled={!agreement.documentPath}>
                                                        Document
                                                    </Button>

                                                    <Button
                                                        className='btn btn-danger btn-icon-only rounded-circle btn-sm'
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            displayAlert({ warning: true, agreement });
                                                        }}>
                                                        <i className='fa-solid fa-trash-can'></i>
                                                    </Button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </Table>
                        <CardFooter className='py-4'>
                            <Row>
                                <div className='col-4 d-flex'>
                                    <span style={{ fontSize: '12px' }}>Rows per page:</span>
                                    <BasicMenu
                                        getValue={getRowValue}
                                        options={['10', '20', '100']}
                                        value={rowPerPage.toString()}
                                    />
                                </div>
                                <div className='col-4 d-flex'>
                                    <span className='min-text'>Page:</span>
                                    <span className='min-text pl-2'>
                                        {page + 1} of {Math.ceil(filteredAgreements.length / rowPerPage)}
                                    </span>
                                </div>
                                <div className='col-4 d-flex'>
                                    <Pagination>
                                        <PaginationItem className={page <= 0 ? 'disabled' : ''}>
                                            <PaginationLink onClick={(e) => previousPage()}>
                                                <i className='fas fa-angle-left' />
                                                <span className='sr-only'>Previous</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                        <PaginationItem
                                            className={
                                                page >= Math.ceil(filteredAgreements.length / rowPerPage) - 1
                                                    ? 'disabled'
                                                    : ''
                                            }>
                                            <PaginationLink onClick={(e) => nextPage()}>
                                                <i className='fas fa-angle-right' />
                                                <span className='sr-only'>Previous</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                    </Pagination>
                                </div>
                            </Row>
                        </CardFooter>
                    </Card>
                </div>
            </Row>
        </>
    );
}
