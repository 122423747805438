/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useQuery, useMutation } from '@apollo/client';
import { GET_CLIENT_MEMBER, GET_CLIENT } from '../../../graphql/clients.graphql';
import { Checkbox, FormControlLabel, LinearProgress, MenuItem, Select } from '@mui/material';
import useToast from '../../../hooks/useToast';
import Spinner from '../../../components/Spinner/Spinner';
import { UPDATE_MEMBER } from '../../../graphql/user.graphql';
import { Role } from '../../../models/user.model';
import { PhoneInput } from 'react-international-phone';
import { MemberStatus, TeamMember } from '../../../models/team.member.model';
import ReactDatetimeClass from 'react-datetime';
import moment, { isMoment } from 'moment';
import { PhoneNumberUtil } from 'google-libphonenumber';

interface ClientManageProps {
    goBackToMembers(): void;
    id: string;
}

type FormData = {
    name: string;
    surname: string;
    email: string;
    contactNumber: string;
    identityNumber: string;
    status: string;
    licencePlate: string;
    role: string;
    sendCommunication: boolean;
    dob?: string;
};

const ClientManageMemberForm = ({ goBackToMembers, id }: ClientManageProps) => {
    const {
        register,
        setValue,
        setError,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>();
    const { showToast, Notification } = useToast();
    const [userId, setUserId] = useState<string>('');

    const { loading, error, data } = useQuery(GET_CLIENT_MEMBER, {
        variables: {
            id: id,
        },
    });

    const phoneUtil = PhoneNumberUtil.getInstance();

    const isPhoneValid = (phone: string) => {
        try {
            return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
        } catch (error) {
            return false;
        }
    };

    const [updateMember, { loading: isUpdating, error: updateError, data: updatedClientMember }] = useMutation(
        UPDATE_MEMBER,
        {
            refetchQueries: [{ query: GET_CLIENT }, 'GetClient', { query: GET_CLIENT_MEMBER }, 'GetClientMember'],
        },
    );

    const onSubmit = (data: FormData) => {
        if (!isPhoneValid(data.contactNumber)) {
            setError('contactNumber', { type: 'custom', message: '*Please enter a valid phone number' });
            return;
        }

        const user = {
            name: data.name,
            surname: data.surname,
            email: data.email,
            id_number: data.identityNumber,
            licence_plate: data.licencePlate,
            contact_number: data.contactNumber,
            date_of_birth: data.dob || undefined,
        };

        const member = {
            role_id: data.role,
            status_enum: data.status,
            send_communication: data.sendCommunication,
        };

        updateMember({
            variables: {
                id: userId,
                user: user,
                memberId: id,
                member: member,
            },
        });
    };

    useEffect(() => {
        if (error) {
            showToast('An error has occurred, please refresh the page', 'danger');
        }

        if (updateError) {
            showToast('An error has occurred while trying to update this member, please try again', 'danger');
        }
    }, [error, updateError]);

    useEffect(() => {
        if (data) {
            setUserId(data.member.user.id);
        }
    }, [data]);

    useEffect(() => {
        if (updatedClientMember) {
            showToast('Successfully updated the member details.', 'success');
        }
    }, [updatedClientMember]);

    if (loading) {
        return <LinearProgress />;
    }

    const clientMember: TeamMember = data?.member;
    const roles: Role[] = data?.roles;
    const memberStatusList: MemberStatus[] = data?.memberStatus;

    // console.log('MEMBER', clientMember);

    return (
        <>
            {Notification}
            <Row>
                <Col md='12' lg='6'>
                    <Card className='mt-4'>
                        <CardHeader>
                            <Row noGutters={true}>
                                <button
                                    className='btn btn-outline-primary btn-sm mr-4'
                                    onClick={() => goBackToMembers()}>
                                    <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                                    <span className='btn-inner-text'>Back</span>
                                </button>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    <Col md='12' lg='6'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='p-member-name'>
                                                Name
                                            </label>
                                            <input
                                                className='form-control'
                                                id='p-member-name'
                                                placeholder="Enter member's name..."
                                                {...register('name', {
                                                    required: true,
                                                    value: clientMember.user.name,
                                                })}
                                                type='text'
                                            />
                                            {errors.name && <span className='invalid'>*This field is required</span>}
                                        </FormGroup>
                                    </Col>
                                    <Col md='12' lg='6'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='p-member-surname'>
                                                Surname
                                            </label>
                                            <input
                                                className='form-control'
                                                id='p-member-surname'
                                                placeholder="Enter member's surname..."
                                                {...register('surname', {
                                                    required: true,
                                                    value: clientMember.user.surname,
                                                })}
                                                type='text'
                                            />
                                            {errors.surname && <span className='invalid'>*This field is required</span>}
                                        </FormGroup>
                                    </Col>
                                    <Col md='12' lg='6'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='email'>
                                                Email
                                            </label>
                                            <input
                                                className='form-control'
                                                id='email'
                                                placeholder="Enter the member's email..."
                                                {...register('email', {
                                                    required: true,
                                                    value: clientMember.user.email,
                                                })}
                                                type='email'
                                            />
                                            {errors.email && <span className='invalid'>*This field is required</span>}
                                        </FormGroup>
                                    </Col>
                                    <Col md='12' lg='6'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='contact'>
                                                Contact number
                                            </label>
                                            <PhoneInput
                                                defaultCountry='za'
                                                placeholder='Enter phone number...'
                                                inputClassName='form-control'
                                                value={clientMember.user.contactNumber}
                                                style={
                                                    {
                                                        '--react-international-phone-width': '100%',
                                                        '--react-international-phone-height': '44px',
                                                        '--react-international-phone-border-radius': '12px',
                                                    } as React.CSSProperties
                                                }
                                                // value={watch('contactNumber')}
                                                {...register('contactNumber', {
                                                    required: true,
                                                    value: clientMember.user.contactNumber ?? '',
                                                })}
                                                onChange={(phone) => setValue('contactNumber', phone)}
                                            />
                                            {errors.contactNumber && (
                                                <span className='invalid'>
                                                    {errors.contactNumber.message ?? '*This field is required'}
                                                </span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md='12' lg='6'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='identityNumber'>
                                                Identity/Passport number(optional)
                                            </label>
                                            <input
                                                className='form-control'
                                                id='identityNumber'
                                                placeholder="Enter member's alternative identity number..."
                                                {...register('identityNumber', {
                                                    required: false,
                                                    value: clientMember.user.identityNumber,
                                                })}
                                                type='text'
                                            />
                                            {errors.identityNumber && (
                                                <span className='invalid'>*This field is required</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md='12' lg='6'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='licencePlate'>
                                                License plate(optional)
                                            </label>
                                            <input
                                                className='form-control'
                                                id='licencePlate'
                                                placeholder="Enter member's licence plate..."
                                                {...register('licencePlate', {
                                                    required: false,
                                                    value: clientMember.user?.licencePlate,
                                                })}
                                                type='text'
                                            />
                                            {errors.licencePlate && (
                                                <span className='invalid'>*This field is required</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md='12' lg='6'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='email'>
                                                Role
                                            </label>
                                            <Select
                                                id='package'
                                                className='form-control'
                                                sx={{ borderRadius: '12px', padding: '0' }}
                                                placeholder='Select a member'
                                                defaultValue={clientMember?.role ? clientMember?.role?.id : 'empty'}
                                                inputProps={{
                                                    ...register('role', {
                                                        required: true,
                                                        value: clientMember?.role?.id || 'empty',
                                                    }),
                                                }}>
                                                <MenuItem disabled value={'empty'}>
                                                    Select a user role...
                                                </MenuItem>
                                                {roles.map((role) => {
                                                    return (
                                                        <MenuItem key={role.id} value={role.id}>
                                                            {`${role.name}`}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormGroup>
                                    </Col>
                                    <Col md='12' lg='6'>
                                        <label className='form-control-label'>Date of birth(Optional)</label>
                                        <ReactDatetimeClass
                                            inputProps={{
                                                placeholder: 'Select date of birth...',
                                                ...register('dob', {
                                                    required: false,
                                                    value: clientMember.user.dateOfBirth
                                                        ? moment(clientMember.user.dateOfBirth).format('YYYY-MM-DD')
                                                        : undefined,
                                                }),
                                            }}
                                            initialValue={
                                                clientMember.user.dateOfBirth
                                                    ? moment(clientMember.user.dateOfBirth).format('yyyy-MM-DD')
                                                    : ''
                                            }
                                            dateFormat={'YYYY-MM-DD'}
                                            timeFormat={false}
                                            onChange={(value) => {
                                                if (value && isMoment(value)) {
                                                    setValue('dob', value.format('yyyy-MM-DD'));
                                                }
                                                // onInputChange();
                                            }}
                                        />
                                    </Col>
                                    <Col md='12' lg='6'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='email'>
                                                Status
                                            </label>
                                            <Select
                                                id='package'
                                                className='form-control'
                                                sx={{ borderRadius: '12px', padding: '0' }}
                                                placeholder='Select a member'
                                                defaultValue={clientMember?.status?.value || 'empty'}
                                                inputProps={{
                                                    ...register('status', {
                                                        required: true,
                                                        value: clientMember?.status?.value || 'empty',
                                                    }),
                                                }}>
                                                <MenuItem disabled value={'empty'}>
                                                    Select a member status...
                                                </MenuItem>
                                                {memberStatusList.map((status) => {
                                                    return (
                                                        <MenuItem key={status?.value} value={status?.value}>
                                                            {`${status.title}`}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormGroup>
                                    </Col>
                                    <Col md='12' lg='6'>
                                        <FormControlLabel
                                            className='mt-4'
                                            control={
                                                <Checkbox
                                                    defaultChecked={clientMember?.sendCommunication || false}
                                                    onChange={(e) => {
                                                        setValue('sendCommunication', e.target.checked);
                                                    }}
                                                    {...register('sendCommunication', {
                                                        required: false,
                                                    })}
                                                />
                                            }
                                            label='Send Communications'
                                        />
                                    </Col>
                                </Row>

                                <div className='d-flex h-100'>
                                    <div className='align-self-end ml-auto'>
                                        <Button
                                            type='submit'
                                            className='btn btn-dark mt-4 btn-block'
                                            disabled={isUpdating}>
                                            {isUpdating ? (
                                                <span>
                                                    <Spinner />
                                                </span>
                                            ) : (
                                                <span>Update member</span>
                                            )}
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default ClientManageMemberForm;
