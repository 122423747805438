import { gql } from "@apollo/client";

export const GET_AFTER_HOUR_RESOURCES = gql`
  query GetAfterHourResources {
    resources: after_hour_resource(where: { deleted_at: { _is_null: true } }) {
      id
      name
      rate
    }
  }
`;
