import React, { useState } from "react";
import Spinner from "../../../components/Spinner/Spinner";
import { Auth } from "aws-amplify";
import useToast from "../../../hooks/useToast";

interface EmailFormProps {
  switchToPassword: () => void;
  getEmail: (email: string) => void;
}

interface PasswordFormProps {
  email: string;
}

const EmailForm = ({ switchToPassword, getEmail }: EmailFormProps) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const { showToast, Notification } = useToast();

  const sendVerificationCode = async () => {
    Auth.forgotPassword(email)
      .then((data) => {
        console.log("Successfully send OTP", data);
        switchToPassword();
        getEmail(email);
      })
      .catch((err) => {
        showToast(
          "An error has occurred, make sure that you have entered a correct email address",
          "danger"
        );
        setLoading(false);
      });
  };

  const submitForm = async (e: any) => {
    e.preventDefault();

    //Error handling...
    if (email === "") {
      setEmailError(true);
      return;
    }

    setLoading(true);
    await sendVerificationCode();
  };

  return (
    <>
      {Notification}
      <div className="login-form">
        <h1 className="text-primary">Hey, Forgot your password?</h1>
        <div className="form-group">
          <label className="form-control-label" htmlFor="email">
            Email address
          </label>
          <input
            id="email"
            className="form-control"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError(false);
            }}
            type="email"
            placeholder="name@company.com..."
          />
          {emailError && (
            <span style={{ color: "red", fontSize: "12px" }}>
              * This field is required
            </span>
          )}
        </div>
        <button
          className="btn btn-primary btn-block mt-4 "
          disabled={loading}
          onClick={(e) => submitForm(e)}
        >
          {loading ? <Spinner /> : <span>SUBMIT</span>}
        </button>
      </div>
    </>
  );
};

const PasswordForm = ({ email }: PasswordFormProps) => {
  const [verificationCode, setVerificationCode] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [verificationCodeError, setVerErrorCode] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [password1Error, setPassword1Error] = useState(false);
  const [password2Error, setPassword2Error] = useState(false);
  const [loading, setLoading] = useState(false);
  const { showToast, Notification } = useToast();

  const redirectToSignIn = () => (window.location.href = "/");
  const changePassword = async () => {
    Auth.forgotPasswordSubmit(email, verificationCode, password1)
      .then((data) => {
        console.log("Successfully changed password", data);
        redirectToSignIn();
      })
      .catch((err) => {
        showToast(
          "An error has occurred, make sure you entered the correct details",
          "danger"
        );
        setLoading(false);
      });
  };

  const submitForm = async (e: any) => {
    e.preventDefault();

    if (verificationCode === "") {
      setVerErrorCode(true);
      return;
    }

    if (password1 === "") {
      setPassword1Error(true);
      return;
    }

    if (password2 === "") {
      setPassword2Error(true);
      return;
    }

    if (password1 !== password2) {
      setPasswordMatch(false);
      return;
    }

    setLoading(true);
    await changePassword();
  };

  return (
    <>
      {Notification}
      <div className="login-form">
        <h1 className="text-primary">Hey, Forgot your password?</h1>
        <div className="form-group">
          <label className="form-control-label" htmlFor="password">
            Verification code{" "}
          </label>
          <input
            id="verification-code"
            value={verificationCode}
            className="form-control"
            type="text"
            placeholder="enter verification code..."
            onChange={(e) => {
              setVerificationCode(e.target.value);
              setVerErrorCode(false);
            }}
          />
          {verificationCodeError && (
            <span style={{ color: "red", fontSize: "12px" }}>
              * verification code must not be empty.
            </span>
          )}
        </div>
        <div className="form-group">
          <label className="form-control-label" htmlFor="password">
            Password
          </label>
          <input
            id="password"
            value={password1}
            className="form-control"
            type="password"
            placeholder="enter new password..."
            onChange={(e) => {
              setPassword1(e.target.value);
              setPassword1Error(false);
              setPasswordMatch(true);
            }}
          />
          {password1Error && (
            <p style={{ color: "red", fontSize: "12px" }}>
              * password must not be empty.
            </p>
          )}
        </div>
        <div className="form-group">
          <label className="form-control-label" htmlFor="password2">
            Re-enter password
          </label>
          <input
            id="password2"
            value={password2}
            className="form-control"
            type="password"
            placeholder="re-enter the password..."
            onChange={(e) => {
              setPassword2(e.target.value);
              setPassword2Error(false);
              setPasswordMatch(true);
            }}
          />
          {password2Error && (
            <p style={{ color: "red", fontSize: "12px" }}>
              * password must not be empty.
            </p>
          )}
          {!passwordMatch && (
            <p style={{ color: "red", fontSize: "12px" }}>
              * passwords must match.
            </p>
          )}
        </div>
        <button
          className="btn btn-primary btn-block"
          onClick={(e: any) => submitForm(e)}
        >
          {!loading ? (
            <span>RESET PASSWORD</span>
          ) : (
            <span>
              {" "}
              <Spinner />{" "}
            </span>
          )}
        </button>
      </div>
    </>
  );
};

const SideImag = () => {
  return (
    <div className="side-image">
      <div className="image-2"></div>
    </div>
  );
};

const PasswordRecoverView = () => {
  const [isEmailForm, setIsEmailForm] = useState(true);
  const [userEmail, setUserEmail] = useState<string>("");

  return (
    <div className="main-container">
      <div className="form-login-container">
        {isEmailForm ? (
          <EmailForm
            getEmail={setUserEmail}
            switchToPassword={() => {
              setIsEmailForm(false);
            }}
          />
        ) : (
          <PasswordForm email={userEmail} />
        )}
        <SideImag />
      </div>
    </div>
  );
};

export default PasswordRecoverView;
