/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
import Container from "reactstrap/es/Container";
import BasicMenu from "../../../components/Menu";
import {
  GET_STATUS_TYPE,
  GET_SUBSCRIBERS,
  REMOVE_SUBSCRIBER,
} from "../../../graphql/colab-subscriber.graphql";
import { useMutation, useQuery } from "@apollo/client";
import { Status, Subscribers } from "../../../models/colab-subscribers.model";
import useToast from "../../../hooks/useToast";
import _ from "lodash";
import { getPermission } from "../../../utils/user.utils";
import { User } from "../../../models/user.model";
import { useSelector } from "react-redux";
import { LinearProgress } from "@mui/material";
import StatusComponent from "./component/StatusComponent";
import SweetAlert from "react-bootstrap-sweetalert";
import { useLocalStorage } from "../../../hooks/local-storage.hook";

interface SubscriberRouteData {
  keyWord: string;
  page: number;
  pageSize: number;
}

interface SubscriberRouteData {
  keyWord: string;
  page: number;
  pageSize: number;
}

export default function SubscribersView() {
  const user: User | null = useSelector((state: any) => state.user.userInfo);
  const [page, setPage] = useState<number>(0);
  const [rowPerPage, setRowPerPage] = useState<number>(10);
  const { showToast, Notification } = useToast();
  const [searchKey, setSearchKey] = useState<string>("");
  const [sortFiled, setSortField] = useState<string>("subscriber");
  const [sortDirection, setSortDirection] = useState<string>("desc");
  const [alert, setAlert] = useState<any>(null);
  const [getLocalStorage, setLocalStorage] = useLocalStorage("subscriber");
  const { data, error, loading } = useQuery(GET_SUBSCRIBERS);
  const { data: statustypeData } = useQuery(GET_STATUS_TYPE);
  const [removeSubscriber, { data: subscriberRemoved, error: removeError }] =
    useMutation(REMOVE_SUBSCRIBER, {
      // refetchQueries: [{ query: GET_SUBSCRIBERS }, "getSubscribers"],
      refetchQueries: ["getSubscribers"],
    });

  useEffect(() => {
    const routeData: SubscriberRouteData = getLocalStorage();

    if (routeData) {
      setPage(routeData?.page || 0);
      setRowPerPage(routeData?.pageSize || 10);
      setSearchKey(routeData?.keyWord || "");
    }
  }, []);

  const nextPage = () => {
    setPage(page + 1);
    const routeData: SubscriberRouteData = getLocalStorage() || {};
    routeData.page = page + 1;
    setLocalStorage(routeData);
  };

  const previousPage = () => {
    setPage(page - 1);
    const routeData: SubscriberRouteData = getLocalStorage() || {};
    routeData.page = page - 1;
    setLocalStorage(routeData);
  };

  const getRowValue = (value: string) => {
    setRowPerPage(parseInt(value));
    const routeData: SubscriberRouteData = getLocalStorage() || {};
    routeData.pageSize = parseInt(value);
    setLocalStorage(routeData);
  };

  useEffect(() => {
    if (error) {
      showToast("An error has occurred, please refresh the page", "danger");
    }
    if (removeError) {
      showToast(
        "An error has occurred while trying to remove a subscriber",
        "danger"
      );
    }
  }, [error, removeError]);

  useEffect(() => {
    if (subscriberRemoved) {
      showToast("Successfully removed subscriber", "success");
    }
  }, [subscriberRemoved]);

  const sortBy: any = (
    subscribers: Subscribers[],
    field: string,
    direction: string
  ) => {
    const orderedBookings = new Map([
      [
        "subscriber",
        _.orderBy(subscribers, (sub) => sub.subscriber, [
          direction === "asc" ? "asc" : "desc",
        ]),
      ],
      [
        "status",
        _.orderBy(subscribers, (sub) => sub.status.title, [
          direction === "asc" ? "asc" : "desc",
        ]),
      ],
    ]);
    return orderedBookings.get(field);
  };

  const subscribers: Subscribers[] = data?.subscribers;
  const statusType: Status[] | [] = statustypeData?.status;

  let filteredSubscribers = subscribers?.filter((sub) => {
    if (searchKey === "") {
      return sub;
    }
    return (
      sub.subscriber
        .toLocaleLowerCase()
        .includes(searchKey.toLocaleLowerCase()) ||
      sub.status.title
        .toLocaleLowerCase()
        .includes(searchKey.toLocaleLowerCase())
    );
  });

  filteredSubscribers = sortBy(filteredSubscribers, sortFiled, sortDirection);

  if (loading) {
    return <LinearProgress />;
  }

  const displayAlert = (props: any) => {
    setAlert(
      <SweetAlert
        {...props}
        showCancel
        confirmBtnText="Continue"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => {
          removeSubscriber({ variables: { id: props.sub.id } });
          setAlert(null);
        }}
        onCancel={() => {
          setAlert(null);
        }}
        focusCancelBtn
      >
        This subscriber will be removed from the system.
      </SweetAlert>
    );
  };

  return (
    <>
      {Notification}
      {alert}
      <Container className="mt-4" fluid>
        <Row>
          <Col>
            <h1>Subscribers</h1>
          </Col>
        </Row>
        <Row className="mt-4">
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Subscribers</h3>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <Input
                      className="w-25"
                      value={searchKey}
                      placeholder="Search keyword..."
                      onChange={(e) => {
                        setSearchKey(e.target.value);
                        setPage(0);

                        const routeData: SubscriberRouteData =
                          getLocalStorage() || {};
                        routeData.keyWord = e.target.value;
                        routeData.page = 0;
                        setLocalStorage(routeData);
                      }}
                    ></Input>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush">
                <thead className="thead-light">
                  <tr>
                    <th
                      onClick={() => {
                        setSortField("subscriber");
                        setSortDirection(
                          sortDirection === "asc" ? "desc" : "asc"
                        );
                      }}
                      className="sort"
                      data-sort="subscriber"
                      scope="col"
                    >
                      Subscriber
                    </th>
                    <th
                      onClick={() => {
                        setSortField("status");
                        setSortDirection(
                          sortDirection === "asc" ? "desc" : "asc"
                        );
                      }}
                      className="sort"
                      data-sort="status"
                      scope="col"
                    >
                      Status
                    </th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody className="list">
                  {filteredSubscribers
                    ?.slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                    .map((sub: any) => {
                      return (
                        <tr key={sub.id}>
                          <td>{`${sub.subscriber}`}</td>
                          <td>
                            {
                              <StatusComponent
                                statusList={statusType}
                                selectedStatus={sub.status}
                                selectedStatusId={sub.id}
                              />
                            }
                          </td>
                          <td className="text-right">
                            {getPermission(user, "locations", "delete") && (
                              <Button
                                className="btn btn-danger btn-icon-only rounded-circle btn-sm"
                                onClick={() => {
                                  displayAlert({ warning: true, sub });
                                }}
                              >
                                <i className="fa-solid fa-trash-can"></i>
                              </Button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <Row>
                  <div className="col-4 d-flex">
                    <span style={{ fontSize: "12px" }}>Rows per page:</span>
                    <BasicMenu
                      getValue={getRowValue}
                      options={["10", "20", "100"]}
                      value={rowPerPage.toString()}
                    />
                  </div>
                  <div className="col-4 d-flex">
                    <span className="min-text">Page:</span>
                    <span className="min-text pl-2">
                      {page + 1} of{" "}
                      {Math.ceil(filteredSubscribers.length / rowPerPage)}
                    </span>
                  </div>
                  <div className="col-4 d-flex">
                    <Pagination>
                      <PaginationItem className={page <= 0 ? "disabled" : ""}>
                        <PaginationLink onClick={(e) => previousPage()}>
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem
                        className={
                          page >=
                          Math.ceil(filteredSubscribers.length / rowPerPage) - 1
                            ? "disabled"
                            : ""
                        }
                      >
                        <PaginationLink onClick={(e) => nextPage()}>
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </div>
                </Row>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
