/* eslint-disable react-hooks/exhaustive-deps */
import { CKEditor } from '@ckeditor/ckeditor5-react';
import React, { useEffect, useState } from 'react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Row } from 'reactstrap';
import { Autocomplete, Chip, LinearProgress, MenuItem, Select, TextField } from '@mui/material';
// import { useHistory } from "react-router-dom";
import ReactDatetimeClass from 'react-datetime';
import moment from 'moment';
import { useMutation, useQuery } from '@apollo/client';
import {
    ADD_TASK,
    GET_TASK_VIEW_DATA,
    // GET_TASK_LIST,
} from '../../../graphql/task-list.graphql';
import useToast from '../../../hooks/useToast';
import { User } from '../../../models/user.model';
import { Assignee, TaskType } from '../../../models/tasks.model';
import { bookingTimeList } from '../../../variables/variables';
import { useForm } from 'react-hook-form';
import { getTime } from '../../../utils/booking.utils';
import Spinner from '../../../components/Spinner/Spinner';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

type FormData = {
    name: string;
    description: string;
    type: string;
    date: string;
    time: string;
};

const TaskAddView = () => {
    // const history = useHistory();
    const navigate = useNavigate();
    const { showToast, Notification } = useToast();
    const [assignees, setAssignees] = useState<Assignee[]>([]);
    const user: User | null = useSelector((state: any) => state.user.userInfo);
    const { data, loading, error } = useQuery(GET_TASK_VIEW_DATA);
    const [addTask, { loading: adding, data: addedTask, error: errorAdding }] = useMutation(ADD_TASK, {
        // refetchQueries: [{ query: GET_TASK_LIST }, "GetTaskList"],
        refetchQueries: ['GetTaskList'],
    });

    const {
        register,
        setValue,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<FormData>();

    useEffect(() => {
        if (error) {
            showToast('An error has occurred, please refresh the page', 'danger');
        }

        if (errorAdding) {
            showToast('An error has occurred while trying to add a task', 'danger');
        }

        if (addedTask) {
            showToast('Successfully added a task', 'success');
            reset();
        }
    }, [error, addedTask, errorAdding]);

    const redirectToTaskListView = () => {
        navigate(-1);
    };

    if (loading) {
        return <LinearProgress />;
    }

    const onSubmit = (data: FormData) => {
        if (assignees.length === 0) {
            showToast('Assign at least one user to this task', 'danger');
            return;
        }

        const dueDate = moment(data?.date)?.utc(true).hours(getTime(data.time)[0]).minutes(0).toISOString();

        const task = {
            title: data.name,
            description: data.description,
            due_date: dueDate,
            type_enum: data.type,
            status_enum: 'pending',
            assignees: {
                data: assignees.map((assignee) => {
                    return {
                        user_id: assignee.userId,
                    };
                }),
            },
            events: {
                data: {
                    user_id: user?.id,
                    title: 'Task Add',
                    description: `added this task`,
                },
            },
        };

        console.log('Task', task);
        addTask({
            variables: {
                task: task,
            },
        });
    };

    const taskTypes: TaskType[] = data?.taskType;
    const users: User[] = data?.user;

    return (
        <Container className='mt-4' fluid>
            {Notification}
            <Row>
                <Col md='12' lg='6'>
                    <Card>
                        <CardHeader>
                            <Row noGutters={true}>
                                <button
                                    className='btn btn-outline-primary btn-sm mr-4'
                                    onClick={redirectToTaskListView}>
                                    <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                                    <span className='btn-inner-text'>Back</span>
                                </button>
                                <h3 className='m-0'>Add task</h3>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='name'>
                                        Name
                                    </label>
                                    <input
                                        className='form-control'
                                        id='company-name'
                                        placeholder='Enter a task name...'
                                        {...register('name', {
                                            required: true,
                                        })}
                                        type='text'
                                    />
                                    {errors.name && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='name'>
                                        Description
                                    </label>
                                    <CKEditor
                                        id='description'
                                        editor={ClassicEditor}
                                        config={{
                                            removePlugins: ['Heading'],
                                            toolbar: [
                                                'bold',
                                                'italic',
                                                'bulletedList',
                                                'numberedList',
                                                'blockQuote',
                                                'link',
                                            ],
                                        }}
                                        placeholder='Enter room description'
                                        onChange={(event: any, editor: any) => {
                                            setValue('description', editor.getData());
                                        }}
                                        onReady={(editor: any) => {}}
                                        inputProps={{
                                            ...register('description', {
                                                required: true,
                                            }),
                                        }}
                                    />
                                    {errors.description && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='email'>
                                        Assignees
                                    </label>
                                    <Autocomplete
                                        id='tags-outlined'
                                        blurOnSelect={true}
                                        onChange={(event, value) => {
                                            if (!value) return;
                                            const assignee: Assignee = {
                                                id: value?.id,
                                                name: value?.name + ' ' + value?.surname,
                                                userId: value?.id,
                                            };

                                            const assigneeList = [...assignees, assignee];
                                            setAssignees(assigneeList);
                                        }}
                                        options={users}
                                        getOptionLabel={(option) => option.name + ' ' + option.surname}
                                        filterSelectedOptions
                                        renderInput={(params) => (
                                            <TextField {...params} placeholder='Select a user to assign...' />
                                        )}
                                    />
                                    {assignees.map((assignee) => {
                                        return (
                                            <Chip
                                                key={assignee.id}
                                                label={assignee.name}
                                                onDelete={() => {
                                                    const assigneeList = [
                                                        ...assignees.filter((a) => a.userId !== assignee.userId),
                                                    ];
                                                    setAssignees(assigneeList);
                                                }}
                                            />
                                        );
                                    })}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='contact'>
                                        Type
                                    </label>
                                    <Select
                                        className='form-control'
                                        sx={{ borderRadius: '12px', padding: '0' }}
                                        defaultValue={'empty'}
                                        inputProps={{
                                            ...register('type', {
                                                required: true,
                                            }),
                                        }}>
                                        <MenuItem disabled className='placeholder-text' value={'empty'}>
                                            Select a task type...
                                        </MenuItem>
                                        {taskTypes?.map((task) => {
                                            return (
                                                <MenuItem key={task.value} value={task.value}>
                                                    {task.title}
                                                </MenuItem>
                                            );
                                        })}
                                        {errors.type && <span className='invalid'>*This field is required</span>}
                                    </Select>
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='package'>
                                        Due date
                                    </label>
                                    <div className='row'>
                                        <div className='col-7'>
                                            <FormGroup>
                                                <ReactDatetimeClass
                                                    inputProps={{
                                                        placeholder: 'Enter due date...',
                                                        autoComplete: 'off',
                                                        ...register('date', {
                                                            required: true,
                                                        }),
                                                    }}
                                                    dateFormat={'YYYY-MM-DD'}
                                                    onChange={(date: moment.Moment) => {
                                                        setValue('date', date.format('YYYY-MM-DD'));
                                                    }}
                                                    initialValue={moment()}
                                                    timeFormat={false}
                                                />
                                                {errors.date && (
                                                    <span className='invalid'>*This field is required</span>
                                                )}
                                            </FormGroup>
                                        </div>
                                        <div className='col-5'>
                                            <Select
                                                id='room'
                                                defaultValue={'empty'}
                                                sx={{ borderRadius: '12px', padding: '0' }}
                                                className='form-control'
                                                inputProps={{
                                                    ...register('time', {
                                                        required: false,
                                                    }),
                                                }}>
                                                <MenuItem disabled value='empty'>
                                                    Select time...
                                                </MenuItem>
                                                {bookingTimeList.map((time) => {
                                                    return (
                                                        <MenuItem key={time} value={time}>
                                                            {time}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                            {errors.time && <span className='invalid'>*This field is required</span>}
                                        </div>
                                    </div>
                                </FormGroup>
                                <div className='d-flex h-100'>
                                    <div className='align-self-end ml-auto'>
                                        <Button type='submit' disabled={adding} className='btn btn-dark mt-4 btn-block'>
                                            {adding && <Spinner />}
                                            {!adding && <span>Add Task</span>}
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default TaskAddView;
