/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { GET_USER } from "../graphql/queries";
import { setUser } from "../slices/userSlice";
import { Auth } from "aws-amplify";
import { useState, useEffect } from "react";
// import { useHistory } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export const useAuth = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [userRole, setUserRole] = useState<string>();
  useEffect(() => {
    setIsLoading(true);
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setUserId(user.attributes.sub);

        setUserRole(user.attributes["custom:role_name"]);
        setIsLoading(false);
      })
      .catch((err) => {
        navigate("/");
      });
  }, [userId]);
  return { userId, isLoading, userRole };
};

export const useGetUser = () => {
  // const history = useHistory();
  const navigate = useNavigate();
  const { userId, isLoading, userRole } = useAuth();
  const dispatch = useDispatch();
  const { loading, error, data } = useQuery(GET_USER, {
    variables: { userId },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (userRole && userRole !== "portal_user") {
      console.log("Not allowed");
      Auth.signOut();
    }
  }, [userRole]);

  if (data?.user) {
    dispatch(setUser(data?.user));
  }

  useEffect(() => {
    if (error) {
      navigate("/"); //  redirect to login screen if the user was not found...
    }
  }, [error]);
  return { userLoading: loading || isLoading, user: data?.user };
};
