import { gql } from '@apollo/client';

export const GetLeads = gql`
    query GetLeads {
        leads: product_lead(where: { deleted_at: { _is_null: true } }, order_by: { created_at: desc }) {
            id
            companyName: company_name
            industry
            contactTime: contact_time
            contactName: contact_name
            contactNumber: contact_number
            contactEmail: contact_email
            officeEnvironment: office_environment_type
            specialNeeds: special_needs
            totalCapacity: total_capacity
            createdAt: created_at
            category: category_enum
            status {
                value
                title
            }
        }
    }
`;

export const GetLeadDetails = gql`
    query GetLeadDetails($leadId: uuid!) {
        lead: product_lead_by_pk(id: $leadId) {
            id
            companyName: company_name
            industry
            message
            contactTime: contact_time
            contactName: contact_name
            contactNumber: contact_number
            contactEmail: contact_email
            officeEnvironment: office_environment_type
            specialNeeds: special_needs
            totalCapacity: total_capacity
            createdAt: created_at
            category: category_enum
            productLeadComments: product_lead_lead_comments(where: { deleted_at: { _is_null: true } }) {
                id
                comment
                createdAt: created_at
                commentUser: lead_comment_user {
                    name
                    surname
                }
                commentType: lead_comment_type {
                    value
                    title
                }
            }
            status {
                value
                title
            }
        }
        leadStatuses: lead_status(order_by: { title: asc }) {
            value
            title
        }
    }
`;

export const UPDATE_LEAD_STATUS = gql`
    mutation UpdateLeadStatus($leadId: uuid!, $status: lead_status_enum) {
        update_product_lead_by_pk(pk_columns: { id: $leadId }, _set: { status_enum: $status }) {
            id
        }
    }
`;

export const REMOVE_LEAD = gql`
    mutation RemoveLead($leadId: uuid!) {
        update_product_lead_by_pk(pk_columns: { id: $leadId }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const ADD_COMMENT = gql`
    mutation addComment($comment: lead_comment_insert_input!) {
        insert_lead_comment_one(object: $comment) {
            id
        }
    }
`;

export const GET_COMMENT_TYPES = gql`
    query getCommentTypes {
        commentTypes: lead_comment_type {
            value
            title
        }
    }
`;

export const REMOVE_COMMENT = gql`
    mutation removeComment($commentId: uuid!) {
        update_lead_comment_by_pk(pk_columns: { id: $commentId }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const ADD_LEAD = gql`
    mutation InsertLead($lead: product_lead_insert_input!) {
        insert_product_lead_one(object: $lead) {
            id
        }
    }
`;
