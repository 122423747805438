import { Auth } from "aws-amplify";
import React, { useState, useEffect } from "react";
import Spinner from "../../../components/Spinner/Spinner";
import useToast from "../../../hooks/useToast";
import NewPasswordForm from "./NewPasswordForm";
const logo = require("../../../assets/img/colab-dark.png");

const Form = () => {
  const [email, setEmail] = useState("");
  const [user, setUser] = useState();
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const { showToast, Notification } = useToast();

  const signIn = async () => {
    try {
      const result = await Auth.signIn(email, password);
      console.log("result", result);
      if (result.challengeName === "NEW_PASSWORD_REQUIRED") {
        setUser(result);
        setShowNewPassword(true);
      }
    } catch (error: any) {
      console.log("error signing in", error);
      showToast("Incorrect email or password", "danger");
      setLoading(false);
    }
  };

  const submitForm = (e: any) => {
    e.preventDefault();

    if (email === "") {
      setEmailError(true);
      return;
    }

    if (password === "") {
      setPasswordError(true);
      return;
    }

    setLoading(true);
    signIn();
  };

  return (
    <>
      {Notification}
      {!showNewPassword && (
        <form className="login-form">
          <img className="logo mb-5" src={logo} alt="colab" />
          <h1 className="text-primary">Hey, Welcome Back!</h1>
          <div className="form-group">
            <label className="form-control-label" htmlFor="email">
              Email address
            </label>
            <input
              value={email}
              id="email"
              className="form-control"
              type="email"
              placeholder="name@company.com"
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailError(false);
              }}
            />
            {emailError && (
              <span style={{ color: "red", fontSize: "12px" }}>
                * email must not be empty.
              </span>
            )}
          </div>
          <div className="form-group">
            <label className="form-control-label" htmlFor="password">
              Password
            </label>
            <input
              value={password}
              className="form-control"
              id="password"
              type="password"
              placeholder="enter 6 characters or more"
              onChange={(e) => {
                setPassword(e.target.value);
                setPasswordError(false);
              }}
            />
            {passwordError && (
              <span style={{ color: "red", fontSize: "12px" }}>
                * password must not be empty.
              </span>
            )}
          </div>
          <button
            onClick={() => {
              window.location.href = "/password-recovery";
            }}
            type="button"
            className="btn btn-link"
          >
            Forgot password?
          </button>
          <button
            disabled={loading}
            className="btn btn-primary btn-block mt-4 ml-0"
            onClick={(e: any) => submitForm(e)}
          >
            {!loading ? (
              <span>LOGIN</span>
            ) : (
              <span>
                <Spinner />
              </span>
            )}
          </button>
        </form>
      )}
      {showNewPassword && <NewPasswordForm user={user} />}
    </>
  );
};

const SideImag = () => {
  return (
    <div className="side-image">
      <div className="image"></div>
    </div>
  );
};

const SignIn: any = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    Auth.currentAuthenticatedUser()
      .then((user) => {
        window.location.href = "/admin/dashboard";
      })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  return (
    !loading && (
      <div className="main-container">
        <div className="form-login-container">
          <Form />
          <SideImag />
        </div>
      </div>
    )
  );
};
export default SignIn;
