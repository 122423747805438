import { gql } from "@apollo/client";

export const GET_USER = gql`
  query ($userId: uuid!) {
    user: user_by_pk(id: $userId) {
      id
      name
      surname
      email
      role {
        id
        name
        tag
        rolePermissions: role_permissions {
          id
          create
          read
          update
          delete
          permission {
            id
            name
            tag
          }
        }
      }
    }
  }
`;

export const GET_USERS = gql`
  query GetUsers {
    users: user(
      where: { _and: { deleted_at: { _is_null: true }, role: { level: { _neq: "client" } } } }
      order_by: { name: asc_nulls_last }
    ) {
      id
      name
      surname
      email
      contactNumber: contact_number
      role {
        id
        name
      }
    }
  }
`;
