import React from "react";
import { CircularProgress, Dialog, DialogContent } from "@mui/material";

interface LoaderDialogProps {
  isOpen: boolean;
}

const LoaderDialog = ({ isOpen }: LoaderDialogProps) => {
  return (
    <Dialog open={isOpen}>
      <DialogContent>
        <div className="d-flex justify-content-center align-items-center w-100 pb-4">
          <CircularProgress />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default LoaderDialog;
