/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Table } from "reactstrap";
import { useMutation } from "@apollo/client";
import useToast from "../../../../hooks/useToast";
import {
  GET_MEETING_ROOM,
  REMOVE_ROOM_RATE,
} from "../../../../graphql/meeting-rooms.graphql";
import { RateType, RoomRate } from "../../../../models/meeting.room.model";
import SweetAlert from "react-bootstrap-sweetalert";
import { AddRoomRateDialog } from "../dialogs/AddRoomRateDialog";
interface MeetingRoomRatesProps {
  roomId: string;
  rates: RoomRate[];
  rateTypes: RateType[];
}

export const MeetingRoomRates = ({
  rates,
  rateTypes,
  roomId,
}: MeetingRoomRatesProps) => {
  const [selectedRate, setSelectedRate] = useState<RoomRate | null>(null);
  const [openAddRate, setOpenAddRate] = useState(false);
  const { showToast, Notification } = useToast();
  const [alert, setAlert] = useState<any>(null);

  const [
    removeRate,
    {
      error: errorRemoving,
      called: calledRemoveRate,
      loading: isRemovingRate,
      reset: resetRemoving,
    },
  ] = useMutation(REMOVE_ROOM_RATE, {
    refetchQueries: [{ query: GET_MEETING_ROOM }, "GetMeetingRoom"],
  });

  useEffect(() => {
    if (calledRemoveRate && !isRemovingRate) {
      resetRemoving();
      showToast("Successfully removed rate", "success");
    }
    if (calledRemoveRate && errorRemoving) {
      showToast("An error has occurred, please try again", "danger");
    }
  }, [errorRemoving, calledRemoveRate, isRemovingRate]);

  const editRate = (rate: RoomRate) => {
    setSelectedRate(rate);
    setOpenAddRate(true);
  };

  const displayRemoveRateAlert = (props: any) => {
    setAlert(
      <SweetAlert
        {...props}
        showCancel
        confirmBtnText="Continue"
        confirmBtnBsStyle="danger"
        title="Are you sure? This action cannot be undone"
        onConfirm={() => {
          setAlert(null);
          removeRate({
            variables: {
              rateId: props.rate.id,
            },
          });
        }}
        onCancel={() => {
          setAlert(null);
        }}
        focusCancelBtn
      >
        This room rate will be removed from the system.
      </SweetAlert>
    );
  };

  return (
    <>
      {
        <AddRoomRateDialog
          isOpen={openAddRate}
          rateTypes={rateTypes}
          roomId={roomId}
          rate={selectedRate}
          toggleOpen={(isOpen) => {
            setOpenAddRate(isOpen);
          }}
        />
      }
      {Notification}
      {alert}
      <Card>
        <CardBody>
          <div className="row no-gutters justify-content-between align-items-center mb-3">
            <h3 className="mb-0">Room Rates</h3>
            <button
              className="btn btn-dark btn-sm"
              onClick={() => {
                setSelectedRate(null);
                setOpenAddRate(true);
              }}
            >
              Add rate
            </button>
          </div>
          {rates.length === 0 && <h5>No rates added for this room</h5>}
          {rates.length > 0 && (
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Type</th>
                  <th scope="col">Price (excl VAT)</th>

                  <th scope="col" />
                </tr>
              </thead>
              <tbody className="list">
                {rates.map((rate) => {
                  return (
                    <tr
                      key={rate.id}
                      style={{ cursor: "pointer" }}
                      onClick={() => editRate(rate)}
                    >
                      <td>{rate.type.title}</td>
                      <td>{`${
                        rate.currency === "zar" ? "R" : "R"
                      }${rate.price.toFixed(2)}`}</td>
                      <td className="text-right">
                        <Button
                          className="btn btn-info btn-icon-only rounded-circle btn-sm"
                          onClick={() => {
                            editRate(rate);
                          }}
                        >
                          <i className="fa-solid fa-pencil"></i>
                        </Button>
                        <Button
                          className="btn btn-danger btn-icon-only rounded-circle  btn-sm"
                          onClick={(e) => {
                            e.stopPropagation();
                            displayRemoveRateAlert({ warning: true, rate });
                          }}
                        >
                          <i className="fa-regular fa-trash-can"></i>
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </CardBody>
      </Card>
    </>
  );
};
