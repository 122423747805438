/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Row,
} from "reactstrap";
import FeaturedImage from "../../../../../components/Image component/ImageContainer";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Chip, LinearProgress, MenuItem, Select } from "@mui/material";
import { useForm } from "react-hook-form";
import useToast from "../../../../../hooks/useToast";
import {
  ADD_ROOM_EXTRA,
  GET_MEETING_ROOM,
  GET_ROOM_EXTRA_STATUS,
  GET_ROOM_EXTRA_UNIT,
  REMOVE_ROOM_EXTRA_VARIANT,
} from "../../../../../graphql/meeting-rooms.graphql";
import { useMutation, useQuery } from "@apollo/client";
import Spinner from "../../../../../components/Spinner/Spinner";
import { uploadImage } from "../../../../../hooks/upload-image.hook";
import {
  RoomExtra,
  RoomExtraStatus,
  RoomExtraUnit,
} from "../../../../../models/room.extra.model";
import { useParams } from "react-router-dom";

type FormData = {
  title: string;
  description: string;
  price: string;
  status: string;
  unit: string;
  variant: string;
};

interface RoomExtraDetailFormProps {
  roomExtra: RoomExtra;
  backToListView: () => void;
}

const RoomExtraDetailForm = ({
  backToListView,
  roomExtra,
}: RoomExtraDetailFormProps) => {
  const [image, setImage] = useState<string>(
    `${process.env.REACT_APP_IMAGE_URL}/${roomExtra.featuredImagePath}`
  );
  const [imageFile, setImageFile] = useState<any>();
  const { showToast, Notification } = useToast();
  const fileRef = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [
    updateRoomExtra,
    { data: addedRoomExtra, loading: addingRoomExtra, error },
  ] = useMutation(ADD_ROOM_EXTRA, {
    refetchQueries: [{ query: GET_MEETING_ROOM }, "GetMeetingRoom"],
  });

  const [deleteRoomExtraVariant, { data, error: errorRemoving }] = useMutation(
    REMOVE_ROOM_EXTRA_VARIANT,
    {
      refetchQueries: [{ query: GET_MEETING_ROOM }, "GetMeetingRoom"],
    }
  );

  const params: any = useParams();

  const {
    data: statusList,
    loading: loadingStatusList,
    error: statusError,
  } = useQuery(GET_ROOM_EXTRA_STATUS);
  const {
    data: unitList,
    loading: loadingUnitList,
    error: unitError,
  } = useQuery(GET_ROOM_EXTRA_UNIT);

  const [variants, setVariants] = useState<string[]>(
    roomExtra.variants.map((variant) => variant.name)
  );

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<FormData>();

  useEffect(() => {
    register("description", {
      required: true,
      value: roomExtra.description,
    });

    console.log("Image", process.env.REACT_APP_IMAGE_URL);
  }, []);

  useEffect(() => {
    if (addedRoomExtra) {
      showToast("Successfully added a room extra", "success");
      setLoading(false);
      reset();
    }

    if (data) {
      console.log("Removed successfully");
    }
  }, [addedRoomExtra, data]);

  useEffect(() => {
    if (error) {
      showToast("An error has occurred while trying to add an extra", "danger");
      setLoading(false);
    }

    if (statusError || unitError) {
      showToast("An error has occurred", "danger");
    }

    if (errorRemoving) {
      console.log("Removing error:", errorRemoving);
    }
  }, [error, statusError, unitError, errorRemoving]);

  const onImageChange = (e: any) => {
    const imageUrl = URL.createObjectURL(e.target.files[0]);
    setImageFile(e.target.files[0]);
    setImage(imageUrl);
  };

  const handleKeyDown = (event: any) => {
    if (["Enter", "Tab", ","].includes(event.key)) {
      event.preventDefault();
      const value = event.target.value;
      setVariants([...variants, value]);
      event.target.value = "";
    }
  };

  const onSubmit = async (data: FormData) => {
    let imageKey = "";
    setLoading(true);

    if (imageFile) imageKey = await uploadImage(imageFile, "room_extra");
    if (!imageFile && roomExtra.featuredImagePath)
      imageKey = roomExtra.featuredImagePath;

    if (imageKey === "error") {
      showToast(
        "An error has occurred while trying to upload this image, please try again",
        "danger"
      );
      setLoading(false);
      return;
    }

    const variantList = variants.map((variant) => {
      return {
        name: variant,
      };
    });

    const room_extra = {
      id: roomExtra.id,
      title: data.title,
      meeting_room_id: params.id,
      description: data.description,
      unit: data.unit,
      price: data.price,
      status_enum: data.status,
      featured_image_path: imageKey,
      room_extra_variants: {
        on_conflict: {
          constraint: "room_extra_variant_name_key",
          update_columns: [],
        },
        data: [...variantList],
      },
    };
    // console.log("Data", room_extra);
    updateRoomExtra({
      variables: {
        extra: room_extra,
      },
    });
  };

  const status: RoomExtraStatus[] = statusList?.status;
  const units: RoomExtraUnit[] = unitList?.units;

  if (loadingStatusList || loadingUnitList) {
    return <LinearProgress />;
  }

  return (
    <>
      {Notification}
      <Row className="mt-4">
        <Col md="12" lg="6">
          <Card>
            <CardHeader>
              <Row noGutters={true}>
                <button
                  className="btn btn-outline-primary btn-sm mr-4"
                  onClick={backToListView}
                >
                  <i
                    className="fas fa-angle-left"
                    style={{ fontSize: "14px" }}
                  />
                  <span className="btn-inner-text">Back</span>
                </button>
                <h3 className="m-0">Update room extra</h3>
              </Row>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                  <label className="form-control-label" htmlFor="name">
                    Featured Image
                  </label>
                  <FeaturedImage imageSource={image} fileInputRef={fileRef} />
                  <input
                    onChange={(e) => onImageChange(e)}
                    type="file"
                    accept="image/*"
                    ref={fileRef}
                    style={{ display: "none" }}
                  />
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="name">
                    Title
                  </label>
                  <input
                    className="form-control"
                    {...register("title", {
                      required: true,
                      value: roomExtra?.title,
                    })}
                    id="company-name"
                    placeholder="Enter meeting room's name..."
                    type="text"
                  />
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="name">
                    Description
                  </label>
                  <CKEditor
                    id="description"
                    data={roomExtra.description}
                    editor={ClassicEditor}
                    config={{
                      removePlugins: ["Heading"],
                      toolbar: [
                        "bold",
                        "italic",
                        "bulletedList",
                        "numberedList",
                        "blockQuote",
                        "link",
                      ],
                    }}
                    placeholder="Enter room description"
                    onChange={(event: any, editor: any) => {
                      setValue("description", editor.getData());
                      trigger("description");
                    }}
                    onReady={(editor: any) => {}}
                  />
                  {errors.description && (
                    <span className="invalid">*This field is required</span>
                  )}
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="name">
                    Price
                  </label>
                  <input
                    className="form-control"
                    {...register("price", {
                      required: true,
                      value: roomExtra.price.toString(),
                    })}
                    id="company-name"
                    placeholder="Enter room extra's price..."
                    type="text"
                  />
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="contact">
                    Status
                  </label>
                  <Select
                    id="package"
                    className="form-control"
                    sx={{ borderRadius: "12px", padding: "0" }}
                    placeholder="Select a role"
                    defaultValue={roomExtra.status.value}
                    inputProps={{
                      ...register("status", {
                        required: true,
                        value: roomExtra.status.value,
                      }),
                    }}
                  >
                    <MenuItem
                      disabled
                      className="placeholder-text"
                      value={roomExtra.status.value}
                    >
                      Select room extra's status...
                    </MenuItem>
                    {status.map((element) => {
                      return (
                        <MenuItem key={element.value} value={element.value}>
                          {element.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {errors.status && (
                    <span className="invalid">*This field is required</span>
                  )}
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="contact">
                    Unit
                  </label>
                  <Select
                    id="package"
                    className="form-control"
                    sx={{ borderRadius: "12px", padding: "0" }}
                    placeholder="Select a role"
                    defaultValue={roomExtra.unit.value}
                    inputProps={{
                      ...register("unit", {
                        required: true,
                        value: roomExtra.unit.value,
                      }),
                    }}
                  >
                    <MenuItem
                      disabled
                      className="placeholder-text"
                      value={"empty"}
                    >
                      Select room extra's unit...
                    </MenuItem>
                    {units.map((element) => {
                      return (
                        <MenuItem key={element.value} value={element.value}>
                          {element.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {errors.unit && (
                    <span className="invalid">*This field is required</span>
                  )}
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="name">
                    Variant
                  </label>
                  <input
                    className="form-control"
                    onKeyDown={handleKeyDown}
                    {...register("variant", { required: false })}
                    id="company-name"
                    placeholder="Enter room extra's variant..."
                    type="text"
                  />
                </FormGroup>
                {variants?.map((variant, index) => {
                  return (
                    <Chip
                      key={index}
                      label={variant}
                      onDelete={() => {
                        const list = variants;
                        list.splice(index, 1);
                        const variantId = roomExtra.variants.find(
                          (element) => element.name === variant
                        )?.id;
                        // console.log("variantId", variantId);
                        setVariants([...list]);
                        deleteRoomExtraVariant({
                          variables: {
                            id: variantId,
                          },
                        });
                      }}
                    />
                  );
                })}
                <div className="d-flex h-100">
                  <div className="align-self-end ml-auto">
                    <Button
                      type="submit"
                      className="btn btn-dark mt-4 btn-block"
                      disabled={addingRoomExtra || loading}
                    >
                      {addingRoomExtra || loading ? (
                        <span>
                          <Spinner />
                        </span>
                      ) : (
                        <span>Update extra</span>
                      )}
                    </Button>
                  </div>
                </div>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default RoomExtraDetailForm;
