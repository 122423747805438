import moment from "moment";
import React from "react";

import { TaskComment } from "../../../../models/tasks.model";

interface TaskCommentCardProps {
  comment?: TaskComment;
  userId: string;
}

const TaskCommentCard = ({ comment, userId }: TaskCommentCardProps) => {
  //console.log("Date", comment?.createdAt);
  const getUserName = (): string => {
    if (userId === comment?.user?.id) {
      return "You";
    }

    return `${comment?.user?.name} ${comment?.user?.surname}`;
  };
  return (
    <>
      <div className="comment-card">
        <div className="card-body">
          <div className="row">
            <div className="col-6">
              <h5 className="card-title">
                {`${getUserName()}`}{" "}
                <span className="card-text"> commented</span>
              </h5>
            </div>
            <div className="col-6 text-right">
              <span className="card-text">
                {moment(comment?.createdAt).fromNow()}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p className="card-text">{comment?.value}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TaskCommentCard;
