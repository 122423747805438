/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, CardHeader, Row } from 'reactstrap';
import { useMutation } from '@apollo/client';
import {
    ADD_TASK_EVENT,
    // GET_TASK_DETAIL_VIEW_DATA,
} from '../../../../graphql/task-list.graphql';
import useToast from '../../../../hooks/useToast';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { User } from '../../../../models/user.model';
import { TaskEvent } from '../../../../models/tasks.model';
import ActivityTimeLieItem from './ActivityTimeLineItem';
import CommentTextField from './CommentTextField';

interface TaskCommentViewProps {
    events?: TaskEvent[];
}

const TaskActivityView = ({ events }: TaskCommentViewProps) => {
    const [addEvent, { data, error, loading }] = useMutation(ADD_TASK_EVENT, {
        refetchQueries: [
            // { query: GET_TASK_DETAIL_VIEW_DATA },
            'GetTaskDetailViewData',
        ],
    });

    const user: User | null = useSelector((state: any) => state.user.userInfo);
    const [commentText, setCommentText] = useState<string>();
    const params = useParams<any>();

    const { showToast, Notification } = useToast();

    useEffect(() => {
        if (error && !data) {
            showToast('There was an error while trying to add this comment', 'danger');
        }

        if (data && !error) {
            showToast('Successfully add a comment', 'success');
        }
    }, [data, error]);

    const addTaskComment = () => {
        const taskEvent = {
            task_id: params.id,
            title: '',
            description: '',
            user_id: user?.id,
            comment: {
                data: {
                    user_id: user?.id,
                    value: commentText,
                },
            },
        };

        addEvent({
            variables: {
                event: taskEvent,
            },
        });
    };

    const isLineItemLastChild = (event: TaskEvent): boolean => {
        return events.indexOf(event) === events.length - 1;
    };

    return (
        <>
            {Notification}
            <Card>
                <CardHeader>
                    <Row noGutters={true}>
                        <h3 className='m-0'>Activity</h3>
                    </Row>
                </CardHeader>
                <CardBody>
                    <div>
                        {events?.map((event) => {
                            return (
                                <div className='row'>
                                    <div className='col'>
                                        <ActivityTimeLieItem
                                            userId={user?.id}
                                            taskEvent={event}
                                            isLastChild={isLineItemLastChild(event)}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </CardBody>
                <CardFooter>
                    <div className='row mt-4'>
                        <div className='col'>
                            <CommentTextField
                                commentText={commentText}
                                getCommentText={setCommentText}
                                isLoading={loading}
                                onAddComment={addTaskComment}
                            />
                        </div>
                    </div>
                </CardFooter>
            </Card>
        </>
    );
};

export default TaskActivityView;
