import { useMutation, useQuery } from '@apollo/client';
import { LinearProgress, MenuItem, Select } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import ReactDatetimeClass from 'react-datetime';
import { useForm } from 'react-hook-form';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Row } from 'reactstrap';
import {
    ADD_MEMBERSHIP_AGREEMENT,
    GET_BILLING_PERIOD_AND_STATUS_LIST,
    GET_MEMBERSHIP_AGREEMENTS,
} from '../../../../graphql/membership-agreemnet.graphql';
import useToast from '../../../../hooks/useToast';
import {
    MembershipAgreementBillingPeriod,
    MembershipAgreementEscalationPeriod,
    MembershipAgreementItemFormData,
    MembershipAgreementItemStatus,
    MembershipAgreementStatus,
} from '../../../../models/membership-agreement.model';
import Spinner from '../../../../components/Spinner/Spinner';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { uploadDocument } from '../../../../hooks/upload-documents.hook';
import { useParams } from 'react-router-dom';
import AddMembershipAgreementItemDialog from '../dialogs/AddMembershipAgreementItemDialog';
import { TeamMember } from '../../../../models/team.member.model';
import { ProductPackage } from '../../../../models/packages.models';
import MembershipListItem from './MembershipListItem';
import MembershipListItemHeader from './MembershipListItemHeader';
import { isMoment } from 'moment';

interface MembershipAgreementAddFormProps {
    onRedirectBack: () => void;
    onRedirectToDetails: (agreementId: string) => void;
}

type FormData = {
    startDate: string;
    endDate: string;
    status: string;
    escalationPercentage: number;
    escalationDate: string;
    billingPeriod: string;
    escalationPeriod: string;
};

export default function MembershipAgreementAddForm({
    onRedirectBack,
    onRedirectToDetails,
}: MembershipAgreementAddFormProps) {
    const {
        register,
        reset,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>();

    const params: any = useParams();

    const { data, loading, error } = useQuery(GET_BILLING_PERIOD_AND_STATUS_LIST, {
        variables: {
            accountId: params?.id,
        },
    });
    const [addMembershipAgreement, { data: added, error: addingError }] = useMutation(ADD_MEMBERSHIP_AGREEMENT, {
        refetchQueries: [GET_MEMBERSHIP_AGREEMENTS],
    });
    const [selectedItem, setSelectedItem] = useState<MembershipAgreementItemFormData>();
    const { showToast, Notification } = useToast();

    const onUpdateItem = (items: MembershipAgreementItemFormData[]) => {
        setItems(items);
    };

    const onItemSelect = (item: MembershipAgreementItemFormData) => {
        if (!item) return;

        setSelectedItem(item);
        setIsOpen(true);
    };

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const fileInputRef = useRef<HTMLInputElement>();
    const [selectedFile, setSelectedFile] = useState<File>(null);
    const [items, setItems] = useState<MembershipAgreementItemFormData[]>([]);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const onSubmit = async (formData: FormData) => {
        if (!selectedFile) {
            showToast('Please make sure that a file is selected', 'danger');
            return;
        }

        let documentKey: string = '';

        setIsLoading(true);
        documentKey = await uploadDocument(selectedFile, 'membership_agreement');

        if (documentKey === 'error') {
            showToast('An error has occurred while trying to upload this file', 'danger');
            setIsLoading(false);
            return;
        }

        const agreement = {
            account_id: params?.id,
            start_date: formData.startDate,
            end_date: formData.endDate,
            status_enum: formData.status,
            escalation_percentage: formData.escalationPercentage / 100,
            billing_period_enum: formData.billingPeriod,
            document_path: documentKey,
            escalation_date: formData.escalationDate,
            escalation_period_enum: formData.escalationPeriod,

            agreement_items: {
                data: items?.map((item) => {
                    return {
                        package_id: item.packageId,
                        assign_member_id: item.assignMemberId ?? undefined,
                        parking_fee: item?.parkingFee ?? 0,
                        balcony_fee: item?.balconyFee ?? 0,
                        total_fee: item?.totalFee ?? 0,
                        status_enum: item?.status ?? '',
                    };
                }),
            },
        };

        await addMembershipAgreement({
            variables: {
                agreement,
            },
        });
    };

    const onItemAdd = (item: MembershipAgreementItemFormData) => {
        const index = items.indexOf(item);

        if (index !== -1) {
            const newItems = [...items];
            newItems[index] = { ...item };
            setItems([...newItems]);
            console.log('Working');
            return;
        }

        setItems([...items, item]);
    };

    const openFileExplorer = () => {
        fileInputRef.current.click();
    };

    const handleFileAttach = (e: any) => {
        setSelectedFile(e.target.files[0]);
    };

    useEffect(() => {
        if (added) {
            showToast('Successfully added membership agreement', 'success');
            reset();
            setIsLoading(false);
            onRedirectToDetails(added.agreement.id);
        }
    }, [added]);

    useEffect(() => {
        if (addingError) {
            showToast('An error has occurred while trying to add the membership agreement', 'danger');
            setIsLoading(false);
        }
    }, [addingError]);

    const onItemRemove = (item: MembershipAgreementItemFormData) => {
        const index = items.indexOf(item);
        const newItems = [...items];
        setItems(newItems.filter((i) => newItems.indexOf(i) !== index));
    };

    useEffect(() => {
        if (error) {
            showToast('An error has occurred while trying to load the page', 'danger');
        }
    }, [error]);

    if (loading) {
        return <LinearProgress />;
    }

    const billingPeriodList: MembershipAgreementBillingPeriod[] = data?.billingPeriodList;
    const statusList: MembershipAgreementStatus[] = data?.statusList;
    const escalationPeriodLists: MembershipAgreementEscalationPeriod[] = data?.escalationPeriodList;
    const itemStatusList: MembershipAgreementItemStatus[] = data?.membershipItemStatusList;
    const members: TeamMember[] = data?.members;
    const packages: ProductPackage[] = data?.packages;

    return (
        <>
            {Notification}
            {isOpen && (
                <AddMembershipAgreementItemDialog
                    onAddOrUpdateItem={onItemAdd}
                    isOpen={isOpen}
                    items={items}
                    onUpdateItem={onUpdateItem}
                    item={selectedItem}
                    members={members}
                    packages={packages}
                    statusList={itemStatusList}
                    handleClose={() => setIsOpen(false)}
                />
            )}
            <Row className='mt-4'>
                <Col md='12' lg='6'>
                    <Card>
                        <CardHeader>
                            <Row noGutters={true}>
                                <button
                                    className='btn btn-outline-primary btn-sm mr-4'
                                    onClick={() => onRedirectBack()}>
                                    <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                                    <span className='btn-inner-text'>Back</span>
                                </button>

                                <h4 className='m-0'>Add Membership Agreement</h4>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='name'>
                                        Start Date
                                    </label>
                                    <ReactDatetimeClass
                                        inputProps={{
                                            placeholder: 'Select start date...',
                                            ...register('startDate', {
                                                required: true,
                                            }),
                                        }}
                                        dateFormat={'YYYY-MM-DD'}
                                        timeFormat={false}
                                        onChange={(value) => {
                                            if (value && isMoment(value)) {
                                                setValue('startDate', value.format('yyyy-MM-DD'));
                                            }
                                        }}
                                    />
                                    {errors.startDate && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='street_address'>
                                        End Date
                                    </label>
                                    <ReactDatetimeClass
                                        inputProps={{
                                            placeholder: 'Select end date...',
                                            ...register('endDate', {
                                                required: true,
                                            }),
                                        }}
                                        dateFormat={'YYYY-MM-DD'}
                                        timeFormat={false}
                                        onChange={(value) => {
                                            if (value && isMoment(value)) {
                                                setValue('endDate', value.format('yyyy-MM-DD'));
                                            }
                                        }}
                                    />
                                    {errors.endDate && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='city'>
                                        Escalation Date
                                    </label>
                                    <ReactDatetimeClass
                                        inputProps={{
                                            placeholder: 'Select escalation date...',
                                            ...register('escalationDate', {
                                                required: true,
                                            }),
                                        }}
                                        dateFormat={'YYYY-MM-DD'}
                                        timeFormat={false}
                                        onChange={(value) => {
                                            if (value && isMoment(value)) {
                                                setValue('escalationDate', value.format('yyyy-MM-DD'));
                                            }
                                        }}
                                    />
                                    {errors.escalationDate && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label'>Escalation Period</label>
                                    <Select
                                        className='form-control'
                                        defaultValue={' '}
                                        {...register('escalationPeriod', { required: true })}
                                        sx={{ borderRadius: '12px', padding: 0 }}>
                                        <MenuItem disabled value={' '}>
                                            {'Select an escalation period...'}
                                        </MenuItem>
                                        {escalationPeriodLists.map((ep) => {
                                            return (
                                                <MenuItem key={ep.value} value={ep.value}>
                                                    {ep.title}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormGroup>

                                <FormGroup>
                                    <label className='form-control-label'>Escalation Percentage</label>
                                    <input
                                        className='form-control'
                                        {...register('escalationPercentage', { required: true })}
                                        placeholder='Enter escalation percentage...'
                                        type='number'
                                        step={0.01}
                                    />
                                    {errors.escalationPercentage && (
                                        <span className='invalid'>*This field is required</span>
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label'>Billing Period</label>
                                    <Select
                                        className='form-control'
                                        defaultValue={' '}
                                        {...register('billingPeriod', { required: true })}
                                        sx={{ borderRadius: '12px', padding: 0 }}>
                                        <MenuItem disabled value={' '}>
                                            {'Select a billing period...'}
                                        </MenuItem>
                                        {billingPeriodList.map((bp) => {
                                            return (
                                                <MenuItem key={bp.value} value={bp.value}>
                                                    {bp.title}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label'>Status</label>
                                    <Select
                                        className='form-control'
                                        defaultValue={' '}
                                        {...register('status', {
                                            required: true,
                                        })}
                                        sx={{ borderRadius: '12px', padding: 0 }}>
                                        <MenuItem disabled value={' '}>
                                            {'Select a status for membership agreement...'}
                                        </MenuItem>
                                        {statusList.map((status) => {
                                            return (
                                                <MenuItem key={status.value} value={status.value}>
                                                    {status.title}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormGroup>
                                <FormGroup>
                                    <Row>
                                        <Col md='12' lg='6'>
                                            <label className='form-control-label'>Items</label>
                                        </Col>
                                        <Col md='12' lg='6' className='text-right'>
                                            <span
                                                className=' btn btn-round btn-dark btn-sm'
                                                color='default'
                                                onClick={() => {
                                                    setIsOpen(true);
                                                    setSelectedItem(undefined);
                                                }}
                                                id='tooltip969372949'>
                                                <span className='btn-inner--text'> Add item</span>
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row className='mt-4'>
                                        <Col className='text-center'>{items.length === 0 && <p>No items added</p>}</Col>
                                    </Row>
                                    <Row>
                                        <Col>{items.length > 0 && <MembershipListItemHeader />}</Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {items?.map((item, index) => {
                                                return (
                                                    <MembershipListItem
                                                        packages={packages}
                                                        members={members}
                                                        onItemSelect={onItemSelect}
                                                        onItemRemove={onItemRemove}
                                                        item={item}
                                                        key={index}
                                                    />
                                                );
                                            })}
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row>
                                        <Col>
                                            <span
                                                className=' mt-4 btn btn-round btn-dark btn-sm'
                                                color='default'
                                                onClick={openFileExplorer}
                                                id='tooltip969372949'>
                                                <span className='btn-inner--text'> Attach file</span>
                                            </span>
                                            <input
                                                onChange={handleFileAttach}
                                                style={{ display: 'none' }}
                                                multiple
                                                accept='.pdf, .docx, .xlsx'
                                                type='file'
                                                ref={fileInputRef}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='mt-4'>
                                        <Col>
                                            {selectedFile && <InsertDriveFileOutlinedIcon fontSize={'large'} />}
                                            <p>{selectedFile?.name?.substring(0, 6)}</p>
                                        </Col>
                                    </Row>
                                </FormGroup>

                                <div className='d-flex h-100'>
                                    <div className='align-self-end ml-auto'>
                                        <Button
                                            type='submit'
                                            className='btn btn-dark mt-4 btn-block'
                                            disabled={isLoading}>
                                            {isLoading && (
                                                <span>
                                                    <Spinner />
                                                </span>
                                            )}
                                            {!isLoading && <span>Add</span>}
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
}
