import { gql } from '@apollo/client';

export const GET_RESOURCES = gql`
    query GetResources {
        resource(where: { deleted_at: { _is_null: true } }) {
            id
            name
            status {
                value
                title
            }
            type {
                value
                title
            }
        }
    }
`;

export const GET_RESOURCE_ITEMS = gql`
    query GetResourceItems($resource_id: uuid!) {
        items: resource_item(where: { resource_id: { _eq: $resource_id }, deleted_at: { _is_null: true } }) {
            id
            name
            code
            resource {
                id
                name
            }
            location: location_space {
                id
                name
            }
            status {
                value
                title
            }
        }
    }
`;

export const GET_RESOURCE_ITEM = gql`
    query GetResourceItem($id: uuid!) {
        item: resource_item_by_pk(id: $id) {
            id
            name
            location: location_space {
                id
                name
            }
            resource {
                id
                name
                attributeForm: attribute_form {
                    id
                    description
                    attributes: resource_form_attributes(
                        where: { deleted_at: { _is_null: true } }
                        order_by: { order: asc }
                    ) {
                        id
                        required
                        type
                        title
                        order
                        options: resource_form_attribute_options(where: { deleted_at: { _is_null: true } }) {
                            id
                            value
                            order
                        }
                        placeholder
                        value: resource_attribute_values {
                            id
                            value
                        }
                    }
                }
            }
            status {
                value
                title
            }
            code
            values {
                id
                value
                attributeId: attribute_id
            }
        }
        spaceLocations: location_space(
            where: { _and: [{ type_enum: { _eq: room }, deleted_at: { _is_null: true } }] }
        ) {
            id
            name
        }
        resourceStatusList: resource_status_enum {
            value
            title
        }
    }
`;

export const ADD_RESOURCE_ITEM = gql`
    mutation AddResourceItem($item: resource_item_insert_input!) {
        item: insert_resource_item_one(
            object: $item
            on_conflict: { constraint: resource_item_pkey, update_columns: [name, location_space_id, status_enum] }
        ) {
            id
        }
    }
`;

export const REMOVE_RESOURCE_ITEM = gql`
    mutation RemoveResourceItem($id: uuid!) {
        resource: update_resource_item_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const ADD_RESOURCE = gql`
    mutation AddResource($resource: resource_insert_input!) {
        resource: insert_resource_one(
            object: $resource
            on_conflict: { constraint: resource_pkey, update_columns: [name] }
        ) {
            id
        }
    }
`;

export const REMOVE_RESOURCE = gql`
    mutation RemoveResource($id: uuid!) {
        resource: update_resource_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const GET_RESOURCE = gql`
    query GetResource($id: uuid!) {
        resource: resource_by_pk(id: $id) {
            id
            name
            attributeForm: attribute_form {
                id
                description
                attributes: resource_form_attributes(
                    where: { deleted_at: { _is_null: true } }
                    order_by: { order: asc }
                ) {
                    id
                    required
                    type
                    title
                    order
                    options: resource_form_attribute_options(where: { deleted_at: { _is_null: true } }) {
                        id
                        value
                        order
                    }
                    placeholder
                    value: resource_attribute_values {
                        id
                        value
                    }
                }
            }
        }
        spaceLocations: location_space(
            where: { _and: [{ type_enum: { _eq: room }, deleted_at: { _is_null: true } }] }
        ) {
            id
            name
        }
        resourceStatusList: resource_status_enum {
            value
            title
        }
    }
`;

export const UPDATE_ATTRIBUTE_VALUE = gql`
    mutation UpdateAttributeValues($values: [resource_attribute_value_insert_input!]!) {
        insert_resource_attribute_value(
            objects: $values
            on_conflict: { constraint: resource_attribute_value_pkey, update_columns: [value] }
        ) {
            affected_rows
        }
    }
`;

export const REMOVE_ATTRIBUTE = gql`
    mutation RemoveAttribute($id: uuid!) {
        attribute: update_resource_form_attribute_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const REMOVE_ATTRIBUTE_OPTION = gql`
    mutation RemoveAttributeOption($id: uuid!) {
        resource: update_resource_form_attribute_option_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;
