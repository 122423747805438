/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, FormControlLabel, LinearProgress, MenuItem, Select } from '@mui/material';
import React, { useRef, useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Row } from 'reactstrap';
import FeaturedImage from '../../../../components/Image component/ImageContainer';
import { useForm } from 'react-hook-form';
import { MeetingRoomItem, RoomStatus } from '../../../../models/meeting.room.model';
import {
    GET_MEETING_ROOM_STATUS,
    GET_MEETING_ROOM,
    UPDATE_MEETING_ROOM_ITEM,
    MEETING_ROOM_CONFIGURATIONS,
} from '../../../../graphql/meeting-rooms.graphql';
import { GET_LOCATIONS } from '../../../../graphql/locations.graphql';
import { useQuery, useMutation } from '@apollo/client';
import useToast from '../../../../hooks/useToast';
import { Location } from '../../../../models/location.model';
import { uploadImage } from '../../../../hooks/upload-image.hook';
import Spinner from '../../../../components/Spinner/Spinner';
import MeetingRoomItemImageView from './MeetingRoomItemImageView';
import MeetingRoomItemConfigView from './MeetingRoomItemConfigView';
import { useParams } from 'react-router-dom';
import { GET_MEETING_ROOM_ITEM } from '../../../../graphql/meeting-rooms.graphql';
import MeetingRoomItemRulesView from './MeetingRoomItemRules';
import MeetingRoomItemChecklistView from './meeting room item/MeetingRoomItemChecklistView';

type FormData = {
    name: string;
    status: string;
    location: string;
    useInAnalytics: boolean;
};

interface MeetingRoomItemProps {
    roomItem: MeetingRoomItem;
    backToItemView(): void;
}

const MeetingRoomItemsManageForm: React.FC<MeetingRoomItemProps> = ({ roomItem, backToItemView }) => {
    const fileRef: any = useRef(null);
    const { showToast, Notification } = useToast();
    const [displayImageView] = useState<boolean>(false);
    const [image, setImage] = useState<string>(`${process.env.REACT_APP_IMAGE_URL}/${roomItem.featuredImage}`);
    const [imageFile, setImageFile] = useState<any>(null);
    const params: any = useParams();
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [updateRoomItem, { error: itemUpdateError, data: updatedItem }] = useMutation(UPDATE_MEETING_ROOM_ITEM, {
        refetchQueries: [{ query: GET_MEETING_ROOM }, 'GetMeetingRoom'],
    });
    const {
        loading: loadingStatusList,
        error: statusLoadError,
        data: loadedStatus,
    } = useQuery(GET_MEETING_ROOM_STATUS);

    const { data } = useQuery(GET_MEETING_ROOM, {
        variables: {
            id: params.id,
        },
    });

    const { data: configurations } = useQuery(MEETING_ROOM_CONFIGURATIONS);

    const { data: itemData } = useQuery(GET_MEETING_ROOM_ITEM, {
        variables: { id: roomItem.id },
    });

    const { loading: loadingLocations, error: locationLoadError, data: loadedLocations } = useQuery(GET_LOCATIONS);
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues: {
            useInAnalytics: roomItem.useInAnalytics,
        },
    });

    useEffect(() => {
        if (statusLoadError || locationLoadError) {
            showToast('An error has occurred, please refresh the page', 'danger');
            setIsUpdating(false);
        }

        if (itemUpdateError) {
            showToast('An error occurred while trying to update this room item', 'danger');
            setIsUpdating(false);
        }

        if (updatedItem) {
            showToast('Successfully updated room item', 'success');
            setIsUpdating(false);
        }

        if (itemData) {
            // console.log("Give me the item data :", itemData)
        }
    }, [statusLoadError, locationLoadError, updatedItem, itemUpdateError, configurations, itemData]);

    const onSubmit = async (formData: FormData) => {
        let imageKey;
        setIsUpdating(true);
        if (imageFile) imageKey = await uploadImage(imageFile, 'meeting_rooms_images');
        if (!imageFile && roomItem.featuredImage) imageKey = roomItem.featuredImage;
        if (imageKey === 'error') {
            showToast('An error has occurred while trying to upload the image', 'danger');
            setIsUpdating(false);
            return;
        }

        const _item = {
            name: formData.name,
            status_enum: formData.status,
            location_id: formData.location,
            featured_image: imageKey,
            use_in_analytics: formData.useInAnalytics,
        };

        updateRoomItem({
            variables: {
                id: roomItem.id,
                item: _item,
            },
        });
    };

    const onImageChange = (e: any) => {
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setImageFile(e.target.files[0]);
        setImage(imageUrl);
    };

    const statusList: RoomStatus[] = loadedStatus?.roomStatus;
    const locations: Location[] = loadedLocations?.location;

    // console.log("room addons", roomItem?.checkListAddOns || []);

    if (loadingStatusList || loadingLocations) {
        return <LinearProgress />;
    }

    return (
        <>
            {Notification}
            <Row className='mt-4'>
                <Col md='12' lg='6'>
                    <Card>
                        <CardHeader>
                            <Row noGutters={true}>
                                <button
                                    className='btn btn-outline-primary btn-sm mr-4'
                                    style={{ height: '28px' }}
                                    onClick={backToItemView}>
                                    <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                                    <span className='btn-inner-text'>Back</span>
                                </button>
                                <h3 className='m-0 pr-4'>Manage room item</h3>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            {displayImageView ? (
                                <MeetingRoomItemImageView images={roomItem.images} roomItem={roomItem} />
                            ) : (
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='name'>
                                            Featured Image
                                        </label>
                                        <FeaturedImage imageSource={image} fileInputRef={fileRef} />
                                        <input
                                            onChange={(e) => onImageChange(e)}
                                            type='file'
                                            accept='image/*'
                                            ref={fileRef}
                                            style={{ display: 'none' }}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='name'>
                                            Name
                                        </label>
                                        <input
                                            className='form-control'
                                            {...register('name', {
                                                required: true,
                                                value: roomItem.name,
                                            })}
                                            id='company-name'
                                            placeholder="Enter meeting room's name..."
                                            type='text'
                                        />
                                        {errors.name && <span className='invalid'>*This field is required</span>}
                                    </FormGroup>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='contact'>
                                            Status
                                        </label>
                                        <Select
                                            id='package'
                                            className='form-control'
                                            sx={{ borderRadius: '12px', padding: '0' }}
                                            placeholder='Select a role'
                                            defaultValue={roomItem.status.value}
                                            inputProps={{
                                                ...register('status', {
                                                    required: true,
                                                    value: roomItem.status.value,
                                                }),
                                            }}>
                                            {statusList.map((status) => {
                                                return (
                                                    <MenuItem key={status.value} id={status.value} value={status.value}>
                                                        {status.title}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                        {errors.status && <span className='invalid'>*This field is required</span>}
                                    </FormGroup>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='package'>
                                            Locations
                                        </label>
                                        <Select
                                            id='package'
                                            className='form-control'
                                            sx={{ borderRadius: '12px', padding: '0' }}
                                            defaultValue={roomItem.location.id}
                                            inputProps={{
                                                placeholder: 'Select a location',
                                                ...register('location', {
                                                    required: true,
                                                    value: roomItem.location.id,
                                                }),
                                            }}>
                                            {locations.map((location) => {
                                                return (
                                                    <MenuItem key={location.id} id={location.id} value={location.id}>
                                                        {location.name}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                        {errors.location && <span className='invalid'>*This field is required</span>}
                                    </FormGroup>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    id='useInAnalytics'
                                                    checked={watch('useInAnalytics')}
                                                    inputProps={{
                                                        ...register('useInAnalytics', {
                                                            required: false,
                                                        }),
                                                    }}
                                                />
                                            }
                                            label='Use in analytics'
                                        />
                                    </FormGroup>
                                    <div className='d-flex h-100'>
                                        <div className='align-self-end ml-auto'>
                                            <Button
                                                type='submit'
                                                className='btn btn-dark mt-4 btn-block'
                                                disabled={isUpdating}>
                                                {isUpdating ? (
                                                    <span>
                                                        <Spinner />
                                                    </span>
                                                ) : (
                                                    <span>Update</span>
                                                )}
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </CardBody>
                    </Card>
                    <MeetingRoomItemChecklistView
                        checklistAddOns={roomItem?.checklistAddOns || []}
                        itemId={roomItem.id}
                    />
                </Col>
                <Col md='12' lg='6'>
                    <Card>
                        <CardBody>
                            <MeetingRoomItemImageView images={roomItem.images} roomItem={roomItem} />
                        </CardBody>
                    </Card>
                    <MeetingRoomItemRulesView roomItem={roomItem} />
                    {data.room.isConfigurable && (
                        <MeetingRoomItemConfigView
                            roomItemConfigurations={roomItem?.itemConfigurations}
                            itemId={roomItem.id}
                        />
                    )}
                </Col>
            </Row>
        </>
    );
};

export default MeetingRoomItemsManageForm;
