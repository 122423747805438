/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Button, CardBody, FormGroup, Spinner } from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { MenuItem, Select } from "@mui/material";
import FeaturedImage from "../../../components/Image component/ImageContainer";
import { useForm } from "react-hook-form";
// import { Type } from "../../../models/ClientAccountDocument.model";
import {
  ADD_MENU_PACKAGE,
  // GET_MENU_DETAILS,
  GET_PACKAGE_TYPES,
} from "../../../graphql/menu-packages.graphql";
import { useMutation, useQuery } from "@apollo/client";
import { uploadImage } from "../../../hooks/upload-image.hook";
import useToast from "../../../hooks/useToast";
import { useParams } from "react-router-dom";
import { MenuPackage } from "../../../models/vendor.model";

type FormData = {
  name: string;
  description: string;
  price: number;
  menuCategory: string;
  menuMealType: string;
  menuStatus: string;
  menuType: string;
};

interface PackageManageViewProps {
  selectedPackage: MenuPackage | null;
}

export default function EditPackageForm({
  selectedPackage,
}: PackageManageViewProps) {
  const [imageFile, setImageFile] = useState<any>();
  const param: any = useParams();
  const { showToast, Notification } = useToast();
  const [image, setImage] = useState<string>(
    `${process.env.REACT_APP_IMAGE_URL}/${selectedPackage?.featuredImage}`
  );

  const fileInputRef: any = useRef(null);
  const [categories, setCategories] = useState<any[]>();
  const [mealTypes, setMealTypes] = useState<any[]>();
  const [status, setStatus] = useState<any[]>();
  const [menuTypes, setMenuTypes] = useState<any[]>();
  const [isType, setIsType] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    trigger,
    formState: { errors },
  } = useForm<FormData>();

  const [
    addMenuPackage,
    { called: isCalled, loading: isAdding, error: isAddingError },
  ] = useMutation(ADD_MENU_PACKAGE, {
    // refetchQueries: [{ query: GET_MENU_DETAILS }, "getMenuDetails"],
    refetchQueries: ["getMenuDetails"],
  });

  const { data } = useQuery(GET_PACKAGE_TYPES);

  useEffect(() => {
    if (selectedPackage?.menuType.value === "meal") {
      setIsType(true);
    }
  }, []);

  useEffect(() => {
    if (!isAdding && isCalled && !isAddingError) {
      showToast("Package updated successfully", "success");
      reset();
      setIsLoading(false);
    }

    if (isAddingError) {
      showToast("An error has occurreds, please try again", "danger");
    }
  }, [isAdding, isCalled, isAddingError]);

  useEffect(() => {
    register("description", {
      required: true,
      value: selectedPackage?.description,
    });
  }, []);

  useEffect(() => {
    register("description", { required: true });
  });

  useEffect(() => {
    if (data) {
      setCategories(data.menuCategory);
      setMealTypes(data.menuMealType);
      setStatus(data.menuStatus);
      setMenuTypes(data.menuType);
    }
  }, [data]);

  const onSubmit = async (data: FormData) => {
    const isMealType = data.menuType === "drinks" ? null : data.menuMealType;

    let imageKey;

    setIsLoading(true);

    if (imageFile) {
      imageKey = await uploadImage(imageFile, "product_images");
    }

    if (!imageKey && selectedPackage?.featuredImage) {
      imageKey = selectedPackage?.featuredImage;
    }

    if (imageKey === "error") {
      showToast(
        "An error has occurred while trying to upload the image",
        "danger"
      );
      setIsLoading(false);
      return;
    }

    setIsLoading(false);

    const packageData = {
      id: selectedPackage?.id,
      vendor_id: param.id,
      name: data.name,
      description: data.description,
      price: data.price,
      meal_type_enum: isMealType,
      category_enum: data.menuCategory,
      type_enum: data.menuType,
      status_enum: data.menuStatus,
      featured_image: imageKey,
      deleted_at: null,
    };

    addMenuPackage({
      variables: { package: packageData },
    });
  };

  const onImageChange = (e: any) => {
    const imageUrl = URL.createObjectURL(e.target.files[0]);
    setImageFile(e.target.files[0]);
    setImage(imageUrl);
  };

  const onTypeChange = (e: any) => {
    e.preventDefault();
    e.target.value.includes("drinks") ? setIsType(false) : setIsType(true);
  };

  return (
    <>
      {Notification}

      <CardBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <label className="form-control-label">Featured Image</label>
            <FeaturedImage fileInputRef={fileInputRef} imageSource={image} />
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              onChange={(e) => {
                onImageChange(e);
              }}
              style={{ display: "none" }}
            />
          </FormGroup>
          <FormGroup>
            <label className="form-control-label" htmlFor="name">
              Name
            </label>
            <input
              className="form-control"
              {...register("name", { required: true })}
              defaultValue={selectedPackage?.name}
              id="name"
              placeholder="Enter a package name..."
              type="text"
            />
            {errors.name && (
              <span className="invalid">*This field is required</span>
            )}
          </FormGroup>
          <FormGroup>
            <label className="form-control-label" htmlFor="description">
              Description
            </label>
            <CKEditor
              id="description"
              data={selectedPackage?.description}
              editor={ClassicEditor}
              config={{
                removePlugins: ["Heading"],
                toolbar: [
                  "bold",
                  "italic",
                  "bulletedList",
                  "numberedList",
                  "blockQuote",
                  "link",
                ],
              }}
              placeholder="Enter package description"
              onChange={(event: any, editor: any) => {
                setValue("description", editor.getData());
                trigger("description");
              }}
              onReady={(editor: any) => {}}
            />
            {errors.description && (
              <span className="invalid">*This field is required</span>
            )}
          </FormGroup>
          <FormGroup>
            <label className="form-control-label" htmlFor="price">
              Price
            </label>
            <input
              {...register("price", {
                required: true,
                value: selectedPackage?.price,
              })}
              className="form-control"
              id="price"
              step={0.01}
              placeholder="Enter package price..."
              defaultValue={selectedPackage?.price}
              type="number"
            />
            {errors?.price && (
              <span className="invalid">*This field is required</span>
            )}
          </FormGroup>
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <FormGroup>
                <label className="form-control-label" htmlFor="menuType">
                  Type
                </label>
                <Select
                  id="menuType"
                  sx={{ borderRadius: "12px", padding: "0" }}
                  className="form-control"
                  defaultValue={selectedPackage?.menuType.value}
                  onChange={(e) => onTypeChange(e)}
                  inputProps={{
                    ...register("menuType", {
                      required: true,
                      value: selectedPackage?.menuType.value,
                    }),
                  }}
                >
                  <MenuItem disabled value={selectedPackage?.menuType.value}>
                    Select type...
                  </MenuItem>
                  {menuTypes?.map((type: any) => {
                    return (
                      <MenuItem key={type.value} value={type.value}>
                        {type.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormGroup>
            </div>
            <div className="col-lg-6 col-md-12">
              {isType && (
                <FormGroup>
                  <label className="form-control-label" htmlFor="menuMealType">
                    Meal Type
                  </label>
                  <Select
                    id="menuMealType"
                    sx={{ borderRadius: "12px", padding: "0" }}
                    className="form-control"
                    defaultValue={
                      selectedPackage?.menuMealType.value
                        ? selectedPackage?.menuMealType.value
                        : " "
                    }
                    inputProps={{
                      ...register("menuMealType", {
                        required: false,
                        value: selectedPackage?.menuMealType?.value
                          ? selectedPackage?.menuMealType.value
                          : " ",
                      }),
                    }}
                  >
                    <MenuItem disabled value={" "}>
                      Select a meal type...
                    </MenuItem>
                    {mealTypes?.map((mealType) => {
                      return (
                        <MenuItem key={mealType.value} value={mealType.value}>
                          {mealType.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormGroup>
              )}
            </div>
            <div className="col-lg-6 col-md-12">
              <FormGroup>
                <label className="form-control-label" htmlFor="menuCategory">
                  Category
                </label>
                <Select
                  id="menuCategory"
                  sx={{ borderRadius: "12px", padding: "0" }}
                  className="form-control"
                  defaultValue={selectedPackage?.menuCategory.value}
                  inputProps={{
                    ...register("menuCategory", {
                      required: true,
                      value: selectedPackage?.menuCategory.value,
                    }),
                  }}
                >
                  <MenuItem
                    disabled
                    value={selectedPackage?.menuCategory.value}
                  >
                    Select a category..
                  </MenuItem>
                  {categories?.map((category) => {
                    return (
                      <MenuItem key={category.value} value={category.value}>
                        {category.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormGroup>
            </div>

            <div className="col-lg-6 col-md-12">
              <FormGroup>
                <label className="form-control-label" htmlFor="menuStatus">
                  Status
                </label>
                <Select
                  id="menuStatus"
                  sx={{ borderRadius: "12px", padding: "0" }}
                  className="form-control"
                  defaultValue={selectedPackage?.menuStatus.value}
                  inputProps={{
                    ...register("menuStatus", {
                      required: true,
                      value: selectedPackage?.menuStatus.value,
                    }),
                  }}
                >
                  <MenuItem disabled value={selectedPackage?.menuStatus.value}>
                    Select status...
                  </MenuItem>
                  {status?.map((s) => {
                    return (
                      <MenuItem key={s.value} value={s.value}>
                        {s.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormGroup>
            </div>
          </div>
          <div className="d-flex h-100">
            <div className="align-self-end ml-auto">
              <Button
                type="submit"
                className="btn btn-dark mt-4 btn-block"
                disabled={isLoading || isAdding}
              >
                {isLoading || isAdding ? (
                  <Spinner />
                ) : (
                  <span>
                    <span>Update</span>
                  </span>
                )}
              </Button>
            </div>
          </div>
        </form>
      </CardBody>
    </>
  );
}
