/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import {
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table,
} from 'reactstrap';
import {
    GET_PACKAGE_BOOKINGS,
    GET_PAST_PACKAGE_BOOKINGS,
    GET_UPCOMING_PACKAGE_BOOKING,
} from '../../../graphql/package-booking.graphql';
import { PackageBooking } from '../../../models/package-bookings.model';
import { LinearProgress } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import { getPermission } from '../../../utils/user.utils';
import { User } from '../../../models/user.model';
import { useSelector } from 'react-redux';
import BasicMenu from '../../../components/Menu';
import { ProductType } from '../../../models/product.model';
import SweetAlert from 'react-bootstrap-sweetalert';
import { REMOVE_PACKAGE } from '../../../graphql/clients.graphql';
import useToast from '../../../hooks/useToast';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from '../../../hooks/local-storage.hook';

interface PackageBookingData {
    keyWord?: string;
    page: number;
    pageSize: number;
    periodFilter: string;
    packageTypeFilter: string;
}

const PackageBookingListView = () => {
    const { loading, data } = useQuery(GET_PACKAGE_BOOKINGS, {
        // fetchPolicy: "network-only",
    });
    const [loadUpcoming, { data: upcoming, loading: loadingUpcoming }] = useLazyQuery(GET_UPCOMING_PACKAGE_BOOKING, {
        fetchPolicy: 'network-only',
    });
    const [loadPast, { data: past, loading: loadingPast }] = useLazyQuery(GET_PAST_PACKAGE_BOOKINGS, {
        fetchPolicy: 'network-only',
    });

    const [filterKey, setFilterKey] = useState<string>('');
    const [sortDirection, setSortDirection] = useState<string>('desc');
    const [sortFiled, setSortField] = useState<string>('from');
    const user: User | null = useSelector((state: any) => state.user.userInfo);
    const [page, setPage] = useState<number>(0);
    const [rowPerPage, setRowsPerPage] = useState<number>(10);
    const [typeFilter, setTypeFilter] = useState<string>('All');
    const [periodFilter, setPeriodFilter] = useState<string>('all');

    const [getLocalStorage, setLocalStorage] = useLocalStorage('package-booking');
    const getRowValue = (value: string) => {
        setRowsPerPage(parseInt(value));
    };
    const [alert, setAlert] = useState<any>(null);
    const { showToast, Notification } = useToast();

    const [deletePackageMutation, { error: errorDeleting, data: deleted }] = useMutation(REMOVE_PACKAGE, {
        // refetchQueries: [{ query: GET_PACKAGE_BOOKINGS }, 'GetPackageBookings'],
        refetchQueries: ['GetPackageBookings'],
    });

    const navigate = useNavigate();

    useEffect(() => {
        const routeData: PackageBookingData = getLocalStorage();

        console.log('Package route data', routeData);

        if (routeData) {
            setFilterKey(routeData?.keyWord || '');
            setPage(routeData?.page || 0);
            setRowsPerPage(routeData?.pageSize || 10);
            setPeriodFilter(routeData?.periodFilter || 'all');
            setTypeFilter(routeData?.packageTypeFilter || 'All');
        }
    }, []);

    useEffect(() => {
        if (deleted && !errorDeleting) {
            showToast('Successfully removed package booking.', 'success');
        }

        if (errorDeleting && !deleted) {
            showToast('An error has occurred while trying to remove the package booking.', 'danger');
        }
    }, [errorDeleting, deleted, showToast]);

    useEffect(() => {
        if (periodFilter === 'upcoming') {
            const newRoutData: PackageBookingData = getLocalStorage() || {};
            newRoutData.periodFilter = 'upcoming';
            setLocalStorage(newRoutData);
            loadUpcoming();
            resetPage();
        }

        if (periodFilter === 'past') {
            const newRoutData: PackageBookingData = getLocalStorage() || {};
            newRoutData.periodFilter = 'past';
            setLocalStorage(newRoutData);
            loadPast();
            resetPage();
        }
    }, [periodFilter]);

    const nextPage = () => {
        setPage(page + 1);
        const newRoutData: PackageBookingData = getLocalStorage() || {};
        newRoutData.page = page + 1;
        setLocalStorage(newRoutData);
    };

    const resetPage = () => {
        setPage(0);
        const newRoutData: PackageBookingData = getLocalStorage() || {};
        newRoutData.page = 0;
        setLocalStorage(newRoutData);
    };

    const previousPage = () => {
        setPage(page - 1);
        const newRoutData: PackageBookingData = getLocalStorage() || {};
        newRoutData.page = page - 1;
        setLocalStorage(newRoutData);
    };

    const getType = (typeValue: string) => {
        setTypeFilter(typeValue);
        setFilterKey('');
        setPage(0);
        const newRoutData: PackageBookingData = getLocalStorage() || {};
        newRoutData.keyWord = '';
        newRoutData.page = 0;
        newRoutData.packageTypeFilter = typeValue;
        setLocalStorage(newRoutData);
    };

    const deletedPackage = (_id: string) => {
        deletePackageMutation({
            variables: {
                id: _id,
            },
        });
    };

    const displayAlert = (props: any) => {
        setAlert(
            <SweetAlert
                {...props}
                showCancel
                confirmBtnText='Continue'
                confirmBtnBsStyle='danger'
                title='Are you sure?'
                onConfirm={() => {
                    deletedPackage(props._package.id);
                    setAlert(null);
                }}
                onCancel={() => {
                    setAlert(null);
                }}
                focusCancelBtn>
                This package will be removed from the system.
            </SweetAlert>,
        );
    };

    const sortBy: any = (packages: PackageBooking[], field: string, direction: string) => {
        const orderedBookings = new Map([
            ['name', _.orderBy(packages, (p) => p.productPackage.name, [direction === 'asc' ? 'asc' : 'desc'])],
            ['from', _.orderBy(packages, (p) => moment(p.from), [direction === 'asc' ? 'asc' : 'desc'])],
            ['to', _.orderBy(packages, (p) => moment(p.to), [direction === 'asc' ? 'asc' : 'desc'])],
            [
                'member',
                _.orderBy(packages, (p) => p.member?.user?.name || p.member?.user?.surname, [
                    direction === 'asc' ? 'asc' : 'desc',
                ]),
            ],
            [
                'client',
                _.orderBy(packages, (p) => p.client?.companyName || p.client?.individualName, [
                    direction === 'asc' ? 'asc' : 'desc',
                ]),
            ],
        ]);
        return orderedBookings.get(field);
    };
    if (loading || loadingUpcoming || loadingPast) {
        return <LinearProgress />;
    }

    let packageBookings: PackageBooking[] = data?.packageBooking;

    if (periodFilter === 'all') {
        packageBookings = data?.packageBooking;
    } else if (periodFilter === 'upcoming') {
        packageBookings = upcoming?.packageBooking;
    } else {
        packageBookings = past?.packageBooking;
    }

    let filteredPackages = packageBookings?.filter((packageBooking) => {
        const filtered =
            packageBooking.productPackage.name.toLowerCase().includes(filterKey?.toLocaleLowerCase()) ||
            packageBooking?.from.toLowerCase().includes(filterKey?.toLocaleLowerCase()) ||
            packageBooking?.to.toLowerCase().includes(filterKey?.toLocaleLowerCase()) ||
            packageBooking?.member?.user?.name.toLowerCase().includes(filterKey?.toLocaleLowerCase()) ||
            packageBooking?.member?.user?.surname.toLowerCase().includes(filterKey?.toLocaleLowerCase());

        if (filterKey === '' && typeFilter === 'All') {
            return packageBooking;
        }

        if (typeFilter === 'All') {
            return packageBooking && filtered;
        }

        if (typeFilter !== 'All') {
            return packageBooking?.productPackage?.product?.type?.title === typeFilter && filtered;
        }

        return filtered;
    });

    filteredPackages = sortBy(filteredPackages, sortFiled, sortDirection);
    const typeList: ProductType[] = data?.productTypes;

    return (
        <>
            {alert}
            {Notification}

            <Container className='mt-4' fluid>
                <Row>
                    <Col>
                        <h1>Package bookings</h1>
                    </Col>
                </Row>
                <Card className='mt-4'>
                    <Row>
                        <div className='col'>
                            <CardHeader className='border-0'>
                                <Row>
                                    <Col xs='6'>
                                        <h3 className='mb-0'>Package booking</h3>
                                    </Col>
                                    <Col xs='6' className='text-right'>
                                        <div>
                                            <button
                                                className={
                                                    periodFilter === 'all'
                                                        ? 'btn btn-round btn-sm btn-dark'
                                                        : ' btn btn-sm btn-outline-primary'
                                                }
                                                color='default'
                                                onClick={() => setPeriodFilter('all')}
                                                id='tooltip969372949'>
                                                <span className='btn-inner--text'>All</span>
                                            </button>
                                            <button
                                                className={
                                                    periodFilter === 'past'
                                                        ? 'btn btn-round btn-sm btn-dark'
                                                        : ' btn btn-sm btn-outline-primary'
                                                }
                                                onClick={() => setPeriodFilter('past')}
                                                color='default'
                                                id='tooltip969372949'>
                                                <span className='btn-inner--text'>Past</span>
                                            </button>
                                            <button
                                                className={
                                                    periodFilter === 'upcoming'
                                                        ? 'btn btn-round btn-sm btn-dark'
                                                        : ' btn btn-sm btn-outline-primary'
                                                }
                                                onClick={() => setPeriodFilter('upcoming')}
                                                color='default'
                                                id='tooltip969372949'>
                                                <span className='btn-inner--text'>Upcoming</span>
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='mt-4'>
                                    <Col xs='8'>
                                        <Input
                                            className='form-control w-50'
                                            type='text'
                                            placeholder='Search keyword...'
                                            value={filterKey}
                                            onChange={(e) => {
                                                setFilterKey(e.target.value);
                                                setPage(0);

                                                const newRoutData: PackageBookingData = getLocalStorage() || {};
                                                newRoutData.keyWord = e.target.value;
                                                newRoutData.page = 0;
                                                setLocalStorage(newRoutData);
                                            }}
                                        />
                                    </Col>
                                    <Col xs='4'>
                                        <div className='row'>
                                            <div className='col-6 d-flex'>
                                                <span className='min-text'>Type</span>
                                                <BasicMenu
                                                    getValue={getType}
                                                    options={['All', ...typeList.map((s) => s.title)]}
                                                    value={typeFilter}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Table className='align-items-center table-flush' responsive>
                                    <thead className='thead-light'>
                                        <tr>
                                            <th
                                                onClick={() => {
                                                    setSortField('name');
                                                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                                }}
                                                className='sort'
                                                data-sort='name'
                                                scope='col'>
                                                Package name
                                            </th>
                                            <th
                                                onClick={() => {
                                                    setSortField('client');
                                                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                                }}
                                                className='sort'
                                                data-sort='to'
                                                scope='col'>
                                                Client
                                            </th>
                                            <th
                                                onClick={() => {
                                                    setSortField('from');
                                                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                                }}
                                                className='sort'
                                                data-sort='from'
                                                scope='col'>
                                                From
                                            </th>
                                            <th
                                                onClick={() => {
                                                    setSortField('to');
                                                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                                }}
                                                className='sort'
                                                data-sort='to'
                                                scope='col'>
                                                To
                                            </th>

                                            <th
                                                onClick={() => {
                                                    setSortField('member');
                                                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                                }}
                                                className='sort'
                                                data-sort='member'
                                                scope='col'>
                                                Assigned member
                                            </th>
                                            <th scope='col'></th>
                                        </tr>
                                    </thead>
                                    <tbody className='list'>
                                        {filteredPackages
                                            ?.slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                                            ?.map((productPackage) => {
                                                return (
                                                    <tr
                                                        key={productPackage.id}
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            navigate(
                                                                `/admin/package-bookings/details/${productPackage.id}`,
                                                            );
                                                        }}>
                                                        <td>{productPackage.productPackage?.name}</td>
                                                        <td>{`${
                                                            productPackage.client?.companyName ||
                                                            productPackage.client?.individualName ||
                                                            ''
                                                        }`}</td>
                                                        <td>{moment(productPackage.from).format('yyyy/MM/DD')}</td>
                                                        <td>{moment(productPackage.to).format('yyyy/MM/DD')}</td>

                                                        <td>
                                                            {productPackage.member
                                                                ? `${productPackage.member.user.name} ${productPackage.member.user.surname}`
                                                                : 'Not set'}
                                                        </td>
                                                        <td className='text-right'>
                                                            {getPermission(user, 'client_packages', 'update') && (
                                                                <button
                                                                    className='btn btn-info btn-icon-only rounded-circle btn-sm'
                                                                    onClick={() => {
                                                                        navigate(
                                                                            `/admin/package-bookings/details/${productPackage.id}`,
                                                                        );
                                                                    }}>
                                                                    <i className='fa-solid fa-pencil'></i>
                                                                </button>
                                                            )}
                                                            {getPermission(user, 'client_packages', 'delete') && (
                                                                <button
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        displayAlert({
                                                                            warning: true,
                                                                            _package: productPackage,
                                                                        });
                                                                    }}
                                                                    className='btn btn-danger btn-icon-only rounded-circle btn-sm'>
                                                                    <i className='fa-solid fa-trash-can'></i>
                                                                </button>
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </Table>
                            </CardBody>
                            <CardFooter className='py-4'>
                                <Row>
                                    <div className='col-4 d-flex'>
                                        <span className='min-text'>Rows per page:</span>
                                        <BasicMenu
                                            getValue={getRowValue}
                                            options={['10', '20', '100']}
                                            value={rowPerPage.toString()}
                                        />
                                    </div>
                                    <div className='col-4 d-flex'>
                                        <span className='min-text'>Page:</span>
                                        <span className='min-text pl-2'>
                                            {page + 1} of {Math.ceil(filteredPackages.length / rowPerPage)}
                                        </span>
                                    </div>
                                    <div className='col-4 d-flex'>
                                        <Pagination>
                                            <PaginationItem className={page <= 0 ? 'disabled' : ''}>
                                                <PaginationLink onClick={(e) => previousPage()}>
                                                    <i className='fas fa-angle-left' />
                                                    <span className='sr-only'>Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem
                                                className={
                                                    page >= Math.ceil(filteredPackages.length / rowPerPage) - 1
                                                        ? 'disabled'
                                                        : ''
                                                }>
                                                <PaginationLink onClick={(e) => nextPage()}>
                                                    <i className='fas fa-angle-right' />
                                                    <span className='sr-only'>Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                    </div>
                                </Row>
                            </CardFooter>
                        </div>
                    </Row>
                </Card>
            </Container>
        </>
    );
};

export default PackageBookingListView;

//btn btn-sm btn-outline-primary
//btn btn-round btn-sm btn-dark
