import { LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import Spinner from '../../../components/Spinner/Spinner';
import { useParams } from 'react-router-dom';
import ResourceFormAttribute from './components/ResourceFormAttribute';
import useToast from '../../../hooks/useToast';
import { Resource, ResourceAttribute, ResourceFormAttributeOption } from '../../../models/resource.model';
import { v4 } from 'uuid';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_RESOURCE, GET_RESOURCE } from '../../../graphql/resource.graphql';
import { useForm } from 'react-hook-form';

type ResourceFormData = {
    title: string;
    description: string;
};

export default function ResourceEditFormView() {
    const { showToast, Notification } = useToast();
    const [formAttributes, setFormAttributes] = useState<ResourceAttribute[]>([]);
    const params: any = useParams();

    const {
        data: resourceData,
        loading: loadingResource,
        error: loadingError,
    } = useQuery(GET_RESOURCE, {
        variables: {
            id: params?.id,
        },
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ResourceFormData>();

    const [draggedElementIndex, setDraggedElementIndex] = useState<number>(-1);

    const onDragStart = (event: Event, index) => {
        setDraggedElementIndex(index);
    };
    const onDragOver = (event: Event) => {
        event.preventDefault();
    };
    const onDrop = (event: Event, index: number) => {
        if (draggedElementIndex === index) return;
        const attributes = [...formAttributes];
        const draggedAttribute: ResourceAttribute = attributes[draggedElementIndex];
        const draggedToAttribute: ResourceAttribute = attributes[index];
        attributes[index] = draggedAttribute;
        attributes[draggedElementIndex] = draggedToAttribute;

        console.log('Attributes', attributes);
        for (var i = 0; i < attributes.length; i++) {
            attributes[i].order = i + 1;
        }
        setFormAttributes([...attributes]);
    };

    const [updateResource, { loading, error, data }] = useMutation(ADD_RESOURCE, {
        refetchQueries: [GET_RESOURCE],
    });

    useEffect(() => {
        if (loadingError) {
            showToast('An error has occurred while trying to load the page', 'danger');
        }
    }, [loadingError]);

    useEffect(() => {
        if (error) {
            showToast('An error has occurred while trying to update the resource', 'danger');
        }
    }, [error]);

    const onAttributeRemove = (attribute: ResourceAttribute) => {
        const newAttributes = [...formAttributes];

        setFormAttributes([...newAttributes.filter((att) => att.key !== attribute.key)]);
    };

    useEffect(() => {
        if (data) {
            showToast('Successfully updated resources', 'success');
        }
    }, [data]);

    useEffect(() => {
        if (resourceData) {
            const attributes = resourceData?.resource?.attributeForm?.attributes.map((att) => {
                return {
                    id: att.id,
                    title: att.title,
                    placeholder: '',
                    isOpen: false,
                    value: att.value[0],
                    order: att.order,
                    required: att.required,
                    type: att.type,
                    key: att.id,
                    options: att.options.map((option) => {
                        return {
                            id: option.id,
                            value: option.value,
                            order: option.order,
                        };
                    }),
                };
            });

            console.log('Resources', resourceData);

            setFormAttributes([...attributes]);
        }
    }, [resourceData]);

    useEffect(() => {
        console.log('Form Attributes', formAttributes);
    }, [formAttributes]);

    const handleAttributeCreating = () => {
        const attribute: ResourceAttribute = {
            id: undefined,
            title: '',
            placeholder: '',
            value: { id: undefined, value: '' },
            required: false,
            type: 'text',
            order: formAttributes.length + 1,
            isOpen: false,
            key: v4(),
            options: [],
        };

        const attributes = [...formAttributes, attribute];
        setFormAttributes(attributes);
    };

    const handleAttributeUpdate = (attributes: ResourceAttribute[]) => {
        setFormAttributes(attributes);
    };

    const onSubmit = (formData: ResourceFormData) => {
        console.log('Form Values', formAttributes);

        const resource = {
            id: resourceData?.resource?.id,
            status_enum: 'active',
            type_enum: 'asset',
            name: formData.title,
            attribute_form: {
                on_conflict: {
                    constraint: 'resource_attribute_form_pkey',
                    update_columns: ['title', 'description'],
                },
                data: {
                    id: resourceData?.resource?.attributeForm?.id,
                    title: formData.title,
                    description: formData.description,
                    resource_form_attributes: {
                        on_conflict: {
                            constraint: 'resource_form_attribute_pkey',
                            update_columns: ['title', 'type', 'required', 'order'],
                        },
                        data: formAttributes?.map((att, index) => {
                            const attribute = formAttributes[index];
                            return {
                                id: attribute?.id,
                                title: att.title ?? '',
                                type: att.type ?? '',
                                required: att.required,
                                order: att.order,
                                resource_form_attribute_options: {
                                    on_conflict: {
                                        constraint: 'resource_form_attribute_option_pkey',
                                        update_columns: ['value', 'order'],
                                    },
                                    data: att.options.map((option: ResourceFormAttributeOption, j) => {
                                        return {
                                            id: attribute?.options[j]?.id,
                                            value: option.value,
                                            order: option.order,
                                        };
                                    }),
                                },
                            };
                        }),
                    },
                },
            },
        };

        updateResource({
            variables: {
                resource,
            },
        });
    };

    if (loadingResource) {
        return <LinearProgress />;
    }

    const resource: Resource = resourceData?.resource;

    // console.log('Resource name', resource.name);

    return (
        <>
            {Notification}
            <Row className='mt-4'>
                <Col md='12' lg='6'>
                    <Card>
                        <CardHeader>
                            <Row noGutters={true}>
                                <Col xs='6'>
                                    <h3 className='m-0'>Edit Resource</h3>
                                </Col>
                                <Col xs='6' className='text-right'>
                                    <button
                                        className='btn btn-round btn-sm btn-dark mr-4'
                                        onClick={handleAttributeCreating}>
                                        <span className='btn-inner-text'>Add attributes</span>
                                    </button>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className='row mb-4'>
                                    <div className='col-lg-12'>
                                        <input
                                            placeholder='Enter resource type title'
                                            className='form-control'
                                            type='text'
                                            {...register('title', { required: true, value: resource.name ?? '' })}
                                        />
                                        {errors.title && <span className='invalid'>*This field is required</span>}
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <textarea
                                            placeholder='Form description'
                                            {...register('description', {
                                                required: true,
                                                value: resource.attributeForm.description ?? '',
                                            })}
                                            className='form-control'
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                        {errors.description && <span className='invalid'>*This field is required</span>}
                                    </div>
                                </div>
                                <div className='d-flex h-100'>
                                    <div className='align-self-end ml-auto'>
                                        <Button
                                            type='submit'
                                            className='btn btn-dark mt-4'
                                            disabled={loading || formAttributes?.length === 0}>
                                            {!loading && <span>Save</span>}
                                            {loading && <Spinner />}
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md='12' lg='6'>
                    {formAttributes?.map((attribute) => {
                        return (
                            <ResourceFormAttribute
                                key={attribute.key}
                                attribute={attribute}
                                onRemove={onAttributeRemove}
                                availableAttributes={formAttributes}
                                onAttributeChange={handleAttributeUpdate}
                                onDragStart={onDragStart}
                                onDragOver={onDragOver}
                                onDrop={onDrop}
                            />
                        );
                    })}
                </Col>
            </Row>
        </>
    );
}
