/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from '@apollo/client';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Button, Card, CardBody, FormGroup } from 'reactstrap';
import Spinner from '../../../../components/Spinner/Spinner';
import { INSERT_CLIENT_ADDRESS } from '../../../../graphql/clients.graphql';
import useToast from '../../../../hooks/useToast';
import { BillingAddress } from '../../../../models/client.model';
import { User } from '../../../../models/user.model';
import { getPermission } from '../../../../utils/user.utils';

type FormData = {
    building: string;
    street: string;
    suburb: string;
    city: string;
    province: string;
    country: string;
    postalCode: string;
};

interface ClientAddressCardProps {
    address: BillingAddress | undefined;
    accountId: string;
}

const ClientAddressCard = ({ address, accountId }: ClientAddressCardProps) => {
    const user: User | null = useSelector((state: any) => state.user.userInfo);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>();

    const { showToast, Notification } = useToast();

    const [updateClientDetailsMutation, { loading: isUpdating, error, called, reset }] =
        useMutation(INSERT_CLIENT_ADDRESS);

    useEffect(() => {
        if (!error && called && !isUpdating) {
            reset();
            showToast('Successfully updated address', 'success');
        }

        if (error && called) {
            showToast('An error has occurred, please try again', 'danger');
        }
    }, [called, error, isUpdating]);

    const onSubmit = (data: FormData) => {
        const newAddress = {
            id: address?.id,
            building: data.building,
            street: data.street,
            suburb: data.suburb,
            city: data.city,
            province: data.province,
            country: data.country,
            postal_code: data.postalCode,
            main_address: true,
            account_id: accountId,
        };
        updateClientDetailsMutation({ variables: { address: newAddress } });
    };

    return (
        <>
            {Notification}
            <Card>
                <CardBody>
                    <h3>Billing Address</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='row'>
                            <div className='col-lg-6 col-md-12'>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='name'>
                                        Building name (optional)
                                    </label>
                                    <input
                                        className='form-control'
                                        id='company-name'
                                        placeholder='Enter building name...'
                                        {...register('building', {
                                            required: false,
                                            value: address?.building,
                                        })}
                                        type='text'
                                    />
                                    {errors.building && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                            </div>
                            <div className='col-lg-6 col-md-12'>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='street'>
                                        Street address
                                    </label>
                                    <input
                                        className='form-control'
                                        id='street'
                                        placeholder='Enter street address...'
                                        {...register('street', {
                                            required: true,
                                            value: address?.street,
                                        })}
                                        type='text'
                                    />
                                    {errors.street && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                            </div>
                            <div className='col-lg-6 col-md-12'>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='suburb'>
                                        Suburb
                                    </label>
                                    <input
                                        className='form-control'
                                        id='suburb'
                                        placeholder='Enter suburb...'
                                        {...register('suburb', {
                                            required: true,
                                            value: address?.suburb,
                                        })}
                                        type='text'
                                    />
                                    {errors.suburb && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                            </div>
                            <div className='col-lg-6 col-md-12'>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='city'>
                                        City
                                    </label>
                                    <input
                                        className='form-control'
                                        id='city'
                                        placeholder='Enter city...'
                                        {...register('city', {
                                            required: true,
                                            value: address?.city,
                                        })}
                                        type='text'
                                    />
                                    {errors.city && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                            </div>
                            <div className='col-lg-6 col-md-12'>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='province'>
                                        Province
                                    </label>
                                    <input
                                        className='form-control'
                                        id='province'
                                        placeholder='Enter province...'
                                        {...register('province', {
                                            required: true,
                                            value: address?.province,
                                        })}
                                        type='text'
                                    />
                                    {errors.province && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                            </div>
                            <div className='col-lg-6 col-md-12'>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='country'>
                                        Country
                                    </label>
                                    <input
                                        className='form-control'
                                        id='country'
                                        placeholder='Enter country...'
                                        {...register('country', {
                                            required: true,
                                            value: address?.country,
                                        })}
                                        type='text'
                                    />
                                    {errors.country && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                            </div>
                            <div className='col-lg-6 col-md-12'>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='postal-code'>
                                        Postal Code
                                    </label>
                                    <input
                                        className='form-control'
                                        id='postal-code'
                                        placeholder='Enter postal code...'
                                        {...register('postalCode', {
                                            required: true,
                                            value: address?.postalCode,
                                        })}
                                        type='text'
                                    />
                                    {errors.postalCode && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                            </div>
                        </div>
                        {getPermission(user, 'clients', 'update') && (
                            <div className='d-flex h-100'>
                                <div className='align-self-end ml-auto'>
                                    <Button type='submit' className='btn btn-dark  mt-4' disabled={isUpdating}>
                                        {isUpdating ? (
                                            <span>
                                                <Spinner />
                                            </span>
                                        ) : (
                                            <span>Update</span>
                                        )}
                                    </Button>
                                </div>
                            </div>
                        )}
                    </form>
                </CardBody>
            </Card>
        </>
    );
};

export default ClientAddressCard;
