/* eslint-disable react-hooks/exhaustive-deps */
import { Badge, MenuItem, Select } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    FormGroup,
    Row,
    Spinner,
    UncontrolledTooltip,
} from 'reactstrap';
import useToast from '../../../hooks/useToast';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import {
    GET_EMAIL_STATUS,
    GET_NEWSLETTER_EMAIL,
    GET_NEWSLETTER_EMAILS,
    REMOVE_FILE_ATTACHMENT,
    UPDATE_NEWSLETTER_EMAIL,
} from '../../../graphql/newsletter-email.graphql';
import { EmailAttachment, EmailTemplate, Emails, MailList, Status } from '../../../models/newsletter-emil.model';
import { useNavigate } from 'react-router-dom';
import { Storage } from 'aws-amplify';
import { v4 } from 'uuid';

interface EmailEditFormProps {
    email: Emails;
}

type FormData = {
    title: string;
    date: string;
    subject: string;
    // status: string;
    template: string;
    mailList: string;
};

export default function EmailEditForm({ email }: EmailEditFormProps) {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { showToast, Notification } = useToast();
    const fileInputRef = useRef<any>();
    const { data: statusFound, error: errorStatus } = useQuery(GET_EMAIL_STATUS);
    const [removeFileAttachment] = useMutation(REMOVE_FILE_ATTACHMENT, {
        refetchQueries: [GET_NEWSLETTER_EMAIL],
    });
    const [selectedFiles, setFiles] = useState<File[]>([]);
    const [attachments, setAttachments] = useState<EmailAttachment[]>(email.attachments);
    const handleFileAttach = (e: any) => {
        setFiles([...selectedFiles, ...e.target.files]);
    };

    const openFileExplorer = () => {
        if (fileInputRef.current) fileInputRef?.current?.click();
    };

    const [updateNewsletterEmail, { data: dataEmail, error: errorEmail, loading: loadingEmailUpdate }] = useMutation(
        UPDATE_NEWSLETTER_EMAIL,
        {
            refetchQueries: [{ query: GET_NEWSLETTER_EMAILS }, 'getNewsletterEmails', GET_NEWSLETTER_EMAIL],
        },
    );
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues: {
            subject: email?.subject ?? '',
            template: email?.template.id ?? '',
            mailList: email?.mailList.id ?? '',
            // status: email?.status.value ?? '',
        },
    });

    useEffect(() => {
        if (errorStatus) {
            showToast('An error has occurred, please refresh the page', 'danger');
        }

        if (errorEmail) {
            showToast('An error has occurred while trying to update email information, please try again', 'danger');
            setIsLoading(false);
        }
    }, [errorStatus, errorEmail]);

    useEffect(() => {
        if (dataEmail && !loadingEmailUpdate) {
            showToast('Successfully updated email', 'success');
            setIsLoading(false);
            reset();
        }
    }, [dataEmail, loadingEmailUpdate]);

    const redirectToEmails = (e: any) => {
        e.preventDefault();
        navigate(-1);
    };

    const onFileRemove = (file: File) => {
        const newFiles = [...selectedFiles.filter((f) => selectedFiles.indexOf(f) !== selectedFiles.indexOf(file))];
        setFiles([...newFiles]);
    };

    const onRemoveExisting = (id: string) => {
        removeFileAttachment({
            variables: {
                id,
            },
        });

        setAttachments([...attachments.filter((att) => att.id !== id)]);
    };

    const onSubmit = async (data: FormData) => {
        setIsLoading(true);
        let keys: string[] = [];

        if (selectedFiles?.length > 0) {
            try {
                const uploadPromises = Array.from(selectedFiles).map(async (file) => {
                    const fileName: string = `attachments/${v4()}_${file.name}`;
                    await Storage.put(fileName, file, {
                        level: 'public',
                    });
                    return fileName;
                });

                keys = await Promise.all(uploadPromises);
            } catch (error) {
                console.log('Error uploading files: ', error);
                showToast('An error has occurred while trying to upload the selected files', 'danger');
                setIsLoading(false);
                return;
            }
        }

        const emailData = {
            subject: data.subject,
            template_id: data.template,
            mail_list_id: data.mailList,
            // status_enum: data.status,
        };

        const attachment = keys.map((key) => {
            return {
                email_id: email?.id,
                path: key,
            };
        });

        updateNewsletterEmail({
            variables: { id: email?.id, emailValues: emailData, attachment: attachment },
        });
    };

    const statusSelect: Status[] = statusFound?.status;
    const templates: EmailTemplate[] = statusFound?.templates;
    const mailLists: MailList[] = statusFound?.mailLists;

    return (
        <>
            {Notification}
            <Container className='mt-4' fluid>
                <Row>
                    <Col md='12' lg='6'>
                        <Card>
                            <CardHeader>
                                <Row noGutters={true}>
                                    <button
                                        className='btn btn-outline-primary btn-sm mr-4'
                                        onClick={(e) => redirectToEmails(e)}>
                                        <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                                        <span className='btn-inner-text'>Back</span>
                                    </button>
                                    <h3 className='m-0'>Update Email Information</h3>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-12'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='subject'>
                                                    Subject
                                                </label>
                                                <input
                                                    className='form-control'
                                                    {...register('subject', { required: true })}
                                                    id='subject'
                                                    placeholder='Enter post subject...'
                                                    type='text'
                                                />
                                                {errors.subject && (
                                                    <span className='invalid'>*This field is required</span>
                                                )}
                                            </FormGroup>
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='name'>
                                                    Template
                                                </label>
                                                <FormGroup>
                                                    <Select
                                                        id='status'
                                                        sx={{ borderRadius: '12px', padding: '0' }}
                                                        className='form-control'
                                                        defaultValue={email?.template.id ?? ' '}
                                                        inputProps={{
                                                            ...register('template', {
                                                                required: true,
                                                            }),
                                                        }}>
                                                        <MenuItem disabled value=' '>
                                                            Select a template...
                                                        </MenuItem>
                                                        {templates?.map((template: any) => {
                                                            return (
                                                                <MenuItem key={template?.id} value={template?.id}>
                                                                    {template?.name}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </FormGroup>
                                                {errors.template && (
                                                    <span className='invalid'>*This field is required</span>
                                                )}
                                            </FormGroup>
                                        </div>

                                        {/* <div className='col-lg-6 col-md-12'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='status'>
                                                    Status
                                                </label>
                                                <Select
                                                    id='status'
                                                    sx={{ borderRadius: '12px', padding: '0' }}
                                                    className='form-control'
                                                    defaultValue={email?.status?.value ?? ' '}
                                                    inputProps={{
                                                        ...register('status', {
                                                            required: true,
                                                        }),
                                                    }}>
                                                    <MenuItem disabled value=' '>
                                                        Select status...
                                                    </MenuItem>
                                                    {statusSelect?.map((status: any) => {
                                                        return (
                                                            <MenuItem key={status.value} value={status.value}>
                                                                {status.title}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormGroup>
                                        </div> */}
                                        <div className='col-lg-6 col-md-12'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='name'>
                                                    Mail List
                                                </label>
                                                <Select
                                                    id='status'
                                                    sx={{ borderRadius: '12px', padding: '0' }}
                                                    className='form-control'
                                                    defaultValue={email?.mailList.id ?? ' '}
                                                    inputProps={{
                                                        ...register('mailList', {
                                                            required: true,
                                                        }),
                                                    }}>
                                                    <MenuItem disabled value=' '>
                                                        Select a mail list...
                                                    </MenuItem>
                                                    {mailLists?.map((mailList: any) => {
                                                        return (
                                                            <MenuItem key={mailList?.id} value={mailList?.id}>
                                                                {mailList?.name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                                {errors.mailList && (
                                                    <span className='invalid'>*This field is required</span>
                                                )}
                                            </FormGroup>
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <label className='form-control-label' htmlFor='attach'>
                                                Attachments
                                            </label>
                                            <FormGroup>
                                                <span
                                                    className=' mt-2 btn btn-round btn-dark btn-sm'
                                                    color='default'
                                                    onClick={openFileExplorer}
                                                    id='tooltip969372949'>
                                                    <span className='btn-inner--text'> Attach file</span>
                                                </span>
                                                <input
                                                    onChange={handleFileAttach}
                                                    style={{ display: 'none' }}
                                                    multiple
                                                    accept='.pdf, .docx, .xlsx'
                                                    type='file'
                                                    ref={fileInputRef}
                                                />
                                            </FormGroup>
                                            {selectedFiles?.map((file) => {
                                                return (
                                                    <>
                                                        <Badge
                                                            style={{ cursor: 'pointer' }}
                                                            id={file.name}
                                                            badgeContent={'X'}
                                                            onClick={() => onFileRemove(file)}
                                                            key={file.name}
                                                            color='primary'>
                                                            <InsertDriveFileOutlinedIcon fontSize={'large'} />
                                                        </Badge>
                                                        <p>{file.name.substring(0, 5)}</p>
                                                    </>
                                                );
                                            })}
                                            {attachments.map((att) => {
                                                return (
                                                    <>
                                                        <Badge
                                                            style={{ cursor: 'pointer' }}
                                                            id={att.id}
                                                            badgeContent={'X'}
                                                            onClick={() => onRemoveExisting(att.id)}
                                                            key={att.id}
                                                            color='primary'>
                                                            <InsertDriveFileOutlinedIcon fontSize={'large'}>
                                                                <UncontrolledTooltip delay={0} target={att.id}>
                                                                    {att.path}
                                                                </UncontrolledTooltip>
                                                            </InsertDriveFileOutlinedIcon>
                                                        </Badge>
                                                        <p>
                                                            {att.path.substring(att.path.length - 8, att.path.length)}
                                                        </p>
                                                    </>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <div className='d-flex h-100'>
                                        <div className='align-self-end ml-auto'>
                                            <Button
                                                type='submit'
                                                className='btn btn-dark mt-4 btn-block'
                                                disabled={isLoading}>
                                                {!isLoading ? (
                                                    <span>Update</span>
                                                ) : (
                                                    <span>
                                                        <Spinner />
                                                    </span>
                                                )}
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
