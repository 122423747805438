import { gql } from "@apollo/client";

export const GET_SUBSCRIBERS = gql`
  query getSubscribers {
    subscribers: colab_subscriber(where: { deleted_at: { _is_null: true } }) {
      id
      subscriber: email
      status: colab_subscriber_status {
        value
        title
      }
    }
  }
`;

export const REMOVE_SUBSCRIBER = gql`
  mutation removeSubscriber($id: uuid!) {
    update_colab_subscriber_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
      id
    }
  }
`;

export const GET_STATUS_TYPE = gql`
  query getStatusType {
    status: colab_subscriber_status {
      value
      title
    }
  }
`;

export const UPDATE_SUBSCRIBER = gql`
  mutation updateSubscriber($id: uuid!, $status: colab_subscriber_status_enum!) {
    update_colab_subscriber_by_pk(pk_columns: { id: $id }, _set: { status_enum: $status }) {
      id
      status_enum
    }
  }
`;
