/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { Image } from "../../../models/product.model";
import { uploadImage } from "../../../hooks/upload-image.hook";
import useToast from "../../../hooks/useToast";
import { useMutation } from "@apollo/client";
import {
  ADD_MEETING_ROOM_IMAGE,
  ADD_MEETING_ROOM_ITEM_IMAGE,
  GET_MEETING_ROOM,
} from "../../../graphql/meeting-rooms.graphql";
import { useParams } from "react-router-dom";
import MeetingRoomImage from "./MeetingRoomImage";
import { CircularProgress } from "@mui/material";
import { MeetingRoomItem } from "../../../models/meeting.room.model";
import MeetingRoomItemImage from "./components/MeetingRoomItemImage";

interface ImageViewProps {
  images: Image[] | undefined;
  isMeetingRoomView: boolean;
  roomItem: MeetingRoomItem | undefined | null;
}

const MeetingRoomImageView = ({
  images,
  isMeetingRoomView,
  roomItem,
}: ImageViewProps) => {
  const inputFileRef = useRef<any>(null);
  const { showToast, Notification } = useToast();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [addImageMutation, { error, data }] = useMutation(
    ADD_MEETING_ROOM_IMAGE,
    {
      refetchQueries: [{ query: GET_MEETING_ROOM }, "GetMeetingRoom"],
    }
  );

  const [addItemImage, { error: itemImageError, data: itemImageCalled }] =
    useMutation(ADD_MEETING_ROOM_ITEM_IMAGE, {
      refetchQueries: [{ query: GET_MEETING_ROOM }, "GetMeetingRoom"],
    });

  const params: any = useParams();

  const changeImage = (e: any) => {
    console.log("CHANGE IMAGE", e);
    addImage(e.target.files[0]);
  };

  // console.log("Images in room data :", images);

  const addImage = async (imageFile: File) => {
    if (!imageFile) {
      showToast("Please select an image first", "danger");
      return;
    }

    setIsUpdating(true);
    const imageKey = await uploadImage(imageFile, "package_images");

    if (imageKey === "error") {
      showToast("An error has occurred while trying ", "danger");
      setIsUpdating(false);
      return;
    }

    const image = {
      meeting_room_id: params.id,
      path: imageKey,
    };

    const item_image = {
      meeting_room_item_id: roomItem?.id,
      path: imageKey,
    };

    if (roomItem && !isMeetingRoomView) {
      addItemImage({
        variables: {
          image: item_image,
        },
      });
      return;
    }

    addImageMutation({
      variables: {
        image: image,
      },
    });
  };

  useEffect(() => {
    if (error || itemImageError) {
      showToast(
        "An error has occurred while trying to upload an image",
        "danger"
      );
      setIsUpdating(false);
    }
  }, [error, itemImageError]);

  useEffect(() => {
    if (data && !error) {
      showToast("Successfully added an image in this room", "success");
      setIsUpdating(false);
    }

    if (itemImageCalled && !itemImageError) {
      showToast("Successfully added an image in this room", "success");
      setIsUpdating(false);
    }
  }, [data, itemImageCalled]);

  if (!isMeetingRoomView) {
    return (
      <>
        {Notification}
        <div>
          <CardHeader className="p-0">
            <Row>
              <Col className="mb-3" xs="6">
                <h3> Images</h3>
              </Col>
              <Col className="text-right" xs="6">
                <button
                  onClick={() => {
                    if (inputFileRef.current) inputFileRef.current.click();
                  }}
                  disabled={isUpdating}
                  className="btn btn-dark btn-sm"
                >
                  {isUpdating ? (
                    <CircularProgress color="primary" size={16} />
                  ) : (
                    <span>Add</span>
                  )}
                </button>
              </Col>
            </Row>
          </CardHeader>
          <Row>
            <input
              onChange={(e) => changeImage(e)}
              ref={inputFileRef}
              type="file"
              accept="image/*"
              style={{ display: "none" }}
            />
          </Row>
          <Row className="mt-4">
            <div className="mx-4">
              {(!images || images?.length === 0) && (
                <h3>This meeting room item has no images</h3>
              )}
              <Row>
                {images?.map((image) => {
                  return (
                    <div key={image.id} className="pr-4 mt-2">
                      <MeetingRoomItemImage
                        id={image.id}
                        sourceImage={`${process.env.REACT_APP_IMAGE_URL}/${image?.path}`}
                      />
                    </div>
                  );
                })}
              </Row>
            </div>
          </Row>
        </div>
      </>
    );
  }

  return (
    <>
      {Notification}
      <Card className="mt-4">
        <CardHeader>
          <Row>
            <Col xs="6">
              <h3 className="mb-0"> Images</h3>
            </Col>
            <Col className="text-right" xs="6">
              <button
                onClick={() => {
                  if (inputFileRef.current) inputFileRef.current.click();
                }}
                disabled={isUpdating}
                className="btn btn-dark btn-sm"
              >
                {isUpdating ? (
                  <CircularProgress color="primary" size={16} />
                ) : (
                  <span>Add</span>
                )}
              </button>
            </Col>
          </Row>
        </CardHeader>
        <Row>
          <input
            onChange={(e) => changeImage(e)}
            ref={inputFileRef}
            type="file"
            accept="image/*"
            style={{ display: "none" }}
          />
        </Row>
        <CardBody>
          <div className="mx-3">
            {(!images || images?.length === 0) && (
              <h3>This meeting room has no images</h3>
            )}
            <Row>
              {images?.map((image) => {
                return (
                  <div key={image.id} className="pr-4 mt-2">
                    <MeetingRoomImage
                      id={image.id}
                      sourceImage={`${process.env.REACT_APP_IMAGE_URL}/${image?.path}`}
                    />
                  </div>
                );
              })}
            </Row>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default MeetingRoomImageView;
