/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from '@apollo/client';
import { LinearProgress, MenuItem, Select } from '@mui/material';
import React, { useRef, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Row } from 'reactstrap';
import FeaturedImage from '../../../../components/Image component/ImageContainer';
import Spinner from '../../../../components/Spinner/Spinner';
import {
    ADD_VENDOR_EQUIPMENT_ITEM,
    // GET_VENDOR,
    GET_VENDOR_EQUIPMENT_RESOURCES,
} from '../../../../graphql/vendors.graphql';
import useToast from '../../../../hooks/useToast';
import { uploadImage } from '../../../../hooks/upload-image.hook';
import {
    VendorEquipmentCategory,
    VendorEquipmentItem,
    VendorEquipmentStatus,
    VendorEquipmentUnit,
} from '../../../../models/vendor.model';

type FormData = {
    name: string;
    price: number;
    unit: string;
    category: string;
    status: string;
};
interface Props {
    backToListView: () => void;
    selectedEquipmentItem?: VendorEquipmentItem;
}

const EquipmentAddForm = ({ backToListView, selectedEquipmentItem }: Props) => {
    const [image, setImage] = useState<string>(
        selectedEquipmentItem
            ? `${process.env.REACT_APP_IMAGE_URL}/${selectedEquipmentItem?.featuredImage}`
            : undefined,
    );
    const [imageFile, setImageFile] = useState<any>();
    const { id: vendorId } = useParams<any>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const fileInputRef: any = useRef(null);
    const { showToast, Notification } = useToast();
    const { data: equipmentData, loading, error } = useQuery(GET_VENDOR_EQUIPMENT_RESOURCES);

    const [addEquipmentItem, { data: addedEquipment, loading: addingEquipment, error: errorAdding }] = useMutation(
        ADD_VENDOR_EQUIPMENT_ITEM,
        {
            // refetchQueries: [{ query: GET_VENDOR }, "getVendor"],
            refetchQueries: ['getVendor'],
        },
    );

    useEffect(() => {
        if (error) {
            showToast('An error has occurred, please refresh the page', 'danger');
        }

        if (errorAdding) {
            showToast('An error has occurred while trying to add an equipment', 'danger');
            setIsLoading(false);
        }
    }, [error, errorAdding]);

    useEffect(() => {
        if (addedEquipment) {
            if (!selectedEquipmentItem) {
                showToast('Successfully added an equipment item', 'success');
            } else {
                showToast('Successfully updated equipment item', 'success');
            }
            setIsLoading(false);
        }
    }, [addedEquipment]);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>();

    const onSubmit = async (data: FormData) => {
        setIsLoading(true);
        if (!imageFile && !selectedEquipmentItem) {
            showToast('Please make sure that an image file has been selected', 'danger');
            return;
        }

        addItem(data);
    };

    const addItem = async (data: FormData) => {
        let imageKey;

        if (selectedEquipmentItem) {
            imageKey = selectedEquipmentItem.featuredImage;
        }

        if (!selectedEquipmentItem || (selectedEquipmentItem && imageFile)) {
            imageKey = await uploadImage(imageFile, 'equipment_items');
        }

        if (imageKey === 'error') {
            showToast('An error has occurred while trying to upload the image', 'danger');
            setIsLoading(false);
            return;
        }

        const item = {
            id: selectedEquipmentItem?.id,
            vendor_id: vendorId,
            featured_image: imageKey,
            unit: data.unit,
            price: data.price,
            name: data.name,
            status_enum: data.status,
            category: data.category,
        };

        addEquipmentItem({
            variables: {
                item: item,
            },
        });
    };

    const onImageChange = (e: any) => {
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setImageFile(e.target.files[0]);
        setImage(imageUrl);
    };

    if (loading) {
        return <LinearProgress />;
    }

    const equipmentStatusList: VendorEquipmentStatus[] = equipmentData?.equipmentStatus;
    const categoryList: VendorEquipmentCategory[] = equipmentData?.equipmentCategory;
    const unitList: VendorEquipmentUnit[] = equipmentData?.equipmentUnit;

    return (
        <>
            {Notification}
            <Row className='mt-4'>
                <Col md='12' lg='6'>
                    <Card>
                        <CardHeader>
                            <Row noGutters={true}>
                                <button className='btn btn-outline-primary btn-sm mr-4' onClick={backToListView}>
                                    <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                                    <span className='btn-inner-text'>Back</span>
                                </button>
                                <h3 className='m-0'>{selectedEquipmentItem?.id ? 'Update item' : 'Add item'}</h3>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <FormGroup>
                                    <label className='form-control-label'>Featured Image</label>
                                    <FeaturedImage fileInputRef={fileInputRef} imageSource={image} />
                                    <input
                                        ref={fileInputRef}
                                        type='file'
                                        accept='image/*'
                                        onChange={(e) => {
                                            onImageChange(e);
                                        }}
                                        style={{ display: 'none' }}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='name'>
                                        Name
                                    </label>
                                    <input
                                        className='form-control'
                                        id='name'
                                        {...register('name', {
                                            required: true,
                                            value: selectedEquipmentItem?.name,
                                        })}
                                        placeholder='Enter item name...'
                                        type='text'
                                    />
                                    {errors.name && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='price'>
                                        Price
                                    </label>
                                    <input
                                        className='form-control'
                                        id='price'
                                        {...register('price', {
                                            required: true,
                                            value: selectedEquipmentItem?.price || 0,
                                        })}
                                        placeholder='Enter item price...'
                                        step={0.01}
                                        type='number'
                                    />{' '}
                                    {errors.price && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                                <div className='row'>
                                    <div className='col-lg-6 col-md-12'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='menuType'>
                                                Unit
                                            </label>
                                            <Select
                                                id='menuType'
                                                sx={{ borderRadius: '12px', padding: '0' }}
                                                className='form-control'
                                                defaultValue={selectedEquipmentItem?.equipmentUnit.value || 'empty'}
                                                inputProps={{
                                                    ...register('unit', { required: true }),
                                                }}>
                                                <MenuItem disabled value='empty'>
                                                    Select unit...
                                                </MenuItem>
                                                {unitList.map((unit) => {
                                                    return (
                                                        <MenuItem key={unit.value} value={unit.value}>
                                                            {unit.title}
                                                        </MenuItem>
                                                    );
                                                })}
                                                {errors.unit && (
                                                    <span className='invalid'>*This field is required</span>
                                                )}
                                            </Select>
                                        </FormGroup>
                                    </div>
                                    <div className='col-lg-6 col-md-12'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='menuCategory'>
                                                Category
                                            </label>
                                            <Select
                                                id='menuCategory'
                                                sx={{ borderRadius: '12px', padding: '0' }}
                                                className='form-control'
                                                defaultValue={selectedEquipmentItem?.itemCategory.value || 'empty'}
                                                inputProps={{
                                                    ...register('category', { required: true }),
                                                }}>
                                                <MenuItem disabled value='empty'>
                                                    Select a category..
                                                </MenuItem>
                                                {categoryList.map((category) => {
                                                    return (
                                                        <MenuItem key={category.value} value={category.value}>
                                                            {category.title}
                                                        </MenuItem>
                                                    );
                                                })}
                                                {errors.category && (
                                                    <span className='invalid'>*This field is required</span>
                                                )}
                                            </Select>
                                        </FormGroup>
                                    </div>

                                    <div className='col-lg-6 col-md-12'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='menuStatus'>
                                                Status
                                            </label>
                                            <Select
                                                id='menuStatus'
                                                sx={{ borderRadius: '12px', padding: '0' }}
                                                className='form-control'
                                                defaultValue={selectedEquipmentItem?.status.value || 'empty'}
                                                inputProps={{
                                                    ...register('status', { required: true }),
                                                }}>
                                                <MenuItem disabled value='empty'>
                                                    Select status...
                                                </MenuItem>
                                                {equipmentStatusList.map((status) => {
                                                    return (
                                                        <MenuItem key={status.value} value={status.value}>
                                                            {status.title}
                                                        </MenuItem>
                                                    );
                                                })}
                                                {errors.status && (
                                                    <span className='invalid'>*This field is required</span>
                                                )}
                                            </Select>
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className='d-flex h-100'>
                                    <div className='align-self-end ml-auto'>
                                        <Button type='submit' className='btn btn-dark mt-4 btn-block'>
                                            {(isLoading || addingEquipment) && (
                                                <span>
                                                    <Spinner />
                                                </span>
                                            )}
                                            {!(isLoading || addingEquipment) && <span>Add item</span>}
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default EquipmentAddForm;
