/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormGroup, Card, CardBody, Button, Row, Col, Spinner } from 'reactstrap';
import { UPDATE_LOCATION } from '../../../graphql/locations.graphql';
import { Location } from '../../../models/location.model';
import useToast from '../../../hooks/useToast';
import FeaturedImage from '../../../components/Image component/ImageContainer';
import { uploadImage } from '../../../hooks/upload-image.hook';

type FormData = {
    name: string;
    street_address: string;
    city: string;
    postal_code: string;
    country: string;
};

interface LocationViewProps {
    location: Location;
}

const EditLocationForm = ({ location }: LocationViewProps) => {
    const { showToast, Notification } = useToast();
    const fileInputRef: any = useRef(null);
    const [imageFile, setImageFile] = useState<any>(null);
    const [image, setImage] = useState<any>(`${process.env.REACT_APP_IMAGE_URL}/${location.featuredImage}`);
    const [updatingDetails, setIsUpdatingDetails] = useState<boolean>(false);

    const [updateLocationMutation, { loading: isUpdating, error: updateError, data: called }] = useMutation(
        UPDATE_LOCATION,
        {
            // refetchQueries: [{ query: GET_LOCATIONS }, "GetLocation"],
            refetchQueries: ['GetLocation'],
        },
    );
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>();

    useEffect(() => {
        if (updateError) {
            showToast('An error has occurred, please try again', 'danger');
        }
    }, [updateError]);

    useEffect(() => {
        if (!isUpdating && called && !updateError) {
            showToast('Successfully updated location details', 'success');
            setIsUpdatingDetails(false);
        }
    }, [isUpdating, called]);

    const onSubmit = async (data: FormData) => {
        let imageKey;

        setIsUpdatingDetails(true);
        if (imageFile) imageKey = await uploadImage(imageFile, 'locations_image');
        if (!imageFile && location.featuredImage) imageKey = location.featuredImage;
        if (imageKey === 'error') {
            showToast('An error has occurred while tying to upload this image', 'danger');
            setIsUpdatingDetails(false);
            return;
        }

        const updateLocation = {
            name: data.name,
            street_address: data.street_address,
            city: data.city,
            postal_code: data.postal_code,
            country: data.country,
            featured_image: imageKey,
        };
        updateLocationMutation({
            variables: { id: location.id, location: updateLocation },
        });
    };

    const changeImage = (e: any) => {
        const url = URL.createObjectURL(e.target.files[0]);
        setImageFile(e.target.files[0]);
        setImage(url);
    };

    return (
        <>
            {Notification}
            <Row className='my-4'>
                <Col md='12' lg='6'>
                    <Card>
                        <CardBody>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='featured-image'>
                                        Featured Image
                                    </label>
                                    <FeaturedImage fileInputRef={fileInputRef} imageSource={image} />
                                    <input
                                        type='file'
                                        accept='image/*'
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                        onChange={(e) => changeImage(e)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='name'>
                                        Name
                                    </label>
                                    <input
                                        className='form-control'
                                        {...register('name', {
                                            required: true,
                                            value: location?.name,
                                        })}
                                        id='name'
                                        placeholder="Enter location's name..."
                                        type='text'
                                    />
                                    {errors.name && <span className='invalid'>This field is required</span>}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='street_address'>
                                        Street Address
                                    </label>
                                    <input
                                        className='form-control'
                                        {...register('street_address', {
                                            required: true,
                                            value: location?.street_address,
                                        })}
                                        id='street_address'
                                        placeholder="Enter user's street address..."
                                        type='text'
                                    />
                                    {errors.street_address && <span className='invalid'>This field is required</span>}
                                </FormGroup>

                                <FormGroup>
                                    <label className='form-control-label' htmlFor='city'>
                                        City
                                    </label>
                                    <input
                                        className='form-control'
                                        {...register('city', {
                                            required: true,
                                            value: location?.city,
                                        })}
                                        id='city'
                                        placeholder='Enter the city...'
                                        type='text'
                                    />
                                    {errors.city && <span className='invalid'>This field is required</span>}
                                </FormGroup>

                                <FormGroup>
                                    <label className='form-control-label' htmlFor='postal_code'>
                                        Postal Code
                                    </label>
                                    <input
                                        className='form-control'
                                        {...register('postal_code', {
                                            required: true,
                                            value: location?.postal_code,
                                        })}
                                        id='postal_code'
                                        placeholder='Enter Postal Code...'
                                        type='text'
                                    />
                                    {errors.postal_code && <span className='invalid'>This field is required</span>}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='country'>
                                        Country
                                    </label>
                                    <input
                                        className='form-control'
                                        {...register('country', {
                                            required: true,
                                            value: location?.country,
                                        })}
                                        id='postal_code'
                                        placeholder='Enter Country...'
                                        type='text'
                                    />
                                    {errors.country && <span className='invalid'>This field is required</span>}
                                </FormGroup>
                                <div className='d-flex h-100'>
                                    <div className='align-self-end ml-auto'>
                                        <Button type='submit' className='btn btn-dark mt-4' disabled={updatingDetails}>
                                            {!updatingDetails ? (
                                                <span>Update Location</span>
                                            ) : (
                                                <span>
                                                    <Spinner />
                                                </span>
                                            )}
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default EditLocationForm;
