/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from "@apollo/client";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
} from "@mui/material";
import { FormGroup } from "reactstrap";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import useToast from "../../../../hooks/useToast";
import { RateType } from "../../../../models/meeting.room.model";
import {
  BasicMeetingRoom,
  CustomConferencePrice,
} from "../../../../models/client.model";
import {
  GET_CUSTOM_PRICING_DATA,
  INSERT_CUSTOM_CONFERENCING_PRICE,
} from "../../../../graphql/clients.graphql";

interface AddCustomConferencePriceDialogProps {
  rateTypes: RateType[];
  isOpen: boolean;
  meetingRooms: BasicMeetingRoom[];
  accountId: string;
  customPrice: CustomConferencePrice | null;
  toggleOpen: (isOpen: boolean) => void;
}

type FormData = {
  price: number;
  currency: string;
  type: string;
  meetingRoomId: string;
};

export const AddCustomConferencePriceDialog = ({
  isOpen,
  rateTypes,
  toggleOpen,
  meetingRooms,
  customPrice,
  accountId,
}: AddCustomConferencePriceDialogProps) => {
  const [addCustomPrice, { error, called, loading, reset }] = useMutation(
    INSERT_CUSTOM_CONFERENCING_PRICE,
    {
      refetchQueries: [
        { query: GET_CUSTOM_PRICING_DATA, variables: { accountId } },
      ],
    }
  );
  const { showToast, Notification } = useToast();

  useEffect(() => {
    if (called && !loading && !error) {
      showToast(
        `Successfully ${customPrice ? "updated" : "added"} custom price`,
        "success"
      );
      reset();
      resetForm();
      toggleOpen(false);
    }
    if (called && error) {
      showToast("An error has occurred, please try again", "danger");
    }
  }, [called, loading, error]);

  useEffect(() => {
    if (customPrice) {
      setValue("price", customPrice.price);
      setValue("currency", customPrice.currencyCode);
      setValue("type", customPrice.rateType.value);
      setValue("meetingRoomId", customPrice.meetingRoom.id);
    }
  }, [customPrice]);

  const handleClose = () => {
    toggleOpen(false);
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset: resetForm,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmit = (data: FormData) => {
    const newCustomPrice = {
      account_id: accountId,
      meeting_room_id: data.meetingRoomId,
      price: data.price,
      currency_code: data.currency,
      rate_type_enum: data.type,
      vat_percentage: 0.15,
      deleted_at: null,
    };
    addCustomPrice({
      variables: {
        customPrice: newCustomPrice,
      },
    });
  };

  return (
    <div>
      {Notification}
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>
          {customPrice ? "Update" : "Add"} Conference Price
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <DialogContentText>
              Enter the details to add the custom price
            </DialogContentText>
            <FormGroup>
              <label className="form-control-label" htmlFor="currency">
                Meeting Room
              </label>
              <Select
                id="meetingRoomId"
                className="form-control"
                sx={{ borderRadius: "12px", padding: "0" }}
                placeholder="Select a type"
                defaultValue={customPrice?.meetingRoom.id}
                inputProps={{
                  ...register("meetingRoomId", {
                    required: true,
                    value: customPrice?.meetingRoom.id,
                  }),
                }}
              >
                {meetingRooms.map((meetingRoom) => (
                  <MenuItem key={meetingRoom.id} value={meetingRoom.id}>
                    {meetingRoom.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.meetingRoomId && (
                <span className="invalid">*This field is required</span>
              )}
            </FormGroup>
            <FormGroup>
              <label className="form-control-label" htmlFor="name">
                Price (excl VAT)
              </label>
              <input
                className="form-control"
                {...register("price", {
                  required: true,
                  value: customPrice?.price,
                })}
                id="company-name"
                placeholder="Enter price..."
                type="number"
                step={0.01}
              />
              {errors.price && (
                <span className="invalid">*This field is required</span>
              )}
            </FormGroup>
            <FormGroup>
              <label className="form-control-label" htmlFor="currency">
                Currency
              </label>
              <Select
                id="currency"
                className="form-control"
                sx={{ borderRadius: "12px", padding: "0" }}
                placeholder="Select a role"
                defaultValue={"zar"}
                inputProps={{
                  ...register("currency", {
                    required: true,
                    value: customPrice?.currencyCode || "zar",
                  }),
                }}
              >
                <MenuItem key={"zar"} value={"zar"}>
                  South African Rand
                </MenuItem>
              </Select>
              {errors.currency && (
                <span className="invalid">*This field is required</span>
              )}
            </FormGroup>
            <FormGroup>
              <label className="form-control-label" htmlFor="currency">
                Rate Type
              </label>
              <Select
                id="type"
                className="form-control"
                sx={{ borderRadius: "12px", padding: "0" }}
                placeholder="Select a type"
                defaultValue={customPrice?.rateType.value}
                inputProps={{
                  ...register("type", {
                    required: true,
                    value: customPrice?.rateType.value,
                  }),
                }}
              >
                {rateTypes.map((type) => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.title}
                  </MenuItem>
                ))}
              </Select>
              {errors.type && (
                <span className="invalid">*This field is required</span>
              )}
            </FormGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit" disabled={loading}>
              {loading && <CircularProgress size={16} />}
              {!loading && <span>{customPrice ? "Update" : "Add"}</span>}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
