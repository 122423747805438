import { gql } from "@apollo/client";

export const GET_PACKAGE_BOOKINGS = gql`
  query GetPackageBookings {
    packageBooking: package_booking(where: { deleted_at: { _is_null: true } }) {
      id
      productPackage: product_package {
        id
        name
        product {
          type: product_type {
            value
            title
          }
        }
      }
      from
      to
      member: account_team_member {
        id
        user {
          name
          surname
        }
      }
      client: user_account {
        id
        companyName: company_name
        individualName: individual_name
        user {
          id
          name
          surname
        }
      }
    }
    productTypes: product_type {
      value
      title
    }
  }
`;

export const GET_PAST_PACKAGE_BOOKINGS = gql`
  query GetPastPackageBookings {
    packageBooking: package_booking(
      where: { deleted_at: { _is_null: true }, from: { _lt: now } }
    ) {
      id
      productPackage: product_package {
        id
        name
        product {
          type: product_type {
            value
            title
          }
        }
      }
      from
      to
      member: account_team_member {
        id
        user {
          name
          surname
        }
      }
      client: user_account {
        id
        companyName: company_name
        individualName: individual_name
        user {
          id
          name
          surname
        }
      }
    }
  }
`;

export const GET_UPCOMING_PACKAGE_BOOKING = gql`
  query GetUpcomingPackageBookings {
    packageBooking: package_booking(
      where: { deleted_at: { _is_null: true }, from: { _gt: now } }
    ) {
      id
      productPackage: product_package {
        id
        name
        product {
          type: product_type {
            value
            title
          }
        }
      }
      from
      to
      member: account_team_member {
        id
        user {
          name
          surname
        }
      }
      client: user_account {
        id
        companyName: company_name
        individualName: individual_name
        user {
          id
          name
          surname
        }
      }
    }
  }
`;

export const GET_PACKAGE_BOOKING_DETAILS = gql`
  query GetPackageBooking($id: uuid!) {
    packageBooking: package_booking_by_pk(id: $id) {
      id
      from
      to
      member: account_team_member {
        id
        user {
          name
          surname
        }
      }
      productPackage: product_package {
        id
        name
      }
      status: booking_status {
        value
        title
      }
      productItem: product_item {
        id
        name
        code
      }
      isAssignable: is_assignable
      client: user_account {
        id
        members: team_members(where: { deleted_at: { _is_null: true } }) {
          id
          user {
            id
            name
            surname
          }
        }
      }
    }
    bookingStatusList: booking_status {
      value
      title
    }
    productPackages: product_package(
      where: { deleted_at: { _is_null: true } }
    ) {
      id
      name
      product {
        id
        productItems: product_items {
          id
          name
          code
          sharable: is_sharable
          status {
            value
            title
          }
        }
      }
    }
  }
`;

export const GET_CALENDAR_PACKAGE_BOOKINGS = gql`
  query CalendarPackageBookings {
    packageBookings: package_booking(
      where: { deleted_at: { _is_null: true } }
    ) {
      id
      from
      to
      status: booking_status {
        value
        title
      }
      client: user_account {
        id
        companyName: company_name
        individualName: individual_name
      }
      productPackage: product_package {
        id
        name
      }
    }
  }
`;
