import React from 'react';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { currencyFormatter } from '../../../../utils/format.utils';

interface ActualAndExpectedIncomeCardsProp {
    chartInfo: any;
}

export default function ActualAndExpectedIncomeCards({ chartInfo }: ActualAndExpectedIncomeCardsProp) {
    const actualIncome = (): number => {
        const chartData = chartInfo;
        if (!chartData) return 0;
        return Math.abs(chartData?.actualIncome);
    };

    const expectedIncome = (): number => {
        const chartData = chartInfo;
        if (!chartData) return 0;
        return Math.abs(chartData?.expectedIncome);
    };

    const textColor = (styleName: string): string => {
        const styleMap = new Map<string, string>();
        styleMap.set('bg-success', 'text-success');
        styleMap.set('bg-danger', 'text-danger');
        if (!styleMap.get(styleName)) return 'text-primary';
        return styleMap.get(styleName);
    };

    const backgroundColor = (): string => {
        const chartData = chartInfo;
        if (!chartData) return 'bg-primary';
        return chartData?.difference < 0 ? 'bg-success' : 'bg-danger';
    };

    return (
        <>
            <Card className='card-stats ml-2 mb-2 pl-4 pr-4 '>
                <div className='row no-gutters justify-content-between align-items-center p-2'>
                    <CardTitle tag='h5' className='text-uppercase text-muted mb-0'>
                        Actual Income
                    </CardTitle>

                    <span className={`h3 font-weight-bold mb-0  ${textColor(backgroundColor())}`}>
                        {currencyFormatter(actualIncome())}
                    </span>
                </div>
            </Card>
            <Card className='card-stats ml-2 pl-2 text-center pl-4 pr-4 '>
                <div className='row no-gutters justify-content-between align-items-center p-2'>
                    <CardTitle tag='h5' className='text-uppercase text-muted  mb-0'>
                        Expected Income
                    </CardTitle>
                    <span className={`h3 font-weight-bold ml-4  mb-0 ${textColor(backgroundColor())}`}>
                        {currencyFormatter(expectedIncome())}
                    </span>
                </div>
            </Card>
        </>
    );
}
