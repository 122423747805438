import React from 'react';
import { Card, CardBody } from 'reactstrap';
import ReactECharts from 'echarts-for-react';
import { ConferenceBookingInfo } from '../../../../models/bookings.model';
import moment from 'moment';

const chartOptions = {
    color: ['#5B9BD5', '#ED7D31', '#5470C6'],
    title: {
        text: 'CUMULATIVE INCOME - ACTUAL VS BUDGET',
    },

    tooltip: {
        trigger: 'axis',
        formatter: function (params) {
            const formatter = new Intl.NumberFormat('en-ZA', {
                style: 'currency',
                currency: 'ZAR',
            });

            let tooltipContent = [];
            tooltipContent.push(`Date: ${params[0].name} <br/>`);
            params.forEach(function (item) {
                const formattedValue = formatter.format(item.value);
                tooltipContent.push(`${item.marker}${item.seriesName}: ${formattedValue}`);
            });

            return tooltipContent.join('<br/>');
        },
    },
    legend: {
        data: ['Income Budget', 'Actual And Future'],
        symbol: 'none',
    },
    grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
    },
    xAxis: {
        type: 'category',
        boundaryGap: false,
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    },
    yAxis: {
        type: 'value',
        axisLabel: {
            // Use the formatter function to format yAxis labels
            formatter: function (value) {
                // Create an Intl.NumberFormat instance for ZAR currency format
                const formatter = new Intl.NumberFormat('en-ZA', {
                    style: 'currency',
                    currency: 'ZAR',
                    minimumFractionDigits: 0, // Adjust fraction digits as needed
                });
                return formatter.format(value);
            },
        },
    },
    series: [
        {
            name: 'Actual And Future',
            type: 'line',
            symbol: 'none',
            markArea: {
                silent: true,
                itemStyle: {
                    opacity: 0.4,
                    color: '#CCCCCC',
                },
                data: [
                    [
                        {
                            xAxis: moment().format('DD-MMM'),
                        },
                        {
                            xAxis: moment().add(30, 'days').format('DD-MMM'),
                        },
                    ],
                ],
            },
            lineStyle: {
                color: '#5B9BD5',
                width: 5,
            },
            stack: 'actual',
            data: [0, 932, 901, 934, 1290, 1330, 1320],
        },
        {
            name: 'Income Budget',
            type: 'line',
            lineStyle: {
                color: '#ED7D31',
                width: 5,
            },
            symbol: 'none',
            stack: 'budget',
            data: [0, 332, 332, 450, 550, 550, 670],
        },
        {
            data: [120, 200, 150, 80, 70, 110, 130],
            type: 'line',
            name: 'Potential Income',
            symbol: 'none',
            symbolSize: 20,
            lineStyle: {
                color: '#5470C6',
                width: 3,
                type: 'dashed',
            },
        },
    ],
};

interface ConferenceBookingIncomeGraphProps {
    chartInfo: ConferenceBookingInfo[];
}

export default function ConferenceBookingIncomeGraph({ chartInfo }: ConferenceBookingIncomeGraphProps) {
    chartOptions.series[0].data = chartInfo.map((info) => info.actualIncome);
    chartOptions.series[1].data = chartInfo.map((info) => info.expectedIncome);
    chartOptions.series[2].data = chartInfo.map((info) => info.potentialIncome);
    chartOptions.xAxis.data = chartInfo.map((info) => moment(info.date).format('DD-MMM'));

    return (
        <Card className=''>
            <CardBody>
                <ReactECharts option={chartOptions} style={{ height: '70vh' }} />
            </CardBody>
        </Card>
    );
}
