import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';

interface EmailIssuesDialogProps {
    isOpen: boolean;
    emails: string[];
    handleClose: () => void;
}
export default function EmailIssuesDialog({ isOpen, handleClose, emails }: EmailIssuesDialogProps) {
    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle>Invalid email list</DialogTitle>
            <DialogContent
                sx={{
                    width: 600,
                }}>
                <DialogContentText className='mb-4'>
                    Kindly be informed that the following email(s) were unable to be added due to their incorrect
                    formatting:
                </DialogContentText>
                <DialogContentText>
                    {emails.map((email) => {
                        return (
                            <p key={email}>
                                {'-'}
                                {email}
                            </p>
                        );
                    })}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <div className='mt-4 d-flex h-100 p-2'>
                    <div className='align-self-end ml-auto'>
                        <Button onClick={handleClose}>Close</Button>
                    </div>
                </div>
            </DialogActions>
        </Dialog>
    );
}
