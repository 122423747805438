/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from "@apollo/client";
import React, { useState, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Button, Card, CardBody, Table } from "reactstrap";
import { REMOVE_MEETING_ROOM_CHECKLIST_ITEM } from "../../../../../graphql/meeting-room-checklist.graphql";
import { GET_MEETING_ROOM } from "../../../../../graphql/meeting-rooms.graphql";
import useToast from "../../../../../hooks/useToast";
import { RoomCheckListItem } from "../../../../../models/meeting.room.model";
import AddCheckListItemDialog from "../../dialogs/AddCheckListItemDialog";

interface Props {
  checkListItems: RoomCheckListItem[];
}

const MeetingRoomCheckListView = ({ checkListItems }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [checkListItem, setCheckListItem] = useState<RoomCheckListItem>();
  const [alert, setAlert] = useState<any>();
  const { showToast, Notification } = useToast();
  const [removeChecklistItem, { data, error }] = useMutation(
    REMOVE_MEETING_ROOM_CHECKLIST_ITEM,
    {
      refetchQueries: [{ query: GET_MEETING_ROOM }, "GetMeetingRoom"],
    }
  );

  const displayAlert = (props: any) => {
    setAlert(
      <SweetAlert
        {...props}
        showCancel
        confirmBtnText="Continue"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => {
          removeChecklistItem({
            variables: {
              id: props.item.id,
            },
          });

          setAlert(null);
        }}
        onCancel={() => {
          setAlert(null);
        }}
        focusCancelBtn
      >
        This checklist item will be removed.
      </SweetAlert>
    );
  };

  useEffect(() => {
    if (data && !error) {
      showToast("Successfully removed checklist item", "success");
    }

    if (error && !data) {
      showToast(
        "An error has occurred while trying to delete this checklist item",
        "danger"
      );
    }
  }, [data, error]);

  return (
    <>
      {alert}
      {Notification}
      {isOpen && (
        <AddCheckListItemDialog
          isOpen={isOpen}
          checkListItem={checkListItem}
          handleClose={() => setIsOpen(false)}
        />
      )}
      <Card>
        <CardBody>
          <div className="row no-gutters justify-content-between align-items-center mb-3">
            <h3 className="mb-0">Room Checklist Items</h3>
            <button
              className="btn btn-dark btn-sm"
              onClick={() => {
                setIsOpen(true);
                setCheckListItem(undefined);
              }}
            >
              Add checklist item
            </button>
          </div>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">Name</th>
                <th scope="col" />
              </tr>
            </thead>
            <tbody className="list">
              {checkListItems.map((item) => {
                return (
                  <tr
                    key={item.id}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setCheckListItem(item);
                      setIsOpen(true);
                    }}
                  >
                    <td>{item.name}</td>
                    <td className="text-right">
                      <Button
                        onClick={() => {
                          setCheckListItem(item);
                          setIsOpen(true);
                        }}
                        className="btn btn-info btn-icon-only rounded-circle btn-sm"
                      >
                        <i className="fa-solid fa-pencil"></i>
                      </Button>
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          displayAlert({ warning: true, item });
                        }}
                        className="btn btn-danger btn-icon-only rounded-circle btn-sm"
                      >
                        <i className="fa-regular fa-trash-can"></i>
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </>
  );
};

export default MeetingRoomCheckListView;
