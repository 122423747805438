import { v4 } from "uuid";
import { Storage } from "aws-amplify";


export const uploadImage = async (file: File, path: string) => {
  let result: any = null;

  try {
    const fileName: string = `${path}/${v4()}_${file.name}`;
    result = await Storage.put(fileName, file, { level: "public" });
  } catch (error: any) {
    console.log("Error uploading the image", error);
    return "error";
  }

  return result.key;
};
