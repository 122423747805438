import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import {
    ADD_SPACE_LOCATION,
    GET_FLOOR_DETAILS,
    GET_LOCATION_SPACE_STATUS_LIST,
    GET_PORTAL_LOCATION,
} from '../../../../graphql/locations.graphql';
import { LinearProgress, MenuItem, Select } from '@mui/material';
import useToast from '../../../../hooks/useToast';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { LocationSpace, LocationSpaceStatus } from '../../../../models/location.model';
import Spinner from '../../../../components/Spinner/Spinner';
import { useParams } from 'react-router-dom';

interface LocationFloorDetailsProps {
    floorId: string;
    goBackToListView: () => void;
}

type FormData = {
    name: string;
    status: string;
};
export default function LocationFloorDetails({ floorId, goBackToListView }: LocationFloorDetailsProps) {
    const { showToast, Notification } = useToast();
    const { loading: loadingStatus, data: statusPayload } = useQuery(GET_LOCATION_SPACE_STATUS_LIST);
    const [addSpaceLocation, { loading: addingSpace, data: addedSpace, error }] = useMutation(ADD_SPACE_LOCATION, {
        refetchQueries: [GET_PORTAL_LOCATION],
    });

    const params: any = useParams();

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm<FormData>();
    const { loading, data } = useQuery(GET_FLOOR_DETAILS, {
        variables: {
            id: floorId,
        },
    });

    useEffect(() => {
        if (addedSpace) {
            showToast('Successfully updated floor details', 'success');
        }
    }, [addedSpace]);

    useEffect(() => {
        if (error) {
            showToast('An error has occurred while trying to update this floor', 'danger');
            console.log('An error has occurred while trying to update this floor', error);
        }
    }, [error]);

    const onSubmit = (data: FormData) => {
        console.log('Form data', data);

        if (data.status === 'empty') {
            setError('status', {
                message: '*This field is required',
            });
            return;
        }

        const space = {
            id: floorId,
            name: data.name,
            status_enum: data.status,
            location_id: params?.id,
            type_enum: 'floor',
        };

        addSpaceLocation({
            variables: {
                space: space,
            },
        });
    };

    if (loading || loadingStatus) {
        return <LinearProgress />;
    }

    const statusList: LocationSpaceStatus[] = statusPayload?.status;
    const floor: LocationSpace = data?.floor;

    console.log('floor', floor);
    return (
        <>
            {Notification}
            <Row>
                <Col md='12' lg='6'>
                    <Card className='mt-4'>
                        <CardHeader>
                            <Row noGutters={true}>
                                <button className='btn btn-outline-primary btn-sm mr-4' onClick={goBackToListView}>
                                    <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                                    <span className='btn-inner-text'>Back</span>
                                </button>

                                <h3 className='m-0'>Update Floor Details</h3>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='name'>
                                        Name
                                    </label>
                                    <input
                                        className='form-control'
                                        {...register('name', { required: true, value: floor?.name })}
                                        id='name'
                                        placeholder='Enter floor name...'
                                        type='text'
                                    />
                                    {errors.name && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='street_address'>
                                        Status
                                    </label>
                                    <Select
                                        id='status'
                                        sx={{ borderRadius: '12px', border: '1px', borderColor: '#F4F4F4' }}
                                        className='form-control'
                                        defaultValue={floor?.status?.value ? floor?.status?.value : ' empty'}
                                        inputProps={{
                                            ...register('status', {
                                                required: true,
                                                value: floor?.status?.value ? floor?.status?.value : ' empty',
                                            }),
                                        }}>
                                        <MenuItem disabled value={'empty'}>
                                            <span className='placeholder-text'>Select floor status...</span>
                                        </MenuItem>
                                        {statusList.map((status) => {
                                            return (
                                                <MenuItem key={status.value} value={status.value}>
                                                    {status.title}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    {errors.status && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                                <div className='d-flex h-100'>
                                    <div className='align-self-end ml-auto'>
                                        <Button
                                            type='submit'
                                            disabled={addingSpace}
                                            className='btn btn-dark mt-4 btn-block'>
                                            {!addingSpace && <span>Add Floor</span>}
                                            {addingSpace && (
                                                <span>
                                                    <Spinner />
                                                </span>
                                            )}
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
}
