import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";
import { Col, Row } from "reactstrap";
import { RoomItemConfiguration } from "../../models/meeting.room.model";
interface BookingConfigurationViewProps {
  itemConfigurations?: RoomItemConfiguration[];
  getConfigurationId?: (id: string) => void;
  configurationId?: string;
}

const BookingConfigurationView = ({
  itemConfigurations,
  configurationId,
  getConfigurationId,
}: BookingConfigurationViewProps) => {
  return (
    <Row className="mt-4 w-100">
      <Col>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={configurationId}
            onChange={(e) => getConfigurationId(e.target.value)}
            name="radio-buttons-group"
          >
            {itemConfigurations?.map((item) => {
              return (
                <FormControlLabel
                  key={item.configurations.id}
                  value={item.configurations.id}
                  control={
                    <Radio
                      onClick={() => {
                        if (configurationId === item.configurations.id) {
                          getConfigurationId(null);
                        }
                      }}
                    />
                  }
                  label={item.configurations.name}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      </Col>
    </Row>
  );
};

export default BookingConfigurationView;
