/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
    Card,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table,
} from 'reactstrap';
import BasicMenu from '../../../components/Menu';
import { useQuery, useMutation } from '@apollo/client';
import { GET_BOOKINGS } from '../../../graphql/bookings.graphql';
import { GET_BOOKING_STATUS, GET_MEETING_ROOM_ITEMS, REMOVE_BOOKING } from '../../../graphql/clients.graphql';
import useToast from '../../../hooks/useToast';
import { Booking, BookingStatus } from '../../../models/bookings.model';
import { LinearProgress } from '@mui/material';
import SweetAlert from 'react-bootstrap-sweetalert';
import moment from 'moment';
import { User } from '../../../models/user.model';
import { useSelector } from 'react-redux';
import { getPermission } from '../../../utils/user.utils';
import _ from 'lodash';
import BookingDialog from '../../../components/BookingDialog';
import { MeetingRoomItem } from '../../../models/meeting.room.model';

function BookingTable() {
    const { showToast, Notification } = useToast();
    const { loading, error, data } = useQuery(GET_BOOKINGS);
    const [searchValue, setSearchValue] = useState('');
    const [alert, setAlert] = useState<any>(null);
    const [page, setPage] = useState<number>(0);
    const [rowPerPage, setRowsPerPage] = useState<number>(10);
    const [sortDirection, setSortDirection] = useState<string>('desc');
    const [sortFiled, setSortField] = useState<string>('date');
    const [selectedBooking, setSelectedBooking] = useState<Booking>();
    const [openBookingDialog, setOpenBookingDialog] = useState<boolean>(false);
    // const { loading: loadingClients, data: loadedClients } =
    //   useQuery(GET_CLIENTS);
    const [removeBookingMutation, { error: removingError, data: removed }] = useMutation(REMOVE_BOOKING, {
        refetchQueries: [{ query: GET_BOOKINGS }, 'GetBookings'],
    });
    const user: User | null = useSelector((state: any) => state.user.userInfo);
    const getRowValue = (value: string) => {
        setRowsPerPage(parseInt(value));
    };

    const { loading: loadingStatus, data: statusList, error: statusError } = useQuery(GET_BOOKING_STATUS);

    const { loading: roomItemsLoading, error: meetingRoomsError, data: roomItems } = useQuery(GET_MEETING_ROOM_ITEMS);

    const nextPage = () => {
        setPage(page + 1);
    };
    const previousPage = () => {
        setPage(page - 1);
    };

    const displayAlert = (props: any) => {
        setAlert(
            <SweetAlert
                {...props}
                showCancel
                confirmBtnText='Continue'
                confirmBtnBsStyle='danger'
                title='Are you sure?'
                onConfirm={() => {
                    removeBooking(props.booking.id);
                    setAlert(null);
                }}
                onCancel={() => {
                    setAlert(null);
                }}
                focusCancelBtn>
                This booking will be removed.
            </SweetAlert>,
        );
    };

    useEffect(() => {
        if (error || meetingRoomsError || statusError) {
            showToast('An error has occurred, please refresh the page', 'danger');
        }

        if (removingError) {
            showToast('An error has occurred while trying remove this booking', 'danger');
        }
    }, [error, removingError, meetingRoomsError, statusError]);

    useEffect(() => {
        if (removed) {
            showToast('Successfully removed the booking', 'success');
        }
    }, [removed]);

    if (loading || roomItemsLoading || loadingStatus) {
        return <LinearProgress />;
    }

    const removeBooking = (_id: string) => {
        removeBookingMutation({
            variables: {
                id: _id,
            },
        });
    };

    const sortBy: any = (bookings: Booking[], field: string, direction: string) => {
        const orderedBookings = new Map([
            [
                'companyName',
                _.orderBy(bookings, (booking) => booking.client.companyName, [direction === 'asc' ? 'asc' : 'desc']),
            ],
            ['subject', _.orderBy(bookings, (booking) => booking.subject, [direction === 'asc' ? 'asc' : 'desc'])],
            ['date', _.orderBy(bookings, (booking) => moment(booking.from), [direction === 'asc' ? 'asc' : 'desc'])],
            ['title', _.orderBy(bookings, (booking) => booking.status.title, [direction === 'asc' ? 'asc' : 'desc'])],
            [
                'from',
                _.orderBy(bookings, (booking) => moment(booking.from).format('HH:mm'), [
                    direction === 'asc' ? 'asc' : 'desc',
                ]),
            ],
            [
                'to',
                _.orderBy(bookings, (booking) => moment(booking.to).format('HH:mm'), [
                    direction === 'asc' ? 'asc' : 'desc',
                ]),
            ],
            ['status', _.orderBy(bookings, (booking) => booking.status.title, [direction === 'asc' ? 'asc' : 'desc'])],
            ['reference', _.orderBy(bookings, (booking) => booking?.reference, [direction === 'asc' ? 'asc' : 'desc'])],
            [
                'room',
                _.orderBy(bookings, (booking) => booking?.meetingRoomItem?.name, [
                    direction === 'asc' ? 'asc' : 'desc',
                ]),
            ],
        ]);
        return orderedBookings.get(field);
    };

    const bookings: Booking[] = data?.booking;
    let filteredBookings = bookings.filter((booking) => {
        if (searchValue === '') {
            return booking;
        }
        const filtered =
            booking.client?.companyName?.toLowerCase().includes(searchValue.toLocaleLowerCase()) ||
            booking.client?.individualName?.toLowerCase().includes(searchValue.toLocaleLowerCase()) ||
            booking.subject.toLowerCase().includes(searchValue.toLocaleLowerCase()) ||
            booking.status?.title.toLowerCase().includes(searchValue.toLocaleLowerCase()) ||
            booking.client.user?.name.toLowerCase().includes(searchValue.toLocaleLowerCase()) ||
            booking.client.user?.surname.toLowerCase().includes(searchValue.toLocaleLowerCase()) ||
            booking.meetingRoomItem?.name.toLowerCase().includes(searchValue.toLocaleLowerCase());
        return filtered;
    });

    filteredBookings = sortBy(filteredBookings, sortFiled, sortDirection);
    const rooms: MeetingRoomItem[] = roomItems?.meetingRoomItems;

    const status: BookingStatus[] = statusList?.status;

    return (
        <>
            {Notification}
            {alert}
            {openBookingDialog && (
                <BookingDialog
                    open={openBookingDialog}
                    rooms={rooms}
                    handleClose={() => setOpenBookingDialog(false)}
                    bookingId={selectedBooking?.id}
                    statusList={status}
                />
            )}
            <Container className='mt-4' fluid>
                <Row>
                    <Col xs='6' className='d-flex'>
                        <h1 className='mb-0'>Bookings</h1>
                        {/* <Link
              className="btn btn-light btn-sm mt-2 mb-2 ml-2"
              to={"/admin/booking"}
            >
              <i className="fa fa-calendar" aria-hidden="true"></i>
            </Link> */}
                    </Col>
                    <Col xs='6' className='text-right'></Col>
                    <div className='col mt-4'>
                        <Card>
                            <CardHeader className='border-0'>
                                <Row>
                                    <Col xs='6'>
                                        <h3 className='mb-0'>Bookings</h3>
                                    </Col>
                                    {getPermission(user, 'bookings', 'create') && (
                                        <Col className='text-right' xs='6'>
                                            {/* <button
                        // to={`/admin/booking/new`}
                        className="btn btn-round btn-sm btn-dark"
                        color="default"
                        id="tooltip969372949"
                        onClick={() => {
                          setOpenBookingDialog(true);
                          setSelectedBooking(undefined);
                        }}
                      >
                        <span className="btn-inner--text">Add booking</span>
                      </button>
                      <UncontrolledTooltip delay={0} target="tooltip969372949">
                        Add booking
                      </UncontrolledTooltip> */}
                                        </Col>
                                    )}
                                </Row>
                                <Row className='mt-4'>
                                    <Col xs='6'>
                                        <Input
                                            className='form-control w-50'
                                            type='text'
                                            placeholder='Search keyword...'
                                            onChange={(e) => {
                                                setSearchValue(e.target.value);
                                                setPage(0);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </CardHeader>
                            <Table className='align-items-center table-flush' responsive>
                                <thead className='thead-light'>
                                    <tr>
                                        <th
                                            onClick={() => {
                                                setSortField('companyName');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='client'
                                            scope='col'>
                                            Company Name
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('subject');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='client'
                                            scope='col'>
                                            Subject
                                        </th>

                                        <th
                                            onClick={() => {
                                                setSortField('date');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='date'
                                            scope='col'>
                                            Date
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('from');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='from'
                                            scope='col'>
                                            From
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('to');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='to'
                                            scope='col'>
                                            To
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('status');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='status'
                                            scope='col'>
                                            Status
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('reference');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='reference'
                                            scope='col'>
                                            Reference
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('room');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='room'
                                            scope='col'>
                                            Room
                                        </th>
                                        <th scope='col'></th>
                                    </tr>
                                </thead>
                                <tbody className='list'>
                                    {filteredBookings
                                        .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                                        .map((booking) => {
                                            return (
                                                <tr
                                                    key={booking.id}
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                        setSelectedBooking(booking);
                                                        setOpenBookingDialog(true);
                                                    }}>
                                                    <td>
                                                        {booking.client?.companyName || booking.client?.individualName}
                                                    </td>
                                                    <td>{booking.subject}</td>
                                                    <td>{moment(booking.from).format('LL')}</td>
                                                    <td>{moment.utc(booking.from).format('HH:mm')}</td>
                                                    <td>{moment.utc(booking.to).format('HH:mm')}</td>
                                                    <td>{booking.status.title}</td>
                                                    <td>{booking.bookingReference || 'N/A'}</td>
                                                    <td>{booking.meetingRoomItem.name}</td>
                                                    <td className='text-right'>
                                                        {getPermission(user, 'bookings', 'update') && (
                                                            <button
                                                                // to={`/admin/booking/tab/${booking.id}`}
                                                                onClick={() => {
                                                                    setSelectedBooking(booking);
                                                                    setOpenBookingDialog(true);
                                                                }}
                                                                className='btn btn-info btn-icon-only rounded-circle btn-sm'>
                                                                <i className='fa-solid fa-pencil'></i>
                                                            </button>
                                                        )}
                                                        {getPermission(user, 'bookings', 'delete') && (
                                                            <button
                                                                className='btn btn-danger btn-icon-only rounded-circle btn-sm'
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    displayAlert({ warning: true, booking });
                                                                }}>
                                                                <i className='fa-solid fa-trash-can'></i>
                                                            </button>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </Table>
                            <CardFooter className='py-4'>
                                <Row>
                                    <div className='col-4 d-flex'>
                                        <span className='min-text'>Rows per page:</span>
                                        <BasicMenu
                                            getValue={getRowValue}
                                            options={['10', '20', '100']}
                                            value={rowPerPage.toString()}
                                        />
                                    </div>
                                    <div className='col-4 d-flex'>
                                        <span className='min-text'>Page:</span>
                                        <span className='min-text pl-2'>
                                            {page + 1} of {Math.ceil(filteredBookings.length / rowPerPage)}
                                        </span>
                                    </div>
                                    <div className='col-4 d-flex'>
                                        <Pagination>
                                            <PaginationItem className={page <= 0 ? 'disabled' : ''}>
                                                <PaginationLink onClick={(e) => previousPage()}>
                                                    <i className='fas fa-angle-left' />
                                                    <span className='sr-only'>Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem
                                                className={
                                                    page >= Math.ceil(filteredBookings.length / rowPerPage) - 1
                                                        ? 'disabled'
                                                        : ''
                                                }>
                                                <PaginationLink onClick={(e) => nextPage()}>
                                                    <i className='fas fa-angle-right' />
                                                    <span className='sr-only'>Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                    </div>
                                </Row>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
}

export default BookingTable;
