/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import { useQuery, useMutation } from "@apollo/client";
import { GET_MEETING_ROOMS } from "../../../graphql/meeting-rooms.graphql";
import { LinearProgress } from "@mui/material";
import { MeetingRoom } from "../../../models/meeting.room.model";
import BasicMenu from "../../../components/Menu";
import SweetAlert from "react-bootstrap-sweetalert";
import { REMOVE_MEETING_ROOM } from "../../../graphql/meeting-rooms.graphql";
import useToast from "../../../hooks/useToast";
import { User } from "../../../models/user.model";
import { useSelector } from "react-redux";
import { getPermission } from "../../../utils/user.utils";
import _ from "lodash";
import { useLocalStorage } from "../../../hooks/local-storage.hook";

interface MeetingRoomRouteData {
  keyWord: string;
  page: number;
  pageSize: number;
}

const MeetingRoomView = () => {
  const { loading, data } = useQuery(GET_MEETING_ROOMS);
  const [page, setPage] = useState<number>(0);
  const [rowPerPage, setRowPerPage] = useState<number>(10);
  const [getLocalStorage, setLocalStorage] = useLocalStorage("meeting-room");
  const [alert, setAlert] = useState<any>(null);
  const [searchKey, setSearchKey] = useState<string>("");
  const navigate = useNavigate();
  const [sortDirection, setSortDirection] = useState<string>("asc");
  const [sortFiled, setSortField] = useState<string>("capacity");
  const user: User | null = useSelector((state: any) => state.user.userInfo);
  const { showToast, Notification } = useToast();
  const [removedRoomMutation, { error: errorRemoving, data: roomRemoved }] =
    useMutation(REMOVE_MEETING_ROOM, {
      refetchQueries: [
        {
          query: GET_MEETING_ROOMS,
        },
        "GetMeetingRooms",
      ],
    });
  const nextPage = () => {
    setPage(page + 1);
    const routeData: MeetingRoomRouteData = getLocalStorage() || {};
    routeData.page = page + 1;
    setLocalStorage(routeData);
  };
  const previousPage = () => {
    setPage(page - 1);
    const routeData: MeetingRoomRouteData = getLocalStorage() || {};
    routeData.page = page - 1;
    setLocalStorage(routeData);
  };

  const getRowValue = (value: string) => {
    setRowPerPage(parseInt(value));
    const routeData: MeetingRoomRouteData = getLocalStorage() || {};
    routeData.pageSize = parseInt(value);
    setLocalStorage(routeData);
  };

  const removeRoom = (_id: string) => {
    removedRoomMutation({
      variables: {
        id: _id,
      },
    });
  };

  const displayAlert = (props: any) => {
    setAlert(
      <SweetAlert
        {...props}
        showCancel
        confirmBtnText="Continue"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => {
          removeRoom(props.room.id);
          setAlert(null);
        }}
        onCancel={() => {
          setAlert(null);
        }}
        focusCancelBtn
      >
        This meeting room will be removed.
      </SweetAlert>
    );
  };

  const sortBy: any = (
    rooms: MeetingRoom[],
    field: string,
    direction: string
  ) => {
    const orderedMeetingRooms = new Map([
      [
        "name",
        _.orderBy(rooms, (room) => room.name, [
          direction === "asc" ? "asc" : "desc",
        ]),
      ],
      [
        "location",
        _.orderBy(rooms, (room) => room.location.name, [
          direction === "asc" ? "asc" : "desc",
        ]),
      ],
      [
        "capacity",
        _.orderBy(rooms, (room) => parseInt(room.capacity), [
          direction === "asc" ? "asc" : "desc",
        ]),
      ],
      [
        "status",
        _.orderBy(rooms, (room) => room.status.title, [
          direction === "asc" ? "asc" : "desc",
        ]),
      ],
    ]);
    return orderedMeetingRooms.get(field);
  };

  useEffect(() => {
    const routeData: MeetingRoomRouteData = getLocalStorage();

    if (routeData) {
      setPage(routeData?.page || 0);
      setRowPerPage(routeData?.pageSize || 10);
      setSearchKey(routeData?.keyWord || "");
    }
  }, []);

  useEffect(() => {
    if (errorRemoving) {
      showToast(
        "An error has occurred while trying to remove this room",
        "danger"
      );
    }
  }, [errorRemoving]);

  useEffect(() => {
    if (roomRemoved) {
      showToast("Successfully removed room", "success");
    }
  }, [roomRemoved]);

  if (loading) {
    return <LinearProgress />;
  }
  const meetingRooms: MeetingRoom[] = data?.meetingRooms;
  let filteredRooms = meetingRooms.filter((room: MeetingRoom) => {
    if (searchKey === "") {
      return room;
    }

    return (
      room.name.toLowerCase().includes(searchKey.toLowerCase()) ||
      room.location.name.toLowerCase().includes(searchKey.toLowerCase()) ||
      room.status.title.toLowerCase().includes(searchKey.toLowerCase())
    );
  });

  filteredRooms = sortBy(filteredRooms, sortFiled, sortDirection);

  return (
    <>
      <Container className="mt-4" fluid>
        {alert}
        {Notification}
        <Row>
          <Col>
            <h1>Meeting rooms</h1>
          </Col>
        </Row>
        <Row className="mt-4">
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Meeting rooms</h3>
                  </Col>
                  {getPermission(user, "meeting_rooms", "create") && (
                    <Col className="text-right" xs="6">
                      <Link
                        to={"/admin/meeting/new"}
                        className="btn btn-round btn-sm btn-dark"
                        color="default"
                        id="tooltip969372949"
                      >
                        <span className="btn-inner--text">Add room</span>
                      </Link>
                      <UncontrolledTooltip delay={0} target="tooltip969372949">
                        Add room
                      </UncontrolledTooltip>
                    </Col>
                  )}
                </Row>
                <Row className="mt-4">
                  <Col xs="6">
                    <Input
                      className="form-control w-50"
                      type="text"
                      value={searchKey}
                      placeholder="Search keyword..."
                      onChange={(e) => {
                        setSearchKey(e.target.value);
                        setPage(0);

                        const routeData: MeetingRoomRouteData =
                          getLocalStorage() || {};
                        routeData.keyWord = e.target.value;
                        routeData.page = 0;
                        setLocalStorage(routeData);
                      }}
                    />
                  </Col>
                  <Col xs="6"></Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th
                      onClick={() => {
                        setSortField("name");
                        setSortDirection(
                          sortDirection === "asc" ? "desc" : "asc"
                        );
                      }}
                      className="sort"
                      data-sort="name"
                      scope="col"
                    >
                      Name
                    </th>
                    <th
                      onClick={() => {
                        setSortField("location");
                        setSortDirection(
                          sortDirection === "asc" ? "desc" : "asc"
                        );
                      }}
                      className="sort"
                      data-sort="location"
                      scope="col"
                    >
                      Location
                    </th>
                    <th
                      onClick={() => {
                        setSortField("capacity");
                        setSortDirection(
                          sortDirection === "asc" ? "desc" : "asc"
                        );
                      }}
                      className="sort"
                      data-sort="capacity"
                      scope="col"
                    >
                      Capacity
                    </th>
                    <th
                      onClick={() => {
                        setSortField("status");
                        setSortDirection(
                          sortDirection === "asc" ? "desc" : "asc"
                        );
                      }}
                      className="sort"
                      data-sort="status"
                      scope="col"
                    >
                      Status
                    </th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody className="list">
                  {filteredRooms
                    .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                    .map((room: MeetingRoom) => {
                      return (
                        <tr
                          key={room.id}
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            navigate(`/admin/meeting/manage/${room.id}`)
                          }
                        >
                          <td>{room.name}</td>
                          <td>{room.location.name}</td>
                          <td>{room.capacity}</td>
                          <td>{room.status.title}</td>
                          <td className="text-right">
                            {getPermission(user, "meeting_rooms", "update") && (
                              <Link
                                className="btn btn-info btn-icon-only rounded-circle btn-sm"
                                to={`/admin/meeting/manage/${room.id}`}
                              >
                                <i className="fa-solid fa-pencil"></i>
                              </Link>
                            )}
                            {getPermission(user, "meeting_rooms", "delete") && (
                              <button
                                className="btn btn-danger btn-icon-only rounded-circle btn-sm"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  displayAlert({ warning: true, room });
                                }}
                              >
                                <i className="fa-solid fa-trash-can"></i>
                              </button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <Row>
                  <div className="col-4 d-flex">
                    <span className="min-text">Rows per page:</span>
                    <BasicMenu
                      getValue={getRowValue}
                      options={["10", "20", "100"]}
                      value={rowPerPage.toString()}
                    />
                  </div>

                  <div className="col-4 d-flex">
                    <span className="min-text">Page:</span>
                    <span className="min-text pl-2">
                      {page + 1} of{" "}
                      {Math.ceil(filteredRooms.length / rowPerPage)}
                    </span>
                  </div>
                  <div className="col-4 d-flex">
                    <Pagination>
                      <PaginationItem className={page <= 0 ? "disabled" : ""}>
                        <PaginationLink onClick={(e) => previousPage()}>
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem
                        className={
                          page >=
                          Math.ceil(filteredRooms.length / rowPerPage) - 1
                            ? "disabled"
                            : ""
                        }
                      >
                        <PaginationLink onClick={(e) => nextPage()}>
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </div>
                </Row>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default MeetingRoomView;
