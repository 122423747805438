import React, { useEffect, useRef } from 'react';
import { ConferenceBookingInfo } from '../../../../models/bookings.model';
import { Card, CardBody, FormGroup } from 'reactstrap';
import ReactECharts from 'echarts-for-react';
import moment from 'moment';
import ReactDatetimeClass from 'react-datetime';
import { DateRange } from '../DailyConferenceIncomeView';
import { useNavigate } from 'react-router-dom';

interface ConferenceDailyIncomeGraphProps {
    chartInfo: ConferenceBookingInfo[];
    dateRange: DateRange;
    loading: boolean;
    onDateRangeChange: (dateRange: DateRange) => void;
}
export default function ConferenceDailyIncomeGraph({
    chartInfo,
    dateRange,
    loading,
    onDateRangeChange,
}: ConferenceDailyIncomeGraphProps) {
    const chartOptions: any = {
        color: ['#5B9BD5', '#ED7D31', '#5470C6'],
        title: {
            text: '',
        },

        tooltip: {
            trigger: 'axis',
            formatter: function (params) {
                const formatter = new Intl.NumberFormat('en-ZA', {
                    style: 'currency',
                    currency: 'ZAR',
                });

                let tooltipContent = [];
                tooltipContent.push(`Date: ${params[0].name} <br/>`);
                params.forEach(function (item) {
                    const formattedValue = formatter.format(item.value);
                    tooltipContent.push(`${item.marker}${item.seriesName}: ${formattedValue}`);
                });

                return tooltipContent.join('<br/>');
            },
        },
        legend: {
            data: ['Income Budget', 'Maximum Room Capacity'],
            symbol: 'none',
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                // Use the formatter function to format yAxis labels
                formatter: function (value) {
                    // Create an Intl.NumberFormat instance for ZAR currency format
                    const formatter = new Intl.NumberFormat('en-ZA', {
                        style: 'currency',
                        currency: 'ZAR',
                        minimumFractionDigits: 0, // Adjust fraction digits as needed
                    });
                    return formatter.format(value);
                },
            },
        },
        series: [
            {
                name: 'Actual Income',
                type: 'bar',
                symbol: 'none',
                markArea: {
                    silent: true,
                    itemStyle: {
                        opacity: 0.4,
                        color: '#CCCCCC',
                    },
                    data: [
                        [
                            {
                                xAxis: moment().format('DD-MMM'),
                            },
                            {
                                xAxis: moment(dateRange.end).format('DD-MMM'),
                            },
                        ],
                    ],
                },
                lineStyle: {
                    color: '#5B9BD5',
                    width: 5,
                },
                stack: 'actual',
                data: [0, 932, 901, 934, 1290, 1330, 1320],
            },
            {
                name: 'Income Budget',
                type: 'line',
                lineStyle: {
                    color: '#ED7D31',
                    width: 5,
                },
                symbol: 'none',
                stack: 'budget',
                data: [0, 332, 332, 450, 550, 550, 670],
            },
            {
                data: [120, 200, 150, 80, 70, 110, 130],
                type: 'line',
                name: 'Maximum Room Capacity',
                symbol: 'none',
                symbolSize: 20,
                lineStyle: {
                    color: '#5470C6',
                    width: 3,
                    type: 'dashed',
                },
            },
            {
                data: [120, 200, 150, 80, 70, 110, 130],
                type: 'bar',
                itemStyle: {
                    borderWidth: 2,
                    borderType: 'dashed',
                    borderColor: '#73c0de',
                },
                name: 'Potential Income',
                stack: 'actual',
                symbol: 'none',
                color: 'rgb(0,0,0, 0)',
                symbolSize: 20,
                lineStyle: {
                    // color: '#5470C6',
                    color: 'rgba(220, 220, 220, 0.0)',
                    width: 3,
                    type: 'dashed',
                },
            },
        ],
    };
    chartOptions.series[0].data = chartInfo.map((info) => info.actualIncome);
    chartOptions.series[1].data = chartInfo.map((info) => info.expectedIncome);
    chartOptions.xAxis.data = chartInfo.map((info) => moment(info.date).format('DD-MMM'));
    chartOptions.series[2].data = chartInfo.map((info) => info?.roomCapacity ?? 0);
    chartOptions.series[3].data = chartInfo.map((info) => {
        return {
            value: info?.potentialIncome ?? 0,
            itemStyle: {
                normal: {
                    type: 'dashed',
                },
                color: '#73C0DE',
                type: 'dashed',
            },
        };
    });

    const navigate = useNavigate();

    const currentPathName = window.location.pathname;

    console.log('Current path', currentPathName);

    console.log('Chart data', chartInfo);
    const startDatePickerRef = useRef<any>();
    const endDatePickerRef = useRef<any>();
    const chartRef = useRef<any>();

    const actualIncomeDataSet = chartOptions.series[0].data;
    const expectedIncomeDataSet = chartOptions.series[1].data;

    useEffect(() => {
        chartOptions.series[0].data = chartInfo.map((info) => info.actualIncome);
        chartOptions.series[1].data = chartInfo.map((info) => info.expectedIncome);
        chartOptions.xAxis.data = chartInfo.map((info) => moment(info.date).format('DD-MMM'));
        chartOptions.series[2].data = chartInfo.map((info) => info?.roomCapacity ?? 0);

        for (var i = 0; i < expectedIncomeDataSet.length; i++) {
            const actual = actualIncomeDataSet[i];
            const expected = expectedIncomeDataSet[i];
            if (actual > expected) {
                chartOptions.series[0].data[i] = { value: actual, itemStyle: { color: '#A9DF96' } };
            } else {
                chartOptions.series[0].data[i] = { value: actual, itemStyle: { color: '#EE6766' } };
            }
        }
    }, [dateRange]);

    for (var i = 0; i < expectedIncomeDataSet.length; i++) {
        const actual = actualIncomeDataSet[i];
        const expected = expectedIncomeDataSet[i];
        if (actual > expected) {
            chartOptions.series[0].data[i] = { value: actual, itemStyle: { color: '#A9DF96' } };
        } else {
            chartOptions.series[0].data[i] = { value: actual, itemStyle: { color: '#EE6766' } };
        }
    }

    const onDateClick = () => {
        startDatePickerRef.current.click();
    };

    const handleOnDateClick = () => {
        endDatePickerRef.current.click();
    };

    const onChangeStartDate = (e: any) => {
        if (moment(e._d) >= moment(dateRange.end)) {
            return;
        }
        const newDateRange: DateRange = { ...dateRange };
        newDateRange.start = moment(e._d).format('YYYY-MM-DD');
        navigate(currentPathName);
        onDateRangeChange(newDateRange);
    };

    const onChangeEndDate = (e: any) => {
        if (moment(e._d) <= moment(dateRange.start)) {
            return;
        }
        const newDateRange: DateRange = { ...dateRange };
        newDateRange.end = moment(e._d).format('YYYY-MM-DD');
        navigate(currentPathName);
        onDateRangeChange(newDateRange);
    };

    if (loading) {
        return null;
    }

    return (
        <Card className=''>
            <CardBody>
                <div className='row'>
                    <div className='col-8'>
                        <h3>DAILY CONFERENCE INCOME - ACTUAL VS BUDGET</h3>
                    </div>
                    <div className='col-4'>
                        <div className='d-flex text-right'>
                            <FormGroup>
                                <div
                                    className='d-flex
                            '>
                                    <p>Start date</p>
                                    <h4 className='date-header ml-4' onClick={onDateClick}>
                                        {moment(dateRange.start).format('LL')}
                                    </h4>
                                </div>

                                <ReactDatetimeClass
                                    inputProps={{
                                        placeholder: 'Select booking date...',
                                        ref: startDatePickerRef,
                                        style: { display: 'none' },
                                    }}
                                    dateFormat={'YYYY-MM-DD'}
                                    timeFormat={false}
                                    value={moment(dateRange.start).format('YYYY-MM-DD')}
                                    onChange={(e) => onChangeStartDate(e)}
                                    closeOnSelect={true}
                                />
                            </FormGroup>
                            <FormGroup>
                                <div
                                    className='d-flex
                            '>
                                    <p className='ml-4'>End date</p>
                                    <h4 className='date-header ml-4' onClick={handleOnDateClick}>
                                        {moment(dateRange.end).format('LL')}
                                    </h4>
                                </div>

                                <ReactDatetimeClass
                                    inputProps={{
                                        placeholder: 'Select booking date...',
                                        ref: endDatePickerRef,
                                        style: { display: 'none' },
                                    }}
                                    dateFormat={'YYYY-MM-DD'}
                                    timeFormat={false}
                                    value={moment(dateRange.end).format('YYYY-MM-DD')}
                                    onChange={(e) => onChangeEndDate(e)}
                                    closeOnSelect={true}
                                />
                            </FormGroup>
                        </div>
                    </div>
                </div>
                <div className='row text-right'>
                    <div className='col-8'></div>
                    {/* <div className='col-4 d-flex'>
                        <FormGroup>
                            <div
                                className='d-flex
                            '>
                                <p>Start date</p>
                                <h4 className='date-header ml-4' onClick={onDateClick}>
                                    {moment(dateRange.start).format('LL')}
                                </h4>
                            </div>

                            <ReactDatetimeClass
                                inputProps={{
                                    placeholder: 'Select booking date...',
                                    ref: startDatePickerRef,
                                    style: { display: 'none' },
                                }}
                                dateFormat={'YYYY-MM-DD'}
                                timeFormat={false}
                                value={moment(dateRange.start).format('YYYY-MM-DD')}
                                onChange={(e) => onChangeStartDate(e)}
                                closeOnSelect={true}
                            />
                        </FormGroup>
                        <FormGroup>
                            <div
                                className='d-flex
                            '>
                                <p className='ml-4'>End date</p>
                                <h4 className='date-header ml-4' onClick={handleOnDateClick}>
                                    {moment(dateRange.end).format('LL')}
                                </h4>
                            </div>

                            <ReactDatetimeClass
                                inputProps={{
                                    placeholder: 'Select booking date...',
                                    ref: endDatePickerRef,
                                    style: { display: 'none' },
                                }}
                                dateFormat={'YYYY-MM-DD'}
                                timeFormat={false}
                                value={moment(dateRange.end).format('YYYY-MM-DD')}
                                onChange={(e) => onChangeEndDate(e)}
                                closeOnSelect={true}
                            />
                        </FormGroup>
                    </div> */}
                </div>
                <ReactECharts
                    ref={chartRef}
                    notMerge={true}
                    lazyUpdate={true}
                    option={chartOptions}
                    style={{ height: '70vh' }}
                />
            </CardBody>
        </Card>
    );
}
