/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";

import SweetAlert from "react-bootstrap-sweetalert";
import {
  GET_PRODUCT,
  REMOVE_PRODUCT_IMAGE,
} from "../../graphql/products.graphql";
import useToast from "../../hooks/useToast";

interface ImageProps {
  sourceImage: string;
  id: string;
}

const ProductImage = ({ sourceImage, id }: ImageProps) => {
  const [removedImageMutation, { error, data }] = useMutation(
    REMOVE_PRODUCT_IMAGE,
    {
      // refetchQueries: [{ query: GET_PRODUCT }, "GetProduct"],
      refetchQueries: ["GetProduct"],
    }
  );
  const [alert, setAlert] = useState<any>(null);
  const { showToast, Notification } = useToast();

  const displayAlert = (props: any) => {
    setAlert(
      <SweetAlert
        {...props}
        showCancel
        confirmBtnText="Continue"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => {
          RemoveProductImage();
          setAlert(null);
        }}
        onCancel={() => {
          setAlert(null);
        }}
        focusCancelBtn
      >
        This image will be removed.
      </SweetAlert>
    );
  };

  useEffect(() => {
    if (error) {
      showToast(
        "An error has occurred while trying to remove the image, please try again",
        "danger"
      );
    }
  }, [error]);

  useEffect(() => {
    if (data && !error) {
      showToast("Successfully removed the image", "success");
    }
  }, [data]);

  const RemoveProductImage = () => {
    removedImageMutation({
      variables: {
        id: id,
      },
    });
  };

  return (
    <div className="image-container">
      {alert}
      {Notification}
      <img
        src={sourceImage}
        alt="img"
        className="image img-responsive img-resize"
      />
      <h1>
        <div className="overlay-alt">
          <button
            className="btn btn-danger btn-icon-only rounded-circle btn-sm"
            onClick={() => displayAlert({ warning: true })}
          >
            <i className="fa-solid fa-trash-can"></i>
          </button>
        </div>
      </h1>
    </div>
  );
};

export default ProductImage;
