import React from "react";
import VoucherAddForm from "./components/VoucherAddForm";

const VoucherAddView = () => {
  return (
    <>
      <VoucherAddForm />
    </>
  );
};

export default VoucherAddView;
