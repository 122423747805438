import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Row, Spinner } from 'reactstrap';
import FeaturedImage from '../../../components/Image component/ImageContainer';
import { useNavigate } from 'react-router-dom';
import { PhoneInput } from 'react-international-phone';
import { useForm } from 'react-hook-form';
import useToast from '../../../hooks/useToast';
import { uploadImage } from '../../../hooks/upload-image.hook';
import {
    ADD_SERVICE_PROVIDER,
    GET_SERVICE_PROVIDERS,
    GET_SERVICE_PROVIDER_LIST,
} from '../../../graphql/service-providers.graphql';
import { useMutation, useQuery } from '@apollo/client';
import { LinearProgress, MenuItem, Select } from '@mui/material';
import { ServiceProviderStatus } from '../../../models/service-providers.model';
import { PhoneNumberUtil } from 'google-libphonenumber';

type FormData = {
    name: string;
    registrationNumber: string;
    vatNumber: string;
    representedBy: string;
    representationCapacity: string;
    contactNumber: string;
    emailAddress: string;
    status: string;
};

export default function ServiceProviderAddFormView() {
    const phoneUtil = PhoneNumberUtil.getInstance();

    const isPhoneValid = (phone: string) => {
        try {
            return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
        } catch (error) {
            return false;
        }
    };
    const navigate = useNavigate();
    const fileInputRef: any = useRef(null);
    const [imageFile, setImageFile] = useState<any>();
    const [image, setImage] = useState<string>();
    const { showToast, Notification } = useToast();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { data, loading, error } = useQuery(GET_SERVICE_PROVIDER_LIST);

    const [addServiceProvider, { data: addedProvider, error: addingError }] = useMutation(ADD_SERVICE_PROVIDER, {
        refetchQueries: [{ query: GET_SERVICE_PROVIDERS }],
    });

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
        reset,
    } = useForm<FormData>();

    const onImageChange = (e: any) => {
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setImageFile(e.target.files[0]);
        setImage(imageUrl);
    };

    useEffect(() => {
        if (error) {
            showToast('An error has occurred while trying to load the page', 'danger');
            console.log('Error', error);
        }
    }, [error]);

    useEffect(() => {
        if (addingError) {
            showToast('An error has occurred while trying to add service provider', 'danger');
            console.log('Adding error', addingError);
            setIsLoading(false);
        }
    }, [addingError]);

    useEffect(() => {
        if (addedProvider) {
            showToast('Successfully added service provider', 'success');
            reset();
            setIsLoading(false);
            navigate(`/admin/service-providers/manage/${addedProvider?.provider?.id}`);
        }
    }, [addedProvider]);

    const onSubmit = async (data: FormData) => {
        if (!isPhoneValid(data.contactNumber)) {
            setError('contactNumber', { type: 'custom', message: '*Please enter a valid phone number' });
            return;
        }

        if (!imageFile) {
            showToast('Please make sure that an image file for the logo has been selected', 'danger');
            return;
        }

        setIsLoading(true);
        const imageKey = await uploadImage(imageFile, 'service_provider');
        if (imageKey === 'error') {
            showToast('An error has occurred while trying to upload the logo image', 'danger');
            setIsLoading(false);
            return;
        }

        const provider = {
            name: data.name,
            registration_number: data.registrationNumber,
            email_address: data.emailAddress,
            vat_number: data.vatNumber,
            contact_number: data.contactNumber,
            represented_by: data.representedBy !== '' ? data.representedBy : undefined,
            representation_capacity: data.representationCapacity !== '' ? data.representationCapacity : undefined,
            logo_path: imageKey,
            status_enum: data.status,
        };

        await addServiceProvider({
            variables: {
                provider,
            },
        });
    };

    if (loading) {
        return <LinearProgress />;
    }

    const serviceProviderStatusList: ServiceProviderStatus[] = data?.statusList;

    return (
        <>
            {Notification}
            <Container className='mt-4' fluid>
                <Row>
                    <Col md='12' lg='6'>
                        <Card>
                            <CardHeader>
                                <Row noGutters={true}>
                                    <button
                                        className='btn btn-outline-primary btn-sm mr-4'
                                        onClick={(e) => navigate(-1)}>
                                        <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                                        <span className='btn-inner-text'>Back</span>
                                    </button>

                                    <h3 className='m-0'>Add Service Provider</h3>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <FormGroup>
                                        <label className='form-control-label'>Logo</label>
                                        <FeaturedImage fileInputRef={fileInputRef} imageSource={image} />
                                        <input
                                            ref={fileInputRef}
                                            type='file'
                                            accept='image/*'
                                            onChange={(e) => {
                                                onImageChange(e);
                                            }}
                                            style={{ display: 'none' }}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='name'>
                                            Name
                                        </label>
                                        <input
                                            className='form-control'
                                            placeholder="Enter service provider's name..."
                                            {...register('name', { required: true })}
                                            type='text'
                                        />
                                        {errors.name && <span className='invalid'>*This field is required</span>}
                                    </FormGroup>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='street_address'>
                                            Registration Number
                                        </label>
                                        <input
                                            className='form-control'
                                            {...register('registrationNumber', { required: true })}
                                            placeholder='Enter registration number...'
                                            type='text'
                                        />
                                        {errors.registrationNumber && (
                                            <span className='invalid'>*This field is required</span>
                                        )}
                                    </FormGroup>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='street_address'>
                                            VAT Number
                                        </label>
                                        <input
                                            className='form-control'
                                            {...register('vatNumber', { required: true })}
                                            placeholder='Enter VAT number...'
                                            type='text'
                                        />
                                        {errors.vatNumber && <span className='invalid'>*This field is required</span>}
                                    </FormGroup>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='city'>
                                            Represented By(Optional)
                                        </label>
                                        <input
                                            className='form-control'
                                            {...register('representedBy', { required: false })}
                                            placeholder='Enter represented by...'
                                            type='text'
                                        />{' '}
                                        {errors.representedBy && (
                                            <span className='invalid'>*This field is required</span>
                                        )}
                                    </FormGroup>
                                    <FormGroup>
                                        <label className='form-control-label'>Representation Capacity(Optional)</label>
                                        <input
                                            className='form-control'
                                            placeholder='Enter representation capacity...'
                                            {...register('representationCapacity', { required: false })}
                                            type='text'
                                        />
                                        {errors.representationCapacity && (
                                            <span className='invalid'>*This field is required</span>
                                        )}
                                    </FormGroup>
                                    <FormGroup>
                                        <label className='form-control-label'>Status</label>
                                        <Select
                                            className='form-control'
                                            defaultValue={' '}
                                            {...register('status', { required: true })}
                                            sx={{ borderRadius: '12px', padding: 0 }}>
                                            <MenuItem disabled value={' '}>
                                                {'Select status for service provider...'}
                                            </MenuItem>
                                            {serviceProviderStatusList.map((status: ServiceProviderStatus) => {
                                                return (
                                                    <MenuItem key={status.value} value={status.value}>
                                                        {status.title}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                        {errors.status && <span className='invalid'>*This field is required</span>}
                                    </FormGroup>

                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='email'>
                                            Contact Number
                                        </label>
                                        <PhoneInput
                                            defaultCountry='za'
                                            placeholder='Enter contact number...'
                                            inputClassName='form-control'
                                            // value={watch('contactNumber')}
                                            style={
                                                {
                                                    '--react-international-phone-width': '100%',
                                                    '--react-international-phone-height': '44px',
                                                    '--react-international-phone-border-radius': '12px',
                                                } as React.CSSProperties
                                            }
                                            {...register('contactNumber', { required: true })}
                                            onChange={(phone) => setValue('contactNumber', phone)}
                                        />
                                        {errors.contactNumber && (
                                            <span className='invalid'>{errors.contactNumber.message}</span>
                                        )}
                                    </FormGroup>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='email'>
                                            Email Address
                                        </label>
                                        <input
                                            className='form-control'
                                            {...register('emailAddress', { required: true })}
                                            placeholder='Enter email address...'
                                            type='email'
                                        />
                                        {errors.emailAddress && (
                                            <span className='invalid'>*This field is required</span>
                                        )}
                                    </FormGroup>
                                    <div className='d-flex h-100'>
                                        <div className='align-self-end ml-auto'>
                                            <Button
                                                type='submit'
                                                className='btn btn-dark mt-4 btn-block'
                                                disabled={isLoading}>
                                                {isLoading && (
                                                    <span>
                                                        <Spinner />
                                                    </span>
                                                )}
                                                {!isLoading && <span>Add</span>}
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
