import { gql } from "@apollo/client";

export const GET_VOUCHERS = gql`
  query GetVouchers {
    voucher(where: { deleted_at: { _is_null: true } }) {
      id
      title
      code
      expiryDate: expiry_date
      status {
        value
        title
      }
      useLimit: use_limit
      discountValue: discount_value
      useCounter: use_counter
      category {
        value
        title
      }
      discountType: discount_type {
        value
        title
      }
    }
  }
`;

export const GET_VOUCHER = gql`
  query GetVoucher($id: uuid!) {
    voucher: voucher_by_pk(id: $id) {
      id
      title
      description
      startingDate: starting_date
      meetingRoom: meeting_room {
        id
        name
      }
      productPackage: product_package {
        id
        name
      }
      expiryDate: expiry_date
      status {
        value
        title
      }
      discountValue: discount_value
      useLimit: use_limit
      discountType: discount_type {
        value
        title
      }
      termsAndConditions: terms_and_conditions
      category {
        value
        title
      }
    }
    categories: voucher_category {
      value
      title
    }
    types: voucher_discount_type {
      value
      title
    }
    status: voucher_status {
      value
      title
    }
    meetingRoom: meeting_room(
      where: { deleted_at: { _is_null: true } }
      order_by: { name: asc }
    ) {
      id
      name
    }
    productPackages: product_package(
      where: { deleted_at: { _is_null: true } }
      order_by: { name: asc }
    ) {
      id
      name
    }
  }
`;

export const ADD_OR_UPDATE_VOUCHER = gql`
  mutation AddOrUpdateVoucher($voucher: voucher_insert_input!) {
    voucher: insert_voucher_one(
      object: $voucher
      on_conflict: {
        constraint: voucher_pkey
        update_columns: [
          discount_type_enum
          starting_date
          voucher_catergory_enum
          status_enum
          discount_value
          use_limit
          description
          terms_and_conditions
          expiry_date
          title
          meeting_room_id
          product_package_id
        ]
      }
    ) {
      id
    }
  }
`;

export const GET_VOUCHER_ADD_VIEW_DATA = gql`
  query GetVoucherAddViewData {
    categories: voucher_category {
      value
      title
    }
    types: voucher_discount_type {
      value
      title
    }
    status: voucher_status {
      value
      title
    }
    meetingRoom: meeting_room(
      where: { deleted_at: { _is_null: true } }
      order_by: { name: asc }
    ) {
      id
      name
    }
    productPackages: product_package(
      where: { deleted_at: { _is_null: true } }
      order_by: { name: asc }
    ) {
      id
      name
    }
  }
`;

export const DELETE_VOUCHERS = gql`
  mutation RemoveVoucher($id: uuid!) {
    voucher: update_voucher_by_pk(
      pk_columns: { id: $id }
      _set: { deleted_at: now }
    ) {
      id
    }
  }
`;

export const CHECK_IS_VOUCHER_VALID = gql`
  mutation IsVoucherValid($args: is_voucher_valid_args!) {
    voucherResults: is_voucher_valid(args: $args) {
      isValid: is_valid
      message
      voucherId: voucher_id
    }
  }
`;
