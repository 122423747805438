/* eslint-disable react-hooks/exhaustive-deps */
import { MenuItem, Select } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Row, Spinner } from 'reactstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FeaturedImage from '../../../components/Image component/ImageContainer';
import { useMutation, useQuery } from '@apollo/client';
import {
    ADD_MENU_PACKAGE,
    // GET_MENU_DETAILS,
    GET_PACKAGE_TYPES,
} from '../../../graphql/menu-packages.graphql';
import useToast from '../../../hooks/useToast';
import { uploadImage } from '../../../hooks/upload-image.hook';
import { useParams } from 'react-router-dom';
import { CategoryType, MealType, StatusType, MenuType } from '../../../models/vendor.model';

type FormData = {
    name: string;
    description: string;
    menuCategory: string;
    menuMealType: string;
    menuStatus: string;
    menuType: string;
    price: number;
};

function CreatePackageForm({ backToMenuPackages }: any) {
    const [imageFile, setImageFile] = useState<any>();
    const [image, setImage] = useState<string>();
    const { showToast, Notification } = useToast();
    const param: any = useParams();
    const fileInputRef: any = useRef(null);
    const [categories, setCategories] = useState<CategoryType[]>();
    const [mealTypes, setMealTypes] = useState<MealType[]>();
    const [status, setStatus] = useState<StatusType[]>();
    const [menuTypes, setMenuTypes] = useState<MenuType[]>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isType, setIsType] = useState<boolean>(false);

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        trigger,
        formState: { errors },
    } = useForm<FormData>();

    const [addMenuPackage, { called: isCalled, loading: isAdding, error: isAddingError }] = useMutation(
        ADD_MENU_PACKAGE,
        {
            // refetchQueries: [{ query: GET_MENU_DETAILS }, "getMenuDetails"],
            refetchQueries: ['getMenuDetails'],
        },
    );

    const { data } = useQuery(GET_PACKAGE_TYPES);

    useEffect(() => {
        if (data) {
            setCategories(data.menuCategory);
            setMealTypes(data.menuMealType);
            setStatus(data.menuStatus);
            setMenuTypes(data.menuType);
        }
    }, [data]);

    useEffect(() => {
        if (!isAdding && isCalled && !isAddingError) {
            showToast('Package added successfully', 'success');
            reset();
            setIsLoading(false);
        }
    }, [isAdding, isCalled, isAddingError]);

    useEffect(() => {
        register('description', { required: true });
    });

    const onSubmit = async (data: FormData) => {
        if (!imageFile) {
            showToast('Please make sure that an image file has been selected', 'danger');
            return;
        }

        setIsLoading(true);

        const imageKey = await uploadImage(imageFile, 'product_images');
        if (imageKey === 'error') {
            showToast('An error has occurred while trying to upload the image', 'danger');
            return;
        }

        setIsLoading(false);

        const packageData = {
            name: data.name,
            description: data.description,
            vendor_id: param.id,
            meal_type_enum: data.menuMealType === ' ' ? null : data.menuMealType,
            category_enum: data.menuCategory,
            type_enum: data.menuType,
            status_enum: data.menuStatus,
            featured_image: imageKey,
            deleted_at: null,
            price: data.price,
        };
        addMenuPackage({
            variables: { package: packageData },
        });
    };

    const onImageChange = (e: any) => {
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setImageFile(e.target.files[0]);
        setImage(imageUrl);
    };

    const onTypeChange = (e: any) => {
        e.preventDefault();
        e.target.value.includes('drinks') ? setIsType(false) : setIsType(true);
    };

    return (
        <>
            {Notification}

            <Row className='mt-4'>
                <Col md='12' lg='6'>
                    <Card>
                        <CardHeader>
                            <Row noGutters={true}>
                                <button
                                    className='btn btn-outline-primary btn-sm mr-4'
                                    onClick={() => backToMenuPackages()}>
                                    <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                                    <span className='btn-inner-text'>Back</span>
                                </button>
                                <h3 className='m-0'>Add Package</h3>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <FormGroup>
                                    <label className='form-control-label'>Featured Image</label>
                                    <FeaturedImage fileInputRef={fileInputRef} imageSource={image} />
                                    <input
                                        ref={fileInputRef}
                                        type='file'
                                        accept='image/*'
                                        onChange={(e) => {
                                            onImageChange(e);
                                        }}
                                        style={{ display: 'none' }}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='name'>
                                        Name
                                    </label>
                                    <input
                                        className='form-control'
                                        {...register('name', { required: true })}
                                        id='name'
                                        placeholder='Enter a package name...'
                                        type='text'
                                    />
                                    {errors.name && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='description'>
                                        Description
                                    </label>
                                    {/* <input
                      className="form-control"
                      {...register("description", { required: true })}
                      id="registrationNumber"
                      placeholder="Enter a package description..."
                      type="text"
                    /> */}
                                    <CKEditor
                                        id='description'
                                        data={''}
                                        editor={ClassicEditor}
                                        config={{
                                            removePlugins: ['Heading'],
                                            toolbar: [
                                                'bold',
                                                'italic',
                                                'bulletedList',
                                                'numberedList',
                                                'blockQuote',
                                                'link',
                                            ],
                                        }}
                                        placeholder='Enter package description'
                                        onChange={(event: any, editor: any) => {
                                            setValue('description', editor.getData());
                                            trigger('description');
                                        }}
                                        onReady={(editor: any) => {}}
                                    />
                                    {errors.description && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='price'>
                                        Price
                                    </label>
                                    <input
                                        {...register('price', { required: true })}
                                        className='form-control'
                                        id='price'
                                        placeholder='Enter package price...'
                                        type='number'
                                        step='0.01'
                                    />
                                    {errors?.price && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                                <div className='row'>
                                    <div className='col-lg-6 col-md-12'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='menuType'>
                                                Type
                                            </label>
                                            <Select
                                                id='menuType'
                                                sx={{ borderRadius: '12px', padding: '0' }}
                                                className='form-control'
                                                defaultValue={'empty'}
                                                onChange={(e) => onTypeChange(e)}
                                                inputProps={{
                                                    ...register('menuType', {
                                                        required: true,
                                                    }),
                                                }}>
                                                <MenuItem disabled value='empty'>
                                                    Select type...
                                                </MenuItem>
                                                {menuTypes?.map((type: any) => {
                                                    return (
                                                        <MenuItem key={type.value} value={type.value}>
                                                            {type.title}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormGroup>
                                    </div>
                                    <div className='col-lg-6 col-md-12'>
                                        {isType && (
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='menuMealType'>
                                                    Meal Type
                                                </label>
                                                <Select
                                                    id='menuMealType'
                                                    sx={{ borderRadius: '12px', padding: '0' }}
                                                    className='form-control'
                                                    defaultValue={'empty'}
                                                    inputProps={{
                                                        ...register('menuMealType', {
                                                            required: false,
                                                        }),
                                                    }}>
                                                    <MenuItem disabled value='empty'>
                                                        Select a meal type...
                                                    </MenuItem>
                                                    {mealTypes?.map((mealType) => {
                                                        return (
                                                            <MenuItem key={mealType.value} value={mealType.value}>
                                                                {mealType.title}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormGroup>
                                        )}
                                    </div>
                                    <div className='col-lg-6 col-md-12'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='menuCategory'>
                                                Category
                                            </label>
                                            <Select
                                                id='menuCategory'
                                                sx={{ borderRadius: '12px', padding: '0' }}
                                                className='form-control'
                                                defaultValue={' '}
                                                inputProps={{
                                                    ...register('menuCategory', {
                                                        required: true,
                                                    }),
                                                }}>
                                                <MenuItem disabled value=' '>
                                                    Select a category..
                                                </MenuItem>
                                                {categories?.map((category) => {
                                                    return (
                                                        <MenuItem key={category.value} value={category.value}>
                                                            {category.title}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormGroup>
                                    </div>

                                    <div className='col-lg-6 col-md-12'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='menuStatus'>
                                                Status
                                            </label>
                                            <Select
                                                id='menuStatus'
                                                sx={{ borderRadius: '12px', padding: '0' }}
                                                className='form-control'
                                                defaultValue={' '}
                                                inputProps={{
                                                    ...register('menuStatus', {
                                                        required: true,
                                                    }),
                                                }}>
                                                <MenuItem disabled value=' '>
                                                    Select status...
                                                </MenuItem>
                                                {status?.map((s) => {
                                                    return (
                                                        <MenuItem key={s.value} value={s.value}>
                                                            {s.title}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className='d-flex h-100'>
                                    <div className='align-self-end ml-auto'>
                                        <Button
                                            type='submit'
                                            className='btn btn-dark mt-4 btn-block'
                                            disabled={isLoading}>
                                            {!isLoading ? (
                                                <span>Add Package</span>
                                            ) : (
                                                <span>
                                                    <Spinner />
                                                </span>
                                            )}
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default CreatePackageForm;
