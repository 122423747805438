import React, { useState } from 'react';
import { Button, Card, CardBody, Table } from 'reactstrap';
import { MembershipAgreementAddendum } from '../../../../models/membership-agreement.model';
import moment from 'moment';
import { downloadPdf } from '../../../../utils/file.utils';

interface AddendumListViewProps {
    addendumList: MembershipAgreementAddendum[];
    getSelectedAddendum: (addendum: MembershipAgreementAddendum) => void;
    showAddendumForm: (shouldDisplay: boolean) => void;
}

export default function AddendumListView({
    addendumList,
    getSelectedAddendum,
    showAddendumForm,
}: AddendumListViewProps) {
    const downloadDocument = (path: string, addendumId: string) => {
        const url = `${process.env.REACT_APP_IMAGE_URL}/${path}`;
        downloadPdf(url, `${addendumId}`);
    };

    return (
        <>
            <Card>
                <CardBody>
                    <div className='row no-gutters justify-content-between align-items-center mb-3'>
                        <h3 className='mb-0'>Membership Agreement Addendum </h3>
                        <button
                            className='btn btn-dark btn-sm'
                            onClick={() => {
                                getSelectedAddendum(undefined);
                                showAddendumForm(true);
                            }}>
                            Add
                        </button>
                    </div>

                    <Table className='align-items-center table-flush' responsive>
                        <thead className='thead-light'>
                            <tr>
                                <th scope='col'>Start date</th>
                                <th scope='col'>End date</th>
                                <th scope='col'>Status</th>

                                <th scope='col' />
                            </tr>
                        </thead>
                        <tbody className='list'>
                            {addendumList.map((addendum) => {
                                return (
                                    <tr
                                        key={addendum?.id}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            showAddendumForm(true);
                                            getSelectedAddendum(addendum);
                                        }}>
                                        <td>{moment(addendum.startDate).format('yyyy-MM-DD')}</td>
                                        <td>{moment(addendum.endDate).format('yyyy-MM-DD')}</td>
                                        <td>{addendum.status.title}</td>

                                        <td></td>
                                        <td>
                                            <Button
                                                className='btn btn-info btn-icon-only rounded-circle btn-sm'
                                                onClick={() => {
                                                    showAddendumForm(true);
                                                    getSelectedAddendum(addendum);
                                                }}>
                                                <i className='fa-solid fa-pencil'></i>
                                            </Button>
                                            <Button
                                                className='btn btn-outlined-info btn-sm'
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    if (addendum.documentPath) {
                                                        downloadDocument(addendum.documentPath, addendum.id);
                                                    }
                                                }}
                                                disabled={!addendum.documentPath}>
                                                Document
                                            </Button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </>
    );
}
