import React from 'react';

export default function MembershipListItemHeader() {
    return (
        <div className='row mt-4'>
            <div className='col-2'>
                <div className='parent'>
                    <h4 className='block-ellipsis'>Package</h4>
                </div>
            </div>
            <div className='col-2 d-flex'>
                <h4 className='block-ellipsis'>Balcony Free</h4>
            </div>

            <div className='col-2 d-flex justify-content-center'>
                <h4 className='small-text overflow-text'>Parking Fee</h4>
            </div>
            <div className='col-2 d-flex justify-content-center'>
                <div>
                    <h4 className='small-text overflow-text'>Total Free</h4>
                </div>
            </div>
            <div className='col-2 d-flex justify-content-center'>
                <h4 className='small-text overflow-text'>Member</h4>
            </div>
            <div className='col-1 d-flex justify-content-end'></div>
            <div className='col-1 d-flex justify-content-end'></div>
        </div>
    );
}
