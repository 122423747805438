import { Amplify } from "aws-amplify";

export function configureAmplify(): void {
  Amplify.configure({
    Auth: {
      identityPoolId: process.env.REACT_APP_AMPLIFY_AUTH_IDENTITY_POOL_ID,
      region: process.env.REACT_APP_AMPLIFY_AUTH_REGION,
      userPoolId: process.env.REACT_APP_AMPLIFY_AUTH_USER_POOL_ID,
      userPoolWebClientId:
        process.env.REACT_APP_AMPLIFY_AUTH_USER_POOL_CLIENT_ID,
    },
    Storage: {
      AWSS3: {
        region: process.env.REACT_APP_S3_REGION,
        bucket: process.env.REACT_APP_S3_BUCKET,
      },
    },
  });
}
