export const downloadPdf = (url: string, filename: string) => {
  fetch(url, {
    method: "GET",
    mode: "no-cors",
    headers: {
      "Content-Type": "application/pdf",
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("target", "_blank");

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
    });
};
