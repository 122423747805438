/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import BasicMenu from "../../../components/Menu";
import { MeetingRoomItem } from "../../../models/meeting.room.model";
import {
  REMOVE_MEETING_ROOM_ITEM,
  GET_MEETING_ROOM,
} from "../../../graphql/meeting-rooms.graphql";
import { useMutation } from "@apollo/client";
import useToast from "../../../hooks/useToast";
import MeetingRoomItemForm from "./MeetingRoomItemForm";
import MeetingRoomItemsManageForm from "./components/MeetingRoomItemManageForm";
import _ from "lodash";
interface ItemViewProps {
  items: MeetingRoomItem[];
}

const MeetingRoomItemView: any = ({ items }: ItemViewProps) => {
  const [alert, setAlert] = useState<any>(null);
  const { showToast, Notification } = useToast();
  const [page, setPage] = useState<number>(0);
  const [roomItem, setRoomItem] = useState<MeetingRoomItem>();
  const [rowPerPage, setRowsPerPage] = useState<number>(10);
  const [filterKey, setFilterKey] = useState<string>("");
  const [displayForm, setDisplayForm] = useState<boolean>(false);
  const [sortDirection, setSortDirection] = useState<string>("asc");
  const [sortFiled, setSortField] = useState<string>("name");
  const [displayManageForm, setDisplayManageForm] = useState<boolean>(false);

  const [removeItemMutation, { error, data }] = useMutation(
    REMOVE_MEETING_ROOM_ITEM,
    {
      refetchQueries: [{ query: GET_MEETING_ROOM }, "GetMeetingRoom"],
    }
  );

  const getRowValue = (value: string) => {
    setRowsPerPage(parseInt(value));
  };
  const nextPage = () => {
    setPage(page + 1);
  };

  const previousPage = () => {
    setPage(page - 1);
  };

  const removeItem = (id: string) => {
    removeItemMutation({
      variables: {
        id: id,
      },
    });
  };

  const displayAlert = (props: any) => {
    setAlert(
      <SweetAlert
        {...props}
        showCancel
        confirmBtnText="Continue"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => {
          removeItem(props.item.id);
          setAlert(null);
        }}
        onCancel={() => {
          setAlert(null);
        }}
        focusCancelBtn
      >
        This item will be removed from the system.
      </SweetAlert>
    );
  };

  useEffect(() => {
    if (!error && data) {
      showToast("Successfully removed the item", "success");
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      showToast(
        "An error has occurred while trying to remove this item",
        "danger"
      );
    }
  }, [error]);

  useEffect(() => {
    if (items) {
      const updateItem = items.find((state) => state.id === roomItem?.id);
      setRoomItem(updateItem);
    }
  }, [items]);

  const sortBy: any = (
    items: MeetingRoomItem[],
    field: string,
    direction: string
  ) => {
    const orderedBookings = new Map([
      [
        "name",
        _.orderBy(items, (item) => item.name, [
          direction === "asc" ? "asc" : "desc",
        ]),
      ],
      [
        "location",
        _.orderBy(items, (item) => item.location.name, [
          direction === "asc" ? "asc" : "desc",
        ]),
      ],
      [
        "status",
        _.orderBy(items, (item) => item.status.title, [
          direction === "asc" ? "asc" : "desc",
        ]),
      ],
    ]);
    return orderedBookings.get(field);
  };

  if (displayForm) {
    return (
      <MeetingRoomItemForm
        backToItemList={() => {
          setDisplayForm(false);
        }}
      />
    );
  }

  if (displayManageForm) {
    return (
      <MeetingRoomItemsManageForm
        backToItemView={() => setDisplayManageForm(false)}
        roomItem={roomItem}
      />
    );
  }

  // console.log("Add ons", roomItem?.checkListAddOns);

  if (items.length === 0 && !displayForm) {
    return (
      <Card className="mt-4">
        <CardHeader className="border-0">
          <Row>
            <Col xs="6">
              <h3 className="mb-0">Items</h3>
            </Col>
            <Col className="text-right" xs="6">
              <button
                className="btn btn-dark btn-sm"
                onClick={() => setDisplayForm(true)}
              >
                Add item
              </button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <h3>This meeting room has no items</h3>
        </CardBody>
      </Card>
    );
  }

  let filteredItems = items.filter((item) => {
    if (filterKey === "") {
      return item;
    }

    return (
      item.location.name
        .toLocaleLowerCase()
        .includes(filterKey.toLocaleLowerCase()) ||
      item.name.toLocaleLowerCase().includes(filterKey.toLocaleLowerCase())
    );
  });

  filteredItems = sortBy(filteredItems, sortFiled, sortDirection);

  return (
    !displayForm && (
      <>
        {Notification}
        {alert}
        <Card className="mt-4">
          <Row>
            <div className="col">
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Items</h3>
                  </Col>
                  <Col className="text-right" xs="6">
                    <button
                      onClick={() => setDisplayForm(true)}
                      className="btn btn-dark btn-sm"
                    >
                      Add item
                    </button>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col xs="6">
                    <Input
                      className="form-control w-50"
                      type="text"
                      placeholder="Search keyword..."
                      onChange={(e) => {
                        setFilterKey(e.target?.value);
                        setPage(0);
                      }}
                    />
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th
                        onClick={() => {
                          setSortField("name");
                          setSortDirection(
                            sortDirection === "asc" ? "desc" : "asc"
                          );
                        }}
                        className="sort"
                        data-sort="name"
                        scope="col"
                      >
                        Name
                      </th>
                      <th
                        onClick={() => {
                          setSortField("location");
                          setSortDirection(
                            sortDirection === "asc" ? "desc" : "asc"
                          );
                        }}
                        className="sort"
                        data-sort="location"
                        scope="col"
                      >
                        Location
                      </th>
                      <th
                        onClick={() => {
                          setSortField("status");
                          setSortDirection(
                            sortDirection === "asc" ? "desc" : "asc"
                          );
                        }}
                        className="sort"
                        data-sort="status"
                        scope="col"
                      >
                        Status
                      </th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {filteredItems
                      .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                      .map((item) => {
                        return (
                          <tr
                            key={item.id}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setDisplayManageForm(true);
                              setRoomItem(item);
                            }}
                          >
                            <td>{item.name}</td>
                            <td>{item.location.name}</td>
                            <td>{item.status.title}</td>
                            <td className="text-right">
                              <button
                                className="btn btn-info btn-icon-only rounded-circle btn-sm"
                                onClick={() => {
                                  setDisplayManageForm(true);
                                  setRoomItem(item);
                                }}
                              >
                                <i className="fa-solid fa-pencil"></i>
                              </button>
                              <button
                                className="btn btn-danger btn-icon-only rounded-circle btn-sm"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  displayAlert({ warning: true, item });
                                }}
                              >
                                <i className="fa-solid fa-trash-can"></i>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </CardBody>
              <CardFooter className="py-4">
                <Row>
                  <div className="col-4 d-flex">
                    <span className="min-text">Rows per page:</span>
                    <BasicMenu
                      getValue={getRowValue}
                      options={["10", "20", "100"]}
                      value={rowPerPage.toString()}
                    />
                  </div>
                  <div className="col-4 d-flex">
                    <span className="min-text">Page:</span>
                    <span className="min-text pl-2">
                      {page + 1} of{" "}
                      {Math.ceil(filteredItems.length / rowPerPage)}
                    </span>
                  </div>
                  <div className="col-4 d-flex">
                    <Pagination>
                      <PaginationItem className={page <= 0 ? "disabled" : ""}>
                        <PaginationLink onClick={(e) => previousPage()}>
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem
                        className={
                          page >=
                          Math.ceil(filteredItems.length / rowPerPage) - 1
                            ? "disabled"
                            : ""
                        }
                      >
                        <PaginationLink onClick={(e) => nextPage()}>
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </div>
                </Row>
              </CardFooter>
            </div>
          </Row>
        </Card>
      </>
    )
  );
};

export default MeetingRoomItemView;
