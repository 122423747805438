import { Autocomplete, LinearProgress, MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import ReactDatetimeClass from 'react-datetime';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Row } from 'reactstrap';
import { GET_PACKAGE_BOOKING_DETAILS } from '../../../graphql/package-booking.graphql';
import { useMutation, useQuery } from '@apollo/client';
import { PackageBooking } from '../../../models/package-bookings.model';
import { ProductPackage } from '../../../models/packages.models';
import { useForm } from 'react-hook-form';
import moment, { isMoment } from 'moment';
import { TeamMember } from '../../../models/team.member.model';
import { BookingStatus } from '../../../models/bookings.model';
import { ProductItem } from '../../../models/product.model';
import { UPDATE_PRODUCT_PACKAGE } from '../../../graphql/packages.graphql';
import Spinner from '../../../components/Spinner/Spinner';
import useToast from '../../../hooks/useToast';

type FormData = {
    package: string;
    packageItem: string;
    from: string;
    to: string;
    status: string;
    member: string;
};

const PackageBookingDetails = () => {
    // const history = useHistory();
    const navigate = useNavigate();
    const params = useParams<any>();
    const {
        register,
        formState: { errors },
        setValue,
        watch,
        handleSubmit,
    } = useForm<FormData>();

    const { showToast, Notification } = useToast();

    const { loading, data } = useQuery(GET_PACKAGE_BOOKING_DETAILS, {
        variables: {
            id: params?.id,
        },
    });

    const [updatePackageMutation, { error: updateError, data: updatedPackage, loading: isUpdating }] =
        useMutation(UPDATE_PRODUCT_PACKAGE);
    const redirectBackToPackageBookings = () => {
        navigate(-1);
    };

    useEffect(() => {
        if (data) {
            const packageBooking: PackageBooking = data?.packageBooking;
            setValue('packageItem', packageBooking?.productItem?.id || '');
        }
    }, [data, setValue]);

    useEffect(() => {
        if (updatedPackage && !updateError) {
            showToast('Successfully updated the package booking', 'success');
        }

        if (updateError && !updatedPackage) {
            showToast('An error has occurred while trying to update', 'danger');
        }
    }, [updatedPackage, updateError, showToast]);

    if (loading) {
        return <LinearProgress />;
    }

    const packageBooking: PackageBooking = data?.packageBooking;
    const productPackages: ProductPackage[] = data?.productPackages || [];
    const teamMembers: TeamMember[] = packageBooking.client.members;
    const bookingStatusList: BookingStatus[] = data?.bookingStatusList || [];

    const packageId = watch('package') ? watch('package') : packageBooking.productPackage.id;
    const productItems: ProductItem[] =
        productPackages
            ?.find((_package) => _package.id === packageId)
            ?.product.productItems.filter((item) => {
                if (item.sharable) {
                    return item;
                }

                return item.status.value !== 'assigned';
            }) || [];

    const onSubmit = (data: FormData) => {
        // if (!isBookingAvailable) {
        //   checkAvailability({
        //     variables: {
        //       packageId: data.package,
        //       from: data.from,
        //       to: data.to,
        //     },
        //   });
        //   return;
        // }

        const productPackage = {
            member_id: data.member === 'empty' ? null : data.member,
            from: data.from,
            to: data.to,
            package_id: data.package,
            status_enum: data.status,
            product_item_id: data.packageItem !== '' ? data.packageItem : undefined,
        };

        //console.log("Package to update", productPackage);

        updatePackageMutation({
            variables: {
                id: packageBooking.id,
                package: productPackage,
            },
        });
    };

    return (
        <>
            {Notification}
            <Container className='mt-4' fluid>
                <Row className='mt-4'>
                    <Col md='12' lg='6'>
                        <Card>
                            <CardHeader>
                                <Row noGutters={true}>
                                    <div className='col-3'>
                                        <button
                                            className='btn btn-outline-primary btn-sm mr-4'
                                            onClick={() => redirectBackToPackageBookings()}>
                                            <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                                            <span className='btn-inner-text'>Back</span>
                                        </button>
                                    </div>
                                    <div className='col-9'>
                                        <h3>Package bookings details</h3>
                                    </div>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className='row'>
                                        <div className='col-md-12 col-lg-6'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='name'>
                                                    Package
                                                </label>
                                                <Select
                                                    id='package'
                                                    className='form-control'
                                                    sx={{ borderRadius: '12px', padding: '0' }}
                                                    placeholder='Select a role'
                                                    // onChange={(event: SelectChangeEvent) => {
                                                    //   onInputChange();
                                                    //   handleChange(event);
                                                    // }}
                                                    defaultValue={packageBooking?.productPackage?.id || 'empty'}
                                                    inputProps={{
                                                        ...register('package', {
                                                            required: true,
                                                            value: packageBooking.productPackage.id || '',
                                                        }),
                                                    }}>
                                                    {productPackages.map((productPackage) => {
                                                        return (
                                                            <MenuItem key={productPackage.id} value={productPackage.id}>
                                                                {productPackage.name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                                {errors.package && (
                                                    <span className='invalid'>*This field is required</span>
                                                )}
                                            </FormGroup>
                                        </div>

                                        <div className='col-md-12 col-lg-6'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='name'>
                                                    Package item
                                                </label>
                                                <Autocomplete
                                                    id='items'
                                                    defaultValue={packageBooking?.productItem || null}
                                                    filterOptions={(options, state) =>
                                                        options.filter((element) => {
                                                            return (
                                                                element.name
                                                                    .toLowerCase()
                                                                    .includes(state.inputValue.toLowerCase()) ||
                                                                element.code
                                                                    ?.toLowerCase()
                                                                    .includes(state.inputValue.toLowerCase())
                                                            );
                                                        })
                                                    }
                                                    onChange={(event, value) => {
                                                        setValue('packageItem', value?.id ? value.id : '');
                                                    }}
                                                    options={productItems ? productItems : []}
                                                    getOptionLabel={(option) =>
                                                        `${option.name} - ${option.code ? option.code : ''}`
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            placeholder='Assign package item'
                                                            fullWidth={true}
                                                            variant='outlined'
                                                        />
                                                    )}
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-12 col-lg-6'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='email'>
                                                    From
                                                </label>
                                                <ReactDatetimeClass
                                                    inputProps={{
                                                        placeholder: 'Select start date...',
                                                        ...register('from', {
                                                            required: true,
                                                            value: moment(packageBooking?.from).format('yyyy-MM-DD'),
                                                        }),
                                                    }}
                                                    timeFormat={false}
                                                    dateFormat={'YYYY-MM-DD'}
                                                    initialValue={moment(packageBooking?.from).format('yyyy-MM-DD')}
                                                    onChange={(value) => {
                                                        if (value && isMoment(value)) {
                                                            setValue('from', value.format('yyyy-MM-DD'));
                                                        }
                                                        //   onInputChange();
                                                    }}
                                                />
                                                {errors.from && (
                                                    <span className='invalid'>*This field is required</span>
                                                )}
                                            </FormGroup>
                                        </div>
                                        <div className='col-md-12 col-lg-6'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='contact'>
                                                    To
                                                </label>
                                                <ReactDatetimeClass
                                                    inputProps={{
                                                        placeholder: 'Select end date...',
                                                        ...register('to', {
                                                            required: true,
                                                            value: moment(packageBooking?.to).format('yyyy-MM-DD'),
                                                        }),
                                                    }}
                                                    timeFormat={false}
                                                    dateFormat={'YYYY-MM-DD'}
                                                    initialValue={moment(packageBooking?.to).format('yyyy-MM-DD')}
                                                    onChange={(value) => {
                                                        //   if (value && isMoment(value)) {
                                                        //     setValue("to", value.format("yyyy-MM-DD"));
                                                        //   }
                                                        //   onInputChange();
                                                    }}
                                                />
                                                {errors.to && <span className='invalid'>*This field is required</span>}
                                            </FormGroup>
                                        </div>
                                        <div className='col-md-12 col-lg-6'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='p-member-name'>
                                                    Member (optional)
                                                </label>
                                                <Select
                                                    id='package'
                                                    className='form-control'
                                                    sx={{ borderRadius: '12px', padding: '0' }}
                                                    placeholder='Select a role'
                                                    defaultValue={packageBooking.member?.id || 'empty'}
                                                    inputProps={{
                                                        ...register('member', {
                                                            required: false,
                                                            value: packageBooking.member?.id || 'empty',
                                                        }),
                                                    }}>
                                                    <MenuItem disabled className='placeholder-text' value={'empty'}>
                                                        Select a member...
                                                    </MenuItem>
                                                    {teamMembers.map((member: any) => {
                                                        return (
                                                            <MenuItem key={member.id} value={member.id}>
                                                                {`${member.user.name} ${member.user.surname}`}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                                {errors.member && (
                                                    <span className='invalid'>*This field is required</span>
                                                )}
                                            </FormGroup>
                                        </div>
                                        <div className='col-md-12 col-lg-6'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='p-member-name'>
                                                    Status
                                                </label>
                                                <Select
                                                    id='package'
                                                    className='form-control'
                                                    placeholder='Select a role'
                                                    sx={{ borderRadius: '12px', padding: '0' }}
                                                    defaultValue={packageBooking?.status.value || 'empty'}
                                                    inputProps={{
                                                        ...register('status', {
                                                            required: true,
                                                            value: packageBooking?.status.value || '',
                                                        }),
                                                    }}>
                                                    {bookingStatusList.map((status: any) => {
                                                        return (
                                                            <MenuItem key={status.value} value={status.value}>
                                                                {status.title}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                                {errors.status && (
                                                    <span className='invalid'>*This field is required</span>
                                                )}
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className='row no-gutters justify-content-end'>
                                        <Button
                                            type='submit'
                                            className='btn btn-dark mt-4 '
                                            // disabled={isUpdating}
                                        >
                                            {isUpdating ? (
                                                <span>
                                                    <Spinner />
                                                </span>
                                            ) : (
                                                <span>Update package</span>
                                            )}
                                        </Button>
                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default PackageBookingDetails;
