/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useToast from "../../../hooks/useToast";
import { useQuery } from "@apollo/client";
import { LinearProgress } from "@mui/material";
import { GET_NEWSLETTER_EMAIL } from "../../../graphql/newsletter-email.graphql";
import { Emails } from "../../../models/newsletter-emil.model";
import EmailEditForm from "./EmailEditForm";

export default function ManageEmailView() {
  const params: any = useParams();
  const { showToast, Notification } = useToast();

  const {
    data: emailData,
    error: errorEmail,
    loading: loadingEmailData,
  } = useQuery(GET_NEWSLETTER_EMAIL, { variables: { id: params.id } });

  useEffect(() => {
    if (errorEmail) {
      showToast("An error has occurred, please refresh the page", "danger");
    }
  }, [errorEmail]);

  const selectedEmail: Emails = emailData?.newsletterEmail;

  if (loadingEmailData) {
    return <LinearProgress />;
  }

  return (
    <>
      {Notification}
      <EmailEditForm email={selectedEmail} />
    </>
  );
}
