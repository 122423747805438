/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
import { Booking, BookingStatus } from "../../../models/bookings.model";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  REMOVE_BOOKING,
  GET_CLIENT,
  GET_BOOKING_STATUS,
} from "../../../graphql/clients.graphql";
import { useMutation, useQuery } from "@apollo/client";
import useToast from "../../../hooks/useToast";
import BookingAddForm from "./BookingAddForm";
import BasicMenu from "../../../components/Menu";
import ClientBookingManageForm from "./ClientBookingManageForm";
import moment from "moment";
import { useSelector } from "react-redux";
import { User } from "../../../models/user.model";
import { getPermission } from "../../../utils/user.utils";
import _ from "lodash";
import BookingDialog from "../../../components/BookingDialog";
import { MeetingRoomItem } from "../../../models/meeting.room.model";
import { useParams } from "react-router-dom";
import { Client } from "../../../models/client.model";
interface ClientBookingsProps {
  bookings: Booking[];
  rooms: MeetingRoomItem[];
  clients: Client[];
}

const ClientBookingsView: any = ({
  bookings,
  rooms,
  clients,
}: ClientBookingsProps) => {
  const [alert, setAlert] = useState<any>(null);
  const [
    removeBookingMutation,
    { error: errorRemoving, data: bookingRemoved },
  ] = useMutation(REMOVE_BOOKING, {
    refetchQueries: [{ query: GET_CLIENT }, "GetClient"],
  });
  const { showToast, Notification } = useToast();
  const [displayForm, setDisplayForm] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowPerPage, setRowsPerPage] = useState<number>(10);
  const [filterKey, setFilterKey] = useState<string>("");
  const [displayManageForm, setDisplayManageForm] = useState<boolean>(false);
  const [sortDirection, setSortDirection] = useState<string>("desc");
  const [sortFiled, setSortField] = useState<string>("date");
  const [bookingId] = useState<string>("");
  const user: User | null = useSelector((state: any) => state.user.userInfo);
  const [openBookingDialog, setOpenBookingDialog] = useState<boolean>(false);
  const [selectedBooking, setSeletedBooking] = useState<Booking>();
  const params: any = useParams();
  const { data: statusList } = useQuery(GET_BOOKING_STATUS);

  useEffect(() => {
    if (errorRemoving) {
      showToast(
        "An error has occurred while trying to remove this booking",
        "danger"
      );
    }
  }, [errorRemoving]);

  const getRowValue = (value: string) => {
    setRowsPerPage(parseInt(value));
  };
  const nextPage = () => {
    setPage(page + 1);
  };

  const previousPage = () => {
    setPage(page - 1);
  };

  useEffect(() => {
    if (bookingRemoved && !errorRemoving) {
      showToast("Booking removed successfully", "success");
    }
  }, [bookingRemoved]);

  const status: BookingStatus[] = statusList?.status;

  if (displayManageForm) {
    return (
      <ClientBookingManageForm
        backToBookings={() => setDisplayManageForm(false)}
        bookingId={bookingId}
      />
    );
  }

  if (displayForm) {
    return <BookingAddForm goBackToBookings={() => setDisplayForm(false)} />;
  }

  if (!displayForm && bookings.length === 0) {
    return (
      <>
        {openBookingDialog && (
          <BookingDialog
            open={openBookingDialog}
            rooms={rooms}
            handleClose={() => setOpenBookingDialog(false)}
            bookingId={selectedBooking?.id}
            clientId={params.id}
            // clients={clients}
            statusList={status}
          />
        )}
        <Card className="mt-4">
          <CardHeader>
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Bookings</h3>
              </Col>
              <Col className="text-right" xs="6">
                {/* <button
                  onClick={() => {
                    setOpenBookingDialog(true);
                    setSeletedBooking(undefined);
                  }}
                  className="btn btn-dark btn-sm"
                >
                  Add booking
                </button> */}
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <h2>This client has no bookings.</h2>
          </CardBody>
        </Card>
      </>
    );
  }

  const removeBooking = (_id: string) => {
    removeBookingMutation({
      variables: {
        id: _id,
      },
    });
  };

  const displayAlert = (props: any) => {
    setAlert(
      <SweetAlert
        {...props}
        showCancel
        confirmBtnText="Continue"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => {
          removeBooking(props.booking.id);
          setAlert(null);
        }}
        onCancel={() => {
          setAlert(null);
        }}
        focusCancelBtn
      >
        This booking will be removed from the system.
      </SweetAlert>
    );
  };

  const sortBy: any = (
    bookings: Booking[],
    field: string,
    direction: string
  ) => {
    const orderedBookings = new Map([
      [
        "subject",
        _.orderBy(bookings, (booking) => booking.subject, [
          direction === "asc" ? "asc" : "desc",
        ]),
      ],
      [
        "date",
        _.orderBy(bookings, (booking) => moment(booking.from), [
          direction === "asc" ? "asc" : "desc",
        ]),
      ],
      [
        "from",
        _.orderBy(bookings, (booking) => moment(booking.from).format("HH:mm"), [
          direction === "asc" ? "asc" : "desc",
        ]),
      ],
      [
        "status",
        _.orderBy(bookings, (booking) => booking.status.title, [
          direction === "asc" ? "asc" : "desc",
        ]),
      ],
      [
        "room",
        _.orderBy(bookings, (booking) => booking.meetingRoomItem.name, [
          direction === "asc" ? "asc" : "desc",
        ]),
      ],
    ]);
    return orderedBookings.get(field);
  };

  let filteredBookings = bookings.filter((booking: Booking) => {
    const filtered =
      booking.subject.toLowerCase().includes(filterKey?.toLocaleLowerCase()) ||
      booking.meetingRoomItem.name
        .toLowerCase()
        .includes(filterKey?.toLocaleLowerCase()) ||
      booking.status.title
        .toLowerCase()
        .includes(filterKey?.toLocaleLowerCase());

    if (filterKey === "") {
      return booking;
    }

    return filtered;
  });

  filteredBookings = sortBy(filteredBookings, sortFiled, sortDirection);

  return (
    bookings.length > 0 &&
    !displayForm && (
      <>
        {openBookingDialog && (
          <BookingDialog
            open={openBookingDialog}
            rooms={rooms}
            handleClose={() => setOpenBookingDialog(false)}
            bookingId={selectedBooking?.id}
            clientId={params.id}
            statusList={status}
          />
        )}
        {Notification}
        {alert}
        <Card className="mt-4">
          <Row>
            <div className="col">
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Bookings</h3>
                  </Col>
                  <Col className="text-right" xs="6">
                    {getPermission(user, "client_bookings", "create") && (
                      <></>
                      // <button
                      //   onClick={() => {
                      //     setOpenBookingDialog(true);
                      //     setSeletedBooking(undefined);
                      //   }}
                      //   className="btn btn-dark btn-sm"
                      // >
                      //   Add booking
                      // </button>
                    )}
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col xs="6">
                    <Input
                      className="form-control w-50"
                      type="text"
                      placeholder="Search keyword..."
                      onChange={(e) => {
                        setFilterKey(e.target.value);
                        setPage(0);
                      }}
                    />
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th
                        onClick={() => {
                          setSortField("subject");
                          setSortDirection(
                            sortDirection === "asc" ? "desc" : "asc"
                          );
                        }}
                        scope="col"
                        className="sort"
                        data-sort="companyName"
                      >
                        Subject
                      </th>
                      <th
                        onClick={() => {
                          setSortField("date");
                          setSortDirection(
                            sortDirection === "asc" ? "desc" : "asc"
                          );
                        }}
                        scope="col"
                        className="sort"
                        data-sort="date"
                      >
                        Date
                      </th>
                      <th
                        onClick={() => {
                          setSortField("from");
                          setSortDirection(
                            sortDirection === "asc" ? "desc" : "asc"
                          );
                        }}
                        scope="col"
                        className="sort"
                        data-sort="from"
                      >
                        Time
                      </th>
                      <th
                        onClick={() => {
                          setSortField("status");
                          setSortDirection(
                            sortDirection === "asc" ? "desc" : "asc"
                          );
                        }}
                        scope="col"
                        className="sort"
                        data-sort="status"
                      >
                        Meeting Room
                      </th>
                      <th
                        onClick={() => {
                          setSortField("room");
                          setSortDirection(
                            sortDirection === "asc" ? "desc" : "asc"
                          );
                        }}
                        scope="col"
                        className="sort"
                        data-sort="room"
                      >
                        Status
                      </th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {filteredBookings
                      .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                      .map((booking: Booking) => {
                        return (
                          <tr
                            key={booking.id}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setOpenBookingDialog(true);
                              setSeletedBooking(booking);
                            }}
                          >
                            <td>{booking.subject}</td>
                            <td>{moment(booking.from).format("yyyy/MM/DD")}</td>
                            <td>
                              {moment(booking.from).format("HH:mm")} -{" "}
                              {moment(booking.to).format("HH:mm")}
                            </td>
                            <td>{booking.meetingRoomItem.name}</td>
                            <td>{booking.status.title}</td>
                            <td className="text-right">
                              {getPermission(
                                user,
                                "client_bookings",
                                "update"
                              ) && (
                                <button
                                  className="btn btn-info btn-icon-only rounded-circle btn-sm"
                                  onClick={() => {
                                    setOpenBookingDialog(true);
                                    setSeletedBooking(booking);
                                  }}
                                >
                                  <i className="fa-solid fa-pencil"></i>
                                </button>
                              )}
                              {getPermission(
                                user,
                                "client_bookings",
                                "delete"
                              ) && (
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    displayAlert({ warning: true, booking });
                                  }}
                                  className="btn btn-danger btn-icon-only rounded-circle btn-sm"
                                >
                                  <i className="fa-solid fa-trash-can"></i>
                                </button>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </CardBody>
              <CardFooter className="py-4">
                <Row>
                  <div className="col-4 d-flex">
                    <span className="min-text">Rows per page:</span>
                    <BasicMenu
                      getValue={getRowValue}
                      options={["10", "20", "100"]}
                      value={rowPerPage.toString()}
                    />
                  </div>

                  <div className="col-4 d-flex">
                    <span className="min-text">Page:</span>
                    <span className="min-text pl-2">
                      {page + 1} of{" "}
                      {Math.ceil(filteredBookings.length / rowPerPage)}
                    </span>
                  </div>
                  <div className="col-4 d-flex">
                    <Pagination>
                      <PaginationItem className={page <= 0 ? "disabled" : ""}>
                        <PaginationLink onClick={(e) => previousPage()}>
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem
                        className={
                          page >=
                          Math.ceil(filteredBookings.length / rowPerPage) - 1
                            ? "disabled"
                            : ""
                        }
                      >
                        <PaginationLink onClick={(e) => nextPage()}>
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </div>
                </Row>
              </CardFooter>
            </div>
          </Row>
        </Card>
      </>
    )
  );
};

export default ClientBookingsView;
