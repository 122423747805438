/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormGroup, Container, Card, CardHeader, CardBody, Button, Row, Col } from 'reactstrap';
import FeaturedImage from '../../../components/Image component/ImageContainer';
import Spinner from '../../../components/Spinner/Spinner';
import { ADD_LOCATION } from '../../../graphql/locations.graphql';
import useToast from '../../../hooks/useToast';
import { uploadImage } from '../../../hooks/upload-image.hook';
import { useNavigate } from 'react-router-dom';

type FormData = {
    name: string;
    street_address: string;
    city: string;
    postal_code: string;
    country: string;
};

function AddLocationForm() {
    const navigate = useNavigate();
    const { showToast, Notification } = useToast();
    const [addLocationMutation, { loading: isAdding, error: addError, called }] = useMutation(ADD_LOCATION, {
        refetchQueries: ['GetLocation'],
    });
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<FormData>();
    const fileInputRef: any = useRef(null);
    const [imageFile, setImageFile] = useState<any>();
    const [image, setImage] = useState<string>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (addError) {
            showToast('An error has occurred, please try again', 'danger');
        }
    }, [addError]);

    useEffect(() => {
        if (!isAdding && called && !addError) {
            showToast('Successfully created location', 'success');
            reset();
            setIsLoading(false);
        }
    }, [isAdding, called]);

    const onSubmit = async (data: FormData) => {
        if (!imageFile) {
            showToast('Please make sure that an image file has been selected', 'danger');
            return;
        }

        setIsLoading(true);

        const imageKey = await uploadImage(imageFile, 'product_images');
        if (imageKey === 'error') {
            showToast('An error has occurred while trying to upload the image', 'danger');
            setIsLoading(false);
            return;
        }

        const location = {
            name: data.name,
            street_address: data.street_address,
            city: data.city,
            postal_code: data.postal_code,
            country: data.country,
            featured_image: imageKey,
        };
        addLocationMutation({
            variables: { location },
        });
    };
    const redirectToLocations = (e: any) => {
        e.preventDefault();
        navigate(-1);
    };

    const onImageChange = (e: any) => {
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setImageFile(e.target.files[0]);
        setImage(imageUrl);
    };

    return (
        <>
            {Notification}
            <Container className='mt-4' fluid>
                <Row>
                    <Col md='12' lg='6'>
                        <Card>
                            <CardHeader>
                                <Row noGutters={true}>
                                    <button
                                        className='btn btn-outline-primary btn-sm mr-4'
                                        onClick={(e) => redirectToLocations(e)}>
                                        <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                                        <span className='btn-inner-text'>Back</span>
                                    </button>

                                    <h3 className='m-0'>Add Location</h3>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <FormGroup>
                                        <label className='form-control-label'>Featured Image</label>
                                        <FeaturedImage fileInputRef={fileInputRef} imageSource={image} />
                                        <input
                                            ref={fileInputRef}
                                            type='file'
                                            accept='image/*'
                                            onChange={(e) => {
                                                onImageChange(e);
                                            }}
                                            style={{ display: 'none' }}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='name'>
                                            Name
                                        </label>
                                        <input
                                            className='form-control'
                                            {...register('name', { required: true })}
                                            id='name'
                                            placeholder="Enter user's name..."
                                            type='text'
                                        />
                                        {errors.name && <span className='invalid'>*This field is required</span>}
                                    </FormGroup>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='street_address'>
                                            Street Address
                                        </label>
                                        <input
                                            className='form-control'
                                            {...register('street_address', { required: true })}
                                            id='street_address'
                                            placeholder="Enter user's street address..."
                                            type='text'
                                        />
                                        {errors.street_address && (
                                            <span className='invalid'>*This field is required</span>
                                        )}
                                    </FormGroup>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='city'>
                                            City
                                        </label>
                                        <input
                                            className='form-control'
                                            {...register('city', { required: true })}
                                            id='city'
                                            placeholder='Enter the city...'
                                            type='text'
                                        />
                                        {errors.city && <span className='invalid'>*This field is required</span>}
                                    </FormGroup>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='postal_code'>
                                            Postal Code
                                        </label>
                                        <input
                                            className='form-control'
                                            {...register('postal_code', { required: true })}
                                            id='postal_code'
                                            placeholder='Enter Postal Code...'
                                            type='text'
                                        />
                                        {errors.postal_code && <span className='invalid'>*This field is required</span>}
                                    </FormGroup>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='country'>
                                            Country
                                        </label>
                                        <input
                                            className='form-control'
                                            {...register('country', { required: true })}
                                            id='country'
                                            placeholder='Enter Country...'
                                            type='text'
                                        />
                                        {errors.country && <span className='invalid'>*This field is required</span>}
                                    </FormGroup>
                                    <div className='d-flex h-100'>
                                        <div className='align-self-end ml-auto'>
                                            <Button
                                                type='submit'
                                                className='btn btn-dark mt-4 btn-block'
                                                disabled={isLoading}>
                                                {!isLoading ? (
                                                    <span>Add Location</span>
                                                ) : (
                                                    <span>
                                                        <Spinner />
                                                    </span>
                                                )}
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default AddLocationForm;
