/* eslint-disable react-hooks/exhaustive-deps */
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  Autocomplete,
  Chip,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import moment from "moment";
import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Row,
} from "reactstrap";
import ReactDatetimeClass from "react-datetime";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { bookingTimeList } from "../../../variables/variables";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import {
  ADD_ASSIGNEE_TASK_EVENT,
  ADD_STATUS_UPDATE_EVENT,
  ADD_TASK,
  GET_TASK_DETAIL_VIEW_DATA,
  GET_TASK_LIST,
  REMOVE_TASK_ASSIGNEE,
} from "../../../graphql/task-list.graphql";
import {
  Assignee,
  Task,
  TaskEvent,
  TaskStatus,
  TaskType,
} from "../../../models/tasks.model";
import { User } from "../../../models/user.model";
import useToast from "../../../hooks/useToast";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../../components/Spinner/Spinner";
import { getTime } from "../../../utils/booking.utils";
import TaskActivityView from "./components/TaskActivityView";
import BasicMenu from "../../../components/Menu";
import { useSelector } from "react-redux";

type FormData = {
  name: string;
  description: string;
  type: string;
  status: string;
  date: string;
  time: string;
};

const TaskDetailView = () => {
  const params = useParams<any>();
  // const history = useHistory();
  const navigate = useNavigate();
  const [taskStatus, setTaskStatus] = useState<string>();
  const user: User | null = useSelector((state: any) => state.user.userInfo);

  const [removeAssignee] = useMutation(REMOVE_TASK_ASSIGNEE, {
    refetchQueries: [
      // { query: GET_TASK_DETAIL_VIEW_DATA },
      "GetTaskDetailViewData",
      // { query: GET_TASK_LIST },
      "GetTaskList",
    ],
  });

  const [addAssigneeTaskEvent] = useMutation(ADD_ASSIGNEE_TASK_EVENT, {
    refetchQueries: [
      // { query: GET_TASK_DETAIL_VIEW_DATA },
      "GetTaskDetailViewData",
      // { query: GET_TASK_LIST },
      "GetTaskList",
    ],
  });

  const [addStatusUpdateEvent] = useMutation(ADD_STATUS_UPDATE_EVENT, {
    refetchQueries: [
      // { query: GET_TASK_DETAIL_VIEW_DATA },
      "GetTaskDetailViewData",
      // { query: GET_TASK_LIST },
      "GetTaskList",
    ],
  });
  const [
    updateTask,
    { loading: updating, data: updatedTask, error: updateError },
  ] = useMutation(ADD_TASK, {
    refetchQueries: [
      // { query: GET_TASK_DETAIL_VIEW_DATA },
      "GetTaskDetailViewData",
      // { query: GET_TASK_LIST },
      "GetTaskList",
    ],
  });
  const {
    data: taskDetails,
    loading,
    error,
  } = useQuery(GET_TASK_DETAIL_VIEW_DATA, {
    variables: {
      id: params.id,
    },
  });
  const { showToast, Notification } = useToast();
  const [assignees, setAssignees] = useState<Assignee[]>([
    taskDetails?.task?.assignees,
  ]);
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const getStatus = (statusValue: string) => {
    setTaskStatus(statusValue);
  };

  const onChangeStatus = () => {
    const statusList: TaskStatus[] = taskDetails?.statusList;
    const task: Task = taskDetails?.task;
    const newStatus = statusList.find(
      (status) => status.title === taskStatus
    ).value;

    const oldStatus = task.status.value;

    if (newStatus !== oldStatus) {
      const task = {
        status_enum: newStatus,
      };

      const event = {
        task_id: params.id,
        user_id: user?.id,
        title: statusList.find((status) => status.value === newStatus).title,
        description: `changed status from ${
          statusList.find((status) => status.value === oldStatus).title
        } to ${statusList.find((status) => status.value === newStatus).title}`,
      };

      addStatusUpdateEvent({
        variables: {
          id: params?.id,
          task: task,
          event: event,
        },
      });
    }
  };

  useEffect(() => {
    if (taskDetails) {
      const assigneeList = [
        ...taskDetails?.task?.assignees.map((element) => {
          return {
            id: element?.id,
            userId: element?.userId,
            name: element?.user?.name + " " + element?.user?.surname,
          };
        }),
      ];
      setAssignees([...assigneeList]);
      const task: Task = taskDetails?.task;
      setTaskStatus(task.status.title);
    }
  }, [taskDetails]);

  useEffect(() => {
    if (taskStatus) {
      onChangeStatus();
    }
  }, [taskStatus]);

  useEffect(() => {
    if (updatedTask && !updateError) {
      showToast("Successfully updated this task", "success");
    }
    if (error) {
      showToast("An error has occurred, please refresh the page", "danger");
    }

    if (updateError && !updatedTask) {
      showToast(
        "An error has occurred while trying to update this task",
        "danger"
      );
    }
  }, [error, updateError, updatedTask]);

  const redirectToTaskListView = () => {
    navigate(-1);
  };

  const onSubmit = (data: FormData) => {
    const dueDate = moment(data?.date)
      ?.utc(true)
      .hours(getTime(data?.time)[0])
      .minutes(0)
      .toISOString();

    const statusList: TaskStatus[] = taskDetails?.statusList;

    const newStatus = statusList.find(
      (status) => status.title === taskStatus
    ).value;

    const task = {
      id: params.id,
      title: data.name,
      description: data.description,
      due_date: dueDate,
      type_enum: data.type,
      status_enum: newStatus,
      events: {
        data: {
          user_id: user?.id,
          title: "Update",
          description: `updated this task`,
        },
      },
    };

    updateTask({
      variables: {
        task: task,
      },
    });
  };

  const taskTypes: TaskType[] = taskDetails?.taskType;
  const statusList: TaskStatus[] = taskDetails?.statusList;
  const task: Task = taskDetails?.task;
  const users: User[] = taskDetails?.user;
  const taskEvents: TaskEvent[] = taskDetails?.task?.events;

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <Container className="mt-4" fluid>
      {Notification}
      <Row>
        <Col md="12" lg="6">
          <Card>
            <CardHeader>
              <Row noGutters={true}>
                <div className="col-3">
                  <button
                    className="btn btn-outline-primary btn-sm mr-4"
                    onClick={redirectToTaskListView}
                  >
                    <i
                      className="fas fa-angle-left"
                      style={{ fontSize: "14px" }}
                    />
                    <span className="btn-inner-text">Back</span>
                  </button>
                </div>
                <div className="col-3">
                  <h3 className="m-0">Task Details</h3>
                </div>
                <div className="col-4 text-right"></div>
                <div className="col-2 text-right mt-1">
                  <div className="outline">
                    <BasicMenu
                      getValue={getStatus}
                      options={statusList.map((s) => s.title)}
                      value={taskStatus}
                    />
                  </div>
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                  <label className="form-control-label" htmlFor="name">
                    Name
                  </label>
                  <input
                    className="form-control"
                    id="company-name"
                    placeholder="Enter a task name..."
                    {...register("name", {
                      required: true,
                      value: task?.title || "",
                    })}
                    type="text"
                  />
                  {errors.name && (
                    <span className="invalid">*This field is required</span>
                  )}
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="name">
                    Description
                  </label>
                  <CKEditor
                    id="description"
                    data={task?.description}
                    editor={ClassicEditor}
                    config={{
                      removePlugins: ["Heading"],
                      toolbar: [
                        "bold",
                        "italic",
                        "bulletedList",
                        "numberedList",
                        "blockQuote",
                        "link",
                      ],
                    }}
                    placeholder="Enter room description"
                    onChange={(event: any, editor: any) => {
                      setValue("description", editor.getData());
                    }}
                    onReady={(editor: any) => {}}
                    inputProps={{
                      ...register("description", {
                        required: true,
                        value: task?.description || "",
                      }),
                    }}
                  />
                  {errors.description && (
                    <span className="invalid">*This field is required</span>
                  )}
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="email">
                    Assignees
                  </label>
                  <Autocomplete
                    id="tags-outlined"
                    blurOnSelect={true}
                    onChange={(event, value) => {
                      if (!value) return;
                      const assignee: Assignee = {
                        name: value?.name + " " + value?.surname,
                        userId: value?.id,
                      };

                      const taskEvent = {
                        task_id: params.id,
                        user_id: user?.id,
                        title: assignee.name,
                        description: `assigned ${assignee.name} to this task`,
                      };

                      addAssigneeTaskEvent({
                        variables: {
                          assignee: {
                            user_id: assignee.userId,
                            task_id: params.id,
                          },
                          event: taskEvent,
                        },
                      });

                      const assigneeList = [...assignees, assignee];
                      setAssignees(assigneeList);
                    }}
                    options={users}
                    getOptionLabel={(option) =>
                      option?.name + " " + option?.surname
                    }
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select a user to assign..."
                      />
                    )}
                  />
                  {assignees.map((assignee) => {
                    return (
                      <Chip
                        sx={{
                          marginTop: "12px",
                        }}
                        key={assignee?.userId}
                        label={assignee?.name}
                        onDelete={() => {
                          if (assignee.id) {
                            const event = {
                              task_id: params.id,
                              user_id: user?.id,
                              title: assignee.name,
                              description: `unassigned ${assignee.name} from this task`,
                            };

                            removeAssignee({
                              variables: {
                                id: assignee.id,
                                event: event,
                              },
                            });
                          }

                          const assigneeList = [
                            ...assignees.filter(
                              (a) => a.userId !== assignee.userId
                            ),
                          ];
                          setAssignees(assigneeList);
                        }}
                      />
                    );
                  })}
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="contact">
                    Type
                  </label>
                  <Select
                    className="form-control"
                    sx={{ borderRadius: "12px", padding: "0" }}
                    defaultValue={task?.type.value || "empty"}
                    inputProps={{
                      ...register("type", {
                        required: true,
                        value: task?.type.value || "empty",
                      }),
                    }}
                  >
                    <MenuItem
                      disabled
                      className="placeholder-text"
                      value={"empty"}
                    >
                      Select a task type...
                    </MenuItem>
                    {taskTypes?.map((task) => {
                      return (
                        <MenuItem key={task.value} value={task.value}>
                          {task.title}
                        </MenuItem>
                      );
                    })}
                    {errors.type && (
                      <span className="invalid">*This field is required</span>
                    )}
                  </Select>
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="package">
                    Due date
                  </label>
                  <div className="row">
                    <div className="col-7">
                      <FormGroup>
                        <ReactDatetimeClass
                          inputProps={{
                            placeholder: "Enter due date...",
                            autoComplete: "off",
                            ...register("date", {
                              required: true,
                              value: task?.dueDate
                                ? moment(task?.dueDate).format("YYYY-MM-DD")
                                : "",
                            }),
                          }}
                          dateFormat={"YYYY-MM-DD"}
                          onChange={(date: moment.Moment) => {
                            setValue("date", date.format("YYYY-MM-DD"));
                          }}
                          initialValue={
                            task?.dueDate ? moment(task?.dueDate) : moment()
                          }
                          timeFormat={false}
                        />
                        {errors.date && (
                          <span className="invalid">
                            *This field is required
                          </span>
                        )}
                      </FormGroup>
                    </div>
                    <div className="col-5">
                      <Select
                        id="room"
                        defaultValue={
                          task?.dueDate
                            ? moment(task?.dueDate).format("HH:mm")
                            : "empty"
                        }
                        sx={{ borderRadius: "12px", padding: "0" }}
                        className="form-control"
                        inputProps={{
                          ...register("time", {
                            required: true,
                            value: task?.dueDate
                              ? moment(task?.dueDate).format("HH:mm")
                              : "empty",
                          }),
                        }}
                      >
                        <MenuItem disabled value="empty">
                          Select time...
                        </MenuItem>
                        {bookingTimeList.map((time) => {
                          return (
                            <MenuItem key={time} value={time}>
                              {time}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {errors.time && (
                        <span className="invalid">*This field is required</span>
                      )}
                    </div>
                  </div>
                </FormGroup>
                <div className="d-flex h-100">
                  <div className="align-self-end ml-auto">
                    <Button
                      type="submit"
                      disabled={updating}
                      className="btn btn-dark mt-4 btn-block"
                    >
                      {updating && <Spinner />}
                      {!updating && <span>Update Details</span>}
                    </Button>
                  </div>
                </div>
              </form>
            </CardBody>
          </Card>
        </Col>
        <Col md="12" lg="6">
          <TaskActivityView events={taskEvents} />
        </Col>
      </Row>
    </Container>
  );
};

export default TaskDetailView;
