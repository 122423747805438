import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import ConferenceBookingIncomeGraph from './components/ConferenceBookingIncomeGraph';
import { useQuery } from '@apollo/client';
import { CONFERENCE_BOOKING_DASHBOARD_DATA } from '../../../graphql/bookings.graphql';
import { LinearProgress } from '@mui/material';
import useToast from '../../../hooks/useToast';
import { ConferenceBookingInfo } from '../../../models/bookings.model';
import moment from 'moment';
import ConferenceDashboardCards from './components/ConferenceDashboardCards';
export default function ConferenceBookingsDashboard() {
    const { data, error, loading } = useQuery(CONFERENCE_BOOKING_DASHBOARD_DATA, {
        variables: {
            from: moment().subtract(2, 'weeks').format('YYYY-MM-DD'),
            to: moment().add(30, 'days').format('YYYY-MM-DD'),
            inSixtyDays: moment().add(60, 'days').format('YYYY-MM-DD'),
        },
        fetchPolicy: 'network-only',
    });
    const { showToast, Notification } = useToast();

    useEffect(() => {
        if (error) {
            showToast('An error has occurred while trying to load this page', 'danger');
        }
    }, [error]);

    if (loading) {
        return <LinearProgress />;
    }

    const chartInfo: ConferenceBookingInfo[] = data?.incomeData || [];
    const sixtyDaysInfo: ConferenceBookingInfo[] = data?.sixtyDaysIncome || [];
    return (
        <>
            {Notification}
            <Container fluid>
                <h1 className='mt-4'>Cumulative Income Dashboard</h1>
                <ConferenceDashboardCards chartInfo={chartInfo} sixtyDaysInfo={sixtyDaysInfo} />
                <ConferenceBookingIncomeGraph chartInfo={chartInfo} />
            </Container>
        </>
    );
}
