/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from 'react';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Row } from 'reactstrap';
import { useMutation, useQuery } from '@apollo/client';
import 'react-international-phone/style.css';
import { ADD_MEMBER } from '../../../graphql/clients.graphql';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { GET_ROLES } from '../../../graphql/user.graphql';
import { Checkbox, FormControlLabel, LinearProgress, MenuItem, Select } from '@mui/material';
import { Role } from '../../../models/user.model';
import Spinner from '../../../components/Spinner/Spinner';
import useToast from '../../../hooks/useToast';
import { PhoneInput } from 'react-international-phone';
import ReactDatetimeClass from 'react-datetime';
import { isMoment } from 'moment';
import { PhoneNumberUtil } from 'google-libphonenumber';

type FormData = {
    name: string;
    surname: string;
    email: string;
    identityNumber: string;
    licencePlate: string;
    contactNumber: string;
    role: string;
    sendCommunication: boolean;
    dob: string;
};

const MemberAddForm = ({ goBackToMembers }: any) => {
    const [addMemberMutation, { loading: addingMember, error: errorAdding, data: memberAdded }] = useMutation(
        ADD_MEMBER,
        {
            refetchQueries: ['GetClient'],
        },
    );

    const { loading, error, data } = useQuery(GET_ROLES);
    const { showToast, Notification } = useToast();

    const params: any = useParams();

    const {
        register,
        setValue,
        watch,
        handleSubmit,
        reset,
        setError,
        formState: { errors },
    } = useForm<FormData>();

    const phoneUtil = PhoneNumberUtil.getInstance();

    const isPhoneValid = (phone: string) => {
        try {
            return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
        } catch (error) {
            return false;
        }
    };

    const onSubmit = (data: FormData) => {
        if (!isPhoneValid(data.contactNumber)) {
            setError('contactNumber', { type: 'custom', message: '*Please enter a valid phone number' });
            return;
        }

        const user = {
            name: data.name,
            surname: data.surname,
            email: data.email,
            identityNumber: data.identityNumber,
            licencePlate: data.licencePlate,
            contactNumber: data.contactNumber.replace(' ', ''),
            role: data.role,
            sendCommunication: data.sendCommunication,
            dateOfBirth: data.dob || undefined,
        };

        addMemberMutation({
            variables: {
                accountId: params.id,
                user,
            },
        });
        // console.log('The input results :', user);
    };

    useEffect(() => {
        if (error) {
            showToast('An error has occurred, please refresh the page', 'danger');
        }

        if (errorAdding) {
            showToast('An error has occurred while trying to add a member, please try again', 'danger');
        }
    }, [errorAdding, error]);

    useEffect(() => {
        if (memberAdded && !error && !errorAdding) {
            showToast('Member successfully added', 'success');
            reset();
        }
    }, [memberAdded, data]);

    if (loading) {
        return <LinearProgress />;
    }

    const userRoles: Role[] = data?.role;

    return (
        <Row>
            {Notification}
            <Col md='12' lg='6'>
                <Card className='mt-4'>
                    <CardHeader>
                        <Row noGutters={true}>
                            <button className='btn btn-outline-primary btn-sm mr-4' onClick={() => goBackToMembers()}>
                                <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                                <span className='btn-inner-text'>Back</span>
                            </button>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <Col md='12' lg='6'>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='p-member-name'>
                                            Name
                                        </label>
                                        <input
                                            className='form-control'
                                            id='p-member-name'
                                            placeholder="Enter member's name..."
                                            {...register('name', {
                                                required: true,
                                            })}
                                            type='text'
                                        />
                                        {errors.name && <span className='invalid'>*This field is required</span>}
                                    </FormGroup>
                                </Col>
                                <Col md='12' lg='6'>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='p-member-surname'>
                                            Surname
                                        </label>
                                        <input
                                            className='form-control'
                                            id='p-member-surname'
                                            placeholder="Enter member's surname..."
                                            {...register('surname', {
                                                required: true,
                                            })}
                                            type='text'
                                        />
                                        {errors.surname && <span className='invalid'>*This field is required</span>}
                                    </FormGroup>
                                </Col>
                                <Col md='12' lg='6'>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='email'>
                                            Email
                                        </label>
                                        <input
                                            className='form-control'
                                            id='email'
                                            placeholder="Enter the member's email..."
                                            {...register('email', {
                                                required: true,
                                            })}
                                            type='email'
                                        />
                                        {errors.email && <span className='invalid'>*This field is required</span>}
                                    </FormGroup>
                                </Col>
                                <Col md='12' lg='6'>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='contact'>
                                            Contact number
                                        </label>
                                        <PhoneInput
                                            defaultCountry='za'
                                            placeholder='Enter phone number...'
                                            inputClassName='form-control'
                                            inputStyle={{
                                                width: '500px',
                                            }}
                                            style={
                                                {
                                                    '--react-international-phone-width': '100%',
                                                    '--react-international-phone-height': '44px',
                                                    '--react-international-phone-border-radius': '12px',
                                                } as React.CSSProperties
                                            }
                                            value={watch('contactNumber')}
                                            {...register('contactNumber', { required: true })}
                                            onChange={(phone) => setValue('contactNumber', phone)}
                                        />
                                        {errors.contactNumber && (
                                            <span className='invalid'>
                                                {errors.contactNumber.message ?? '*This field is required'}
                                            </span>
                                        )}
                                    </FormGroup>
                                </Col>
                                <Col md='12' lg='6'>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='identityNumber'>
                                            Identity/Passport number(optional)
                                        </label>
                                        <input
                                            className='form-control'
                                            id='identityNumber'
                                            placeholder="Enter member's alternative identity number..."
                                            {...register('identityNumber', {
                                                required: false,
                                            })}
                                            type='text'
                                        />
                                        {errors.identityNumber && (
                                            <span className='invalid'>*This field is required</span>
                                        )}
                                    </FormGroup>
                                </Col>
                                <Col md='12' lg='6'>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='licencePlate'>
                                            License plate(optional)
                                        </label>
                                        <input
                                            className='form-control'
                                            id='licencePlate'
                                            placeholder="Enter member's licence plate..."
                                            {...register('licencePlate', {
                                                required: false,
                                            })}
                                            type='text'
                                        />
                                        {errors.licencePlate && (
                                            <span className='invalid'>*This field is required</span>
                                        )}
                                    </FormGroup>
                                </Col>
                                <Col md='12' lg='6'>
                                    <label className='form-control-label'>Date of birth(Optional)</label>
                                    <ReactDatetimeClass
                                        inputProps={{
                                            placeholder: 'Select date of birth...',
                                            ...register('dob', {
                                                required: false,
                                            }),
                                        }}
                                        dateFormat={'YYYY-MM-DD'}
                                        timeFormat={false}
                                        onChange={(value) => {
                                            if (value && isMoment(value)) {
                                                setValue('dob', value.format('yyyy-MM-DD'));
                                            }
                                        }}
                                    />
                                </Col>
                                <Col md='12' lg='6'>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='email'>
                                            Role
                                        </label>
                                        <Select
                                            id='package'
                                            className='form-control'
                                            sx={{ borderRadius: '12px', padding: '0' }}
                                            placeholder='Select a member'
                                            defaultValue={'empty'}
                                            inputProps={{
                                                ...register('role', {
                                                    required: false,
                                                    value: '',
                                                }),
                                            }}>
                                            <MenuItem disabled value={'empty'}>
                                                Select a user role...
                                            </MenuItem>
                                            {userRoles.map((role) => {
                                                return (
                                                    <MenuItem key={role.id} value={role.id}>
                                                        {`${role.name}`}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormGroup>
                                </Col>
                                <Col md='12' lg='6'>
                                    <FormControlLabel
                                        className='mt-4'
                                        control={
                                            <Checkbox
                                                onChange={(e) => {
                                                    setValue('sendCommunication', e.target.checked);
                                                }}
                                                {...register('sendCommunication', { required: false })}
                                            />
                                        }
                                        label='Send Communications'
                                    />
                                </Col>
                            </Row>

                            <div className='d-flex h-100'>
                                <div className='align-self-end ml-auto'>
                                    <Button
                                        type='submit'
                                        className='btn btn-dark mt-4 btn-block'
                                        disabled={addingMember}>
                                        {addingMember ? (
                                            <span>
                                                <Spinner />
                                            </span>
                                        ) : (
                                            <span>Add member</span>
                                        )}
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

export default MemberAddForm;
