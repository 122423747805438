/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Row,
} from "reactstrap";
import { Select, LinearProgress, MenuItem } from "@mui/material";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_PRODUCT_ITEM_STATUS,
  ADD_PRODUCT_ITEM,
  GET_PRODUCT,
} from "../../../graphql/products.graphql";
import useToast from "../../../hooks/useToast";
import { ProductItem, ProductItemStatus } from "../../../models/product.model";
import Spinner from "../../../components/Spinner/Spinner";
import { useParams } from "react-router-dom";
import { GET_LOCATIONS } from "../../../graphql/locations.graphql";
import { Location } from "../../../models/location.model";

interface ProductFormProps {
  backToItems(): void;
  selectedItem?: ProductItem;
}

type FormData = {
  name: string;
  pax: string;
  code: string;
  status: string;
  location: string;
  area: string;
  floor: string;
};
const ProductItemAddForm: any = ({
  backToItems,
  selectedItem,
}: ProductFormProps) => {
  const { loading, error, data } = useQuery(GET_PRODUCT_ITEM_STATUS);
  const { showToast, Notification } = useToast();
  const params: any = useParams();
  const [sharable, setSharable] = useState<boolean>(true);
  const { data: loadLocation } = useQuery(GET_LOCATIONS);
  const [selectedLocation, setSelectedLocation] = useState<
    string | undefined
  >();
  const [
    addItemMutation,
    { loading: isAdding, error: errorAdding, data: addedItem },
  ] = useMutation(ADD_PRODUCT_ITEM, {
    refetchQueries: [{ query: GET_PRODUCT }, "GetProduct"],
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      name: selectedItem?.name || "",
      pax: selectedItem?.pax || "",
      code: selectedItem?.code || "",
      status: selectedItem?.status.value || "",
      location: selectedItem?.location || "location",
      area: selectedItem?.area || "",
      floor: selectedItem?.floor || "",
    },
  });

  useEffect(() => {
    if (selectedItem) {
      console.log("Item", selectedItem);
      setSharable(selectedItem.sharable !== null && selectedItem.sharable);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (error) {
      showToast("An error has occurred, please refresh the page", "danger");
    }

    if (errorAdding) {
      showToast(
        !selectedItem
          ? "An error has occurred while trying to add this item"
          : "An error has occurred while trying to update this item",
        "danger"
      );
    }
  }, [error, errorAdding]);

  useEffect(() => {
    if (addedItem) {
      showToast(
        !selectedItem
          ? "Successfully added an item"
          : "Successfully updated an item",
        "success"
      );
      if (!selectedItem) reset();
    }
  }, [addedItem]);

  const onSubmit = (data: FormData) => {
    let _item = {
      name: data.name,
      pax: data.pax,
      status_enum: data.status,
      code: data.code,
      product_id: params.id,
      is_sharable: sharable,
      location_id: selectedLocation,
      area: data.area,
      floor: data.floor,
    };

    const _itemToUpdate = {
      id: selectedItem ? selectedItem?.id : "",
      name: data.name,
      pax: data.pax,
      status_enum: data.status,
      code: data.code,
      product_id: params.id,
      is_sharable: sharable,
      location_id: selectedLocation ? selectedLocation : data.location,
      area: data.area,
      floor: data.floor,
    };

    console.log("Product items :", _itemToUpdate);

    addItemMutation({
      variables: {
        item: !selectedItem ? _item : _itemToUpdate,
      },
    });
  };
  if (loading) {
    return <LinearProgress />;
  }

  const productItemStatusList: ProductItemStatus[] = data?.status;
  const listLocations: Location[] = loadLocation?.location;
  console.log("location used ", listLocations);

  return (
    <>
      {Notification}
      <Row>
        <Col md="12" lg="6">
          <Card className="mt-4">
            <CardHeader>
              <Row noGutters={true}>
                <button
                  className="btn btn-outline-primary btn-sm mr-4"
                  onClick={() => backToItems()}
                >
                  <i
                    className="fas fa-angle-left"
                    style={{ fontSize: "14px" }}
                  />
                  <span className="btn-inner-text">Back</span>
                </button>
              </Row>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-lg-6 col-md-8">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="p-member-name"
                      >
                        Name
                      </label>
                      <input
                        {...register("name", { required: true })}
                        className="form-control"
                        id="p-member-name"
                        placeholder="Enter item's name..."
                        type="text"
                      />
                      {errors.name && (
                        <span className="invalid">*This field is required</span>
                      )}
                    </FormGroup>
                  </div>
                  <div className="col-lg-6 col-md-8">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="p-member-name"
                      >
                        Pax
                      </label>
                      <input
                        {...register("pax", { required: true })}
                        className="form-control"
                        id="p-member-name"
                        placeholder="Enter pax number..."
                        type="text"
                      />
                      {errors.pax && (
                        <span className="invalid">*This field is required</span>
                      )}
                    </FormGroup>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="p-member-name"
                      >
                        Code
                      </label>
                      <input
                        {...register("code", { required: true })}
                        className="form-control"
                        id="p-member-name"
                        placeholder="Enter item code..."
                        type="text"
                      />
                      {errors.code && (
                        <span className="invalid">*This field is required</span>
                      )}
                    </FormGroup>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="location">
                        Locations
                      </label>
                      <Select
                        sx={{ borderRadius: "12px", padding: "0" }}
                        id="location"
                        className="form-control"
                        placeholder="Select a location..."
                        defaultValue={
                          selectedItem ? selectedItem?.location : "location"
                        }
                        inputProps={{
                          ...register("location", {
                            required: false,
                          }),
                        }}
                        onChange={(e) => {
                          setSelectedLocation(e.target.value);
                        }}
                      >
                        <MenuItem
                          key="location"
                          value={"location"}
                          disabled={true}
                        >
                          Select a location
                        </MenuItem>
                        {listLocations?.map((location: Location) => {
                          return (
                            <MenuItem key={location.id} value={location.id}>
                              {location.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {/* {errors.location && (
                        <span className="invalid">*This field is required</span>
                      )} */}
                    </FormGroup>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="p-member-name"
                      >
                        Area
                      </label>
                      <input
                        {...register("area", { required: false })}
                        className="form-control"
                        id="p-member-name"
                        placeholder="Enter the area..."
                        type="text"
                      />
                      {/* {errors.area && (
                        <span className="invalid">*This field is required</span>
                      )} */}
                    </FormGroup>
                  </div>{" "}
                  <div className="col-lg-6 col-md-12">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="p-member-name"
                      >
                        Floor
                      </label>
                      <input
                        {...register("floor", { required: false })}
                        className="form-control"
                        id="p-member-name"
                        placeholder="Enter building floor..."
                        type="text"
                      />
                      {/* {errors.floor && (
                        <span className="invalid">*This field is required</span>
                      )} */}
                    </FormGroup>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="status">
                        Status
                      </label>
                      <Select
                        sx={{ borderRadius: "12px", padding: "0" }}
                        id="status"
                        className="form-control"
                        placeholder="Select booking status..."
                        defaultValue={
                          selectedItem ? selectedItem.status.value : ""
                        }
                        inputProps={{
                          ...register("status", {
                            required: true,
                          }),
                        }}
                      >
                        <MenuItem value={""}>None</MenuItem>
                        {productItemStatusList.map((status) => {
                          return (
                            <MenuItem key={status.value} value={status.value}>
                              {status.title}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {errors.status && (
                        <span className="invalid">*This field is required</span>
                      )}
                    </FormGroup>
                  </div>
                  <div className="d-flex h-100">
                    <div className="d-flex h-25 w-25">
                      <input
                        type="checkbox"
                        checked={sharable}
                        onChange={(e) => {
                          setSharable(e.target.checked);
                        }}
                      />
                      <label className="ml-2 mt-2">Sharable</label>
                    </div>
                  </div>
                  <div className="align-self-end ml-auto">
                    <Button
                      type="submit"
                      className="btn btn-dark mt-4 btn-block"
                      disabled={isAdding}
                    >
                      {isAdding ? (
                        <span>
                          <Spinner />
                        </span>
                      ) : (
                        <span>{selectedItem ? "Update item" : "Add item"}</span>
                      )}
                    </Button>
                  </div>
                </div>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ProductItemAddForm;
