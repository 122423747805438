import React, { useEffect } from 'react';
import { LinearProgress, MenuItem, Select } from '@mui/material';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Row, Spinner } from 'reactstrap';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import useToast from '../../../../../hooks/useToast';
import { ADD_MAIL_LIST, GET_MAIL_LISTS, GET_STATUS_LIST } from '../../../../../graphql/mail-list.graphql';
import { Status } from '../../../../../models/newsletter-post.model';
import { MailList } from '../../../../../models/newsletter-emil.model';

interface FormData {
    name: string;
    status: string;
}

//Continue here...

interface MailListDetailsFormProps {
    mailListDetails: MailList;
}

export default function MailListDetailsForm({ mailListDetails }: MailListDetailsFormProps) {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues: {
            name: mailListDetails.name,
            status: mailListDetails.status.value,
        },
    });
    const { data, loading, error } = useQuery(GET_STATUS_LIST);
    const [addMailList, { loading: adding, error: addingError }] = useMutation(ADD_MAIL_LIST, {
        refetchQueries: [GET_MAIL_LISTS],
    });
    const { showToast, Notification } = useToast();

    const params: any = useParams();

    const onSubmit = async (data: FormData) => {
        console.log('Form data', data);

        const mailList = {
            id: params?.id,
            name: data.name,
            status_enum: data.status,
        };

        await addMailList({
            variables: {
                mailList,
            },
        });

        showToast('Successfully updated mail list', 'success');
        reset();
    };

    useEffect(() => {
        if (error) {
            console.log('Error', error);
        }
    }, [error]);

    useEffect(() => {
        if (addingError) {
            showToast('An error has occurred while trying to add a mail list', 'danger');
        }
    }, [addingError]);

    if (loading) {
        return <LinearProgress />;
    }

    const statusList: Status[] = data?.statusList;
    return (
        <>
            {Notification}

            <Row className='mt-4'>
                <Col md='12' lg='6'>
                    <Card>
                        <CardHeader>
                            <Row noGutters={true}>
                                <h3 className='m-0'> Mail List Details</h3>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='title'>
                                                Name
                                            </label>
                                            <input
                                                className='form-control'
                                                {...register('name', { required: true })}
                                                id='title'
                                                placeholder='Enter post title...'
                                                type='text'
                                            />
                                            {errors.name && <span className='invalid'>*This field is required</span>}
                                        </FormGroup>
                                    </div>

                                    <div className='col-lg-12'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='status'>
                                                Status
                                            </label>
                                            <Select
                                                id='status'
                                                sx={{ borderRadius: '12px', padding: '0' }}
                                                className='form-control'
                                                defaultValue={mailListDetails.status.value ?? ' '}
                                                inputProps={{
                                                    ...register('status', {
                                                        required: true,
                                                    }),
                                                }}>
                                                <MenuItem disabled value=' '>
                                                    Select status...
                                                </MenuItem>
                                                {statusList?.map((status: any) => {
                                                    return (
                                                        <MenuItem key={status.value} value={status.value}>
                                                            {status.title}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormGroup>
                                    </div>
                                    {/**Type section */}
                                </div>
                                <div className='d-flex h-100'>
                                    <div className='align-self-end ml-auto'>
                                        <Button type='submit' className='btn btn-dark mt-4 btn-block' disabled={adding}>
                                            {!adding ? (
                                                <span>Update</span>
                                            ) : (
                                                <span>
                                                    <Spinner />
                                                </span>
                                            )}
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
}
