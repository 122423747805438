import { LinearProgress, MenuItem, Select } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import ReactDatetimeClass from 'react-datetime';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Row } from 'reactstrap';
import {
    ADD_MEMBERSHIP_AGREEMENT,
    GET_MEMBERSHIP_AGREEMENT,
    GET_MEMBERSHIP_AGREEMENTS,
    REMOVE_MEMBERSHIP_ITEM,
} from '../../../../graphql/membership-agreemnet.graphql';
import { useMutation, useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import {
    MembershipAgreement,
    MembershipAgreementAddendum,
    MembershipAgreementAddendumStatus,
    MembershipAgreementBillingPeriod,
    MembershipAgreementEscalationPeriod,
    MembershipAgreementItemFormData,
    MembershipAgreementItemStatus,
    MembershipAgreementStatus,
} from '../../../../models/membership-agreement.model';
import Spinner from '../../../../components/Spinner/Spinner';
import useToast from '../../../../hooks/useToast';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import moment, { isMoment } from 'moment';
import { uploadDocument } from '../../../../hooks/upload-documents.hook';
import { useParams } from 'react-router-dom';
import AddMembershipAgreementItemDialog from '../dialogs/AddMembershipAgreementItemDialog';
import { TeamMember } from '../../../../models/team.member.model';
import { ProductPackage } from '../../../../models/packages.models';
import MembershipListItemHeader from './MembershipListItemHeader';
import MembershipListItem from './MembershipListItem';
import AddendumListView from './AddendumListView';
import AddendumForm from './AddendumForm';
type FormData = {
    startDate: string;
    endDate: string;
    status: string;
    escalationPercentage: number;
    escalationDate: string;
    billingPeriod: string;
    escalationPeriod: string;
};

interface MembershipAgreementDetailsFormProps {
    onRedirectBack?: () => void;
    agreementId: string;
}

export default function MembershipAgreementDetailsForm({
    agreementId,
    onRedirectBack,
}: MembershipAgreementDetailsFormProps) {
    const params: any = useParams();
    const { data, error, loading } = useQuery(GET_MEMBERSHIP_AGREEMENT, {
        variables: {
            id: agreementId,
            accountId: params?.id,
        },
    });

    const [removeItem, { error: removedError }] = useMutation(REMOVE_MEMBERSHIP_ITEM, {
        refetchQueries: [GET_MEMBERSHIP_AGREEMENT, GET_MEMBERSHIP_AGREEMENTS],
    });
    const { showToast, Notification } = useToast();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const fileInputRef = useRef<HTMLInputElement>();
    const [selectedItem, setSelectedItem] = useState<MembershipAgreementItemFormData>();
    const [selectedFile, setSelectedFile] = useState<File>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [items, setItems] = useState<MembershipAgreementItemFormData[]>([]);
    const [selectedAddendum, setSelectAddendum] = useState<MembershipAgreementAddendum>();
    const [showAddendumForm, setShowAddendumForm] = useState<boolean>(false);
    const onItemSelect = (item: MembershipAgreementItemFormData) => {
        if (!item || agreement.status.value !== 'draft') return;

        setSelectedItem(item);
        setIsOpen(true);
    };

    useEffect(() => {
        if (removedError) {
            console.log('Remove error', removedError);
        }
    }, [removedError]);

    useEffect(() => {
        if (data) {
            setItems(data?.agreement.items);
        }
    }, [data]);

    const [updateMembershipAgreement, { data: updated, error: updateError }] = useMutation(ADD_MEMBERSHIP_AGREEMENT, {
        refetchQueries: [GET_MEMBERSHIP_AGREEMENT, GET_MEMBERSHIP_AGREEMENTS],
    });

    const openFileExplorer = () => {
        fileInputRef.current.click();
    };

    const onItemRemove = (item: MembershipAgreementItemFormData) => {
        if (agreement.status.value !== 'draft') return;
        const index = items.indexOf(item);

        if (item?.id) {
            removeItem({
                variables: {
                    id: item?.id,
                },
            });
        }
        const newItems = [...items];
        setItems(newItems.filter((i) => newItems.indexOf(i) !== index));
    };

    const handleFileAttach = (e: any) => {
        setSelectedFile(e.target.files[0]);
    };

    const onItemAdd = (item: MembershipAgreementItemFormData) => {
        setItems([...items, item]);
    };

    const onUpdateItem = (items: MembershipAgreementItemFormData[]) => {
        setItems(items);
    };

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<FormData>();

    useEffect(() => {
        if (error) {
            showToast('An error has occurred while trying to load the page', 'danger');
            console.log('Error', error);
        }
    }, [error]);

    useEffect(() => {
        if (updated) {
            showToast('Successfully updated membership agreement details', 'success');
            setIsLoading(false);
        }
    }, [updated]);

    useEffect(() => {
        if (updateError) {
            showToast('An error has occurred while trying to update the membership agreement', 'danger');
            setIsLoading(false);
        }
    }, [updateError]);

    const onSubmit = async (formData: FormData) => {
        let documentKey: string = '';
        const agreement: MembershipAgreement = data?.agreement;

        setIsLoading(true);
        if (selectedFile) documentKey = await uploadDocument(selectedFile, 'membership_agreement');
        if (!selectedFile && agreement.documentPath) documentKey = agreement.documentPath;
        if (documentKey === 'error') {
            showToast('An error has occurred while trying to upload this file', 'danger');
            setIsLoading(false);
            return;
        }

        const agreementData = {
            id: agreementId,
            account_id: params?.id,
            start_date: formData.startDate,
            end_date: formData.endDate,
            status_enum: formData.status,
            escalation_percentage: formData.escalationPercentage / 100,
            billing_period_enum: formData.billingPeriod,
            document_path: documentKey,
            escalation_date: formData.escalationDate,
            escalation_period_enum: formData.escalationPeriod,
            agreement_items: {
                on_conflict: {
                    constraint: 'membership_agreement_item_pkey',
                    update_columns: [
                        'assign_member_id',
                        'balcony_fee',
                        'package_id',
                        'parking_fee',
                        'balcony_fee',
                        'status_enum',
                        'total_fee',
                    ],
                },
                data: items?.map((item) => {
                    return {
                        id: item.id ?? undefined,
                        package_id: item.packageId,
                        assign_member_id: item.assignMemberId ?? undefined,
                        parking_fee: item?.parkingFee ?? 0,
                        balcony_fee: item?.balconyFee ?? 0,
                        total_fee: item?.totalFee ?? 0,
                        status_enum: item?.status ?? '',
                    };
                }),
            },
        };

        await updateMembershipAgreement({
            variables: {
                agreement: agreementData,
            },
        });
    };

    if (loading) {
        return <LinearProgress />;
    }

    const agreement: MembershipAgreement = data?.agreement;
    const billingPeriodList: MembershipAgreementBillingPeriod[] = data?.billingPeriodList;
    const statusList: MembershipAgreementStatus[] = data?.statusList;
    const escalationPeriodLists: MembershipAgreementEscalationPeriod[] = data?.escalationPeriodList;
    const itemStatusList: MembershipAgreementItemStatus[] = data?.membershipItemStatusList;
    const members: TeamMember[] = data?.members;
    const packages: ProductPackage[] = data?.packages;
    const addendumStatusList: MembershipAgreementAddendumStatus[] = data?.addendumStatusList;

    const addendumList: MembershipAgreementAddendum[] = data?.agreement?.agreementAddendums;

    if (showAddendumForm) {
        return (
            <Row className='mt-4'>
                <Col md='12' lg='6'>
                    <AddendumForm
                        addendum={selectedAddendum}
                        agreementId={agreementId}
                        members={members}
                        itemStatusList={statusList}
                        packages={packages}
                        statusList={addendumStatusList}
                        onRedirectBack={() => setShowAddendumForm(false)}
                    />
                </Col>
            </Row>
        );
    }

    return (
        <>
            {Notification}
            {isOpen && (
                <AddMembershipAgreementItemDialog
                    onAddOrUpdateItem={onItemAdd}
                    isOpen={isOpen}
                    members={members}
                    item={selectedItem}
                    onUpdateItem={onUpdateItem}
                    items={items}
                    packages={packages}
                    statusList={itemStatusList}
                    handleClose={() => setIsOpen(false)}
                />
            )}
            <Row className='mt-4'>
                <Col md='12' lg='6'>
                    <Card>
                        <CardHeader>
                            <Row noGutters={true}>
                                <button
                                    className='btn btn-outline-primary btn-sm mr-4'
                                    onClick={() => onRedirectBack()}>
                                    <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                                    <span className='btn-inner-text'>Back</span>
                                </button>

                                <h4 className='m-0'>Membership Agreement Details</h4>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='name'>
                                        Start Date
                                    </label>
                                    <ReactDatetimeClass
                                        inputProps={{
                                            placeholder: 'Select start date...',
                                            ...register('startDate', {
                                                required: true,
                                                value: moment(agreement?.startDate).format('YYYY-MM-DD') ?? '',
                                            }),
                                        }}
                                        initialValue={moment(agreement?.startDate).format('YYYY-MM-DD')}
                                        dateFormat={'YYYY-MM-DD'}
                                        timeFormat={false}
                                        onChange={(value) => {
                                            if (value && isMoment(value)) {
                                                setValue('startDate', value.format('yyyy-MM-DD'));
                                            }
                                        }}
                                    />
                                    {errors.startDate && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='street_address'>
                                        End Date
                                    </label>
                                    <ReactDatetimeClass
                                        inputProps={{
                                            placeholder: 'Select end date...',
                                            ...register('endDate', {
                                                required: true,
                                                value: moment(agreement?.endDate).format('YYYY-MM-DD') ?? '',
                                            }),
                                        }}
                                        initialValue={moment(agreement?.endDate).format('YYYY-MM-DD')}
                                        dateFormat={'YYYY-MM-DD'}
                                        timeFormat={false}
                                        onChange={(value) => {
                                            if (value && isMoment(value)) {
                                                setValue('endDate', value.format('yyyy-MM-DD'));
                                            }
                                        }}
                                    />
                                    {errors.endDate && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='city'>
                                        Escalation Date
                                    </label>
                                    <ReactDatetimeClass
                                        inputProps={{
                                            placeholder: 'Select escalation date...',
                                            ...register('escalationDate', {
                                                required: true,
                                                value: moment(agreement?.escalationDate).format('YYYY-MM-DD') ?? '',
                                            }),
                                        }}
                                        initialValue={moment(agreement?.escalationDate).format('YYYY-MM-DD')}
                                        dateFormat={'YYYY-MM-DD'}
                                        timeFormat={false}
                                        onChange={(value) => {
                                            if (value && isMoment(value)) {
                                                setValue('escalationDate', value.format('yyyy-MM-DD'));
                                            }
                                        }}
                                    />
                                    {errors.escalationDate && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label'>Escalation Period</label>
                                    <Select
                                        className='form-control'
                                        defaultValue={agreement?.escalationPeriod.value ?? ' '}
                                        {...register('escalationPeriod', {
                                            required: true,
                                            value: agreement?.escalationPeriod.value ?? '',
                                        })}
                                        sx={{ borderRadius: '12px', padding: 0 }}>
                                        <MenuItem disabled value={' '}>
                                            {'Select an escalation period...'}
                                        </MenuItem>
                                        {escalationPeriodLists.map((ep) => {
                                            return (
                                                <MenuItem key={ep.value} value={ep.value}>
                                                    {ep.title}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormGroup>

                                <FormGroup>
                                    <label className='form-control-label'>Escalation Percentage</label>
                                    <input
                                        className='form-control'
                                        {...register('escalationPercentage', {
                                            required: true,
                                            value: agreement?.escalationPercentage * 100,
                                        })}
                                        placeholder='Enter escalation percentage...'
                                        type='number'
                                        step={0.01}
                                    />
                                    {errors.escalationPercentage && (
                                        <span className='invalid'>*This field is required</span>
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label'>Billing Period</label>
                                    <Select
                                        className='form-control'
                                        defaultValue={agreement?.billingPeriod.value ?? ' '}
                                        {...register('billingPeriod', {
                                            required: true,
                                            value: agreement?.billingPeriod.value ?? ' ',
                                        })}
                                        sx={{ borderRadius: '12px', padding: 0 }}>
                                        <MenuItem disabled value={' '}>
                                            {'Select a billing period...'}
                                        </MenuItem>
                                        {billingPeriodList.map((bp) => {
                                            return (
                                                <MenuItem key={bp.value} value={bp.value}>
                                                    {bp.title}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label'>Status</label>
                                    <Select
                                        className='form-control'
                                        defaultValue={agreement?.status.value ?? ' '}
                                        {...register('status', {
                                            value: agreement?.status.value ?? '',
                                            required: true,
                                        })}
                                        sx={{ borderRadius: '12px', padding: 0 }}>
                                        <MenuItem disabled value={' '}>
                                            {'Select a status for membership agreement...'}
                                        </MenuItem>
                                        {statusList.map((status) => {
                                            return (
                                                <MenuItem key={status.value} value={status.value}>
                                                    {status.title}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormGroup>

                                <FormGroup>
                                    <Row>
                                        <Col md='12' lg='6'>
                                            <label className='form-control-label'>Items</label>
                                        </Col>
                                        <Col md='12' lg='6' className='text-right'>
                                            <button
                                                className=' btn btn-round btn-dark btn-sm'
                                                color='default'
                                                disabled={agreement.status.value !== 'draft'}
                                                onClick={(e) => {
                                                    e.preventDefault();

                                                    setIsOpen(true);
                                                    setSelectedItem(undefined);
                                                }}
                                                id='tooltip969372949'>
                                                <span className='btn-inner--text'> Add item</span>
                                            </button>
                                        </Col>
                                    </Row>
                                    <Row className='mt-4'>
                                        <Col className='text-center'>{items.length === 0 && <p>No items added</p>}</Col>
                                    </Row>
                                    <Row>
                                        <Col>{items.length > 0 && <MembershipListItemHeader />}</Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {items?.map((item, index) => {
                                                return (
                                                    <MembershipListItem
                                                        packages={packages}
                                                        members={members}
                                                        onItemSelect={onItemSelect}
                                                        onItemRemove={onItemRemove}
                                                        item={item}
                                                        key={index}
                                                    />
                                                );
                                            })}
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row>
                                        <Col>
                                            <span
                                                className=' mt-4 btn btn-round btn-dark btn-sm'
                                                color='default'
                                                onClick={openFileExplorer}
                                                id='tooltip969372949'>
                                                <span className='btn-inner--text'> Attach file</span>
                                            </span>
                                            <input
                                                onChange={handleFileAttach}
                                                style={{ display: 'none' }}
                                                multiple
                                                accept='.pdf, .docx, .xlsx'
                                                type='file'
                                                ref={fileInputRef}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='mt-4'>
                                        <Col>
                                            {(selectedFile || agreement?.documentPath) && (
                                                <InsertDriveFileOutlinedIcon fontSize={'large'} />
                                            )}
                                            <p>
                                                {selectedFile?.name?.substring(0, 6) ??
                                                    agreement?.documentPath.substring(0, 6)}
                                            </p>
                                        </Col>
                                    </Row>
                                </FormGroup>

                                <div className='d-flex h-100'>
                                    <div className='align-self-end ml-auto'>
                                        <Button
                                            type='submit'
                                            className='btn btn-dark mt-4 btn-block'
                                            disabled={isLoading}>
                                            {isLoading && (
                                                <span>
                                                    <Spinner />
                                                </span>
                                            )}
                                            {!isLoading && <span>Update</span>}
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
                <Col md='12' lg='6'>
                    <AddendumListView
                        addendumList={addendumList}
                        getSelectedAddendum={setSelectAddendum}
                        showAddendumForm={setShowAddendumForm}
                    />
                </Col>
            </Row>
        </>
    );
}
