/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Label,
  Row,
  Table,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import useToast from "../../../hooks/useToast";
import Spinner from "../../../components/Spinner/Spinner";
import { LinearProgress, MenuItem, Select } from "@mui/material";
import {
  GET_MEETING_ROOM,
  REMOVE_ROOM_DISCOUNT,
  UPDATE_MEETING_ROOM,
} from "../../../graphql/meeting-rooms.graphql";
import {
  DiscountType,
  MeetingRoom,
  MeetingRoomConfiguration,
  RateType,
  RoomCheckListItem,
  RoomDiscount,
  RoomRate,
  RoomStatus,
} from "../../../models/meeting.room.model";
import { Location } from "../../../models/location.model";
import { useParams } from "react-router-dom";
import FeaturedImage from "../../../components/Image component/ImageContainer";
import { uploadImage } from "../../../hooks/upload-image.hook";
import SweetAlert from "react-bootstrap-sweetalert";
import { AddRoomDiscountDialog } from "./dialogs/AddRoomDiscountDialog";
import { MeetingRoomRates } from "./components/MeetingRoomRates";
import MeetingRoomConfigurationView from "./components/MeetingRoomConfigurationView";
import MeetingRoomCheckListView from "./components/meeting room checklist/MeetingRoomcheckListItemList";

type FormData = {
  name: string;
  description: string;
  capacity: string;
  location: string;
  status: string;
};

interface MeetingRoomViewProps {
  room: MeetingRoom;
}

const MeetingRoomDetails = ({ room }: MeetingRoomViewProps) => {
  const params: any = useParams();
  const [image, setImage] = useState<string>(
    `${process.env.REACT_APP_IMAGE_URL}/${room?.featuredImage}`
  );
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [imageFile, setImageFile] = useState<any>(null);
  const [alert, setAlert] = useState<any>(null);

  const [openAddDiscount, setOpenAddDiscount] = useState(false);
  const [selectedDiscount, setSelectedDiscount] = useState<RoomDiscount | null>(
    null
  );
  const [isConfigurable, setIsConfigurable] = useState<boolean>(false);

  const fileRef: any = useRef(null);
  const {
    loading: loadingRoom,
    error: errorLoadingRoom,
    data,
  } = useQuery(GET_MEETING_ROOM, {
    variables: {
      id: params.id,
    },
  });
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>();
  const { showToast, Notification } = useToast();
  const [updateRoomMutation, { error: errorUpdating, data: updated }] =
    useMutation(UPDATE_MEETING_ROOM, {
      refetchQueries: [{ query: GET_MEETING_ROOM }, "GetMeetingRoom"],
    });

  const [
    removeDiscount,
    {
      error: errorRemovingDiscount,
      called: calledRemoveDiscount,
      loading: isRemovingDicount,
      reset: resetRemovingDicount,
    },
  ] = useMutation(REMOVE_ROOM_DISCOUNT, {
    refetchQueries: [{ query: GET_MEETING_ROOM }, "GetMeetingRoom"],
  });

  useEffect(() => {
    if (calledRemoveDiscount && !isRemovingDicount) {
      resetRemovingDicount();
      showToast("Successfully remved discount", "success");
    }
    if (calledRemoveDiscount && errorRemovingDiscount) {
      showToast("An error has occurred, please try again", "danger");
    }

    if (data) {
      setIsConfigurable(data.room.isConfigurable);
    }
  }, [errorRemovingDiscount, calledRemoveDiscount, isRemovingDicount, data]);

  useEffect(() => {
    register("description", {
      required: true,
      value: data?.room.description,
    });
  }, []);

  useEffect(() => {
    register("description", { required: true });
  });

  useEffect(() => {
    if (errorLoadingRoom) {
      showToast("An error has occurred, please try again", "danger");
      setIsUpdating(false);
    }

    if (errorUpdating) {
      showToast(
        "An error has occurred while trying to update the details",
        "danger"
      );
      setIsUpdating(false);
    }
  }, [errorUpdating, errorLoadingRoom]);

  useEffect(() => {
    if (updated && !errorLoadingRoom) {
      showToast("Successfully updated meeting room's details", "success");
      setIsUpdating(false);
    }
  }, [updated]);

  const editDiscount = (discount: RoomDiscount) => {
    setSelectedDiscount(discount);
    setOpenAddDiscount(true);
  };

  const displayRemoveDiscountAlert = (props: any) => {
    setAlert(
      <SweetAlert
        {...props}
        showCancel
        confirmBtnText="Continue"
        confirmBtnBsStyle="danger"
        title="Are you sure? This action cannot be undone"
        onConfirm={() => {
          setAlert(null);
          removeDiscount({
            variables: {
              dicountId: props.discount.id,
            },
          });
        }}
        onCancel={() => {
          setAlert(null);
        }}
        focusCancelBtn
      >
        This room discount will be removed from the system.
      </SweetAlert>
    );
  };

  const onSubmit = async (formData: FormData) => {
    let imageKey;

    setIsUpdating(true);

    if (imageFile)
      imageKey = await uploadImage(imageFile, "meeting_rooms_images");
    if (!imageFile && room.featuredImage) imageKey = room.featuredImage;

    if (imageKey === "error") {
      showToast(
        "An error has occurred while trying to upload the image",
        "danger"
      );
      setIsUpdating(false);
      return;
    }

    const roomMeeting = {
      name: formData.name,
      description: formData.description,
      capacity: formData.capacity,
      status_enum: formData.status,
      location_id: formData.location,
      featured_image: imageKey,
      is_configurable: isConfigurable,
    };

    updateRoomMutation({
      variables: {
        id: params.id,
        room: roomMeeting,
      },
    });
  };

  const onImageChange = (e: any) => {
    const imageUrl = URL.createObjectURL(e.target.files[0]);
    setImageFile(e.target.files[0]);
    setImage(imageUrl);
  };

  if (loadingRoom) {
    return <LinearProgress />;
  }

  const discounts: RoomDiscount[] = data?.room?.discounts || [];
  const rates: RoomRate[] = data?.room?.rates || [];
  const locations: Location[] = data?.location || [];
  const roomStatuses: RoomStatus[] = data?.roomStatus;
  const rateTypes: RateType[] = data.types;
  const discountTypes: DiscountType[] = data.discountTypes;
  const checkListItems: RoomCheckListItem[] = data?.room?.checkListItems || [];

  const configurations: MeetingRoomConfiguration[] =
    data?.room.meetingRoomConfiguration;

  return (
    <>
      {Notification}
      {alert}
      {
        <AddRoomDiscountDialog
          isOpen={openAddDiscount}
          discountTypes={discountTypes}
          roomId={room.id}
          discount={selectedDiscount}
          toggleOpen={(isOpen) => {
            setOpenAddDiscount(isOpen);
          }}
        />
      }
      <Row className="mt-4">
        <Col md="12" lg="6">
          <Card>
            <CardBody>
              <h3 className="mb-3">Room Information</h3>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                  <label className="form-control-label" htmlFor="name">
                    Featured Image
                  </label>
                  <FeaturedImage imageSource={image} fileInputRef={fileRef} />
                  <input
                    onChange={(e) => onImageChange(e)}
                    type="file"
                    accept="image/*"
                    ref={fileRef}
                    style={{ display: "none" }}
                  />
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="name">
                    Name
                  </label>
                  <input
                    className="form-control"
                    {...register("name", { required: true, value: room.name })}
                    id="company-name"
                    placeholder="Enter meeting room's name..."
                    type="text"
                  />
                  {errors.name && (
                    <span className="invalid">*This field is required</span>
                  )}
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="name">
                    Description
                  </label>
                  <CKEditor
                    id="description"
                    data={room.description}
                    editor={ClassicEditor}
                    config={{
                      removePlugins: ["Heading"],
                      toolbar: [
                        "bold",
                        "italic",
                        "bulletedList",
                        "numberedList",
                        "blockQuote",
                        "link",
                      ],
                    }}
                    onChange={(event: any, editor: any) => {
                      setValue("description", editor.getData());
                    }}
                    onReady={(editor: any) => {}}
                  />
                  {errors.description && (
                    <span className="invalid">*This field is required</span>
                  )}
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="email">
                    Capacity
                  </label>
                  <input
                    className="form-control"
                    {...register("capacity", {
                      required: true,
                      value: room.capacity,
                    })}
                    id="email"
                    placeholder="Enter meeting room's capacity..."
                    type="text"
                  />
                  {errors.capacity && (
                    <span className="invalid">*This field is required</span>
                  )}
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="contact">
                    Status
                  </label>
                  <Select
                    id="package"
                    className="form-control"
                    sx={{ borderRadius: "12px", padding: "0" }}
                    defaultValue={room.status.value}
                    inputProps={{
                      ...register("status", {
                        required: true,
                        value: room.status.value,
                      }),
                    }}
                  >
                    {roomStatuses.map((status: RoomStatus) => {
                      return (
                        <MenuItem key={status.value} value={status.value}>
                          {status.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {errors.status && (
                    <span className="invalid">*This field is required</span>
                  )}
                </FormGroup>
                <FormGroup>
                  <label className="form-control-label" htmlFor="package">
                    Locations
                  </label>
                  <Select
                    id="package"
                    className="form-control"
                    sx={{ borderRadius: "12px", padding: "0" }}
                    defaultValue={room.location.id}
                    inputProps={{
                      ...register("location", {
                        required: true,
                        value: room.location.id,
                      }),
                    }}
                  >
                    {locations.map((location: Location) => {
                      return (
                        <MenuItem key={location.id} value={location.id}>
                          {location.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {errors.location && (
                    <span className="invalid">*This field is required</span>
                  )}
                </FormGroup>
                <FormGroup check inline>
                  <input
                    type="checkbox"
                    className="mr-2"
                    checked={isConfigurable}
                    onChange={() => setIsConfigurable(!isConfigurable)}
                  />
                  <Label check>Configurable</Label>
                </FormGroup>
                <div className="d-flex h-100">
                  <div className="align-self-end ml-auto">
                    <Button
                      type="submit"
                      className="btn btn-dark mt-4 btn-block"
                      disabled={isUpdating}
                    >
                      {isUpdating ? (
                        <span>
                          <Spinner />
                        </span>
                      ) : (
                        <span>Update details</span>
                      )}
                    </Button>
                  </div>
                </div>
              </form>
            </CardBody>
          </Card>
        </Col>
        <Col md="12" lg="6">
          <MeetingRoomRates
            roomId={room.id}
            rates={rates}
            rateTypes={rateTypes}
          />
          <Card>
            <CardBody>
              <div className="row no-gutters justify-content-between align-items-center mb-3">
                <h3 className="mb-0">Room Discounts</h3>
                <button
                  className="btn btn-dark btn-sm"
                  onClick={() => {
                    setSelectedDiscount(null);
                    setOpenAddDiscount(true);
                  }}
                >
                  Add discount
                </button>
              </div>
              {discounts.length === 0 && !loadingRoom && (
                <h5>No discounts added for this room</h5>
              )}
              {discounts.length > 0 && (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Percentage</th>
                      <th scope="col">Type</th>

                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody className="list">
                    {discounts.map((discount) => {
                      return (
                        <tr
                          key={discount.id}
                          style={{ cursor: "pointer" }}
                          onClick={() => editDiscount(discount)}
                        >
                          <td>{discount.name}</td>
                          <td>{`${discount.percentage * 100}%`}</td>
                          <td>{discount.type.title}</td>

                          <td className="text-right">
                            <Button
                              className="btn btn-info btn-icon-only rounded-circle btn-sm"
                              onClick={() => {
                                editDiscount(discount);
                              }}
                            >
                              <i className="fa-solid fa-pencil"></i>
                            </Button>
                            <Button
                              className="btn btn-danger btn-icon-only rounded-circle btn-sm"
                              onClick={(e) => {
                                e.stopPropagation();
                                displayRemoveDiscountAlert({
                                  warning: true,
                                  discount,
                                });
                              }}
                            >
                              <i className="fa-regular fa-trash-can"></i>
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}
            </CardBody>
          </Card>
          {data.room.isConfigurable && (
            <MeetingRoomConfigurationView configurations={configurations} />
          )}
          <MeetingRoomCheckListView checkListItems={checkListItems} />
        </Col>
      </Row>
    </>
  );
};

export default MeetingRoomDetails;
