import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
// import { useForm } from 'react-hook-form';
import { Input } from 'reactstrap';
import { EmailTemplate } from '../../../../../models/newsletter-emil.model';
import { useMutation } from '@apollo/client';
import { ADD_EMAIL_TEMPLATE, GET_EMAIL_TEMPLATES } from '../../../../../graphql/newsletter-email.graphql';
import useToast from '../../../../../hooks/useToast';
import { useNavigate } from 'react-router-dom';

interface EmailTemplateAddDialogProps {
    isOpen: boolean;
    handleClose: () => void;
    selectTemplate?: EmailTemplate;
}

export default function EmailTemplateAddDialog({ isOpen, handleClose, selectTemplate }: EmailTemplateAddDialogProps) {
    const [templateName, setTemplateName] = useState<string>(selectTemplate?.name ?? '');

    const [addEmailTemplate, { data, error, loading }] = useMutation(ADD_EMAIL_TEMPLATE, {
        refetchQueries: [GET_EMAIL_TEMPLATES],
    });

    const navigate = useNavigate();

    const { showToast, Notification } = useToast();

    useEffect(() => {
        if (error) {
            console.log('Error', error);
            showToast('An error has ocurred while trying to add a template', 'danger');
        }
    }, [error]);

    useEffect(() => {
        if (data) {
            if (!selectTemplate) {
                navigate(`/admin/templates/manage/${data?.template?.id}`);
                return;
            }
        }
    }, [data]);

    const addTemplate = async () => {
        if (templateName === '') return;

        const template = {
            id: selectTemplate?.id,
            name: templateName,
        };
        await addEmailTemplate({
            variables: {
                template,
            },
        });
    };

    return (
        <>
            {Notification}
            <Dialog open={isOpen} onClose={handleClose}>
                <DialogTitle>
                    {!selectTemplate && <h2>Add Template</h2>}
                    {selectTemplate && <h2>Update Template</h2>}
                </DialogTitle>
                <DialogContent>
                    <FormGroup>
                        <label>Name</label>
                        <Input
                            value={templateName}
                            onChange={(e) => setTemplateName(e.target.value)}
                            style={{ width: '500px' }}
                            placeholder='Enter template name'
                            type='text'
                        />
                    </FormGroup>
                </DialogContent>
                <DialogActions>
                    <Button type='submit' disabled={loading} onClick={addTemplate}>
                        {!loading && (selectTemplate ? 'Update' : 'Add')}
                        {loading && <CircularProgress size={16} />}
                    </Button>
                    <Button onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
