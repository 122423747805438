/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from '@apollo/client';
import { LinearProgress } from '@mui/material';
import React, { useState, useEffect } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
    Card,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Button,
    Row,
    Table,
    UncontrolledTooltip,
    Pagination,
    PaginationItem,
    PaginationLink,
    Input,
} from 'reactstrap';
import BasicMenu from '../../../components/Menu';
import { Location } from '../../../models/location.model';
import { User } from '../../../models/user.model';
import { getPermission } from '../../../utils/user.utils';
import _ from 'lodash';
import useToast from '../../../hooks/useToast';
import { GET_LOCATIONS, REMOVE_LOCATION } from '../../../graphql/locations.graphql';
import { useLocalStorage } from '../../../hooks/local-storage.hook';

interface LocationRouteData {
    keyWord: string;
    page: number;
    pageSize: number;
}

export default function LocationView() {
    const { showToast, Notification } = useToast();
    const [alert, setAlert] = useState<any>(null);
    const { loading, data, error } = useQuery(GET_LOCATIONS);
    const user: User | null = useSelector((state: any) => state.user.userInfo);
    const [getLocalStorage, setLocalStorage] = useLocalStorage('location');
    const [page, setPage] = useState<number>(0);
    const [searchKey, setSearchKey] = useState<string>('');
    const [rowPerPage, setRowPerPage] = useState<number>(10);
    const [sortFiled, setSortField] = useState<string>('name');
    const [sortDirection, setSortDirection] = useState<string>('desc');
    const [removeLocationMutation, { error: removeError, data: removedLocation }] = useMutation(REMOVE_LOCATION, {
        refetchQueries: [{ query: GET_LOCATIONS }, 'GetLocation'],
    });
    const removeLocation = (id: string) => {
        removeLocationMutation({
            variables: { id },
        });
    };

    const navigate = useNavigate();

    useEffect(() => {
        const routeData: LocationRouteData = getLocalStorage();

        if (routeData) {
            setPage(routeData?.page || 0);
            setRowPerPage(routeData?.pageSize || 10);
            setSearchKey(routeData?.keyWord || '');
        }
    }, []);

    const nextPage = () => {
        setPage(page + 1);
        const routeData: LocationRouteData = getLocalStorage() || {};
        routeData.page = page + 1;
        setLocalStorage(routeData);
    };

    const previousPage = () => {
        setPage(page - 1);
        const routeData: LocationRouteData = getLocalStorage() || {};
        routeData.page = page - 1;
        setLocalStorage(routeData);
    };

    const getRowValue = (value: string) => {
        setRowPerPage(parseInt(value));
        const routeData: LocationRouteData = getLocalStorage() || {};
        routeData.pageSize = parseInt(value);
        setLocalStorage(routeData);
    };

    useEffect(() => {
        if (error) {
            showToast('An error has occurred, please refresh the page', 'danger');
        }

        if (removeError) {
            showToast('An error has occurred while trying to remove a location', 'danger');
        }
    }, [error, removeError]);

    useEffect(() => {
        if (removedLocation) {
            showToast('Successfully removed location', 'success');
        }
    }, [removedLocation]);

    const sortBy: any = (locations: Location[], field: string, direction: string) => {
        const orderedBookings = new Map([
            ['name', _.orderBy(locations, (location) => location.name, [direction === 'asc' ? 'asc' : 'desc'])],
            [
                'streetAddress',
                _.orderBy(locations, (location) => location.street_address, [direction === 'asc' ? 'asc' : 'desc']),
            ],
            ['city', _.orderBy(locations, (location) => location.city, [direction === 'asc' ? 'asc' : 'desc'])],
            ['country', _.orderBy(locations, (location) => location.country, [direction === 'asc' ? 'asc' : 'desc'])],
            [
                'postalCode',
                _.orderBy(locations, (location) => location.postal_code, [direction === 'asc' ? 'asc' : 'desc']),
            ],
        ]);
        return orderedBookings.get(field);
    };

    const locations: Location[] = data?.location || [];
    let filteredLocations = locations.filter((location) => {
        if (searchKey === '') {
            return location;
        }

        return (
            location.name.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            location.street_address.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            location.city.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            location.country.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            location.postal_code.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase())
        );
    });

    filteredLocations = sortBy(filteredLocations, sortFiled, sortDirection);

    const displayAlert = (props: any) => {
        setAlert(
            <SweetAlert
                {...props}
                showCancel
                confirmBtnText='Continue'
                confirmBtnBsStyle='danger'
                title='Are you sure?'
                onConfirm={() => {
                    removeLocation(props.location.id);
                    setAlert(null);
                }}
                onCancel={() => {
                    setAlert(null);
                }}
                focusCancelBtn>
                This location will be removed from the system.
            </SweetAlert>,
        );
    };

    if (loading) {
        return <LinearProgress />;
    }

    return (
        <>
            {alert}
            {Notification}
            <div>
                <Container className='mt-4' fluid>
                    <Row>
                        <Col>
                            <h1>Locations</h1>
                        </Col>
                    </Row>
                    <Row className='mt-4'>
                        <div className='col'>
                            <Card>
                                <CardHeader className='border-0'>
                                    <Row>
                                        <Col xs='6'>
                                            <h3 className='mb-0'>Locations</h3>
                                        </Col>
                                        {getPermission(user, 'locations', 'create') && (
                                            <Col className='text-right' xs='6'>
                                                <Link
                                                    to={'/admin/locations/new'}
                                                    className='btn btn-round btn-dark btn-sm'
                                                    color='default'
                                                    id='tooltip969372949'>
                                                    <span className='btn-inner--text'>Add location</span>
                                                </Link>
                                                <UncontrolledTooltip delay={0} target='tooltip969372949'>
                                                    Add location
                                                </UncontrolledTooltip>
                                            </Col>
                                        )}
                                    </Row>
                                    <Row className='mt-4'>
                                        <Col>
                                            <Input
                                                className='w-25'
                                                value={searchKey}
                                                placeholder='Search keyword...'
                                                onChange={(e) => {
                                                    setSearchKey(e.target.value);
                                                    setPage(0);

                                                    const routeData: LocationRouteData = getLocalStorage() || {};
                                                    routeData.keyWord = e.target.value;
                                                    routeData.page = 0;
                                                    setLocalStorage(routeData);
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <Table className='align-items-center table-flush' responsive>
                                    <thead className='thead-light'>
                                        <tr>
                                            <th
                                                onClick={() => {
                                                    setSortField('name');
                                                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                                }}
                                                className='sort'
                                                data-sort='name'
                                                scope='col'>
                                                Name
                                            </th>
                                            <th
                                                onClick={() => {
                                                    setSortField('streetAddress');
                                                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                                }}
                                                className='sort'
                                                data-sort='streetAddress'
                                                scope='col'>
                                                Street Address
                                            </th>
                                            <th
                                                onClick={() => {
                                                    setSortField('city');
                                                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                                }}
                                                className='sort'
                                                data-sort='city'
                                                scope='col'>
                                                City
                                            </th>
                                            <th
                                                onClick={() => {
                                                    setSortField('country');
                                                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                                }}
                                                className='sort'
                                                data-sort='country'
                                                scope='col'>
                                                Country
                                            </th>
                                            <th
                                                onClick={() => {
                                                    setSortField('postalCode');
                                                    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                                }}
                                                className='sort'
                                                data-sort='postalCode'
                                                scope='col'>
                                                Postal Code
                                            </th>
                                            <th scope='col'></th>
                                        </tr>
                                    </thead>
                                    <tbody className='list'>
                                        {filteredLocations
                                            .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                                            .map((location: any) => {
                                                return (
                                                    <tr
                                                        key={location.id}
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() =>
                                                            navigate(`/admin/locations/edit/${location.id}`)
                                                        }>
                                                        <td>{`${location.name}`}</td>
                                                        <td>{location.street_address}</td>
                                                        <td>{location.city}</td>
                                                        <td>{location.country}</td>
                                                        <td>{location.postal_code}</td>
                                                        <td className='text-right'>
                                                            {getPermission(user, 'locations', 'update') && (
                                                                <Link
                                                                    className='btn btn-info btn-icon-only rounded-circle btn-sm'
                                                                    to={`/admin/locations/edit/${location.id}`}>
                                                                    <i className='fa-solid fa-pencil'></i>
                                                                </Link>
                                                            )}
                                                            {getPermission(user, 'locations', 'delete') && (
                                                                <Button
                                                                    className='btn btn-danger btn-icon-only rounded-circle btn-sm'
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        displayAlert({ warning: true, location });
                                                                    }}>
                                                                    <i className='fa-solid fa-trash-can'></i>
                                                                </Button>
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </Table>
                                <CardFooter className='py-4'>
                                    <Row>
                                        <div className='col-4 d-flex'>
                                            <span style={{ fontSize: '12px' }}>Rows per page:</span>
                                            <BasicMenu
                                                getValue={getRowValue}
                                                options={['10', '20', '100']}
                                                value={rowPerPage.toString()}
                                            />
                                        </div>

                                        <div className='col-4 d-flex'>
                                            <span className='min-text'>Page:</span>
                                            <span className='min-text pl-2'>
                                                {page + 1} of {Math.ceil(filteredLocations.length / rowPerPage)}
                                            </span>
                                        </div>
                                        <div className='col-4 d-flex'>
                                            <Pagination>
                                                <PaginationItem className={page <= 0 ? 'disabled' : ''}>
                                                    <PaginationLink onClick={(e) => previousPage()}>
                                                        <i className='fas fa-angle-left' />
                                                        <span className='sr-only'>Previous</span>
                                                    </PaginationLink>
                                                </PaginationItem>
                                                <PaginationItem
                                                    className={
                                                        page >= Math.ceil(filteredLocations.length / rowPerPage) - 1
                                                            ? 'disabled'
                                                            : ''
                                                    }>
                                                    <PaginationLink onClick={(e) => nextPage()}>
                                                        <i className='fas fa-angle-right' />
                                                        <span className='sr-only'>Previous</span>
                                                    </PaginationLink>
                                                </PaginationItem>
                                            </Pagination>
                                        </div>
                                    </Row>
                                </CardFooter>
                            </Card>
                        </div>
                    </Row>
                </Container>
            </div>
        </>
    );
}
