/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Table } from "reactstrap";
import AddItemConfigurationDialog from "../dialogs/AddItemConfigurationDialog";
import SweetAlert from "react-bootstrap-sweetalert";
import { useMutation } from "@apollo/client";
import {
  GET_MEETING_ROOM,
  REMOVE_ASSIGNED_CONFIGURATION,
} from "../../../../graphql/meeting-rooms.graphql";
import useToast from "../../../../hooks/useToast";
import { RoomItemConfiguration } from "../../../../models/meeting.room.model";

interface MeetingRoomConfigurationProp {
  roomItemConfigurations: RoomItemConfiguration[];
  itemId: string;
}

const MeetingRoomItemConfigView = ({
  roomItemConfigurations,
  itemId,
}: MeetingRoomConfigurationProp) => {
  const [itemDialogOpened, setItemDialogOpened] = useState<boolean>(false);
  const [alert, setAlert] = useState<any>(null);
  const { Notification, showToast } = useToast();

  const [deleteConfiguration, { data: addedConfig, error: errorRemoving }] =
    useMutation(REMOVE_ASSIGNED_CONFIGURATION, {
      refetchQueries: [{ query: GET_MEETING_ROOM }, "GetMeetingRoom"],
    });

  useEffect(() => {
    if (errorRemoving) {
      showToast(
        "An error has occurred while trying to remove this configuration",
        "danger"
      );
    }

    if (addedConfig) {
      showToast("Successfully removed configuration for this room", "success");
    }

    if (alert) {
      console.log("Alert data : ", alert);
    }
  }, [alert, addedConfig, errorRemoving]);

  const displayRemoveRoomConfigurations = (props: any) => {
    setAlert(
      <SweetAlert
        {...props}
        showCancel
        confirmBtnText="Continue"
        confirmBtnBsStyle="danger"
        title="Are you sure? This action cannot be undone"
        onConfirm={() => {
          setAlert(null);
          deleteConfiguration({
            variables: {
              id: props.id,
            },
          });
        }}
        onCancel={() => {
          setAlert(null);
        }}
        focusCancelBtn
      >
        This room configuration will be removed from the system.
      </SweetAlert>
    );
  };

  return (
    <>
      {
        <AddItemConfigurationDialog
          isOpen={itemDialogOpened}
          itemId={itemId}
          roomItemConfigurations={roomItemConfigurations}
          handleClose={() => setItemDialogOpened(false)}
        />
      }
      {Notification}
      {alert}
      <Card>
        <CardBody>
          <div className="row no-gutters justify-content-between align-items-center mb-3">
            <h3 className="mb-0">Room Configurations</h3>
            <button
              className="btn btn-dark btn-sm"
              onClick={() => {
                setItemDialogOpened(true);
              }}
            >
              Add
            </button>
          </div>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Status</th>
                <th scope="col" />
              </tr>
            </thead>
            <tbody className="list">
              {roomItemConfigurations &&
                roomItemConfigurations.map((element) => {
                  return (
                    <tr key={element?.id}>
                      <td>{element?.configurations?.name}</td>
                      <td>{element?.configurations?.status?.title}</td>
                      <td className="text-right">
                        <Button
                          className="btn btn-danger btn-icon-only rounded-circle btn-sm"
                          onClick={() => {
                            displayRemoveRoomConfigurations(element);
                          }}
                        >
                          <i className="fa-regular fa-trash-can"></i>
                        </Button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </>
  );
};

export default MeetingRoomItemConfigView;
