/* eslint-disable react-hooks/exhaustive-deps */
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Row,
} from "reactstrap";
import { useForm } from "react-hook-form";
import useToast from "../../../hooks/useToast";
import { useQuery, useMutation } from "@apollo/client";
import {
  GET_MEETING_ROOM_STATUS,
  GET_MEETING_ROOM,
} from "../../../graphql/meeting-rooms.graphql";
import { GET_LOCATIONS } from "../../../graphql/locations.graphql";
import { ADD_MEETING_ROOM_ITEM } from "../../../graphql/meeting-rooms.graphql";
import { useParams } from "react-router-dom";
import Spinner from "../../../components/Spinner/Spinner";
import LinearProgress from "@mui/material/LinearProgress";
import { Location } from "../../../models/location.model";
import { RoomStatus } from "../../../models/meeting.room.model";
import FeaturedImage from "../../../components/Image component/ImageContainer";
import { uploadImage } from "../../../hooks/upload-image.hook";

type FormData = {
  name: string;
  location: string;
  status: string;
};

const MeetingRoomItemForm = ({ backToItemList }: any) => {
  const { showToast, Notification } = useToast();
  const param: any = useParams();
  const {
    loading: loadingLocations,
    error,
    data: loadedLocations,
  } = useQuery(GET_LOCATIONS);

  const {
    loading: loadingStatus,
    error: statusError,
    data: loadedStatus,
  } = useQuery(GET_MEETING_ROOM_STATUS);
  const [imageFile, setImageFile] = useState<any>();
  const [image, setImage] = useState<string>();
  const fileRef: any = useRef(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [addItemMutation, { error: errorAdding, data: loadedItem }] =
    useMutation(ADD_MEETING_ROOM_ITEM, {
      refetchQueries: [{ query: GET_MEETING_ROOM }, "GetMeetingRoom"],
    });
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmit = async (data: FormData) => {
    setIsLoading(true);
    if (getValues("location") === "empty") {
      setValue("location", "");
    }

    if (getValues("status") === "empty") {
      setValue("status", "");
    }

    if (!imageFile) {
      showToast("Please make sure that an image file is selected", "danger");
      setIsLoading(false);
      return;
    }

    const imageKey = await uploadImage(imageFile, "meeting_rooms_images");
    if (imageKey === "error") {
      showToast(
        "An error has occurred while trying to upload this image, please try again",
        "danger"
      );
      setIsLoading(false);
      return;
    }

    const _item = {
      name: data.name,
      status_enum: data.status,
      location_id: data.location,
      meeting_room_id: param.id,
      featured_image: imageKey,
    };

    addItemMutation({
      variables: {
        item: _item,
      },
    });
  };

  useEffect(() => {
    if (error || statusError) {
      showToast("An error has occurred, please refresh the page", "danger");
    }
  }, [error, errorAdding, statusError]);

  useEffect(() => {
    if (loadedItem) {
      showToast("Successfully add an item", "success");
      reset();
      setIsLoading(false);
    }
  }, [loadedItem]);

  const onImageChange = (e: any) => {
    const imageUrl = URL.createObjectURL(e.target.files[0]);
    setImageFile(e.target.files[0]);
    setImage(imageUrl);
  };

  if (loadingLocations || loadingStatus) {
    return <LinearProgress />;
  }

  const locations: Location[] = loadedLocations?.location;
  const meetingRoomStatuses: RoomStatus[] = loadedStatus?.roomStatus;

  return (
    <Row>
      {Notification}
      <Col md="12" lg="6">
        <Card className="mt-4">
          <CardHeader>
            <Row noGutters={true}>
              <button
                className="btn btn-outline-primary btn-sm mr-4"
                onClick={() => backToItemList()}
              >
                <i className="fas fa-angle-left" style={{ fontSize: "14px" }} />
                <span className="btn-inner-text">Back</span>
              </button>
            </Row>
          </CardHeader>
          <CardBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormGroup>
                <label className="form-control-label" htmlFor="name">
                  Featured Image
                </label>
                <FeaturedImage imageSource={image} fileInputRef={fileRef} />
                <input
                  onChange={(e) => onImageChange(e)}
                  type="file"
                  accept="image/*"
                  ref={fileRef}
                  style={{ display: "none" }}
                />
              </FormGroup>
              <FormGroup>
                <label className="form-control-label" htmlFor="p-member-name">
                  Name
                </label>
                <input
                  className="form-control"
                  {...register("name", {
                    required: true,
                  })}
                  id="p-member-name"
                  placeholder="Enter items's subject..."
                  type="text"
                />
                {errors.name && (
                  <span className="invalid">*This field is required</span>
                )}
              </FormGroup>
              <FormGroup>
                <label className="form-control-label" htmlFor="status">
                  Location
                </label>
                <Select
                  sx={{ borderRadius: "12px", padding: "0" }}
                  id="status"
                  defaultValue={"empty"}
                  {...register("location", { required: true, value: "empty" })}
                  className="form-control"
                  placeholder="Select booking status..."
                >
                  <MenuItem key={"-1"} disabled value={"empty"}>
                    Select booking status...
                  </MenuItem>
                  {locations.map((location) => {
                    return (
                      <MenuItem key={location.id} value={location.id}>
                        {location.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                {errors.location && (
                  <span className="invalid">*This field is required</span>
                )}
              </FormGroup>
              <FormGroup>
                <label className="form-control-label">Status</label>
                <Select
                  id="status"
                  sx={{ borderRadius: "12px", padding: "0" }}
                  defaultValue={"active"}
                  className="form-control"
                  {...register("status", {
                    required: true,
                    value: "active",
                  })}
                >
                  <MenuItem key={"-2"} disabled value={"empty"}>
                    Select room's status...
                  </MenuItem>
                  {meetingRoomStatuses.map((status) => {
                    return (
                      <MenuItem key={status.value} value={status.value}>
                        {status.title}
                      </MenuItem>
                    );
                  })}
                </Select>
                {errors.status && (
                  <span className="invalid">*This field is required</span>
                )}
              </FormGroup>
              <div className="d-flex h-100">
                <div className="align-self-end ml-auto">
                  <Button
                    type="submit"
                    className="btn btn-dark mt-4 btn-block"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <span>
                        <Spinner />
                      </span>
                    ) : (
                      <span>Add item</span>
                    )}
                  </Button>
                </div>
              </div>
            </form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default MeetingRoomItemForm;
