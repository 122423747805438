import React from 'react';
import {
    MembershipAgreementItemFormData,
    MembershipAgreementItemStatus,
} from '../../../../models/membership-agreement.model';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    SelectChangeEvent,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { FormGroup } from 'reactstrap';
import { TeamMember } from '../../../../models/team.member.model';
import { ProductPackage } from '../../../../models/packages.models';

interface AddMembershipAgreementItemDialogProps {
    handleClose: () => void;
    isOpen: boolean;
    members: TeamMember[];
    statusList: MembershipAgreementItemStatus[];
    packages: ProductPackage[];
    item?: MembershipAgreementItemFormData;
    items: MembershipAgreementItemFormData[];
    onUpdateItem: (items: MembershipAgreementItemFormData[]) => void;
    onAddOrUpdateItem: (item: MembershipAgreementItemFormData) => void;
}

type FormData = {
    packageId: string;
    assignMemberId: string;
    parkingFee: number;
    balconyFee: number;
    totalFee: number;
    status: string;
};

export default function AddMembershipAgreementItemDialog({
    handleClose,
    isOpen,
    item,
    statusList,
    packages,
    members,
    onUpdateItem,
    items,
    onAddOrUpdateItem,
}: AddMembershipAgreementItemDialogProps) {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<FormData>();

    const onSubmit = (formData: FormData) => {
        const itemData: MembershipAgreementItemFormData = {
            id: item?.id ?? undefined,
            balconyFee: Number(formData.balconyFee),
            packageId: formData?.packageId,
            parkingFee: Number(formData?.parkingFee),
            totalFee: Number(formData?.totalFee),
            status: formData?.status,
            assignMemberId:
                formData?.assignMemberId && formData?.assignMemberId !== ' ' ? formData?.assignMemberId : undefined,
        };
        if (item) {
            const index = items.indexOf(item);
            const newItems = [...items];
            newItems[index] = { ...itemData };
            onUpdateItem(newItems);
            handleClose();
            return;
        }

        onAddOrUpdateItem(itemData);
        handleClose();
    };

    const handlePackageSelection = (event: SelectChangeEvent) => {
        const selectedPackagePrice: number = Number(packages.find((p) => p.id === event.target.value).price) ?? 0;
        setValue('totalFee', selectedPackagePrice);
    };

    return (
        <>
            <Dialog open={isOpen} onClose={handleClose}>
                <DialogTitle>{item ? 'Update' : 'Add'} Item</DialogTitle>
                <form style={{ width: '500px' }} onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>
                        <FormGroup>
                            <label className='form-control-label' htmlFor='currency'>
                                Package
                            </label>
                            <Select
                                className='form-control'
                                sx={{ borderRadius: '12px', padding: '0' }}
                                defaultValue={item?.packageId ?? ' '}
                                placeholder='Select a package'
                                onChange={handlePackageSelection}
                                inputProps={{
                                    ...register('packageId', {
                                        required: true,
                                        value: item?.packageId ?? '',
                                    }),
                                }}>
                                <MenuItem disabled value={' '}>
                                    Select a package...
                                </MenuItem>
                                {packages.map((p) => {
                                    return (
                                        <MenuItem key={p?.id} value={p?.id}>
                                            {p?.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            {errors.packageId && <span className='invalid'>*This field is required</span>}
                        </FormGroup>
                        <FormGroup>
                            <label className='form-control-label' htmlFor='currency'>
                                Member (Optional)
                            </label>
                            <Select
                                className='form-control'
                                sx={{ borderRadius: '12px', padding: '0' }}
                                placeholder='Select a package'
                                defaultValue={item?.assignMemberId ?? ' '}
                                inputProps={{
                                    ...register('assignMemberId', {
                                        required: false,
                                        value: item?.assignMemberId ?? undefined,
                                    }),
                                }}>
                                <MenuItem disabled value={' '}>
                                    Select a team member...
                                </MenuItem>
                                {members?.map((member) => {
                                    return (
                                        <MenuItem
                                            key={member.id}
                                            value={
                                                member.id
                                            }>{`${member.user?.name} ${member.user?.surname}`}</MenuItem>
                                    );
                                })}
                            </Select>
                            {errors.assignMemberId && <span className='invalid'>*This field is required</span>}
                        </FormGroup>
                        <FormGroup>
                            <label className='form-control-label' htmlFor='name'>
                                Parking fee (Optional)
                            </label>
                            <input
                                className='form-control'
                                id='company-name'
                                {...register('parkingFee', { required: false, value: item?.parkingFee })}
                                placeholder='Enter price...'
                                type='number'
                                step={0.01}
                            />
                            {errors.parkingFee && <span className='invalid'>*This field is required</span>}
                        </FormGroup>
                        <FormGroup>
                            <label className='form-control-label' htmlFor='name'>
                                Balcony fee (Optional)
                            </label>
                            <input
                                className='form-control'
                                id='company-name'
                                {...register('balconyFee', { required: false, value: item?.balconyFee })}
                                placeholder='Enter price...'
                                type='number'
                                step={0.01}
                            />
                            {errors.balconyFee && <span className='invalid'>*This field is required</span>}
                        </FormGroup>
                        <FormGroup>
                            <label className='form-control-label' htmlFor='name'>
                                Total fee
                            </label>
                            <input
                                className='form-control'
                                id='company-name'
                                {...register('totalFee', { required: true, value: item?.totalFee })}
                                placeholder='Enter price...'
                                type='number'
                                step={0.01}
                            />
                            {errors.totalFee && <span className='invalid'>*This field is required</span>}
                        </FormGroup>
                        <FormGroup>
                            <label className='form-control-label' htmlFor='currency'>
                                Status
                            </label>
                            <Select
                                id='currency'
                                className='form-control'
                                sx={{ borderRadius: '12px', padding: '0' }}
                                placeholder='Select a role'
                                defaultValue={item?.status ?? ' '}
                                inputProps={{
                                    ...register('status', {
                                        required: true,
                                        value: item?.status ?? '',
                                    }),
                                }}>
                                <MenuItem disabled value={' '}>
                                    Select a status...
                                </MenuItem>
                                {statusList?.map((status) => {
                                    return <MenuItem value={status.value}>{status.title}</MenuItem>;
                                })}
                            </Select>
                            {errors.status && <span className='invalid'>*This field is required</span>}
                        </FormGroup>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type='submit'>{item ? 'Update' : 'Add'}</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}
