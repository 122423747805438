import React, { useEffect, useState } from 'react';
import { OrderExportType } from '../../../../models/order.model';
import useToast from '../../../../hooks/useToast';
import { useForm } from 'react-hook-form';
import {
    Autocomplete,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import { FormGroup } from 'reactstrap';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CREATE_ORDER_EXPORT, IS_ORDER_EXPORT_AVAILABLE } from '../../../../graphql/orders.graphql';
import { AccountSearchQueryResult } from '../../../../models/client.model';
import { SEARCH_ACCOUNTS } from '../../../../graphql/clients.graphql';

type Props = {
    isOpen: boolean;
    toggleOpen: (isOpen: boolean) => void;
    types: OrderExportType[];
};

type FormData = {
    type: string;
    from: string;
    name: string;
    to: string;
    client: string;
};

const CreateOrderExportDialog: React.FC<Props> = ({ isOpen, toggleOpen, types }) => {
    const { showToast, Notification } = useToast();

    const [searchAccounts, { data: searchedData, called: searchCalled }] = useLazyQuery(SEARCH_ACCOUNTS);
    const [checkAvailability, { loading: checking, data: exportData, error: checkingError }] = useLazyQuery(
        IS_ORDER_EXPORT_AVAILABLE,
        {
            fetchPolicy: 'network-only',
        },
    );
    const [query, setQuery] = useState<string>();
    const [accounts, setAccounts] = useState<AccountSearchQueryResult[]>([]);
    const [availabilityText, setAvailabilityText] = useState<string>('');

    const [createOrderExport, { loading, called, error, data }] = useMutation(CREATE_ORDER_EXPORT, {
        refetchQueries: ['GetOrderExports'],
    });

    const handleClose = () => {
        toggleOpen(false);
    };

    useEffect(() => {
        if (searchCalled && searchedData) {
            setAccounts(searchedData.accounts);
        }
    }, [searchedData]);

    useEffect(() => {
        if (query && query.length >= 3) {
            searchAccounts({ variables: { query: `%${query}%` } });
        }
    }, [query, searchAccounts]);

    const {
        register,
        handleSubmit,
        reset: resetForm,
        formState: { errors },
        watch,
        setValue,
    } = useForm<FormData>({
        defaultValues: {
            from: moment().subtract(1, 'month').format('YYYY-MM-DD'),
            to: moment().format('YYYY-MM-DD'),
        },
    });

    const onSubmit = (data: FormData) => {
        console.log(data);
        createOrderExport({
            variables: {
                type: data.type,
                from: data.from,
                to: data.to,
                name: data.name,
                accountId: data.client != '' ? data.client : undefined,
            },
        });
    };

    // useEffect(() => {
    //     handleCheckAvailability();
    // }, []);

    useEffect(() => {
        if (exportData) {
            setAvailabilityText(
                exportData.export?.isAvailable
                    ? 'Ready to export'
                    : 'An existing export(s) within the selected date range has been identified. Please either delete, import, or reject these pending exports before proceeding with the creation of a new one',
            );
        }
    }, [exportData]);

    useEffect(() => {
        if (checkingError) {
            showToast('An error has occurred while trying to check availability', 'danger');
        }
    }, [checkingError]);

    const handleCheckAvailability = async () => {
        if (!watch('from') || !watch('to') || watch('type') === '' || !watch('type')) {
            return;
        }

        await checkAvailability({
            variables: {
                args: {
                    from_date: moment(watch('from')).format('YYYY-MM-DD'),
                    to_date: moment(watch('to')).format('YYYY-MM-DD'),
                    client_id: watch('client') !== '' ? watch('client') : undefined,
                    export_type: watch('type'),
                },
            },
        });
    };

    useEffect(() => {
        if (called && data) {
            showToast('Successfully created your order export', 'success');
            resetForm();
            toggleOpen(false);
        }

        if (called && error) {
            showToast('An error has occurred, please try again', 'danger');
        }
    }, [called, error, data]);

    return (
        <>
            {Notification}
            <Dialog open={isOpen} onClose={handleClose}>
                <DialogTitle>Create Order Export</DialogTitle>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>
                        <div className='row'>
                            <div className='col-md-12 col-lg-6'>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='currency'>
                                        Type
                                    </label>
                                    <Select
                                        id='type'
                                        className='form-control'
                                        sx={{ borderRadius: '12px', padding: '0' }}
                                        defaultValue={''}
                                        onChange={() => handleCheckAvailability()}
                                        inputProps={{
                                            ...register('type', {
                                                required: true,
                                            }),
                                        }}>
                                        {types?.map((type: OrderExportType) => (
                                            <MenuItem key={type.value} value={type.value}>
                                                {type.title}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {errors.type && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                            </div>
                            <div className='col-md-12 col-lg-6'>
                                <FormGroup>
                                    <label className='form-control-label'>Name</label>
                                    <input
                                        className='form-control'
                                        placeholder='Enter export name...'
                                        {...register('name', {
                                            required: true,
                                        })}
                                    />
                                    {errors.name && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                            </div>
                            <div className='col-md-12 col-lg-6'>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='currency'>
                                        From
                                    </label>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                            slotProps={{
                                                textField: {
                                                    variant: 'outlined',
                                                    style: { fontSize: '14px', color: '#8898aa' },
                                                    className: 'form-control',
                                                },
                                                field: {
                                                    className: 'form-control',
                                                },
                                            }}
                                            value={moment(watch('from'))}
                                            format='YYYY-MM-DD'
                                            closeOnSelect={true}
                                            {...register('from', {
                                                required: true,
                                            })}
                                            defaultValue={moment()}
                                            onChange={(newValue) => {
                                                console.log(newValue);
                                                setValue('from', newValue.format('YYYY-MM-DD'));
                                                handleCheckAvailability();
                                            }}
                                        />
                                    </LocalizationProvider>
                                </FormGroup>
                            </div>
                            <div className='col-md-12 col-lg-6 mb-4'>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='currency'>
                                        To
                                    </label>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                            slotProps={{
                                                textField: {
                                                    variant: 'outlined',
                                                    style: { fontSize: '14px', color: '#8898aa' },
                                                    className: 'form-control',
                                                },
                                                field: {
                                                    className: 'form-control',
                                                },
                                            }}
                                            value={moment(watch('to'))}
                                            format='YYYY-MM-DD'
                                            closeOnSelect={true}
                                            {...register('to', {
                                                required: true,
                                            })}
                                            defaultValue={moment()}
                                            onChange={(newValue) => {
                                                console.log(newValue);
                                                setValue('to', newValue.format('YYYY-MM-DD'));
                                                handleCheckAvailability();
                                            }}
                                        />
                                    </LocalizationProvider>
                                </FormGroup>
                            </div>
                            <div className='col-md-12 col-lg-6'>
                                <FormGroup>
                                    <label className='form-control-label'>Client(Optional)</label>
                                    <Autocomplete
                                        id='client'
                                        {...register('client', {
                                            required: false,
                                        })}
                                        onChange={(event, value) => {
                                            const _value: any = value?.id;
                                            setValue('client', _value);
                                            handleCheckAvailability();

                                            // calculateRate();
                                        }}
                                        onInputChange={(event, value) => {
                                            setQuery(value);
                                        }}
                                        // defaultValue={defaultClientValue()}
                                        filterOptions={(options) => options}
                                        options={accounts}
                                        getOptionLabel={(option) => option.companyName || option.individualName}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder='Select client'
                                                fullWidth={true}
                                                variant='outlined'
                                                style={{ fontSize: '14px' }}
                                            />
                                        )}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col'>
                                {!checking && (
                                    <span
                                        style={{
                                            color: exportData?.export?.isAvailable ? 'blue' : 'red',
                                            fontSize: '12px',
                                            paddingRight: '12px',
                                        }}>
                                        {availabilityText}
                                    </span>
                                )}
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        {/* {!checking && (
                            <span
                                style={{
                                    color: exportData?.export?.isAvailable ? 'blue' : 'red',
                                    fontSize: '12px',
                                    paddingRight: '12px',
                                }}>
                                {availabilityText}
                            </span>
                        )} */}
                        {checking && <CircularProgress size={16} />}
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type='submit' disabled={loading || !exportData?.export?.isAvailable}>
                            {loading && <CircularProgress size={16} />}
                            {!loading && <span>Add</span>}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default CreateOrderExportDialog;
