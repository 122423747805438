/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LinearProgress } from "@mui/material";
import { Product } from "../../../models/product.model";
import {
  Card,
  CardFooter,
  CardHeader,
  Col,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import {
  GET_PRODUCTS,
  REMOVE_PRODUCT,
} from "../../../graphql/products.graphql";
import { useQuery, useMutation } from "@apollo/client";
import useToast from "../../../hooks/useToast";
import SweetAlert from "react-bootstrap-sweetalert";
import BasicMenu from "../../../components/Menu";
import { User } from "../../../models/user.model";
import { useSelector } from "react-redux";
import { getPermission } from "../../../utils/user.utils";
import _ from "lodash";
import { useLocalStorage } from "../../../hooks/local-storage.hook";

interface ProductRouteData {
  keyWord: string;
  page: number;
  pageSize: number;
}

const ProductView = () => {
  const { loading, error, data } = useQuery(GET_PRODUCTS);
  const [removeProductMutation, { error: errorRemoving, called }] = useMutation(
    REMOVE_PRODUCT,
    {
      refetchQueries: [{ query: GET_PRODUCTS }, "GetProducts"],
    }
  );
  const { showToast, Notification } = useToast();
  const [searchKey, setSearchKey] = useState<string>("");
  const [alert, setAlert] = useState<any>(null);
  const [page, setPage] = useState<number>(0);
  const [rowPerPage, setRowsPerPage] = useState<number>(10);
  const [getLocalStorage, setLocalStorage] = useLocalStorage("product");
  const [sortDirection, setSortDirection] = useState<string>("desc");
  const [sortFiled, setSortField] = useState<string>("name");
  const removeProduct = (_id: string) => {
    removeProductMutation({ variables: { id: _id } });
    setAlert(null);
  };
  const user: User | null = useSelector((state: any) => state.user.userInfo);

  const navigate = useNavigate();

  const displayAlert = (props: any) => {
    setAlert(
      <SweetAlert
        {...props}
        showCancel
        confirmBtnText="Continue"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => {
          removeProduct(props.product.id);
        }}
        onCancel={() => {
          setAlert(null);
        }}
        focusCancelBtn
      >
        This product will be removed from the system.
      </SweetAlert>
    );
  };

  useEffect(() => {
    const routeData: ProductRouteData = getLocalStorage();

    if (routeData) {
      setPage(routeData?.page || 0);
      setRowsPerPage(routeData?.pageSize || 10);
      setSearchKey(routeData?.keyWord || "");
    }
  }, []);

  useEffect(() => {
    if (error || errorRemoving) {
      showToast("An error has occurred, please try again.", "danger");
    }
  }, [error, errorRemoving]);

  useEffect(() => {
    if (!error && called && !errorRemoving) {
      showToast("Successfully removed product", "success");
    }
  }, [called]);

  if (loading) {
    return <LinearProgress />;
  }

  const getRowValue = (value: string) => {
    setRowsPerPage(parseInt(value));
    const routeData: ProductRouteData = getLocalStorage() || {};
    routeData.pageSize = parseInt(value);
    if (parseInt(value) !== rowPerPage) {
      setPage(0);
      routeData.page = 0;
    }
    setLocalStorage(routeData);
  };

  const nextPage = () => {
    setPage(page + 1);
    const routeData: ProductRouteData = getLocalStorage() || {};
    routeData.page = page + 1;
    setLocalStorage(routeData);
  };
  const previousPage = () => {
    setPage(page - 1);
    const routeData: ProductRouteData = getLocalStorage() || {};
    routeData.page = page - 1;
    setLocalStorage(routeData);
  };

  const sortBy: any = (
    products: Product[],
    field: string,
    direction: string
  ) => {
    const orderedBookings = new Map([
      [
        "name",
        _.orderBy(products, (product) => product.name, [
          direction === "asc" ? "asc" : "desc",
        ]),
      ],
      [
        "type",
        _.orderBy(products, (product) => product.type?.title, [
          direction === "asc" ? "asc" : "desc",
        ]),
      ],
      [
        "status",
        _.orderBy(products, (product) => product.status?.title, [
          direction === "asc" ? "asc" : "desc",
        ]),
      ],
    ]);
    return orderedBookings.get(field);
  };

  const products: Product[] = data?.products;
  let filteredProducts = products.filter((product) => {
    if (searchKey === "") {
      return product;
    }

    return (
      product.name
        .toLocaleLowerCase()
        .includes(searchKey.toLocaleLowerCase()) ||
      product.type?.title
        .toLocaleLowerCase()
        .includes(searchKey.toLocaleLowerCase()) ||
      product.status?.title
        .toLocaleLowerCase()
        .includes(searchKey.toLocaleLowerCase())
    );
  });

  filteredProducts = sortBy(filteredProducts, sortFiled, sortDirection);

  return (
    <div className="container-fluid mt-4">
      {Notification}
      {alert}
      <Row>
        <Col>
          <h1>Products</h1>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <Card>
            <CardHeader className="border-0">
              <Row>
                <Col xs="6">
                  <h3 className="mb-0">Products</h3>
                </Col>
                {getPermission(user, "products", "create") && (
                  <Col className="text-right" xs="6">
                    <Link
                      to={"/admin/products/new"}
                      className="btn btn-round btn-dark btn-sm"
                      color="default"
                      id="tooltip969372949"
                    >
                      <span className="btn-inner--text">Add product</span>
                    </Link>
                    <UncontrolledTooltip delay={0} target="tooltip969372949">
                      Add product
                    </UncontrolledTooltip>
                  </Col>
                )}
              </Row>
              <Row className="mt-4">
                <Col>
                  <Input
                    className="w-25"
                    value={searchKey}
                    placeholder="Search keyword..."
                    onChange={(e) => {
                      setSearchKey(e.target.value);
                      setPage(0);
                      const routeData: ProductRouteData =
                        getLocalStorage() || {};
                      routeData.keyWord = e.target.value;
                      routeData.page = 0;
                      setLocalStorage(routeData);
                    }}
                  />
                </Col>
              </Row>
            </CardHeader>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th
                    onClick={() => {
                      setSortField("name");
                      setSortDirection(
                        sortDirection === "asc" ? "desc" : "asc"
                      );
                    }}
                    className="sort"
                    data-sort="name"
                    scope="col"
                  >
                    Name
                  </th>
                  <th
                    onClick={() => {
                      setSortField("type");
                      setSortDirection(
                        sortDirection === "asc" ? "desc" : "asc"
                      );
                    }}
                    className="sort"
                    data-sort="type"
                    scope="col"
                  >
                    type
                  </th>
                  <th
                    onClick={() => {
                      setSortField("status");
                      setSortDirection(
                        sortDirection === "asc" ? "desc" : "asc"
                      );
                    }}
                    className="sort"
                    data-sort="status"
                    scope="col"
                  >
                    Status
                  </th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody className="list">
                {filteredProducts
                  .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                  .map((product) => {
                    return (
                      <tr
                        key={product.id}
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          navigate(`/admin/products/manage/${product.id}`)
                        }
                      >
                        <td>{product.name}</td>
                        <td>{product.type?.title}</td>
                        <td>{product.status?.title}</td>
                        <td className="text-right">
                          {getPermission(user, "products", "update") && (
                            <Link
                              to={`/admin/products/manage/${product.id}`}
                              className="btn btn-info btn-icon-only rounded-circle btn-sm"
                            >
                              <i className="fa-solid fa-pencil"></i>
                            </Link>
                          )}
                          {getPermission(user, "products", "delete") && (
                            <button
                              className="btn btn-danger btn-icon-only rounded-circle btn-sm"
                              onClick={(e) => {
                                e.stopPropagation();
                                displayAlert({ warning: true, product });
                              }}
                            >
                              <i className="fa-solid fa-trash-can"></i>
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <CardFooter className="py-4">
              <Row>
                <div className="col-4 d-flex">
                  <span className="min-text">Rows per page:</span>
                  <BasicMenu
                    getValue={getRowValue}
                    options={["10", "20", "100"]}
                    value={rowPerPage.toString()}
                  />
                </div>

                <div className="col-4 d-flex">
                  <span className="min-text">Page:</span>
                  <span className="min-text pl-2">
                    {page + 1} of{" "}
                    {Math.ceil(filteredProducts.length / rowPerPage)}
                  </span>
                </div>
                <div className="col-4 d-flex">
                  <Pagination>
                    <PaginationItem className={page <= 0 ? "disabled" : ""}>
                      <PaginationLink onClick={(e) => previousPage()}>
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem
                      className={
                        page >=
                        Math.ceil(filteredProducts.length / rowPerPage) - 1
                          ? "disabled"
                          : ""
                      }
                    >
                      <PaginationLink onClick={(e) => nextPage()}>
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </div>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ProductView;
