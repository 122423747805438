/* eslint-disable react-hooks/exhaustive-deps */
import { LinearProgress, Select, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Row,
} from "reactstrap";
import useToast from "../../../hooks/useToast";
import {
  GET_BOOKING_STATUS,
  GET_MEETING_ROOM_ITEMS,
  ADD_BOOKING,
  GET_CLIENT,
} from "../../../graphql/clients.graphql";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { BookingGuest, BookingStatus } from "../../../models/bookings.model";
import { useForm } from "react-hook-form";
import Spinner from "../../../components/Spinner/Spinner";
import { useParams } from "react-router-dom";
import { MeetingRoomItem } from "../../../models/meeting.room.model";
import ReactDatetimeClass from "react-datetime";
import { bookingTimeList } from "../../../variables/variables";
import { CHECK_MEETING_ROOM_ITEM_AVAILABILITY } from "../../../graphql/meeting-rooms.graphql";
import moment, { isMoment } from "moment";
import { getTime } from "../../../utils/booking.utils";
import EmailChipListInput from "./components/EmailChipListInput";

type FormData = {
  subject: string;
  date: string;
  from: string;
  to: string;
  status: string;
  meetingItem: string;
};

const BookingAddForm: any = ({ goBackToBookings }: any) => {
  const { loading, error, data } = useQuery(GET_BOOKING_STATUS);
  const {
    loading: roomItemsLoading,
    error: meetingRoomsError,
    data: roomItems,
  } = useQuery(GET_MEETING_ROOM_ITEMS);
  const { showToast, Notification } = useToast();
  const [guests, setGuests] = useState<BookingGuest[]>([]);

  const [buttonText, setButtonText] = useState<string>("Check availability");
  const [isRoomAvailable, setIsRoomAvailable] = useState<boolean>(false);
  const [
    checkMeetingRoom,
    { loading: checking, error: checkingError, data: meetingRoom },
  ] = useLazyQuery(CHECK_MEETING_ROOM_ITEM_AVAILABILITY);
  const [
    addBookingMutation,
    { loading: addingBooking, error: errorAdding, data: bookingAdded },
  ] = useMutation(ADD_BOOKING, {
    refetchQueries: [{ query: GET_CLIENT }, "GetClient"],
  });
  const [startingTime, setStarting] = useState<string>("");
  const [endingTime, setEndingTime] = useState<string>("");
  const [additionalNotes, setAdditionalNotes] = useState<string>("");
  const params: any = useParams();

  const {
    handleSubmit,
    register,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<FormData>();

  const onAddGuest = (guest: BookingGuest) => {
    setGuests([...guests, guest]);
  };

  const removeGuest = (guest: BookingGuest) => {
    const newGuests = guests.filter((item) => item.email !== guest.email);
    setGuests(newGuests);
  };

  const onSubmit = (data: FormData) => {
    if (getValues("meetingItem") === "empty") {
      setValue("meetingItem", "");
      return;
    }

    if (getValues("from") === "empty") {
      setValue("from", "");
      return;
    }

    if (getValues("to") === "empty") {
      setValue("to", "");
      return;
    }

    const timeFrom = moment(data.date)
      .utc(true)
      .hours(getTime(data.from)[0])
      .toISOString();
    const timeTo = moment(data.date)
      .utc(true)
      .hours(getTime(data.to)[0])
      .toISOString();
    console.log("Stating time", timeFrom);
    console.log("Ending time", timeTo);

    if (!isRoomAvailable) {
      checkMeetingRoom({
        variables: {
          meetingRoomItemId: data.meetingItem,
          from: timeFrom,
          to: timeTo,
        },
      });
      return;
    }

    const booking = {
      subject: data.subject,
      time_from: timeFrom,
      time_to: timeTo,
      status_enum: data.status,
      meeting_room_item_id: data.meetingItem,
      account_id: params.id,
      booking_guests: {
        data: guests.map((guest) => ({ email: guest.email })),
      },
      additional_notes: additionalNotes !== "" ? additionalNotes : null,
    };

    addBookingMutation({
      variables: {
        booking,
      },
    });
  };

  const populateEndingTimeForm = () => {
    const startTime = getValues("from");

    if (getValues("to") !== "") {
      const endTime = getValues("to");
      const startingTimeIndex = bookingTimeList.indexOf(startTime);
      const endingTimeIndex = bookingTimeList.indexOf(endTime);

      if (startingTimeIndex >= endingTimeIndex) {
        return;
      }
    }

    setValue("from", startTime);
    setStarting(startTime);
    const nextTimeSlotIndex = bookingTimeList.indexOf(startTime);
    if (getValues("to") === "") {
      setValue("to", bookingTimeList[nextTimeSlotIndex + 1]);
      setEndingTime(bookingTimeList[nextTimeSlotIndex + 1]);
    }
    setStarting(startTime);
  };

  const onChangeEndingTime = () => {
    const startTime = getValues("from");
    const endingTime = getValues("to");
    const startingTimeIndex = bookingTimeList.indexOf(startTime);
    const endingTimeIndex = bookingTimeList.indexOf(endingTime);

    if (endingTimeIndex <= startingTimeIndex) {
      return;
    }
    setValue("to", endingTime);
    setEndingTime(endingTime);
  };

  useEffect(() => {
    if (error || meetingRoomsError) {
      showToast("Something went wrong, please refresh page.", "danger");
    }

    if (errorAdding) {
      showToast(
        "An error has occurred while trying to add this booking, please try again",
        "danger"
      );
    }

    if (checkingError) {
      showToast(
        "An error has occurred while checking for a room, please try again",
        "danger"
      );
    }
  }, [error, meetingRoomsError, errorAdding, checkingError]);

  useEffect(() => {
    if (!error && !meetingRoomsError && !errorAdding && bookingAdded) {
      showToast("Booking added successfully", "success");
      reset();
    }
  }, [bookingAdded]);

  useEffect(() => {
    if (meetingRoom?.booking) {
      setButtonText(
        meetingRoom?.booking.isAvailable
          ? "Check availability"
          : "Not available"
      );
      setIsRoomAvailable(meetingRoom?.booking.isAvailable);
    }
  }, [meetingRoom]);

  const onInputChange = () => {
    setIsRoomAvailable(false);
  };

  const isStartDateValid = (current: moment.Moment) => {
    const yesterday: moment.Moment = moment().subtract(1, "day");
    return current.isAfter(yesterday);
  };

  if (loading || roomItemsLoading) {
    return <LinearProgress />;
  }

  const bookingStatuses: BookingStatus[] = data?.status;
  const meetingRoomItems: MeetingRoomItem[] = roomItems?.meetingRoomItems;

  return (
    <Row>
      {Notification}
      <Col md="12" lg="6">
        <Card className="mt-4">
          <CardHeader>
            <Row noGutters={true}>
              <button
                className="btn btn-outline-primary btn-sm mr-4"
                onClick={() => goBackToBookings()}
              >
                <i className="fas fa-angle-left" style={{ fontSize: "14px" }} />
                <span className="btn-inner-text">Back</span>
              </button>
            </Row>
          </CardHeader>
          <CardBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-md-12 col-lg-6">
                  <FormGroup>
                    <label className="form-control-label">Meeting Room</label>
                    <Select
                      id="status"
                      sx={{ borderRadius: "12px", padding: "0" }}
                      className="form-control"
                      disabled={checking}
                      defaultValue={"empty"}
                      onChange={onInputChange}
                      inputProps={{
                        ...register("meetingItem", {
                          required: true,
                          value: "",
                        }),
                      }}
                    >
                      <MenuItem disabled value={"empty"}>
                        <span className="placeholder-text">
                          Select meeting room...
                        </span>
                      </MenuItem>
                      {meetingRoomItems.map((item: MeetingRoomItem) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {errors.meetingItem && (
                      <span className="invalid">*This field is required</span>
                    )}
                  </FormGroup>
                </div>
                <div className="col-md-12 col-lg-6">
                  <FormGroup>
                    <label className="form-control-label">Date</label>
                    <ReactDatetimeClass
                      inputProps={{
                        placeholder: "Enter booking date...",
                        ...register("date", {
                          required: true,
                          value: "",
                          disabled: checking,
                        }),
                        autoComplete: "off",
                      }}
                      timeFormat={false}
                      dateFormat={"YYYY-MM-DD"}
                      initialValue={moment()}
                      isValidDate={isStartDateValid}
                      onChange={(value) => {
                        if (value && isMoment(value)) {
                          setValue("date", value.format("yyyy-MM-DD"));
                        }
                        onInputChange();
                      }}
                    />
                    {errors.date && (
                      <span className="invalid">*This field is required</span>
                    )}
                  </FormGroup>
                </div>
                <div className="col-md-12 col-lg-6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="email">
                      From
                    </label>
                    <Select
                      id="room"
                      defaultValue={"empty"}
                      value={startingTime ? startingTime : "empty"}
                      onChange={() => {
                        populateEndingTimeForm();
                        onInputChange();
                      }}
                      sx={{ borderRadius: "12px", padding: "0" }}
                      disabled={checking}
                      className="form-control"
                      inputProps={{
                        ...register("from", {
                          required: true,
                          value: "",
                        }),
                      }}
                    >
                      <MenuItem disabled value={"empty"}>
                        <span className="placeholder-text">
                          Select starting time...
                        </span>
                      </MenuItem>
                      {bookingTimeList
                        .filter((time) => {
                          return (
                            bookingTimeList.indexOf(time) !==
                            bookingTimeList.length - 1
                          );
                        })
                        .map((time) => {
                          return (
                            <MenuItem key={time} value={time}>
                              {time}
                            </MenuItem>
                          );
                        })}
                    </Select>
                    {errors.from && (
                      <span className="invalid">*This field is required</span>
                    )}
                  </FormGroup>
                </div>
                <div className="col-md-12 col-lg-6">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="contact">
                      To
                    </label>
                    <Select
                      id="room"
                      sx={{ borderRadius: "12px", padding: "0" }}
                      className="form-control"
                      value={endingTime !== "" ? endingTime : "empty"}
                      defaultValue={"empty"}
                      disabled={startingTime === "" || checking}
                      onChange={(e: any) => {
                        console.log(`TIME: ${e.target.value}`);
                        onChangeEndingTime();
                        onInputChange();
                      }}
                      inputProps={{
                        ...register("to", {
                          required: true,
                          value: "",
                        }),
                      }}
                    >
                      <MenuItem disabled value={"empty"}>
                        <span className="placeholder-text">
                          Select ending time...
                        </span>
                      </MenuItem>
                      {bookingTimeList
                        .filter((time) => {
                          const startTimeIndex = bookingTimeList.indexOf(
                            getValues("from")
                          );
                          return bookingTimeList.indexOf(time) > startTimeIndex;
                        })
                        .map((time) => {
                          return (
                            <MenuItem key={time} value={time}>
                              {time}
                            </MenuItem>
                          );
                        })}
                    </Select>
                    {errors.to && (
                      <span className="invalid">*This field is required</span>
                    )}
                  </FormGroup>
                </div>
              </div>

              {!isRoomAvailable && (
                <div className="d-flex h-100">
                  <div className="align-self-end ml-auto">
                    <Button
                      type="submit"
                      className="btn btn-dark mt-4 btn-block"
                      disabled={checking}
                    >
                      {checking ? (
                        <span>
                          <Spinner />
                        </span>
                      ) : (
                        <span>{buttonText}</span>
                      )}
                    </Button>
                  </div>
                </div>
              )}
              {isRoomAvailable && (
                <>
                  <div className="row">
                    <div className="col-md-12 col-lg-6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="p-member-name"
                        >
                          Subject
                        </label>
                        <input
                          {...register("subject", { required: true })}
                          className="form-control"
                          id="p-member-name"
                          placeholder="Enter booking's subject..."
                          type="text"
                        />
                        {errors.subject && (
                          <span className="invalid">
                            *This field is required
                          </span>
                        )}
                      </FormGroup>
                    </div>
                    <div className="col-md-12 col-lg-6">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="status">
                          Status
                        </label>
                        <Select
                          sx={{ borderRadius: "12px", padding: "0" }}
                          id="status"
                          className="form-control"
                          defaultValue={"active"}
                          placeholder="Select booking status..."
                          inputProps={{
                            ...register("status", {
                              required: true,
                            }),
                          }}
                        >
                          {bookingStatuses.map((_status) => {
                            return (
                              <MenuItem
                                key={_status.value}
                                value={_status.value}
                              >
                                {_status.title}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {errors.status && (
                          <span className="invalid">
                            *This field is required
                          </span>
                        )}
                      </FormGroup>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-lg-6">
                      <EmailChipListInput
                        label="Guests"
                        placeholder="Enter email address"
                        onAddItem={onAddGuest}
                        onRemoveitem={removeGuest}
                        items={guests}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-lg-6">
                      <div className="form-group">
                        <label
                          className="form-control-label"
                          htmlFor="exampleFormControlTextarea1"
                        >
                          Notes
                        </label>
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows={3}
                          cols={3}
                          value={additionalNotes}
                          onChange={(e) => {
                            setAdditionalNotes(e.target.value);
                          }}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex h-100">
                    <div className="align-self-end ml-auto">
                      <Button
                        type="submit"
                        className="btn btn-dark mt-4 btn-block"
                        disabled={addingBooking}
                      >
                        {addingBooking ? (
                          <span>
                            <Spinner />
                          </span>
                        ) : (
                          <span>Add booking</span>
                        )}
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default BookingAddForm;
