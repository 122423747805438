import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import Spinner from '../../../components/Spinner/Spinner';
import useToast from '../../../hooks/useToast';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
//import { useHistory } from "react-router-dom";

const logo = require('../../../assets/img/colab-dark.png');

type FormData = {
    password: string;
    confirmPassword: string;
};

interface NewPasswordProps {
    user: any;
}
const NewPasswordForm: React.FC<NewPasswordProps> = ({ user }) => {
    const [loading, setLoading] = useState(false);
    const { showToast, Notification } = useToast();
    const navigate = useNavigate();

    //const location = useLocation
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>();

    const setNewPassword = async (data: FormData) => {
        try {
            await Auth.completeNewPassword(user, data.password);
            navigate('/admin/dashboard');
        } catch (error: any) {
            console.log('error signing in', error);
            showToast('Incorrect email or password', 'danger');
            setLoading(false);
        }
    };

    const submitForm = (data: FormData) => {
        setLoading(true);
        setNewPassword(data);
    };

    return (
        <>
            {Notification}
            <form className='login-form' onSubmit={handleSubmit(submitForm)}>
                <img className='logo mb-5' src={logo} alt='colab' />
                <h1 className='text-primary'>Set your new password</h1>
                <div className='form-group'>
                    <label className='form-control-label' htmlFor='email'>
                        New Password
                    </label>
                    <input
                        {...register('password', { required: true })}
                        id='password'
                        className='form-control'
                        type='password'
                        placeholder='Enter password...'
                    />
                    {errors.password && (
                        <span style={{ color: 'red', fontSize: '12px' }}>* this field is required.</span>
                    )}
                </div>
                <div className='form-group'>
                    <label className='form-control-label' htmlFor='password'>
                        Confirm Password
                    </label>
                    <input
                        {...register('confirmPassword', { required: true })}
                        className='form-control'
                        id='confirmPassword'
                        type='password'
                        placeholder='Confirm password...'
                    />
                    {errors.confirmPassword && (
                        <span style={{ color: 'red', fontSize: '12px' }}>* this field is required.</span>
                    )}
                </div>

                <button disabled={loading} className='btn btn-primary btn-block mt-4 ml-0' type='submit'>
                    {!loading ? (
                        <span>Continue</span>
                    ) : (
                        <span>
                            <Spinner />
                        </span>
                    )}
                </button>
            </form>
        </>
    );
};

export default NewPasswordForm;
