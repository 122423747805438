import React from "react";
import VoucherDetailsForm from "./components/VoucherDetailsForm";

const VoucherDetailsView = () => {
  return (
    <>
      <VoucherDetailsForm />
    </>
  );
};

export default VoucherDetailsView;
