/* eslint-disable react-hooks/exhaustive-deps */
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Button,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import {
  ASSIGN_CONFIGURATION,
  GET_MEETING_ROOM,
} from "../../../../graphql/meeting-rooms.graphql";
import { useParams } from "react-router-dom";
import { FormGroup } from "reactstrap";
import { useMutation } from "@apollo/client";
import useToast from "../../../../hooks/useToast";
import { RoomItemConfiguration } from "../../../../models/meeting.room.model";

interface DialogProps {
  isOpen: boolean;
  handleClose: () => void;
  itemId: string;
  roomItemConfigurations: RoomItemConfiguration[];
}

const AddItemConfigurationDialog = ({
  isOpen,
  handleClose,
  itemId,
  roomItemConfigurations,
}: DialogProps) => {
  const params: any = useParams();
  const { Notification, showToast } = useToast();
  const [configId, setConfigId] = useState<string>("");
  const { data } = useQuery(GET_MEETING_ROOM, {
    variables: {
      id: params.id,
    },
  });

  const [addConfiguration, { data: addedConfig, error, loading: adding }] =
    useMutation(ASSIGN_CONFIGURATION, {
      refetchQueries: [{ query: GET_MEETING_ROOM }, "GetMeetingRoom"],
    });

  useEffect(() => {
    if (error) {
      showToast(
        "There was an error while trying to add this configuration",
        "danger"
      );
    }

    if (addedConfig) {
      showToast("Successfully added configuration", "success");
      handleClose();
    }
  }, [error, addedConfig]);

  const addConfig = () => {
    const config = {
      configuration_id: configId,
      meeting_room_item_id: itemId,
    };

    addConfiguration({ variables: { assign: config } });
  };

  let configurations = data?.room?.meetingRoomConfiguration.filter(
    (config: any) => {
      if (roomItemConfigurations.length === 0) {
        return config;
      }

      return roomItemConfigurations.some((itemConfig) => {
        return itemConfig.configurationId !== config.id;
      });
    }
  );

  if (
    data?.room?.meetingRoomConfiguration.length ===
    roomItemConfigurations.length
  ) {
    console.log("All the configurations are assigned");
    configurations = [];
  }

  return (
    <div>
      {Notification}
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>
          <h2>Assign configuration</h2>
        </DialogTitle>
        <DialogContent>
          <FormGroup>
            <label>Select configuration</label>
            <Select
              id="type"
              className="form-control"
              sx={{ borderRadius: "12px", padding: "0" }}
              placeholder="Select a type"
              value={configId}
              onChange={(e) => setConfigId(e.target.value)}
            >
              {configurations?.map((config: any) => {
                return (
                  <MenuItem key={config?.id} value={config?.id}>
                    {config.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={adding || configId === ""}
            onClick={() => addConfig()}
          >
            {!adding && "Assign"}
            {adding && <CircularProgress size={16} />}
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddItemConfigurationDialog;
