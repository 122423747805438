import React from "react";
import Avatar from "@mui/material/Avatar";

interface UserAvatarProps {
  name: string;
}

export default function UserAvatar({ name }: UserAvatarProps) {
  const stringAvatar = (name: string) => {
    return {
      sx: {
        bgcolor: "black",
        color: "white",
        width: 32,
        height: 32,
        fontSize: "12px",
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  };

  return (
    <>
      <Avatar {...stringAvatar(name)} />
    </>
  );
}
