/* eslint-disable react-hooks/exhaustive-deps */
import { Chip, FormControl, LinearProgress, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, CardText, CardTitle, Col, Container, Row } from 'reactstrap';
import moment from 'moment';
import { useMutation, useQuery } from '@apollo/client';
import {
    ADD_NOMINATED_MEMBER,
    GENERATE_PROFORMA_INVOICE,
    GENERATE_QUOTE_PDF,
    GET_QUOTE_DETAILS,
    GET_QUOTE_FORM_INFO,
    REMOVE_NOMINATED_QUOTE_MEMBER,
    SEND_CLIENT_QUOTE,
    UPDATE_QUOTE,
} from '../../../graphql/quotes.graphql';
import useToast from '../../../hooks/useToast';
import { useNavigate, useParams } from 'react-router-dom';
import { Quote, QuoteItem, QuoteStatus, QuoteType } from '../../../models/quotes.models';
import QuoteItemInfo from './components/QuoteItemInfo';
import { GET_BOOKING_STATUS, GET_MEETING_ROOM_ITEMS } from '../../../graphql/clients.graphql';
import { MeetingRoomItem } from '../../../models/meeting.room.model';
import { BookingStatus } from '../../../models/bookings.model';
import BookingDialog from '../../../components/BookingDialog';
import AddExistingBookingDialog from './dialogs/AddExistingBookingDialog';
import Spinner from '../../../components/Spinner/Spinner';

const QuoteDetails = () => {
    const navigate = useNavigate();
    const params: any = useParams();
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [openLinkDialog, setLinkDialog] = useState<boolean>(false);
    const [bookingId, setBookingId] = useState<string>();

    const [sendQuoteEmail, { data: sendEmail, loading: sendingEmail, error: errorSendingEmail }] =
        useMutation(SEND_CLIENT_QUOTE);

    const { loading, error, data } = useQuery(GET_QUOTE_DETAILS, {
        variables: { id: params.id },
    });

    const { loading: loadingStatus, error: statusError, data: loadedStatus } = useQuery(GET_BOOKING_STATUS);
    const { loading: loadingItems, error: itemError, data: loadedItems } = useQuery(GET_MEETING_ROOM_ITEMS);

    const [generatePDF, { data: generatedPDF, loading: generating, error: errorGenerating }] = useMutation(
        GENERATE_QUOTE_PDF,
        {
            fetchPolicy: 'network-only',
        },
    );

    const [addQuote, { error: addingQuoteError, data: addedQuoteData }] = useMutation(UPDATE_QUOTE, {
        refetchQueries: [GET_QUOTE_DETAILS, 'getQuote'],
    });

    const [generateProformaInvoice, { data: invoice, error: invoiceError, loading: generatingInvoice }] =
        useMutation(GENERATE_PROFORMA_INVOICE);

    const { loading: loadingQuoteInfo, error: quoteInfoError, data: quoteInfo } = useQuery(GET_QUOTE_FORM_INFO);
    const { showToast, Notification } = useToast();

    const [addNominatedMember, { loading: addingMember }] = useMutation(ADD_NOMINATED_MEMBER, {
        refetchQueries: [GET_QUOTE_DETAILS],
    });

    const [removedMember, { loading: removing }] = useMutation(REMOVE_NOMINATED_QUOTE_MEMBER, {
        refetchQueries: [GET_QUOTE_DETAILS],
    });

    const handleStatusChange = (event: SelectChangeEvent) => {
        const quote = {
            status_enum: event.target.value,
        };

        addQuote({
            variables: {
                id: params.id,
                quote: quote,
            },
        });
    };

    const getTotalRoomAddOnPrice = (items: QuoteItem[]): number => {
        let totalPrice = 0;
        for (let item of items) {
            for (let addOn of item.booking.roomExtras) {
                totalPrice += addOn.totalPrice;
            }
        }
        return totalPrice;
    };

    const getTotalCateringPrice = (items: QuoteItem[]): number => {
        let totalPrice = 0;

        items.forEach((item) => {
            item.booking.menuPackages?.forEach((menu) => {
                totalPrice += menu.totalPrice;
            });
        });

        return totalPrice;
    };

    const getTotalResources = (items: QuoteItem[]): number => {
        let totalPrice = 0;
        items.forEach((item) => {
            item.booking.bookingResources?.forEach((resource) => {
                totalPrice += resource.totalPrice;
            });
        });

        return totalPrice;
    };

    const calculateTotalPrice = (items: QuoteItem[]) => {
        let subTotal: number = 0;
        let totalVat = 0;
        let totalAmountDue = 0;
        items.forEach((item) => {
            if (item.booking.discount > 0 && item.booking.quotedPrice) {
                subTotal += item.booking.quotedPrice;
                return;
            }
            subTotal += item.booking.totalPrice;
        });

        subTotal += getTotalCateringPrice(items);
        subTotal += getTotalResources(items);
        subTotal += getTotalRoomAddOnPrice(items);

        totalVat = subTotal * 0.15;
        totalAmountDue = subTotal + totalVat;

        return {
            subTotal: subTotal,
            vat: totalVat,
            totalAmount: totalAmountDue,
        };
    };

    const handleTypeChange = (event: SelectChangeEvent) => {
        const quote = {
            type_enum: event.target.value,
        };
        addQuote({
            variables: {
                id: params.id,
                quote: quote,
            },
        });
    };

    useEffect(() => {
        if (invoice) {
            console.log('PDF data', invoice);
            const byteArray = new Uint8Array(
                atob(invoice.proformaInvoice.pdf)
                    .split('')
                    .map((char) => char.charCodeAt(0)),
            );

            const blob = new Blob([byteArray], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            window.open(url, '_blank');
        }
    }, [invoice]);

    useEffect(() => {
        if (invoiceError) {
            showToast('An error has occurred while trying to generate an invoice', 'danger');
        }
    }, [invoiceError]);

    useEffect(() => {
        if (addedQuoteData || statusError) {
            showToast('Successfully updated quote', 'success');
        }
    }, [addedQuoteData]);

    useEffect(() => {
        if (sendEmail) {
            showToast(sendEmail.email.message, 'success');
        }
    }, [sendEmail]);

    useEffect(() => {
        if (errorSendingEmail) {
            showToast('An error has occurred while trying to send the quote', 'danger');
        }
    }, [errorSendingEmail]);

    useEffect(() => {
        if (error || quoteInfoError || statusError || itemError) {
            showToast('An error has occurred, please refresh the page', 'danger');
            console.log('Error', error);
        }

        if (addingQuoteError) {
            showToast('An error has occurred while trying to update this quote', 'danger');
        }

        if (errorGenerating) {
            showToast('An error has occurred while trying to generate the quote document', 'danger');
        }

        if (generatedPDF) {
            console.log('PDF data', generatedPDF);
            const byteArray = new Uint8Array(
                atob(generatedPDF.response.pdf)
                    .split('')
                    .map((char) => char.charCodeAt(0)),
            );

            const blob = new Blob([byteArray], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            window.open(url, '_blank');
        }
    }, [error, quoteInfoError, addingQuoteError, statusError, itemError, generatedPDF, errorGenerating]);

    if (loading || loadingQuoteInfo || loadingStatus || loadingItems) {
        return <LinearProgress />;
    }

    const handleSelectOnChange = (event: SelectChangeEvent) => {
        const memberIndex = quote.nominatedMembers.findIndex((nom) => nom.member.id === event.target.value);

        if (memberIndex !== -1) return;

        const nominatedMemberData = {
            member_id: event.target.value,
            quote_id: params.id,
        };

        addNominatedMember({
            variables: {
                member: nominatedMemberData,
            },
        });
    };

    const items: MeetingRoomItem[] = loadedItems?.meetingRoomItems;
    const bookingStatusList: BookingStatus[] = loadedStatus?.status;
    const quote: Quote = data?.quote;
    const client = quote?.client;
    const quoteItems: QuoteItem[] = quote?.items;
    const statusList: QuoteStatus[] = quoteInfo?.status;
    const types: QuoteType[] = quoteInfo?.quoteTypes;
    const { subTotal, vat, totalAmount } = calculateTotalPrice(quoteItems);
    const quoteItemList: QuoteItem[] = data?.quoteItems;

    return (
        <>
            {openDialog && (
                <BookingDialog
                    open={openDialog}
                    rooms={items}
                    quoteId={params.id}
                    clientId={client.id}
                    bookingId={bookingId}
                    statusList={bookingStatusList}
                    handleClose={() => setOpenDialog(false)}
                />
            )}
            {openLinkDialog && (
                <AddExistingBookingDialog
                    handleClose={() => setLinkDialog(false)}
                    isOpen={openLinkDialog}
                    quoteItems={quoteItemList}
                    quoteId={params.id}
                    clientId={client?.id}
                />
            )}
            {Notification}
            <Container fluid={true}>
                <Row className='mt-4'>
                    <Col md='12' lg='10' xl='8'>
                        <Card>
                            <CardHeader>
                                <Row noGutters={true}>
                                    <div className='col-6'>
                                        <div className='row'>
                                            <div className='col-5'>
                                                <button
                                                    className='btn btn-outline-primary btn-sm mr-4'
                                                    onClick={(e) => navigate(-1)}>
                                                    <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                                                    <span className='btn-inner-text'>Back</span>
                                                </button>
                                            </div>
                                            <div className='col-7'>
                                                <h3 className='m-0 text-sm'>
                                                    Quote Details{`- ${quote?.reference || 'N/A'}`}
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-6 text-right'>
                                        <Button
                                            className='btn btn-outlined-info btn-sm'
                                            onClick={() => {
                                                generatePDF({
                                                    variables: {
                                                        quoteInfo: {
                                                            quote_id: quote.id,
                                                        },
                                                    },
                                                });
                                            }}
                                            disabled={
                                                (quote.status.value !== 'active' &&
                                                    quote.status.value !== 'accepted') ||
                                                generating
                                            }>
                                            {!generating && <span>Quote</span>}
                                            {generating && <Spinner />}
                                        </Button>

                                        {quote.status.value === 'accepted' && quote?.order?.id && (
                                            <Button
                                                className='btn btn-outlined-info btn-sm'
                                                onClick={() => {
                                                    if (quote.order.id) {
                                                        navigate(`/admin/orders/details/${quote?.order?.id}`);
                                                    }
                                                }}>
                                                <span>View Order</span>
                                            </Button>
                                        )}
                                        {/* {quote.status.value === 'active' && (
                                            <Button
                                                disabled={sendingEmail}
                                                onClick={() => {
                                                    sendQuoteEmail({
                                                        variables: {
                                                            quoteId: params.id,
                                                        },
                                                    });
                                                }}
                                                className='btn btn-outlined-info btn-sm'>
                                                {!sendingEmail && <span>Send Quote</span>}
                                                {sendingEmail && <Spinner />}
                                            </Button>
                                        )} */}
                                    </div>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md='12' lg='6'>
                                        <CardTitle>Quote Information</CardTitle>

                                        <CardText>Date: {moment(quote.createdAt).format('yyyy-MM-DD')} </CardText>
                                        <CardText>Reference: {`${quote.reference || 'N/A'}`}</CardText>
                                        <CardText>
                                            Status:{' '}
                                            <FormControl variant='standard' className='ml-1'>
                                                <Select
                                                    className='bare-dropdown'
                                                    labelId='demo-simple-select-label'
                                                    id='demo-simple-select'
                                                    value={quote.status.value}
                                                    disabled={quoteItems.length === 0}
                                                    label='Status'
                                                    onChange={handleStatusChange}>
                                                    {statusList.map((status) => (
                                                        <MenuItem key={status.value} value={status.value}>
                                                            {status.title}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </CardText>
                                        <CardText>
                                            Type:{'  '}
                                            <FormControl variant='standard' className='ml-1'>
                                                <Select
                                                    className='bare-dropdown'
                                                    labelId='demo-simple-select-label'
                                                    id='demo-simple-select'
                                                    value={quote.type.value}
                                                    label='Payment Status'
                                                    onChange={handleTypeChange}>
                                                    {types.map((type) => (
                                                        <MenuItem key={type.value} value={type.value}>
                                                            {type.title}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </CardText>
                                    </Col>
                                    <Col md='12' lg='6'>
                                        <CardTitle>Account Information</CardTitle>
                                        <CardText> Company: {quote.client?.companyName}</CardText>
                                        <CardText> VAT Number: {quote.client?.vatNumber} </CardText>
                                        <CardText>
                                            {' '}
                                            Name:{'  '}
                                            {`${quote.client.user.name} ${quote.client.user.surname}`}
                                        </CardText>
                                        <CardText> Email: {quote.client?.user.email} </CardText>
                                        <CardText> Contact Number: {quote.client.user.contactNumber}</CardText>
                                        {/* <CardText>
                                            <Select
                                                variant='standard'
                                                sx={{ width: '100%' }}
                                                placeholder='Select member'
                                                disabled={addingMember || removing}
                                                onChange={handleSelectOnChange}
                                                defaultValue={'empty'}>
                                                <MenuItem disabled value={'empty'}>
                                                    <span className='placeholder-text'>Select member</span>
                                                </MenuItem>
                                                {client.members.map((member) => {
                                                    return (
                                                        <MenuItem
                                                            key={member.id}
                                                            value={
                                                                member.id
                                                            }>{`${member.user.name} ${member.user.surname}`}</MenuItem>
                                                    );
                                                })}
                                            </Select>

                                            {quote.nominatedMembers.map((nom) => {
                                                return (
                                                    <React.Fragment key={nom.member.id}>
                                                        <Chip
                                                            sx={{ marginTop: 1 }}
                                                            disabled={removing}
                                                            label={`${nom.member?.user.name} ${nom.member.user.surname}`}
                                                            onDelete={() => {
                                                                if (removing) return;

                                                                removedMember({
                                                                    variables: {
                                                                        id: nom.id,
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                    </React.Fragment>
                                                );
                                            })}
                                        </CardText> */}
                                    </Col>
                                    <Col className='mt-4' md='12' lg='10'>
                                        <CardTitle>Quote Items</CardTitle>
                                        {quoteItems.map((item) => {
                                            return (
                                                <QuoteItemInfo
                                                    onClick={() => {
                                                        if (!item.booking) {
                                                            return;
                                                        }

                                                        setBookingId(item.booking.id);
                                                        setOpenDialog(true);
                                                    }}
                                                    key={item.id}
                                                    item={item}
                                                />
                                            );
                                        })}
                                        <div className='mt-4 text-right'>
                                            <p className='order-subtotal'>Sub Total - R{subTotal?.toFixed(2)}</p>
                                            <p className='order-subtotal'>VAT - R{vat?.toFixed(2)}</p>
                                            <h3 className='order-total'>Total - R{totalAmount?.toFixed(2)}</h3>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className='d-flex mt-2'>
                                            <div className='align-self-end ml-auto'>
                                                <Button
                                                    type='button'
                                                    disabled={
                                                        quote.status.value === 'cancelled' ||
                                                        quote.status.value === 'accepted'
                                                    }
                                                    className='btn btn-outline-dark'
                                                    onClick={() => setLinkDialog(true)}>
                                                    <span>Add existing</span>
                                                </Button>
                                                <Button
                                                    type='button'
                                                    className='btn btn-dark'
                                                    disabled={
                                                        quote.status.value === 'cancelled' ||
                                                        quote.status.value === 'accepted'
                                                    }
                                                    onClick={() => {
                                                        setBookingId(undefined);
                                                        setOpenDialog(true);
                                                    }}>
                                                    <span>Add new</span>
                                                </Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default QuoteDetails;
