import { Dialog, DialogTitle } from '@mui/material';
import React from 'react';
import useToast from '../../../../hooks/useToast';
import { Client } from '../../../../models/client.model';
import { QuoteStatus, QuoteType } from '../../../../models/quotes.models';
import AddQuoteForm from '../components/AddQuoteForm';

interface AddQuoteProps {
    isOpen: boolean;
    handleClose: () => void;
    clients: Client[];

    quoteTypes: QuoteType[];
    quoteStatus: QuoteStatus[];
}

const AddQuoteDialog = ({ isOpen, handleClose, clients, quoteStatus, quoteTypes }: AddQuoteProps) => {
    const { showToast, Notification } = useToast();
    return (
        <div>
            {Notification}
            <Dialog open={isOpen} onClose={handleClose}>
                <DialogTitle> Add Quote</DialogTitle>
                <AddQuoteForm
                    handleClose={handleClose}
                    clients={clients}
                    quoteStatus={quoteStatus}
                    quoteTypes={quoteTypes}
                    onSuccess={() => {
                        showToast('Quote added successfully', 'success');
                    }}
                />
            </Dialog>
        </div>
    );
};

export default AddQuoteDialog;
