/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FormGroup, Spinner } from "reactstrap";
import { ASSIGN_CHECKLIST_ITEM } from "../../../../graphql/meeting-room-checklist.graphql";
import { GET_MEETING_ROOM } from "../../../../graphql/meeting-rooms.graphql";
import useToast from "../../../../hooks/useToast";
import {
  CheckListAddOn,
  RoomCheckListItem,
} from "../../../../models/meeting.room.model";

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  checkListAddOns?: CheckListAddOn[];
  itemId?: string;
}
const AddChecklistAdOnDialog = ({
  isOpen,
  handleClose,
  checkListAddOns,
  itemId,
}: Props) => {
  const params: any = useParams();
  const { data } = useQuery(GET_MEETING_ROOM, {
    variables: {
      id: params.id,
    },
  });

  const [
    assignChecklistItem,
    { loading: assigningChecklist, data: assignedChecklist, error },
  ] = useMutation(ASSIGN_CHECKLIST_ITEM, {
    refetchQueries: [{ query: GET_MEETING_ROOM }, "GetMeetingRoom"],
  });

  const [checklistItemId, setChecklistItemId] = useState<string>();
  const checkListItem: RoomCheckListItem[] = data?.room.checkListItems;
  const { showToast, Notification } = useToast();

  useEffect(() => {
    if (assignedChecklist && !error) {
      showToast("Successfully assigned checklist item", "success");
    }

    if (!assignedChecklist && error) {
      showToast(
        "An error has occurred while trying to assign a checklist item",
        "danger"
      );
    }
  }, [assignedChecklist, error]);

  let items = checkListItem.filter((item) => {
    if (checkListAddOns?.length === 0) {
      return item;
    }

    return (
      checkListAddOns.findIndex(
        (addOn) => addOn.checkListItem.id === item.id
      ) === -1
    );
  });

  if (checkListItem.length === checkListAddOns?.length) {
    console.log("Full");
    items = [];
  }

  const assignItem = async () => {
    const item = {
      meeting_room_item_id: itemId,
      checklist_item_id: checklistItemId,
    };

    assignChecklistItem({
      variables: {
        item: item,
      },
    });
  };

  return (
    <div>
      {Notification}
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>
          <h2>Assign checklist item</h2>
        </DialogTitle>
        <DialogContent>
          <FormGroup>
            <label>Select checklist item</label>
            <Select
              id="type"
              className="form-control"
              sx={{ borderRadius: "12px", padding: "0" }}
              placeholder="Select a type"
              value={checklistItemId}
              onChange={(e) => setChecklistItemId(e.target.value)}
            >
              {items.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormGroup>
        </DialogContent>
        <DialogActions>
          {!assigningChecklist && (
            <Button
              onClick={() => assignItem()}
              disabled={!checklistItemId || checklistItemId === ""}
            >
              Assign
            </Button>
          )}
          {assigningChecklist && (
            <Button>
              <Spinner />
            </Button>
          )}
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddChecklistAdOnDialog;
