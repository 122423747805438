import moment from "moment";
import React from "react";
import UserAvatar from "../../../../components/UserAvatar";
import { TaskEvent } from "../../../../models/tasks.model";

interface TaskEventItemProps {
  taskEvent: TaskEvent;
  userId: string;
  isLastChild?: boolean;
}

const TaskEventItem = ({
  taskEvent,
  userId,
  isLastChild,
}: TaskEventItemProps) => {
  const getUserName = (): string => {
    if (userId === taskEvent?.user?.id) {
      return "You";
    }

    return `${taskEvent?.user?.name} ${taskEvent?.user?.surname}`;
  };
  return (
    <>
      <div className="row">
        <div className="col-1">
          <UserAvatar
            name={`${taskEvent.user?.name} ${taskEvent.user?.surname}`}
          />
          {!isLastChild && <div className="vertical-line"></div>}
        </div>
        <div className="col-7">
          <h5>
            {`${getUserName()}  `}
            <span className="card-text">{taskEvent?.description}</span>
          </h5>
        </div>
        <div className="col-4 text-right">
          <span className="card-text">
            {moment(taskEvent?.createdAt).fromNow()}
          </span>
        </div>
      </div>
    </>
  );
};

export default TaskEventItem;
