/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from "@apollo/client";
import { LinearProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Container,
  Input,
} from "reactstrap";
import BasicMenu from "../../../components/Menu";
import { GET_USERS } from "../../../graphql/queries";
import { REMOVE_USER } from "../../../graphql/user.graphql";
import useToast from "../../../hooks/useToast";
import { User } from "../../../models/user.model";
import { getPermission } from "../../../utils/user.utils";
import _ from "lodash";
import { useLocalStorage } from "../../../hooks/local-storage.hook";

interface UserRouteData {
  keyWord: string;
  page: number;
  pageSize: number;
}

function Tables() {
  const { showToast, Notification } = useToast();
  const [alert, setAlert] = useState<any>(null);
  const [getLocalStorage, setLocalStorage] = useLocalStorage("user");
  const { loading, error, data } = useQuery(GET_USERS);
  const [page, setPage] = useState<number>(0);
  const [userFilter, setUserFilter] = useState<string>("");
  const [rowPerPage, setRowsPerPage] = useState<number>(10);
  const [sortDirection, setSortDirection] = useState<string>("asc");
  const [sortFiled, setSortField] = useState<string>("name");
  const [
    removeUserMutation,
    { loading: isRemoving, error: removeError, called },
  ] = useMutation(REMOVE_USER, {
    // refetchQueries: [{ query: GET_USERS }, "GetUsers"],
    refetchQueries: ["GetUsers"],
  });
  const portalUser: User | null = useSelector(
    (state: any) => state.user.userInfo
  );

  console.log("portalUser", portalUser);
  const navigate = useNavigate();

  useEffect(() => {
    if (error || removeError) {
      showToast("An error has occurred, please try again", "danger");
    }
  }, [error, removeError]);

  useEffect(() => {
    if (!isRemoving && called && !removeError) {
      showToast("Successfully removed user", "success");
    }
  }, [isRemoving, called]);

  useEffect(() => {
    console.log("ALERT", alert);
  }, [alert]);

  const removeUser = (id: string) => {
    removeUserMutation({
      variables: { id },
    });
  };

  useEffect(() => {
    const routeData: UserRouteData = getLocalStorage();

    if (routeData) {
      setPage(routeData?.page || 0);
      setRowsPerPage(routeData?.pageSize || 10);
      setUserFilter(routeData?.keyWord || "");
    }
  }, []);

  const sortBy: any = (users: User[], field: string, direction: string) => {
    const orderedBookings = new Map([
      [
        "name",
        _.orderBy(users, (user) => user.name, [
          direction === "asc" ? "asc" : "desc",
        ]),
      ],
      [
        "email",
        _.orderBy(users, (user) => user.email, [
          direction === "asc" ? "asc" : "desc",
        ]),
      ],
      [
        "contactNumber",
        _.orderBy(users, (user) => user.contactNumber, [
          direction === "asc" ? "asc" : "desc",
        ]),
      ],
      [
        "role",
        _.orderBy(users, (user) => user.role.name, [
          direction === "asc" ? "asc" : "desc",
        ]),
      ],
    ]);
    return orderedBookings.get(field);
  };

  const users: User[] = data?.users || [];
  console.log("data", data);
  let filteredUsers = users.filter((user: User) => {
    const isUserFiltered =
      user.contactNumber.toLowerCase().includes(userFilter.toLowerCase()) ||
      user.name.toLowerCase().includes(userFilter.toLowerCase()) ||
      user.surname.toLowerCase().includes(userFilter.toLowerCase()) ||
      user.email.toLowerCase().includes(userFilter.toLowerCase()) ||
      user.role.name.toLowerCase().includes(userFilter.toLowerCase());

    return isUserFiltered;
  });

  filteredUsers = sortBy(filteredUsers, sortFiled, sortDirection);

  const displayAlert = (props: any) => {
    setAlert(
      <SweetAlert
        {...props}
        showCancel
        confirmBtnText="Continue"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => {
          removeUser(props.user.id);
          setAlert(null);
        }}
        onCancel={() => {
          setAlert(null);
        }}
        focusCancelBtn
      >
        This user will be removed from the system.
      </SweetAlert>
    );
  };

  const getRowValue = (value: string) => {
    setRowsPerPage(parseInt(value));
    const routeData: UserRouteData = getLocalStorage() || {};
    routeData.pageSize = parseInt(value);
    if (parseInt(value) !== rowPerPage) {
      setPage(0);
      routeData.page = 0;
    }
    setLocalStorage(routeData);
  };

  const nextPage = () => {
    setPage(page + 1);
    const routeData: UserRouteData = getLocalStorage() || {};
    routeData.page = page + 1;
    setLocalStorage(routeData);
  };
  const previousPage = () => {
    setPage(page - 1);
    const routeData: UserRouteData = getLocalStorage() || {};
    routeData.page = page - 1;
    setLocalStorage(routeData);
  };

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <>
      {Notification}
      {alert}
      <Container className="mt-4" fluid>
        <Row>
          <Col>
            <h1>Users</h1>
          </Col>
        </Row>
        <Row className="mt-4">
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Users</h3>
                  </Col>
                  {getPermission(portalUser, "users_management", "create") && (
                    <Col className="text-right" xs="6">
                      <Link
                        to={"/admin/users/new"}
                        className="btn btn-round btn-sm btn-dark"
                        color="default"
                        id="tooltip969372949"
                      >
                        <span className="btn-inner--text">Add user</span>
                      </Link>
                      <UncontrolledTooltip delay={0} target="tooltip969372949">
                        Add user
                      </UncontrolledTooltip>
                    </Col>
                  )}
                </Row>
                <Row className="mt-4 border-0">
                  <Col>
                    <Input
                      className="form-control w-25"
                      type="text"
                      value={userFilter}
                      placeholder="Search keyword..."
                      onChange={(e) => {
                        setUserFilter(e.target.value);
                        setPage(0);

                        const routeData: UserRouteData =
                          getLocalStorage() || {};
                        routeData.keyWord = e.target.value;
                        routeData.page = 0;
                        setLocalStorage(routeData);
                      }}
                    />
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th
                      onClick={() => {
                        setSortField("name");
                        setSortDirection(
                          sortDirection === "asc" ? "desc" : "asc"
                        );
                      }}
                      className="sort"
                      data-sort="name"
                      scope="col"
                    >
                      Name and Surname
                    </th>
                    <th
                      onClick={() => {
                        setSortField("email");
                        setSortDirection(
                          sortDirection === "asc" ? "desc" : "asc"
                        );
                      }}
                      className="sort"
                      data-sort="email"
                      scope="col"
                    >
                      Email
                    </th>
                    <th
                      onClick={() => {
                        setSortField("contactNumber");
                        setSortDirection(
                          sortDirection === "asc" ? "desc" : "asc"
                        );
                      }}
                      className="sort"
                      data-sort="contactNumber"
                      scope="col"
                    >
                      Contact Number
                    </th>
                    <th
                      onClick={() => {
                        setSortField("role");
                        setSortDirection(
                          sortDirection === "asc" ? "desc" : "asc"
                        );
                      }}
                      className="sort"
                      data-sort="role"
                      scope="col"
                    >
                      Role
                    </th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody className="list">
                  {filteredUsers
                    .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                    .map((user: User) => {
                      return (
                        <tr
                          key={user.id}
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            navigate(`/admin/users/edit/${user.id}`)
                          }
                        >
                          <td>{`${user.name} ${user.surname}`}</td>
                          <td>{user.email}</td>
                          <td>{user.contactNumber}</td>
                          <td>{user.role?.name}</td>
                          <td className="text-right">
                            {getPermission(
                              portalUser,
                              "users_management",
                              "update"
                            ) && (
                              <Link
                                className="btn btn-info btn-icon-only rounded-circle btn-sm"
                                to={`/admin/users/edit/${user.id}`}
                              >
                                <i className="fa-solid fa-pencil"></i>
                              </Link>
                            )}
                            {getPermission(
                              portalUser,
                              "users_management",
                              "delete"
                            ) && (
                              <Button
                                className="btn btn-danger btn-icon-only rounded-circle btn-sm"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  displayAlert({ warning: true, user });
                                }}
                              >
                                <i className="fa-solid fa-trash-can"></i>
                              </Button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <Row>
                  <div className="col-4 d-flex">
                    <span style={{ fontSize: "12px" }}>Rows per page:</span>
                    <BasicMenu
                      getValue={getRowValue}
                      options={["10", "20", "25"]}
                      value={rowPerPage.toString()}
                    />
                  </div>
                  <div className="col-4 d-flex">
                    <span className="min-text">Page:</span>
                    <span className="min-text pl-2">
                      {page + 1} of{" "}
                      {Math.ceil(filteredUsers.length / rowPerPage)}
                    </span>
                  </div>
                  <div className="col-4 d-flex">
                    <Pagination>
                      <PaginationItem className={page <= 0 ? "disabled" : ""}>
                        <PaginationLink onClick={(e) => previousPage()}>
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem
                        className={
                          page >=
                          Math.ceil(filteredUsers.length / rowPerPage) - 1
                            ? "disabled"
                            : ""
                        }
                      >
                        <PaginationLink onClick={(e) => nextPage()}>
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </div>
                </Row>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default Tables;
