/* eslint-disable react-hooks/exhaustive-deps */
import { LinearProgress, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactDatetimeClass from "react-datetime";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Row,
} from "reactstrap";
import { GET_CLIENT } from "../../../graphql/clients.graphql";
import {
  UPDATE_BOOKING,
  GET_BOOKING,
  REMOVE_BOOKING_GUEST,
} from "../../../graphql/bookings.graphql";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import useToast from "../../../hooks/useToast";
import { MeetingRoomItem } from "../../../models/meeting.room.model";
import {
  Booking,
  BookingGuest,
  BookingStatus,
} from "../../../models/bookings.model";
import { useForm } from "react-hook-form";
import Spinner from "../../../components/Spinner/Spinner";
import moment, { isMoment } from "moment";
import { bookingTimeList } from "../../../variables/variables";
import { getTime } from "../../../utils/booking.utils";
import EmailChipListInput from "./components/EmailChipListInput";
import { CHECK_MEETING_ROOM_ITEM_AVAILABILITY } from "../../../graphql/meeting-rooms.graphql";

interface ClientManageFormProps {
  backToBookings(): void;
  bookingId: string;
}

type FormData = {
  subject: string;
  from: string;
  to: string;
  date: string;
  status: string;
  meetingItem: string;
};

const ClientBookingManageForm = ({
  backToBookings,
  bookingId,
}: ClientManageFormProps) => {
  const [guests, setGuests] = useState<BookingGuest[]>([]);
  const { loading, error, data } = useQuery(GET_BOOKING, {
    variables: {
      id: bookingId,
    },
  });

  const [
    updateMutation,
    { loading: isUpdating, error: errorUpdating, data: updatedBooking },
  ] = useMutation(UPDATE_BOOKING, {
    refetchQueries: [
      { query: GET_CLIENT },
      "GetClient",
      { query: GET_BOOKING },
      "GetBooking",
    ],
  });

  const [
    checkMeetingRoom,
    { loading: checking, error: checkingError, data: meetingRoom },
  ] = useLazyQuery(CHECK_MEETING_ROOM_ITEM_AVAILABILITY);
  const [buttonText, setButtonText] = useState<string>("Check Availability");
  const [isRoomAvailable, setIsRoomAvailable] = useState<boolean>(true);
  const [additionalNotes, setAdditionalNotes] = useState<string>("");
  const [removeBookingGuest] = useMutation(REMOVE_BOOKING_GUEST);

  const [endingTime, setEndingTime] = useState<string>("");
  const [startingTime, setStarting] = useState<string>("");

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<FormData>();

  const { showToast, Notification } = useToast();

  useEffect(() => {
    if (error) {
      showToast("An error has occurred, please refresh the page.", "danger");
    }

    if (errorUpdating) {
      showToast(
        "An error has occurred while tying to update this booking, please try again",
        "danger"
      );
    }

    if (checkingError) {
      showToast(
        "An error has occurred while trying to check for a booking",
        "danger"
      );
    }
  }, [error, errorUpdating, checkingError]);

  useEffect(() => {
    if (updatedBooking) {
      showToast("Successfully updated booking.", "success");
    }
  }, [updatedBooking]);

  useEffect(() => {
    if (!loading && data) {
      const booking = data.booking;
      setGuests(booking.guests);
      if (booking.notes !== null) setAdditionalNotes(booking.notes);
    }

    if (meetingRoom?.booking) {
      setButtonText(
        meetingRoom?.booking.isAvailable
          ? "Check availability"
          : "Not available"
      );
      setIsRoomAvailable(meetingRoom?.booking.isAvailable);
    }
  }, [loading, data, meetingRoom]);

  const populateEndingTimeForm = () => {
    const startTime = getValues("from");

    if (getValues("to") !== "") {
      const endTime = getValues("to");
      const startingTimeIndex = bookingTimeList.indexOf(startTime);
      const endingTimeIndex = bookingTimeList.indexOf(endTime);
      if (startingTimeIndex >= endingTimeIndex) {
        return;
      }
    }
    setValue("from", startTime);
    setStarting(startTime);

    const nextTimeSlotIndex = bookingTimeList.indexOf(startTime);
    if (getValues("to") === "") {
      setValue("to", bookingTimeList[nextTimeSlotIndex + 1]);
      setEndingTime(bookingTimeList[nextTimeSlotIndex + 1]);
    }
    console.log("ending time", getValues("to"));
  };

  const onChangeEndingTime = () => {
    const startTime = getValues("from");
    const endTime = getValues("to");

    const startingTimeIndex = bookingTimeList.indexOf(startTime);
    const endingTimeIndex = bookingTimeList.indexOf(endTime);

    if (endingTimeIndex <= startingTimeIndex) {
      showToast(
        "The starting time cannot be ahead of the ending time",
        "danger"
      );
      return;
    }

    setValue("to", endTime);
    setEndingTime(endTime);
  };

  const onSubmit = (data: FormData) => {
    console.log("DATA", data);
    const timeFrom = moment(data.date)
      .utc(true)
      .hours(getTime(data.from)[0])
      .toISOString();
    const timeTo = moment(data.date)
      .utc(true)
      .hours(getTime(data.to)[0])
      .toISOString();

    if (!isRoomAvailable) {
      checkMeetingRoom({
        variables: {
          meetingRoomItemId: data.meetingItem,
          from: timeFrom,
          to: timeTo,
        },
      });
      return;
    }

    const booking: object = {
      subject: data.subject,
      time_from: timeFrom,
      time_to: timeTo,
      status_enum: data.status,
      meeting_room_item_id: data.meetingItem,
      additional_notes:
        additionalNotes !== "" || additionalNotes !== null
          ? additionalNotes
          : null,
    };

    const bookingGuests = guests.map((item) => ({
      booking_id: bookingId,
      email: item.email,
    }));

    console.log("BOOKING", booking);

    updateMutation({
      variables: {
        id: bookingId,
        booking: booking,
        guests: bookingGuests,
      },
    });
  };

  const onAddGuest = (guest: BookingGuest) => {
    setGuests([...guests, guest]);
  };

  const removeGuest = (guest: BookingGuest) => {
    if (guest.id) {
      removeBookingGuest({ variables: { guestId: guest.id } });
    }
    const newGuests = guests.filter((item) => item.email !== guest.email);
    setGuests(newGuests);
  };

  const onInputChange = () => {
    setIsRoomAvailable(false);
  };

  if (loading) {
    return <LinearProgress />;
  }

  const meetingRoomItems: MeetingRoomItem[] = data?.meetingRoomItems;
  const bookingStatuses: BookingStatus[] = data?.status;
  const booking: Booking = data?.booking;

  return (
    <>
      {Notification}
      <Row>
        <Col md="12" lg="6">
          <Card className="mt-4">
            <CardHeader>
              <Row noGutters={true}>
                <button
                  className="btn btn-outline-primary btn-sm mr-4"
                  onClick={() => backToBookings()}
                >
                  <i
                    className="fas fa-angle-left"
                    style={{ fontSize: "14px" }}
                  />
                  <span className="btn-inner-text">Back</span>
                </button>
              </Row>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-md-12 col-lg-6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="p-member-name"
                      >
                        Subject
                      </label>
                      <input
                        {...register("subject", {
                          required: true,
                          value: booking.subject,
                        })}
                        className="form-control"
                        id="p-member-name"
                        placeholder="Enter booking's subject..."
                        type="text"
                      />
                      {errors.subject && (
                        <span className="invalid">*This field is required</span>
                      )}
                    </FormGroup>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-lg-6">
                    <FormGroup>
                      <label className="form-control-label">Date</label>
                      <ReactDatetimeClass
                        inputProps={{
                          placeholder: "Select booking date...",
                          ...register("date", {
                            required: true,
                            value: moment(booking.from).format("yyyy-MM-DD"),
                          }),
                        }}
                        initialValue={moment(booking.from).format("yyyy-MM-DD")}
                        dateFormat={"YYYY-MM-DD"}
                        timeFormat={false}
                        onChange={(value) => {
                          if (value && isMoment(value)) {
                            setValue("date", value.format("yyyy-MM-DD"));
                          }
                          onInputChange();
                        }}
                      />
                      {errors.date && (
                        <span className="invalid">*This field is required</span>
                      )}
                    </FormGroup>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-lg-6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="email">
                        From
                      </label>
                      <Select
                        id="from"
                        onChange={() => {
                          populateEndingTimeForm();
                          onInputChange();
                        }}
                        sx={{ borderRadius: "12px", padding: "0" }}
                        className="form-control"
                        value={
                          startingTime
                            ? startingTime
                            : moment(booking.from).format("HH:mm")
                        }
                        defaultValue={moment(booking.from).format("HH:mm")}
                        inputProps={{
                          ...register("from", {
                            required: true,
                            value: moment(booking.from).format("HH:mm"),
                          }),
                        }}
                      >
                        {bookingTimeList
                          .filter((time) => {
                            return (
                              bookingTimeList.indexOf(time) <
                              bookingTimeList.indexOf(
                                endingTime
                                  ? endingTime
                                  : moment(booking.to).format("HH:mm")
                              )
                            );
                          })
                          .map((time) => {
                            return (
                              <MenuItem key={time} value={time}>
                                {time}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      {errors.from && (
                        <span className="invalid">*This field is required</span>
                      )}
                    </FormGroup>
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="contact">
                        To
                      </label>
                      <Select
                        id="room"
                        onChange={() => {
                          onChangeEndingTime();
                          onInputChange();
                        }}
                        sx={{ borderRadius: "12px", padding: "0" }}
                        className="form-control"
                        defaultValue={moment(booking.to).format("HH:mm")}
                        value={
                          endingTime
                            ? endingTime
                            : moment(booking.to).format("HH:mm")
                        }
                        inputProps={{
                          ...register("to", {
                            required: true,
                            value: moment(booking.to).format("HH:mm"),
                          }),
                        }}
                      >
                        {bookingTimeList
                          .filter((time) => {
                            const startTimeIndex = bookingTimeList.indexOf(
                              getValues("from")
                            );

                            return (
                              bookingTimeList.indexOf(time) > startTimeIndex
                            );
                          })
                          .map((time) => {
                            return (
                              <MenuItem key={time} value={time}>
                                {time}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      {errors.to && (
                        <span className="invalid">*This field is required</span>
                      )}
                    </FormGroup>
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="status">
                        Status
                      </label>
                      <Select
                        sx={{ borderRadius: "12px", padding: "0" }}
                        id="status"
                        className="form-control"
                        placeholder="Select booking status..."
                        defaultValue={booking.status.value}
                        onChange={onInputChange}
                        inputProps={{
                          ...register("status", {
                            required: true,
                            value: booking.status.value,
                          }),
                        }}
                      >
                        {bookingStatuses.map((_status) => {
                          return (
                            <MenuItem key={_status.value} value={_status.value}>
                              {_status.title}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {errors.status && (
                        <span className="invalid">*This field is required</span>
                      )}
                    </FormGroup>
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <FormGroup>
                      <label className="form-control-label">Meeting Room</label>
                      <Select
                        id="status"
                        sx={{ borderRadius: "12px", padding: "0" }}
                        className="form-control"
                        defaultValue={booking.meetingRoomItem.id}
                        onChange={onInputChange}
                        inputProps={{
                          ...register("meetingItem", {
                            required: true,
                            value: booking.meetingRoomItem.id,
                          }),
                        }}
                      >
                        {meetingRoomItems.map((item: MeetingRoomItem) => {
                          return (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {errors.meetingItem && (
                        <span className="invalid">*This field is required</span>
                      )}
                    </FormGroup>
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <div className="form-group">
                      <label
                        className="form-control-label"
                        htmlFor="exampleFormControlTextarea1"
                      >
                        Notes
                      </label>
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows={3}
                        cols={3}
                        value={additionalNotes}
                        onChange={(e) => {
                          setAdditionalNotes(e.target.value);
                        }}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <EmailChipListInput
                      label="Guests"
                      placeholder="Enter email address"
                      onAddItem={onAddGuest}
                      onRemoveitem={removeGuest}
                      items={guests}
                    />
                  </div>
                </div>

                <div className="d-flex h-100">
                  <div className="align-self-end ml-auto">
                    {isRoomAvailable ? (
                      <Button
                        type="submit"
                        className="btn btn-dark h-100"
                        disabled={isUpdating}
                      >
                        {isUpdating ? (
                          <span>
                            <Spinner />
                          </span>
                        ) : (
                          <span>Update booking</span>
                        )}
                      </Button>
                    ) : (
                      <Button type="submit" className="btn btn-dark h-100">
                        {checking ? (
                          <span>
                            <Spinner />
                          </span>
                        ) : (
                          <span>{buttonText}</span>
                        )}
                      </Button>
                    )}
                  </div>
                </div>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ClientBookingManageForm;
