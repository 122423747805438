/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Button, Card, CardBody, Col, FormGroup, Row } from 'reactstrap';
import { LinearProgress, MenuItem, Select } from '@mui/material';
import { PriceType, ProductPackage } from '../../../models/packages.models';
import { useForm } from 'react-hook-form';
import { useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { GET_PRODUCTS, GET_PRODUCT_STATUS } from '../../../graphql/products.graphql';
import useToast from '../../../hooks/useToast';
import { Product, ProductStatus } from '../../../models/product.model';
import { GET_PRICE_TYPES, UPDATE_PACKAGE_PRODUCT } from '../../../graphql/packages.graphql';
import Spinner from '../../../components/Spinner/Spinner';
import FeaturedImage from '../../../components/Image component/ImageContainer';
import { uploadImage } from '../../../hooks/upload-image.hook';

interface PackageViewProps {
    _package: ProductPackage;
}

type FormData = {
    name: string;
    description: string;
    price: string;
    unit: string;
    status: string;
    priceType: string;
    product: string;
};

const PackageDetailView = ({ _package }: PackageViewProps) => {
    const { loading: loadingStatus, error: statusError, data: statusData } = useQuery(GET_PRODUCT_STATUS);
    const [image, setImage] = useState<any>(`${process.env.REACT_APP_IMAGE_URL}/${_package.featuredImage}`);
    const { loading: loadingProducts, error: errorLoadingProducts, data: loadedProducts } = useQuery(GET_PRODUCTS);
    const [updating, setUpdating] = useState<boolean>(false);
    const { loading, error, data } = useQuery(GET_PRICE_TYPES);
    const { showToast, Notification } = useToast();
    const fileInputRef: any = useRef(null);
    const [imageFile, setImageFile] = useState<any>(null);
    const [assignable, setAssignable] = useState<boolean>(false);
    const [updatePackageMutation, { error: errorUpdating, data: updatedPackage }] = useMutation(
        UPDATE_PACKAGE_PRODUCT,
        {
            // refetchQueries: [{ query: GET_PRODUCT_PACKAGE }, "GetProductPackage"],
            refetchQueries: ['GetProductPackage'],
        },
    );
    const param: any = useParams();
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<FormData>();

    useEffect(() => {
        register('description', { required: true, value: _package.description });
    }, []);

    useEffect(() => {
        register('description', { required: true });
    });

    useEffect(() => {
        if (statusError || errorLoadingProducts || error) {
            showToast('An error has occurred, please try again', 'danger');
            setUpdating(false);
        }

        if (errorUpdating) {
            showToast('An error has occurred while trying updating this package', 'danger');
            setUpdating(false);
        }
    }, [statusError, errorLoadingProducts, error, errorUpdating]);

    useEffect(() => {
        if (updatedPackage) {
            showToast('Successfully updated package', 'success');
            setUpdating(false);
        }
    }, [updatedPackage]);

    const onSubmit = async (data: FormData) => {
        let imageKey;
        setUpdating(true);

        if (imageFile) imageKey = await uploadImage(imageFile, 'package_images');
        if (!imageFile && _package.featuredImage) imageKey = _package.featuredImage;

        if (imageKey === 'error') {
            showToast('An error has occurred while trying to upload the image', 'danger');
            setUpdating(false);
            return;
        }

        const __package = {
            name: data.name,
            description: data.description,
            unit: data.unit ? data.unit : null,
            price: data.price ? data.price : null,
            price_type_enum: data.priceType,
            status_enum: data.status,
            product_id: data.product,
            featured_image: imageKey,
            is_assignable: assignable,
        };

        updatePackageMutation({
            variables: {
                id: param.id,
                package: __package,
            },
        });
    };

    const handleImageChange = (e: any) => {
        const url = URL.createObjectURL(e.target.files[0]);
        setImage(url);
        setImageFile(e.target.files[0]);
    };

    if (loadingStatus || loadingProducts || loading) {
        return <LinearProgress />;
    }

    const packageStatusList: ProductStatus[] = statusData?.status;
    const products: Product[] = loadedProducts?.products;
    const priceTypes: PriceType[] = data?.priceTypes;

    console.log('Checked is :', _package);

    return (
        <>
            {Notification}
            <Row className='mt-4'>
                <Col md='12' lg='6'>
                    <Card>
                        <CardBody>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <FormGroup>
                                    <label className='form-control-label'>Featured Image</label>
                                    <FeaturedImage fileInputRef={fileInputRef} imageSource={image} />
                                    <input
                                        ref={fileInputRef}
                                        type='file'
                                        accept='image/*'
                                        onChange={(e) => {
                                            handleImageChange(e);
                                        }}
                                        style={{ display: 'none' }}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='name'>
                                        Name
                                    </label>
                                    <input
                                        className='form-control'
                                        id='product'
                                        placeholder="Enter package's name..."
                                        {...register('name', {
                                            value: _package.name,
                                            required: true,
                                        })}
                                        type='text'
                                    />
                                    {errors.name && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='description'>
                                        Description
                                    </label>
                                    <CKEditor
                                        id='description'
                                        data={_package.description}
                                        editor={ClassicEditor}
                                        config={{
                                            removePlugins: ['Heading'],
                                            toolbar: [
                                                'bold',
                                                'italic',
                                                'bulletedList',
                                                'numberedList',
                                                'blockQuote',
                                                'link',
                                            ],
                                        }}
                                        onChange={(event: any, editor: any) => {
                                            setValue('description', editor.getData());
                                        }}
                                        onReady={(editor: any) => {}}
                                    />
                                    {errors.description && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='price'>
                                        Price
                                    </label>
                                    <input
                                        className='form-control'
                                        id='price'
                                        placeholder="Enter package's name..."
                                        type='text'
                                        {...register('price', {
                                            value: _package.price,
                                            required: false,
                                        })}
                                    />
                                    {/* {errors.price && (
                    <span className="invalid">*This field is required</span>
                  )} */}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='unit'>
                                        Unit
                                    </label>
                                    <input
                                        className='form-control'
                                        id='unit'
                                        placeholder="Enter package's unit..."
                                        type='text'
                                        {...register('unit', {
                                            value: _package.unit,
                                            required: false,
                                        })}
                                    />
                                    {/* {errors.unit && (
                    <span className="invalid">*This field is required</span>
                  )} */}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='status'>
                                        Status
                                    </label>
                                    <Select
                                        id='status'
                                        sx={{ borderRadius: '12px', padding: '0' }}
                                        className='form-control'
                                        placeholder='Select a role'
                                        defaultValue={_package.status.value}
                                        inputProps={{
                                            ...register('status', {
                                                value: _package.status.value,
                                                required: true,
                                            }),
                                        }}>
                                        {packageStatusList.map((status: ProductStatus) => {
                                            return (
                                                <MenuItem key={status.value} value={status.value}>
                                                    {status.title}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    {errors.status && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='price-type'>
                                        Price type
                                    </label>
                                    <Select
                                        id='price-type'
                                        sx={{ borderRadius: '12px', padding: '0' }}
                                        className='form-control'
                                        defaultValue={_package.priceType.value}
                                        placeholder='Select a role'
                                        inputProps={{
                                            ...register('priceType', {
                                                value: _package.priceType.value,
                                                required: true,
                                            }),
                                        }}>
                                        {priceTypes.map((type: PriceType) => {
                                            return (
                                                <MenuItem key={type.value} value={type.value}>
                                                    {type.title}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    {errors.priceType && <span>*This field is required</span>}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='price-type'>
                                        Product
                                    </label>
                                    <Select
                                        id='price-type'
                                        sx={{ borderRadius: '12px', padding: '0' }}
                                        className='form-control'
                                        placeholder='Select a role'
                                        defaultValue={_package.product.id}
                                        inputProps={{
                                            ...register('product', {
                                                required: true,
                                                value: _package.product.id,
                                            }),
                                        }}>
                                        {products.map((product) => {
                                            return (
                                                <MenuItem key={product.id} value={product.id}>
                                                    {product.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    {errors.product && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                                <FormGroup>
                                    <div className='d-flex h-25 w-25'>
                                        <input
                                            type='checkbox'
                                            checked={_package.isAssignable}
                                            onChange={(e) => {
                                                setAssignable(e.target.checked);
                                            }}
                                        />
                                        <label className='ml-2 mt-2'>Assignable</label>
                                    </div>
                                </FormGroup>
                                <div className='d-flex h-100'>
                                    <div className='align-self-end ml-auto'>
                                        <Button type='submit' className='btn btn-dark mt-4' disabled={updating}>
                                            {updating ? (
                                                <span>
                                                    <Spinner />
                                                </span>
                                            ) : (
                                                <span>Update package</span>
                                            )}
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default PackageDetailView;
