import { gql } from "@apollo/client";

export const GET_MENU_DETAILS = gql`
  query getMenuDetails($id: uuid) {
    menu: menu_package(
      where: { vendor_id: { _eq: $id }, deleted_at: { _is_null: true } }
    ) {
      id
      name
      vendor_id
      description
      price
      featuredImage: featured_image
      packageRates: menu_package_menu_package_rates(
        where: { deleted_at: { _is_null: true } }
      ) {
        id
        price
        currency: menu_package_rate_menu_package_currency_type {
          title
          value
        }
        currency_enum
        minimumLimit: lower_limit
        maximumLimit: upper_limit
      }
      menuType: menu_package_menu_package_type {
        title
        value
      }
      menuStatus: menu_package_menu_package_status {
        value
        title
      }
      menuMealType: menu_package_menu_package_meal_type {
        value
        title
      }
      menuCategory: menu_package_menu_package_category {
        value
        title
      }
    }
  }
`;

export const ADD_MENU_PACKAGE = gql`
  mutation AddMenuPackge($package: menu_package_insert_input!) {
    insert_menu_package_one(
      object: $package
      on_conflict: {
        constraint: menu_package_pkey
        update_columns: [
          name
          description
          price
          category_enum
          meal_type_enum
          status_enum
          type_enum
          featured_image
        ]
      }
    ) {
      id
    }
  }
`;

export const GET_PACKAGE_TYPES = gql`
  {
    menuCategory: menu_package_category {
      title
      value
    }
    menuMealType: menu_package_meal_type {
      title
      value
    }
    menuType: menu_package_type {
      title
      value
    }
    menuStatus: menu_package_status {
      title
      value
    }
    rateCurrencyType: menu_package_currency_type {
      title
      value
    }
  }
`;

export const DELETE_MENU_PACKAGE = gql`
  mutation RemoveMenuPackage($id: uuid!) {
    update_menu_package_by_pk(
      pk_columns: { id: $id }
      _set: { deleted_at: now }
    ) {
      id
    }
  }
`;

export const ADD_VENDOR_RATE = gql`
  mutation addVendorRate($rate: menu_package_rate_insert_input!) {
    insert_menu_package_rate_one(
      object: $rate
      on_conflict: {
        constraint: menu_package_rate_pkey
        update_columns: [
          price
          currency_enum
          lower_limit
          upper_limit
          deleted_at
        ]
      }
    ) {
      id
    }
  }
`;

export const REMOVE_VENDOR_RATE = gql`
  mutation removeRate($id: uuid!) {
    update_menu_package_rate_by_pk(
      pk_columns: { id: $id }
      _set: { deleted_at: now }
    ) {
      id
    }
  }
`;

export const GET_MENU_RATES = gql`
  query getMenuPackages($id: uuid!) {
    menuRates: menu_package_rate(
      where: { deleted_at: { _is_null: true }, menu_package_id: { _eq: $id } }
    ) {
      id
      menu_package_id
      price
      currency: menu_package_rate_menu_package_currency_type {
        title
        value
      }
      minimumLimit: lower_limit
      maximumLimit: upper_limit
    }
  }
`;
