/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from '@apollo/client';
import { FormControl, LinearProgress, MenuItem, Select } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useSelector } from 'react-redux';
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table,
} from 'reactstrap';
import BasicMenu from '../../../components/Menu';
import { GET_ORDER_EXPORTS, REMOVE_ORDER_EXPORT, UPDATE_EXPORT_STATUS } from '../../../graphql/orders.graphql';
import useToast from '../../../hooks/useToast';
import { OrderExport, OrderExportStatus, OrderExportType } from '../../../models/order.model';
import { User } from '../../../models/user.model';
import { downloadPdf } from '../../../utils/file.utils';
import { getPermission } from '../../../utils/user.utils';
import CreateOrderExportDialog from './dialogs/CreateOrderExportDialog';
import { useLocalStorage } from '../../../hooks/local-storage.hook';
import { orderBy } from 'lodash';

interface OrderExportRouteData {
    keyWord: string;
    page: number;
    pageSize: number;
    type: string;
}

export default function OrderExports() {
    const { showToast, Notification } = useToast();
    const [alert, setAlert] = useState<any>(null);
    const [getLocalStorage, setLocalStorage] = useLocalStorage('order-export');
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { loading, error, data } = useQuery(GET_ORDER_EXPORTS);
    const [page, setPage] = useState<number>(0);
    const [selectedType, setSelectedType] = useState<string>('All');
    const [exportsFilter, setExportsFilter] = useState<string>('');
    const [rowPerPage, setRowsPerPage] = useState<number>(10);
    const [sortDirection, setSortDirection] = useState<string>('desc');
    const [sortFiled, setSortField] = useState<string>('datestamp');
    const [removeExport, { loading: isRemoving, error: removeError, called }] = useMutation(REMOVE_ORDER_EXPORT, {
        refetchQueries: ['GetOrderExports'],
    });
    const [updateExportStatus] = useMutation(UPDATE_EXPORT_STATUS, {
        refetchQueries: ['GetOrderExports'],
    });
    const user: User | null = useSelector((state: any) => state.user.userInfo);

    useEffect(() => {
        const routeData: OrderExportRouteData = getLocalStorage();

        if (routeData) {
            setPage(routeData?.page || 0);
            setRowsPerPage(routeData?.pageSize || 10);
            setExportsFilter(routeData?.keyWord || '');
            setSelectedType(routeData?.type || 'All');
        }
    }, []);

    useEffect(() => {
        if (error || removeError) {
            showToast('An error has occurred, please try again', 'danger');
        }
    }, [error, removeError]);

    useEffect(() => {
        if (!isRemoving && called && !removeError) {
            showToast('Successfully removed export', 'success');
        }
    }, [isRemoving, called]);

    useEffect(() => {
        console.log('ALERT', alert);
    }, [alert]);

    const getType = (type: string) => {
        setSelectedType(type);

        setPage(0);
        const exportRouteData: OrderExportRouteData = getLocalStorage() || {};
        exportRouteData.type = type;
        exportRouteData.page = 0;
        setLocalStorage(exportRouteData);
    };

    const sortBy: any = (exports: OrderExport[], field: string, direction: string) => {
        console.log('FIELD', field);
        const orderedExports = new Map([
            [
                'datestamp',
                orderBy(exports, (orderExport) => orderExport?.datestamp, [direction === 'asc' ? 'asc' : 'desc']),
            ],
            [
                'type',
                orderBy(exports, (orderExport) => orderExport?.type.title, [direction === 'asc' ? 'asc' : 'desc']),
            ],
            ['from', orderBy(exports, (orderExport) => orderExport?.from, [direction === 'asc' ? 'asc' : 'desc'])],
            ['to', orderBy(exports, (orderExport) => orderExport?.to, [direction === 'asc' ? 'asc' : 'desc'])],
            [
                'status',
                orderBy(exports, (orderExport) => orderExport?.status.title, [direction === 'asc' ? 'asc' : 'desc']),
            ],
            [
                'createdAt',
                orderBy(exports, (orderExport) => orderExport?.createdAt, [direction === 'asc' ? 'asc' : 'desc']),
            ],
        ]);
        console.log('orderedExports', orderedExports);
        return orderedExports.get(field);
    };

    const exports: OrderExport[] = data?.exports || [];
    const types: OrderExportType[] = data?.types || [];
    const statuses: OrderExportStatus[] = data?.statuses || [];
    console.log('data', data);
    let filteredExports = exports.filter((orderExport: OrderExport) => {
        const isOrderFiltered =
            orderExport.datestamp.toLowerCase().includes(exportsFilter.toLowerCase()) ||
            orderExport.type.title.toLowerCase().includes(exportsFilter.toLowerCase()) ||
            orderExport.status.title.toLowerCase().includes(exportsFilter.toLowerCase()) ||
            orderExport?.userAccount?.companyName?.toLowerCase().includes(exportsFilter.toLowerCase()) ||
            orderExport?.userAccount?.individualName?.toLowerCase().includes(exportsFilter.toLowerCase());

        if (selectedType === 'All') {
            return orderExport && isOrderFiltered;
        }

        if (exportsFilter === '' && selectedType !== 'All') {
            return orderExport.type.title === selectedType && isOrderFiltered;
        }

        return isOrderFiltered;
    });

    filteredExports = sortBy(filteredExports, sortFiled, sortDirection);
    console.log('filteredExports', filteredExports);

    const displayAlert = (props: any) => {
        setAlert(
            <SweetAlert
                {...props}
                showCancel
                confirmBtnText='Continue'
                confirmBtnBsStyle='danger'
                title='Are you sure?'
                onConfirm={() => {
                    removeExport({
                        variables: { exportId: props.orderExport.id },
                    });
                    setAlert(null);
                }}
                onCancel={() => {
                    setAlert(null);
                }}
                focusCancelBtn>
                This export will be removed from the system.
            </SweetAlert>,
        );
    };

    const getRowValue = (value: string) => {
        const routeData: OrderExportRouteData = getLocalStorage() || {};
        setRowsPerPage(parseInt(value));
        routeData.pageSize = parseInt(value);
        if (parseInt(value) !== rowPerPage) {
            setPage(0);
            routeData.page = 0;
        }
        setLocalStorage(routeData);
    };

    const nextPage = () => {
        setPage(page + 1);
        const routeData: OrderExportRouteData = getLocalStorage() || {};
        routeData.page = page + 1;
        setLocalStorage(routeData);
    };
    const previousPage = () => {
        setPage(page - 1);
        const routeData: OrderExportRouteData = getLocalStorage() || {};
        routeData.page = page - 1;
        setLocalStorage(routeData);
    };

    const downloadExport = (path: string, exportId: string) => {
        const url = `${process.env.REACT_APP_IMAGE_URL}/${path}`;
        downloadPdf(url, `${exportId}.pdf`);
    };

    if (loading) {
        return <LinearProgress />;
    }

    return (
        <>
            {Notification}

            {alert}
            {isOpen && (
                <CreateOrderExportDialog
                    isOpen={isOpen}
                    toggleOpen={(isOpen) => {
                        setIsOpen(isOpen);
                    }}
                    types={types}
                />
            )}
            <Container className='mt-4' fluid>
                <Row>
                    <h1 className='mb-0'>Order Exports</h1>
                </Row>
                <Row className='mt-4'>
                    <div className='col'>
                        <Card>
                            <CardHeader className='border-0'>
                                <Row noGutters>
                                    <h3 className='mb-0'>Order Exports</h3>
                                    <Button
                                        color='primary'
                                        size='sm'
                                        className='ml-auto'
                                        onClick={() => {
                                            setIsOpen(true);
                                        }}>
                                        New Export
                                    </Button>
                                </Row>
                                <Row className='mt-4 border-0'>
                                    <Col xs='8'>
                                        <Input
                                            className='form-control w-25'
                                            type='text'
                                            value={exportsFilter}
                                            placeholder='Search keyword...'
                                            onChange={(e) => {
                                                setExportsFilter(e.target.value);
                                                setPage(0);

                                                const routeData: OrderExportRouteData = getLocalStorage() || {};
                                                routeData.keyWord = e.target.value;
                                                routeData.page = 0;
                                                setLocalStorage(routeData);
                                            }}
                                        />
                                    </Col>
                                    <Col xs='4'>
                                        <div className='row'>
                                            <div className='col-6 d-flex'>
                                                <span className='min-text'>Type</span>
                                                <BasicMenu
                                                    getValue={getType}
                                                    options={['All', ...types.map((s) => s.title)]}
                                                    value={selectedType}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <Table className='align-items-center table-flush' responsive>
                                <thead className='thead-light'>
                                    <tr>
                                        <th
                                            onClick={() => {
                                                setSortField('datestamp');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='datestamp'
                                            scope='col'>
                                            Datestamp
                                        </th>
                                        <th
                                            // onClick={() => {
                                            //     setSortField('datestamp');
                                            //     setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            // }}
                                            className='sort'
                                            data-sort='client'
                                            scope='col'>
                                            Client
                                        </th>
                                        <th className='sort' data-sort='client' scope='col'>
                                            Name
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('type');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='type'
                                            scope='col'>
                                            Type
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('from');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='from'
                                            scope='col'>
                                            From
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('to');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='to'
                                            scope='col'>
                                            To
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('createdAt');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='createdAt'
                                            scope='col'>
                                            Created At
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('status');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='status'
                                            scope='col'>
                                            Status
                                        </th>

                                        <th scope='col' />
                                    </tr>
                                </thead>
                                <tbody className='list'>
                                    {filteredExports
                                        .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                                        .map((orderExport: OrderExport) => {
                                            return (
                                                <tr key={orderExport.id}>
                                                    <td>{orderExport.datestamp}</td>
                                                    <td>
                                                        {orderExport.userAccount
                                                            ? orderExport?.userAccount?.companyName ||
                                                              orderExport?.userAccount?.individualName
                                                            : ''}
                                                    </td>
                                                    <td>{orderExport?.name ?? 'Not set'}</td>
                                                    <td>{orderExport.type.title}</td>
                                                    <td>{moment(orderExport.from).format('yyyy-MM-DD')}</td>
                                                    <td>{moment(orderExport.to).format('yyyy-MM-DD')}</td>
                                                    <td>{moment(orderExport.createdAt).format('yyyy-MM-DD HH:mm')}</td>
                                                    <td>
                                                        <FormControl fullWidth size='small'>
                                                            <Select
                                                                labelId='demo-simple-select-label'
                                                                sx={{
                                                                    boxShadow: 'none',
                                                                    '.MuiOutlinedInput-notchedOutline': {
                                                                        border: 0,
                                                                    },
                                                                }}
                                                                autoWidth
                                                                id='demo-simple-select'
                                                                value={orderExport.status.value}
                                                                disabled={
                                                                    orderExport.status.value === 'rejected' ||
                                                                    orderExport.status.value === 'imported'
                                                                }
                                                                onChange={(event) => {
                                                                    updateExportStatus({
                                                                        variables: {
                                                                            exportId: orderExport.id,
                                                                            status: event.target.value,
                                                                        },
                                                                    });
                                                                }}>
                                                                {statuses.map((status: OrderExportStatus) => {
                                                                    return (
                                                                        <MenuItem value={status.value}>
                                                                            {status.title}
                                                                        </MenuItem>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                    </td>
                                                    <td className='text-right'>
                                                        {getPermission(user, 'orders', 'read') && (
                                                            <Button
                                                                className='btn btn-outlined-info btn-sm'
                                                                onClick={() => {
                                                                    if (orderExport.path) {
                                                                        downloadExport(
                                                                            orderExport.path,
                                                                            orderExport.id,
                                                                        );
                                                                    }
                                                                }}
                                                                disabled={!orderExport.path}>
                                                                Download
                                                            </Button>
                                                        )}
                                                        {getPermission(user, 'orders', 'delete') && (
                                                            <Button
                                                                className='btn btn-danger btn-icon-only rounded-circle btn-sm'
                                                                onClick={() => {
                                                                    displayAlert({ warning: true, orderExport });
                                                                }}>
                                                                <i className='fa-solid fa-trash-can'></i>
                                                            </Button>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </Table>
                            <CardFooter className='py-4'>
                                <Row>
                                    <div className='col-4 d-flex'>
                                        <span style={{ fontSize: '12px' }}>Rows per page:</span>
                                        <BasicMenu
                                            getValue={getRowValue}
                                            options={['10', '20', '100']}
                                            value={rowPerPage.toString()}
                                        />
                                    </div>
                                    <div className='col-4 d-flex'>
                                        <span className='min-text'>Page:</span>
                                        <span className='min-text pl-2'>
                                            {page + 1} of {Math.ceil(filteredExports.length / rowPerPage)}
                                        </span>
                                    </div>
                                    <div className='col-4 d-flex'>
                                        <Pagination>
                                            <PaginationItem className={page <= 0 ? 'disabled' : ''}>
                                                <PaginationLink onClick={(e) => previousPage()}>
                                                    <i className='fas fa-angle-left' />
                                                    <span className='sr-only'>Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem
                                                className={
                                                    page >= Math.ceil(filteredExports.length / rowPerPage) - 1
                                                        ? 'disabled'
                                                        : ''
                                                }>
                                                <PaginationLink onClick={(e) => nextPage()}>
                                                    <i className='fas fa-angle-right' />
                                                    <span className='sr-only'>Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                    </div>
                                </Row>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
}
