import { ClearIcon } from '@mui/x-date-pickers';
import React from 'react';
import { MembershipAgreementItemFormData } from '../../../../models/membership-agreement.model';
import { ProductPackage } from '../../../../models/packages.models';
import { TeamMember } from '../../../../models/team.member.model';

interface MembershipListItemProps {
    item: MembershipAgreementItemFormData;
    packages: ProductPackage[];
    members: TeamMember[];
    onItemRemove: (item: MembershipAgreementItemFormData) => void;
    onItemSelect: (item?: MembershipAgreementItemFormData) => void;
}

export default function MembershipListItem({
    item,
    packages,
    members,
    onItemRemove,
    onItemSelect,
}: MembershipListItemProps) {
    const packageName = packages?.find((p) => p.id === item.packageId).name;

    console.log('assigned id', item?.assignMemberId);
    const memberName = item?.assignMemberId
        ? members?.find((m) => m.id === item?.assignMemberId)?.user?.name +
          ' ' +
          members?.find((m) => m.id === item?.assignMemberId)?.user?.surname
        : 'Not assigned';

    return (
        <>
            <div className='row mt-4' style={{ cursor: 'pointer' }} onClick={() => onItemSelect(item)}>
                <div className='col-2'>
                    <div className='parent'>
                        <p className='block-ellipsis'>{packageName}</p>
                    </div>
                </div>
                <div className='col-2 d-flex'>
                    <div className='parent'>
                        <p className='block-ellipsis'>{`R ${item?.balconyFee.toFixed(2)}`}</p>
                    </div>
                </div>

                <div className='col-2 d-flex '>
                    <div>
                        <p className='small-text overflow-text'>{`R ${item?.parkingFee.toFixed(2)}`}</p>
                    </div>
                </div>
                <div className='col-2 d-flex justify-content-center'>
                    <div>
                        <p className='small-text overflow-text'>{`R ${item?.totalFee.toFixed(2)}`}</p>
                    </div>
                </div>
                <div className='col-2 d-flex justify-content-center'>
                    <div>
                        <p className='small-text overflow-text'>{memberName ?? 'Not assigned'}</p>
                    </div>
                </div>
                <div className='col-1 d-flex justify-content-end'>
                    <div className='text-right'>
                        <ClearIcon
                            sx={{
                                cursor: 'pointer',
                            }}
                            color='error'
                            onClick={(e) => {
                                e.stopPropagation();
                                onItemRemove(item);
                            }}
                        />
                    </div>
                </div>
                <div className='col-1 d-flex justify-content-end'></div>
            </div>
            <hr className='mt-2'></hr>
        </>
    );
}
