/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ClientDetailView from './ClientDetailView';
import ClientBookingsView from './ClientBookingsView';
import ClientMembersView from './ClientMembersView';
import { Col, Container, Row } from 'reactstrap';
// import { useHistory } from "react-router-dom";
import { useQuery } from '@apollo/client';
import {
    GET_CLIENT,
    // GET_CLIENTS,
    GET_MEETING_ROOM_ITEMS,
} from '../../../graphql/clients.graphql';
import { LinearProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import useToast from '../../../hooks/useToast';
import { Client } from '../../../models/client.model';
import ClientPackagesView from './ClientPacakgesView';
import { Booking } from '../../../models/bookings.model';
import { useSelector } from 'react-redux';
import { User } from '../../../models/user.model';
import { getPermission } from '../../../utils/user.utils';
import OrderView from './components/OrderView';
import ClientDocumentView from './ClientDocumentView';
import CustomPricing from './CustomPricing';
import { MeetingRoomItem } from '../../../models/meeting.room.model';
import MembershipAgreementList from './components/MembershipAgreementList';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box sx={{ p: 0 }}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function ClientManageView() {
    const [value, setValue] = React.useState(0);
    // const history = useHistory();
    const navigate = useNavigate();
    const params: any = useParams();
    const user: User | null = useSelector((state: any) => state.user.userInfo);
    const { loading: roomItemsLoading, error: meetingRoomsError, data: roomItems } = useQuery(GET_MEETING_ROOM_ITEMS);

    // const { loading: loadingClients, data: loadedClients } =
    //   useQuery(GET_CLIENTS);

    const { showToast, Notification } = useToast();
    const { loading, error, data } = useQuery(GET_CLIENT, {
        variables: {
            id: params.id,
        },
    });

    console.log('User data : ', user);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    React.useEffect(() => {
        if (error || meetingRoomsError) {
            showToast('An error has occurred, please reload the page', 'danger');
        }
    }, [error, meetingRoomsError]);

    const redirectToClients = (e: any) => {
        e.preventDefault();
        navigate(-1);
    };

    if (loading || roomItemsLoading) {
        return <LinearProgress />;
    }

    const client: Client = data?.client;
    // const clients: Client[] = loadedClients?.clients;
    const bookings: Booking[] = client?.bookings;
    const bookingPackages: any[] = data?.client?.packages;
    const rooms: MeetingRoomItem[] = roomItems.meetingRoomItems;

    console.log('Client', client);

    return (
        <>
            {Notification}
            <Container className='mt-4' fluid>
                <Row className='ml-1'>
                    <Col>
                        <Row className='pb-4'>
                            <button
                                className='btn btn-outline-primary btn-sm mr-4'
                                onClick={(e) => {
                                    redirectToClients(e);
                                }}>
                                <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                                <span className='btn-inner-text'>Back</span>
                            </button>
                            <h3 className='m-0'>Manage client</h3>
                        </Row>
                        <Row>
                            <Box sx={{ width: '100%' }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
                                        {getPermission(user, 'clients', 'read') && (
                                            <Tab
                                                sx={{ textTransform: 'capitalize' }}
                                                label='Details'
                                                {...a11yProps(0)}
                                            />
                                        )}
                                        {getPermission(user, 'client_bookings', 'read') && (
                                            <Tab
                                                sx={{ textTransform: 'capitalize' }}
                                                label='Bookings'
                                                {...a11yProps(1)}
                                            />
                                        )}
                                        {getPermission(user, 'client_packages', 'read') && (
                                            <Tab
                                                sx={{ textTransform: 'capitalize' }}
                                                label='Packages'
                                                {...a11yProps(2)}
                                            />
                                        )}
                                        {getPermission(user, 'client_members', 'read') && (
                                            <Tab
                                                sx={{ textTransform: 'capitalize' }}
                                                label='Members'
                                                {...a11yProps(3)}
                                            />
                                        )}
                                        {getPermission(user, 'client_members', 'read') && (
                                            <Tab
                                                sx={{ textTransform: 'capitalize' }}
                                                label='Orders'
                                                {...a11yProps(4)}
                                            />
                                        )}

                                        {getPermission(user, 'client_documents', 'read') && (
                                            <Tab
                                                sx={{ textTransform: 'capitalize' }}
                                                label='Documents'
                                                {...a11yProps(5)}
                                            />
                                        )}
                                        {getPermission(user, 'client_rates', 'read') && (
                                            <Tab
                                                sx={{ textTransform: 'capitalize' }}
                                                label='Custom Pricing'
                                                {...a11yProps(6)}
                                            />
                                        )}
                                        {getPermission(user, 'client_members', 'read') && (
                                            <Tab
                                                sx={{ textTransform: 'capitalize' }}
                                                label='Membership Agreements'
                                                {...a11yProps(7)}
                                            />
                                        )}
                                    </Tabs>
                                </Box>
                                <Box>
                                    {getPermission(user, 'clients', 'read') && (
                                        <TabPanel value={value} index={0}>
                                            <ClientDetailView client={client} />
                                        </TabPanel>
                                    )}
                                    {getPermission(user, 'client_bookings', 'read') && (
                                        <TabPanel value={value} index={1}>
                                            <ClientBookingsView
                                                bookings={bookings}
                                                rooms={rooms}
                                                // clients={clients}
                                            />
                                        </TabPanel>
                                    )}
                                    {getPermission(user, 'client_packages', 'read') && (
                                        <TabPanel value={value} index={2}>
                                            <ClientPackagesView
                                                packages={bookingPackages}
                                                clientMembers={client?.members}
                                            />
                                        </TabPanel>
                                    )}
                                    {getPermission(user, 'client_members', 'read') && (
                                        <TabPanel value={value} index={3}>
                                            <ClientMembersView members={client?.members} />
                                        </TabPanel>
                                    )}
                                    {getPermission(user, 'client_members', 'read') && (
                                        <TabPanel value={value} index={4}>
                                            {/* <ClientMembersView members={client.members} /> */}
                                            <OrderView orders={client?.orders} />
                                        </TabPanel>
                                    )}
                                    {getPermission(user, 'clients', 'read') && (
                                        <TabPanel value={value} index={5}>
                                            <ClientDocumentView clientData={client} />
                                        </TabPanel>
                                    )}
                                    {getPermission(user, 'client_rates', 'read') && (
                                        <TabPanel value={value} index={6}>
                                            <CustomPricing clientData={client} />
                                        </TabPanel>
                                    )}
                                    {getPermission(user, 'client_rates', 'read') && (
                                        <TabPanel value={value} index={7}>
                                            <MembershipAgreementList />
                                        </TabPanel>
                                    )}
                                </Box>
                            </Box>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
