/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { VendorMenuPackageItem } from "../../models/vendor.model";
import ClearIcon from "@mui/icons-material/Clear";
import SweetAlert from "react-bootstrap-sweetalert";
import { useMutation } from "@apollo/client";
import { REMOVE_BOOKING_MENU_PACKAGE } from "../../graphql/bookings.graphql";
import { Booking } from "../../models/bookings.model";

interface Props {
  item?: VendorMenuPackageItem;
  selectedMenuItems?: VendorMenuPackageItem[];
  selectedBooking?: Booking;
  getSelectedMenuItems?: (item: VendorMenuPackageItem[]) => void;
}

const PackageMenuItem = ({
  item,
  selectedMenuItems,
  selectedBooking,
  getSelectedMenuItems,
}: Props) => {
  const [quantity, setQuantity] = useState<number>(item.quantity);
  const [removedBookingMenu] = useMutation(REMOVE_BOOKING_MENU_PACKAGE);

  const addQuantity = () => setQuantity(quantity + 1);
  const minuteQuantity = () => {
    if (quantity >= 2) setQuantity(quantity - 1);
  };

  const [alert, setAlert] = useState<any>(null);

  const displayAlert = (props: any) => {
    setAlert(
      <SweetAlert
        {...props}
        showCancel
        confirmBtnText="Continue"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => {
          removedItem();
          setAlert(null);
        }}
        onCancel={() => {
          setAlert(null);
        }}
        focusCancelBtn
      >
        This item will be removed.
      </SweetAlert>
    );
  };

  const handleQuantityChange = useCallback(() => {
    if (quantity) {
      const itemIndex = selectedMenuItems?.findIndex(
        (i) => i.menuPackageId === item.menuPackageId
      );

      if (itemIndex === -1) {
        return;
      }

      const newItem = {
        id: item?.id,
        quantity: quantity,
        totalPrice: item.unitPrice * quantity,
        featuredImage: item.featuredImage,
        unitPrice: item.unitPrice,
        vendorName: item.vendorName,
        vendorId: item.vendorId,
        menuPackageName: item.menuPackageName,
        menuPackageId: item.menuPackageId,
      };

      const items = [...selectedMenuItems];
      items.splice(itemIndex, 1, { ...newItem });
      getSelectedMenuItems([...items]);
    }
  }, [quantity]);

  useEffect(() => {
    handleQuantityChange();
  }, [handleQuantityChange]);

  const removedItem = () => {
    const items = selectedMenuItems;

    if (selectedBooking) {
      removedBookingMenu({
        variables: {
          id: item.id,
        },
      });
    }

    getSelectedMenuItems([
      ...items.filter((i) => i.menuPackageId !== item.menuPackageId),
    ]);
  };

  return (
    <div className="row mt-4">
      {alert}
      <div className="col-2">
        <p className="small-text overflow-text">{item?.vendorName}</p>
      </div>
      <div className="col-3 d-flex align-items-center">
        <div className="parent">
          <p className="block-ellipsis">{item.menuPackageName}</p>
        </div>
      </div>
      <div className="col-4 d-flex justify-content-center">
        <span
          style={{
            width: 30,
            height: 30,
          }}
          className="btn rounded-circle btn-dark btn-sm"
          onClick={minuteQuantity}
        >
          -
        </span>
        <p className="pr-2 small-text">{item?.quantity}</p>
        <span
          style={{
            width: 30,
            height: 30,
          }}
          className="btn rounded-circle btn-dark btn-sm"
          onClick={addQuantity}
        >
          +
        </span>
      </div>
      <div className="col-2 d-flex justify-content-center">
        <div>
          <p className="small-text overflow-text">
            R{item?.totalPrice.toFixed(2)}
          </p>
        </div>
      </div>
      <div className="col-1 d-flex justify-content-end">
        <div className="text-right">
          <ClearIcon
            sx={{
              cursor: "pointer",
            }}
            color="error"
            onClick={() => displayAlert({ warning: true })}
          />
        </div>
      </div>
    </div>
  );
};

export default PackageMenuItem;
