import React, { useEffect, useRef } from 'react';
import EmailEditor from 'react-email-editor';
import { Col, Container, Row } from 'reactstrap';
import { GET_EMAIL_TEMPLATE, UPDATE_EMAIL_TEMPLATE } from '../../../../graphql/newsletter-email.graphql';
import { useMutation, useQuery } from '@apollo/client';
import { LinearProgress } from '@mui/material';
import useToast from '../../../../hooks/useToast';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '../../../../components/Spinner/Spinner';
import { v4 } from 'uuid';
import { Storage } from 'aws-amplify';

//Delete the templates
//Assign templates to emails
//Use templates when sending newsletters
export default function TemplateView() {
    const emailEditorRef = useRef(null);
    const { showToast, Notification } = useToast();
    const params: any = useParams();
    const navigate = useNavigate();
    const { data, error, loading } = useQuery(GET_EMAIL_TEMPLATE, {
        variables: {
            id: params?.id,
        },
    });

    const [updateEmailTemplate, { loading: updating, data: updatedTemplate, error: updateError }] = useMutation(
        UPDATE_EMAIL_TEMPLATE,
        {
            refetchQueries: [GET_EMAIL_TEMPLATE],
        },
    );

    const saveDesign = () => {
        emailEditorRef?.current?.editor?.exportHtml(async (data) => {
            console.log('exportHtml', data);

            const template = {
                template: data,
            };

            await updateEmailTemplate({
                variables: {
                    id: params?.id,
                    template: template,
                },
            });
        });
    };

    const onLoad = () => {
        emailEditorRef?.current?.editor.registerCallback('image', async function (file, done) {
            console.log('registerCallback', file.accepted[0]);

            const image = file.attachments[0];

            const fileName = `${v4()}_${image?.name}`;
            const path = `templates/${fileName}`;
            console.log('path', path);
            const imageItem = await Storage.put(path, image, { level: 'public' });
            console.log('imageItem', imageItem);
            let url = imageItem.key;
            console.log('url', url);
            if (url) {
                done({
                    progress: 100,
                    url: `${process.env.REACT_APP_IMAGE_URL + '/' + url}`,
                });
            }
        });

        if (!data.template.template) return;
        // console.log('Data', emailEditorRef?.current?.editor);
        emailEditorRef?.current?.editor.loadDesign(data.template.template?.design);
    };

    useEffect(() => {
        if (updatedTemplate) {
            showToast('Successfully updated template', 'success');
        }
    }, [updatedTemplate]);

    useEffect(() => {
        if (updateError) {
            showToast('An error has occurred while trying to update this template', 'danger');
        }
    }, [updateError]);

    useEffect(() => {
        if (error) {
            showToast('An error has occurred while trying to load the page', 'danger');
        }
    }, [error]);

    const onReady = () => {
        console.log('Ready!');
    };

    if (loading) {
        return <LinearProgress />;
    }

    // const emailTemplate: EmailTemplate = data?.template;

    return (
        <>
            {Notification}
            <Container className='mt-4' fluid>
                <Row>
                    <Col xs='6'>
                        <button className='btn btn-outline-primary btn-sm mr-4' onClick={(e) => navigate(-1)}>
                            <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                            <span className='btn-inner-text'>Back</span>
                        </button>
                    </Col>
                    <Col xs='6' className='text-right'>
                        <button
                            disabled={updating}
                            className=' btn btn-round btn-dark btn-sm'
                            color='default'
                            onClick={saveDesign}
                            id='tooltip969372949'>
                            {!updating && <span className='btn-inner--text'>Save Design</span>}
                            {updating && <Spinner />}
                        </button>
                    </Col>
                </Row>
                <Row
                    className='mt-4'
                    style={{
                        height: '80vh',
                    }}>
                    <EmailEditor ref={emailEditorRef} onLoad={onLoad} onReady={onReady} />
                </Row>
            </Container>
        </>
    );
}
