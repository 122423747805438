import React, { useEffect, useRef, useState } from 'react';
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table,
} from 'reactstrap';
import BasicMenu from '../../../../../components/Menu';
import { User } from '../../../../../models/user.model';
import { useSelector } from 'react-redux';
import { getPermission } from '../../../../../utils/user.utils';
import { MailListEmail } from '../../../../../models/newsletter-emil.model';
import _ from 'lodash';
import { Status } from '../../../../../models/newsletter-post.model';
import MailListEmailAddForm from './MailListEmailAddForm';
// import moment from 'moment';
import { useMutation } from '@apollo/client';
import {
    ADD_MAIL_LIST_EMAILS,
    GET_MAIL_LIST_DETAILS,
    REMOVE_MAIL_LIST_EMAIL,
    // SEND_MAIL_LIST_EMAILS,
} from '../../../../../graphql/mail-list.graphql';
import useToast from '../../../../../hooks/useToast';
import SweetAlert from 'react-bootstrap-sweetalert';
import Papa from 'papaparse';
import Spinner from '../../../../../components/Spinner/Spinner';
import { useParams } from 'react-router-dom';
import EmailIssuesDialog from './EmailIssuesDialog';

interface MailListEmailsListProps {
    emails: MailListEmail[];
    statusList: Status[];
}

export default function MailListEmailsList({ emails, statusList }: MailListEmailsListProps) {
    const user: User | null = useSelector((state: any) => state.user.userInfo);
    const [searchKey, setSearchKey] = useState<string>('');
    const [page, setPage] = useState<number>(0);
    const [rowPerPage, setRowPerPage] = useState<number>(10);
    const [showIssueDialog, setShowIssueDialog] = useState<boolean>(false);
    const [incorrectEmails, setIncorrectEmails] = useState<string[]>([]);
    const [sortFiled, setSortField] = useState<string>('title');
    const [sortDirection, setSortDirection] = useState<string>('desc');
    const [displayForm, setDisplayForm] = useState<boolean>(false);
    const [selectedEmail, setSelectedEmail] = useState<MailListEmail>();
    const csvFileInputRef: any = useRef();
    const params: any = useParams();
    const [addEmails, { loading: addingEmails, data: addedEmails, error }] = useMutation(ADD_MAIL_LIST_EMAILS, {
        refetchQueries: [GET_MAIL_LIST_DETAILS],
    });
    const { showToast, Notification } = useToast();
    const [removeEmail, { error: removedError, data: removedEmail }] = useMutation(REMOVE_MAIL_LIST_EMAIL, {
        refetchQueries: [GET_MAIL_LIST_DETAILS],
    });
    const [alert, setAlert] = useState<any>(null);
    const sortBy: any = (mailLists: MailListEmail[], field: string, direction: string) => {
        const orderedBookings = new Map([
            ['email', _.orderBy(mailLists, (mail) => mail.email, [direction === 'asc' ? 'asc' : 'desc'])],
            ['title', _.orderBy(mailLists, (mail) => mail.title, [direction === 'asc' ? 'asc' : 'desc'])],
            ['subject', _.orderBy(mailLists, (mail) => mail.title, [direction === 'asc' ? 'asc' : 'desc'])],
            ['publishedDate', _.orderBy(mailLists, (mail) => mail.title, [direction === 'asc' ? 'asc' : 'desc'])],
            ['status', _.orderBy(mailLists, (mail) => mail.status.title, [direction === 'asc' ? 'asc' : 'desc'])],
        ]);
        return orderedBookings.get(field);
    };

    const isEmailValid = (email: string) => {
        return email
            .toLocaleLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            );
    };

    useEffect(() => {
        if (error) {
            showToast('An error has occurred while trying to import emails', 'danger');
        }
    }, [error]);

    useEffect(() => {
        if (addedEmails) {
            showToast('Successfully imported emails', 'success');
        }
    }, [addedEmails]);

    const displayAlert = (props: any) => {
        setAlert(
            <SweetAlert
                {...props}
                showCancel
                confirmBtnText='Continue'
                confirmBtnBsStyle='danger'
                title='Are you sure?'
                onConfirm={async () => {
                    removeEmail({
                        variables: {
                            id: props.email.id,
                        },
                    });
                    setAlert(null);
                }}
                onCancel={() => {
                    setAlert(null);
                }}
                focusCancelBtn>
                This email will be removed from the system.
            </SweetAlert>,
        );
    };

    useEffect(() => {
        if (removedError) {
            showToast('An error has occurred while trying to remove this email', 'danger');
        }
    }, [removedError]);

    useEffect(() => {
        if (removedEmail) {
            showToast('Successfully removed this email', 'success');
        }
    }, [removedEmail]);

    const nextPage = () => {
        setPage(page + 1);
    };

    const previousPage = () => {
        setPage(page - 1);
    };

    const getRowValue = (value: string) => {
        setRowPerPage(parseInt(value));
    };

    const handleFileUpload = (e: any) => {
        console.log('Triggered');
        Papa.parse(e.target.files[0], {
            header: false,
            skipEmptyLines: true,
            complete: function (results) {
                const uniqueEmails: string[] = _.uniq(results.data.map((email) => email[0]));

                const trimmedEmails = uniqueEmails.map((email) => email.trim().toLocaleLowerCase());
                let failedEmails: string[] = [];
                let passedEmails: string[] = [];

                trimmedEmails.forEach((email) => {
                    if (isEmailValid(email)) {
                        passedEmails.push(email);
                    } else {
                        failedEmails.push(email);
                    }
                });

                // console.log('Failed emails', failedEmails);

                if (failedEmails.length > 0) {
                    setShowIssueDialog(true);
                    setIncorrectEmails([...failedEmails]);
                }

                const emails = passedEmails.map((email) => {
                    return {
                        email: email,
                        mail_list_id: params?.id,
                        status_enum: 'active',
                    };
                });

                console.log('Passed emails', emails);

                csvFileInputRef.current.value = '';
                addEmails({
                    variables: {
                        emails,
                    },
                });
            },
        });
    };

    if (displayForm) {
        return (
            <MailListEmailAddForm
                onReturnToEmailListView={() => setDisplayForm(false)}
                emailStatusList={statusList}
                email={selectedEmail}
            />
        );
    }

    let filteredEmails = emails.filter((email: MailListEmail) => {
        if (searchKey === '') {
            return email;
        }
        return (
            email.email.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            email.status.title.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase())
        );
    });

    filteredEmails = sortBy(filteredEmails, sortFiled, sortDirection);

    return (
        <>
            {alert}
            {Notification}
            {showIssueDialog && (
                <EmailIssuesDialog
                    isOpen={showIssueDialog}
                    emails={incorrectEmails}
                    handleClose={() => {
                        setShowIssueDialog(false);
                        setIncorrectEmails([]);
                    }}
                />
            )}
            <Row className='mt-4'>
                <div className='col'>
                    <Card>
                        <CardHeader className='border-0'>
                            <Row>
                                <Col xs='6'>
                                    <h3 className='mb-0'>Emails</h3>
                                </Col>
                                {getPermission(user, 'newsletter', 'create') && (
                                    <Col className='text-right' xs='6'>
                                        <Button
                                            className=' btn btn-round btn-dark btn-sm'
                                            color='default'
                                            id='tooltip969372949'
                                            onClick={() => {
                                                setDisplayForm(true);
                                                setSelectedEmail(undefined);
                                            }}>
                                            <span className='btn-inner--text'> Add email</span>
                                        </Button>

                                        <Button
                                            className=' btn btn-round btn-dark btn-sm'
                                            color='default'
                                            id='tooltip969372949'
                                            loading={addingEmails}
                                            onClick={() => {
                                                if (csvFileInputRef) csvFileInputRef.current.click();
                                            }}>
                                            {!addingEmails && <span className='btn-inner--text'> Import</span>}
                                            {addingEmails && <Spinner />}
                                        </Button>
                                        <input
                                            type='file'
                                            name='file'
                                            ref={csvFileInputRef}
                                            onChange={handleFileUpload}
                                            accept='.csv'
                                            style={{ display: 'none' }}
                                        />
                                    </Col>
                                )}
                            </Row>
                            <Row className='mt-4'>
                                <Col>
                                    <Input
                                        className='w-25'
                                        placeholder='Search keyword...'
                                        onChange={(e) => {
                                            setSearchKey(e.target.value);
                                            setPage(0);
                                        }}></Input>
                                </Col>
                            </Row>
                        </CardHeader>
                        <Table className='align-items-center table-flush'>
                            <thead className='thead-light'>
                                <tr>
                                    <th
                                        onClick={() => {
                                            setSortField('email');
                                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                        }}
                                        className='sort'
                                        data-sort='email'
                                        scope='col'>
                                        Email Address
                                    </th>
                                    <th
                                        onClick={() => {
                                            setSortField('status');
                                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                        }}
                                        className='sort'
                                        data-sort='status'
                                        scope='col'>
                                        Status
                                    </th>
                                    <th scope='col'></th>
                                </tr>
                            </thead>
                            <tbody className='list'>
                                {filteredEmails
                                    .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                                    .map((email: any) => {
                                        return (
                                            <tr
                                                key={email.id}
                                                style={{
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    setDisplayForm(true);
                                                    setSelectedEmail(email);
                                                }}>
                                                <td>{`${email.email}`}</td>
                                                <td>{email.status.title}</td>
                                                <td className='text-right'>
                                                    {getPermission(user, 'newsletter', 'update') && (
                                                        <Button
                                                            className='btn btn-info btn-icon-only rounded-circle btn-sm'
                                                            onClick={() => {
                                                                setDisplayForm(true);
                                                                setSelectedEmail(email);
                                                            }}>
                                                            <i className='fa-solid fa-pencil'></i>
                                                        </Button>
                                                    )}
                                                    {getPermission(user, 'locations', 'delete') && (
                                                        <Button
                                                            className='btn btn-danger btn-icon-only rounded-circle btn-sm'
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                displayAlert({ warning: true, email });
                                                            }}>
                                                            <i className='fa-solid fa-trash-can'></i>
                                                        </Button>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </Table>
                        <CardFooter className='py-4'>
                            <Row>
                                <div className='col-4 d-flex'>
                                    <span style={{ fontSize: '12px' }}>Rows per page:</span>
                                    <BasicMenu
                                        getValue={getRowValue}
                                        options={['10', '20', '100']}
                                        value={rowPerPage.toString()}
                                    />
                                </div>
                                <div className='col-4 d-flex'>
                                    <span className='min-text'>Page:</span>
                                    <span className='min-text pl-2'>
                                        {page + 1} of {Math.ceil(filteredEmails.length / rowPerPage)}
                                    </span>
                                </div>
                                <div className='col-4 d-flex'>
                                    <Pagination>
                                        <PaginationItem className={page <= 0 ? 'disabled' : ''}>
                                            <PaginationLink onClick={(e) => previousPage()}>
                                                <i className='fas fa-angle-left' />
                                                <span className='sr-only'>Previous</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                        <PaginationItem
                                            className={
                                                page >= Math.ceil(filteredEmails.length / rowPerPage) - 1
                                                    ? 'disabled'
                                                    : ''
                                            }>
                                            <PaginationLink onClick={(e) => nextPage()}>
                                                <i className='fas fa-angle-right' />
                                                <span className='sr-only'>Previous</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                    </Pagination>
                                </div>
                            </Row>
                        </CardFooter>
                    </Card>
                </div>
            </Row>
        </>
    );
}
