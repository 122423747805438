import React from 'react';
import PackageCalendar from './components/PackageCalendar';
import { Col, Container, Row } from 'reactstrap';
import { GET_CALENDAR_PACKAGE_BOOKINGS } from '../../../graphql/package-booking.graphql';
import { useQuery } from '@apollo/client';
import { LinearProgress } from '@mui/material';
import { PackageBooking, PackageBookingEvent } from '../../../models/package-bookings.model';
import { BookingStatus } from '../../../models/bookings.model';
import moment from 'moment';

const PackageBookingCalendarView = () => {
    const { loading, data } = useQuery(GET_CALENDAR_PACKAGE_BOOKINGS);

    const getBookingClassName = (status: BookingStatus): string => {
        switch (status.value) {
            case 'active':
                return 'bg-green';
            case 'complete':
                return 'bg-green';
            case 'pending':
                return 'bg-orange';
            case 'confirmed':
                return 'bg-info';
            default:
                return 'bg-info';
        }
    };

    const getIsAllDayBooking = (start: string, end: string): boolean => {
        const hours = moment(end).diff(moment(start), 'hours');
        return hours >= 8;
    };

    if (loading) {
        return <LinearProgress />;
    }

    const packageBookingEvents: PackageBookingEvent[] = data?.packageBookings.map((packageBooking: PackageBooking) => {
        return {
            id: packageBooking?.id,
            title: packageBooking?.client?.companyName || packageBooking?.client?.individualName || '',
            start: new Date(packageBooking?.from || ''),
            className: getBookingClassName(packageBooking?.status),
            allDay: getIsAllDayBooking(packageBooking?.from, packageBooking?.to),
            description: packageBooking?.productPackage?.name,
        };
    });

    return (
        <>
            <Container className='mt-4' fluid>
                <Row>
                    <Col>
                        <h1>Calendar</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <PackageCalendar packageBookings={packageBookingEvents} />
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default PackageBookingCalendarView;
