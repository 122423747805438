/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { BookingResource } from "../../../../models/resources.model";
import { useMutation } from "@apollo/client";
import { REMOVE_BOOKING_RESOURCE } from "../../../../graphql/bookings.graphql";
import SweetAlert from "react-bootstrap-sweetalert";

interface BookingResourceItemProps {
  resourceItem: BookingResource;
  selectedResources?: BookingResource[];
  getBookingResources?: (resources: BookingResource[]) => void;
}

const BookingResourceItem = ({
  resourceItem,
  selectedResources,
  getBookingResources,
}: BookingResourceItemProps) => {
  const [removedResource, { data, error }] = useMutation(
    REMOVE_BOOKING_RESOURCE
  );
  const [alert, setAlert] = useState<any>(null);
  const [quantity, setQuantity] = useState<number>(resourceItem?.quantity || 0);

  const addQuantity = () => setQuantity(quantity + 1);
  const minuteQuantity = () => {
    if (quantity >= 2) setQuantity(quantity - 1);
  };

  useEffect(() => {
    if (data) {
      console.log("Successfully removed", data);
    }

    if (error) {
      console.log("An error has occurred", error);
    }
  }, [error, data]);

  const handleQuantityChange = useCallback(() => {
    if (quantity) {
      const itemIndex = selectedResources?.indexOf(resourceItem);

      if (itemIndex === -1) {
        return;
      }

      const newItem: BookingResource = {
        id: resourceItem?.id,
        bookingId: resourceItem?.bookingId,
        name: resourceItem?.name,
        quantity: quantity,
        rate: resourceItem?.rate,
        resourceId: resourceItem?.resourceId,
        totalPrice: resourceItem?.rate * quantity,
      };

      const items = [...selectedResources];
      items.splice(itemIndex, 1, { ...newItem });
      getBookingResources([...items]);
    }
  }, [quantity]);

  useEffect(() => {
    handleQuantityChange();
  }, [handleQuantityChange]);

  const onItemRemove = () => {
    if (resourceItem?.id) {
      removedResource({
        variables: {
          id: resourceItem?.id,
        },
      });
    }

    const itemIndex = selectedResources?.indexOf(resourceItem);

    const items = [
      ...selectedResources?.filter(
        (resource) => selectedResources.indexOf(resource) !== itemIndex
      ),
    ];
    getBookingResources(items);
  };

  const displayAlert = (props: any) => {
    setAlert(
      <SweetAlert
        {...props}
        showCancel
        confirmBtnText="Continue"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => {
          onItemRemove();
          setAlert(null);
        }}
        onCancel={() => {
          setAlert(null);
        }}
        focusCancelBtn
      >
        This resource will be removed from the system.
      </SweetAlert>
    );
  };

  return (
    <div className="row mt-4">
      {alert}
      <div className="col-3">
        <p className="small-text overflow-text">{resourceItem?.name}</p>
      </div>
      <div className="col-3 d-flex justify-content-center">
        <span
          style={{
            width: 30,
            height: 30,
          }}
          className="btn rounded-circle btn-dark btn-sm"
          onClick={minuteQuantity}
        >
          -
        </span>
        <p className="pr-2 small-text">{resourceItem?.quantity}</p>
        <span
          style={{
            width: 30,
            height: 30,
          }}
          className="btn rounded-circle btn-dark btn-sm"
          onClick={addQuantity}
        >
          +
        </span>
      </div>
      <div className="col-3 d-flex justify-content-center">
        <div>
          <p className="small-text overflow-text">
            R{resourceItem?.totalPrice.toFixed(2)}
          </p>
        </div>
      </div>
      <div className="col-3 d-flex justify-content-end">
        <div className="text-right">
          <ClearIcon
            sx={{
              cursor: "pointer",
            }}
            color="error"
            onClick={() => displayAlert({ warning: true })}
          />
        </div>
      </div>
    </div>
  );
};

export default BookingResourceItem;
