import React , { useRef }from "react";
import NotificationAlert from "react-notification-alert"

const useToast = () => {
    const ref = useRef<any>(null);
    const showToast = (message: string, type: string) =>{
      const option = {
        place: "tc",
        message: (
        <div className="alert-text">
          <span data-notify="message">
            {message}
          </span>
        </div>
      ),
      type: type,
      autoDismiss: 7
    };
     ref.current?.notificationAlert(option);
    }

    const Notification = <NotificationAlert ref = {ref} />
    return {showToast, Notification};
}

export default useToast;
