/* eslint-disable react-hooks/exhaustive-deps */
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormGroup,
    MenuItem,
    Select,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import useToast from '../../../../hooks/useToast';
import {
    ADD_VENDOR_RATE,
    // GET_MENU_RATES,
    GET_PACKAGE_TYPES,
} from '../../../../graphql/menu-packages.graphql';
import { CurrencyType, Rate } from '../../../../models/vendor.model';

interface AddVendorRateDialogProps {
    isOpen: boolean;
    toggleOpen: (isOpen: boolean) => void;
    selectedPackage: any;
    rate: Rate | null;
}

type FormData = {
    price: number;
    currency: string;
    minimumLimit: number;
    maximumLimit: number;
};

export default function AddVendorMenuRate({ isOpen, toggleOpen, selectedPackage, rate }: AddVendorRateDialogProps) {
    const [isRateId, setIsRateId] = useState<boolean>(false);
    const [rateId, setRateId] = useState<any>();
    const { showToast, Notification } = useToast();
    const [rateCurrencyType, setRateCurrencyType] = useState<CurrencyType[] | []>();

    const [addRateMutataion, { called, error, loading, reset }] = useMutation(ADD_VENDOR_RATE, {
        // refetchQueries: [{ query: GET_MENU_RATES }, "getMenuPackages"],
        refetchQueries: ['getMenuPackages'],
    });

    const { data } = useQuery(GET_PACKAGE_TYPES);

    const {
        register,
        handleSubmit,
        // setValue,
        formState: { errors },
    } = useForm<FormData>();

    useEffect(() => {
        if (data) {
            setRateCurrencyType(data.rateCurrencyType);
        }
    }, [data]);

    useEffect(() => {
        if (called && !loading && !error) {
            showToast(`Successfully ${rate ? 'updated' : 'added'} vendor rate`, 'success');
            reset();
            // resetForm();
            toggleOpen(false);
        }
        if (called && error) {
            showToast('An error has occurred, please try again', 'danger');
        }
    }, [called, loading, error]);

    useEffect(() => {
        if (rate) {
            // setValue("price", rate?.price);
            // setValue("currency", rate?.currency.value);
            // setValue("minimumLimit", rate?.minimumLimit);
            // setValue("maximumLimit", rate?.maximumLimit);
            setIsRateId(true);
            setRateId(rate?.id);
        } else {
            setIsRateId(false);
        }
    }, [rate]);

    const handleClose = () => {
        toggleOpen(false);
    };

    const onSubmit = (data: FormData) => {
        let vendorRate: any;

        if (data.maximumLimit) {
            if (data.maximumLimit < data.minimumLimit) {
                console.log('Max', data.maximumLimit);
                console.log('Min', data.minimumLimit);
                showToast('The maximum value should not be less than the minimum ', 'danger');
                return;
            }
        }

        if (isRateId) {
            vendorRate = {
                id: rateId,
                menu_package_id: selectedPackage.id,
                price: data.price,
                currency_enum: data.currency,
                lower_limit: data.minimumLimit,
                upper_limit: data.maximumLimit ? data.maximumLimit : null,
            };
        } else {
            vendorRate = {
                menu_package_id: selectedPackage.id,
                price: data.price,
                currency_enum: data.currency,
                lower_limit: data.minimumLimit,
                upper_limit: data.maximumLimit ? data.maximumLimit : null,
            };
        }

        addRateMutataion({
            variables: {
                rate: vendorRate,
            },
        });
    };

    return (
        <div>
            {Notification}
            <Dialog open={isOpen} onClose={handleClose}>
                <DialogTitle>{rate ? 'Update' : 'Add'} Vendor Rate</DialogTitle>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogContent>
                        <DialogContentText>Enter the details to add the rate</DialogContentText>
                        <FormGroup className='mt-4'>
                            <label className='form-control-label' htmlFor='price'>
                                Price (excl VAT)
                            </label>
                            <input
                                className='form-control'
                                {...register('price', {
                                    required: true,
                                    value: rate?.price || 0,
                                })}
                                id='price'
                                step={0.01}
                                placeholder='Enter price...'
                                type='number'
                            />
                            {errors.price && <span className='invalid'>*This field is required</span>}
                        </FormGroup>
                        <FormGroup className='mt-4'>
                            <label className='form-control-label' htmlFor='currency'>
                                Currency
                            </label>
                            <Select
                                id='currency'
                                className='form-control'
                                sx={{ borderRadius: '12px', padding: '0' }}
                                placeholder='Select a role'
                                defaultValue={rate?.currency.value}
                                inputProps={{
                                    ...register('currency', {
                                        required: true,
                                        value: rate?.currency.value,
                                    }),
                                }}>
                                <MenuItem disabled value={rate?.currency.value}>
                                    South African Rand
                                </MenuItem>
                                {rateCurrencyType?.map((rate) => {
                                    return (
                                        <MenuItem key={rate.value} value={rate.value}>
                                            {rate.title}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            {errors.currency && <span className='invalid'>*This field is required</span>}
                        </FormGroup>
                        <div className='row mt-4'>
                            <div className='col-md-12 col-lg-6'>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='minimumLimit'>
                                        minimum
                                    </label>
                                    <input
                                        className='form-control'
                                        type='number'
                                        placeholder='Enter minimum number...'
                                        {...register('minimumLimit', {
                                            required: true,
                                            value: rate?.minimumLimit || 0,
                                        })}
                                        id='minimumLimit'
                                    />
                                    {errors.minimumLimit && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                            </div>
                            <div className='col-md-12 col-lg-6'>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='maximumLimit'>
                                        maximum
                                    </label>
                                    <input
                                        className='form-control'
                                        placeholder='Enter maximum number...'
                                        type='number'
                                        {...register('maximumLimit', {
                                            required: false,
                                            value: rate?.maximumLimit || 0,
                                        })}
                                        id='maximumLimit'
                                    />
                                </FormGroup>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type='submit' disabled={loading}>
                            {loading && <CircularProgress size={16} />}
                            {!loading && <span>{rate ? 'Update' : 'Add'}</span>}
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}
