/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import useToast from "../../../../hooks/useToast";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  REMOVE_MEETING_ROOM_ITEM_IMAGE,
  GET_MEETING_ROOM,
} from "../../../../graphql/meeting-rooms.graphql";

interface ImageProps {
  sourceImage: any;
  id: string;
}

const MeetingRoomItemImage = ({ sourceImage, id }: ImageProps) => {
  const [alert, setAlert] = useState<any>(null);
  const { showToast, Notification } = useToast();
  const [
    removeLocationImageMutation,
    { error: errorRemoving, data: removedImage },
  ] = useMutation(REMOVE_MEETING_ROOM_ITEM_IMAGE, {
    refetchQueries: [{ query: GET_MEETING_ROOM }, "GetMeetingRoom"],
  });

  const displayAlert = (props: any) => {
    setAlert(
      <SweetAlert
        {...props}
        showCancel
        confirmBtnText="Continue"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => {
          deleteMeetingRoomImage();
          setAlert(null);
        }}
        onCancel={() => {
          setAlert(null);
        }}
        focusCancelBtn
      >
        This image will be removed.
      </SweetAlert>
    );
  };

  const deleteMeetingRoomImage = () => {
    removeLocationImageMutation({
      variables: {
        id: id,
      },
    });
  };

  useEffect(() => {
    if (errorRemoving) {
      showToast(
        "An error has occurred while trying to remove the image, please try again",
        "danger"
      );
    }
  }, [errorRemoving]);

  useEffect(() => {
    if (removedImage) {
      showToast("Successfully removed the image", "success");
    }
  }, [removedImage]);

  return (
    <div className="image-container">
      {alert}
      {Notification}
      <img
        src={sourceImage}
        alt="img"
        className="image img-responsive img-resize"
      />
      <h1>
        <div className="overlay-alt">
          <button
            className="btn btn-danger btn-icon-only rounded-circle btn-sm"
            onClick={() => displayAlert({ warning: true })}
          >
            <i className="fa-solid fa-trash-can"></i>
          </button>
        </div>
      </h1>
    </div>
  );
};

export default MeetingRoomItemImage;
