import { Button, FormControlLabel, MenuItem, Radio, Select, SelectChangeEvent, Switch } from '@mui/material';
import React from 'react';
import { SurveyQuestionItem, SurveyQuestionItemType } from '../../../../models/survey.modesl';
import DeleteIcon from '@mui/icons-material/Delete';
import { PhoneInput } from 'react-international-phone';

interface QuestionSectionItemProps {
    item: SurveyQuestionItem;
    typeList: SurveyQuestionItemType[];
    onItemTypeChange: (type: string, item: SurveyQuestionItem) => void;
    onDropdownOptionAdd: (item: SurveyQuestionItem) => void;
    onDropdownOptionRemove: (item: SurveyQuestionItem, index: number) => void;
    onDropdownOptionTitleChange: (item: SurveyQuestionItem, index: number, value: string) => void;
    onListOptionTitleChange: (item: SurveyQuestionItem, index: number, value: string) => void;
    onListOptionAdd: (item: SurveyQuestionItem) => void;
    onListOptionRemove: (item: SurveyQuestionItem, index: number) => void;
    onRequiredChanges: (item: SurveyQuestionItem) => void;
    onItemRemove: (item: SurveyQuestionItem) => void;
    onItemTitleChange: (item: SurveyQuestionItem, value: string) => void;
}
export default function QuestionSectionItem({
    typeList,
    item,
    onItemTypeChange,
    onDropdownOptionAdd,
    onDropdownOptionTitleChange,
    onDropdownOptionRemove,
    onListOptionRemove,
    onListOptionTitleChange,
    onListOptionAdd,
    onRequiredChanges,
    onItemRemove,
    onItemTitleChange,
}: QuestionSectionItemProps) {
    const handleTypeChange = (event: SelectChangeEvent) => {
        onItemTypeChange(event.target.value, item);
    };

    return (
        <div className='mb-4'>
            <div className='row d-flex'>
                <div className='col-lg-7'>
                    <input
                        id='title'
                        placeholder='Enter question item title'
                        value={item.title ?? ''}
                        className='form-control'
                        onChange={(e) => onItemTitleChange(item, e.target.value)}
                        style={{ fontSize: '14px' }}
                    />
                </div>
                <div className='col-lg-5'>
                    <Select
                        id='title'
                        variant='outlined'
                        className='form-control'
                        defaultValue={item.type.value ?? 'empty'}
                        placeholder='Add attribute title'
                        fullWidth={true}
                        onChange={handleTypeChange}
                        style={{ fontSize: '14px', borderRadius: '12px' }}>
                        <MenuItem disabled value={'empty'}>
                            <span className='placeholder-text'>Select question item type...</span>
                        </MenuItem>
                        {typeList.map((type) => {
                            return (
                                <MenuItem key={type.value} value={type.value}>
                                    {type.title}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </div>
            </div>
            <div className='row mt-4 mb-2'>
                <div className='col-lg-12'>
                    {item.type.value === 'text' && (
                        <input className='form-control' type='text' disabled placeholder='Text input field' />
                    )}
                    {item.type.value === 'email' && (
                        <input className='form-control' type='email' disabled placeholder='Email input field' />
                    )}
                    {item.type.value === 'number' && (
                        <input className='form-control' type='number' disabled placeholder='Number input field' />
                    )}
                    {item.type.value === 'date' && (
                        <input className='form-control' type='date' disabled placeholder='Number input field' />
                    )}
                    {item.type.value === 'dropdown' && (
                        <>
                            {item?.dropDownOptions?.map((option, index) => {
                                return (
                                    <div className='row mb-2' key={option.key}>
                                        <div className='col-lg-6'>
                                            <input
                                                value={option.value ?? ''}
                                                type='text'
                                                onChange={(e) =>
                                                    onDropdownOptionTitleChange(item, index, e.target.value)
                                                }
                                                className='form-control'
                                                placeholder={`Option ${index + 1}`}
                                            />
                                        </div>
                                        <div className='col-lg-6'>
                                            <DeleteIcon
                                                sx={{
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => onDropdownOptionRemove(item, index)}
                                            />
                                        </div>
                                    </div>
                                );
                            })}

                            <div className='row mt-4'>
                                <div className='col-lg-6'>
                                    <Button onClick={() => onDropdownOptionAdd(item)}>Add option</Button>
                                </div>
                            </div>
                        </>
                    )}
                    {item.type.value === 'list_option' && (
                        <>
                            {item?.listOptions?.map((option, index) => {
                                return (
                                    <div className='row mb-2' key={option.key}>
                                        <div className='col-lg-1'>
                                            <Radio
                                                disabled
                                                value='a'
                                                name='radio-buttons'
                                                inputProps={{ 'aria-label': 'A' }}
                                            />
                                        </div>
                                        <div className='col-lg-6 text-left'>
                                            <input
                                                value={option.value ?? ''}
                                                type='text'
                                                onChange={(e) => onListOptionTitleChange(item, index, e.target.value)}
                                                className='form-control'
                                                placeholder={`Option ${index + 1}`}
                                            />
                                        </div>
                                        <div className='col-lg-5'>
                                            <DeleteIcon
                                                sx={{
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => onListOptionRemove(item, index)}
                                            />
                                        </div>
                                    </div>
                                );
                            })}

                            <div className='row mt-4'>
                                <div className='col-lg-6'>
                                    <Button onClick={() => onListOptionAdd(item)}>Add option</Button>
                                </div>
                            </div>
                        </>
                    )}

                    {item.type.value === 'phone_number' && (
                        <PhoneInput
                            defaultCountry='za'
                            placeholder='Phone number input field'
                            inputClassName='form-control'
                            disabled
                            style={
                                {
                                    '--react-international-phone-width': '100%',
                                    '--react-international-phone-height': '44px',
                                    '--react-international-phone-border-radius': '12px',
                                } as React.CSSProperties
                            }
                        />
                    )}
                    {item.type.value === 'textarea' && (
                        <textarea
                            style={{
                                backgroundColor: 'transparent',
                            }}
                            className='w-100 p-4 form-control'
                            disabled
                            rows={3}
                            placeholder='Textarea input field'
                        />
                    )}
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <DeleteIcon
                        sx={{
                            cursor: 'pointer',
                        }}
                        className='mt-1'
                        onClick={(e) => {
                            onItemRemove(item);
                        }}
                    />

                    <FormControlLabel
                        sx={{
                            marginLeft: 2,
                            marginTop: 1,
                        }}
                        control={
                            <Switch
                                color='primary'
                                defaultChecked={item.required ?? false}
                                onChange={() => onRequiredChanges(item)}
                            />
                        }
                        label='Required'
                    />
                </div>
            </div>
            <hr></hr>
        </div>
    );
}
