/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Row, Col } from "reactstrap";
import DetailView from "./DetailView";
import { useNavigate, useParams } from "react-router-dom";
import ImageView from "./ImageView";
import { useQuery } from "@apollo/client";
import {
  GET_PRODUCT,
  GET_PRODUCT_STATUS,
  GET_PRODUCT_TYPES,
} from "../../../graphql/products.graphql";
import {
  Image,
  Product,
  ProductItem,
  ProductStatus,
  ProductType,
} from "../../../models/product.model";
import { LinearProgress } from "@mui/material";
import useToast from "../../../hooks/useToast";
import ItemView from "./ItemView";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ProductTabView() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const params: any = useParams();
  const { loading, error, data } = useQuery(GET_PRODUCT, {
    variables: { id: params.id },
  });
  const {
    loading: loadingStatus,
    error: statusError,
    data: statusData,
  } = useQuery(GET_PRODUCT_STATUS);
  const {
    loading: loadingTypes,
    error: typeError,
    data: typesData,
  } = useQuery(GET_PRODUCT_TYPES);
  const { showToast, Notification } = useToast();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (error || typeError || statusError) {
      showToast("An error has occurred", "danger");
    }
  }, [error, typeError, statusError]);

  if (loading || loadingStatus || loadingTypes) {
    return <LinearProgress />;
  }

  const product: Product = data?.product;
  const productImages: Image[] = product?.images;
  const productTypes: ProductType[] = typesData?.types;
  const productStatus: ProductStatus[] = statusData?.status;
  const productItems: ProductItem[] = product?.productItems;

  return (
    <div className="container-fluid mt-4">
      {Notification}
      <Col>
        <Row className="my-4">
          <button
            className="btn btn-outline-primary btn-sm mr-4"
            onClick={() => navigate(-1)}
          >
            <i className="fas fa-angle-left" style={{ fontSize: "14px" }} />
            <span className="btn-inner-text">Back</span>
          </button>
          <h3 className="mb-0">Manage product</h3>
        </Row>
      </Col>
      <Col>
        <Row>
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                color: "#000",
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  sx={{ textTransform: "capitalize" }}
                  label="Details"
                  {...a11yProps(0)}
                />
                <Tab
                  sx={{ textTransform: "capitalize" }}
                  label="Items"
                  {...a11yProps(1)}
                />
                <Tab
                  sx={{ textTransform: "capitalize" }}
                  label="Images"
                  {...a11yProps(2)}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <DetailView
                product={product}
                productStatus={productStatus}
                productTypes={productTypes}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ItemView productItems={productItems} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <ImageView images={productImages} />
            </TabPanel>
          </Box>
        </Row>
      </Col>
    </div>
  );
}
