/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Col, Row, UncontrolledTooltip } from "reactstrap";
import {
  AfterHourResource,
  BookingResource,
} from "../../../../models/resources.model";
import AddResourceDialog from "../dialogs/AddResourceDialog";
import BookingResourceItem from "./BookingResourceItem";

interface Props {
  afterHourResources?: AfterHourResource[];
  getBookingResources?: (resources: BookingResource[]) => void;
  bookingResources?: BookingResource[];
  bookingHours?: number;
}

const BookingAfterHourResourcesView = ({
  afterHourResources,
  getBookingResources,
  bookingResources,
  bookingHours,
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const getItemsTotalPrice = (): number => {
    let total = 0;
    bookingResources.forEach((resource) => {
      total += resource?.totalPrice;
    });
    return total;
  };

  return (
    <>
      {isOpen && (
        <AddResourceDialog
          isOpen={isOpen}
          handleClose={() => setIsOpen(false)}
          afterHourResources={afterHourResources}
          getBookingResources={getBookingResources}
          bookingResources={bookingResources || []}
          bookingHours={bookingHours}
        />
      )}
      <Row>
        <Col>
          <Row>
            <Col xs="6">
              <h3 className="mb-0">Selected resources</h3>
            </Col>
            <Col className="text-right" xs="6">
              <div
                className="btn btn-dark btn-sm"
                color="default"
                id="tooltip969372949"
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                Add resource
              </div>
              <UncontrolledTooltip delay={0} target="tooltip969372949">
                Add resource
              </UncontrolledTooltip>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="menu-items">
              {bookingResources?.map((item) => {
                return (
                  <BookingResourceItem
                    resourceItem={item}
                    selectedResources={bookingResources}
                    getBookingResources={getBookingResources}
                  />
                );
              })}
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <div className="d-flex h-100">
                <div className="align-self-end ml-auto">
                  {bookingResources?.length > 0 && (
                    <span>
                      Total R{getItemsTotalPrice().toFixed(2)} (excl VAT)
                    </span>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default BookingAfterHourResourcesView;
