/* eslint-disable react-hooks/exhaustive-deps */

import { useMutation, useQuery } from '@apollo/client';
import { LinearProgress, MenuItem, Select } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Card, CardBody, Col, FormGroup, Row, Spinner } from 'reactstrap';
import { ADD_VENDOR, GET_VENDOR_RESOURCES } from '../../../graphql/vendors.graphql';
import useToast from '../../../hooks/useToast';
import { Vendor, VendorStatus } from '../../../models/vendor.model';
import FeaturedImage from '../../../components/Image component/ImageContainer';
import { uploadImage } from '../../../hooks/upload-image.hook';
import { Location } from '../../../models/location.model';

interface VendorViewProps {
    vendor: Vendor;
}

type FormData = {
    name: string;
    registrationNumber: string;
    vatNumber: string;
    code?: string;
    location: string;
    deliveryFee?: number;
    description: string;
    status: string;
    type: string;
};

export default function EditVendorForm({ vendor }: VendorViewProps) {
    const [imageFile, setImageFile] = useState<any>();
    const { showToast, Notification } = useToast();
    const [image, setImage] = useState<string>(`${process.env.REACT_APP_IMAGE_URL}/${vendor?.imagePath}`);
    const fileInputRef: any = useRef(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { data, loading, error } = useQuery(GET_VENDOR_RESOURCES);

    const [addVendorMutation, { loading: isAdding, error: addingError, called }] = useMutation(ADD_VENDOR, {
        // refetchQueries: [{ query: GET_VENDORS }, "getVendors"],
        refetchQueries: ['getVendor', 'getVendors'],
    });

    const {
        register,
        handleSubmit,
        // reset,
        formState: { errors },
    } = useForm<FormData>();

    useEffect(() => {
        if (!isAdding && called && !addingError) {
            showToast('Vendor updated successfully', 'success');
            // reset();
            setIsLoading(false);
        }

        if (error) {
            showToast('An error has occurred, please refresh the page', 'danger');
        }
    }, [isAdding, called, addingError, error]);

    const onSubmit = async (data: FormData) => {
        let imageKey;

        setIsLoading(true);

        if (imageFile) {
            imageKey = await uploadImage(imageFile, 'product_images');
        }

        if (!imageKey && vendor.imagePath) {
            imageKey = vendor.imagePath;
        }

        if (imageKey === 'error') {
            showToast('An error has occurred while trying to upload the image', 'danger');
            setIsLoading(false);
            return;
        }

        const vendorData = {
            id: vendor.id,
            name: data.name,
            registration_number: data.registrationNumber,
            vat_number: data.vatNumber,
            description: data.description,
            location_id: data.location,
            status_enum: data.status,
            code: data?.code || undefined,
            delivery_fee: data?.deliveryFee || undefined,
            image_path: imageKey,
            type_enum: vendor.type.value,
        };

        console.log('Vendor payload', vendorData);
        addVendorMutation({
            variables: { vendor: vendorData },
        });
    };

    const onImageChange = (e: any) => {
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setImageFile(e.target.files[0]);
        setImage(imageUrl);
    };

    const vendorStatusList: VendorStatus[] = data?.vendorStatus;
    const locations: Location[] = data?.location;

    if (loading) {
        return <LinearProgress />;
    }

    return (
        <>
            {Notification}

            <Row className='mt-4'>
                <Col md='12' lg='6'>
                    <Card>
                        <CardBody>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <FormGroup>
                                    <label className='form-control-label'>Featured Image</label>
                                    <FeaturedImage fileInputRef={fileInputRef} imageSource={image} />
                                    <input
                                        ref={fileInputRef}
                                        type='file'
                                        accept='image/*'
                                        onChange={(e) => {
                                            onImageChange(e);
                                        }}
                                        style={{ display: 'none' }}
                                    />
                                </FormGroup>
                                <div className='row'>
                                    <div className='col-lg-6 col-md-12'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='name'>
                                                Name
                                            </label>
                                            <input
                                                className='form-control'
                                                {...register('name', {
                                                    required: true,
                                                    value: vendor?.name,
                                                })}
                                                id='name'
                                                placeholder="Enter vendor's name..."
                                                type='text'
                                            />
                                            {errors.name && <span className='invalid'>*This field is required</span>}
                                        </FormGroup>
                                    </div>
                                    <div className='col-lg-6 col-md-12'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='registrationNumber'>
                                                Registration number
                                            </label>
                                            <input
                                                className='form-control'
                                                {...register('registrationNumber', {
                                                    required: true,
                                                    value: vendor?.registrationNumber,
                                                })}
                                                id='registrationNumber'
                                                placeholder="Enter vendor's registration number..."
                                                type='text'
                                            />
                                            {errors.registrationNumber && (
                                                <span className='invalid'>*This field is required</span>
                                            )}
                                        </FormGroup>
                                    </div>
                                    <div className='col-lg-6 col-md-12'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='vatNumber'>
                                                VAT number
                                            </label>
                                            <input
                                                className='form-control'
                                                {...register('vatNumber', {
                                                    required: true,
                                                    value: vendor?.vatNumber,
                                                })}
                                                id='vatNumber'
                                                placeholder="Enter vendor's vat number..."
                                                type='text'
                                            />
                                            {errors.vatNumber && (
                                                <span className='invalid'>*This field is required</span>
                                            )}
                                        </FormGroup>
                                    </div>
                                    <div className='col-lg-6 col-md-12'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='status'>
                                                Status
                                            </label>
                                            <Select
                                                id='status'
                                                sx={{ borderRadius: '12px', padding: '0' }}
                                                className='form-control'
                                                defaultValue={vendor?.status.value}
                                                inputProps={{
                                                    ...register('status', {
                                                        required: true,
                                                        value: vendor?.status.value,
                                                    }),
                                                }}>
                                                <MenuItem disabled value={vendor?.status.value}>
                                                    Select status...
                                                </MenuItem>
                                                {vendorStatusList?.map((status: any) => {
                                                    return (
                                                        <MenuItem key={status.value} value={status.value}>
                                                            {status.title}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                            {errors.status && <span className='invalid'>*This field is required</span>}
                                        </FormGroup>
                                    </div>
                                    <div className='col-lg-6 col-md-12'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='name'>
                                                Code(Optional)
                                            </label>
                                            <input
                                                className='form-control'
                                                {...register('code', {
                                                    required: false,
                                                    value: vendor?.code || '',
                                                })}
                                                id='name'
                                                placeholder="Enter vendor's code..."
                                                type='text'
                                            />
                                            {errors.name && <span className='invalid'>*This field is required</span>}
                                        </FormGroup>
                                    </div>
                                    <div className='col-lg-6 col-md-12'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='name'>
                                                Delivery fee(Optional)
                                            </label>
                                            <input
                                                className='form-control'
                                                {...register('deliveryFee', {
                                                    required: false,
                                                    value: vendor?.deliveryFee,
                                                })}
                                                id='name'
                                                placeholder="Enter vendor's delivery fee..."
                                                type='number'
                                            />
                                            {errors.name && <span className='invalid'>*This field is required</span>}
                                        </FormGroup>
                                    </div>
                                    <div className='col-lg-6 col-md-12'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='type'>
                                                Location
                                            </label>
                                            <Select
                                                id='type'
                                                sx={{ borderRadius: '12px', padding: '0' }}
                                                className='form-control'
                                                defaultValue={vendor?.location.id || 'empty'}
                                                inputProps={{
                                                    ...register('location', {
                                                        required: true,
                                                    }),
                                                }}>
                                                <MenuItem disabled value=' '>
                                                    Select location...
                                                </MenuItem>
                                                {locations?.map((location) => {
                                                    return (
                                                        <MenuItem key={location?.id} value={location?.id}>
                                                            {location?.name}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormGroup>
                                    </div>
                                    <div className='col-lg-6 col-md-12'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='description'>
                                                Description
                                            </label>
                                            <textarea
                                                className='form-control'
                                                {...register('description', {
                                                    required: true,
                                                    value: vendor?.description,
                                                })}
                                                id='description'
                                                placeholder="Enter vendor's description..."></textarea>
                                            {errors.description && (
                                                <span className='invalid'>*This field is required</span>
                                            )}
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className='d-flex h-100'>
                                    <div className='align-self-end ml-auto'>
                                        <Button
                                            type='submit'
                                            className='btn btn-dark mt-4 btn-block'
                                            disabled={isLoading}>
                                            {!isLoading ? (
                                                <span>Update Vendor</span>
                                            ) : (
                                                <span>
                                                    <Spinner />
                                                </span>
                                            )}
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
}
