import { gql } from "@apollo/client";

export const CHECK_BOOKING_AVAILABILITY = gql`
  mutation CheckAvailablePackage(
    $packageId: String!
    $from: String!
    $to: String!
  ) {
    booking: check_package_availability(
      packageId: $packageId
      from: $from
      to: $to
    ) {
      is_available
    }
  }
`;
