import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useState } from "react";
import { Row } from "reactstrap";
import {
  AfterHourResource,
  BookingResource,
} from "../../../../models/resources.model";

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  afterHourResources?: AfterHourResource[];
  getBookingResources?: (resources: BookingResource[]) => void;
  bookingResources: BookingResource[];
  bookingHours?: number;
}

const AddResourceDialog = ({
  isOpen,
  handleClose,
  afterHourResources,
  getBookingResources,
  bookingResources,
  bookingHours,
}: Props) => {
  const [selectedResource, setSelectedResources] =
    useState<BookingResource>(null);

  const onAddItems = () => {
    const items = [...bookingResources, selectedResource];
    getBookingResources(items);
    handleClose();
  };

  const onSelectedResource = (resourceId: string) => {
    const afterHourResourceOption = afterHourResources?.find(
      (resource) => resource?.id === resourceId
    );

    const newItem: BookingResource = {
      name: afterHourResourceOption?.name,
      rate: afterHourResourceOption?.rate,
      quantity: bookingHours,
      resourceId: afterHourResourceOption?.id,
      totalPrice: afterHourResourceOption?.rate * bookingHours,
    };

    setSelectedResources(newItem);
  };
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>Add resource</DialogTitle>
      <DialogContent
        sx={{
          width: 600,
        }}
      >
        <Row>
          <div className="col">
            <Select
              sx={{
                width: "100%",
              }}
              variant="standard"
              defaultValue={"empty"}
              onChange={(e) => {
                onSelectedResource(e.target.value);
              }}
            >
              <MenuItem disabled value={"empty"}>
                <span className="placeholder-text">
                  Select a resource type...
                </span>
              </MenuItem>
              {afterHourResources?.map((resource) => {
                return (
                  <MenuItem value={resource?.id} key={resource?.id}>
                    {resource?.name}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        </Row>
      </DialogContent>
      <DialogActions>
        <div className="mt-4 d-flex h-100 p-2">
          <div className="align-self-end ml-auto">
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={onAddItems}
              disabled={!selectedResource}
              type="submit"
            >
              Add
            </Button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default AddResourceDialog;
