import { gql } from '@apollo/client';

export const GET_LOCATIONS = gql`
    query GetLocation {
        location(where: { deleted_at: { _is_null: true } }, order_by: { name: asc }) {
            id
            name
            city
            country
            postal_code
            street_address
        }
    }
`;
export const ADD_LOCATION = gql`
    mutation Addlocation($location: [location_insert_input!]!) {
        insert_location(objects: $location) {
            returning {
                id
            }
        }
    }
`;

export const UPDATE_LOCATION = gql`
    mutation UpdateLocation($id: uuid!, $location: location_set_input!) {
        update_location_by_pk(pk_columns: { id: $id }, _set: $location) {
            id
        }
    }
`;

export const GET_PORTAL_LOCATION = gql`
    query GetLocation($id: uuid!) {
        location: location_by_pk(id: $id) {
            id
            name
            images: location_images(where: { deleted_at: { _is_null: true } }) {
                id
                path
            }
            street_address
            city
            postal_code
            country
            rooms: location_spaces(
                where: { type_enum: { _eq: room }, deleted_at: { _is_null: true } }
                order_by: { name: asc }
            ) {
                id
                name
                parent: parent_location_space {
                    name
                }
                status {
                    value
                    title
                }
            }
            floors: location_spaces(
                where: { type_enum: { _eq: floor }, deleted_at: { _is_null: true } }
                order_by: { name: asc }
            ) {
                id
                name
                status {
                    value
                    title
                }
            }
            featuredImage: featured_image
        }
    }
`;

export const REMOVE_LOCATION = gql`
    mutation RemoveLocation($id: uuid!) {
        update_location_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const GET_LOCATION_IMAGE = gql`
    query GetLocationImage($id: uuid!) {
        location: location_image_by_pk(id: $id) {
            id
        }
    }
`;

export const ADD_LOCATION_IMAGE = gql`
    mutation AddLocationImage($image: location_image_insert_input!) {
        insert_location_image_one(object: $image) {
            id
        }
    }
`;

export const REMOVE_LOCATION_IMAGE = gql`
    mutation RemoveLocationImage($id: uuid!) {
        update_location_image_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const ADD_SPACE_LOCATION = gql`
    mutation AddSpaceLocation($space: location_space_insert_input!) {
        insert_location_space_one(
            object: $space
            on_conflict: { constraint: location_space_pkey, update_columns: [status_enum, name, parent_id] }
        ) {
            id
        }
    }
`;

export const GET_LOCATION_SPACE_STATUS_LIST = gql`
    query GetSpaceLocationStatusList {
        status: location_space_status {
            value
            title
        }
    }
`;

export const GET_FLOOR_DETAILS = gql`
    query GetFloorSPaceDetails($id: uuid!) {
        floor: location_space_by_pk(id: $id) {
            id
            name
            status {
                value
                title
            }
        }
    }
`;

export const GET_ROOM_DETAILS = gql`
    query GetRoomSPaceDetails($id: uuid!) {
        room: location_space_by_pk(id: $id) {
            id
            name
            parent: parent_location_space {
                id
                name
            }
            status {
                value
                title
            }
        }
    }
`;

export const REMOVE_LOCATION_SPACE = gql`
    mutation RemoveLocationSpace($id: uuid!) {
        space: update_location_space_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;
