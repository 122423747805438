/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import { Image } from '../../../models/product.model';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import ProductImage from '../../../components/Image component/ProductImage';
import { ADD_IMAGE_PRODUCT } from '../../../graphql/products.graphql';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { uploadImage } from '../../../hooks/upload-image.hook';
import useToast from '../../../hooks/useToast';
import { CircularProgress, LinearProgress } from '@mui/material';

interface ImageViewProps {
    images: Image[] | undefined;
}

export default function ImageView({ images }: ImageViewProps) {
    const params: any = useParams();
    const fileInputRef: any = useRef(null);
    const { showToast, Notification } = useToast();
    const [isAddingImage, setIsAddingImage] = useState<boolean>(false);
    const [addProductImageMutation, { error: errorAddingImage, data: addedImage }] = useMutation(ADD_IMAGE_PRODUCT, {
        // refetchQueries: [{ query: GET_PRODUCT }, "GetProduct"],
        refetchQueries: ['GetProduct'],
    });

    const changeImage = (e: any) => {
        AddProductImage(e.target.files[0]);
    };

    useEffect(() => {
        if (errorAddingImage) {
            showToast('An error has occurred while trying to add an image, please try again', 'danger');
            setIsAddingImage(false);
        }
    }, [errorAddingImage]);

    useEffect(() => {
        if (addedImage) {
            showToast('Successfully added an image for this product', 'success');
            setIsAddingImage(false);
        }
    }, [addedImage]);

    const AddProductImage = async (imageFile: any) => {
        setIsAddingImage(true);
        const imageKey = await uploadImage(imageFile, 'product_images');

        if (imageKey === 'error') {
            showToast('An error has occurred while trying to upload the image, please try again', 'danger');
            setIsAddingImage(false);
            return;
        }

        const _image = {
            product_id: params.id,
            path: imageKey,
        };

        addProductImageMutation({
            variables: {
                image: _image,
            },
        });
    };

    if (isAddingImage) {
        return (
            <>
                {Notification}
                <LinearProgress />
            </>
        );
    }

    if (images?.length === 0) {
        return (
            <>
                {Notification}
                <Card className='mt-4'>
                    <CardHeader>
                        <button
                            onClick={() => {
                                fileInputRef.current.click();
                            }}
                            disabled={isAddingImage}
                            className='btn btn-dark btn-sm'>
                            {isAddingImage ? <CircularProgress color='primary' size={16} /> : <span>Add</span>}
                        </button>
                        <input
                            onChange={(e) => changeImage(e)}
                            ref={fileInputRef}
                            type='file'
                            accept='image/*'
                            style={{ display: 'none' }}
                        />
                    </CardHeader>
                    <CardBody>
                        <div>
                            <Row>
                                <Col>
                                    <h3>No Image for this product</h3>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </>
        );
    }

    return (
        <>
            {Notification}
            <Card className='mt-4'>
                <CardHeader>
                    <button
                        onClick={() => {
                            fileInputRef.current.click();
                        }}
                        disabled={isAddingImage}
                        className='btn btn-dark btn-sm'>
                        {isAddingImage ? <CircularProgress color='primary' size={16} /> : <span>Add</span>}
                    </button>
                    <input
                        onChange={(e) => changeImage(e)}
                        ref={fileInputRef}
                        type='file'
                        accept='image/*'
                        style={{ display: 'none' }}
                    />
                </CardHeader>
                <CardBody>
                    <div className='container'>
                        <Row>
                            {images?.map((image) => {
                                return (
                                    <div key={image.id} className='pr-4 mt-4'>
                                        <ProductImage
                                            id={image.id}
                                            sourceImage={`${process.env.REACT_APP_IMAGE_URL}/${image?.path}`}
                                        />
                                    </div>
                                );
                            })}
                        </Row>
                    </div>
                </CardBody>
            </Card>
        </>
    );
}
