import { gql } from '@apollo/client';

export const GET_MAIL_LISTS = gql`
    query GetMailLists {
        mailList: mail_list(where: { deleted_at: { _is_null: true } }) {
            id
            name
            status {
                value
                title
            }
        }
    }
`;

export const GET_STATUS_LIST = gql`
    query GetMailListStatus {
        statusList: mail_list_status {
            value
            title
        }
    }
`;
export const ADD_MAIL_LIST = gql`
    mutation AddMailList($mailList: mail_list_insert_input!) {
        mailList: insert_mail_list_one(
            object: $mailList
            on_conflict: { constraint: mail_list_pkey, update_columns: [name, status_enum] }
        ) {
            id
        }
    }
`;

export const GET_MAIL_LIST_DETAILS = gql`
    query GetMailListDetails($id: uuid!) {
        mailList: mail_list_by_pk(id: $id) {
            id
            name
            status {
                value
                title
            }
            emails: mail_list_emails(where: { deleted_at: { _is_null: true } }) {
                id
                email
                title
                subject
                publishedDate: published_date
                status {
                    value
                    title
                }
                template: email_template {
                    id
                    name
                }
            }
        }
        statusList: newsletter_email_status {
            value
            title
        }
    }
`;

export const ADD_MAIL_LIST_EMAIL = gql`
    mutation AddEmail($email: mail_list_email_insert_input!) {
        email: insert_mail_list_email_one(
            object: $email
            on_conflict: {
                constraint: mail_list_email_mail_list_id_email_key
                update_columns: [email, status_enum, deleted_at]
            }
        ) {
            id
        }
    }
`;

export const UPDATE_MAIL_LIST_EMAIL = gql`
    mutation UpdateMailListEmail($id: uuid!, $email: mail_list_email_set_input!) {
        email: update_mail_list_email_by_pk(pk_columns: { id: $id }, _set: $email) {
            id
        }
    }
`;

export const ADD_MAIL_LIST_EMAILS = gql`
    mutation AddMailListEmails($emails: [mail_list_email_insert_input!]!) {
        emails: insert_mail_list_email(
            objects: $emails
            on_conflict: { constraint: mail_list_email_mail_list_id_email_key, update_columns: [deleted_at] }
        ) {
            returning {
                id
            }
        }
    }
`;

export const REMOVE_MAIL_LIST = gql`
    mutation RemoveMailList($id: uuid!) {
        mailList: update_mail_list_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const REMOVE_MAIL_LIST_EMAIL = gql`
    mutation RemoveMailListEmail($id: uuid!) {
        email: update_mail_list_email_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const SEND_MAIL_LIST_EMAILS = gql`
    mutation SendMailListEmails($id: String!) {
        response: send_mail_list_emails(id: $id) {
            message
        }
    }
`;
