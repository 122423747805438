/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Col, Container, Row } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import MeetingRoomDetails from "./MeetingRoomDetails";
import MeetingRoomItemView from "./MeetingRoomItemView";
import BaseRoot from "./components/room-extras/BaseRoot";
import { GET_MEETING_ROOM } from "../../../graphql/meeting-rooms.graphql";
import { useQuery } from "@apollo/client";
import { LinearProgress } from "@mui/material";
import useToast from "../../../hooks/useToast";
import {
  MeetingRoom,
  MeetingRoomItem,
  // RateType,
} from "../../../models/meeting.room.model";
import MeetingRoomImageView from "./MeetingRoomImageView";
import { Image } from "../../../models/product.model";
import { RoomExtra } from "../../../models/room.extra.model";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ClientManageView() {
  const [value, setValue] = React.useState(0);
  // const history = useHistory();
  const navigate = useNavigate();
  const { showToast, Notification } = useToast();

  const params: any = useParams();
  const {
    loading: loadingRoom,
    error: errorLoadingRoom,
    data: loadedRoom,
  } = useQuery(GET_MEETING_ROOM, {
    variables: {
      id: params.id,
    },
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const redirectToRooms = (e: any) => {
    e.preventDefault();
    navigate(-1);
  };

  React.useEffect(() => {
    if (errorLoadingRoom) {
      showToast("An error has occurred, please refresh the page", "danger");
    }
  }, [errorLoadingRoom]);

  if (loadingRoom) {
    return <LinearProgress />;
  }

  const room: MeetingRoom = loadedRoom?.room;
  const items: MeetingRoomItem[] = room?.items;
  const images: Image[] = room?.images;
  const roomExtras: RoomExtra[] = loadedRoom?.room?.roomExtras;

  return (
    <>
      {Notification}
      <Container className="mt-4" fluid>
        <Row className="mx-2">
          <Col>
            <Row className="pb-4">
              <button
                className="btn btn-outline-primary btn-sm mr-4"
                onClick={(e) => {
                  redirectToRooms(e);
                }}
              >
                <i className="fas fa-angle-left" style={{ fontSize: "14px" }} />
                <span className="btn-inner-text">Back</span>
              </button>
              <h3 className="m-0">Manage meeting rooms</h3>
            </Row>
            <Row>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      sx={{ textTransform: "capitalize" }}
                      label="Details"
                      {...a11yProps(0)}
                    />
                    <Tab
                      sx={{ textTransform: "capitalize" }}
                      label="Rooms"
                      {...a11yProps(1)}
                    />
                    <Tab
                      sx={{ textTransform: "capitalize" }}
                      label="Images"
                      {...a11yProps(2)}
                    />
                    <Tab
                      sx={{ textTransform: "capitalize" }}
                      label="Extras"
                      {...a11yProps(3)}
                    />
                  </Tabs>
                </Box>
                <Box>
                  <TabPanel value={value} index={0}>
                    <MeetingRoomDetails room={room} />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <MeetingRoomItemView items={items} />
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <MeetingRoomImageView
                      images={images}
                      isMeetingRoomView={true}
                      roomItem={null}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    <BaseRoot roomExtras={roomExtras} />
                  </TabPanel>
                </Box>
              </Box>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
