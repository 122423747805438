/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from "@apollo/client";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
} from "@mui/material";
import { FormGroup } from "reactstrap";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  GET_MEETING_ROOM,
  INSERT_MEETING_ROOM_RATE,
} from "../../../../graphql/meeting-rooms.graphql";
import useToast from "../../../../hooks/useToast";
import { RateType, RoomRate } from "../../../../models/meeting.room.model";

interface AddRoomRateDialogProps {
  rateTypes: RateType[];
  isOpen: boolean;
  roomId: string;
  rate: RoomRate | null;
  toggleOpen: (isOpen: boolean) => void;
}

type FormData = {
  price: number;
  currency: string;
  type: string;
};

export const AddRoomRateDialog = ({
  isOpen,
  rateTypes,
  toggleOpen,
  roomId,
  rate,
}: AddRoomRateDialogProps) => {
  const [addRoomRate, { error, called, loading, reset }] = useMutation(
    INSERT_MEETING_ROOM_RATE,
    {
      refetchQueries: [{ query: GET_MEETING_ROOM }, "GetMeetingRoom"],
    }
  );
  const { showToast, Notification } = useToast();

  useEffect(() => {
    if (called && !loading && !error) {
      showToast(
        `Successfully ${rate ? "updated" : "added"} room rate`,
        "success"
      );
      reset();
      resetForm();
      toggleOpen(false);
    }
    if (called && error) {
      showToast("An error has occurred, please try again", "danger");
    }
  }, [called, loading, error]);

  useEffect(() => {
    if (rate) {
      setValue("price", rate.price);
      setValue("currency", rate.currency);
      setValue("type", rate.type.value);
    }
  }, [rate]);

  const handleClose = () => {
    toggleOpen(false);
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset: resetForm,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmit = (data: FormData) => {
    const roomRate = {
      meeting_room_id: roomId,
      price: data.price,
      currency_code: data.currency,
      rate_type_enum: data.type,
      vat_percentage: 0.15,
      deleted_at: null,
    };
    addRoomRate({
      variables: {
        roomRate,
      },
    });
  };

  return (
    <div>
      {Notification}
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>{rate ? "Update" : "Add"} Room Rate</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <DialogContentText>
              Enter the details to add the rate to this room
            </DialogContentText>
            <FormGroup>
              <label className="form-control-label" htmlFor="name">
                Price (excl VAT)
              </label>
              <input
                className="form-control"
                {...register("price", { required: true, value: rate?.price })}
                id="company-name"
                placeholder="Enter price..."
                type="number"
                step={0.01}
              />
              {errors.price && (
                <span className="invalid">*This field is required</span>
              )}
            </FormGroup>
            <FormGroup>
              <label className="form-control-label" htmlFor="currency">
                Currency
              </label>
              <Select
                id="currency"
                className="form-control"
                sx={{ borderRadius: "12px", padding: "0" }}
                placeholder="Select a role"
                defaultValue={"zar"}
                inputProps={{
                  ...register("currency", {
                    required: true,
                    value: rate?.currency || "zar",
                  }),
                }}
              >
                <MenuItem key={"zar"} value={"zar"}>
                  South African Rand
                </MenuItem>
              </Select>
              {errors.currency && (
                <span className="invalid">*This field is required</span>
              )}
            </FormGroup>
            <FormGroup>
              <label className="form-control-label" htmlFor="currency">
                Rate Type
              </label>
              <Select
                id="type"
                className="form-control"
                sx={{ borderRadius: "12px", padding: "0" }}
                placeholder="Select a type"
                defaultValue={rate?.type.value}
                inputProps={{
                  ...register("type", {
                    required: true,
                    value: rate?.type.value,
                  }),
                }}
              >
                {rateTypes.map((type) => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.title}
                  </MenuItem>
                ))}
              </Select>
              {errors.type && (
                <span className="invalid">*This field is required</span>
              )}
            </FormGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit" disabled={loading}>
              {loading && <CircularProgress size={16} />}
              {!loading && <span>{rate ? "Update" : "Add"}</span>}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
