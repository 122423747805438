/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  CircularProgress,
  MenuItem,
} from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { FormGroup } from "reactstrap";
import FeaturedImage from "../../../../components/Image component/ImageContainer";
import { useForm } from "react-hook-form";
import {
  ADD_MEETING_ROOM_CONFIGURATION,
  MEETING_CONFIGURATION_STATUS,
  GET_MEETING_ROOM,
  UPDATE_MEETING_ROOM_CONFIGURATION,
} from "../../../../graphql/meeting-rooms.graphql";
import { useMutation, useQuery } from "@apollo/client";
import { uploadImage } from "../../../../hooks/upload-image.hook";
import useToast from "../../../../hooks/useToast";
import { useParams } from "react-router-dom";
import { MeetingRoomConfiguration } from "../../../../models/meeting.room.model";
interface AddConfigurationProps {
  isOpen: boolean;
  handleClose: () => void;
  configuration: MeetingRoomConfiguration;
}

type FormData = {
  name: string;
  description: string;
  price?: number;
  status: string;
};

const AddConfigurationDialog = ({
  isOpen,
  handleClose,
  configuration,
}: AddConfigurationProps) => {
  const [image, setImage] = useState<string>("");
  const [imageFile, setImageFile] = useState<any>(null);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const { showToast, Notification } = useToast();
  const params: any = useParams();

  const [
    addConfiguration,
    { data: addedConfiguration, error, loading: adding },
  ] = useMutation(ADD_MEETING_ROOM_CONFIGURATION, {
    refetchQueries: [GET_MEETING_ROOM],
  });

  const [
    updateConfiguration,
    { data: updated, error: updateError, loading: updating, reset },
  ] = useMutation(UPDATE_MEETING_ROOM_CONFIGURATION, {
    refetchQueries: [GET_MEETING_ROOM],
  });

  const { data: statusData } = useQuery(MEETING_CONFIGURATION_STATUS);

  const onSubmit = async (data: FormData) => {
    let imageKey;

    if (!imageFile && !configuration) {
      showToast(
        "Please make sure that an image file has been selected",
        "danger"
      );
      return;
    }

    setIsUpdating(true);
    if (imageFile)
      imageKey = await uploadImage(imageFile, "meeting_rooms_images");
    if (!imageFile && configuration?.image) imageKey = configuration?.image;

    if (imageKey === "error") {
      showToast(
        "An error has occurred while trying to upload the image",
        "danger"
      );
      return;
    }

    const config = {
      name: data.name,
      description: data.description,
      configuration_enum_status: data.status ? data.status : "active",
      meeting_room_id: params.id,
      image_path: imageKey,
      price: data.price > 0 ? data.price : null,
    };

    if (configuration) {
      updateConfiguration({
        variables: {
          id: configuration?.id,
          configuration: config,
        },
      });
      return;
    }

    addConfiguration({
      variables: {
        configuration: config,
      },
    });
  };

  useEffect(() => {
    if (addedConfiguration && !error) {
      showToast("Successfully added a configuration for this room", "success");
      setIsUpdating(false);
    }

    if (updated && !updateError) {
      showToast("Successfully updated configuration for this room", "success");
      setIsUpdating(false);
      reset();
    }

    if (error) {
      showToast(
        "An error has occurred while trying to add a configuration.",
        "error"
      );
      setIsUpdating(false);
    }

    if (updateError) {
      showToast(
        "An error has occurred while trying to update this configuration.",
        "error"
      );
      setIsUpdating(false);
    }
  }, [addedConfiguration, error, updated, updateError]);

  useEffect(() => {
    if (configuration) {
      console.log("Configuration price", configuration?.price);
      setImage(`${process.env.REACT_APP_IMAGE_URL}/${configuration?.image}`);
      setValue("name", configuration?.name);
      setValue("price", configuration?.price || 0);
      setValue("description", configuration?.description);
      setValue("status", "active");
    } else {
      setImage("");
      setValue("name", "");
      setValue("price", 0);
      setValue("description", "");
      setValue("status", "");
    }
  }, [configuration, statusData]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>();

  const onImageChange = (e: any) => {
    const imageUrl = URL.createObjectURL(e.target.files[0]);
    setImageFile(e.target.files[0]);
    setImage(imageUrl);
  };

  const fileRef: any = useRef(null);
  return (
    <div>
      {Notification}
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>
          {configuration ? "Update" : "Add"} Room Configuration
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <DialogContentText className="mb-4">
              {configuration
                ? "Enter the details to update a configuration for this room"
                : "Enter the details to add a configuration for this room"}
            </DialogContentText>
            <FormGroup>
              <label className="form-control-label" htmlFor="name">
                Image
              </label>
              <FeaturedImage imageSource={image} fileInputRef={fileRef} />
              <input
                onChange={(e) => onImageChange(e)}
                type="file"
                accept="image/*"
                ref={fileRef}
                style={{ display: "none" }}
              />
            </FormGroup>
            <FormGroup>
              <label className="form-control-label" htmlFor="name">
                Name
              </label>
              <input
                className="form-control"
                id="discount-name"
                placeholder="Enter name..."
                {...register("name", {
                  required: true,
                  value: configuration?.name,
                })}
                type="text"
              />
              {errors.name && (
                <span className="invalid">*This field is required</span>
              )}
            </FormGroup>
            <FormGroup>
              <label className="form-control-label" htmlFor="price">
                Price(Optional)
              </label>
              <input
                className="form-control"
                id="discount-name"
                placeholder="Enter price..."
                {...register("price", {
                  required: false,
                  value: configuration?.price || 0,
                })}
                type="number"
              />
            </FormGroup>
            <FormGroup>
              <label className="form-control-label" htmlFor="description">
                Description
              </label>
              <textarea
                className="form-control"
                id="description"
                placeholder="Enter description..."
                {...register("description", {
                  required: true,
                  value: configuration?.description,
                })}
                rows={3}
              ></textarea>
              {errors.description && (
                <span className="invalid">*This field is required</span>
              )}
            </FormGroup>
            {configuration && (
              <FormGroup>
                <label className="form-control-label" htmlFor="currency">
                  Status
                </label>
                <Select
                  id="type"
                  className="form-control"
                  sx={{ borderRadius: "12px", padding: "0" }}
                  placeholder="Select a type"
                  inputProps={{
                    ...register("status", {
                      required: true,
                      value: "active",
                    }),
                  }}
                  defaultValue={configuration?.status.value || ""}
                >
                  {statusData?.status.map((status: any) => {
                    return (
                      <MenuItem key={status?.value} value={status?.value}>
                        {status?.title}
                      </MenuItem>
                    );
                  })}
                </Select>
                {errors.description && configuration && (
                  <span className="invalid">*This field is required</span>
                )}
              </FormGroup>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">
              {(adding || isUpdating || updating) && (
                <CircularProgress size={16} />
              )}
              {!isUpdating && <span>{configuration ? "Update" : "Add"}</span>}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default AddConfigurationDialog;
