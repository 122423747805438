/* eslint-disable react-hooks/exhaustive-deps */
import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  MenuPackage,
  Vendor,
  VendorMenuPackageItem,
} from "../../models/vendor.model";

interface Props {
  menuPackage: MenuPackage;
  selectedMenuItems: VendorMenuPackageItem[];
  selectedVendor?: Vendor;
  getItems?: (items: VendorMenuPackageItem[]) => void;
}

const VendorMenuPackageItems = ({
  menuPackage,
  getItems,
  selectedMenuItems,
  selectedVendor,
}: Props) => {
  const [quantity, setQuantity] = useState<number>(0);

  useEffect(() => {
    const items = selectedMenuItems.filter(
      (item) => item.menuPackageId !== menuPackage?.id
    );
    items.push({
      quantity: quantity,
      totalPrice: getPackageTotalRate(),
      menuPackageId: menuPackage?.id,
      menuPackageName: menuPackage?.name,
      unitPrice: menuPackage.price,
      featuredImage: menuPackage.featuredImage,
      vendorId: selectedVendor?.id,
      vendorName: selectedVendor?.name,
    });
    getItems(items);
  }, [quantity]);

  const getPackageTotalRate = (): number => {
    const applicableMenuRate = menuPackage.menuRates.find(
      (rate) => quantity >= rate.minimumLimit
    );

    if (applicableMenuRate) {
      return applicableMenuRate.price;
    }

    return menuPackage.price * quantity;
  };
  return (
    <div className="row mt-4 ml-4">
      <div className="col-3">
        <img
          className="order-image"
          src={`${process.env.REACT_APP_IMAGE_URL}/${menuPackage?.featuredImage}`}
          style={{
            width: "75px",
            height: "50px",
          }}
          alt="item"
        />
      </div>
      <div className="col-3">
        <div className="parent">
          <p className="block-ellipsis">{menuPackage?.name}</p>
        </div>
      </div>
      <div className="col-3">
        <TextField
          type="number"
          className="text-center"
          defaultValue={0}
          variant="standard"
          onChange={(e) => setQuantity(parseInt(e.target.value))}
        />
      </div>
      <div className="col-3">
        <p className="card-text text-strong text-right">
          {" "}
          R
          {!isNaN(getPackageTotalRate())
            ? getPackageTotalRate().toFixed(2)
            : "0.00"}
        </p>
      </div>
    </div>
  );
};

export default VendorMenuPackageItems;
