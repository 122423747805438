import React from "react";
import { MeetingRoomItem } from "../../../../models/meeting.room.model";
import { Image } from "../../../../models/product.model";
import MeetingRoomImageView from "../MeetingRoomImageView";

interface Props {
  images: Image[];
  roomItem: MeetingRoomItem;
}

const MeetingRoomItemImageView = ({ images, roomItem }: Props) => {
  return (
    <MeetingRoomImageView
      images={images}
      isMeetingRoomView={false}
      roomItem={roomItem}
    />
  );
};

export default MeetingRoomItemImageView;
