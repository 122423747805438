/* eslint-disable react-hooks/exhaustive-deps */
import { MenuItem, Select } from '@mui/material';
import React, { useEffect } from 'react';
import { Status } from '../../../../models/colab-subscribers.model';
import { UPDATE_SUBSCRIBER } from '../../../../graphql/colab-subscriber.graphql';
import useToast from '../../../../hooks/useToast';
import { useMutation } from '@apollo/client';

interface SubscribersViewProps {
    statusList: Status[] | [];
    selectedStatus: Status | null;
    selectedStatusId: string;
}

export default function StatusComponent({ statusList, selectedStatus, selectedStatusId }: SubscribersViewProps) {
    const { showToast, Notification } = useToast();

    const [updateSubscriber, { data, error }] = useMutation(UPDATE_SUBSCRIBER, {
        // refetchQueries: [{ query: GET_SUBSCRIBERS }, "getSubscribers"],
        refetchQueries: ['getSubscribers'],
    });

    useEffect(() => {
        if (error) {
            showToast('An error has occurred, please refresh the page', 'danger');
        }
    }, [error]);

    useEffect(() => {
        if (data) {
            showToast('Successfully updated status', 'success');
        }
    }, [data]);

    const handleStatusChange = (event: any, statusId: string): void => {
        const id = statusId;
        const status = event.target.value;

        updateSubscriber({ variables: { id, status } });
    };

    return (
        <>
            {Notification}
            <Select
                className='bare-dropdown'
                variant='standard'
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={selectedStatus?.value}
                label='Status'
                onChange={(event) => handleStatusChange(event, selectedStatusId)}>
                {statusList?.map((status: Status) => {
                    return (
                        <MenuItem key={status.value} value={status.value}>
                            {status.title}
                        </MenuItem>
                    );
                })}
            </Select>
        </>
    );
}
