import { gql } from "@apollo/client";

export const GET_TASK_LIST = gql`
  query GetTaskList {
    task(where: { deleted_at: { _is_null: true } }) {
      id
      title
      createdAt: created_at
      dueDate: due_date
      type {
        value
        title
      }
      status {
        value
        title
      }
    }
    taskTypes: task_type {
      value
      title
    }
    statusList: task_status {
      value
      title
    }
  }
`;

export const REMOVE_TASK = gql`
  mutation deletedTask($id: uuid!) {
    update_task_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
      id
    }
  }
`;

export const ADD_TASK = gql`
  mutation AddTask($task: task_insert_input!) {
    insert_task_one(
      object: $task
      on_conflict: {
        constraint: task_pkey
        update_columns: [due_date, status_enum, title, type_enum, description]
      }
    ) {
      id
    }
  }
`;

export const REMOVE_TASK_ASSIGNEE = gql`
  mutation deleteTaskAssignee($id: uuid!, $event: task_event_insert_input!) {
    assignee: delete_task_assignee_by_pk(id: $id) {
      id
    }
    event: insert_task_event_one(object: $event) {
      id
    }
  }
`;

export const GET_TASK_VIEW_DATA = gql`
  query GetTaskViewData {
    taskType: task_type {
      value
      title
    }
    user(
      where: {
        _and: {
          deleted_at: { _is_null: true }
          role: { level: { _eq: "portal" } }
        }
      }
    ) {
      id
      name
      surname
    }
  }
`;

export const GET_TASK_DETAIL_VIEW_DATA = gql`
  query GetTaskDetailViewData($id: uuid!) {
    task: task_by_pk(id: $id) {
      id
      title
      dueDate: due_date
      events {
        id
        title
        createdAt: created_at
        description
        user {
          id
          name
          surname
        }
        comment {
          id
          value
          createdAt: created_at
          user {
            id
            name
            surname
          }
        }
      }
      description
      type {
        value
        title
      }
      status {
        value
        title
      }
      assignees {
        id
        userId: user_id
        user {
          id
          name
          surname
        }
      }
    }
    taskType: task_type {
      value
      title
    }
    statusList: task_status {
      value
      title
    }
    user(
      where: {
        _and: {
          deleted_at: { _is_null: true }
          role: { level: { _eq: "portal" } }
        }
      }
    ) {
      id
      name
      surname
    }
  }
`;

export const ADD_TASK_EVENT = gql`
  mutation AddTaskEvent($event: task_event_insert_input!) {
    insert_task_event_one(object: $event) {
      id
    }
  }
`;

export const ADD_STATUS_UPDATE_EVENT = gql`
  mutation AddStatusUpdateTaskEvent(
    $id: uuid!
    $task: task_set_input!
    $event: task_event_insert_input!
  ) {
    task: update_task_by_pk(pk_columns: { id: $id }, _set: $task) {
      id
    }

    event: insert_task_event_one(object: $event) {
      id
    }
  }
`;

export const ADD_ASSIGNEE_TASK_EVENT = gql`
  mutation AddAssigneeTaskEvent(
    $assignee: task_assignee_insert_input!
    $event: task_event_insert_input!
  ) {
    task: insert_task_assignee_one(object: $assignee) {
      id
    }
    event: insert_task_event_one(object: $event) {
      id
    }
  }
`;
