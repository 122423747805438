/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Row } from "reactstrap";
import { Image } from "../../../models/product.model";
import { uploadImage } from "../../../hooks/upload-image.hook";
import useToast from "../../../hooks/useToast";
import { useMutation } from "@apollo/client";
import {
  ADD_LOCATION_IMAGE,
  GET_PORTAL_LOCATION,
} from "../../../graphql/locations.graphql";
import { useParams } from "react-router-dom";
// import LinearProgress from "@mui/material/LinearProgress";
import LocationImage from "./LocationImage";
import { CircularProgress } from "@mui/material";

interface ImageViewProps {
  images: Image[] | [];
}

const LocationImageView = ({ images }: ImageViewProps) => {
  const inputFileRef: any = useRef(null);
  // const [imageFile, setImageFile] = useState<any>();
  const { showToast, Notification } = useToast();
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [addImageMutation, { error, data: addedImage }] = useMutation(
    ADD_LOCATION_IMAGE,
    {
      refetchQueries: [{ query: GET_PORTAL_LOCATION }, "GetLocation"],
    }
  );
  const params: any = useParams();

  const changeImage = (e: any) => {
    // setImageFile(e.target.files[0]);
    addImage(e.target.files[0]);
  };

  const addImage = async (imageFile: any) => {
    if (!imageFile) {
      showToast("Please select an image first", "danger");
      return;
    }

    setIsAdding(true);
    const imageKey = await uploadImage(imageFile, "package_images");

    if (imageKey === "error") {
      showToast("An error has occurred while trying ", "danger");
      setIsAdding(false);
      return;
    }

    const image = {
      location_id: params.id,
      path: imageKey,
    };

    addImageMutation({
      variables: {
        image: image,
      },
    });
  };

  useEffect(() => {
    if (error) {
      showToast("An error has occurred while trying to an image", "danger");
      setIsAdding(false);
    }
  }, [error]);

  useEffect(() => {
    if (addedImage && !error) {
      showToast("Successfully added an image in locations", "success");
      setIsAdding(false);
    }
  }, [addedImage]);

  // if (isAdding) {
  //   return (
  //     <>
  //       {Notification}
  //       <LinearProgress />
  //     </>
  //   );
  // }

  if (images?.length === 0) {
    return (
      <>
        {Notification}
        <Card className="mt-4">
          <CardHeader>
            <button
              onClick={() => {
                inputFileRef.current.click();
              }}
              disabled={isAdding}
              className="btn btn-dark btn-sm"
            >
              {isAdding ? (
                <CircularProgress color="primary" size={16} />
              ) : (
                <span>Add</span>
              )}
            </button>
            <input
              onChange={(e) => changeImage(e)}
              ref={inputFileRef}
              type="file"
              accept="image/*"
              style={{ display: "none" }}
            />
          </CardHeader>
          <CardBody>
            <h1>This location has no images</h1>
          </CardBody>
        </Card>
      </>
    );
  }

  return (
    <>
      {Notification}
      <Card className="mt-4">
        <CardHeader>
          <button
            onClick={() => {
              inputFileRef.current.click();
            }}
            disabled={isAdding}
            className="btn btn-dark btn-sm"
          >
            {isAdding ? (
              <CircularProgress color="primary" size={16} />
            ) : (
              <span>Add</span>
            )}
          </button>
          <input
            onChange={(e) => changeImage(e)}
            ref={inputFileRef}
            type="file"
            accept="image/*"
            style={{ display: "none" }}
          />
        </CardHeader>
        <CardBody>
          <div className="container">
            <Row>
              {images?.map((image) => {
                return (
                  <div key={image.id} className="pr-4 mt-4">
                    <LocationImage
                      id={image.id}
                      sourceImage={`${process.env.REACT_APP_IMAGE_URL}/${image?.path}`}
                    />
                  </div>
                );
              })}
            </Row>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default LocationImageView;
