/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
import BasicMenu from "../../../../components/Menu";
import { Order } from "../../../../models/order.model";
import { getPermission } from "../../../../utils/user.utils";
import moment from "moment";
import _ from "lodash";
import useToast from "../../../../hooks/useToast";
import { downloadPdf } from "../../../../utils/file.utils";
import SweetAlert from "react-bootstrap-sweetalert";
import { User } from "../../../../models/user.model";
import { useSelector } from "react-redux";
import { REMOVE_ORDER } from "../../../../graphql/orders.graphql";
import { useMutation } from "@apollo/client";

interface Props {
  orders: Order[];
}

const OrderView = ({ orders }: Props) => {
  const [sortDirection, setSortDirection] = useState<string>("desc");
  const [sortFiled, setSortField] = useState<string>("date");
  const [orderFilter, setOrderFilter] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [rowPerPage, setRowsPerPage] = useState<number>(10);
  const { showToast, Notification } = useToast();
  const [alert, setAlert] = useState<any>(null);
  const user: User | null = useSelector((state: any) => state.user.userInfo);
  const navigate = useNavigate();
  const [removeOrder, { error: removeError, data: removedOrder }] = useMutation(
    REMOVE_ORDER,
    {
      refetchQueries: ["GetClient"],
    }
  );

  const displayAlert = (props: any) => {
    setAlert(
      <SweetAlert
        {...props}
        showCancel
        confirmBtnText="Continue"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => {
          removeOrder({ variables: { orderId: props.order.id } });
          setAlert(null);
        }}
        onCancel={() => {
          setAlert(null);
        }}
        focusCancelBtn
      >
        This order will be removed from the system.
      </SweetAlert>
    );
  };

  const downloadInvoice = (path: string, orderId: string) => {
    const url = `${process.env.REACT_APP_IMAGE_URL}/${path}`;
    downloadPdf(url, `${orderId}.pdf`);
  };

  const nextPage = () => {
    setPage(page + 1);
  };
  const previousPage = () => {
    setPage(page - 1);
  };

  useEffect(() => {
    if (removeError) {
      showToast("An error occurred while trying to remove the order", "danger");
    }

    if (removedOrder) {
      showToast("Successfully removed the order", "success");
    }
  }, [removeError, removedOrder]);

  const sortBy: any = (orders: Order[], field: string, direction: string) => {
    const orderedOrders = new Map([
      [
        "date",
        _.orderBy(orders, (order) => moment(order.createdAt), [
          direction === "asc" ? "asc" : "desc",
        ]),
      ],
      [
        "reference",
        _.orderBy(orders, (order) => order.reference, [
          direction === "asc" ? "asc" : "desc",
        ]),
      ],
      [
        "status",
        _.orderBy(orders, (order) => order.status.title, [
          direction === "asc" ? "asc" : "desc",
        ]),
      ],
      [
        "paymentStatus",
        _.orderBy(orders, (order) => order.paymentStatus.title, [
          direction === "asc" ? "asc" : "desc",
        ]),
      ],
    ]);
    return orderedOrders.get(field);
  };

  const getRowValue = (value: string) => {
    setRowsPerPage(parseInt(value));
    if (parseInt(value) !== rowPerPage) setPage(0);
  };

  let filteredOrders = orders.filter((order: Order) => {
    const isOrderFiltered = order.reference
      .toLowerCase()
      .includes(orderFilter.toLowerCase());
    return isOrderFiltered;
  });

  filteredOrders = sortBy(filteredOrders, sortFiled, sortDirection);

  return (
    <>
      {Notification}
      {alert}

      <Row className="mt-4">
        <div className="col">
          <Card>
            <CardHeader className="border-0">
              <Row>
                <Col xs="6">
                  <h3 className="mb-0">Orders</h3>
                </Col>
              </Row>
              <Row className="mt-4 border-0">
                <Col>
                  <Input
                    className="form-control w-25"
                    type="text"
                    placeholder="Search keyword..."
                    onChange={(e) => {
                      setOrderFilter(e.target.value);
                      setPage(0);
                    }}
                  />
                </Col>
              </Row>
            </CardHeader>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th
                    onClick={() => {
                      setSortField("date");
                      setSortDirection(
                        sortDirection === "asc" ? "desc" : "asc"
                      );
                    }}
                    className="sort"
                    data-sort="date"
                    scope="col"
                  >
                    Date
                  </th>
                  <th
                    onClick={() => {
                      setSortField("reference");
                      setSortDirection(
                        sortDirection === "asc" ? "desc" : "asc"
                      );
                    }}
                    className="sort"
                    data-sort="reference"
                    scope="col"
                  >
                    Reference
                  </th>
                  <th
                    onClick={() => {
                      setSortField("status");
                      setSortDirection(
                        sortDirection === "asc" ? "desc" : "asc"
                      );
                    }}
                    className="sort"
                    data-sort="status"
                    scope="col"
                  >
                    Status
                  </th>
                  <th
                    onClick={() => {
                      setSortField("paymentStatus");
                      setSortDirection(
                        sortDirection === "asc" ? "desc" : "asc"
                      );
                    }}
                    className="sort"
                    data-sort="paymentStatus"
                    scope="col"
                  >
                    Payment Status
                  </th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody className="list">
                {filteredOrders
                  .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                  .map((order: Order) => {
                    return (
                      <tr
                        key={order.id}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate(`/admin/orders/details/${order.id}`);
                        }}
                      >
                        <td>{moment(order.createdAt).format("yyyy-MM-DD")}</td>
                        <td>{order.reference}</td>
                        <td>{order.status.title}</td>
                        <td>{order.paymentStatus.title}</td>
                        <td className="text-right">
                          {getPermission(user, "orders", "read") && (
                            <Link
                              className="btn btn-info btn-sm"
                              to={`/admin/orders/details/${order.id}`}
                            >
                              View
                            </Link>
                          )}
                          {getPermission(user, "orders", "read") && (
                            <Button
                              className="btn btn-outlined-info btn-sm"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (order.invoicePath) {
                                  downloadInvoice(order.invoicePath, order.id);
                                }
                              }}
                              disabled={!order.invoicePath}
                            >
                              Invoice
                            </Button>
                          )}
                          {getPermission(user, "orders", "delete") && (
                            <Button
                              className="btn btn-danger btn-icon-only rounded-circle btn-sm"
                              onClick={(e) => {
                                e.stopPropagation();
                                displayAlert({ warning: true, order });
                              }}
                            >
                              <i className="fa-solid fa-trash-can"></i>
                            </Button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            <CardFooter className="py-4">
              <Row>
                <div className="col-4 d-flex">
                  <span style={{ fontSize: "12px" }}>Rows per page:</span>
                  <BasicMenu
                    getValue={getRowValue}
                    options={["10", "20", "100"]}
                    value={rowPerPage.toString()}
                  />
                </div>
                <div className="col-4 d-flex">
                  <span className="min-text">Page:</span>
                  <span className="min-text pl-2">
                    {page + 1} of{" "}
                    {Math.ceil(filteredOrders.length / rowPerPage)}
                  </span>
                </div>
                <div className="col-4 d-flex">
                  <Pagination>
                    <PaginationItem className={page <= 0 ? "disabled" : ""}>
                      <PaginationLink onClick={(e) => previousPage()}>
                        <i className="fas fa-angle-left" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem
                      className={
                        page >=
                        Math.ceil(filteredOrders.length / rowPerPage) - 1
                          ? "disabled"
                          : ""
                      }
                    >
                      <PaginationLink onClick={(e) => nextPage()}>
                        <i className="fas fa-angle-right" />
                        <span className="sr-only">Previous</span>
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </div>
              </Row>
            </CardFooter>
          </Card>
        </div>
      </Row>
    </>
  );
};

export default OrderView;
