/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from '@apollo/client';
import { LinearProgress } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table,
    UncontrolledTooltip,
} from 'reactstrap';
import BasicMenu from '../../../components/Menu';
import useToast from '../../../hooks/useToast';
import { User } from '../../../models/user.model';
import { getPermission } from '../../../utils/user.utils';
import _ from 'lodash';
import { GetLeads, REMOVE_LEAD } from '../../../graphql/leads.graphql';
import { Lead } from '../../../models/lead.model';
import { useLocalStorage } from '../../../hooks/local-storage.hook';

interface LeadRouteData {
    keyWord: string;
    page: number;
    pageSize: number;
}

export const LeadsList = () => {
    const { showToast, Notification } = useToast();
    const [alert, setAlert] = useState<any>(null);
    const [getLocalStorage, setLocalStorage] = useLocalStorage('lead');
    const { loading, error, data } = useQuery(GetLeads);
    const [page, setPage] = useState<number>(0);
    const [leadsFilter, setLeadsFilter] = useState<string>('');
    const [rowPerPage, setRowsPerPage] = useState<number>(10);
    const [sortDirection, setSortDirection] = useState<string>('desc');
    const [sortField, setSortField] = useState<string>('date');
    const navigate = useNavigate();
    const [removeLead, { loading: isRemoving, error: removeError, called }] = useMutation(REMOVE_LEAD, {
        refetchQueries: [{ query: GetLeads }, 'GetLeads'],
    });
    const user: User | null = useSelector((state: any) => state.user.userInfo);

    useEffect(() => {
        if (error || removeError) {
            showToast('An error has occurred, please try again', 'danger');
        }
    }, [error, removeError]);

    useEffect(() => {
        if (!isRemoving && called && !removeError) {
            showToast('Successfully removed  lead', 'success');
        }
    }, [isRemoving, called]);

    const sortBy: any = (leads: Lead[], field: string, direction: string) => {
        const orderedLeads = new Map([
            ['company', _.orderBy(leads, (lead) => lead.companyName, [direction === 'asc' ? 'asc' : 'desc'])],
            ['industry', _.orderBy(leads, (lead) => lead.industry, [direction === 'asc' ? 'asc' : 'desc'])],
            ['date', _.orderBy(leads, (lead) => moment(lead.createdAt), [direction === 'asc' ? 'asc' : 'desc'])],

            ['status', _.orderBy(leads, (lead) => lead.status.title, [direction === 'asc' ? 'asc' : 'desc'])],

            ['type', _.orderBy(leads, (lead) => lead.category || 'Not Set', [direction === 'asc' ? 'asc' : 'desc'])],
        ]);
        return orderedLeads.get(field);
    };

    const leads: Lead[] = data?.leads || [];

    let filteredLeads = leads.filter((lead: Lead) => {
        const isLeadsFiltered =
            lead.companyName.toLowerCase().includes(leadsFilter.toLowerCase()) ||
            lead.contactName.toLowerCase().includes(leadsFilter.toLowerCase()) ||
            lead.contactEmail.toLowerCase().includes(leadsFilter.toLowerCase());
        return isLeadsFiltered;
    });

    filteredLeads = sortBy(filteredLeads, sortField, sortDirection);

    const getCategory = (lead: Lead): string => {
        if (lead) {
            switch (lead.category) {
                case 'contact_us':
                    return 'Contact Us';
                case 'product_questionnaire':
                    return 'Long Term Office';
                case 'internal':
                    return 'Internal';

                default:
                    return 'Not Set';
            }
        }
        return '';
    };

    const displayAlert = (props: any) => {
        setAlert(
            <SweetAlert
                {...props}
                showCancel
                confirmBtnText='Continue'
                confirmBtnBsStyle='danger'
                title='Are you sure?'
                onConfirm={() => {
                    removeLead({
                        variables: { leadId: props.lead.id },
                    });
                    setAlert(null);
                }}
                onCancel={() => {
                    setAlert(null);
                }}
                focusCancelBtn>
                This lead will be removed from the system.
            </SweetAlert>,
        );
    };

    useEffect(() => {
        const routeData: LeadRouteData = getLocalStorage();

        if (routeData) {
            setPage(routeData?.page || 0);
            setRowsPerPage(routeData?.pageSize || 10);
            setLeadsFilter(routeData?.keyWord || '');
        }
    }, []);

    const getRowValue = (value: string) => {
        setRowsPerPage(parseInt(value));
        const routeData: LeadRouteData = getLocalStorage() || {};
        routeData.pageSize = parseInt(value);
        if (parseInt(value) !== rowPerPage) {
            setPage(0);
            routeData.page = 0;
        }
        setLocalStorage(routeData);
    };

    const nextPage = () => {
        setPage(page + 1);
        const routeData: LeadRouteData = getLocalStorage() || {};
        routeData.page = page + 1;
        setLocalStorage(routeData);
    };
    const previousPage = () => {
        setPage(page - 1);
        const routeData: LeadRouteData = getLocalStorage() || {};
        routeData.page = page - 1;
        setLocalStorage(routeData);
    };

    if (loading) {
        return <LinearProgress />;
    }

    return (
        <>
            {Notification}
            {alert}
            <Container className='mt-4' fluid>
                <Row>
                    <Col>
                        <h1>Leads</h1>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <div className='col'>
                        <Card>
                            <CardHeader className='border-0'>
                                <Row>
                                    <Col xs='6'>
                                        <h3 className='mb-0'>Leads</h3>
                                    </Col>
                                    {getPermission(user, 'leads', 'create') && (
                                        <Col className='text-right' xs='6'>
                                            <Link
                                                to={'/admin/leads/new'}
                                                className='btn btn-round btn-dark btn-sm'
                                                color='default'
                                                id='tooltip969372949'>
                                                <span className='btn-inner--text'>Add Lead</span>
                                            </Link>
                                            <UncontrolledTooltip delay={0} target='tooltip969372949'>
                                                Add Lead
                                            </UncontrolledTooltip>
                                        </Col>
                                    )}
                                </Row>
                                <Row className='mt-4 border-0'>
                                    <Col>
                                        <Input
                                            className='form-control w-25'
                                            type='text'
                                            value={leadsFilter}
                                            placeholder='Search keyword...'
                                            onChange={(e) => {
                                                setLeadsFilter(e.target.value);
                                                setPage(0);

                                                const routeData: LeadRouteData = getLocalStorage() || {};
                                                routeData.keyWord = e.target.value;
                                                routeData.page = 0;
                                                setLocalStorage(routeData);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </CardHeader>
                            <Table className='align-items-center table-flush' responsive>
                                <thead className='thead-light'>
                                    <tr>
                                        <th
                                            onClick={() => {
                                                setSortField('company');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='company'
                                            scope='col'>
                                            Company
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('industry');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='industry'
                                            scope='col'>
                                            Industry
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('date');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='date'
                                            scope='col'>
                                            Date
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('type');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='type'
                                            scope='col'>
                                            Type
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('status');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='status'
                                            scope='col'>
                                            Status
                                        </th>

                                        <th scope='col' />
                                    </tr>
                                </thead>
                                <tbody className='list'>
                                    {filteredLeads
                                        .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                                        .map((lead: Lead) => {
                                            return (
                                                <tr
                                                    key={lead.id}
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => navigate(`/admin/leads/details/${lead.id}`)}>
                                                    <td>{lead.companyName}</td>
                                                    <td>{lead.industry || 'N/A'}</td>
                                                    <td>{moment(lead.createdAt).format('yyyy-MM-DD')}</td>

                                                    <td>{getCategory(lead)}</td>
                                                    <td>{lead.status.title}</td>

                                                    <td className='text-right'>
                                                        {getPermission(user, 'orders', 'read') && (
                                                            <Link
                                                                className='btn btn-info btn-sm'
                                                                to={`/admin/leads/details/${lead.id}`}>
                                                                View
                                                            </Link>
                                                        )}

                                                        {getPermission(user, 'orders', 'delete') && (
                                                            <Button
                                                                className='btn btn-danger btn-icon-only rounded-circle btn-sm'
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    displayAlert({ warning: true, lead });
                                                                }}>
                                                                <i className='fa-solid fa-trash-can'></i>
                                                            </Button>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </Table>
                            <CardFooter className='py-4'>
                                <Row>
                                    <div className='col-4 d-flex'>
                                        <span style={{ fontSize: '12px' }}>Rows per page:</span>
                                        <BasicMenu
                                            getValue={getRowValue}
                                            options={['10', '20', '100']}
                                            value={rowPerPage.toString()}
                                        />
                                    </div>
                                    <div className='col-4 d-flex'>
                                        <span className='min-text'>Page:</span>
                                        <span className='min-text pl-2'>
                                            {page + 1} of {Math.ceil(filteredLeads.length / rowPerPage)}
                                        </span>
                                    </div>
                                    <div className='col-4 d-flex'>
                                        <Pagination>
                                            <PaginationItem className={page <= 0 ? 'disabled' : ''}>
                                                <PaginationLink onClick={(e) => previousPage()}>
                                                    <i className='fas fa-angle-left' />
                                                    <span className='sr-only'>Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem
                                                className={
                                                    page >= Math.ceil(filteredLeads.length / rowPerPage) - 1
                                                        ? 'disabled'
                                                        : ''
                                                }>
                                                <PaginationLink onClick={(e) => nextPage()}>
                                                    <i className='fas fa-angle-right' />
                                                    <span className='sr-only'>Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                    </div>
                                </Row>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};
