/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import { useQuery, useMutation } from "@apollo/client";
import {
  GET_PRODUCT_PACKAGES,
  REMOVE_PRODUCT_PACKAGE,
} from "../../../graphql/packages.graphql";
import useToast from "../../../hooks/useToast";
import { LinearProgress } from "@mui/material";
import { ProductPackage } from "../../../models/packages.models";
import BasicMenu from "../../../components/Menu";
import SweetAlert from "react-bootstrap-sweetalert";
import { User } from "../../../models/user.model";
import { useSelector } from "react-redux";
import { getPermission } from "../../../utils/user.utils";
import _ from "lodash";
import { useLocalStorage } from "../../../hooks/local-storage.hook";

interface PackageRouteData {
  keyWord: string;
  page: number;
  pageSize: number;
}

const PackageView = () => {
  const { loading, error, data } = useQuery(GET_PRODUCT_PACKAGES);
  const { showToast, Notification } = useToast();
  const [getLocalStorage, setLocalStorage] = useLocalStorage("package");
  const [rowPerPage, setRowsPerPage] = useState<number>(10);
  const [searchKey, setSearchKey] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [alert, setAlert] = useState<any>(null);
  const [sortDirection, setSortDirection] = useState<string>("asc");
  const [sortFiled, setSortField] = useState<string>("name");
  const user: User | null = useSelector((state: any) => state.user.userInfo);
  const [
    removePackageMutation,
    { error: errorRemovingPackage, data: removedPackage },
  ] = useMutation(REMOVE_PRODUCT_PACKAGE, {
    // refetchQueries: [{ query: GET_PRODUCT_PACKAGES }, "GetProductPackages"],
    refetchQueries: ["GetProductPackages"],
  });

  const navigate = useNavigate();

  useEffect(() => {
    const routeData: PackageRouteData = getLocalStorage();

    if (routeData) {
      setPage(routeData?.page || 0);
      setRowsPerPage(routeData?.pageSize || 10);
      setSearchKey(routeData?.keyWord || "");
    }
  }, []);

  useEffect(() => {
    if (error) {
      showToast("An error has occurred, please try again", "danger");
    }

    if (errorRemovingPackage) {
      showToast(
        "An error has occurred while trying to remove this package",
        "danger"
      );
    }
  }, [error, errorRemovingPackage]);

  useEffect(() => {
    if (removedPackage) {
      showToast("Successfully removed package", "success");
    }
  }, [removedPackage]);

  const getRowValue = (value: string) => {
    setRowsPerPage(parseInt(value));
    const routeData: PackageRouteData = getLocalStorage() || {};
    routeData.pageSize = parseInt(value);
    setLocalStorage(routeData);
  };

  const removePackage = async (_id: string) => {
    removePackageMutation({
      variables: {
        id: _id,
      },
    });
  };

  const displayAlert = (props: any) => {
    setAlert(
      <SweetAlert
        {...props}
        showCancel
        confirmBtnText="Continue"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => {
          removePackage(props._package.id);
          setAlert(null);
        }}
        onCancel={() => {
          setAlert(null);
        }}
        focusCancelBtn
      >
        This package will be removed.
      </SweetAlert>
    );
  };

  const sortBy: any = (
    productPackages: ProductPackage[],
    field: string,
    direction: string
  ) => {
    const orderedBookings = new Map([
      [
        "name",
        _.orderBy(productPackages, (_package) => _package.name, [
          direction === "asc" ? "asc" : "desc",
        ]),
      ],
      [
        "price",
        _.orderBy(productPackages, (_package) => parseInt(_package.price), [
          direction === "asc" ? "asc" : "desc",
        ]),
      ],
      [
        "type",
        _.orderBy(productPackages, (_package) => _package.priceType.title, [
          direction === "asc" ? "asc" : "desc",
        ]),
      ],
    ]);
    return orderedBookings.get(field);
  };

  const nextPage = () => {
    setPage(page + 1);
    const routeData: PackageRouteData = getLocalStorage() || {};
    routeData.page = page + 1;
    setLocalStorage(routeData);
  };

  const previousPage = () => {
    setPage(page - 1);
    const routeData: PackageRouteData = getLocalStorage() || {};
    routeData.page = page - 1;
    setLocalStorage(routeData);
  };

  if (loading) {
    return <LinearProgress />;
  }

  const productPackages: ProductPackage[] = data?.packages;
  let filteredPackages = productPackages.filter((_package: ProductPackage) => {
    if (searchKey === "") {
      return _package;
    }
    return (
      _package.name.toLowerCase().includes(searchKey.toLowerCase()) ||
      _package.priceType.title.toLowerCase().includes(searchKey.toLowerCase())
    );
  });

  filteredPackages = sortBy(filteredPackages, sortFiled, sortDirection);

  return (
    <>
      {Notification}
      {alert}
      <Container className="mt-4" fluid>
        <Row>
          <Col>
            <h1>Packages</h1>
          </Col>
        </Row>
        <Row className="mt-4">
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Product Packages</h3>
                  </Col>
                  {getPermission(user, "packages", "create") && (
                    <Col className="text-right" xs="6">
                      <Link
                        to={"/admin/packages/new"}
                        className="btn btn-round btn-dark btn-sm"
                        color="default"
                        id="tooltip969372949"
                      >
                        <span className="btn-inner--text">Add package</span>
                      </Link>
                      <UncontrolledTooltip delay={0} target="tooltip969372949">
                        Add package
                      </UncontrolledTooltip>
                    </Col>
                  )}
                </Row>
                <Row className="mt-4">
                  <Col>
                    <Input
                      value={searchKey}
                      onChange={(e) => {
                        setSearchKey(e.target.value);
                        const routeData: PackageRouteData =
                          getLocalStorage() || {};
                        routeData.keyWord = e.target.value;
                        setLocalStorage(routeData);
                      }}
                      placeholder="Search keyword..."
                      className="w-25"
                    />
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th
                      onClick={() => {
                        setSortField("name");
                        setSortDirection(
                          sortDirection === "asc" ? "desc" : "asc"
                        );
                      }}
                      className="sort"
                      data-sort="name"
                      scope="col"
                    >
                      Name
                    </th>
                    <th
                      onClick={() => {
                        setSortField("price");
                        setSortDirection(
                          sortDirection === "asc" ? "desc" : "asc"
                        );
                      }}
                      className="sort"
                      data-sort="price"
                      scope="col"
                    >
                      Price
                    </th>
                    <th
                      onClick={() => {
                        setSortField("type");
                        setSortDirection(
                          sortDirection === "asc" ? "desc" : "asc"
                        );
                      }}
                      className="sort"
                      data-sort="type"
                      scope="col"
                    >
                      Type
                    </th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody className="list">
                  {filteredPackages
                    .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                    .map((_package: ProductPackage) => {
                      return (
                        <tr
                          key={_package.id}
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            navigate(`/admin/packages/manage/${_package.id}`)
                          }
                        >
                          <td>{_package.name}</td>
                          {_package.price !== null ? (
                            <td>R {_package.price}</td>
                          ) : (
                            <td>No price</td>
                          )}
                          <td>{_package.priceType.title}</td>
                          <td className="text-right">
                            {getPermission(user, "packages", "update") && (
                              <Link
                                to={`/admin/packages/manage/${_package.id}`}
                                className="btn btn-info btn-icon-only rounded-circle btn-sm"
                              >
                                <i className="fa-solid fa-pencil"></i>
                              </Link>
                            )}
                            {getPermission(user, "packages", "delete") && (
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  displayAlert({ warning: true, _package });
                                }}
                                className="btn btn-danger btn-icon-only rounded-circle btn-sm"
                              >
                                <i className="fa-solid fa-trash-can"></i>
                              </button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <Row>
                  <div className="col-4 d-flex">
                    <span className="min-text">Rows per page:</span>
                    <BasicMenu
                      getValue={getRowValue}
                      options={["10", "20", "100"]}
                      value={rowPerPage.toString()}
                    />
                  </div>
                  <div className="col-4 d-flex">
                    <span className="min-text">Page:</span>
                    <span className="min-text pl-2">
                      {page + 1} of{" "}
                      {Math.ceil(filteredPackages.length / rowPerPage)}
                    </span>
                  </div>
                  <div className="col-4 d-flex">
                    <Pagination>
                      <PaginationItem className={page <= 0 ? "disabled" : ""}>
                        <PaginationLink onClick={(e) => previousPage()}>
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem
                        className={
                          page >=
                          Math.ceil(filteredPackages.length / rowPerPage) - 1
                            ? "disabled"
                            : ""
                        }
                      >
                        <PaginationLink onClick={(e) => nextPage()}>
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </div>
                </Row>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default PackageView;
