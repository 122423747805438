/* eslint-disable react-hooks/exhaustive-deps */
import { Badge, MenuItem, Select } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Row, Spinner } from 'reactstrap';
import useToast from '../../../hooks/useToast';
import { useForm } from 'react-hook-form';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { useMutation, useQuery } from '@apollo/client';
import {
    ADD_NEWSLETTER_EMAIL,
    GET_EMAIL_STATUS,
    GET_NEWSLETTER_EMAILS,
} from '../../../graphql/newsletter-email.graphql';
import { EmailTemplate, MailList, Status } from '../../../models/newsletter-emil.model';
import { useNavigate } from 'react-router-dom';
import { SEND_MAIL_LIST_EMAILS } from '../../../graphql/mail-list.graphql';
// import { useDropzone } from 'react-dropzone';
import { v4 } from 'uuid';
import { Storage } from 'aws-amplify';

type FormData = {
    subject: string;
    status: string;
    template: string;
    mailList: string;
};

export default function EmailAddForm() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const fileInputRef = useRef<any>();
    const { showToast, Notification } = useToast();
    const [selectedFiles, setFiles] = useState<File[]>([]);
    const [sendEmails, { error, data }] = useMutation(SEND_MAIL_LIST_EMAILS);
    const { data: statusFound, error: errorStatus } = useQuery(GET_EMAIL_STATUS);
    const [addNewsletterEmail, { data: dataEmail, error: errorEmail, loading: loadingEmail }] = useMutation(
        ADD_NEWSLETTER_EMAIL,
        {
            refetchQueries: [{ query: GET_NEWSLETTER_EMAILS }, 'getNewsletterEmails'],
        },
    );

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<FormData>();

    useEffect(() => {
        if (errorStatus) {
            showToast('An error has occurred, please refresh the page', 'danger');
        }

        if (errorEmail) {
            showToast('An error has occurred while trying to add email information, please try again', 'danger');
        }
    }, [errorStatus, errorEmail]);

    useEffect(() => {
        if (dataEmail && !loadingEmail) {
            sendEmails({
                variables: {
                    id: dataEmail?.newsletter?.id,
                },
            });
        }
    }, [dataEmail]);

    useEffect(() => {
        if (data) {
            showToast('Emails were sent successfully', 'success');
            reset();
            setIsLoading(false);
        }
    }, [data]);

    useEffect(() => {
        if (error) {
            console.log('An error has occurred while trying to send the email', 'danger');
            setIsLoading(false);
        }
    }, [error]);

    const redirectToEmails = (e: any) => {
        e.preventDefault();
        navigate(-1);
    };

    const handleFileAttach = (e: any) => {
        setFiles([...selectedFiles, ...e.target.files]);
    };

    const openFileExplorer = () => {
        if (fileInputRef.current) fileInputRef?.current?.click();
    };

    const onFileRemove = (file: File) => {
        const newFiles = [...selectedFiles.filter((f) => selectedFiles.indexOf(f) !== selectedFiles.indexOf(file))];
        setFiles([...newFiles]);
    };

    const onSubmit = async (data: FormData) => {
        setIsLoading(true);

        let keys: string[] = [];

        if (selectedFiles?.length > 0) {
            try {
                const uploadPromises = Array.from(selectedFiles).map(async (file) => {
                    const fileName: string = `attachments/${v4()}_${file.name}`;
                    await Storage.put(fileName, file, {
                        level: 'public',
                    });
                    return fileName;
                });

                keys = await Promise.all(uploadPromises);
            } catch (error) {
                console.log('Error uploading files: ', error);
                showToast('An error has occurred while trying to upload the selected files', 'danger');
                setIsLoading(false);
                return;
            }
        }

        const emailData = {
            subject: data.subject,
            status_enum: 'active',
            template_id: data.template,
            mail_list_id: data.mailList,
            attachments: {
                data: keys?.map((key) => {
                    return {
                        path: key,
                    };
                }),
            },
        };
        await addNewsletterEmail({ variables: { emailValues: emailData } });
    };
    const statusSelect: Status[] = statusFound?.status;
    const templates: EmailTemplate[] = statusFound?.templates;
    const mailLists: MailList[] = statusFound?.mailLists;
    return (
        <>
            {Notification}
            <Container className='mt-4' fluid>
                <Row>
                    <Col md='12' lg='6'>
                        <Card>
                            <CardHeader>
                                <Row noGutters={true}>
                                    <button
                                        className='btn btn-outline-primary btn-sm mr-4'
                                        onClick={(e) => redirectToEmails(e)}>
                                        <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                                        <span className='btn-inner-text'>Back</span>
                                    </button>
                                    <h3 className='m-0'>Send New Email</h3>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-12'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='subject'>
                                                    Subject
                                                </label>
                                                <input
                                                    className='form-control'
                                                    {...register('subject', { required: true })}
                                                    id='subject'
                                                    placeholder='Enter post subject...'
                                                    type='text'
                                                />
                                                {errors.subject && (
                                                    <span className='invalid'>*This field is required</span>
                                                )}
                                            </FormGroup>
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='name'>
                                                    Template
                                                </label>
                                                <FormGroup>
                                                    <Select
                                                        id='status'
                                                        sx={{ borderRadius: '12px', padding: '0' }}
                                                        className='form-control'
                                                        defaultValue={' '}
                                                        inputProps={{
                                                            ...register('template', {
                                                                required: true,
                                                            }),
                                                        }}>
                                                        <MenuItem disabled value=' '>
                                                            Select a template...
                                                        </MenuItem>
                                                        {templates?.map((template: any) => {
                                                            return (
                                                                <MenuItem key={template?.id} value={template?.id}>
                                                                    {template?.name}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </FormGroup>
                                                {errors.template && (
                                                    <span className='invalid'>*This field is required</span>
                                                )}
                                            </FormGroup>
                                        </div>

                                        <div className='col-lg-6 col-md-12'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='name'>
                                                    Mail List
                                                </label>
                                                <FormGroup>
                                                    <Select
                                                        id='status'
                                                        sx={{ borderRadius: '12px', padding: '0' }}
                                                        className='form-control'
                                                        defaultValue={' '}
                                                        inputProps={{
                                                            ...register('mailList', {
                                                                required: true,
                                                            }),
                                                        }}>
                                                        <MenuItem disabled value=' '>
                                                            Select a mail list...
                                                        </MenuItem>
                                                        {mailLists?.map((mailList: any) => {
                                                            return (
                                                                <MenuItem key={mailList?.id} value={mailList?.id}>
                                                                    {mailList?.name}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </FormGroup>
                                                {errors.mailList && (
                                                    <span className='invalid'>*This field is required</span>
                                                )}
                                            </FormGroup>
                                        </div>
                                        <div className='col-lg-6 col-md-12'>
                                            <FormGroup>
                                                <span
                                                    className=' mt-4 btn btn-round btn-dark btn-sm'
                                                    color='default'
                                                    onClick={openFileExplorer}
                                                    id='tooltip969372949'>
                                                    <span className='btn-inner--text'> Attach file</span>
                                                </span>
                                                <input
                                                    onChange={handleFileAttach}
                                                    style={{ display: 'none' }}
                                                    multiple
                                                    accept='.pdf, .docx, .xlsx'
                                                    type='file'
                                                    ref={fileInputRef}
                                                />
                                            </FormGroup>
                                            {selectedFiles?.map((file) => {
                                                return (
                                                    <>
                                                        <Badge
                                                            style={{ cursor: 'pointer' }}
                                                            id={file.name}
                                                            badgeContent={'X'}
                                                            onClick={() => onFileRemove(file)}
                                                            key={file.name}
                                                            color='primary'>
                                                            <InsertDriveFileOutlinedIcon fontSize={'large'} />
                                                        </Badge>
                                                        <p>{file.name.substring(0, 5)}</p>
                                                    </>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <div className='d-flex h-100'>
                                        <div className='align-self-end ml-auto'>
                                            <Button
                                                type='submit'
                                                className='btn btn-dark mt-4 btn-block'
                                                disabled={isLoading}>
                                                {!isLoading ? (
                                                    <span>Send</span>
                                                ) : (
                                                    <span>
                                                        <Spinner />
                                                    </span>
                                                )}
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
