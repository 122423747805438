import React, { useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { LinearProgress, MenuItem, Select } from '@mui/material';

import { useForm } from 'react-hook-form';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Row } from 'reactstrap';
import {
    ADD_SPACE_LOCATION,
    GET_LOCATION_SPACE_STATUS_LIST,
    GET_PORTAL_LOCATION,
} from '../../../../graphql/locations.graphql';
import { LocationSpace, LocationSpaceStatus } from '../../../../models/location.model';
import Spinner from '../../../../components/Spinner/Spinner';
import useToast from '../../../../hooks/useToast';
import { useParams } from 'react-router-dom';

type FormData = {
    name: string;
    status: string;
    floor: string;
};

interface LocationRoomAddFormProps {
    goBackToListView: () => void;
    floors: LocationSpace[];
}

export default function LocationRoomAddForm({ goBackToListView, floors }: LocationRoomAddFormProps) {
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm<FormData>();
    const { showToast, Notification } = useToast();

    const { loading, data } = useQuery(GET_LOCATION_SPACE_STATUS_LIST);
    const params: any = useParams();

    const [addSpaceLocation, { loading: addingSpace, data: addedSpace, error }] = useMutation(ADD_SPACE_LOCATION, {
        refetchQueries: [GET_PORTAL_LOCATION],
    });

    useEffect(() => {
        if (error) {
            showToast('An error has occurred while trying to add this floor', 'danger');
            console.log('Error', error);
        }
    }, [error]);

    const onSubmit = (data: FormData): void => {
        if (data.status === 'empty') {
            setError('status', {
                message: '*This field is required',
            });
            return;
        }

        if (data.floor === 'empty') {
            setError('floor', {
                message: '*This field is required',
            });
            return;
        }

        const space = {
            name: data.name,
            status_enum: data.status,
            type_enum: 'room',
            location_id: params?.id,
            parent_id: data.floor,
        };

        addSpaceLocation({
            variables: {
                space: space,
            },
        });
    };
    useEffect(() => {
        if (addedSpace) {
            showToast('Successfully added floor', 'success');
        }
    }, [addedSpace]);

    if (loading) {
        return <LinearProgress />;
    }

    const statusList: LocationSpaceStatus[] = data?.status;
    return (
        <>
            {Notification}
            <Row>
                <Col md='12' lg='6'>
                    <Card className='mt-4'>
                        <CardHeader>
                            <Row noGutters={true}>
                                <button className='btn btn-outline-primary btn-sm mr-4' onClick={goBackToListView}>
                                    <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                                    <span className='btn-inner-text'>Back</span>
                                </button>

                                <h3 className='m-0'>Add Room</h3>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='name'>
                                        Name
                                    </label>
                                    <input
                                        className='form-control'
                                        {...register('name', { required: true })}
                                        id='name'
                                        placeholder='Enter room name...'
                                        type='text'
                                    />
                                    {errors.name && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='street_address'>
                                        Status
                                    </label>
                                    <Select
                                        id='status'
                                        sx={{ borderRadius: '12px', border: '1px', borderColor: '#F4F4F4' }}
                                        className='form-control'
                                        defaultValue={'empty'}
                                        inputProps={{
                                            ...register('status', {
                                                required: true,
                                            }),
                                        }}>
                                        <MenuItem disabled value={'empty'}>
                                            <span className='placeholder-text'>Select room status...</span>
                                        </MenuItem>
                                        {statusList.map((status) => {
                                            return (
                                                <MenuItem key={status.value} value={status.value}>
                                                    {status.title}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    {errors.status && <span className='invalid'>{errors.status.message}</span>}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='street_address'>
                                        Floor
                                    </label>
                                    <Select
                                        id='status'
                                        sx={{ borderRadius: '12px', border: '1px', borderColor: '#F4F4F4' }}
                                        className='form-control'
                                        defaultValue={'empty'}
                                        inputProps={{
                                            ...register('floor', {
                                                required: true,
                                            }),
                                        }}>
                                        <MenuItem disabled value={'empty'}>
                                            <span className='placeholder-text'>Select a floor...</span>
                                        </MenuItem>
                                        {floors.map((floor) => {
                                            return (
                                                <MenuItem key={floor.id} value={floor.id}>
                                                    {floor.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    {errors.floor && <span className='invalid'>{errors.floor.message}</span>}
                                </FormGroup>
                                <div className='d-flex h-100'>
                                    <div className='align-self-end ml-auto'>
                                        <Button
                                            type='submit'
                                            disabled={addingSpace}
                                            className='btn btn-dark mt-4 btn-block'>
                                            {!addingSpace && <span>Add Room</span>}
                                            {addingSpace && (
                                                <span>
                                                    <Spinner />
                                                </span>
                                            )}
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
}
