/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table,
    UncontrolledTooltip,
} from 'reactstrap';
import BasicMenu from '../../../../components/Menu';
import { User } from '../../../../models/user.model';
import { VendorEquipmentItem } from '../../../../models/vendor.model';
import { getPermission } from '../../../../utils/user.utils';
import EquipmentAddForm from './EquipmentAddForm';
import _ from 'lodash';
import SweetAlert from 'react-bootstrap-sweetalert';
import useToast from '../../../../hooks/useToast';
import { useMutation } from '@apollo/client';
import { DELETE_VENDOR_EQUIPMENT_ITEM } from '../../../../graphql/vendors.graphql';

interface Props {
    vendorEquipmentItems?: VendorEquipmentItem[];
}

const VendorEquipmentItemView = ({ vendorEquipmentItems }: Props) => {
    const user: User | null = useSelector((state: any) => state.user.userInfo);
    const [searchKey, setSearchKey] = useState<string>('');
    const { showToast, Notification } = useToast();
    const [sortFiled, setSortField] = useState<string>('name');
    const [alert, setAlert] = useState<any>(null);
    const [sortDirection, setSortDirection] = useState<string>('desc');
    const [page, setPage] = useState<number>(0);
    const [rowPerPage, setRowPerPage] = useState<number>(10);
    const [equipmentItem, setEquipmentItem] = useState<VendorEquipmentItem>();
    const [displayForm, setDisplayForm] = useState<boolean>(false);
    const [removeEquipmentItem, { data: deletedItem, error: deleteError }] = useMutation(DELETE_VENDOR_EQUIPMENT_ITEM, {
        // refetchQueries: [{ query: GET_VENDOR }, "getVendor"],
        refetchQueries: ['getVendor'],
    });

    const nextPage = () => {
        setPage(page + 1);
    };

    const previousPage = () => {
        setPage(page - 1);
    };

    const displayAlert = (props: any) => {
        setAlert(
            <SweetAlert
                {...props}
                showCancel
                confirmBtnText='Continue'
                confirmBtnBsStyle='danger'
                title='Are you sure?'
                onConfirm={() => {
                    removeEquipmentItem({
                        variables: {
                            id: props.item.id,
                        },
                    });

                    setAlert(null);
                }}
                onCancel={() => {
                    setAlert(null);
                }}
                focusCancelBtn>
                This equipment item will be removed from the system.
            </SweetAlert>,
        );
    };

    useEffect(() => {
        if (deleteError && !deletedItem) {
            showToast('An error has occurred while trying to remove this equipment item', 'danger');
        }

        if (deletedItem && !deleteError) {
            showToast('Successfully removed equipment item', 'success');
        }
    }, [deleteError, deletedItem]);

    const sortBy: any = (items: VendorEquipmentItem[], field: string, direction: string) => {
        const orderedBookings = new Map([
            ['name', _.orderBy(items, (item) => item?.name, [direction === 'asc' ? 'asc' : 'desc'])],
            ['price', _.orderBy(items, (item) => item?.price, [direction === 'asc' ? 'asc' : 'desc'])],
            ['category', _.orderBy(items, (item) => item?.itemCategory?.title, [direction === 'asc' ? 'asc' : 'desc'])],
            ['unit', _.orderBy(items, (item) => item?.equipmentUnit?.title, [direction === 'asc' ? 'asc' : 'desc'])],
            ['status', _.orderBy(items, (item) => item?.status?.title, [direction === 'asc' ? 'asc' : 'desc'])],
        ]);
        return orderedBookings.get(field);
    };

    const getRowValue = (value: string) => {
        setRowPerPage(parseInt(value));
    };

    if (displayForm) {
        return <EquipmentAddForm backToListView={() => setDisplayForm(false)} selectedEquipmentItem={equipmentItem} />;
    }

    let filteredItems = vendorEquipmentItems.filter((item) => {
        if (searchKey === '') {
            return item;
        }

        return (
            item?.name?.toLowerCase().includes(searchKey.toLowerCase()) ||
            item?.status?.title.toLowerCase().includes(searchKey.toLowerCase()) ||
            item?.equipmentUnit?.title.toLowerCase().includes(searchKey.toLowerCase()) ||
            item?.itemCategory?.title.toLowerCase().includes(searchKey.toLowerCase())
        );
    });

    filteredItems = sortBy(filteredItems, sortFiled, sortDirection);

    return (
        <>
            {Notification}
            {alert}
            <div>
                <Row className='mt-4'>
                    <div className='col'>
                        <Card>
                            <CardHeader className='border-0'>
                                <Row>
                                    <Col xs='6'>
                                        <h3 className='mb-0'>Items</h3>
                                    </Col>
                                    {getPermission(user, 'locations', 'create') && (
                                        <Col className='text-right' xs='6'>
                                            <button
                                                className='btn btn-dark btn-sm'
                                                color='default'
                                                id='tooltip969372949'
                                                onClick={() => {
                                                    setDisplayForm(true);
                                                    setEquipmentItem(undefined);
                                                }}>
                                                Add item
                                            </button>
                                            <UncontrolledTooltip delay={0} target='tooltip969372949'>
                                                Add item
                                            </UncontrolledTooltip>
                                        </Col>
                                    )}
                                </Row>
                                <Row className='mt-4'>
                                    <Col>
                                        <Input
                                            className='w-25'
                                            placeholder='Search keyword...'
                                            onChange={(e) => {
                                                setSearchKey(e.target.value);
                                                setPage(0);
                                            }}></Input>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <Table className='align-items-center table-flush'>
                                <thead className='thead-light'>
                                    <tr>
                                        <th
                                            onClick={() => {
                                                setSortField('name');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='name'
                                            scope='col'>
                                            Name
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('price');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='price'
                                            scope='col'>
                                            Price
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('category');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='menuCategory'
                                            scope='col'>
                                            Category
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('unit');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='MenuMealType'
                                            scope='col'>
                                            Unit
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('status');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='menuStatus'
                                            scope='col'>
                                            Status
                                        </th>
                                        <th scope='col'></th>
                                    </tr>
                                </thead>
                                <tbody className='list'>
                                    {filteredItems
                                        ?.slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                                        .map((item) => {
                                            return (
                                                <tr
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                        setEquipmentItem(item);
                                                        setDisplayForm(true);
                                                    }}
                                                    key={item.id}>
                                                    <td>{`${item?.name}`}</td>
                                                    <td>{`R${item?.price}`}</td>
                                                    <td>{item?.itemCategory.title}</td>
                                                    <td>{item?.equipmentUnit.title}</td>
                                                    <td>{item?.status.title}</td>
                                                    <td className='text-right'>
                                                        {getPermission(user, 'locations', 'update') && (
                                                            <Button
                                                                className='btn btn-info btn-icon-only rounded-circle btn-sm'
                                                                onClick={() => {
                                                                    setEquipmentItem(item);
                                                                    setDisplayForm(true);
                                                                }}>
                                                                {' '}
                                                                <i className='fa-solid fa-pencil'></i>
                                                            </Button>
                                                        )}
                                                        {getPermission(user, 'locations', 'delete') && (
                                                            <Button
                                                                className='btn btn-danger btn-icon-only rounded-circle btn-sm'
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    displayAlert({ warning: true, item });
                                                                }}>
                                                                <i className='fa-solid fa-trash-can'></i>
                                                            </Button>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </Table>
                            <CardFooter className='py-4'>
                                <Row>
                                    <div className='col-4 d-flex'>
                                        <span style={{ fontSize: '12px' }}>Rows per page:</span>
                                        <BasicMenu
                                            getValue={getRowValue}
                                            options={['10', '20', '100']}
                                            value={rowPerPage.toString()}
                                        />
                                    </div>
                                    <div className='col-4 d-flex'>
                                        <span className='min-text'>Page:</span>
                                        <span className='min-text pl-2'>
                                            {page + 1} of {Math.ceil(filteredItems.length / rowPerPage)}
                                        </span>
                                    </div>
                                    <div className='col-4 d-flex'>
                                        <Pagination>
                                            <PaginationItem className={page <= 0 ? 'disabled' : ''}>
                                                <PaginationLink onClick={(e) => previousPage()}>
                                                    <i className='fas fa-angle-left' />
                                                    <span className='sr-only'>Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem
                                                className={
                                                    page >= Math.ceil(filteredItems.length / rowPerPage) - 1
                                                        ? 'disabled'
                                                        : ''
                                                }>
                                                <PaginationLink onClick={(e) => nextPage()}>
                                                    <i className='fas fa-angle-right' />
                                                    <span className='sr-only'>Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                    </div>
                                </Row>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </div>
        </>
    );
};

export default VendorEquipmentItemView;
