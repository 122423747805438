/* eslint-disable react-hooks/exhaustive-deps */
// import moment from "moment";
import { useMutation, useQuery } from "@apollo/client";
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import useToast from "../../../hooks/useToast";
import { GET_TASK_LIST, REMOVE_TASK } from "../../../graphql/task-list.graphql";
import { Task, TaskStatus, TaskType } from "../../../models/tasks.model";
import { LinearProgress } from "@mui/material";
import BasicMenu from "../../../components/Menu";
import _ from "lodash";
import moment from "moment";
import { getPermission } from "../../../utils/user.utils";
import { User } from "../../../models/user.model";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { useLocalStorage } from "../../../hooks/local-storage.hook";

interface TaskRouteData {
  keyWord: string;
  statusFilter: string;
  typeFilter: string;
  page: number;
  pageSize: number;
}

const TaskListView = () => {
  const [page, setPage] = useState<number>(0);
  const [getLocalStorage, setLocalStorage] = useLocalStorage("task");
  const user: User | null = useSelector((state: any) => state.user.userInfo);
  const [searchKey, setSearchKey] = useState<string>("");
  const [rowPerPage, setRowPerPage] = useState<number>(10);
  const [alert, setAlert] = useState<any>(null);
  const { showToast, Notification } = useToast();
  const [sortFiled, setSortField] = useState<string>("date");
  const [sortDirection, setSortDirection] = useState<string>("desc");
  const [statusFilter, setStatusFilter] = useState<string>("All");
  const [taskTypeFilter, setTaskTypeFilter] = useState<string>("All");

  const { data, loading, error } = useQuery(GET_TASK_LIST);
  const [deleteTask, { data: deletedTask }] = useMutation(REMOVE_TASK, {
    // refetchQueries: [{ query: GET_TASK_LIST }, "GetTaskList"],
    refetchQueries: ["GetTaskList"],
  });
  const previousPage = () => {
    setPage(page - 1);
    const routeData: TaskRouteData = getLocalStorage() || {};
    routeData.page = page - 1;
    setLocalStorage(routeData);
  };
  const nextPage = () => {
    setPage(page + 1);
    const routeData: TaskRouteData = getLocalStorage() || {};
    routeData.page = page + 1;
    setLocalStorage(routeData);
  };

  const navigate = useNavigate();

  const displayAlert = (props: any) => {
    setAlert(
      <SweetAlert
        {...props}
        showCancel
        confirmBtnText="Continue"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => {
          deleteTask({
            variables: {
              id: props.task.id,
            },
          });
          setAlert(null);
        }}
        onCancel={() => {
          setAlert(null);
        }}
        focusCancelBtn
      >
        This task will be removed.
      </SweetAlert>
    );
  };

  useEffect(() => {
    const routeData: TaskRouteData = getLocalStorage();

    if (routeData) {
      setPage(routeData?.page || 0);
      setRowPerPage(routeData?.pageSize || 10);
      setSearchKey(routeData?.keyWord || "");
      setStatusFilter(routeData?.statusFilter || "All");
      setTaskTypeFilter(routeData?.typeFilter || "All");
    }
  }, []);

  const getStatus = (statusValue: string) => {
    setStatusFilter(statusValue);
    setSearchKey("");
    setPage(0);

    const routeData: TaskRouteData = getLocalStorage() || {};
    routeData.page = 0;
    routeData.keyWord = "";
    routeData.statusFilter = statusValue;
    setLocalStorage(routeData);
  };

  const getType = (typeValue: string) => {
    setTaskTypeFilter(typeValue);
    setSearchKey("");
    setPage(0);

    const routeData: TaskRouteData = getLocalStorage() || {};
    routeData.page = 0;
    routeData.keyWord = "";
    routeData.typeFilter = typeValue;
    setLocalStorage(routeData);
  };

  const sortBy: any = (tasks: Task[], field: string, direction: string) => {
    const orderedBookings = new Map([
      [
        "name",
        _.orderBy(tasks, (task) => task.title, [
          direction === "asc" ? "asc" : "desc",
        ]),
      ],
      [
        "dueDate",
        _.orderBy(tasks, (task) => task.dueDate, [
          direction === "asc" ? "asc" : "desc",
        ]),
      ],
      [
        "date",
        _.orderBy(tasks, (task) => task.createdAt, [
          direction === "asc" ? "asc" : "desc",
        ]),
      ],
      [
        "type",
        _.orderBy(tasks, (task) => task.type.title, [
          direction === "asc" ? "asc" : "desc",
        ]),
      ],
      [
        "status",
        _.orderBy(tasks, (task) => task.status.title, [
          direction === "asc" ? "asc" : "desc",
        ]),
      ],
    ]);
    return orderedBookings.get(field);
  };

  const getRowValue = (value: string) => {
    setRowPerPage(parseInt(value));
    const routeData: TaskRouteData = getLocalStorage() || {};
    routeData.pageSize = parseInt(value);
    setLocalStorage(routeData);
  };

  useEffect(() => {
    if (error) {
      showToast("An error has occurred, please refresh the page", "danger");
    }

    if (deletedTask) {
      showToast("Successfully removed task", "success");
    }
  }, [error, deletedTask]);

  if (loading) {
    return <LinearProgress />;
  }

  const taskList: Task[] = data?.task;
  let filteredTaskList = taskList.filter((task) => {
    const filtered =
      task.title.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
      task.status.title
        .toLocaleLowerCase()
        .includes(searchKey.toLocaleLowerCase()) ||
      task.type.title
        .toLocaleLowerCase()
        .includes(searchKey.toLocaleLowerCase());

    if (statusFilter === "All" && taskTypeFilter === "All") {
      return task && filtered;
    }

    if (searchKey !== "All" && searchKey === "" && task.status !== null) {
      return (
        (task.status?.title === statusFilter && filtered) ||
        (task.type.title === taskTypeFilter && filtered)
      );
    }

    return filtered;
  });

  filteredTaskList = sortBy(filteredTaskList, sortFiled, sortDirection);
  const statusList: TaskStatus[] = data?.statusList;
  const taskTypes: TaskType[] = data?.taskTypes;

  return (
    <>
      {Notification}
      {alert}
      <Container className="mt-4" fluid>
        <Row>
          <Col>
            <h1>Tasks</h1>
          </Col>
        </Row>
        <Row className="mt-4">
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Tasks</h3>
                  </Col>
                  <Col className="text-right" xs="6">
                    <Link
                      to={"/admin/task/new/"}
                      className="btn btn-round btn-sm btn-dark"
                      color="default"
                      id="tooltip969372949"
                    >
                      <span className="btn-inner--text">Add task</span>
                    </Link>

                    <UncontrolledTooltip delay={0} target="tooltip969372949">
                      Add task
                    </UncontrolledTooltip>
                  </Col>
                </Row>
                <Row className="mt-4 border-0">
                  <Col xs="8">
                    <Input
                      className="form-control w-25"
                      type="text"
                      value={searchKey}
                      placeholder="Search keyword..."
                      onChange={(e) => {
                        setSearchKey(e.target.value);
                        setPage(0);

                        const routeData: TaskRouteData =
                          getLocalStorage() || {};
                        routeData.keyWord = e.target.value;
                        routeData.page = 0;
                        setLocalStorage(routeData);
                      }}
                    />
                  </Col>
                  <Col xs="4">
                    <div className="row">
                      <div className="col-6 d-flex">
                        <span className="min-text">Status</span>
                        <BasicMenu
                          getValue={getStatus}
                          options={["All", ...statusList.map((s) => s.title)]}
                          value={statusFilter}
                        />
                      </div>
                      <div className="col-6 d-flex">
                        <span className="min-text">Type</span>
                        <BasicMenu
                          getValue={getType}
                          options={["All", ...taskTypes.map((s) => s.title)]}
                          value={taskTypeFilter}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th
                      onClick={() => {
                        setSortField("name");
                        setSortDirection(
                          sortDirection === "asc" ? "desc" : "asc"
                        );
                      }}
                      className="sort"
                      data-sort="client"
                      scope="col"
                    >
                      Name
                    </th>
                    <th
                      onClick={() => {
                        setSortField("date");
                        setSortDirection(
                          sortDirection === "asc" ? "desc" : "asc"
                        );
                      }}
                      className="sort"
                      data-sort="date"
                      scope="col"
                    >
                      Date created
                    </th>
                    <th
                      onClick={() => {
                        setSortField("dueDate");
                        setSortDirection(
                          sortDirection === "asc" ? "desc" : "asc"
                        );
                      }}
                      className="sort"
                      data-sort="reference"
                      scope="col"
                    >
                      Due date
                    </th>
                    <th
                      onClick={() => {
                        setSortField("type");
                        setSortDirection(
                          sortDirection === "asc" ? "desc" : "asc"
                        );
                      }}
                      className="sort"
                      data-sort="status"
                      scope="col"
                    >
                      Type
                    </th>
                    <th
                      onClick={() => {
                        setSortField("status");
                        setSortDirection(
                          sortDirection === "asc" ? "desc" : "asc"
                        );
                      }}
                      className="sort"
                      data-sort="paymentStatus"
                      scope="col"
                    >
                      Status
                    </th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody className="list">
                  {filteredTaskList
                    .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                    .map((task) => {
                      return (
                        <tr
                          key={task.id}
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            navigate(`/admin/task/manage/${task.id}`)
                          }
                        >
                          <td>{task.title}</td>
                          <td>{moment(task.createdAt).format("LL")}</td>
                          <td>{moment(task.dueDate).format("LL")}</td>
                          <td>{task.type.title}</td>
                          <td>{task.status.title}</td>
                          <td>
                            {getPermission(user, "tasks", "update") && (
                              <Link
                                className="btn btn-info btn-icon-only rounded-circle btn-sm"
                                to={`/admin/task/manage/${task.id}`}
                              >
                                <i className="fa-solid fa-pencil"></i>
                              </Link>
                            )}
                            {getPermission(user, "tasks", "delete") && (
                              <Button
                                className="btn btn-danger btn-icon-only rounded-circle btn-sm"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  displayAlert({ warning: true, task });
                                }}
                              >
                                <i className="fa-solid fa-trash-can"></i>
                              </Button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <Row>
                  <div className="col-4 d-flex">
                    <span style={{ fontSize: "12px" }}>Rows per page:</span>
                    <BasicMenu
                      getValue={getRowValue}
                      options={["10", "20", "100"]}
                      value={rowPerPage.toString()}
                    />
                  </div>
                  <div className="col-4 d-flex">
                    <span className="min-text">Page:</span>
                    <span className="min-text pl-2">
                      {page + 1} of{" "}
                      {Math.ceil(filteredTaskList.length / rowPerPage)}
                    </span>
                  </div>
                  <div className="col-4 d-flex">
                    <Pagination>
                      <PaginationItem className={page <= 0 ? "disabled" : ""}>
                        <PaginationLink onClick={(e) => previousPage()}>
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem
                        className={
                          page >=
                          Math.ceil(filteredTaskList.length / rowPerPage) - 1
                            ? "disabled"
                            : ""
                        }
                      >
                        <PaginationLink onClick={(e) => nextPage()}>
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </div>
                </Row>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default TaskListView;
