/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, LinearProgress, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactDatetimeClass from 'react-datetime';
import {
    Button,
    // Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    FormGroup,
    Row,
} from 'reactstrap';
import { UPDATE_PRODUCT_PACKAGE } from '../../../graphql/packages.graphql';
import { useMutation, useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { GET_PACKAGES } from '../../../graphql/clients.graphql';
import useToast from '../../../hooks/useToast';
// import Spinner from "../../../components/Spinner/Spinner";
import moment, { isMoment } from 'moment';
import { CHECK_BOOKING_AVAILABILITY } from '../../../graphql/availability.graphql';
import { ProductItem } from '../../../models/product.model';
import { ProductPackage } from '../../../models/packages.models';
import { BookingStatus } from '../../../models/bookings.model';
import Spinner from '../../../components/Spinner/Spinner';
interface ClientManageFormProps {
    backToPackages(): void;
    clientPackage: any;
    clientMembers: any[];
}

type FormData = {
    package: string;
    from: string;
    to: string;
    member: string;
    status: string;
};

const ClientPackageManageForm = ({ backToPackages, clientPackage, clientMembers }: ClientManageFormProps) => {
    const {
        register,
        handleSubmit,
        setValue,
        // getValues,
        formState: { errors },
    } = useForm<FormData>();
    const { loading, error, data } = useQuery(GET_PACKAGES);

    const { showToast, Notification } = useToast();
    const [checkAvailability, { error: errorChecking, data: checkedBooking }] = useMutation(CHECK_BOOKING_AVAILABILITY);
    const [updatePackageMutation, { error: updateError, data: updatedPackage, loading: isUpdating }] = useMutation(
        UPDATE_PRODUCT_PACKAGE,
        {
            // refetchQueries: [{ query: GET_CLIENT }, "GetClient"],
            refetchQueries: ['GetClient'],
        },
    );

    const [isBookingAvailable, setIsBookingAvailable] = useState<boolean>(true);
    const [packageId, setPackageId] = useState<string>();
    const [assignedItemId, setAssignedItemId] = useState<string | null>(null);

    useEffect(() => {
        console.log('Packages', clientPackage);
        if (clientPackage) setPackageId(clientPackage.productPackage.id);
    }, []);
    useEffect(() => {
        if (error) {
            showToast('An error has occurred, please refresh the page.', 'danger');
        }

        if (updateError) {
            showToast('An error has occurred while trying to update this package, please try again', 'danger');
        }

        if (errorChecking) {
            showToast('An error has occurred while trying to check for a booking', 'danger');
        }
    }, [error, updateError, errorChecking]);

    useEffect(() => {
        if (updatedPackage) {
            showToast('Successfully updated the package', 'success');
        }
    }, [updatedPackage]);

    useEffect(() => {
        if (checkedBooking) {
            if (!checkedBooking.booking.is_available) {
                // setButtonText("Not available");
            }
            setIsBookingAvailable(checkedBooking.booking.is_available);
        }
    }, [checkedBooking]);

    const onSubmit = (data: FormData) => {
        if (!isBookingAvailable) {
            checkAvailability({
                variables: {
                    packageId: data.package,
                    from: data.from,
                    to: data.to,
                },
            });
            return;
        }

        const productPackage = {
            member_id: data.member === 'empty' ? null : data.member,
            from: data.from,
            to: data.to,
            package_id: data.package,
            status_enum: data.status,
            assigned_item_id: assignedItemId,
        };

        console.log('Package to update', productPackage);

        updatePackageMutation({
            variables: {
                id: clientPackage.id,
                package: productPackage,
            },
        });
    };

    // const isEndingDateValid = (selectedDate: moment.Moment) => {
    //   const today = moment(getValues("from"));
    //   return selectedDate.isSameOrAfter(today);
    // };

    const onInputChange = () => {
        setIsBookingAvailable(false);
    };

    const handleChange = (event: SelectChangeEvent) => {
        setPackageId(event.target.value as string);
    };

    if (loading) {
        return <LinearProgress />;
    }

    const packages: ProductPackage[] = data?.packages;
    const bookingStatus: BookingStatus[] = data?.status;
    const productItems: ProductItem[] | undefined = packages
        .find((_package) => _package.id === packageId)
        ?.product.productItems.filter((item) => {
            if (item.sharable) {
                return item;
            }

            return item.status.value !== 'assigned';
        });

    //console.log("Item", productItems);

    const items: ProductItem[] = data.productItems;

    const assignedItem: ProductItem | undefined = items?.find((item) => item.id === clientPackage.assignedItemId);

    const isAssigned = packages.find((_package) => _package.id === packageId)?.isAssignable;

    //console.log("client  :", data);

    //console.log("Product items", isAssigned);

    return (
        <>
            {Notification}
            <Row className='mt-4'>
                <Col md='12' lg='6'>
                    <Card>
                        <CardHeader>
                            <Row noGutters={true}>
                                <button
                                    className='btn btn-outline-primary btn-sm mr-4'
                                    onClick={() => backToPackages()}>
                                    <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                                    <span className='btn-inner-text'>Back</span>
                                </button>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className='row'>
                                    <div className='col-md-12 col-lg-6'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='name'>
                                                Package
                                            </label>
                                            <Select
                                                id='package'
                                                className='form-control'
                                                sx={{ borderRadius: '12px', padding: '0' }}
                                                placeholder='Select a role'
                                                onChange={(event: SelectChangeEvent) => {
                                                    onInputChange();
                                                    handleChange(event);
                                                }}
                                                defaultValue={clientPackage.productPackage.id}
                                                inputProps={{
                                                    ...register('package', {
                                                        required: true,
                                                        value: clientPackage.productPackage.id,
                                                    }),
                                                }}>
                                                {packages.map((_package) => {
                                                    return (
                                                        <MenuItem key={_package.id} value={_package.id}>
                                                            {_package.name}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                            {errors.package && <span className='invalid'>*This field is required</span>}
                                        </FormGroup>
                                    </div>
                                    {isAssigned === true && (
                                        <div className='col-md-12 col-lg-6'>
                                            <FormGroup>
                                                <label className='form-control-label' htmlFor='name'>
                                                    Package item
                                                </label>
                                                <Autocomplete
                                                    id='items'
                                                    defaultValue={assignedItem ? assignedItem : null}
                                                    filterOptions={(options, state) =>
                                                        options.filter((element) => {
                                                            return (
                                                                element.name
                                                                    .toLowerCase()
                                                                    .includes(state.inputValue.toLowerCase()) ||
                                                                element.code
                                                                    ?.toLowerCase()
                                                                    .includes(state.inputValue.toLowerCase())
                                                            );
                                                        })
                                                    }
                                                    onChange={(event, value) => {
                                                        setAssignedItemId(value?.id ? value.id : '');
                                                    }}
                                                    options={productItems ? productItems : []}
                                                    getOptionLabel={(option) =>
                                                        `${option.name} - ${option.code ? option.code : ''}`
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            placeholder='Assign package item'
                                                            fullWidth={true}
                                                            variant='outlined'
                                                        />
                                                    )}
                                                />
                                            </FormGroup>
                                        </div>
                                    )}
                                </div>
                                <div className='row'>
                                    <div className='col-md-12 col-lg-6'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='email'>
                                                From
                                            </label>
                                            <ReactDatetimeClass
                                                inputProps={{
                                                    placeholder: 'Select start date...',
                                                    ...register('from', {
                                                        required: true,
                                                        value: moment(clientPackage.from).format('yyyy-MM-DD'),
                                                    }),
                                                }}
                                                timeFormat={false}
                                                dateFormat={'YYYY-MM-DD'}
                                                initialValue={moment(clientPackage.from).format('yyyy-MM-DD')}
                                                onChange={(value) => {
                                                    if (value && isMoment(value)) {
                                                        setValue('from', value.format('yyyy-MM-DD'));
                                                    }
                                                    onInputChange();
                                                }}
                                            />
                                            {errors.from && <span className='invalid'>*This field is required</span>}
                                        </FormGroup>
                                    </div>
                                    <div className='col-md-12 col-lg-6'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='contact'>
                                                To
                                            </label>
                                            <ReactDatetimeClass
                                                inputProps={{
                                                    placeholder: 'Select end date...',
                                                    ...register('to', {
                                                        required: true,
                                                        value: moment(clientPackage.to).format('yyyy-MM-DD'),
                                                    }),
                                                }}
                                                timeFormat={false}
                                                dateFormat={'YYYY-MM-DD'}
                                                initialValue={moment(clientPackage.to).format('yyyy-MM-DD')}
                                                onChange={(value) => {
                                                    if (value && isMoment(value)) {
                                                        setValue('to', value.format('yyyy-MM-DD'));
                                                    }
                                                    onInputChange();
                                                }}
                                            />
                                            {errors.to && <span className='invalid'>*This field is required</span>}
                                        </FormGroup>
                                    </div>
                                    <div className='col-md-12 col-lg-6'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='p-member-name'>
                                                Member (optional)
                                            </label>
                                            <Select
                                                id='package'
                                                className='form-control'
                                                sx={{ borderRadius: '12px', padding: '0' }}
                                                placeholder='Select a role'
                                                defaultValue={clientPackage.member?.id || 'empty'}
                                                inputProps={{
                                                    ...register('member', {
                                                        required: false,
                                                        value: clientPackage.member?.id || 'empty',
                                                    }),
                                                }}>
                                                <MenuItem disabled className='placeholder-text' value={'empty'}>
                                                    Select a member...
                                                </MenuItem>
                                                {clientMembers.map((member: any) => {
                                                    return (
                                                        <MenuItem key={member.id} value={member.id}>
                                                            {`${member.user.name} ${member.user.surname}`}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                            {errors.member && <span className='invalid'>*This field is required</span>}
                                        </FormGroup>
                                    </div>
                                    <div className='col-md-12 col-lg-6'>
                                        <FormGroup>
                                            <label className='form-control-label' htmlFor='p-member-name'>
                                                Status
                                            </label>
                                            <Select
                                                id='package'
                                                className='form-control'
                                                placeholder='Select a role'
                                                sx={{ borderRadius: '12px', padding: '0' }}
                                                defaultValue={clientPackage?.status.value}
                                                inputProps={{
                                                    ...register('status', {
                                                        required: true,
                                                        value: clientPackage?.status.value,
                                                    }),
                                                }}>
                                                {bookingStatus.map((status: any) => {
                                                    return (
                                                        <MenuItem key={status.value} value={status.value}>
                                                            {status.title}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                            {errors.status && <span className='invalid'>*This field is required</span>}
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className='row no-gutters justify-content-end'>
                                    {/* <div className="d-flex h-25 w-25">
                        <input
                          type="checkbox"
                          checked={assignable}
                          onChange={(e) => {
                            setAssignable(e.target.checked);
                          }}
                        />
                        <label className="ml-2 mt-2">Assignable</label>
                      </div> */}
                                    <Button type='submit' className='btn btn-dark mt-4 ' disabled={isUpdating}>
                                        {isUpdating ? (
                                            <span>
                                                <Spinner />
                                            </span>
                                        ) : (
                                            <span>Update package</span>
                                        )}
                                    </Button>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default ClientPackageManageForm;
