/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from "@apollo/client";
import React, { useState, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Card, CardBody, Table, Button } from "reactstrap";
import { REMOVE_CHECKLIST_ADD_ON } from "../../../../../graphql/meeting-room-checklist.graphql";
import { GET_MEETING_ROOM } from "../../../../../graphql/meeting-rooms.graphql";
import useToast from "../../../../../hooks/useToast";
import { CheckListAddOn } from "../../../../../models/meeting.room.model";
import AddChecklistAdOnDialog from "../../dialogs/AddChecklistAdOnDialog";

interface Props {
  checklistAddOns?: CheckListAddOn[];
  itemId: string;
}

const MeetingRoomItemChecklistView = ({ checklistAddOns, itemId }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [alert, setAlert] = useState<any>();
  const { showToast, Notification } = useToast();
  const [removeChecklistAddOn, { data, error }] = useMutation(
    REMOVE_CHECKLIST_ADD_ON,
    {
      refetchQueries: [{ query: GET_MEETING_ROOM }, "GetMeetingRoom"],
    }
  );

  useEffect(() => {
    if (data && !error) {
      showToast("Successfully removed assigned checklist item", "success");
    }

    if (error && !data) {
      showToast(
        "An error has occurred while trying to removed this checklist item",
        "danger"
      );
    }
  }, [data, error]);

  const displayAlert = (props: any) => {
    setAlert(
      <SweetAlert
        {...props}
        showCancel
        confirmBtnText="Continue"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => {
          removeChecklistAddOn({
            variables: {
              id: props.addOn.id,
            },
          });
          setAlert(null);
        }}
        onCancel={() => {
          setAlert(null);
        }}
        focusCancelBtn
      >
        This image will be removed.
      </SweetAlert>
    );
  };

  return (
    <>
      {alert}
      {Notification}
      {isOpen && (
        <AddChecklistAdOnDialog
          isOpen={isOpen}
          itemId={itemId}
          checkListAddOns={checklistAddOns}
          handleClose={() => setIsOpen(false)}
        />
      )}
      <Card>
        <CardBody>
          <div className="row no-gutters justify-content-between align-items-center mb-3">
            <h3 className="mb-0">Room Checklist Items</h3>
            <button
              className="btn btn-dark btn-sm"
              onClick={() => {
                setIsOpen(true);
              }}
            >
              Add
            </button>
          </div>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">Name</th>
                <th scope="col" />
              </tr>
            </thead>
            <tbody className="list">
              {checklistAddOns?.map((addOn) => {
                return (
                  <tr key={addOn.id} style={{ cursor: "pointer" }}>
                    <td>{addOn.checkListItem?.name}</td>
                    <td className="text-right">
                      <Button
                        onClick={() => displayAlert({ warning: true, addOn })}
                        className="btn btn-danger btn-icon-only rounded-circle btn-sm"
                      >
                        <i className="fa-regular fa-trash-can"></i>
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </>
  );
};

export default MeetingRoomItemChecklistView;
