/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import { GET_VENDORS, REMOVE_VENDOR } from "../../../graphql/vendors.graphql";
import { useMutation, useQuery } from "@apollo/client";
import { Vendor } from "../../../models/vendor.model";
import _ from "lodash";
import BasicMenu from "../../../components/Menu";
import { User } from "../../../models/user.model";
import { getPermission } from "../../../utils/user.utils";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import useToast from "../../../hooks/useToast";
import { LinearProgress } from "@mui/material";
import { useLocalStorage } from "../../../hooks/local-storage.hook";

interface VendorRouteData {
  keyWord: string;
  page: number;
  pageSize: number;
}

export default function VendorsView() {
  const user: User | null = useSelector((state: any) => state.user.userInfo);
  const { showToast, Notification } = useToast();
  const [alert, setAlert] = useState<any>(null);
  const [page, setPage] = useState<number>(0);
  const [rowPerPage, setRowPerPage] = useState<number>(10);
  const [searchKey, setSearchKey] = useState<string>("");
  const [sortFiled, setSortField] = useState<string>("name");
  const [sortDirection, setSortDirection] = useState<string>("desc");
  const [getLocalStorage, setLocalStorage] = useLocalStorage("vendors");

  const { data, error, loading } = useQuery(GET_VENDORS);
  const [removeVendorMutation, { data: removedVendor, error: removeError }] =
    useMutation(REMOVE_VENDOR, {
      // refetchQueries: [{ query: GET_VENDORS }, "getVendors"],
      refetchQueries: ["getVendors"],
    });

  useEffect(() => {
    const routeData: VendorRouteData = getLocalStorage();

    if (routeData) {
      setPage(routeData?.page || 0);
      setRowPerPage(routeData?.pageSize || 10);
      setSearchKey(routeData?.keyWord || "");
    }
  }, []);

  const navigate = useNavigate();

  const nextPage = () => {
    setPage(page + 1);
    const routeData: VendorRouteData = getLocalStorage() || {};
    routeData.page = page + 1;
    setLocalStorage(routeData);
  };

  const previousPage = () => {
    setPage(page - 1);
    const routeData: VendorRouteData = getLocalStorage() || {};
    routeData.page = page - 1;
    setLocalStorage(routeData);
  };

  const getRowValue = (value: string) => {
    setRowPerPage(parseInt(value));
    const routeData: VendorRouteData = getLocalStorage() || {};
    routeData.pageSize = parseInt(value);
    setLocalStorage(routeData);
  };

  const removeVendor = (id: string) => {
    removeVendorMutation({
      variables: { id },
    });
  };

  useEffect(() => {
    if (error) {
      showToast("An error has occurred, please refresh the page", "danger");
    }

    if (removeError) {
      showToast(
        "An error has occurred while trying to remove a vendor",
        "danger"
      );
    }
  }, [error, removeError]);

  useEffect(() => {
    if (removedVendor) {
      showToast("Successfully removed vendor", "success");
    }
  }, [removedVendor]);

  const sortBy: any = (vendors: Vendor[], field: string, direction: string) => {
    const orderedBookings = new Map([
      [
        "name",
        _.orderBy(vendors, (vendor) => vendor.name, [
          direction === "asc" ? "asc" : "desc",
        ]),
      ],
      [
        "description",
        _.orderBy(vendors, (vendor) => vendor.description, [
          direction === "asc" ? "asc" : "desc",
        ]),
      ],
      [
        "registrationNumber",
        _.orderBy(vendors, (vendor) => vendor.registrationNumber, [
          direction === "asc" ? "asc" : "desc",
        ]),
      ],
      [
        "vatNumber",
        _.orderBy(vendors, (vendor) => vendor.vatNumber, [
          direction === "asc" ? "asc" : "desc",
        ]),
      ],
      [
        "status",
        _.orderBy(vendors, (vendor) => vendor.status.title, [
          direction === "asc" ? "asc" : "desc",
        ]),
      ],
    ]);
    return orderedBookings.get(field);
  };

  const vendors: Vendor[] = data?.vendor || [];

  let filteredVendors = vendors.filter((vendor) => {
    if (searchKey === "") {
      return vendor;
    }

    return (
      vendor.name.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
      vendor.description
        .toLocaleLowerCase()
        .includes(searchKey.toLocaleLowerCase()) ||
      vendor.registrationNumber
        .toLocaleLowerCase()
        .includes(searchKey.toLocaleLowerCase()) ||
      vendor.vatNumber
        .toLocaleLowerCase()
        .includes(searchKey.toLocaleLowerCase()) ||
      vendor.status.title
        .toLocaleLowerCase()
        .includes(searchKey.toLocaleLowerCase())
    );
  });

  if (loading) {
    return <LinearProgress />;
  }

  filteredVendors = sortBy(filteredVendors, sortFiled, sortDirection);

  const displayAlert = (props: any) => {
    setAlert(
      <SweetAlert
        {...props}
        showCancel
        confirmBtnText="Continue"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => {
          removeVendor(props.vendor.id);
          setAlert(null);
        }}
        onCancel={() => {
          setAlert(null);
        }}
        focusCancelBtn
      >
        This vendor will be removed from the system.
      </SweetAlert>
    );
  };

  return (
    <>
      {Notification}
      {alert}
      <Container className="mt-4" fluid>
        <Row>
          <Col>
            <h1>Vendors</h1>
          </Col>
        </Row>
        <Row className="mt-4">
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col xs="6">
                    <h3 className="mb-0">Vendors</h3>
                  </Col>
                  {getPermission(user, "locations", "create") && (
                    <Col className="text-right" xs="6">
                      <Link
                        to={"/admin/vendors/new"}
                        className=" btn btn-round btn-dark btn-sm"
                        color="default"
                        id="tooltip969372949"
                      >
                        <span className="btn-inner--text">Add vendor</span>
                      </Link>
                      <UncontrolledTooltip delay={0} target="tooltip969372949">
                        Add vendor
                      </UncontrolledTooltip>
                    </Col>
                  )}
                </Row>
                <Row className="mt-4">
                  <Col>
                    <Input
                      className="w-25"
                      placeholder="Search keyword..."
                      onChange={(e) => {
                        setSearchKey(e.target.value);
                        setPage(0);

                        const routeData: VendorRouteData =
                          getLocalStorage() || {};
                        routeData.keyWord = e.target.value;
                        routeData.page = 0;
                        setLocalStorage(routeData);
                      }}
                    ></Input>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush">
                <thead className="thead-light">
                  <tr>
                    <th
                      onClick={() => {
                        setSortField("name");
                        setSortDirection(
                          sortDirection === "asc" ? "desc" : "asc"
                        );
                      }}
                      className="sort"
                      data-sort="name"
                      scope="col"
                    >
                      Name
                    </th>
                    <th
                      onClick={() => {
                        setSortField("description");
                        setSortDirection(
                          sortDirection === "asc" ? "desc" : "asc"
                        );
                      }}
                      className="sort"
                      data-sort="description"
                      scope="col"
                    >
                      Description
                    </th>
                    <th
                      onClick={() => {
                        setSortField("registrationNumber");
                        setSortDirection(
                          sortDirection === "asc" ? "desc" : "asc"
                        );
                      }}
                      className="sort"
                      data-sort="registration_number"
                      scope="col"
                    >
                      Registration number
                    </th>
                    <th
                      onClick={() => {
                        setSortField("vatNumber");
                        setSortDirection(
                          sortDirection === "asc" ? "desc" : "asc"
                        );
                      }}
                      className="sort"
                      data-sort="vat_number"
                      scope="col"
                    >
                      Vat number
                    </th>
                    <th
                      onClick={() => {
                        setSortField("status");
                        setSortDirection(
                          sortDirection === "asc" ? "desc" : "asc"
                        );
                      }}
                      className="sort"
                      data-sort="status"
                      scope="col"
                    >
                      Status
                    </th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody className="list">
                  {filteredVendors
                    .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                    .map((vendor: any) => {
                      return (
                        <tr
                          key={vendor.id}
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            navigate(`/admin/vendors/manage/${vendor.id}`)
                          }
                        >
                          <td>{`${vendor.name}`}</td>
                          <td>{vendor.description}</td>
                          <td>{vendor.registrationNumber}</td>
                          <td>{vendor.vatNumber}</td>
                          <td>{vendor.status.title}</td>
                          <td className="text-right">
                            {getPermission(user, "locations", "update") && (
                              <Link
                                className="btn btn-info btn-icon-only rounded-circle btn-sm"
                                to={`/admin/vendors/manage/${vendor.id}`}
                              >
                                <i className="fa-solid fa-pencil"></i>
                              </Link>
                            )}
                            {getPermission(user, "locations", "delete") && (
                              <Button
                                className="btn btn-danger btn-icon-only rounded-circle btn-sm"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  displayAlert({ warning: true, vendor });
                                }}
                              >
                                <i className="fa-solid fa-trash-can"></i>
                              </Button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <Row>
                  <div className="col-4 d-flex">
                    <span style={{ fontSize: "12px" }}>Rows per page:</span>
                    <BasicMenu
                      getValue={getRowValue}
                      options={["10", "20", "100"]}
                      value={rowPerPage.toString()}
                    />
                  </div>
                  <div className="col-4 d-flex">
                    <span className="min-text">Page:</span>
                    <span className="min-text pl-2">
                      {page + 1} of{" "}
                      {Math.ceil(filteredVendors.length / rowPerPage)}
                    </span>
                  </div>
                  <div className="col-4 d-flex">
                    <Pagination>
                      <PaginationItem className={page <= 0 ? "disabled" : ""}>
                        <PaginationLink onClick={(e) => previousPage()}>
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem
                        className={
                          page >=
                          Math.ceil(filteredVendors.length / rowPerPage) - 1
                            ? "disabled"
                            : ""
                        }
                      >
                        <PaginationLink onClick={(e) => nextPage()}>
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </div>
                </Row>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
