import { gql } from '@apollo/client';

export const GET_QUOTES = gql`
    query getQuotes {
        quote(where: { deleted_at: { _is_null: true } }) {
            id
            createdAt: created_at
            status: quote_quote_status {
                value
                title
            }
            reference: quote_reference
            type: quote_quote_type {
                value
                title
            }
            client {
                id
                companyName: company_name
                individualName: individual_name
            }
        }
    }
`;

export const REMOVE_QUOTE = gql`
    mutation removeQuote($id: uuid!) {
        update_quote_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const GET_QUOTE_FORM_INFO = gql`
    query getQuoteFormInfo {
        clients: user_account {
            id
            companyName: company_name
        }

        status: quote_status {
            value
            title
        }
        quoteTypes: quote_type {
            value
            title
        }
    }
`;

export const ADD_QUOTE = gql`
    mutation addQuote($quote: quote_insert_input!) {
        quote: insert_quote_one(object: $quote) {
            id
        }
    }
`;

export const UPDATE_QUOTE = gql`
    mutation updateQuote($id: uuid!, $quote: quote_set_input!) {
        update_quote_by_pk(pk_columns: { id: $id }, _set: $quote) {
            id
        }
    }
`;

export const GET_QUOTE_DETAILS = gql`
    query getQuote($id: uuid!) {
        quote: quote_by_pk(id: $id) {
            id
            createdAt: created_at
            status: quote_quote_status {
                value
                title
            }
            nominatedMembers: nominated_members(where: { deleted_at: { _is_null: true } }) {
                id
                member {
                    id
                    user {
                        name
                        surname
                    }
                }
            }
            client {
                id
                isColabMember: is_colab_member
                members: team_members(where: { deleted_at: { _is_null: true }, send_communication: { _eq: true } }) {
                    id
                    user {
                        name
                        surname
                    }
                }
            }
            order {
                id
            }
            reference: quote_reference
            items: quote_items(
                where: {
                    _and: { deleted_at: { _is_null: true } }
                    quote_item_booking: { status_enum: { _nin: [transferred, expired] } }
                }
                order_by: { quote_item_booking: { time_from: asc } }
            ) {
                id
                price
                discount
                quoteId: quote_id
                quotedPrice: quoted_price
                totalPrice: total_price
                quantity
                booking: quote_item_booking {
                    id
                    reference
                    to: time_to
                    from: time_from
                    requiresVerification: requires_verification
                    quotedPrice: quoted_price
                    totalPrice: total_price
                    price
                    discount
                    status {
                        value
                        title
                    }
                    subject
                    bookingReference: booking_number
                    roomExtras: room_extras {
                        id
                        quantity
                        rate
                        totalPrice: total_price
                    }
                    bookingResources: booking_after_hour_resources {
                        id
                        bookingId: booking_id
                        name
                        quantity
                        rate
                        resourceId: resource_id
                        totalPrice: total_price
                    }
                    menuPackages: menu_packages {
                        id
                        unitPrice: price
                        vendorName: vendor_name
                        totalPrice: total_price
                        menuPackageName: menu_package_name
                        menuPackageId: menu_package_id
                        featuredImage: featured_image
                        vendorId: vendor_id
                        quantity
                    }
                    meetingRoomItem: meeting_room_item {
                        name
                        featuredImage: featured_image
                        meetingRoom: meeting_room {
                            id
                            capacity
                            rates {
                                type: rate_type {
                                    value
                                    title
                                }
                                price
                            }
                        }
                    }
                }
            }
            type: quote_quote_type {
                value
                title
            }
            client {
                id
                vatNumber: vat_number
                companyName: company_name
                user {
                    name
                    surname
                    email
                    contactNumber: contact_number
                }
            }
        }
        quoteItems: quote_item(where: { deleted_at: { _is_null: true } }) {
            id
            booking: quote_item_booking {
                id
            }
        }
    }
`;

export const ADD_QUOTE_ITEM = gql`
    mutation InsertQuoteItemToQuote($item: quote_item_insert_input!) {
        insert_quote_item_one(
            object: $item
            on_conflict: {
                constraint: quote_item_booking_id_key
                update_columns: [discount, currency, price, quantity, quoted_price, total_price, unit]
            }
        ) {
            id
        }
    }
`;

export const REMOVE_QUOTE_ITEM = gql`
    mutation removeQuoteItem($id: uuid!) {
        update_quote_item_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const GENERATE_QUOTE_PDF = gql`
    mutation generateQuoteDocument($quoteInfo: QuoteInput!) {
        response: generate_quote_pdf(quoteInfo: $quoteInfo) {
            pdf
        }
    }
`;

export const GENERATE_PROFORMA_INVOICE = gql`
    mutation GenerateProformaInvoice($quoteId: uuid!) {
        proformaInvoice: generate_proforma_invoice_pdf(quote_id: $quoteId) {
            pdf
        }
    }
`;

export const ADD_NOMINATED_MEMBER = gql`
    mutation AddNominatedMember($member: quote_nominated_member_insert_input!) {
        member: insert_quote_nominated_member_one(object: $member) {
            id
        }
    }
`;

export const REMOVE_NOMINATED_QUOTE_MEMBER = gql`
    mutation RemoveNominatedQuoteMember($id: uuid!) {
        member: update_quote_nominated_member_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const SEND_CLIENT_QUOTE = gql`
    mutation SendClientQuoteEmail($quoteId: String!) {
        email: send_quote_email(quote_id: $quoteId) {
            message
        }
    }
`;
