import { gql } from '@apollo/client';

export const SEARCH_POSTS = gql`
    query SearchPosts($query: String) {
        posts: newsletter_post(
            where: { title: { _ilike: $query }, deleted_at: { _is_null: true } }
            order_by: { title: asc }
            limit: 10
        ) {
            id
            title
        }
    }
`;

export const ADD_NEWSLETTER_EMAIL = gql`
    mutation AddNewsletterEmail($emailValues: newsletter_email_insert_input!) {
        newsletter: insert_newsletter_email_one(object: $emailValues) {
            id
        }
    }
`;
export const GET_EMAIL_STATUS = gql`
    query getEmailStatus {
        status: newsletter_email_status {
            value
            title
        }
        templates: email_template(where: { deleted_at: { _is_null: true } }) {
            id
            name
        }
        mailLists: mail_list(where: { deleted_at: { _is_null: true } }) {
            id
            name
        }
    }
`;

export const GET_NEWSLETTER_EMAILS = gql`
    query getNewsletterEmails {
        newsLetterEmail: newsletter_email(where: { deleted_at: { _is_null: true } }) {
            id
            title
            subject
            status: newsletter_emails_status {
                value
                title
            }
            publishDate: publish_date
            template {
                id
                name
            }
            mailList: mail_list {
                id
                name
            }
        }
    }
`;

export const GET_NEWSLETTER_EMAIL = gql`
    query getNewsletterEmail($id: uuid!) {
        newsletterEmail: newsletter_email_by_pk(id: $id) {
            id
            title
            subject
            status: newsletter_emails_status {
                value
                title
            }
            attachments(where: { deleted_at: { _is_null: true } }) {
                id
                path
            }
            publishDate: publish_date
            template {
                id
                name
            }
            mailList: mail_list {
                id
                name
            }
        }
    }
`;

export const UPDATE_NEWSLETTER_EMAIL = gql`
    mutation updateNewsletterEmail(
        $id: uuid!
        $emailValues: newsletter_email_set_input!
        $attachment: [newsletter_email_attachment_insert_input!]!
    ) {
        email: update_newsletter_email_by_pk(pk_columns: { id: $id }, _set: $emailValues) {
            id
        }

        attachment: insert_newsletter_email_attachment(objects: $attachment) {
            returning {
                id
            }
        }
    }
`;

export const REMOVE_NEWSLETTER_EMAIL = gql`
    mutation removeNewsletterEmail($id: uuid!) {
        update_newsletter_email_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const GET_EMAIL_TEMPLATES = gql`
    query GetEmailTemplates {
        templates: email_template(where: { deleted_at: { _is_null: true } }) {
            id
            name
        }
    }
`;

export const REMOVE_EMAIL_TEMPLATE = gql`
    mutation RemoveEmailTemplate($id: uuid!) {
        emailTemplate: update_email_template_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const ADD_EMAIL_TEMPLATE = gql`
    mutation AddEmailTemplate($template: email_template_insert_input!) {
        template: insert_email_template_one(
            object: $template
            on_conflict: { constraint: email_template_pkey, update_columns: [name] }
        ) {
            id
        }
    }
`;

export const GET_EMAIL_TEMPLATE = gql`
    query GetEmailTemplate($id: uuid!) {
        template: email_template_by_pk(id: $id) {
            id
            name
            template
        }
    }
`;

export const UPDATE_EMAIL_TEMPLATE = gql`
    mutation UpdateEmailTemplate($id: uuid!, $template: email_template_set_input!) {
        template: update_email_template_by_pk(pk_columns: { id: $id }, _set: $template) {
            id
        }
    }
`;

export const SEARCH_TEMPLATE_BY_NAME = gql`
    query SearchTemplateByName($name: String!) {
        template: email_template(where: { name: { _ilike: $name }, deleted_at: { _is_null: true } }) {
            id
            name
        }
    }
`;

export const SEARCH_MAIL_LIST_BY_NAME = gql`
    query SearchMailListByName($name: String!) {
        mailList: mail_list(where: { name: { _ilike: $name }, deleted_at: { _is_null: true } }) {
            id
            name
        }
    }
`;

export const REMOVE_FILE_ATTACHMENT = gql`
    mutation RemoveFileAttachment($id: uuid!) {
        attachment: update_newsletter_email_attachment_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;
