import React, { useState } from "react";
import { Col, Row, UncontrolledTooltip } from "reactstrap";
import BookingAddOnsDialog from "./BookingAddOnsDialog";
import { RoomExtra } from "../../../../../models/room.extra.model";
import { BookingRoomExtra } from "../../../../../models/bookings.model";
import BookingAdOnItem from "./BookingAdOnItem";

interface BookingAddOnsViewProps {
  roomExtras?: RoomExtra[];
  bookingHours?: number;
  bookingRoomAdOns: BookingRoomExtra[];
  getBookingRoomAdOns: (addOns: BookingRoomExtra[]) => void;
}

const BookingAddOnsView = ({
  roomExtras,
  bookingHours,
  bookingRoomAdOns,
  getBookingRoomAdOns,
}: BookingAddOnsViewProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const getItemsTotalPrice = (): number => {
    let total = 0;
    bookingRoomAdOns.forEach((resource) => {
      total += resource?.totalPrice;
    });
    return total;
  };

  return (
    <>
      {isOpen && (
        <BookingAddOnsDialog
          isOpen={isOpen}
          handleClose={() => setIsOpen(false)}
          roomExtras={roomExtras}
          bookingHours={bookingHours}
          bookingRoomAdOns={bookingRoomAdOns}
          getBookingRoomAdOns={getBookingRoomAdOns}
        />
      )}
      <Row>
        <Col>
          <Row>
            <Col xs="6">
              <h3 className="mb-0">Selected Add-Ons</h3>
            </Col>
            <Col className="text-right" xs="6">
              <div
                className="btn btn-dark btn-sm"
                color="default"
                id="tooltip969372949"
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                Add
              </div>
              <UncontrolledTooltip delay={0} target="tooltip969372949">
                Add
              </UncontrolledTooltip>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="menu-items">
              {bookingRoomAdOns?.map((addOn) => {
                return (
                  <BookingAdOnItem
                    key={addOn.id}
                    bookingAdOn={addOn}
                    selectedAdOns={bookingRoomAdOns}
                    getBookingAdOns={getBookingRoomAdOns}
                  />
                );
              })}
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <div className="d-flex h-100">
                <div className="align-self-end ml-auto">
                  {bookingRoomAdOns.length > 0 && (
                    <span>
                      Total R{getItemsTotalPrice().toFixed(2)} (excl VAT)
                    </span>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default BookingAddOnsView;
