import { useMutation } from '@apollo/client';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Row } from 'reactstrap';
import { ADD_LEAD, GetLeads } from '../../../graphql/leads.graphql';
import useToast from '../../../hooks/useToast';
import Spinner from '../../../components/Spinner/Spinner';

type FormData = {
    companyName: string;
    companyEmail: string;
    contactName: string;
    contactNumber: string;
    description: string;
    totalPeople: number;
};

export default function LeadAddForm() {
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
    } = useForm<FormData>();

    const [addLead, { loading, data, error }] = useMutation(ADD_LEAD, {
        refetchQueries: [{ query: GetLeads }],
    });
    const { showToast, Notification } = useToast();

    const redirectToLeadsView = (e: any) => {
        e.preventDefault();
        navigate(-1);
    };

    const validatePhoneNumber = (value: any, code: any) => {
        if (!value) {
            return true;
        }

        const phoneNumber = value.replace(/[^\d]/g, '');

        if (phoneNumber.length !== 10 && !phoneNumber.startsWith('0')) {
            return `+${phoneNumber}`;
        }

        if (phoneNumber.startsWith('0')) {
            const formattedNumber = `+${code}${phoneNumber.slice(1)}`;
            return formattedNumber;
        }

        return value;
    };

    const handleOnChange = (value: any, countryData: any) => {
        setValue('contactNumber', validatePhoneNumber(value, countryData.dialCode));
    };

    useEffect(() => {
        if (data) {
            showToast('Successfully added ', 'success');
            reset();
        }
    }, [data]);

    useEffect(() => {
        if (error) {
            showToast('An error has occurred while trying to add a lead', 'danger');
        }
    }, [error]);

    const onSubmit = async (data: FormData) => {
        console.log('Form Data', data);

        const lead = {
            contact_name: data.contactName,
            company_name: data.companyName,
            contact_number: data.contactNumber,
            contact_email: data.companyEmail,
            message: data.description,
            category_enum: 'internal',
            status_enum: 'new',
        };

        await addLead({
            variables: {
                lead: lead,
            },
        });
    };

    return (
        <>
            {Notification}
            <Container className='mt-4' fluid>
                <Row className='mb-4'>
                    <Col>
                        <h1>Add Product Lead</h1>
                    </Col>
                </Row>
                <Row>
                    <Col md='12' lg='6'>
                        <Card>
                            <CardHeader>
                                <Row noGutters={true}>
                                    <button
                                        className='btn btn-outline-primary btn-sm mr-4'
                                        onClick={(e) => redirectToLeadsView(e)}>
                                        <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                                        <span className='btn-inner-text'>Back</span>
                                    </button>
                                    <h3 className='m-0'>Add Lead</h3>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='name'>
                                            Company Name
                                        </label>
                                        <input
                                            {...register('companyName', { required: true })}
                                            className='form-control'
                                            id='product'
                                            placeholder='Enter company name...'
                                            type='text'
                                        />
                                        {errors?.companyName && (
                                            <span className='invalid'>*This field is required</span>
                                        )}
                                    </FormGroup>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='name'>
                                            Company Email
                                        </label>
                                        <input
                                            {...register('companyEmail', { required: true })}
                                            className='form-control'
                                            id='product'
                                            placeholder='Enter contact email...'
                                            type='email'
                                        />
                                        {errors?.companyEmail && (
                                            <span className='invalid'>*This field is required</span>
                                        )}
                                    </FormGroup>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='name'>
                                            Contact Name
                                        </label>
                                        <input
                                            {...register('contactName', { required: true })}
                                            className='form-control'
                                            id='product'
                                            placeholder='Enter contact name...'
                                            type='text'
                                        />
                                        {errors?.contactName && (
                                            <span className='invalid'>*This field is required</span>
                                        )}
                                    </FormGroup>
                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='contact'>
                                            Contact number
                                        </label>
                                        <PhoneInput
                                            {...register('contactNumber', {
                                                required: true,
                                            })}
                                            prefix='+'
                                            placeholder={'+27 (70) 123-4567'}
                                            country='za'
                                            onChange={handleOnChange}
                                        />
                                        {errors?.contactNumber && (
                                            <span className='invalid'>*This field is required</span>
                                        )}
                                    </FormGroup>

                                    <FormGroup>
                                        <label className='form-control-label' htmlFor='contact'>
                                            Description
                                        </label>
                                        <textarea
                                            {...register('description', {
                                                required: true,
                                            })}
                                            className='form-control'
                                            placeholder='Enter a description...'></textarea>
                                        {errors?.description && (
                                            <span className='invalid'>*This field is required</span>
                                        )}
                                    </FormGroup>

                                    <div className='d-flex h-100'>
                                        <div className='align-self-end ml-auto'>
                                            <Button disabled={loading} type='submit' className='btn btn-dark mt-4'>
                                                {loading && <Spinner />}
                                                {!loading && <span>Add Lead</span>}
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
