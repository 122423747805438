/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Container, Row } from "reactstrap";
// import { useHistory } from "react-router-dom";
import PackageDetailView from "./PackageDetailView";
import PackageImageView from "./PackageImageView";
import { GET_PRODUCT_PACKAGE } from "../../../graphql/packages.graphql";
import { useQuery } from "@apollo/client";
import useToast from "../../../hooks/useToast";
import { LinearProgress } from "@mui/material";
import { ProductPackage } from "../../../models/packages.models";
import { useNavigate, useParams } from "react-router-dom";
import { Image } from "../../../models/product.model";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function PackageManageView() {
  const [value, setValue] = React.useState(0);
  const param: any = useParams();
  // const history = useHistory();
  const navigate = useNavigate();
  const { showToast, Notification } = useToast();
  const { loading, error, data } = useQuery(GET_PRODUCT_PACKAGE, {
    variables: {
      id: param.id,
    },
  });
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    if (error) {
      showToast("An error has occurred, please refresh your page", "danger");
    }
  }, [error]);

  if (loading) {
    return <LinearProgress />;
  }

  const _package: ProductPackage = data?.package;
  const _images: Image[] = _package?.images || [];

  return (
    <Container className="mt-4" fluid>
      {Notification}
      <Row className="my-4 ml-1">
        <button
          className="btn btn-outline-primary btn-sm mr-4"
          onClick={() => navigate(-1)}
        >
          <i className="fas fa-angle-left" style={{ fontSize: "14px" }} />
          <span className="btn-inner-text">Back</span>
        </button>
        <h3 className="mb-0">Manage packages</h3>
      </Row>
      <Row className="ml-1">
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                sx={{ textTransform: "capitalize" }}
                label="Details"
                {...a11yProps(0)}
              />
              <Tab
                sx={{ textTransform: "capitalize" }}
                label="Images"
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <PackageDetailView _package={_package} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <PackageImageView images={_images} />
          </TabPanel>
        </Box>
      </Row>
    </Container>
  );
}
