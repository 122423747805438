import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
} from "@mui/material";
import { Row } from "reactstrap";
import {
  MenuPackage,
  MenuType,
  Vendor,
  VendorMenuPackageItem,
} from "../../models/vendor.model";
import VendorMenuPackageItems from "../Booking Vendors/VendorMenuPackageItems";
interface Props {
  isOpen?: boolean;
  vendors?: Vendor[];
  vendorMenuTypes?: MenuType[];
  menuItems?: VendorMenuPackageItem[];
  handleClose: () => void;
  getSelectedItems?: (items: VendorMenuPackageItem[]) => void;
}

const AddCateringMenuOptionDialog = ({
  isOpen,
  vendors,
  handleClose,
  menuItems,
  vendorMenuTypes,
  getSelectedItems,
}: Props) => {
  const [menuPackage, setMenuPackages] = useState<MenuPackage[]>([]);
  const [menuType, setMenuType] = useState<string>();
  const [selectedVendor, setSelectedVendor] = useState<Vendor>();
  const [selectedMenuItems, setSelectedMenuItems] = useState<
    VendorMenuPackageItem[]
  >([]);

  const onAddItems = () => {
    const items = [...menuItems];

    selectedMenuItems
      .filter((item) => item.totalPrice > 0)
      .forEach((item) => {
        items.push(item);
      });

    getSelectedItems(items);
    handleClose();
  };

  const cateringVendors: Vendor[] = vendors;
  const menuTypes = [...vendorMenuTypes, { value: "drinks", title: "Drinks" }];
  const filteredMenuPackages: MenuPackage[] = menuPackage.filter((menu) => {
    return (
      menuItems.findIndex((item) => item?.menuPackageId === menu?.id) === -1
    );
  });

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>Add menu option</DialogTitle>
      <DialogContent
        sx={{
          width: 600,
        }}
      >
        <Row>
          <div className="col-8">
            <Select
              sx={{
                width: "100%",
              }}
              variant="standard"
              defaultValue={"empty"}
              onChange={(e) => {
                const vendor = cateringVendors.find(
                  (vendor) => vendor.id === e.target.value
                );
                setSelectedVendor(vendor);
                setMenuPackages(vendor?.MenuPackages);
              }}
            >
              <MenuItem disabled value="empty">
                <span className="placeholder-text"> Select a vendor...</span>
              </MenuItem>
              {cateringVendors.map((vendor) => {
                return (
                  <MenuItem key={vendor?.id} value={vendor?.id}>
                    {vendor?.name}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <div className="col-4">
            <Select
              sx={{
                width: "100%",
              }}
              variant="standard"
              defaultValue={"empty"}
              value={menuType}
              onChange={(e) => {
                setMenuType(e.target.value);
              }}
            >
              <MenuItem disabled value={"empty"}>
                <span className="placeholder-text">Select a meal type...</span>
              </MenuItem>
              {menuTypes?.map((type) => {
                return (
                  <MenuItem key={type.value} value={type.value}>
                    {type.title}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        </Row>
        <Row>
          <div className="mt-4">
            {filteredMenuPackages
              .filter((menu) => {
                if (menu.menuPackageType.value === menuType) {
                  return menu;
                }

                if (menuType) {
                  return (
                    menu?.menuMealType && menu?.menuMealType.value === menuType
                  );
                }
                return menu;
              })
              ?.map((menu) => {
                return (
                  <VendorMenuPackageItems
                    key={menu?.id}
                    selectedVendor={selectedVendor}
                    selectedMenuItems={selectedMenuItems}
                    menuPackage={menu}
                    getItems={setSelectedMenuItems}
                  />
                );
              })}
          </div>
        </Row>
      </DialogContent>
      <DialogActions>
        <div className="mt-4 d-flex h-100 p-2">
          <div className="align-self-end ml-auto">
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={onAddItems}
              disabled={
                selectedMenuItems.filter((item) => item.totalPrice > 0)
                  .length === 0
              }
              type="submit"
            >
              Add
            </Button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default AddCateringMenuOptionDialog;
