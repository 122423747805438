/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table,
    UncontrolledTooltip,
} from 'reactstrap';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { LinearProgress } from '@mui/material';
import _ from 'lodash';
import { getPermission } from '../../../../utils/user.utils';
import { User } from '../../../../models/user.model';
import BasicMenu from '../../../../components/Menu';
import { GET_MAIL_LISTS, REMOVE_MAIL_LIST } from '../../../../graphql/mail-list.graphql';
import useToast from '../../../../hooks/useToast';
import { MailList } from '../../../../models/newsletter-emil.model';
import SweetAlert from 'react-bootstrap-sweetalert';
// import LoaderDialog from '../../quotes/dialogs/LoaderDialog';

export default function MailListView() {
    const user: User | null = useSelector((state: any) => state.user.userInfo);
    const [searchKey, setSearchKey] = useState<string>('');
    const [page, setPage] = useState<number>(0);
    const [rowPerPage, setRowPerPage] = useState<number>(10);
    const [sortFiled, setSortField] = useState<string>('name');
    const [sortDirection, setSortDirection] = useState<string>('desc');
    const { showToast, Notification } = useToast();
    const [alert, setAlert] = useState<any>(null);
    const { data, loading, error } = useQuery(GET_MAIL_LISTS, {
        fetchPolicy: 'network-only',
    });

    const [removeMailList, { error: removeError, data: removedMailList }] = useMutation(REMOVE_MAIL_LIST, {
        refetchQueries: [GET_MAIL_LISTS],
    });

    const navigate = useNavigate();

    const sortBy: any = (mailLists: MailList[], field: string, direction: string) => {
        const orderedBookings = new Map([
            ['name', _.orderBy(mailLists, (mail) => mail.name, [direction === 'asc' ? 'asc' : 'desc'])],
            ['status', _.orderBy(mailLists, (mail) => mail.status.title, [direction === 'asc' ? 'asc' : 'desc'])],
        ]);
        return orderedBookings.get(field);
    };

    useEffect(() => {
        if (error) {
            showToast('An error has occurred while trying to load mail lists', 'danger');
        }
    }, [error]);

    useEffect(() => {
        if (removeError) {
            showToast('An error has occurred while trying to remove mail list', 'danger');
        }
    }, [removeError]);

    useEffect(() => {
        if (removedMailList) {
            showToast('Successfully removed mail list', 'success');
        }
    }, [removedMailList]);

    const nextPage = () => {
        setPage(page + 1);
    };

    const previousPage = () => {
        setPage(page - 1);
    };

    const getRowValue = (value: string) => {
        setRowPerPage(parseInt(value));
    };

    const displayAlert = (props: any) => {
        setAlert(
            <SweetAlert
                {...props}
                showCancel
                confirmBtnText='Continue'
                confirmBtnBsStyle='danger'
                title='Are you sure?'
                onConfirm={async () => {
                    removeMailList({
                        variables: {
                            id: props.email.id,
                        },
                    });
                    setAlert(null);
                }}
                onCancel={() => {
                    setAlert(null);
                }}
                focusCancelBtn>
                This mail list will be removed from the system.
            </SweetAlert>,
        );
    };

    if (loading) {
        return <LinearProgress />;
    }

    let filteredMailLists: MailList[] = data?.mailList.filter((mail: MailList) => {
        if (searchKey === '') {
            return mail;
        }
        return (
            mail.name.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            mail.status.title.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase())
        );
    });

    filteredMailLists = sortBy(filteredMailLists, sortFiled, sortDirection);

    return (
        <>
            {alert}
            {Notification}
            {/* <LoaderDialog isOpen={sending} /> */}
            <Container className='mt-4' fluid>
                <Row>
                    <Col>
                        <h1>Mail Lists</h1>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <div className='col'>
                        <Card>
                            <CardHeader className='border-0'>
                                <Row>
                                    <Col xs='6'>
                                        <h3 className='mb-0'>Mail Lists</h3>
                                    </Col>
                                    {getPermission(user, 'newsletter', 'create') && (
                                        <Col className='text-right' xs='6'>
                                            <Link
                                                to={'/admin/mail-list/new'}
                                                className=' btn btn-round btn-dark btn-sm'
                                                color='default'
                                                id='tooltip969372949'>
                                                <span className='btn-inner--text'> Add mail list</span>
                                            </Link>
                                            <UncontrolledTooltip delay={0} target='tooltip969372949'>
                                                Add mail list
                                            </UncontrolledTooltip>
                                        </Col>
                                    )}
                                </Row>
                                <Row className='mt-4'>
                                    <Col>
                                        <Input
                                            className='w-25'
                                            placeholder='Search keyword...'
                                            onChange={(e) => {
                                                setSearchKey(e.target.value);
                                                setPage(0);
                                            }}></Input>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <Table className='align-items-center table-flush'>
                                <thead className='thead-light'>
                                    <tr>
                                        <th
                                            onClick={() => {
                                                setSortField('name');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='title'
                                            scope='col'>
                                            Title
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('status');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='status'
                                            scope='col'>
                                            Status
                                        </th>
                                        <th scope='col'></th>
                                    </tr>
                                </thead>
                                <tbody className='list'>
                                    {filteredMailLists
                                        .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                                        .map((email: MailList) => {
                                            return (
                                                <tr
                                                    key={email.id}
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                        navigate(`/admin/mail-list/manage/${email.id}`);
                                                    }}>
                                                    <td>{`${email.name}`}</td>
                                                    <td>{email.status.title}</td>
                                                    <td className='text-right'>
                                                        {getPermission(user, 'newsletter', 'update') && (
                                                            <Link
                                                                className='btn btn-info btn-icon-only rounded-circle btn-sm'
                                                                to={`/admin/mail-list/manage/${email.id}`}>
                                                                <i className='fa-solid fa-pencil'></i>
                                                            </Link>
                                                        )}

                                                        {getPermission(user, 'locations', 'delete') && (
                                                            <Button
                                                                className='btn btn-danger btn-icon-only rounded-circle btn-sm'
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    displayAlert({ warning: true, email });
                                                                }}>
                                                                <i className='fa-solid fa-trash-can'></i>
                                                            </Button>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </Table>
                            <CardFooter className='py-4'>
                                <Row>
                                    <div className='col-4 d-flex'>
                                        <span style={{ fontSize: '12px' }}>Rows per page:</span>
                                        <BasicMenu
                                            getValue={getRowValue}
                                            options={['10', '20', '100']}
                                            value={rowPerPage.toString()}
                                        />
                                    </div>
                                    <div className='col-4 d-flex'>
                                        <span className='min-text'>Page:</span>
                                        <span className='min-text pl-2'>
                                            {page + 1} of {Math.ceil(filteredMailLists.length / rowPerPage)}
                                        </span>
                                    </div>
                                    <div className='col-4 d-flex'>
                                        <Pagination>
                                            <PaginationItem className={page <= 0 ? 'disabled' : ''}>
                                                <PaginationLink onClick={(e) => previousPage()}>
                                                    <i className='fas fa-angle-left' />
                                                    <span className='sr-only'>Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem
                                                className={
                                                    page >= Math.ceil(filteredMailLists.length / rowPerPage) - 1
                                                        ? 'disabled'
                                                        : ''
                                                }>
                                                <PaginationLink onClick={(e) => nextPage()}>
                                                    <i className='fas fa-angle-right' />
                                                    <span className='sr-only'>Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                    </div>
                                </Row>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
}
