import { gql } from "@apollo/client";

export const GET_MEETING_ROOM_CHECKLIST_ITEMS = gql`
  query getMeetingRoomCheckListItems {
    checkListItems: meeting_room_checklist_item(
      where: { deleted_at: { _is_null: true } }
    ) {
      id
      name
      description
      featuredImage: featured_image
    }
  }
`;

export const REMOVE_MEETING_ROOM_CHECKLIST_ITEM = gql`
  mutation removeCheckListItem($id: uuid!) {
    update_meeting_room_checklist_item_by_pk(
      pk_columns: { id: $id }
      _set: { deleted_at: now }
    ) {
      id
    }
  }
`;

export const ADD_MEETING_ROOM_CHECKLIST_ITEM = gql`
  mutation AddCheckListItem($item: meeting_room_checklist_item_insert_input!) {
    insert_meeting_room_checklist_item_one(
      object: $item
      on_conflict: {
        constraint: meeting_room_checklist_item_pkey
        update_columns: [featured_image, description, name]
      }
    ) {
      id
    }
  }
`;

export const ASSIGN_CHECKLIST_ITEM = gql`
  mutation assignChecklistToMeetingRoomItem(
    $item: meeting_room_checklist_add_on_insert_input!
  ) {
    insert_meeting_room_checklist_add_on_one(object: $item) {
      id
    }
  }
`;

export const REMOVE_CHECKLIST_ADD_ON = gql`
  mutation removeChecklistAddOn($id: uuid!) {
    update_meeting_room_checklist_add_on_by_pk(
      pk_columns: { id: $id }
      _set: { deleted_at: now }
    ) {
      id
    }
  }
`;
