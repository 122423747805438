// import * as React from 'react';
import React, { useState, MouseEvent } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
interface MenuProps {
  getValue: any;
  options: string[];
  value: string;
  onChange?: () => void;
}
export default function BasicMenu({
  getValue,
  options,
  value,
  onChange,
}: MenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e: any) => {
    if (e.target.innerText !== "") {
      getValue(e.target.innerText);
      if (onChange) onChange();
    }
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        style={{
          color: "#000",
          fontSize: "12px",
          marginTop: "-9px",
          textTransform: "capitalize",
          fontWeight: "Bold",
        }}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {value}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {options.map((option: any) => {
          return (
            <MenuItem key={option} onClick={handleClose}>
              {option}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
