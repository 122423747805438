/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography, Tab, Tabs, LinearProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Row } from "reactstrap";
import { Vendor, VendorEquipmentItem } from "../../../models/vendor.model";
import { useQuery } from "@apollo/client";
import { GET_VENDOR } from "../../../graphql/vendors.graphql";
import useToast from "../../../hooks/useToast";
import EditVendorForm from "./EditVendorForm";
import VendorMenuDetailView from "./PackageListView";
import VendorEquipmentItemView from "./components/VendorEqipmentItemView";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function VendorManageView() {
  const [value, setValue] = React.useState(0);
  // const history = useHistory();
  const navigate = useNavigate();
  const param: any = useParams();
  const { showToast, Notification } = useToast();

  const { data, loading, error } = useQuery(GET_VENDOR, {
    variables: {
      id: param.id,
    },
  });

  useEffect(() => {
    if (error) {
      showToast("An error has occurred, please refresh your page", "danger");
    }
  }, [error]);

  if (loading) {
    return <LinearProgress />;
  }

  const vendor: Vendor = data?.vendorDetails;
  const vendorEquipmentItems: VendorEquipmentItem[] = vendor?.equipmentItems;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Container className="mt-4" fluid>
      {Notification}
      <Row className="my-4 ml-1">
        <button
          className="btn btn-outline-primary btn-sm mr-4"
          onClick={() => navigate(-1)}
        >
          <i className="fas fa-angle-left" style={{ fontSize: "14px" }} />
          <span className="btn-inner-text">Back</span>
        </button>
        <h3 className="mb-0">Manage vendors</h3>
      </Row>
      <Row className="ml-1">
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                sx={{ textTransform: "capitalize" }}
                label="Details"
                {...a11yProps(0)}
              />
              {vendor?.type.value === "catering" && (
                <Tab
                  sx={{ textTransform: "capitalize" }}
                  label="Menu"
                  {...a11yProps(1)}
                />
              )}
              {vendor?.type.value === "equipment" && (
                <Tab
                  sx={{ textTransform: "capitalize" }}
                  label="Equipment items"
                  {...a11yProps(1)}
                />
              )}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <EditVendorForm vendor={vendor} />
          </TabPanel>
          {vendor?.type.value === "catering" && (
            <TabPanel value={value} index={1}>
              <VendorMenuDetailView />
            </TabPanel>
          )}
          {vendor?.type.value === "equipment" && (
            <TabPanel value={value} index={1}>
              <VendorEquipmentItemView
                vendorEquipmentItems={vendorEquipmentItems || []}
              />
            </TabPanel>
          )}
        </Box>
      </Row>
    </Container>
  );
}
