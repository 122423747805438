import { Box, Tab, Tabs, Typography } from '@mui/material';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import SurveyEditFormView from './components/SurveyEditFormView';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box sx={{ p: 0 }}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default function SurveyTabView() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const navigate = useNavigate();

    return (
        <>
            <Container className='mt-4' fluid>
                <Row className='mx-2'>
                    <Col>
                        <Row className='pb-4'>
                            <button
                                className='btn btn-outline-primary btn-sm mr-4'
                                onClick={(e) => {
                                    navigate(-1);
                                }}>
                                <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                                <span className='btn-inner-text'>Back</span>
                            </button>
                            <h3 className='m-0'>Manage Survey</h3>
                        </Row>
                        <Row>
                            <Box sx={{ width: '100%' }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
                                        <Tab sx={{ textTransform: 'capitalize' }} label='Details' {...a11yProps(0)} />
                                    </Tabs>
                                </Box>
                                <Box>
                                    <TabPanel value={value} index={0}>
                                        <SurveyEditFormView />
                                    </TabPanel>
                                </Box>
                            </Box>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
