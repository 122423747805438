import React, { useEffect, useState } from "react";

import { Button, Card, CardBody, Table } from "reactstrap";

import SweetAlert from "react-bootstrap-sweetalert";
import {
  GET_CUSTOM_PRICING_DATA,
  REMOVE_CUSTOM_PACKAGE_PRICE,
} from "../../../../graphql/clients.graphql";
import { useMutation } from "@apollo/client";
import { AddCustomPackagePriceDialog } from "../dialogs/AddCustomPackagePriceDialog";
import {
  BasicPackage,
  CustomPackagePrice,
} from "../../../../models/client.model";
import useToast from "../../../../hooks/useToast";

interface CustomConferencePricingProps {
  packages: BasicPackage[];
  packagePrices: CustomPackagePrice[];
  accountId: string;
}

const CustomPackagePricing = ({
  packages,
  packagePrices,
  accountId,
}: CustomConferencePricingProps) => {
  const [selectedPrice, setSelectedPrice] = useState<CustomPackagePrice | null>(
    null
  );
  const [openAddRate, setOpenAddRate] = useState(false);
  const { showToast, Notification } = useToast();
  const [alert, setAlert] = useState<any>(null);

  const [
    removeCustomPrice,
    {
      error: errorRemoving,
      called: calledRemovePrice,
      loading: isRemovingPrice,
      reset: resetRemoving,
    },
  ] = useMutation(REMOVE_CUSTOM_PACKAGE_PRICE, {
    refetchQueries: [
      { query: GET_CUSTOM_PRICING_DATA, variables: { accountId } },
    ],
  });

  useEffect(() => {
    if (calledRemovePrice && !isRemovingPrice) {
      resetRemoving();
      showToast("Successfully removed price", "success");
    }
    if (calledRemovePrice && errorRemoving) {
      showToast("An error has occurred, please try again", "danger");
    }
  }, [
    errorRemoving,
    calledRemovePrice,
    isRemovingPrice,
    resetRemoving,
    showToast,
  ]);

  const editPackagePrice = (rate: CustomPackagePrice) => {
    setSelectedPrice(rate);
    setOpenAddRate(true);
  };

  const displayRemoveRateAlert = (props: any) => {
    setAlert(
      <SweetAlert
        {...props}
        showCancel
        confirmBtnText="Continue"
        confirmBtnBsStyle="danger"
        title="Are you sure? This action cannot be undone"
        onConfirm={() => {
          setAlert(null);
          removeCustomPrice({
            variables: {
              id: props.packagePrice.id,
            },
          });
        }}
        onCancel={() => {
          setAlert(null);
        }}
        focusCancelBtn
      >
        This custom price will be removed from the system.
      </SweetAlert>
    );
  };

  return (
    <>
      {Notification}
      {alert}
      {
        <AddCustomPackagePriceDialog
          isOpen={openAddRate}
          packages={packages}
          accountId={accountId}
          customPrice={selectedPrice}
          toggleOpen={(isOpen) => {
            setOpenAddRate(isOpen);
          }}
        />
      }
      <Card>
        <CardBody>
          <div className="row no-gutters justify-content-between align-items-center mb-3">
            <h3 className="mb-0">Package Prices</h3>
            <button
              className="btn btn-dark btn-sm"
              onClick={() => {
                setSelectedPrice(null);
                setOpenAddRate(true);
              }}
            >
              Add custom price
            </button>
          </div>
          {packagePrices.length === 0 && (
            <h5>No rates added for this client</h5>
          )}
          {packagePrices.length > 0 && (
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Package</th>
                  <th scope="col">Price (excl VAT)</th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody className="list">
                {packagePrices.map((packagePrice) => {
                  return (
                    <tr
                      key={packagePrice.id}
                      style={{ cursor: "pointer" }}
                      onClick={() => editPackagePrice(packagePrice)}
                    >
                      <td>{packagePrice.package.name}</td>
                      <td>{`${
                        packagePrice.currencyCode === "zar" ? "R" : "R"
                      }${packagePrice.price.toFixed(2)}`}</td>
                      <td className="text-right">
                        <Button
                          className="btn btn-info btn-icon-only rounded-circle btn-sm"
                          onClick={() => {
                            editPackagePrice(packagePrice);
                          }}
                        >
                          <i className="fa-solid fa-pencil"></i>
                        </Button>
                        <Button
                          className="btn btn-danger btn-icon-only rounded-circle  btn-sm"
                          onClick={(e) => {
                            e.stopPropagation();
                            displayRemoveRateAlert({
                              warning: true,
                              packagePrice,
                            });
                          }}
                        >
                          <i className="fa-regular fa-trash-can"></i>
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default CustomPackagePricing;
