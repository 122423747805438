import AddLocationForm from './views/pages/administration/AddLocationForm';
import AddUserForm from './views/pages/administration/AddUserForm';
import EditUserForm from './views/pages/administration/EditUserForm';
import TabPanel from './views/pages/administration/TabPanel';
import UserManager from './views/pages/administration/UserManager';
import BookingsView from './views/pages/booking/BookingsView';
import Alternative from './views/pages/dashboards/Alternative';
import ClientView from './views/pages/clients/ClientView';
import ClientAddForm from './views/pages/clients/ClientAddForm';
import ClientManageView from './views/pages/clients/ClientManageView';
import ProductView from './views/pages/administration/ProductView';
import AddProductForm from './views/pages/administration/AddProductForm';
import ProductTabView from './views/pages/administration/ProductTabView';
import PackageView from './views/pages/product-packages/PackageView';
import PackageAddForm from './views/pages/product-packages/PackageAddForm';
import PackageManageView from './views/pages/product-packages/PackageManageView';
import LocationView from './views/pages/locations/LocationView';
import MeetingRoomView from './views/pages/meeting-rooms/MeetingRoomView';
import MeetingRoomTabView from './views/pages/meeting-rooms/MeetingRoomTabView';
import MeetingAddForm from './views/pages/meeting-rooms/MeetingRoomAddForm';
import BookingTable from './views/pages/booking/BookingTable';
import { OrdersList } from './views/pages/orders/OrdersList';
import { OrderDetails } from './views/pages/orders/OrderDetails';
import { LeadsList } from './views/pages/leads/LeadsList';
import { LeadDetails } from './views/pages/leads/LeadDetails';
import VendorsView from './views/pages/vendors/VendorsView';
import AddVendorForm from './views/pages/vendors/CreateVendorForm';
import VendorManageView from './views/pages/vendors/VendorManageView';
import QuoteListView from './views/pages/quotes/QuotesListView';
import QuoteDetails from './views/pages/quotes/QuoteDetailView';
import SubscribersView from './views/pages/subscribers/SubscribersView';
import BookingVerifications from './views/pages/booking/BookingVerifications';
import VerificationDetails from './views/pages/booking/VerificationDetails';
import OrderExports from './views/pages/orders/OrderExports';
import TaskListView from './views/pages/tasks/TaskListView';
import TaskAddView from './views/pages/tasks/TaskAddView';
import TaskDetailView from './views/pages/tasks/TaskDetailView';
import VoucherListView from './views/pages/vouchers/VoucherListView';
import VoucherAddView from './views/pages/vouchers/VoucherAddView';
import VoucherDetailsView from './views/pages/vouchers/VoucherDetailsView';
import PackageBookingListView from './views/pages/package-bookings/PackageBookingsListView';
import PackageBookingDetails from './views/pages/package-bookings/PackageBookingDetails';
import PackageBookingCalendarView from './views/pages/package-bookings/PackageBookingCalendarView';
import BookingFullCalendarView from './views/pages/booking/BookingFullCalendarView';
import LeadAddForm from './views/pages/leads/LeadAddForm';
import ResourceListView from './views/pages/resources/ResourceListView';
import ResourceAddFormView from './views/pages/resources/ResourceAddFormView';
import MailListView from './views/pages/newsletters/email-list/MailListView';
import MailListAddFormView from './views/pages/newsletters/email-list/MailListAddFormView';
import MailListDetails from './views/pages/newsletters/email-list/MailListDetails';
import EmailTemplateListView from './views/pages/newsletters/email-templates/EmailTemplateListView';
import TemplateView from './views/pages/newsletters/email-templates/TemplateView';
import EmailView from './views/pages/newsletters/EmailView';
import EmailAddForm from './views/pages/newsletters/EmailAddForm';
import ManageEmailView from './views/pages/newsletters/MangeEmailView';
import ConferenceBookingsDashboard from './views/pages/booking/ConferenceBookingsDashboard';
import DailyConferenceIncomeView from './views/pages/dashboards/DailyConferenceIncomeView';

import SurveyListView from './views/pages/survey/SurveyListView';
import SurveyAddFormView from './views/pages/survey/SurveyAddFormView';
import SurveyTabView from './views/pages/survey/SurveyTabView';

import ResourceTabView from './views/pages/resources/components/ResourceTabView';
import ServiceProvidersListView from './views/pages/service-providers/ServiceProvidersListView';
import ServiceProviderAddFormView from './views/pages/service-providers/ServiceProviderAddFormView';
import ServiceProviderTabView from './views/pages/service-providers/ServiceProviderTabView';

const routes = [
    {
        collapse: true,
        name: 'Dashboard',
        icon: 'ni ni-shop text-primary',
        state: 'dashboardsCollapse',
        permission: 'main_dashboard',
        views: [
            {
                path: '/dashboard',
                name: 'Main',
                miniName: 'U',
                layout: '/admin',
                permission: 'main_dashboard',
                children: [
                    {
                        path: '/',
                        component: Alternative,
                    },
                ],
            },
            {
                path: '/cumulative-income-dashboard',
                name: 'Conference Cumulative',
                miniName: 'D',
                layout: '/admin',
                permission: 'bookings',
                children: [
                    {
                        path: '/',
                        component: ConferenceBookingsDashboard,
                    },
                ],
            },
            {
                path: '/daily-income-dashboard',
                name: 'Conference Daily',
                miniName: 'D',
                layout: '/admin',
                permission: 'bookings',
                children: [
                    {
                        path: '/',
                        component: DailyConferenceIncomeView,
                    },
                ],
            },
        ],
    },
    {
        collapse: true,
        name: 'Conference',
        icon: 'fa-solid fa-book',
        state: 'bookingCollapse',
        permission: 'bookings',
        views: [
            {
                path: '/booking-calendar',
                name: 'Calendar',
                miniName: 'C',
                layout: '/admin',
                permission: 'bookings',
                children: [
                    {
                        path: '/',
                        component: BookingsView,
                    },
                ],
            },
            {
                path: '/booking-full-calendar',
                name: 'Full Calendar',
                miniName: 'F',
                layout: '/admin',
                permission: 'bookings',
                children: [
                    {
                        path: '/',
                        component: BookingFullCalendarView,
                    },
                ],
            },
            {
                path: '/booking-list',
                name: 'All bookings',
                miniName: 'A',
                layout: '/admin',
                permission: 'bookings',
                children: [
                    {
                        path: '/',
                        component: BookingTable,
                    },
                ],
            },
            {
                path: '/booking-verifications',
                name: 'Verifications',
                miniName: 'V',
                layout: '/admin',
                permission: 'booking-verifications',
                children: [
                    {
                        path: '/',
                        component: BookingVerifications,
                    },
                    {
                        path: '/:id',
                        permission: 'booking-verifications',
                        component: VerificationDetails,
                    },
                ],
            },
        ],
    },

    {
        collapse: true,
        name: 'Package bookings',
        state: 'T',

        icon: 'fa-solid fa-box',
        permission: 'client_packages',
        views: [
            {
                path: '/package-bookings',
                name: 'All Bookings ',
                miniName: 'A',
                layout: '/admin',
                permission: 'client_packages',
                children: [
                    {
                        path: '/',
                        component: PackageBookingListView,
                    },
                    {
                        path: '/details/:id',
                        component: PackageBookingDetails,
                    },
                ],
            },
            {
                path: '/package-booking-calendar',
                name: 'Calendar',
                miniName: 'C',
                layout: '/admin',
                permission: 'client_packages',
                children: [
                    {
                        path: '/',
                        component: PackageBookingCalendarView,
                    },
                ],
            },
        ],
    },

    {
        collapse: false,
        name: 'Quotes',
        state: '',
        path: '/quotes',
        icon: '<fa-sharp fa-regular fa-file-lines',
        layout: '/admin',
        permission: 'quotes',
        children: [
            {
                path: '/',
                component: QuoteListView,
            },
            {
                path: '/details/:id',
                component: QuoteDetails,
            },
        ],
    },
    {
        collapse: false,
        name: 'Clients',
        state: '',
        path: '/clients',
        icon: 'fa-sharp fa-solid fa-users',
        layout: '/admin',
        permission: 'clients',
        children: [
            {
                path: '/',
                component: ClientView,
            },
            {
                path: '/new',
                component: ClientAddForm,
            },
            {
                path: '/manage/:id',
                component: ClientManageView,
            },
        ],
    },
    {
        collapse: false,
        name: 'Survey',
        state: '',
        path: '/survey',
        icon: 'fa-solid fa-square-poll-horizontal',
        layout: '/admin',
        permission: 'clients',
        children: [
            {
                path: '/',
                component: SurveyListView,
            },
            {
                path: '/new',
                component: SurveyAddFormView,
            },
            {
                path: '/manage/:id',
                component: SurveyTabView,
            },
        ],
    },
    {
        collapse: true,
        name: 'Orders',
        icon: 'fa-solid fa-book',
        state: 'ordersCollapse',
        permission: 'orders',
        views: [
            {
                path: '/orders',
                name: 'All Orders',
                miniName: 'O',
                layout: '/admin',
                permission: 'orders',
                children: [
                    {
                        path: '/',
                        component: OrdersList,
                    },
                    {
                        path: '/details/:id',
                        component: OrderDetails,
                    },
                ],
            },
            {
                path: '/order-exports',
                name: 'Exports',
                miniName: 'E',
                layout: '/admin',
                permission: 'bookings',
                children: [
                    {
                        path: '/',
                        component: OrderExports,
                    },
                ],
            },
        ],
    },
    {
        collapse: false,
        name: 'Tasks',
        state: 'T',
        path: '/task',
        icon: 'fa-solid fa-list-check',
        layout: '/admin',
        permission: 'tasks',
        children: [
            {
                path: '/',
                component: TaskListView,
            },
            {
                path: '/new',
                component: TaskAddView,
            },
            {
                path: '/manage/:id',
                component: TaskDetailView,
            },
        ],
    },

    {
        collapse: false,
        name: 'Leads',
        state: '',
        path: '/leads',
        icon: 'fa-sharp fa-solid fa-chart-line',
        layout: '/admin',
        permission: 'leads',
        children: [
            {
                path: '/',
                component: LeadsList,
            },
            {
                path: '/details/:id',
                component: LeadDetails,
            },
            {
                path: '/new',
                component: LeadAddForm,
            },
        ],
    },
    {
        collapse: false,
        name: 'Meeting rooms',
        state: '',
        path: '/meeting',
        icon: 'fa-solid fa-handshake',
        layout: '/admin',
        permission: 'meeting_rooms',
        children: [
            {
                path: '/',
                component: MeetingRoomView,
            },
            {
                path: '/new',
                component: MeetingAddForm,
            },
            {
                path: '/manage/:id',
                component: MeetingRoomTabView,
            },
        ],
    },
    {
        collapse: true,
        name: 'Newsletters',
        icon: 'fa-solid fa-newspaper',
        state: 'newsletterCollapse',
        permission: 'newsletter',
        views: [
            // {
            //     path: '/posts',
            //     name: 'Posts',
            //     miniName: 'P',
            //     layout: '/admin',
            //     permission: 'newsletter',
            //     children: [
            //         {
            //             path: '/',
            //             component: PostsView,
            //         },
            //         {
            //             path: '/new',
            //             component: PostsAddForm,
            //         },
            //         {
            //             path: '/manage/:id',
            //             component: ManagePostView,
            //         },
            //     ],
            // },
            {
                path: '/emails',
                name: 'Emails',
                miniName: 'E',
                layout: '/admin',
                permission: 'newsletter',
                children: [
                    {
                        path: '/',
                        component: EmailView,
                    },
                    {
                        path: '/new',
                        component: EmailAddForm,
                    },
                    {
                        path: '/manage/:id',
                        component: ManageEmailView,
                    },
                ],
            },
            {
                path: '/mail-list',
                name: 'Mail Lists',
                miniName: 'M',
                layout: '/admin',
                permission: 'newsletter',
                children: [
                    {
                        path: '/',
                        component: MailListView,
                    },
                    {
                        path: '/new',
                        component: MailListAddFormView,
                    },
                    {
                        path: '/manage/:id',
                        component: MailListDetails,
                    },
                ],
            },
            {
                path: '/templates',
                name: 'Templates',
                miniName: 'P',
                layout: '/admin',
                permission: 'newsletter',
                children: [
                    {
                        path: '/',
                        component: EmailTemplateListView,
                    },
                    {
                        path: '/manage/:id',
                        component: TemplateView,
                    },
                ],
            },
        ],
    },
    {
        collapse: false,
        name: 'Packages',
        icon: 'fa-sharp fa-solid fa-cubes',
        state: 'dashboardsCollapse',
        path: '/packages',
        layout: '/admin',
        permission: 'packages',
        children: [
            {
                path: '/',
                component: PackageView,
            },
            {
                path: '/new',
                component: PackageAddForm,
            },
            {
                path: '/manage/:id',
                component: PackageManageView,
            },
        ],
    },
    {
        collapse: true,
        name: 'Administration',
        icon: 'fa fa-cog',
        permission: 'admin',
        views: [
            {
                path: '/users',
                name: 'Users',
                miniName: 'U',
                layout: '/admin',
                permission: 'users_management',
                children: [
                    {
                        path: '/',
                        component: UserManager,
                    },
                    {
                        path: '/new',
                        component: AddUserForm,
                    },
                    {
                        path: '/edit/:id',
                        component: EditUserForm,
                    },
                ],
            },
            {
                path: '/products',
                name: 'Products',
                miniName: 'P',
                layout: '/admin',
                permission: 'products',
                children: [
                    {
                        path: '/',
                        component: ProductView,
                    },
                    {
                        path: '/new',
                        component: AddProductForm,
                    },
                    {
                        path: '/manage/:id',
                        component: ProductTabView,
                    },
                ],
            },
            {
                path: '/vouchers',
                name: 'Vouchers',
                miniName: 'P',
                layout: '/admin',
                permission: 'products',
                children: [
                    {
                        path: '/',
                        component: VoucherListView,
                    },
                    {
                        path: '/new',
                        component: VoucherAddView,
                    },
                    {
                        path: '/manage/:id',
                        component: VoucherDetailsView,
                    },
                ],
            },
            {
                path: '/locations',
                name: 'Locations',
                miniName: 'L',
                layout: '/admin',
                permission: 'locations',
                children: [
                    {
                        path: '/',
                        component: LocationView,
                    },
                    {
                        path: '/new',
                        component: AddLocationForm,
                    },
                    {
                        path: '/edit/:id',
                        component: TabPanel,
                    },
                ],
            },
            {
                path: '/vendors',
                name: 'Vendors',
                miniName: 'V',

                layout: '/admin',
                permission: 'locations',
                children: [
                    {
                        path: '/',
                        component: VendorsView,
                    },
                    {
                        path: '/new',
                        component: AddVendorForm,
                    },
                    {
                        path: '/manage/:id',
                        component: VendorManageView,
                    },
                ],
            },
            {
                path: '/service-providers',
                name: 'Service Providers',
                miniName: 'S',

                layout: '/admin',
                permission: 'locations',
                children: [
                    {
                        path: '/',
                        component: ServiceProvidersListView,
                    },
                    {
                        path: '/new',
                        component: ServiceProviderAddFormView,
                    },
                    {
                        path: '/manage/:id',
                        component: ServiceProviderTabView,
                    },
                ],
            },
            {
                path: '/subscribers',
                name: 'Subscribers',
                miniName: 'S',

                layout: '/admin',
                permission: 'locations',
                children: [
                    {
                        path: '/',
                        component: SubscribersView,
                    },
                ],
            },

            {
                path: '/resources',
                name: 'Resources',
                miniName: 'R',

                layout: '/admin',
                permission: 'locations',
                children: [
                    {
                        path: '/',
                        component: ResourceListView,
                    },
                    {
                        path: '/new',
                        component: ResourceAddFormView,
                    },
                    {
                        path: '/manage/:id',
                        component: ResourceTabView,
                    },
                    // {
                    //     path: '/edit/:id',
                    //     component: ResourceEditFormView,
                    // },
                ],
            },
        ],
    },
];

export default routes;
