import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    Col,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table,
} from 'reactstrap';
import { REMOVE_ACCOUNT_DOCUMENT } from '../../../graphql/clients.graphql';
import { useMutation } from '@apollo/client';
import useToast from '../../../hooks/useToast';
import { Client, ClientAccountDocument } from '../../../models/client.model';
import SweetAlert from 'react-bootstrap-sweetalert';
import ClientDocumentForm from './ClientDocumentForm';
import ClientDocumentManageForm from './ClientDocumentManageForm';
import BasicMenu from '../../../components/Menu';
import _ from 'lodash';
import { downloadPdf } from '../../../utils/file.utils';

interface ClientDocumentCardProps {
    clientData: Client;
}

export default function ClientDocumentView({ clientData }: ClientDocumentCardProps) {
    const [displayForm, setDisplayForm] = useState<boolean>(false);
    const [displayManageForm, setDisplayManageForm] = useState<boolean>(false);
    const { showToast, Notification } = useToast();
    const [searchFilter, setSearchFilter] = useState<string>('');
    const [sortDirection, setSortDirection] = useState<string>('asc');
    const [sortFiled, setSortField] = useState<string>('date');
    const [alert, setAlert] = useState<any>(null);
    const [selectedAccountDocument, setSelectedAccountDocument] = useState<ClientAccountDocument | null>(null);
    const [page, setPage] = useState<number>(0);
    const [rowPerPage, setRowsPerPage] = useState<number>(10);

    const nextPage = () => {
        setPage(page + 1);
    };
    const previousPage = () => {
        setPage(page - 1);
    };

    const [
        removeAccountDocument,
        { called: removeCalled, error: removeError, loading: removeLoading, reset: removeReset },
    ] = useMutation(REMOVE_ACCOUNT_DOCUMENT, {
        // refetchQueries: [{ query: GET_CLIENT }, "GetClient"],
        refetchQueries: ['GetClient'],
    });

    useEffect(() => {
        if (removeCalled && !removeLoading) {
            removeReset();
            showToast('Successfully removed document', 'success');
        }

        if (removeCalled && removeError) {
            showToast('An error has occurred, please try again', 'danger');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [removeCalled, removeError, removeLoading]);

    useEffect(() => {
        console.log('Alert ', alert);
    }, [alert]);

    const getRowValue = (value: string) => {
        setRowsPerPage(parseInt(value));
        if (parseInt(value) !== rowPerPage) setPage(0);
    };

    const editDocument = (accountDocumentData: any) => {
        setDisplayManageForm(true);
        setSelectedAccountDocument(accountDocumentData);
    };

    const sortBy: any = (documents: ClientAccountDocument[], field: string, direction: string) => {
        const orderedBookings = new Map([
            ['name', _.orderBy(documents, (doc) => doc?.name, [direction === 'asc' ? 'asc' : 'desc'])],
            ['type', _.orderBy(documents, (doc) => doc?.type.title, [direction === 'asc' ? 'asc' : 'desc'])],
            ['description', _.orderBy(documents, (doc) => doc?.description, [direction === 'asc' ? 'asc' : 'desc'])],
            ['date', _.orderBy(documents, (doc) => doc?.date, [direction === 'asc' ? 'asc' : 'desc'])],
        ]);
        return orderedBookings.get(field);
    };

    const displayRemoveDocumentAlert = (props: any) => {
        setAlert(
            <SweetAlert
                {...props}
                showCancel
                confirmBtnText='Continue'
                confirmBtnBsStyle='danger'
                title='Are you sure? This action cannot be undone'
                onConfirm={() => {
                    setAlert(null);
                    removeAccountDocument({
                        variables: { id: props.document.id },
                    });
                }}
                onCancel={() => {
                    setAlert(null);
                }}
                focusCancelBtn>
                This Document will be removed from the system.
            </SweetAlert>,
        );
    };
    //    console.log("Here is the client id ", clientData.id)
    if (displayForm) {
        return <ClientDocumentForm backToDocuments={() => setDisplayForm(false)} clientId={clientData.id} />;
    }

    if (displayManageForm) {
        return (
            <ClientDocumentManageForm
                backToDocuments={() => setDisplayManageForm(false)}
                seletedDocumentUpdate={selectedAccountDocument}
            />
        );
    }

    let filteredDocuments: ClientAccountDocument[] = clientData.accountDocuments.filter((key) => {
        const checkForSearch =
            key.name?.toLowerCase().includes(searchFilter.toLowerCase()) ||
            key.type?.title.toLowerCase().includes(searchFilter.toLowerCase()) ||
            key.description?.toLocaleLowerCase().includes(searchFilter.toLocaleLowerCase());

        console.log('name', key.type.title?.toLowerCase());

        if (searchFilter === '') {
            return key;
        }

        return checkForSearch;
    });

    filteredDocuments = sortBy(filteredDocuments, sortFiled);

    const downloadDocument = (path: string, documentId: string) => {
        const url = `${process.env.REACT_APP_IMAGE_URL}/${path}`;
        downloadPdf(url, `${documentId}`);
    };

    return (
        <>
            {Notification}
            {alert}
            <Card className='mt-4'>
                <Row>
                    <div className='col'>
                        <CardBody>
                            <div className='row no-gutters justify-content-between align-items-center'>
                                <h3>Documents</h3>
                                <button
                                    className='btn btn-dark btn-sm'
                                    onClick={() => {
                                        setDisplayForm(true);
                                    }}>
                                    Add Document
                                </button>
                            </div>
                            <Row className='mt-4'>
                                <Col xs='6'>
                                    <Input
                                        className='form-control w-50'
                                        value={searchFilter}
                                        type='text'
                                        placeholder='Search keyword...'
                                        onChange={(e) => {
                                            setSearchFilter(e.target.value);
                                            setPage(0);
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Table className='align-items-center table-flush mt-4' responsive>
                                <thead className='thead-light'>
                                    <tr>
                                        <th
                                            className='sort'
                                            data-sort='type'
                                            scope='col'
                                            onClick={() => {
                                                setSortField('type');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}>
                                            Type
                                        </th>
                                        <th
                                            className='sort'
                                            data-sort='name'
                                            scope='col'
                                            onClick={() => {
                                                setSortField('name');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}>
                                            Name
                                        </th>
                                        <th
                                            className='sort'
                                            data-sort='description'
                                            scope='col'
                                            onClick={() => {
                                                setSortField('description');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}>
                                            Description
                                        </th>
                                        <th
                                            className='sort'
                                            data-sort='date'
                                            scope='col'
                                            onClick={() => {
                                                setSortField('date');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}>
                                            Date
                                        </th>
                                        <th scope='col'></th>
                                    </tr>
                                </thead>
                                <tbody className='list'>
                                    {filteredDocuments
                                        .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                                        .map((document: any) => {
                                            return (
                                                <tr
                                                    key={document?.id}
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        editDocument(document);
                                                    }}>
                                                    <td>{document?.documentTypes.title}</td>
                                                    <td>{document?.name}</td>
                                                    <td>{document?.description}</td>
                                                    <td>{moment(document?.date).format('yyyy-MM-DD')}</td>
                                                    <td className='text-right'>
                                                        <Button
                                                            className='btn btn-info btn-icon-only rounded-circle btn-sm'
                                                            onClick={() => editDocument(document)}>
                                                            {' '}
                                                            <i className='fa-solid fa-pencil'></i>
                                                        </Button>
                                                        <Button
                                                            className='btn btn-outlined-info btn-sm'
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                if (document.path) {
                                                                    downloadDocument(document.path, document.id);
                                                                }
                                                            }}
                                                            disabled={!document.path}>
                                                            Document
                                                        </Button>
                                                        <Button
                                                            className='btn btn-danger btn-icon-only rounded-circle  btn-sm'
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                displayRemoveDocumentAlert({
                                                                    warning: true,
                                                                    document,
                                                                });
                                                            }}>
                                                            <i className='fa-regular fa-trash-can'></i>
                                                        </Button>{' '}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </Table>
                        </CardBody>
                        <CardFooter className='py-4'>
                            <Row>
                                <div className='col-4 d-flex'>
                                    <span className='min-text'>Rows per page:</span>
                                    <BasicMenu
                                        getValue={getRowValue}
                                        options={['10', '20', '100']}
                                        value={rowPerPage.toString()}
                                    />
                                </div>
                                <div className='col-4 d-flex'>
                                    <span className='min-text'>Page:</span>
                                    <span className='min-text pl-2'>
                                        {page + 1} of {Math.ceil(filteredDocuments.length / rowPerPage)}
                                    </span>
                                </div>
                                <div className='col-4 d-flex'>
                                    <Pagination>
                                        <PaginationItem className={page <= 0 ? 'disabled' : ''}>
                                            <PaginationLink onClick={(e) => previousPage()}>
                                                <i className='fas fa-angle-left' />
                                                <span className='sr-only'>Previous</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                        <PaginationItem
                                            className={
                                                page >= Math.ceil(filteredDocuments.length / rowPerPage) - 1
                                                    ? 'disabled'
                                                    : ''
                                            }>
                                            <PaginationLink onClick={(e) => nextPage()}>
                                                <i className='fas fa-angle-right' />
                                                <span className='sr-only'>Previous</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                    </Pagination>
                                </div>
                            </Row>
                        </CardFooter>
                    </div>
                </Row>
            </Card>
        </>
    );
}
