import { gql } from '@apollo/client';

export const GET_MEMBERSHIP_AGREEMENTS = gql`
    query GetMembershipAgreements($accountId: uuid!) {
        agreements: membership_agreement(
            where: { deleted_at: { _is_null: true }, account_id: { _eq: $accountId } }
            order_by: { created_at: desc }
        ) {
            id
            startDate: start_date
            endDate: end_date
            documentPath: document_path
            status {
                value
                title
            }
            itemsAggregate: agreement_items_aggregate(
                where: { deleted_at: { _is_null: true }, status_enum: { _eq: active } }
            ) {
                aggregate {
                    sum {
                        balconyFee: balcony_fee
                        parkingFee: parking_fee
                        totalFee: total_fee
                    }
                }
            }
        }
    }
`;

export const GET_MEMBERSHIP_AGREEMENT = gql`
    query GetMemberShipAgreement($id: uuid!, $accountId: uuid!) {
        agreement: membership_agreement_by_pk(id: $id) {
            id
            billingPeriod: billing_period {
                value
                title
            }
            startDate: start_date
            escalationPercentage: escalation_percentage
            escalationPeriod: escalation_period {
                value
                title
            }
            endDate: end_date
            documentPath: document_path
            status {
                value
                title
            }
            billingPeriod: billing_period {
                value
                title
            }
            escalationDate: escalation_date
            items: agreement_items(where: { deleted_at: { _is_null: true }, type_enum: { _eq: "both" } }) {
                id
                assignMemberId: assign_member_id
                packageId: package_id
                balconyFee: balcony_fee
                parkingFee: parking_fee
                status: status_enum
                totalFee: total_fee
                member: team_member {
                    id
                    user {
                        name
                        surname
                    }
                }
                package: product_package {
                    id
                    name
                }
            }
            agreementAddendums: agreement_addendums {
                id
                startDate: start_date
                endDate: end_date
                documentPath: document_path
                items: agreement_items {
                    id
                    balconyFee: balcony_fee
                    parkingFee: parking_fee
                    totalFee: total_fee
                    assignMemberId: assign_member_id
                    packageId: package_id
                    status: status_enum
                    member: team_member {
                        id
                        user {
                            name
                            surname
                        }
                    }
                    package: product_package {
                        id
                        name
                    }
                }
                status {
                    value
                    title
                }
            }
        }
        statusList: membership_agreement_status {
            value
            title
        }
        billingPeriodList: billing_period {
            value
            title
        }
        addendumStatusList: membership_agreement_addendum_status {
            value
            title
        }
        escalationPeriodList: escalation_period {
            value
            title
        }
        packages: product_package(where: { deleted_at: { _is_null: true } }) {
            id
            name
            price
        }
        membershipItemStatusList: membership_agreement_item_status(where: { value: { _neq: "updated" } }) {
            value
            title
        }
        members: account_team_member(where: { account_id: { _eq: $accountId }, deleted_at: { _is_null: true } }) {
            id
            user {
                name
                surname
            }
        }
    }
`;

export const REMOVE_MEMBER_AGREEMENT = gql`
    mutation RemoveMembershipAgreement($id: uuid!) {
        agreement: update_membership_agreement_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const GET_BILLING_PERIOD_AND_STATUS_LIST = gql`
    query GetBillingPeriodAndStatusList($accountId: uuid!) {
        statusList: membership_agreement_status {
            value
            title
        }
        packages: product_package(where: { deleted_at: { _is_null: true } }) {
            id
            name
            price
        }
        billingPeriodList: billing_period {
            value
            title
        }
        escalationPeriodList: escalation_period {
            value
            title
        }
        membershipItemStatusList: membership_agreement_item_status {
            value
            title
        }
        members: account_team_member(where: { account_id: { _eq: $accountId }, deleted_at: { _is_null: true } }) {
            id
            user {
                name
                surname
            }
        }
    }
`;

export const UPDATE_MEMBERSHIP_AGREEMENT = gql`
    mutation UpdateMembershipAgreement($id: uuid!, $agreement: membership_agreement_set_input!) {
        agreement: update_membership_agreement_by_pk(pk_columns: { id: $id }, _set: $agreement) {
            id
        }
    }
`;

export const ADD_MEMBERSHIP_AGREEMENT = gql`
    mutation AddMembershipAgreements($agreement: membership_agreement_insert_input!) {
        agreement: insert_membership_agreement_one(
            object: $agreement
            on_conflict: {
                constraint: membership_agreement_pkey
                update_columns: [
                    billing_period_enum
                    document_path
                    end_date
                    escalation_date
                    escalation_percentage
                    escalation_period_enum
                    start_date
                    status_enum
                ]
            }
        ) {
            id
        }
    }
`;

export const REMOVE_MEMBERSHIP_ITEM = gql`
    mutation RemoveMembershipAgreement($id: uuid!) {
        item: update_membership_agreement_item_by_pk(pk_columns: { id: $id }, _set: { deleted_at: now }) {
            id
        }
    }
`;

export const ADD_AGREEMENT_ITEM = gql`
    mutation AddMembershipAgreementItem($item: membership_agreement_item_insert_input!) {
        item: insert_membership_agreement_item_one(object: $item) {
            id
        }
    }
`;

export const CANCEL_MEMBERSHIP_ADDENDUM = gql`
    mutation CancelMembershipAgreementAddendum($id: uuid!) {
        item: update_membership_agreement_addendum_by_pk(pk_columns: { id: $id }, _set: { status_enum: cancelled }) {
            id
        }
    }
`;

export const ADD_MEMBERSHIP_AGREEMENT_ADDENDUM = gql`
    mutation AddMembershipAddendum($addendum: membership_agreement_addendum_insert_input!) {
        addendum: insert_membership_agreement_addendum_one(object: $addendum) {
            id
        }
    }
`;
