import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table,
    UncontrolledTooltip,
} from 'reactstrap';
import BasicMenu from '../../../../components/Menu';
import useToast from '../../../../hooks/useToast';
import { GET_SERVICE_AGREEMENTS, REMOVE_SERVICE_AGREEMENT } from '../../../../graphql/service-providers.graphql';
import { useMutation, useQuery } from '@apollo/client';
import { LinearProgress } from '@mui/material';
import SweetAlert from 'react-bootstrap-sweetalert';
import { ServiceAgreement } from '../../../../models/service-providers.model';
import _ from 'lodash';
import ServiceAgreementAddForm from './ServiceAgreementAddForm';
import moment from 'moment';
import ServiceAgreementDetailsForm from './ServiceAgreementDetailsForm';
import { downloadPdf } from '../../../../utils/file.utils';

export default function ServiceAgreementListView() {
    const [page, setPage] = useState<number>(0);
    const [searchKey, setSearchKey] = useState<string>('');
    const [rowPerPage, setRowPerPage] = useState<number>(10);
    const [alert, setAlert] = useState<any>(null);
    const { showToast, Notification } = useToast();
    const params: any = useParams();
    const [sortFiled, setSortField] = useState<string>('name');
    const [sortDirection, setSortDirection] = useState<string>('desc');
    const [showAddForm, setShowAddForm] = useState<boolean>(false);
    const [showDetailsForm, setShowDetailsForm] = useState<boolean>(false);
    const [agreementId, setAgreementId] = useState<string>('');

    const { data, loading, error } = useQuery(GET_SERVICE_AGREEMENTS, {
        variables: {
            provider_id: params?.id,
        },
    });

    const [removeServiceAgreement, { data: removedAgreement }] = useMutation(REMOVE_SERVICE_AGREEMENT, {
        refetchQueries: [{ query: REMOVE_SERVICE_AGREEMENT }],
    });

    useEffect(() => {
        if (removedAgreement) {
            showToast('Successfully removed service agreement', 'success');
        }
    }, [removedAgreement]);

    useEffect(() => {
        if (error) {
            showToast('An error has occurred while trying to load the page', 'danger');
            console.log('Loading error', error);
        }
    }, [error]);

    const sortBy: any = (agreements: ServiceAgreement[], field: string, direction: string) => {
        const orderedProviders = new Map([
            [
                'name',
                _.orderBy(agreements, (agreement) => agreement.serviceProvider.name, [
                    direction === 'asc' ? 'asc' : 'desc',
                ]),
            ],
            [
                'startDate',
                _.orderBy(agreements, (agreement) => agreement.startDate, [direction === 'asc' ? 'asc' : 'desc']),
            ],
            [
                'endDate',
                _.orderBy(agreements, (agreement) => agreement.endDate, [direction === 'asc' ? 'asc' : 'desc']),
            ],
            [
                'dateSigned',
                _.orderBy(agreements, (agreement) => agreement.dateSigned, [direction === 'asc' ? 'asc' : 'desc']),
            ],

            [
                'priceExclVat',
                _.orderBy(agreements, (agreement) => agreement.priceExclVat, [direction === 'asc' ? 'asc' : 'desc']),
            ],
            [
                'priceInclVat',
                _.orderBy(agreements, (agreement) => agreement.priceInclVat, [direction === 'asc' ? 'asc' : 'desc']),
            ],
            [
                'status',
                _.orderBy(agreements, (agreement) => agreement.status.title, [direction === 'asc' ? 'asc' : 'desc']),
            ],
            [
                'billingPeriod',
                _.orderBy(agreements, (agreement) => agreement.billingPeriod.title, [
                    direction === 'asc' ? 'asc' : 'desc',
                ]),
            ],
        ]);
        return orderedProviders.get(field);
    };

    const previousPage = () => {
        setPage(page - 1);
    };

    const downloadDocument = (path: string, agreementId: string) => {
        const url = `${process.env.REACT_APP_IMAGE_URL}/${path}`;
        downloadPdf(url, `${agreementId}`);
    };

    const nextPage = () => {
        setPage(page + 1);
    };

    const redirectToDetailsForm = (agreementId: string) => {
        setAgreementId(agreementId);
        setShowAddForm(false);
        setShowDetailsForm(true);
    };

    const displayAlert = (props: any) => {
        setAlert(
            <SweetAlert
                {...props}
                showCancel
                confirmBtnText='Continue'
                confirmBtnBsStyle='danger'
                title='Are you sure?'
                onConfirm={() => {
                    removeServiceAgreement({
                        variables: {
                            id: props?.agreement?.id,
                        },
                    });
                    setAlert(null);
                }}
                onCancel={() => {
                    setAlert(null);
                }}
                focusCancelBtn>
                This service agreement will be removed from the system.
            </SweetAlert>,
        );
    };

    const getRowValue = (value: string) => {
        setRowPerPage(parseInt(value));
    };

    if (showAddForm) {
        return (
            <ServiceAgreementAddForm
                onRedirectBack={() => setShowAddForm(false)}
                redirectToDetails={redirectToDetailsForm}
            />
        );
    }

    if (showDetailsForm) {
        return (
            <ServiceAgreementDetailsForm onRedirectBack={() => setShowDetailsForm(false)} agreementId={agreementId} />
        );
    }

    if (loading) {
        return <LinearProgress />;
    }

    const serviceAgreements: ServiceAgreement[] = data?.agreement;

    let filteredAgreements = serviceAgreements.filter((agreement) => {
        if (searchKey === '') {
            return agreement;
        }
        const filtered =
            agreement.serviceProvider.name.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            agreement.startDate.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            agreement.endDate.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            agreement.dateSigned.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            agreement.priceExclVat.toString().toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            agreement.priceInclVat.toString().toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            agreement.billingPeriod.title.toString().toLocaleLowerCase().includes(searchKey.toLocaleLowerCase());

        return filtered;
    });

    filteredAgreements = sortBy(filteredAgreements, sortFiled, sortDirection);

    return (
        <>
            {alert}
            {Notification}
            <Row className='mt-4'>
                <div className='col'>
                    <Card>
                        <CardHeader className='border-0'>
                            <Row>
                                <Col xs='6'>
                                    <h3 className='mb-0'>Service Level Agreements</h3>
                                </Col>
                                <Col className='text-right' xs='6'>
                                    <Button
                                        onClick={() => setShowAddForm(true)}
                                        className='btn btn-round btn-sm btn-dark'
                                        color='default'
                                        id='tooltip969372949'>
                                        <span className='btn-inner--text'>Add service agreement </span>
                                    </Button>

                                    <UncontrolledTooltip delay={0} target='tooltip969372949'>
                                        Add service agreement
                                    </UncontrolledTooltip>
                                </Col>
                            </Row>
                            <Row className='mt-4 border-0'>
                                <Col xs='8'>
                                    <Input
                                        className='form-control w-25'
                                        type='text'
                                        value={searchKey}
                                        placeholder='Search keyword...'
                                        onChange={(e) => {
                                            setSearchKey(e.target.value);
                                            setPage(0);
                                        }}
                                    />
                                </Col>
                            </Row>
                        </CardHeader>
                        <Table className='align-items-center table-flush' responsive>
                            <thead className='thead-light'>
                                <tr>
                                    <th
                                        onClick={() => {
                                            setSortField('name');
                                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                        }}
                                        className='sort'
                                        data-sort='client'
                                        scope='col'>
                                        Provider
                                    </th>
                                    <th
                                        onClick={() => {
                                            setSortField('startDate');
                                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                        }}
                                        className='sort'
                                        data-sort='date'
                                        scope='col'>
                                        Start date
                                    </th>
                                    <th
                                        onClick={() => {
                                            setSortField('endDate');
                                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                        }}
                                        className='sort'
                                        data-sort='reference'
                                        scope='col'>
                                        End date
                                    </th>
                                    <th
                                        onClick={() => {
                                            setSortField('dateSigned');
                                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                        }}
                                        className='sort'
                                        data-sort='reference'
                                        scope='col'>
                                        Date signed
                                    </th>
                                    <th
                                        onClick={() => {
                                            setSortField('priceExclVat');
                                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                        }}
                                        className='sort'
                                        data-sort='paymentStatus'
                                        scope='col'>
                                        Price Excl VAT
                                    </th>
                                    <th
                                        onClick={() => {
                                            setSortField('priceInclVat');
                                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                        }}
                                        className='sort'
                                        data-sort='paymentStatus'
                                        scope='col'>
                                        Price Inc VAT
                                    </th>
                                    <th
                                        onClick={() => {
                                            setSortField('status');
                                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                        }}
                                        className='sort'
                                        data-sort='paymentStatus'
                                        scope='col'>
                                        Status
                                    </th>
                                    <th
                                        onClick={() => {
                                            setSortField('billingPeriod');
                                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                        }}
                                        className='sort'
                                        data-sort='paymentStatus'
                                        scope='col'>
                                        Billing Period
                                    </th>
                                    <th scope='col' />
                                </tr>
                            </thead>
                            <tbody className='list'>
                                {filteredAgreements
                                    .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                                    .map((agreement) => {
                                        return (
                                            <tr
                                                key={agreement.id}
                                                onClick={() => {
                                                    setAgreementId(agreement.id);
                                                    setShowDetailsForm(true);
                                                }}
                                                style={{
                                                    cursor: 'pointer',
                                                }}>
                                                <td>{agreement.serviceProvider.name ?? ''}</td>
                                                <td>{moment(agreement.startDate).format('yyyy-MM-DD')}</td>
                                                <td>{moment(agreement.endDate).format('yyyy-MM-DD')}</td>
                                                <td>{moment(agreement.dateSigned).format('yyyy-MM-DD')}</td>
                                                <td>{`R ${agreement.priceExclVat.toFixed(2)}`}</td>
                                                <td>{`R ${agreement.priceInclVat.toFixed(2)}`}</td>
                                                <td>{agreement.status.title ?? ''}</td>
                                                <td>{agreement.billingPeriod.title ?? ''}</td>
                                                <td>
                                                    <Button
                                                        onClick={() => {
                                                            setAgreementId(agreement.id);
                                                            setShowDetailsForm(true);
                                                        }}
                                                        className='btn btn-info btn-icon-only rounded-circle btn-sm'>
                                                        <i className='fa-solid fa-pencil'></i>
                                                    </Button>
                                                    <Button
                                                        className='btn btn-outlined-info btn-sm'
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            if (agreement.documentPath) {
                                                                downloadDocument(agreement.documentPath, agreement.id);
                                                            }
                                                        }}
                                                        disabled={!agreement.documentPath}>
                                                        Document
                                                    </Button>

                                                    <Button
                                                        className='btn btn-danger btn-icon-only rounded-circle btn-sm'
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            displayAlert({ warning: true, agreement });
                                                        }}>
                                                        <i className='fa-solid fa-trash-can'></i>
                                                    </Button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </Table>
                        <CardFooter className='py-4'>
                            <Row>
                                <div className='col-4 d-flex'>
                                    <span style={{ fontSize: '12px' }}>Rows per page:</span>
                                    <BasicMenu
                                        getValue={getRowValue}
                                        options={['10', '20', '100']}
                                        value={rowPerPage.toString()}
                                    />
                                </div>
                                <div className='col-4 d-flex'>
                                    <span className='min-text'>Page:</span>
                                    <span className='min-text pl-2'>
                                        {page + 1} of {Math.ceil(filteredAgreements.length / rowPerPage)}
                                    </span>
                                </div>
                                <div className='col-4 d-flex'>
                                    <Pagination>
                                        <PaginationItem className={page <= 0 ? 'disabled' : ''}>
                                            <PaginationLink onClick={(e) => previousPage()}>
                                                <i className='fas fa-angle-left' />
                                                <span className='sr-only'>Previous</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                        <PaginationItem
                                            className={
                                                page >= Math.ceil(filteredAgreements.length / rowPerPage) - 1
                                                    ? 'disabled'
                                                    : ''
                                            }>
                                            <PaginationLink onClick={(e) => nextPage()}>
                                                <i className='fas fa-angle-right' />
                                                <span className='sr-only'>Previous</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                    </Pagination>
                                </div>
                            </Row>
                        </CardFooter>
                    </Card>
                </div>
            </Row>
        </>
    );
}
