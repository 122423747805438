import React from "react";
import { createRoot } from "react-dom/client";
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";

import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/css/login-styles.css";
import "./assets/scss/argon-dashboard-pro-react.scss";
import { configureAmplify } from "./aws-amplify/aws-amplify";
import App from "./app";
import { Provider } from "react-redux";
import { store } from "./app/store";

declare const document: any;
const container = document.getElementById("root");
const root = createRoot(container);

configureAmplify();

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
