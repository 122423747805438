import {
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { Card, CardBody, Col, FormGroup, Row } from "reactstrap";
import {
  MeetingRoomItem,
  MeetingRoomItemRules,
} from "../../../../models/meeting.room.model";
import { useMutation, useQuery } from "@apollo/client";
import {
  DELETE_EXCLUSION,
  GET_MEETING_ROOM,
  INSERT_ROOM_RULES,
} from "../../../../graphql/meeting-rooms.graphql";
import Spinner from "../../../../components/Spinner/Spinner";
import useToast from "../../../../hooks/useToast";
import CloseIcon from "@mui/icons-material/Close";
import { GET_MEETING_ROOM_ITEMS } from "../../../../graphql/clients.graphql";

type FormData = {
  customSetupFee?: number;
  minDuration: number;
  leadTime: number;
  trailTime: number;
  priceOnRequest: boolean;
  allowLongTerm: boolean;
  allowRepeating: boolean;
  overflowBooking: boolean;
  allowShortBooking: boolean;
  status: string;
  baseUnit: string;
  exclusions: {
    id?: string;
    meetingRoomItemId: string;
    blockWhenBooked: boolean;
  }[];
};

interface MeetingRoomItemRulesProps {
  roomItem: MeetingRoomItem;
}

const MeetingRoomItemRulesView: React.FC<MeetingRoomItemRulesProps> = ({
  roomItem,
}) => {
  const rules: MeetingRoomItemRules | undefined = roomItem.rules[0];

  const [updateRules, { loading, error, data, called, reset }] = useMutation(
    INSERT_ROOM_RULES,
    {
      refetchQueries: [{ query: GET_MEETING_ROOM }, "GetMeetingRoom"],
    }
  );

  const [deleteExclusion] = useMutation(DELETE_EXCLUSION);

  const { data: meetingRoomItemsData } = useQuery(GET_MEETING_ROOM_ITEMS, {});

  const { showToast, Notification } = useToast();

  const meetingRooms: MeetingRoomItem[] =
    meetingRoomItemsData?.meetingRoomItems || [];

  useEffect(() => {
    if (data && called) {
      showToast("Rules updated successfully", "success");
      reset();
    }
  }, [data, called, showToast, reset]);

  useEffect(() => {
    if (error) {
      showToast("An error has occurred, please try again", "danger");
    }
  }, [error, showToast]);

  const { control, register, handleSubmit, watch, getValues } =
    useForm<FormData>({
      defaultValues: {
        leadTime: rules?.leadTime || 0,
        trailTime: rules?.trailTime || 0,
        minDuration: rules?.minimumDuration || 0,
        priceOnRequest: rules?.priceOnRequest || false,
        allowLongTerm: rules?.allowLongTerm || false,
        allowRepeating: rules?.allowRepeating || false,
        overflowBooking: rules?.overflowBooking || false,
        allowShortBooking: rules?.allowShortBooking || false,
        status: rules?.status?.value || "active",
        baseUnit: rules?.baseUnit || "hour",
        customSetupFee: rules?.customSetupFee || 0,
        exclusions: rules?.exclusions.map((exclusion) => ({
          id: exclusion.id,
          meetingRoomItemId: exclusion.meetingRoomItem.id,
          blockWhenBooked: exclusion.blockWhenBooked,
        })),
      },
    });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "exclusions",
  });

  const onSubmit = (formData: FormData) => {
    console.log(formData);
    const newRules = {
      meeting_room_item_id: roomItem.id,
      lead_time: formData.leadTime,
      trail_time: formData.trailTime,
      minimum_duration: formData.minDuration,
      price_on_request: formData.priceOnRequest,
      allow_long_term: formData.allowLongTerm,
      allow_repeating: formData.allowRepeating,
      overflow_booking: formData.overflowBooking,
      allow_short_booking: formData.allowShortBooking,
      status_enum: "active",
      deleted_at: null,
      custom_setup_fee: formData.customSetupFee,
      exclusions: {
        on_conflict: {
          constraint:
            "meeting_room_item_rules_room__rules_id_meeting_room_item_id_key",
          update_columns: ["block_when_booked"],
        },
        data: formData.exclusions.map((exclusion) => {
          return {
            meeting_room_item_id: exclusion.meetingRoomItemId,
            block_when_booked: exclusion.blockWhenBooked,
          };
        }),
      },
    };

    updateRules({
      variables: { rules: newRules },
    });
  };

  console.log("Rules", rules);

  return (
    <>
      {Notification}
      <Card className="mt-4">
        <CardBody>
          <h3>Rules</h3>
          <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
            <p>Base Rules Unit - {rules?.baseUnit || "Not Set"}</p>
            <Row>
              <Col md="12" lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="name">
                    Lead Time
                  </label>
                  <input
                    className="form-control"
                    {...register("leadTime", { required: false })}
                    id="leadTime"
                    placeholder="Enter an amount..."
                    type="number"
                  />
                </FormGroup>
              </Col>
              <Col md="12" lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="name">
                    Trail Time
                  </label>
                  <input
                    className="form-control"
                    {...register("trailTime", { required: false })}
                    id="trailTime"
                    placeholder="Enter an amount..."
                    type="number"
                  />
                </FormGroup>
              </Col>
              <Col md="12" lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="name">
                    Minimum Booking Duration
                  </label>
                  <input
                    className="form-control"
                    {...register("minDuration", { required: false })}
                    id="minDuration"
                    placeholder="Enter an amount..."
                    type="number"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormControlLabel
                  control={
                    <Checkbox
                      id="priceOnRequest"
                      checked={watch("priceOnRequest")}
                      inputProps={{
                        ...register("priceOnRequest", {
                          required: false,
                        }),
                      }}
                    />
                  }
                  label="Price on request"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      id="allowLongTerm"
                      checked={watch("allowLongTerm")}
                      inputProps={{
                        ...register("allowLongTerm", {
                          required: false,
                        }),
                      }}
                    />
                  }
                  label="Allow long term"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      id="allowRepeating"
                      checked={watch("allowRepeating")}
                      inputProps={{
                        ...register("allowRepeating", {
                          required: false,
                        }),
                      }}
                    />
                  }
                  label="Allow repeating"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      id="overflowBooking"
                      checked={watch("overflowBooking")}
                      inputProps={{
                        ...register("overflowBooking", {
                          required: false,
                        }),
                      }}
                    />
                  }
                  label="Overflow booking"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      id="allowShortBooking"
                      checked={watch("allowShortBooking")}
                      inputProps={{
                        ...register("allowShortBooking", {
                          required: false,
                        }),
                      }}
                    />
                  }
                  label="Allow short bookings"
                />
              </Col>
            </Row>
            <Row>
              <Col md="12" lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="name">
                    Custom Setup Fee
                  </label>
                  <input
                    className="form-control"
                    {...register("customSetupFee", { required: false })}
                    id="customSetupFee"
                    placeholder="Enter an amount..."
                    type="number"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6" md="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="name">
                    Base Unit
                  </label>
                  <Select
                    id="end"
                    value={watch("baseUnit") || "empty"}
                    sx={{ borderRadius: "12px" }}
                    className="form-control"
                    inputProps={{
                      ...register("baseUnit", {
                        required: true,
                      }),
                    }}
                  >
                    <MenuItem disabled value={"empty"}>
                      <span className="placeholder-text">Select unit...</span>
                    </MenuItem>
                    <MenuItem value={"hour"}>Hour</MenuItem>
                    <MenuItem value={"day"}>Day</MenuItem>
                  </Select>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <h3>Exclusions</h3>
                {fields.map((field, index) => (
                  <Row
                    key={field.id}
                    noGutters
                    className="justify-content-start align-items-start"
                  >
                    <FormGroup>
                      <Select
                        id="end"
                        variant="standard"
                        defaultValue={"empty"}
                        value={
                          watch(`exclusions.${index}.meetingRoomItemId`) ||
                          "empty"
                        }
                        sx={{ borderRadius: "12px", minWidth: "200px" }}
                        inputProps={{
                          ...register(`exclusions.${index}.meetingRoomItemId`),
                        }}
                      >
                        <MenuItem disabled value={"empty"}>
                          <span className="placeholder-text">
                            Select room...
                          </span>
                        </MenuItem>
                        {meetingRooms.map((room) => {
                          return (
                            <MenuItem key={room.id} value={room.id}>
                              {room.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormGroup>
                    <FormControlLabel
                      className="ml-4"
                      control={
                        <Checkbox
                          checked={watch(`exclusions.${index}.blockWhenBooked`)}
                          inputProps={{
                            ...register(`exclusions.${index}.blockWhenBooked`),
                          }}
                        />
                      }
                      label="Block When Booked"
                    />
                    <IconButton
                      onClick={() => {
                        if (field.id) {
                          const value = getValues(`exclusions.${index}.id`);
                          deleteExclusion({
                            variables: { id: value },
                          });
                        }
                        remove(index);
                      }}
                      size="small"
                    >
                      <CloseIcon />
                    </IconButton>
                  </Row>
                ))}
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={() => {
                    append({ meetingRoomItemId: "", blockWhenBooked: true });
                  }}
                >
                  Add Exclusion
                </button>
              </Col>
            </Row>
            <Row noGutters className="justify-content-end">
              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary"
              >
                {loading ? (
                  <span>
                    <Spinner />
                  </span>
                ) : (
                  <span>Update</span>
                )}
              </button>
            </Row>
          </form>
        </CardBody>
      </Card>
    </>
  );
};

export default MeetingRoomItemRulesView;
