/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import { v4 } from 'uuid';
import { ResourceAttribute, ResourceFormAttributeOption } from '../../../models/resource.model';
import ResourceFormAttribute from './components/ResourceFormAttribute';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { ADD_RESOURCE, GET_RESOURCES } from '../../../graphql/resource.graphql';
import Spinner from '../../../components/Spinner/Spinner';
import useToast from '../../../hooks/useToast';

type ResourceFormData = {
    title: string;
    description: string;
};

export default function ResourceAddFormView() {
    const navigate = useNavigate();
    const { showToast, Notification } = useToast();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ResourceFormData>();
    const [formAttributes, setFormAttributes] = useState<ResourceAttribute[]>([]);

    const [draggedElementIndex, setDraggedElementIndex] = useState<number>(-1);

    const [addResource, { loading, error, data }] = useMutation(ADD_RESOURCE, {
        refetchQueries: [{ query: GET_RESOURCES }],
    });

    const onDragStart = (event: Event, index: number) => {
        setDraggedElementIndex(index);
    };
    const onDragOver = (event: Event) => {
        event.preventDefault();
    };
    const onDrop = (event: Event, index: number) => {
        if (draggedElementIndex === index) return;
        const attributes = [...formAttributes];
        const draggedAttribute: ResourceAttribute = { ...attributes[draggedElementIndex] };
        const draggedToAttribute: ResourceAttribute = { ...attributes[index] };
        attributes[index] = { ...draggedAttribute };
        attributes[draggedElementIndex] = { ...draggedToAttribute };
        setFormAttributes([...attributes]);
    };

    const onAttributeRemove = (attribute: ResourceAttribute) => {
        const filteredAttributes = formAttributes.filter((att) => att.key !== attribute.key);

        setFormAttributes(filteredAttributes);
    };

    useEffect(() => {
        if (error) {
            showToast('An error has occurred while trying to add a resource', 'danger');
        }
    }, [error]);

    useEffect(() => {
        if (data) {
            showToast('Successfully added resources', 'success');
            navigate(-1);
        }
    }, [data]);

    const handleAttributeCreating = () => {
        const attribute: ResourceAttribute = {
            title: '',
            placeholder: '',
            value: undefined,
            isOpen: false,
            required: false,
            type: 'text',
            key: v4(),
            options: [],
        };

        const attributes = [...formAttributes, attribute];
        setFormAttributes(attributes);
    };

    const handleAttributeUpdate = (attributes: ResourceAttribute[]) => {
        setFormAttributes(attributes);
    };

    const onSubmit = async (data: ResourceFormData) => {
        console.log('Data', data);
        const resource = {
            status_enum: 'active',
            type_enum: 'asset',
            name: data.title,
            attribute_form: {
                data: {
                    title: data.title,
                    description: data.description,
                    resource_form_attributes: {
                        data: formAttributes.map((att, index) => {
                            return {
                                title: att.title ?? '',
                                type: att.type ?? '',
                                required: att.required,
                                order: index + 1,
                                resource_form_attribute_options: {
                                    data: att.options.map((option: ResourceFormAttributeOption) => {
                                        return {
                                            value: option.value,
                                            order: option.order,
                                        };
                                    }),
                                },
                            };
                        }),
                    },
                },
            },
        };

        await addResource({
            variables: {
                resource,
            },
        });
    };

    return (
        <>
            {Notification}
            <Container className='mt-4' fluid>
                <Row>
                    <Col md='12' lg='6'>
                        <Card>
                            <CardHeader>
                                <Row noGutters={true}>
                                    <Col xs='3'>
                                        <button
                                            className='btn btn-outline-primary btn-sm mr-4'
                                            onClick={() => navigate(-1)}>
                                            <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                                            <span className='btn-inner-text'>Back</span>
                                        </button>
                                    </Col>
                                    <Col xs='3'>
                                        <h3 className='m-0'>Add Resource</h3>
                                    </Col>
                                    <Col xs='6' className='text-right'>
                                        <button
                                            className='btn btn-round btn-sm btn-dark mr-4'
                                            onClick={handleAttributeCreating}>
                                            <span className='btn-inner-text'>Add attributes</span>
                                        </button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className='row mb-4'>
                                        <div className='col-lg-12'>
                                            <input
                                                type='text'
                                                className='form-control'
                                                placeholder='Enter resource title'
                                                {...register('title', { required: true })}
                                            />
                                            {errors.title && <span className='invalid'>*This field is required</span>}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <textarea
                                                placeholder='Resource description'
                                                className='form-control'
                                                {...register('description', { required: true })}
                                                style={{
                                                    width: '100%',
                                                }}
                                            />
                                            {errors.description && (
                                                <span className='invalid'>*This field is required</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className='d-flex h-100'>
                                        <div className='align-self-end ml-auto'>
                                            <Button
                                                type='submit'
                                                className='btn btn-dark mt-4'
                                                disabled={loading || formAttributes.length === 0}>
                                                {!loading && <span>Add</span>}
                                                {loading && <Spinner />}
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md='12' lg='6'>
                        {formAttributes.map((attribute) => {
                            return (
                                <ResourceFormAttribute
                                    key={attribute.key}
                                    attribute={attribute}
                                    onRemove={onAttributeRemove}
                                    availableAttributes={formAttributes}
                                    onAttributeChange={handleAttributeUpdate}
                                    onDragStart={onDragStart}
                                    onDragOver={onDragOver}
                                    onDrop={onDrop}
                                />
                            );
                        })}
                    </Col>
                </Row>
            </Container>
        </>
    );
}
