import React, { useEffect, useState } from 'react';
import { GET_EMAIL_TEMPLATES, REMOVE_EMAIL_TEMPLATE } from '../../../../graphql/newsletter-email.graphql';
import { useMutation, useQuery } from '@apollo/client';
import { LinearProgress } from '@mui/material';
import useToast from '../../../../hooks/useToast';
import {
    Button,
    Card,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table,
    UncontrolledTooltip,
} from 'reactstrap';
import { useSelector } from 'react-redux';
import { User } from '../../../../models/user.model';
import { getPermission } from '../../../../utils/user.utils';
// import { Link } from 'react-router-dom';
import BasicMenu from '../../../../components/Menu';
import _ from 'lodash';
import { EmailTemplate } from '../../../../models/newsletter-emil.model';
import EmailTemplateAddDialog from './components/EmailTemplateAddDialog';
import { useNavigate } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

export default function EmailTemplateListView() {
    const { data, loading, error } = useQuery(GET_EMAIL_TEMPLATES);

    const user: User | null = useSelector((state: any) => state.user.userInfo);

    const [searchKey, setSearchKey] = useState<string>('');
    const [page, setPage] = useState<number>(0);
    const [rowPerPage, setRowPerPage] = useState<number>(10);
    const [sortFiled, setSortField] = useState<string>('name');
    const [sortDirection, setSortDirection] = useState<string>('desc');
    const [alert, setAlert] = useState<any>(null);
    const { showToast, Notification } = useToast();
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [selectedEmailTemplate, setSelectedEmailTemplate] = useState<EmailTemplate>();
    const navigate = useNavigate();

    const [removeEmailTemplate, { error: removeError, data: removedTemplate }] = useMutation(REMOVE_EMAIL_TEMPLATE, {
        refetchQueries: [GET_EMAIL_TEMPLATES],
    });

    const sortBy: any = (templates: EmailTemplate[], field: string, direction: string) => {
        const orderedBookings = new Map([
            ['name', _.orderBy(templates, (template) => template.name, [direction === 'asc' ? 'asc' : 'desc'])],
        ]);
        return orderedBookings.get(field);
    };

    const displayAlert = (props: any) => {
        setAlert(
            <SweetAlert
                {...props}
                showCancel
                confirmBtnText='Continue'
                confirmBtnBsStyle='danger'
                title='Are you sure?'
                onConfirm={async () => {
                    await removeEmailTemplate({
                        variables: {
                            id: props.template.id,
                        },
                    });
                    setAlert(null);
                }}
                onCancel={() => {
                    setAlert(null);
                }}
                focusCancelBtn>
                This template will be removed from the system.
            </SweetAlert>,
        );
    };

    const nextPage = () => {
        setPage(page + 1);
    };

    const previousPage = () => {
        setPage(page - 1);
    };

    const getRowValue = (value: string) => {
        setRowPerPage(parseInt(value));
    };

    useEffect(() => {
        if (removedTemplate) {
            showToast('Successfully removed email template', 'success');
        }
    }, [removedTemplate]);

    useEffect(() => {
        if (removeError) {
            showToast('An error has occurred while trying to remove this template', 'danger');
        }
    }, [removeError]);

    useEffect(() => {
        if (error) {
            showToast('An error has occurred while trying to load templates', 'danger');
        }
    }, [error]);

    if (loading) {
        return <LinearProgress />;
    }

    let filteredTemplates: EmailTemplate[] = data?.templates.filter((template: EmailTemplate) => {
        if (searchKey === '') {
            return template;
        }
        return template.name.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase());
    });

    filteredTemplates = sortBy(filteredTemplates, sortFiled, sortDirection);
    return (
        <>
            {alert}
            {Notification}{' '}
            {isDialogOpen && (
                <EmailTemplateAddDialog
                    selectTemplate={selectedEmailTemplate}
                    isOpen={isDialogOpen}
                    handleClose={() => setIsDialogOpen(false)}
                />
            )}
            <Container className='mt-4' fluid>
                <Row>
                    <Col>
                        <h1>Email Templates</h1>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <div className='col'>
                        <Card>
                            <CardHeader className='border-0'>
                                <Row>
                                    <Col xs='6'>
                                        <h3 className='mb-0'>Email Templates</h3>
                                    </Col>
                                    {getPermission(user, 'newsletter', 'create') && (
                                        <Col className='text-right' xs='6'>
                                            <button
                                                className=' btn btn-round btn-dark btn-sm'
                                                onClick={() => {
                                                    setIsDialogOpen(true);
                                                    setSelectedEmailTemplate(null);
                                                }}
                                                color='default'
                                                id='tooltip969372949'>
                                                <span className='btn-inner--text'> Add template</span>
                                            </button>
                                            <UncontrolledTooltip delay={0} target='tooltip969372949'>
                                                Add template
                                            </UncontrolledTooltip>
                                        </Col>
                                    )}
                                </Row>
                                <Row className='mt-4'>
                                    <Col>
                                        <Input
                                            className='w-25'
                                            placeholder='Search keyword...'
                                            onChange={(e) => {
                                                setSearchKey(e.target.value);
                                                setPage(0);
                                            }}></Input>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <Table className='align-items-center table-flush'>
                                <thead className='thead-light'>
                                    <tr>
                                        <th
                                            onClick={() => {
                                                setSortField('name');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='title'
                                            scope='col'>
                                            Name
                                        </th>
                                        <th scope='col'></th>
                                    </tr>
                                </thead>
                                <tbody className='list'>
                                    {filteredTemplates
                                        .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                                        .map((template: EmailTemplate) => {
                                            return (
                                                <tr
                                                    key={template.id}
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => {
                                                        setIsDialogOpen(true);
                                                        setSelectedEmailTemplate(template);
                                                    }}>
                                                    <td>{`${template.name}`}</td>
                                                    <td className='text-right'>
                                                        {getPermission(user, 'newsletter', 'update') && (
                                                            <button
                                                                className='btn btn-info btn-icon-only rounded-circle btn-sm'
                                                                onClick={() => {
                                                                    setIsDialogOpen(true);
                                                                    setSelectedEmailTemplate(template);
                                                                }}>
                                                                <i className='fa-solid fa-pencil'></i>
                                                            </button>
                                                        )}
                                                        {getPermission(user, 'newsletter', 'update') && (
                                                            <button
                                                                className='btn btn-info  btn-sm'
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    navigate(`/admin/templates/manage/${template.id}`);
                                                                }}>
                                                                View
                                                            </button>
                                                        )}
                                                        {getPermission(user, 'locations', 'delete') && (
                                                            <Button
                                                                className='btn btn-danger btn-icon-only rounded-circle btn-sm'
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    displayAlert({ warning: true, template });
                                                                }}>
                                                                <i className='fa-solid fa-trash-can'></i>
                                                            </Button>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </Table>
                            <CardFooter className='py-4'>
                                <Row>
                                    <div className='col-4 d-flex'>
                                        <span style={{ fontSize: '12px' }}>Rows per page:</span>
                                        <BasicMenu
                                            getValue={getRowValue}
                                            options={['10', '20', '100']}
                                            value={rowPerPage.toString()}
                                        />
                                    </div>
                                    <div className='col-4 d-flex'>
                                        <span className='min-text'>Page:</span>
                                        <span className='min-text pl-2'>
                                            {page + 1} of {Math.ceil(filteredTemplates.length / rowPerPage)}
                                        </span>
                                    </div>
                                    <div className='col-4 d-flex'>
                                        <Pagination>
                                            <PaginationItem className={page <= 0 ? 'disabled' : ''}>
                                                <PaginationLink onClick={(e) => previousPage()}>
                                                    <i className='fas fa-angle-left' />
                                                    <span className='sr-only'>Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem
                                                className={
                                                    page >= Math.ceil(filteredTemplates.length / rowPerPage) - 1
                                                        ? 'disabled'
                                                        : ''
                                                }>
                                                <PaginationLink onClick={(e) => nextPage()}>
                                                    <i className='fas fa-angle-right' />
                                                    <span className='sr-only'>Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                    </div>
                                </Row>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
}
