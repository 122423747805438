import React, { useEffect } from "react";
import {
  BasicMeetingRoom,
  BasicPackage,
  Client,
  CustomConferencePrice,
  CustomPackagePrice,
} from "../../../models/client.model";
import { useQuery } from "@apollo/client";
import { GET_CUSTOM_PRICING_DATA } from "../../../graphql/clients.graphql";
import useToast from "../../../hooks/useToast";
import { LinearProgress } from "@mui/material";
import { RateType } from "../../../models/meeting.room.model";
import { Col, Row } from "reactstrap";
import CustomConferencePricing from "./components/CustomConferencePricing";
import CustomPackagePricing from "./components/CustomPackagePricing";

interface CustomPricingProps {
  clientData: Client;
}

const CustomPricing = ({ clientData }: CustomPricingProps) => {
  const { loading, error, data } = useQuery(GET_CUSTOM_PRICING_DATA, {
    variables: {
      accountId: clientData.id,
    },
    skip: !clientData.id,
  });

  const { showToast, Notification } = useToast();

  useEffect(() => {
    if (error) {
      showToast("An error has occurred, please refresh the page", "danger");
    }
  }, [error, showToast]);

  if (loading) {
    return <LinearProgress />;
  }

  const meetingRooms: BasicMeetingRoom[] = data?.meetingRooms || [];
  const packages: BasicPackage[] = data?.productPackages || [];
  const rateTypes: RateType[] = data?.rateTypes || [];
  const conferencePrices: CustomConferencePrice[] = data?.customPricing || [];
  const packagePrices: CustomPackagePrice[] = data?.customPackagePricing || [];

  return (
    <>
      {Notification}
      <Row className="mt-4">
        <Col md="12" lg="6">
          <CustomConferencePricing
            rateTypes={rateTypes}
            meetingRooms={meetingRooms}
            accountId={clientData.id}
            conferencePrices={conferencePrices}
          />
        </Col>
        <Col md="12" lg="6">
          <CustomPackagePricing
            packages={packages}
            accountId={clientData.id}
            packagePrices={packagePrices}
          />
        </Col>
      </Row>
    </>
  );
};

export default CustomPricing;
