import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    Card,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    Row,
    Table,
    UncontrolledTooltip,
} from 'reactstrap';
import BasicMenu from '../../../components/Menu';
import { getPermission } from '../../../utils/user.utils';
import useToast from '../../../hooks/useToast';
import { useSelector } from 'react-redux';
import { User } from '../../../models/user.model';
import { GET_SURVEY_LIST, REMOVE_SURVEY } from '../../../graphql/survey.graphql';
import { useMutation, useQuery } from '@apollo/client';
import { LinearProgress } from '@mui/material';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';
import { Survey } from '../../../models/survey.modesl';
import moment from 'moment';

export default function SurveyListView() {
    const [searchValue, setSearchValue] = useState('');
    const [alert, setAlert] = useState<any>(null);
    const { data, loading, error } = useQuery(GET_SURVEY_LIST);

    const [removeSurvey, { data: removedSurvey, error: errorDeletingSurvey }] = useMutation(REMOVE_SURVEY, {
        refetchQueries: [{ query: GET_SURVEY_LIST }],
    });
    const user: User | null = useSelector((state: any) => state.user.userInfo);
    const { showToast, Notification } = useToast();
    const [page, setPage] = useState<number>(0);
    const [rowPerPage, setRowsPerPage] = useState<number>(10);
    const [sortDirection, setSortDirection] = useState<string>('asc');
    const [sortFiled, setSortField] = useState<string>('title');
    const navigate = useNavigate();

    useEffect(() => {
        if (error) {
            showToast('An error has occurred while trying load the page', 'success');
            console.log('Loading error', error);
        }
    }, [error]);

    useEffect(() => {
        if (removedSurvey) {
            showToast('Successfully removed survey', 'success');
        }
    }, [removedSurvey]);

    const getRowValue = (value: string) => {
        setRowsPerPage(parseInt(value));
        if (parseInt(value) !== rowPerPage) {
            setPage(0);
        }
    };

    useEffect(() => {
        if (errorDeletingSurvey) {
            showToast('An error has occurred while trying to remove this survey', 'danger');
            console.log('Remove error', error);
        }
    }, [errorDeletingSurvey]);

    const nextPage = () => {
        setPage(page + 1);
    };
    const previousPage = () => {
        setPage(page - 1);
    };

    const sortBy: any = (survey: Survey[], field: string, direction: string) => {
        const orderedBookings = new Map([
            ['title', _.orderBy(survey, (sur) => sur?.title, [direction === 'asc' ? 'asc' : 'desc'])],
            ['status', _.orderBy(survey, (sur) => sur.status.title, [direction === 'asc' ? 'asc' : 'desc'])],
            ['createdAt', _.orderBy(survey, (sur) => sur.createdAt, [direction === 'asc' ? 'asc' : 'desc'])],
        ]);
        return orderedBookings.get(field);
    };

    const displayAlert = (props: any) => {
        setAlert(
            <SweetAlert
                {...props}
                showCancel
                confirmBtnText='Continue'
                confirmBtnBsStyle='danger'
                title='Are you sure?'
                onConfirm={() => {
                    removeSurvey({
                        variables: {
                            id: props.survey.id,
                        },
                    });
                    setAlert(null);
                }}
                onCancel={() => {
                    setAlert(null);
                }}
                focusCancelBtn>
                This survey will be removed.
            </SweetAlert>,
        );
    };

    if (loading) {
        return <LinearProgress />;
    }

    const surveyList: Survey[] = data?.survey;

    let filteredSurveys: Survey[] = surveyList.filter((key: Survey) => {
        const checkForSearch =
            key?.title?.toLowerCase().includes(searchValue.toLowerCase()) ||
            key?.status.title?.toLowerCase().includes(searchValue.toLowerCase()) ||
            key?.createdAt?.toLowerCase().includes(searchValue.toLowerCase());

        return checkForSearch;
    });

    filteredSurveys = sortBy(filteredSurveys, sortFiled, sortDirection);

    return (
        <>
            {Notification}
            {alert}
            <Container className='mt-4' fluid>
                <Row>
                    <Col>
                        <h1>Surveys</h1>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <div className='col'>
                        <Card>
                            <CardHeader className='border-0'>
                                <Row>
                                    <Col xs='6'>
                                        <h3 className='mb-0'>Surveys</h3>
                                    </Col>
                                    {getPermission(user, 'clients', 'create') && (
                                        <Col className='text-right' xs='6'>
                                            <Link
                                                to={'/admin/survey/new'}
                                                className='btn btn-round btn-sm btn-dark'
                                                color='default'
                                                id='tooltip969372949'>
                                                <span className='btn-inner--text'>Add survey</span>
                                            </Link>
                                            <UncontrolledTooltip delay={0} target='tooltip969372949'>
                                                Add survey
                                            </UncontrolledTooltip>
                                        </Col>
                                    )}
                                </Row>
                                <Row className='mt-4'>
                                    <Col xs='6'>
                                        <Input
                                            className='form-control w-50'
                                            value={searchValue}
                                            type='text'
                                            placeholder='Search keyword...'
                                            onChange={(e) => {
                                                setSearchValue(e.target.value);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </CardHeader>
                            <Table className='align-items-center table-flush' responsive>
                                <thead className='thead-light'>
                                    <tr>
                                        <th
                                            onClick={() => {
                                                setSortField('title');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='companyName'
                                            scope='col'>
                                            Title
                                        </th>
                                        <th
                                            onClick={() => {
                                                setSortField('status');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='name'
                                            scope='col'>
                                            Status
                                        </th>

                                        <th
                                            onClick={() => {
                                                setSortField('createdAt');
                                                setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                                            }}
                                            className='sort'
                                            data-sort='status'
                                            scope='col'>
                                            Created At
                                        </th>
                                        <th scope='col'></th>
                                    </tr>
                                </thead>
                                <tbody className='list'>
                                    {filteredSurveys
                                        .slice(page * rowPerPage, page * rowPerPage + rowPerPage)
                                        .map((survey) => {
                                            return (
                                                <tr
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => navigate(`/admin/survey/manage/${survey.id}`)}
                                                    key={survey.id}>
                                                    <td>{survey?.title ?? ''}</td>
                                                    <td>{survey?.status.title ?? ''}</td>
                                                    <td>{moment(survey?.createdAt).format('yyyy-MM-DD')}</td>
                                                    <td>
                                                        {getPermission(user, 'clients', 'read') && (
                                                            <Link
                                                                to={`/admin/survey/manage/${survey.id}`}
                                                                className='btn btn-info btn-icon-only rounded-circle btn-sm'>
                                                                <i className='fa-solid fa-pencil'></i>
                                                            </Link>
                                                        )}
                                                        {getPermission(user, 'clients', 'delete') && (
                                                            <button
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    displayAlert({ warning: true, survey });
                                                                }}
                                                                className='btn btn-danger btn-icon-only rounded-circle btn-sm'>
                                                                <i className='fa-solid fa-trash-can'></i>
                                                            </button>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </Table>
                            <CardFooter className='py-4'>
                                <Row>
                                    <div className='col-4 d-flex'>
                                        <span className='min-text'>Rows per page:</span>
                                        <BasicMenu
                                            getValue={getRowValue}
                                            options={['10', '20', '100']}
                                            value={rowPerPage.toString()}
                                        />
                                    </div>
                                    <div className='col-4 d-flex'>
                                        <span className='min-text'>Page:</span>
                                        <span className='min-text pl-2'>
                                            {page + 1} of {Math.ceil(filteredSurveys.length / rowPerPage)}
                                        </span>
                                    </div>
                                    <div className='col-4 d-flex'>
                                        <Pagination>
                                            <PaginationItem className={page <= 0 ? 'disabled' : ''}>
                                                <PaginationLink onClick={(e) => previousPage()}>
                                                    <i className='fas fa-angle-left' />
                                                    <span className='sr-only'>Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                            <PaginationItem
                                                className={
                                                    page >= Math.ceil(filteredSurveys.length / rowPerPage) - 1
                                                        ? 'disabled'
                                                        : ''
                                                }>
                                                <PaginationLink onClick={(e) => nextPage()}>
                                                    <i className='fas fa-angle-right' />
                                                    <span className='sr-only'>Previous</span>
                                                </PaginationLink>
                                            </PaginationItem>
                                        </Pagination>
                                    </div>
                                </Row>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
}
