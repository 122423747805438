import React, { useEffect } from 'react';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Row } from 'reactstrap';
import {
    Resource,
    ResourceAttribute,
    ResourceFormAttributeOption,
    ResourceStatus,
} from '../../../../models/resource.model';
import { Checkbox, FormControlLabel, LinearProgress, MenuItem, Select } from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_RESOURCE_ITEM, GET_RESOURCE, GET_RESOURCE_ITEMS } from '../../../../graphql/resource.graphql';
import useToast from '../../../../hooks/useToast';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Spinner from '../../../../components/Spinner/Spinner';
import { LocationSpace } from '../../../../models/location.model';

interface ResourceFormProps {
    redirectBackToResourceList?: () => void;
}
export default function ResourceForm({ redirectBackToResourceList }: ResourceFormProps) {
    const params: any = useParams();
    const { data, loading, error } = useQuery(GET_RESOURCE, {
        variables: {
            id: params?.id,
        },
    });

    const [addResourceItem, { data: addedItem, loading: addingItem, error: insertError }] = useMutation(
        ADD_RESOURCE_ITEM,
        {
            refetchQueries: [GET_RESOURCE_ITEMS],
        },
    );

    const {
        register,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = async (formData: any) => {
        const formValues = Object.keys(formData)
            .filter((key) => {
                return (
                    key.includes('item_name') === false &&
                    key.includes('item_location') === false &&
                    key.includes('item_status') === false &&
                    key.includes('item_code') === false
                );
            })
            .map((key) => formData[key]);

        const values = formValues.map((value: any, index: number) => {
            const resource: Resource = data?.resource;
            const attributes = resource?.attributeForm?.attributes;
            return {
                attribute_id: attributes[index]?.id ?? undefined,
                value: value,
            };
        });

        const item = {
            name: formData['item_name'] ?? undefined,
            location_space_id: formData['item_location'] ?? undefined,
            resource_id: params?.id ?? undefined,
            status_enum: formData['item_status'] ?? undefined,
            code: formData['item_code'] ?? undefined,
            values: {
                data: values,
            },
        };

        // console.log('Values', item);

        await addResourceItem({
            variables: {
                item,
            },
        });
    };

    useEffect(() => {
        if (addedItem) {
            showToast('Successfully updated the attribute values', 'success');

            reset();
        }
    }, [addedItem]);

    useEffect(() => {
        if (insertError) {
            showToast('An error has occurred while trying update the values', 'danger');
            console.log('Update error', insertError);
        }
    }, [insertError]);

    const renderAttribute = (attribute: ResourceAttribute, key: React.Key): React.ReactNode => {
        let reactNode: React.ReactNode = <></>;
        let field = '';
        let optionalText = '';

        switch (attribute.type) {
            case 'text':
                field = attribute?.title;
                field = field.toLocaleLowerCase();
                optionalText = !attribute.required ? '(Optional)' : '';
                reactNode = (
                    <FormGroup key={key}>
                        <label className='form-control-label' htmlFor='name'>
                            {attribute?.title + optionalText ?? ''}
                        </label>
                        <input
                            className='form-control'
                            type='text'
                            placeholder={`Please enter ${field}...`}
                            {...register(field, {
                                required: attribute.required,
                            })}
                        />
                        {errors[field] && <span className='invalid'>*This field is required</span>}
                    </FormGroup>
                );
                break;
            case 'number':
                field = attribute?.title;
                field = field.toLocaleLowerCase();
                optionalText = !attribute.required ? '(Optional)' : '';
                reactNode = (
                    <FormGroup key={key}>
                        <label className='form-control-label' htmlFor='name'>
                            {attribute?.title + optionalText ?? ''}
                        </label>
                        <input
                            className='form-control'
                            placeholder={`Please enter a value for ${field}...`}
                            type='number'
                            {...register(field, {
                                required: attribute.required,
                                valueAsNumber: true,
                            })}
                        />
                        {errors[field] && <span className='invalid'>*This field is required</span>}
                    </FormGroup>
                );
                break;

            case 'textarea':
                field = attribute?.title;
                field = field.toLocaleLowerCase();
                optionalText = !attribute.required ? '(Optional)' : '';
                reactNode = (
                    <FormGroup key={key}>
                        <label className='form-control-label' htmlFor='name'>
                            {attribute?.title + optionalText ?? ''}
                        </label>
                        <textarea
                            rows={5}
                            placeholder={`Please type in text for ${field}...`}
                            className='form-control'
                            {...register(field, {
                                required: attribute.required,
                            })}
                        />
                        {errors[field] && <span className='invalid'>*This field is required</span>}
                    </FormGroup>
                );
                break;

            case 'dropdown':
                field = attribute?.title;
                field = field.toLocaleLowerCase();
                optionalText = !attribute.required ? '(Optional)' : '';

                reactNode = (
                    <FormGroup key={key}>
                        <label className='form-control-label' htmlFor='name'>
                            {attribute?.title + optionalText ?? ''}
                        </label>
                        <Select
                            className='form-control'
                            defaultValue={' '}
                            sx={{ borderRadius: '12px', padding: 0 }}
                            {...register(field, { required: attribute.required })}>
                            <MenuItem disabled value={' '}>{`Select a value for ${field}...`}</MenuItem>
                            {attribute?.options.map((option: ResourceFormAttributeOption) => {
                                return (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.value}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        {errors[field] && <span className='invalid'>*This field is required</span>}
                    </FormGroup>
                );
                break;

            case 'checkbox':
                field = attribute?.title;
                field = field.toLocaleLowerCase();
                reactNode = (
                    <FormGroup key={key}>
                        <FormControlLabel
                            control={<Checkbox />}
                            label={attribute?.title ?? ''}
                            {...register(field, { required: false })}
                        />
                    </FormGroup>
                );
                break;

            case 'date':
                field = attribute?.title;
                field = field.toLocaleLowerCase();
                optionalText = !attribute.required ? '(Optional)' : '';
                reactNode = (
                    <FormGroup key={key}>
                        <label className='form-control-label' htmlFor='name'>
                            {attribute?.title + optionalText ?? ''}
                        </label>
                        <input
                            className='form-control'
                            type='date'
                            {...register(field, { required: attribute.required })}
                        />
                        {errors[field] && <span className='invalid'>*This field is required</span>}
                    </FormGroup>
                );
                break;

            case 'time':
                field = attribute?.title;
                field = field.toLocaleLowerCase();
                optionalText = !attribute.required ? '(Optional)' : '';
                reactNode = (
                    <FormGroup key={key}>
                        <label className='form-control-label' htmlFor='name'>
                            {attribute?.title + optionalText ?? ''}
                        </label>
                        <input
                            className='form-control'
                            type='time'
                            {...register(field, { required: attribute.required })}
                        />
                        {errors[field] && <span className='invalid'>*This field is required</span>}
                    </FormGroup>
                );
                break;
        }

        return reactNode;
    };

    const { showToast, Notification } = useToast();

    useEffect(() => {
        if (error) {
            showToast('An error has occurred while trying to load this page', 'danger');
        }
    }, [error]);

    if (loading) {
        return <LinearProgress />;
    }

    const resource: Resource = data?.resource;
    const resourceItemStatusList: ResourceStatus[] = data?.resourceStatusList;
    const spaceLocations: LocationSpace[] = data?.spaceLocations;
    return (
        <>
            {Notification}

            <Row className='mt-4'>
                <Col md='12' lg='6'>
                    <Card>
                        <CardHeader>
                            <Row noGutters={true}>
                                <div className='col-3'>
                                    <button
                                        className='btn btn-outline-primary btn-sm mr-4'
                                        onClick={() => {
                                            if (redirectBackToResourceList) {
                                                redirectBackToResourceList();
                                            }
                                        }}>
                                        <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                                        <span className='btn-inner-text'>Back</span>
                                    </button>
                                </div>

                                <div className='col-3'>
                                    <h3 className='m-0 mr-4'>Add Resource Item</h3>
                                </div>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='name'>
                                        Name
                                    </label>
                                    <input
                                        type='text'
                                        placeholder='Enter resource item name...'
                                        className='form-control'
                                        {...register('item_name', { required: true })}
                                    />
                                    {errors['item_name'] && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='name'>
                                        Location
                                    </label>
                                    <Select
                                        className='form-control'
                                        defaultValue={' '}
                                        sx={{ borderRadius: '12px', padding: 0 }}
                                        {...register('item_location', { required: true })}>
                                        <MenuItem
                                            disabled
                                            value={' '}>{`Select a location for the resource item...`}</MenuItem>
                                        {spaceLocations?.map((location: LocationSpace) => {
                                            return (
                                                <MenuItem key={location.id} value={location.id}>
                                                    {location.name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    {errors['item_location'] && (
                                        <span className='invalid'>*This field is required</span>
                                    )}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='name'>
                                        Status
                                    </label>
                                    <Select
                                        className='form-control'
                                        defaultValue={' '}
                                        sx={{ borderRadius: '12px', padding: 0 }}
                                        {...register('item_status', { required: true })}>
                                        <MenuItem
                                            disabled
                                            value={' '}>{`Select a status for the resource item...`}</MenuItem>
                                        {resourceItemStatusList?.map((status: ResourceStatus) => {
                                            return (
                                                <MenuItem key={status.value} value={status.value}>
                                                    {status.title}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                    {errors['item_status'] && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                                <FormGroup>
                                    <label className='form-control-label' htmlFor='name'>
                                        Code
                                    </label>
                                    <input
                                        type='text'
                                        placeholder='Enter a unique code for the resource item...'
                                        className='form-control'
                                        {...register('item_code', { required: true })}
                                    />
                                    {errors['item_code'] && <span className='invalid'>*This field is required</span>}
                                </FormGroup>
                                {resource?.attributeForm?.attributes?.map((attribute) => {
                                    return renderAttribute(attribute, attribute.id);
                                })}
                                <div className='d-flex h-100'>
                                    <div className='align-self-end ml-auto'>
                                        <Button type='submit' className='btn btn-dark mt-4 btn-block'>
                                            {!addingItem && <span>Add item</span>}
                                            {addingItem && <Spinner />}
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
}
