/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Table } from "reactstrap";
import AddVendorMenuRate from "./dialog/VendorMenuRateDialog";
import useToast from "../../../hooks/useToast";
import SweetAlert from "react-bootstrap-sweetalert";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_MENU_RATES,
  REMOVE_VENDOR_RATE,
} from "../../../graphql/menu-packages.graphql";
import { MenuPackage, Rate } from "../../../models/vendor.model";

interface PackageManageViewProps {
  selectedPackage: MenuPackage | null;
}

export default function RateListView({
  selectedPackage,
}: PackageManageViewProps) {
  const [openAddRate, setOpenAddRate] = useState(false);
  const [selectedRate, setSelectedRate] = useState<any>(null);
  const { showToast, Notification } = useToast();
  const [alert, setAlert] = useState<any>(null);

  const { data, error } = useQuery(GET_MENU_RATES, {
    variables: { id: selectedPackage?.id },
  });

  useEffect(() => {
    if (error) {
      showToast("An error has occurred, please refresh your page", "danger");
    }
  }, [data, error]);

  const [
    removeRate,
    {
      error: errorRemoving,
      called: calledRemoveRate,
      loading: isRemovingRate,
      reset: resetRemoving,
    },
  ] = useMutation(REMOVE_VENDOR_RATE, {
    // refetchQueries: [{ query: GET_MENU_RATES }, "getMenuPackages"],
    refetchQueries: ["getMenuPackages"],
  });

  const editRate = (rate: any) => {
    setOpenAddRate(true);
    setSelectedRate(rate);
  };

  useEffect(() => {
    if (calledRemoveRate && !isRemovingRate) {
      resetRemoving();
      showToast("Successfully removed rate", "success");
    }
    if (calledRemoveRate && errorRemoving) {
      showToast("An error has occurred, please try again", "danger");
    }
  }, [alert, errorRemoving, calledRemoveRate, isRemovingRate]);

  const displayRemoveRateAlert = (props: any) => {
    setAlert(
      <SweetAlert
        {...props}
        showCancel
        confirmBtnText="Continue"
        confirmBtnBsStyle="danger"
        title="Are you sure? This action cannot be undone"
        onConfirm={() => {
          setAlert(null);
          removeRate({
            variables: {
              id: props.rate.id,
            },
          });
        }}
        onCancel={() => {
          setAlert(null);
        }}
        focusCancelBtn
      >
        This room rate will be removed from the system.
      </SweetAlert>
    );
  };

  const packageRates: Rate[] | [] = data?.menuRates;

  return (
    <>
      {
        <AddVendorMenuRate
          isOpen={openAddRate}
          toggleOpen={(isOpen) => {
            setOpenAddRate(isOpen);
          }}
          selectedPackage={selectedPackage}
          rate={selectedRate}
        />
      }
      {Notification}
      {alert}
      <Card>
        <CardBody>
          <div className="row no-gutters justify-content-between align-items-center mb-3">
            <h3 className="mb-0">Menu rates</h3>
            <button
              className="btn btn-dark btn-sm"
              onClick={() => {
                setSelectedRate(null);
                setOpenAddRate(true);
              }}
            >
              Add rate
            </button>
          </div>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">Price (excl VAT)</th>
                <th scope="col">Currency</th>
                <th scope="col">Range</th>
                <th scope="col" />
              </tr>
            </thead>
            <tbody className="list">
              {packageRates?.map((rate: any) => {
                return (
                  <tr key={rate.id}>
                    <td>{`R${rate.price}.00`}</td>
                    <td>{rate.currency.title.toUpperCase()}</td>
                    <td>{`${rate.minimumLimit}${
                      rate.maximumLimit === null ? "+" : "-" + rate.maximumLimit
                    }`}</td>
                    <td className="text-right">
                      <Button
                        className="btn btn-info btn-icon-only rounded-circle btn-sm"
                        onClick={() => {
                          editRate(rate);
                        }}
                      >
                        <i className="fa-solid fa-pencil"></i>
                      </Button>
                      <Button
                        className="btn btn-danger btn-icon-only rounded-circle  btn-sm"
                        onClick={() => {
                          displayRemoveRateAlert({ warning: true, rate });
                        }}
                      >
                        <i className="fa-regular fa-trash-can"></i>
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </>
  );
}
