import { Box, Tab, Tabs, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import ResourceEditFormView from '../ResourceEditFormView';
import ResourceItemList from './ResourceItemList';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box sx={{ p: 0 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function ResourceTabView() {
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const navigate = useNavigate();

    return (
        <div className='container-fluid mt-4'>
            <Col>
                <Row className='my-4'>
                    <button className='btn btn-outline-primary btn-sm mr-4' onClick={() => navigate(-1)}>
                        <i className='fas fa-angle-left' style={{ fontSize: '14px' }} />
                        <span className='btn-inner-text'>Back</span>
                    </button>
                    <h3 className='mb-0'>Manage Resource</h3>
                </Row>
            </Col>
            <Col>
                <Row>
                    <Box sx={{ width: '100%' }}>
                        <Box
                            sx={{
                                borderBottom: 1,
                                borderColor: 'divider',
                                color: '#000',
                            }}>
                            <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
                                <Tab sx={{ textTransform: 'capitalize' }} label='Details' {...a11yProps(0)} />
                                <Tab sx={{ textTransform: 'capitalize' }} label='Items' {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <ResourceEditFormView />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <ResourceItemList />
                        </TabPanel>
                    </Box>
                </Row>
            </Col>
        </div>
    );
}
